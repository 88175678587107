// WARNING: This file is automatically generated. Do not edit.
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type ActiveTripForSponsor = {
  __typename?: "ActiveTripForSponsor";
  busAttendant?: Maybe<BusAttendant>;
  date: Scalars["DateTime"]["output"];
  directionality: RouteDirectionality;
  driver?: Maybe<Driver>;
  id: Scalars["String"]["output"];
  status: TripStatus;
  trackingPollRate: Scalars["Float"]["output"];
  tripHasUnknownLocationStop: Scalars["Boolean"]["output"];
  tripStops: Array<TripStopForSponsor>;
  vehicle?: Maybe<Vehicle>;
};

export type ActiveTripStopForSponsor = {
  __typename?: "ActiveTripStopForSponsor";
  _count: TripStopCount;
  actualArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualCompletedTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualLat?: Maybe<Scalars["Float"]["output"]>;
  actualLon?: Maybe<Scalars["Float"]["output"]>;
  actualOrder?: Maybe<Scalars["Int"]["output"]>;
  actualPickupTime?: Maybe<Scalars["DateTime"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedAttendanceStatus: AttendanceStatus;
  confirmedDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedNoShowTime?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  delayInMinutes?: Maybe<Scalars["Int"]["output"]>;
  delayTripStopAlerts?: Maybe<Array<DelayTripStopAlert>>;
  detectedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  estimatedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isCompletedNotificationSent: Scalars["Boolean"]["output"];
  isInTransit?: Maybe<Scalars["Boolean"]["output"]>;
  isInTransitNotificationSent: Scalars["Boolean"]["output"];
  isInaccurateLocation: Scalars["Boolean"]["output"];
  isNearbyDropoffNotificationSent: Scalars["Boolean"]["output"];
  isNearbyPickupNotificationSent: Scalars["Boolean"]["output"];
  isOnLeave: Scalars["Boolean"]["output"];
  isVisitedNotificationSent: Scalars["Boolean"]["output"];
  plannedArrivalTime: Scalars["DateTime"]["output"];
  plannedLat?: Maybe<Scalars["Float"]["output"]>;
  plannedLon?: Maybe<Scalars["Float"]["output"]>;
  plannedOrder?: Maybe<Scalars["Int"]["output"]>;
  routeStop: RouteStop;
  routeStopId: Scalars["String"]["output"];
  schoolTripStop?: Maybe<TripStop>;
  schoolTripStopId?: Maybe<Scalars["String"]["output"]>;
  schoolTripStopStatus: SchoolTripStopStatus;
  studentStopLogs?: Maybe<Array<StudentStopLog>>;
  studentTripStopStatus: StudentTripStopStatus;
  studentsTripStops?: Maybe<Array<TripStop>>;
  trip: Trip;
  tripId: Scalars["String"]["output"];
  unknownDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum ActivityStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type AddAppVersionInput = {
  appType: AppType;
  appVersionType: AppVersionType;
  description?: InputMaybe<Scalars["String"]["input"]>;
  descriptionAr?: InputMaybe<Scalars["String"]["input"]>;
  major: Scalars["Float"]["input"];
  minor: Scalars["Float"]["input"];
  patch: Scalars["Float"]["input"];
};

export type Address = {
  __typename?: "Address";
  administrativeArea?: Maybe<AdministrativeArea>;
  administrativeAreaId?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  block?: Maybe<Scalars["String"]["output"]>;
  building?: Maybe<Scalars["String"]["output"]>;
  buildingType?: Maybe<BuildingType>;
  collectionPoint?: Maybe<CollectionPoint>;
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  flat?: Maybe<Scalars["String"]["output"]>;
  floor?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isPrimary: Scalars["Boolean"]["output"];
  locality?: Maybe<Locality>;
  localityId?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  poi?: Maybe<Poi>;
  school?: Maybe<School>;
  serviceProvider?: Maybe<ServiceProvider>;
  source?: Maybe<AddressSource>;
  street?: Maybe<Scalars["String"]["output"]>;
  subLocality?: Maybe<SubLocality>;
  subLocalityId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
  way?: Maybe<Scalars["String"]["output"]>;
};

export type AddressCreateInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaCreateNestedOneWithoutAddressesInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutAddressInput>;
  country: CountryCreateNestedOneWithoutAddressInput;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityCreateNestedOneWithoutAddressInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiCreateNestedOneWithoutAddressInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutAddressInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutAddressInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityCreateNestedOneWithoutAddressInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateManyAdministrativeAreaInput = {
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  countryId: Scalars["String"]["input"];
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  localityId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocalityId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateManyAdministrativeAreaInputEnvelope = {
  data: Array<AddressCreateManyAdministrativeAreaInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddressCreateManyCountryInput = {
  administrativeAreaId?: InputMaybe<Scalars["String"]["input"]>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  localityId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocalityId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateManyCountryInputEnvelope = {
  data: Array<AddressCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddressCreateManyLocalityInput = {
  administrativeAreaId?: InputMaybe<Scalars["String"]["input"]>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  countryId: Scalars["String"]["input"];
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocalityId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateManyLocalityInputEnvelope = {
  data: Array<AddressCreateManyLocalityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddressCreateManySubLocalityInput = {
  administrativeAreaId?: InputMaybe<Scalars["String"]["input"]>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  countryId: Scalars["String"]["input"];
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  localityId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateManySubLocalityInputEnvelope = {
  data: Array<AddressCreateManySubLocalityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddressCreateManyUserInput = {
  administrativeAreaId?: InputMaybe<Scalars["String"]["input"]>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  countryId: Scalars["String"]["input"];
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  localityId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocalityId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateManyUserInputEnvelope = {
  data: Array<AddressCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddressCreateNestedManyWithoutAdministrativeAreaInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutAdministrativeAreaInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutAdministrativeAreaInput>>;
  createMany?: InputMaybe<AddressCreateManyAdministrativeAreaInputEnvelope>;
};

export type AddressCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutCountryInput>>;
  createMany?: InputMaybe<AddressCreateManyCountryInputEnvelope>;
};

export type AddressCreateNestedManyWithoutLocalityInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutLocalityInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutLocalityInput>>;
  createMany?: InputMaybe<AddressCreateManyLocalityInputEnvelope>;
};

export type AddressCreateNestedManyWithoutSubLocalityInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutSubLocalityInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutSubLocalityInput>>;
  createMany?: InputMaybe<AddressCreateManySubLocalityInputEnvelope>;
};

export type AddressCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutUserInput>>;
  createMany?: InputMaybe<AddressCreateManyUserInputEnvelope>;
};

export type AddressCreateNestedOneWithoutCollectionPointInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutCollectionPointInput>;
  create?: InputMaybe<AddressCreateWithoutCollectionPointInput>;
};

export type AddressCreateNestedOneWithoutSchoolInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutSchoolInput>;
  create?: InputMaybe<AddressCreateWithoutSchoolInput>;
};

export type AddressCreateNestedOneWithoutServiceProviderInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutServiceProviderInput>;
  create?: InputMaybe<AddressCreateWithoutServiceProviderInput>;
};

export type AddressCreateOrConnectWithoutAdministrativeAreaInput = {
  create: AddressCreateWithoutAdministrativeAreaInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutCollectionPointInput = {
  create: AddressCreateWithoutCollectionPointInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutCountryInput = {
  create: AddressCreateWithoutCountryInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutLocalityInput = {
  create: AddressCreateWithoutLocalityInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutSchoolInput = {
  create: AddressCreateWithoutSchoolInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutServiceProviderInput = {
  create: AddressCreateWithoutServiceProviderInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutSubLocalityInput = {
  create: AddressCreateWithoutSubLocalityInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutUserInput = {
  create: AddressCreateWithoutUserInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateWithoutAdministrativeAreaInput = {
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutAddressInput>;
  country: CountryCreateNestedOneWithoutAddressInput;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityCreateNestedOneWithoutAddressInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiCreateNestedOneWithoutAddressInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutAddressInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutAddressInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityCreateNestedOneWithoutAddressInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateWithoutCollectionPointInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaCreateNestedOneWithoutAddressesInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  country: CountryCreateNestedOneWithoutAddressInput;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityCreateNestedOneWithoutAddressInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiCreateNestedOneWithoutAddressInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutAddressInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutAddressInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityCreateNestedOneWithoutAddressInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateWithoutCountryInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaCreateNestedOneWithoutAddressesInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutAddressInput>;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityCreateNestedOneWithoutAddressInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiCreateNestedOneWithoutAddressInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutAddressInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutAddressInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityCreateNestedOneWithoutAddressInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateWithoutLocalityInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaCreateNestedOneWithoutAddressesInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutAddressInput>;
  country: CountryCreateNestedOneWithoutAddressInput;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiCreateNestedOneWithoutAddressInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutAddressInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutAddressInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityCreateNestedOneWithoutAddressInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateWithoutSchoolInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaCreateNestedOneWithoutAddressesInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutAddressInput>;
  country: CountryCreateNestedOneWithoutAddressInput;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityCreateNestedOneWithoutAddressInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiCreateNestedOneWithoutAddressInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutAddressInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityCreateNestedOneWithoutAddressInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateWithoutServiceProviderInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaCreateNestedOneWithoutAddressesInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutAddressInput>;
  country: CountryCreateNestedOneWithoutAddressInput;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityCreateNestedOneWithoutAddressInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiCreateNestedOneWithoutAddressInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutAddressInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityCreateNestedOneWithoutAddressInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateWithoutSubLocalityInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaCreateNestedOneWithoutAddressesInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutAddressInput>;
  country: CountryCreateNestedOneWithoutAddressInput;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityCreateNestedOneWithoutAddressInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiCreateNestedOneWithoutAddressInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutAddressInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutAddressInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutAddressesInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressCreateWithoutUserInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaCreateNestedOneWithoutAddressesInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutAddressInput>;
  country: CountryCreateNestedOneWithoutAddressInput;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityCreateNestedOneWithoutAddressInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiCreateNestedOneWithoutAddressInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutAddressInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutAddressInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityCreateNestedOneWithoutAddressInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressListRelationFilter = {
  every?: InputMaybe<AddressWhereInput>;
  none?: InputMaybe<AddressWhereInput>;
  some?: InputMaybe<AddressWhereInput>;
};

export type AddressRelationFilter = {
  is?: InputMaybe<AddressWhereInput>;
  isNot?: InputMaybe<AddressWhereInput>;
};

export type AddressResponsePayload = {
  __typename?: "AddressResponsePayload";
  administrativeArea?: Maybe<AdministrativeAreaResponsePayload>;
  block?: Maybe<Scalars["String"]["output"]>;
  building?: Maybe<Scalars["String"]["output"]>;
  buildingType?: Maybe<BuildingType>;
  country: CountryResponsePayload;
  flat?: Maybe<Scalars["String"]["output"]>;
  floor?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isPrimary: Scalars["Boolean"]["output"];
  lat?: Maybe<Scalars["Float"]["output"]>;
  locality?: Maybe<LocalityResponsePayload>;
  lon?: Maybe<Scalars["Float"]["output"]>;
  long?: Maybe<Scalars["Float"]["output"]>;
  street?: Maybe<Scalars["String"]["output"]>;
  subLocality?: Maybe<SubLocalityResponsePayload>;
  way?: Maybe<Scalars["String"]["output"]>;
};

export type AddressScalarWhereInput = {
  AND?: InputMaybe<Array<AddressScalarWhereInput>>;
  NOT?: InputMaybe<Array<AddressScalarWhereInput>>;
  OR?: InputMaybe<Array<AddressScalarWhereInput>>;
  administrativeAreaId?: InputMaybe<StringFilter>;
  block?: InputMaybe<StringFilter>;
  building?: InputMaybe<StringFilter>;
  buildingType?: InputMaybe<EnumBuildingTypeFilter>;
  countryId?: InputMaybe<StringFilter>;
  flat?: InputMaybe<StringFilter>;
  floor?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPrimary?: InputMaybe<BoolFilter>;
  localityId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  source?: InputMaybe<EnumAddressSourceFilter>;
  street?: InputMaybe<StringFilter>;
  subLocalityId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  way?: InputMaybe<StringFilter>;
};

export enum AddressSource {
  GOOGLE = "GOOGLE",
  USER = "USER",
}

export type AddressUpdateManyMutationInput = {
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressUpdateManyWithWhereWithoutAdministrativeAreaInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithWhereWithoutCountryInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithWhereWithoutLocalityInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithWhereWithoutSubLocalityInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithWhereWithoutUserInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithoutAdministrativeAreaNestedInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutAdministrativeAreaInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutAdministrativeAreaInput>>;
  createMany?: InputMaybe<AddressCreateManyAdministrativeAreaInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AddressUpdateWithWhereUniqueWithoutAdministrativeAreaInput>
  >;
  updateMany?: InputMaybe<
    Array<AddressUpdateManyWithWhereWithoutAdministrativeAreaInput>
  >;
  upsert?: InputMaybe<
    Array<AddressUpsertWithWhereUniqueWithoutAdministrativeAreaInput>
  >;
};

export type AddressUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutCountryInput>>;
  createMany?: InputMaybe<AddressCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<Array<AddressUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<Array<AddressUpdateManyWithWhereWithoutCountryInput>>;
  upsert?: InputMaybe<Array<AddressUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type AddressUpdateManyWithoutLocalityNestedInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutLocalityInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutLocalityInput>>;
  createMany?: InputMaybe<AddressCreateManyLocalityInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<Array<AddressUpdateWithWhereUniqueWithoutLocalityInput>>;
  updateMany?: InputMaybe<
    Array<AddressUpdateManyWithWhereWithoutLocalityInput>
  >;
  upsert?: InputMaybe<Array<AddressUpsertWithWhereUniqueWithoutLocalityInput>>;
};

export type AddressUpdateManyWithoutSubLocalityNestedInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutSubLocalityInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutSubLocalityInput>>;
  createMany?: InputMaybe<AddressCreateManySubLocalityInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AddressUpdateWithWhereUniqueWithoutSubLocalityInput>
  >;
  updateMany?: InputMaybe<
    Array<AddressUpdateManyWithWhereWithoutSubLocalityInput>
  >;
  upsert?: InputMaybe<
    Array<AddressUpsertWithWhereUniqueWithoutSubLocalityInput>
  >;
};

export type AddressUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutUserInput>>;
  createMany?: InputMaybe<AddressCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<Array<AddressUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<AddressUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<AddressUpsertWithWhereUniqueWithoutUserInput>>;
};

export type AddressUpdateOneRequiredWithoutCollectionPointNestedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutCollectionPointInput>;
  create?: InputMaybe<AddressCreateWithoutCollectionPointInput>;
  update?: InputMaybe<AddressUpdateToOneWithWhereWithoutCollectionPointInput>;
  upsert?: InputMaybe<AddressUpsertWithoutCollectionPointInput>;
};

export type AddressUpdateOneRequiredWithoutSchoolNestedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutSchoolInput>;
  create?: InputMaybe<AddressCreateWithoutSchoolInput>;
  update?: InputMaybe<AddressUpdateToOneWithWhereWithoutSchoolInput>;
  upsert?: InputMaybe<AddressUpsertWithoutSchoolInput>;
};

export type AddressUpdateOneWithoutServiceProviderNestedInput = {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutServiceProviderInput>;
  create?: InputMaybe<AddressCreateWithoutServiceProviderInput>;
  delete?: InputMaybe<AddressWhereInput>;
  disconnect?: InputMaybe<AddressWhereInput>;
  update?: InputMaybe<AddressUpdateToOneWithWhereWithoutServiceProviderInput>;
  upsert?: InputMaybe<AddressUpsertWithoutServiceProviderInput>;
};

export type AddressUpdateToOneWithWhereWithoutCollectionPointInput = {
  data: AddressUpdateWithoutCollectionPointInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpdateToOneWithWhereWithoutSchoolInput = {
  data: AddressUpdateWithoutSchoolInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpdateToOneWithWhereWithoutServiceProviderInput = {
  data: AddressUpdateWithoutServiceProviderInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpdateWithWhereUniqueWithoutAdministrativeAreaInput = {
  data: AddressUpdateWithoutAdministrativeAreaInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithWhereUniqueWithoutCountryInput = {
  data: AddressUpdateWithoutCountryInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithWhereUniqueWithoutLocalityInput = {
  data: AddressUpdateWithoutLocalityInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithWhereUniqueWithoutSubLocalityInput = {
  data: AddressUpdateWithoutSubLocalityInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithWhereUniqueWithoutUserInput = {
  data: AddressUpdateWithoutUserInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithoutAdministrativeAreaInput = {
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutAddressNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutAddressNestedInput>;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityUpdateOneWithoutAddressNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiUpdateOneWithoutAddressNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutAddressNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutAddressNestedInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityUpdateOneWithoutAddressNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressUpdateWithoutCollectionPointInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaUpdateOneWithoutAddressesNestedInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutAddressNestedInput>;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityUpdateOneWithoutAddressNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiUpdateOneWithoutAddressNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutAddressNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutAddressNestedInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityUpdateOneWithoutAddressNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressUpdateWithoutCountryInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaUpdateOneWithoutAddressesNestedInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutAddressNestedInput>;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityUpdateOneWithoutAddressNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiUpdateOneWithoutAddressNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutAddressNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutAddressNestedInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityUpdateOneWithoutAddressNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressUpdateWithoutLocalityInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaUpdateOneWithoutAddressesNestedInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutAddressNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutAddressNestedInput>;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiUpdateOneWithoutAddressNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutAddressNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutAddressNestedInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityUpdateOneWithoutAddressNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressUpdateWithoutSchoolInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaUpdateOneWithoutAddressesNestedInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutAddressNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutAddressNestedInput>;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityUpdateOneWithoutAddressNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiUpdateOneWithoutAddressNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutAddressNestedInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityUpdateOneWithoutAddressNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressUpdateWithoutServiceProviderInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaUpdateOneWithoutAddressesNestedInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutAddressNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutAddressNestedInput>;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityUpdateOneWithoutAddressNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiUpdateOneWithoutAddressNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutAddressNestedInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityUpdateOneWithoutAddressNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressUpdateWithoutSubLocalityInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaUpdateOneWithoutAddressesNestedInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutAddressNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutAddressNestedInput>;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityUpdateOneWithoutAddressNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiUpdateOneWithoutAddressNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutAddressNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutAddressNestedInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutAddressesNestedInput>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressUpdateWithoutUserInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaUpdateOneWithoutAddressesNestedInput>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  collectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutAddressNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutAddressNestedInput>;
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  locality?: InputMaybe<LocalityUpdateOneWithoutAddressNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  poi?: InputMaybe<PoiUpdateOneWithoutAddressNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutAddressNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutAddressNestedInput>;
  source?: InputMaybe<AddressSource>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocality?: InputMaybe<SubLocalityUpdateOneWithoutAddressNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressUpsertWithWhereUniqueWithoutAdministrativeAreaInput = {
  create: AddressCreateWithoutAdministrativeAreaInput;
  update: AddressUpdateWithoutAdministrativeAreaInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithWhereUniqueWithoutCountryInput = {
  create: AddressCreateWithoutCountryInput;
  update: AddressUpdateWithoutCountryInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithWhereUniqueWithoutLocalityInput = {
  create: AddressCreateWithoutLocalityInput;
  update: AddressUpdateWithoutLocalityInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithWhereUniqueWithoutSubLocalityInput = {
  create: AddressCreateWithoutSubLocalityInput;
  update: AddressUpdateWithoutSubLocalityInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithWhereUniqueWithoutUserInput = {
  create: AddressCreateWithoutUserInput;
  update: AddressUpdateWithoutUserInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithoutCollectionPointInput = {
  create: AddressCreateWithoutCollectionPointInput;
  update: AddressUpdateWithoutCollectionPointInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpsertWithoutSchoolInput = {
  create: AddressCreateWithoutSchoolInput;
  update: AddressUpdateWithoutSchoolInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressUpsertWithoutServiceProviderInput = {
  create: AddressCreateWithoutServiceProviderInput;
  update: AddressUpdateWithoutServiceProviderInput;
  where?: InputMaybe<AddressWhereInput>;
};

export type AddressWhereInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  administrativeArea?: InputMaybe<AdministrativeAreaRelationFilter>;
  administrativeAreaId?: InputMaybe<StringFilter>;
  block?: InputMaybe<StringFilter>;
  building?: InputMaybe<StringFilter>;
  buildingType?: InputMaybe<EnumBuildingTypeFilter>;
  collectionPoint?: InputMaybe<CollectionPointRelationFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  flat?: InputMaybe<StringFilter>;
  floor?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPrimary?: InputMaybe<BoolFilter>;
  locality?: InputMaybe<LocalityRelationFilter>;
  localityId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  poi?: InputMaybe<PoiRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  source?: InputMaybe<EnumAddressSourceFilter>;
  street?: InputMaybe<StringFilter>;
  subLocality?: InputMaybe<SubLocalityRelationFilter>;
  subLocalityId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  way?: InputMaybe<StringFilter>;
};

export type AddressWhereUniqueInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  administrativeArea?: InputMaybe<AdministrativeAreaRelationFilter>;
  administrativeAreaId?: InputMaybe<StringFilter>;
  block?: InputMaybe<StringFilter>;
  building?: InputMaybe<StringFilter>;
  buildingType?: InputMaybe<EnumBuildingTypeFilter>;
  collectionPoint?: InputMaybe<CollectionPointRelationFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  flat?: InputMaybe<StringFilter>;
  floor?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<BoolFilter>;
  locality?: InputMaybe<LocalityRelationFilter>;
  localityId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  poi?: InputMaybe<PoiRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  source?: InputMaybe<EnumAddressSourceFilter>;
  street?: InputMaybe<StringFilter>;
  subLocality?: InputMaybe<SubLocalityRelationFilter>;
  subLocalityId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  way?: InputMaybe<StringFilter>;
};

export type AddressesInfoFilterInput = {
  skip?: InputMaybe<Scalars["Float"]["input"]>;
  take?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AdhocNotification = {
  __typename?: "AdhocNotification";
  _count: AdhocNotificationCount;
  channel: AdhocNotificationChannel;
  countries: Scalars["JSON"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  dateSent: Scalars["DateTime"]["output"];
  direction?: Maybe<Array<RouteDirectionality>>;
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  messageAr: Scalars["String"]["output"];
  paymentStatus?: Maybe<Array<PaymentStatus>>;
  registrationStatus?: Maybe<Array<SchoolRegistrationInternalStatus>>;
  routes: Scalars["JSON"]["output"];
  schoolRegistrationIds?: Maybe<Scalars["JSON"]["output"]>;
  schools: Scalars["JSON"]["output"];
  students?: Maybe<Array<AdhocNotificationStudentUser>>;
  title: Scalars["String"]["output"];
  titleAr: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  users?: Maybe<Array<AdhocNotificationSponsorUser>>;
};

export enum AdhocNotificationChannel {
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
  SMS = "SMS",
  SMS_AND_PUSH_NOTIFICATION = "SMS_AND_PUSH_NOTIFICATION",
}

export type AdhocNotificationCount = {
  __typename?: "AdhocNotificationCount";
  students: Scalars["Int"]["output"];
  users: Scalars["Int"]["output"];
};

export type AdhocNotificationCreateNestedOneWithoutStudentsInput = {
  connect?: InputMaybe<AdhocNotificationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdhocNotificationCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<AdhocNotificationCreateWithoutStudentsInput>;
};

export type AdhocNotificationCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<AdhocNotificationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdhocNotificationCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<AdhocNotificationCreateWithoutUsersInput>;
};

export type AdhocNotificationCreateOrConnectWithoutStudentsInput = {
  create: AdhocNotificationCreateWithoutStudentsInput;
  where: AdhocNotificationWhereUniqueInput;
};

export type AdhocNotificationCreateOrConnectWithoutUsersInput = {
  create: AdhocNotificationCreateWithoutUsersInput;
  where: AdhocNotificationWhereUniqueInput;
};

export type AdhocNotificationCreateWithoutStudentsInput = {
  channel: AdhocNotificationChannel;
  countries: Scalars["JSON"]["input"];
  dateSent?: InputMaybe<Scalars["DateTime"]["input"]>;
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  messageAr: Scalars["String"]["input"];
  paymentStatus?: InputMaybe<Array<PaymentStatus>>;
  registrationStatus?: InputMaybe<Array<SchoolRegistrationInternalStatus>>;
  routes: Scalars["JSON"]["input"];
  schoolRegistrationIds?: InputMaybe<Scalars["JSON"]["input"]>;
  schools: Scalars["JSON"]["input"];
  title: Scalars["String"]["input"];
  titleAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutNotificationInput>;
};

export type AdhocNotificationCreateWithoutUsersInput = {
  channel: AdhocNotificationChannel;
  countries: Scalars["JSON"]["input"];
  dateSent?: InputMaybe<Scalars["DateTime"]["input"]>;
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  messageAr: Scalars["String"]["input"];
  paymentStatus?: InputMaybe<Array<PaymentStatus>>;
  registrationStatus?: InputMaybe<Array<SchoolRegistrationInternalStatus>>;
  routes: Scalars["JSON"]["input"];
  schoolRegistrationIds?: InputMaybe<Scalars["JSON"]["input"]>;
  schools: Scalars["JSON"]["input"];
  students?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutNotificationInput>;
  title: Scalars["String"]["input"];
  titleAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationRelationFilter = {
  is?: InputMaybe<AdhocNotificationWhereInput>;
  isNot?: InputMaybe<AdhocNotificationWhereInput>;
};

export type AdhocNotificationSponsorUser = {
  __typename?: "AdhocNotificationSponsorUser";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  notification: AdhocNotification;
  notificationId: Scalars["String"]["output"];
  sponsorUser: User;
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["String"]["output"];
};

export type AdhocNotificationSponsorUserCreateManyNotificationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type AdhocNotificationSponsorUserCreateManyNotificationInputEnvelope = {
  data: Array<AdhocNotificationSponsorUserCreateManyNotificationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AdhocNotificationSponsorUserCreateManySponsorUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notificationId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationSponsorUserCreateManySponsorUserInputEnvelope = {
  data: Array<AdhocNotificationSponsorUserCreateManySponsorUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AdhocNotificationSponsorUserCreateNestedManyWithoutNotificationInput =
  {
    connect?: InputMaybe<Array<AdhocNotificationSponsorUserWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<AdhocNotificationSponsorUserCreateOrConnectWithoutNotificationInput>
    >;
    create?: InputMaybe<
      Array<AdhocNotificationSponsorUserCreateWithoutNotificationInput>
    >;
    createMany?: InputMaybe<AdhocNotificationSponsorUserCreateManyNotificationInputEnvelope>;
  };

export type AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput =
  {
    connect?: InputMaybe<Array<AdhocNotificationSponsorUserWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<AdhocNotificationSponsorUserCreateOrConnectWithoutSponsorUserInput>
    >;
    create?: InputMaybe<
      Array<AdhocNotificationSponsorUserCreateWithoutSponsorUserInput>
    >;
    createMany?: InputMaybe<AdhocNotificationSponsorUserCreateManySponsorUserInputEnvelope>;
  };

export type AdhocNotificationSponsorUserCreateOrConnectWithoutNotificationInput =
  {
    create: AdhocNotificationSponsorUserCreateWithoutNotificationInput;
    where: AdhocNotificationSponsorUserWhereUniqueInput;
  };

export type AdhocNotificationSponsorUserCreateOrConnectWithoutSponsorUserInput =
  {
    create: AdhocNotificationSponsorUserCreateWithoutSponsorUserInput;
    where: AdhocNotificationSponsorUserWhereUniqueInput;
  };

export type AdhocNotificationSponsorUserCreateWithoutNotificationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  sponsorUser: UserCreateNestedOneWithoutAdhocNotificationsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationSponsorUserCreateWithoutSponsorUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notification: AdhocNotificationCreateNestedOneWithoutUsersInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationSponsorUserListRelationFilter = {
  every?: InputMaybe<AdhocNotificationSponsorUserWhereInput>;
  none?: InputMaybe<AdhocNotificationSponsorUserWhereInput>;
  some?: InputMaybe<AdhocNotificationSponsorUserWhereInput>;
};

export type AdhocNotificationSponsorUserScalarWhereInput = {
  AND?: InputMaybe<Array<AdhocNotificationSponsorUserScalarWhereInput>>;
  NOT?: InputMaybe<Array<AdhocNotificationSponsorUserScalarWhereInput>>;
  OR?: InputMaybe<Array<AdhocNotificationSponsorUserScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  notificationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AdhocNotificationSponsorUserUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationSponsorUserUpdateManyWithWhereWithoutNotificationInput =
  {
    data: AdhocNotificationSponsorUserUpdateManyMutationInput;
    where: AdhocNotificationSponsorUserScalarWhereInput;
  };

export type AdhocNotificationSponsorUserUpdateManyWithWhereWithoutSponsorUserInput =
  {
    data: AdhocNotificationSponsorUserUpdateManyMutationInput;
    where: AdhocNotificationSponsorUserScalarWhereInput;
  };

export type AdhocNotificationSponsorUserUpdateManyWithoutNotificationNestedInput =
  {
    connect?: InputMaybe<Array<AdhocNotificationSponsorUserWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<AdhocNotificationSponsorUserCreateOrConnectWithoutNotificationInput>
    >;
    create?: InputMaybe<
      Array<AdhocNotificationSponsorUserCreateWithoutNotificationInput>
    >;
    createMany?: InputMaybe<AdhocNotificationSponsorUserCreateManyNotificationInputEnvelope>;
    delete?: InputMaybe<Array<AdhocNotificationSponsorUserWhereUniqueInput>>;
    deleteMany?: InputMaybe<
      Array<AdhocNotificationSponsorUserScalarWhereInput>
    >;
    disconnect?: InputMaybe<
      Array<AdhocNotificationSponsorUserWhereUniqueInput>
    >;
    set?: InputMaybe<Array<AdhocNotificationSponsorUserWhereUniqueInput>>;
    update?: InputMaybe<
      Array<AdhocNotificationSponsorUserUpdateWithWhereUniqueWithoutNotificationInput>
    >;
    updateMany?: InputMaybe<
      Array<AdhocNotificationSponsorUserUpdateManyWithWhereWithoutNotificationInput>
    >;
    upsert?: InputMaybe<
      Array<AdhocNotificationSponsorUserUpsertWithWhereUniqueWithoutNotificationInput>
    >;
  };

export type AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput =
  {
    connect?: InputMaybe<Array<AdhocNotificationSponsorUserWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<AdhocNotificationSponsorUserCreateOrConnectWithoutSponsorUserInput>
    >;
    create?: InputMaybe<
      Array<AdhocNotificationSponsorUserCreateWithoutSponsorUserInput>
    >;
    createMany?: InputMaybe<AdhocNotificationSponsorUserCreateManySponsorUserInputEnvelope>;
    delete?: InputMaybe<Array<AdhocNotificationSponsorUserWhereUniqueInput>>;
    deleteMany?: InputMaybe<
      Array<AdhocNotificationSponsorUserScalarWhereInput>
    >;
    disconnect?: InputMaybe<
      Array<AdhocNotificationSponsorUserWhereUniqueInput>
    >;
    set?: InputMaybe<Array<AdhocNotificationSponsorUserWhereUniqueInput>>;
    update?: InputMaybe<
      Array<AdhocNotificationSponsorUserUpdateWithWhereUniqueWithoutSponsorUserInput>
    >;
    updateMany?: InputMaybe<
      Array<AdhocNotificationSponsorUserUpdateManyWithWhereWithoutSponsorUserInput>
    >;
    upsert?: InputMaybe<
      Array<AdhocNotificationSponsorUserUpsertWithWhereUniqueWithoutSponsorUserInput>
    >;
  };

export type AdhocNotificationSponsorUserUpdateWithWhereUniqueWithoutNotificationInput =
  {
    data: AdhocNotificationSponsorUserUpdateWithoutNotificationInput;
    where: AdhocNotificationSponsorUserWhereUniqueInput;
  };

export type AdhocNotificationSponsorUserUpdateWithWhereUniqueWithoutSponsorUserInput =
  {
    data: AdhocNotificationSponsorUserUpdateWithoutSponsorUserInput;
    where: AdhocNotificationSponsorUserWhereUniqueInput;
  };

export type AdhocNotificationSponsorUserUpdateWithoutNotificationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  sponsorUser?: InputMaybe<UserUpdateOneRequiredWithoutAdhocNotificationsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationSponsorUserUpdateWithoutSponsorUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notification?: InputMaybe<AdhocNotificationUpdateOneRequiredWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationSponsorUserUpsertWithWhereUniqueWithoutNotificationInput =
  {
    create: AdhocNotificationSponsorUserCreateWithoutNotificationInput;
    update: AdhocNotificationSponsorUserUpdateWithoutNotificationInput;
    where: AdhocNotificationSponsorUserWhereUniqueInput;
  };

export type AdhocNotificationSponsorUserUpsertWithWhereUniqueWithoutSponsorUserInput =
  {
    create: AdhocNotificationSponsorUserCreateWithoutSponsorUserInput;
    update: AdhocNotificationSponsorUserUpdateWithoutSponsorUserInput;
    where: AdhocNotificationSponsorUserWhereUniqueInput;
  };

export type AdhocNotificationSponsorUserWhereInput = {
  AND?: InputMaybe<Array<AdhocNotificationSponsorUserWhereInput>>;
  NOT?: InputMaybe<Array<AdhocNotificationSponsorUserWhereInput>>;
  OR?: InputMaybe<Array<AdhocNotificationSponsorUserWhereInput>>;
  id?: InputMaybe<StringFilter>;
  notification?: InputMaybe<AdhocNotificationRelationFilter>;
  notificationId?: InputMaybe<StringFilter>;
  sponsorUser?: InputMaybe<UserRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AdhocNotificationSponsorUserWhereUniqueInput = {
  AND?: InputMaybe<Array<AdhocNotificationSponsorUserWhereInput>>;
  NOT?: InputMaybe<Array<AdhocNotificationSponsorUserWhereInput>>;
  OR?: InputMaybe<Array<AdhocNotificationSponsorUserWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notification?: InputMaybe<AdhocNotificationRelationFilter>;
  notificationId?: InputMaybe<StringFilter>;
  sponsorUser?: InputMaybe<UserRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AdhocNotificationStudentUser = {
  __typename?: "AdhocNotificationStudentUser";
  createdAt: Scalars["DateTime"]["output"];
  direction?: Maybe<Array<RouteDirectionality>>;
  id: Scalars["String"]["output"];
  notification: AdhocNotification;
  notificationId: Scalars["String"]["output"];
  paymentStatus: PaymentStatus;
  registrationStatus: SchoolRegistrationInternalStatus;
  routes?: Maybe<Scalars["JSON"]["output"]>;
  school: School;
  schoolId: Scalars["String"]["output"];
  studentUser: User;
  updatedAt: Scalars["DateTime"]["output"];
  userId: Scalars["String"]["output"];
};

export type AdhocNotificationStudentUserCreateManyNotificationInput = {
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus: PaymentStatus;
  registrationStatus: SchoolRegistrationInternalStatus;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  schoolId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type AdhocNotificationStudentUserCreateManyNotificationInputEnvelope = {
  data: Array<AdhocNotificationStudentUserCreateManyNotificationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AdhocNotificationStudentUserCreateManySchoolInput = {
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notificationId: Scalars["String"]["input"];
  paymentStatus: PaymentStatus;
  registrationStatus: SchoolRegistrationInternalStatus;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type AdhocNotificationStudentUserCreateManySchoolInputEnvelope = {
  data: Array<AdhocNotificationStudentUserCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AdhocNotificationStudentUserCreateManyStudentUserInput = {
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notificationId: Scalars["String"]["input"];
  paymentStatus: PaymentStatus;
  registrationStatus: SchoolRegistrationInternalStatus;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  schoolId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationStudentUserCreateManyStudentUserInputEnvelope = {
  data: Array<AdhocNotificationStudentUserCreateManyStudentUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AdhocNotificationStudentUserCreateNestedManyWithoutNotificationInput =
  {
    connect?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<AdhocNotificationStudentUserCreateOrConnectWithoutNotificationInput>
    >;
    create?: InputMaybe<
      Array<AdhocNotificationStudentUserCreateWithoutNotificationInput>
    >;
    createMany?: InputMaybe<AdhocNotificationStudentUserCreateManyNotificationInputEnvelope>;
  };

export type AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AdhocNotificationStudentUserCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<
    Array<AdhocNotificationStudentUserCreateWithoutSchoolInput>
  >;
  createMany?: InputMaybe<AdhocNotificationStudentUserCreateManySchoolInputEnvelope>;
};

export type AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput =
  {
    connect?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<AdhocNotificationStudentUserCreateOrConnectWithoutStudentUserInput>
    >;
    create?: InputMaybe<
      Array<AdhocNotificationStudentUserCreateWithoutStudentUserInput>
    >;
    createMany?: InputMaybe<AdhocNotificationStudentUserCreateManyStudentUserInputEnvelope>;
  };

export type AdhocNotificationStudentUserCreateOrConnectWithoutNotificationInput =
  {
    create: AdhocNotificationStudentUserCreateWithoutNotificationInput;
    where: AdhocNotificationStudentUserWhereUniqueInput;
  };

export type AdhocNotificationStudentUserCreateOrConnectWithoutSchoolInput = {
  create: AdhocNotificationStudentUserCreateWithoutSchoolInput;
  where: AdhocNotificationStudentUserWhereUniqueInput;
};

export type AdhocNotificationStudentUserCreateOrConnectWithoutStudentUserInput =
  {
    create: AdhocNotificationStudentUserCreateWithoutStudentUserInput;
    where: AdhocNotificationStudentUserWhereUniqueInput;
  };

export type AdhocNotificationStudentUserCreateWithoutNotificationInput = {
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus: PaymentStatus;
  registrationStatus: SchoolRegistrationInternalStatus;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  school: SchoolCreateNestedOneWithoutAdhocNotificationStudentUsersInput;
  studentUser: UserCreateNestedOneWithoutAdhocNotificationStudentUsersInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationStudentUserCreateWithoutSchoolInput = {
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notification: AdhocNotificationCreateNestedOneWithoutStudentsInput;
  paymentStatus: PaymentStatus;
  registrationStatus: SchoolRegistrationInternalStatus;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  studentUser: UserCreateNestedOneWithoutAdhocNotificationStudentUsersInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationStudentUserCreateWithoutStudentUserInput = {
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notification: AdhocNotificationCreateNestedOneWithoutStudentsInput;
  paymentStatus: PaymentStatus;
  registrationStatus: SchoolRegistrationInternalStatus;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  school: SchoolCreateNestedOneWithoutAdhocNotificationStudentUsersInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationStudentUserListRelationFilter = {
  every?: InputMaybe<AdhocNotificationStudentUserWhereInput>;
  none?: InputMaybe<AdhocNotificationStudentUserWhereInput>;
  some?: InputMaybe<AdhocNotificationStudentUserWhereInput>;
};

export type AdhocNotificationStudentUserScalarWhereInput = {
  AND?: InputMaybe<Array<AdhocNotificationStudentUserScalarWhereInput>>;
  NOT?: InputMaybe<Array<AdhocNotificationStudentUserScalarWhereInput>>;
  OR?: InputMaybe<Array<AdhocNotificationStudentUserScalarWhereInput>>;
  direction?: InputMaybe<EnumRouteDirectionalityListFilter>;
  id?: InputMaybe<StringFilter>;
  notificationId?: InputMaybe<StringFilter>;
  paymentStatus?: InputMaybe<EnumPaymentStatusFilter>;
  registrationStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  routes?: InputMaybe<JsonFilter>;
  schoolId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AdhocNotificationStudentUserUpdateManyMutationInput = {
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  registrationStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationStudentUserUpdateManyWithWhereWithoutNotificationInput =
  {
    data: AdhocNotificationStudentUserUpdateManyMutationInput;
    where: AdhocNotificationStudentUserScalarWhereInput;
  };

export type AdhocNotificationStudentUserUpdateManyWithWhereWithoutSchoolInput =
  {
    data: AdhocNotificationStudentUserUpdateManyMutationInput;
    where: AdhocNotificationStudentUserScalarWhereInput;
  };

export type AdhocNotificationStudentUserUpdateManyWithWhereWithoutStudentUserInput =
  {
    data: AdhocNotificationStudentUserUpdateManyMutationInput;
    where: AdhocNotificationStudentUserScalarWhereInput;
  };

export type AdhocNotificationStudentUserUpdateManyWithoutNotificationNestedInput =
  {
    connect?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<AdhocNotificationStudentUserCreateOrConnectWithoutNotificationInput>
    >;
    create?: InputMaybe<
      Array<AdhocNotificationStudentUserCreateWithoutNotificationInput>
    >;
    createMany?: InputMaybe<AdhocNotificationStudentUserCreateManyNotificationInputEnvelope>;
    delete?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
    deleteMany?: InputMaybe<
      Array<AdhocNotificationStudentUserScalarWhereInput>
    >;
    disconnect?: InputMaybe<
      Array<AdhocNotificationStudentUserWhereUniqueInput>
    >;
    set?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
    update?: InputMaybe<
      Array<AdhocNotificationStudentUserUpdateWithWhereUniqueWithoutNotificationInput>
    >;
    updateMany?: InputMaybe<
      Array<AdhocNotificationStudentUserUpdateManyWithWhereWithoutNotificationInput>
    >;
    upsert?: InputMaybe<
      Array<AdhocNotificationStudentUserUpsertWithWhereUniqueWithoutNotificationInput>
    >;
  };

export type AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AdhocNotificationStudentUserCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<
    Array<AdhocNotificationStudentUserCreateWithoutSchoolInput>
  >;
  createMany?: InputMaybe<AdhocNotificationStudentUserCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AdhocNotificationStudentUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
  set?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AdhocNotificationStudentUserUpdateWithWhereUniqueWithoutSchoolInput>
  >;
  updateMany?: InputMaybe<
    Array<AdhocNotificationStudentUserUpdateManyWithWhereWithoutSchoolInput>
  >;
  upsert?: InputMaybe<
    Array<AdhocNotificationStudentUserUpsertWithWhereUniqueWithoutSchoolInput>
  >;
};

export type AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput =
  {
    connect?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<AdhocNotificationStudentUserCreateOrConnectWithoutStudentUserInput>
    >;
    create?: InputMaybe<
      Array<AdhocNotificationStudentUserCreateWithoutStudentUserInput>
    >;
    createMany?: InputMaybe<AdhocNotificationStudentUserCreateManyStudentUserInputEnvelope>;
    delete?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
    deleteMany?: InputMaybe<
      Array<AdhocNotificationStudentUserScalarWhereInput>
    >;
    disconnect?: InputMaybe<
      Array<AdhocNotificationStudentUserWhereUniqueInput>
    >;
    set?: InputMaybe<Array<AdhocNotificationStudentUserWhereUniqueInput>>;
    update?: InputMaybe<
      Array<AdhocNotificationStudentUserUpdateWithWhereUniqueWithoutStudentUserInput>
    >;
    updateMany?: InputMaybe<
      Array<AdhocNotificationStudentUserUpdateManyWithWhereWithoutStudentUserInput>
    >;
    upsert?: InputMaybe<
      Array<AdhocNotificationStudentUserUpsertWithWhereUniqueWithoutStudentUserInput>
    >;
  };

export type AdhocNotificationStudentUserUpdateWithWhereUniqueWithoutNotificationInput =
  {
    data: AdhocNotificationStudentUserUpdateWithoutNotificationInput;
    where: AdhocNotificationStudentUserWhereUniqueInput;
  };

export type AdhocNotificationStudentUserUpdateWithWhereUniqueWithoutSchoolInput =
  {
    data: AdhocNotificationStudentUserUpdateWithoutSchoolInput;
    where: AdhocNotificationStudentUserWhereUniqueInput;
  };

export type AdhocNotificationStudentUserUpdateWithWhereUniqueWithoutStudentUserInput =
  {
    data: AdhocNotificationStudentUserUpdateWithoutStudentUserInput;
    where: AdhocNotificationStudentUserWhereUniqueInput;
  };

export type AdhocNotificationStudentUserUpdateWithoutNotificationInput = {
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  registrationStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutAdhocNotificationStudentUsersNestedInput>;
  studentUser?: InputMaybe<UserUpdateOneRequiredWithoutAdhocNotificationStudentUsersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationStudentUserUpdateWithoutSchoolInput = {
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notification?: InputMaybe<AdhocNotificationUpdateOneRequiredWithoutStudentsNestedInput>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  registrationStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  studentUser?: InputMaybe<UserUpdateOneRequiredWithoutAdhocNotificationStudentUsersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationStudentUserUpdateWithoutStudentUserInput = {
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notification?: InputMaybe<AdhocNotificationUpdateOneRequiredWithoutStudentsNestedInput>;
  paymentStatus?: InputMaybe<PaymentStatus>;
  registrationStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutAdhocNotificationStudentUsersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationStudentUserUpsertWithWhereUniqueWithoutNotificationInput =
  {
    create: AdhocNotificationStudentUserCreateWithoutNotificationInput;
    update: AdhocNotificationStudentUserUpdateWithoutNotificationInput;
    where: AdhocNotificationStudentUserWhereUniqueInput;
  };

export type AdhocNotificationStudentUserUpsertWithWhereUniqueWithoutSchoolInput =
  {
    create: AdhocNotificationStudentUserCreateWithoutSchoolInput;
    update: AdhocNotificationStudentUserUpdateWithoutSchoolInput;
    where: AdhocNotificationStudentUserWhereUniqueInput;
  };

export type AdhocNotificationStudentUserUpsertWithWhereUniqueWithoutStudentUserInput =
  {
    create: AdhocNotificationStudentUserCreateWithoutStudentUserInput;
    update: AdhocNotificationStudentUserUpdateWithoutStudentUserInput;
    where: AdhocNotificationStudentUserWhereUniqueInput;
  };

export type AdhocNotificationStudentUserWhereInput = {
  AND?: InputMaybe<Array<AdhocNotificationStudentUserWhereInput>>;
  NOT?: InputMaybe<Array<AdhocNotificationStudentUserWhereInput>>;
  OR?: InputMaybe<Array<AdhocNotificationStudentUserWhereInput>>;
  direction?: InputMaybe<EnumRouteDirectionalityListFilter>;
  id?: InputMaybe<StringFilter>;
  notification?: InputMaybe<AdhocNotificationRelationFilter>;
  notificationId?: InputMaybe<StringFilter>;
  paymentStatus?: InputMaybe<EnumPaymentStatusFilter>;
  registrationStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  routes?: InputMaybe<JsonFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  studentUser?: InputMaybe<UserRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AdhocNotificationStudentUserWhereUniqueInput = {
  AND?: InputMaybe<Array<AdhocNotificationStudentUserWhereInput>>;
  NOT?: InputMaybe<Array<AdhocNotificationStudentUserWhereInput>>;
  OR?: InputMaybe<Array<AdhocNotificationStudentUserWhereInput>>;
  direction?: InputMaybe<EnumRouteDirectionalityListFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notification?: InputMaybe<AdhocNotificationRelationFilter>;
  notificationId?: InputMaybe<StringFilter>;
  paymentStatus?: InputMaybe<EnumPaymentStatusFilter>;
  registrationStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  routes?: InputMaybe<JsonFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  studentUser?: InputMaybe<UserRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AdhocNotificationUpdateOneRequiredWithoutStudentsNestedInput = {
  connect?: InputMaybe<AdhocNotificationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdhocNotificationCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<AdhocNotificationCreateWithoutStudentsInput>;
  update?: InputMaybe<AdhocNotificationUpdateToOneWithWhereWithoutStudentsInput>;
  upsert?: InputMaybe<AdhocNotificationUpsertWithoutStudentsInput>;
};

export type AdhocNotificationUpdateOneRequiredWithoutUsersNestedInput = {
  connect?: InputMaybe<AdhocNotificationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdhocNotificationCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<AdhocNotificationCreateWithoutUsersInput>;
  update?: InputMaybe<AdhocNotificationUpdateToOneWithWhereWithoutUsersInput>;
  upsert?: InputMaybe<AdhocNotificationUpsertWithoutUsersInput>;
};

export type AdhocNotificationUpdateToOneWithWhereWithoutStudentsInput = {
  data: AdhocNotificationUpdateWithoutStudentsInput;
  where?: InputMaybe<AdhocNotificationWhereInput>;
};

export type AdhocNotificationUpdateToOneWithWhereWithoutUsersInput = {
  data: AdhocNotificationUpdateWithoutUsersInput;
  where?: InputMaybe<AdhocNotificationWhereInput>;
};

export type AdhocNotificationUpdateWithoutStudentsInput = {
  channel?: InputMaybe<AdhocNotificationChannel>;
  countries?: InputMaybe<Scalars["JSON"]["input"]>;
  dateSent?: InputMaybe<Scalars["DateTime"]["input"]>;
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  messageAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<Array<PaymentStatus>>;
  registrationStatus?: InputMaybe<Array<SchoolRegistrationInternalStatus>>;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  schoolRegistrationIds?: InputMaybe<Scalars["JSON"]["input"]>;
  schools?: InputMaybe<Scalars["JSON"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutNotificationNestedInput>;
};

export type AdhocNotificationUpdateWithoutUsersInput = {
  channel?: InputMaybe<AdhocNotificationChannel>;
  countries?: InputMaybe<Scalars["JSON"]["input"]>;
  dateSent?: InputMaybe<Scalars["DateTime"]["input"]>;
  direction?: InputMaybe<Array<RouteDirectionality>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  messageAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<Array<PaymentStatus>>;
  registrationStatus?: InputMaybe<Array<SchoolRegistrationInternalStatus>>;
  routes?: InputMaybe<Scalars["JSON"]["input"]>;
  schoolRegistrationIds?: InputMaybe<Scalars["JSON"]["input"]>;
  schools?: InputMaybe<Scalars["JSON"]["input"]>;
  students?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutNotificationNestedInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdhocNotificationUpsertWithoutStudentsInput = {
  create: AdhocNotificationCreateWithoutStudentsInput;
  update: AdhocNotificationUpdateWithoutStudentsInput;
  where?: InputMaybe<AdhocNotificationWhereInput>;
};

export type AdhocNotificationUpsertWithoutUsersInput = {
  create: AdhocNotificationCreateWithoutUsersInput;
  update: AdhocNotificationUpdateWithoutUsersInput;
  where?: InputMaybe<AdhocNotificationWhereInput>;
};

export type AdhocNotificationWhereInput = {
  AND?: InputMaybe<Array<AdhocNotificationWhereInput>>;
  NOT?: InputMaybe<Array<AdhocNotificationWhereInput>>;
  OR?: InputMaybe<Array<AdhocNotificationWhereInput>>;
  channel?: InputMaybe<EnumAdhocNotificationChannelFilter>;
  countries?: InputMaybe<JsonFilter>;
  dateSent?: InputMaybe<DateTimeFilter>;
  direction?: InputMaybe<EnumRouteDirectionalityListFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  messageAr?: InputMaybe<StringFilter>;
  paymentStatus?: InputMaybe<EnumPaymentStatusListFilter>;
  registrationStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusListFilter>;
  routes?: InputMaybe<JsonFilter>;
  schoolRegistrationIds?: InputMaybe<JsonFilter>;
  schools?: InputMaybe<JsonFilter>;
  students?: InputMaybe<AdhocNotificationStudentUserListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  titleAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<AdhocNotificationSponsorUserListRelationFilter>;
};

export type AdhocNotificationWhereUniqueInput = {
  AND?: InputMaybe<Array<AdhocNotificationWhereInput>>;
  NOT?: InputMaybe<Array<AdhocNotificationWhereInput>>;
  OR?: InputMaybe<Array<AdhocNotificationWhereInput>>;
  channel?: InputMaybe<EnumAdhocNotificationChannelFilter>;
  countries?: InputMaybe<JsonFilter>;
  dateSent?: InputMaybe<DateTimeFilter>;
  direction?: InputMaybe<EnumRouteDirectionalityListFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<StringFilter>;
  messageAr?: InputMaybe<StringFilter>;
  paymentStatus?: InputMaybe<EnumPaymentStatusListFilter>;
  registrationStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusListFilter>;
  routes?: InputMaybe<JsonFilter>;
  schoolRegistrationIds?: InputMaybe<JsonFilter>;
  schools?: InputMaybe<JsonFilter>;
  students?: InputMaybe<AdhocNotificationStudentUserListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  titleAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<AdhocNotificationSponsorUserListRelationFilter>;
};

export type AdhocNotificationWithoutJsonTypes = {
  __typename?: "AdhocNotificationWithoutJSONTypes";
  _count: AdhocNotificationCount;
  channel: AdhocNotificationChannel;
  countries?: Maybe<Array<CountryForAdhocNotification>>;
  createdAt: Scalars["DateTime"]["output"];
  dateSent: Scalars["DateTime"]["output"];
  direction?: Maybe<Array<RouteDirectionality>>;
  id: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  messageAr: Scalars["String"]["output"];
  paymentStatus?: Maybe<Array<PaymentStatus>>;
  registrationStatus?: Maybe<Array<SchoolRegistrationInternalStatus>>;
  routes?: Maybe<Array<RouteForAdhocNotification>>;
  schoolRegistrationIds?: Maybe<Scalars["JSON"]["output"]>;
  schools?: Maybe<Array<SchoolForAdhocNotification>>;
  students?: Maybe<Array<AdhocNotificationStudentUser>>;
  title: Scalars["String"]["output"];
  titleAr: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  users?: Maybe<Array<AdhocNotificationSponsorUser>>;
};

export type AdministrativeArea = {
  __typename?: "AdministrativeArea";
  _count: AdministrativeAreaCount;
  addresses?: Maybe<Array<Address>>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  localities?: Maybe<Array<Locality>>;
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  order?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type AdministrativeAreaCount = {
  __typename?: "AdministrativeAreaCount";
  addresses: Scalars["Int"]["output"];
  localities: Scalars["Int"]["output"];
};

export type AdministrativeAreaCreateManyCountryInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  order?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdministrativeAreaCreateManyCountryInputEnvelope = {
  data: Array<AdministrativeAreaCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AdministrativeAreaCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<AdministrativeAreaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AdministrativeAreaCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<AdministrativeAreaCreateWithoutCountryInput>>;
  createMany?: InputMaybe<AdministrativeAreaCreateManyCountryInputEnvelope>;
};

export type AdministrativeAreaCreateNestedOneWithoutAddressesInput = {
  connect?: InputMaybe<AdministrativeAreaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdministrativeAreaCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<AdministrativeAreaCreateWithoutAddressesInput>;
};

export type AdministrativeAreaCreateNestedOneWithoutLocalitiesInput = {
  connect?: InputMaybe<AdministrativeAreaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdministrativeAreaCreateOrConnectWithoutLocalitiesInput>;
  create?: InputMaybe<AdministrativeAreaCreateWithoutLocalitiesInput>;
};

export type AdministrativeAreaCreateOrConnectWithoutAddressesInput = {
  create: AdministrativeAreaCreateWithoutAddressesInput;
  where: AdministrativeAreaWhereUniqueInput;
};

export type AdministrativeAreaCreateOrConnectWithoutCountryInput = {
  create: AdministrativeAreaCreateWithoutCountryInput;
  where: AdministrativeAreaWhereUniqueInput;
};

export type AdministrativeAreaCreateOrConnectWithoutLocalitiesInput = {
  create: AdministrativeAreaCreateWithoutLocalitiesInput;
  where: AdministrativeAreaWhereUniqueInput;
};

export type AdministrativeAreaCreateWithoutAddressesInput = {
  country: CountryCreateNestedOneWithoutAdministrativeAreasInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  localities?: InputMaybe<LocalityCreateNestedManyWithoutAdministrativeAreaInput>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  order?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdministrativeAreaCreateWithoutCountryInput = {
  addresses?: InputMaybe<AddressCreateNestedManyWithoutAdministrativeAreaInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  localities?: InputMaybe<LocalityCreateNestedManyWithoutAdministrativeAreaInput>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  order?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdministrativeAreaCreateWithoutLocalitiesInput = {
  addresses?: InputMaybe<AddressCreateNestedManyWithoutAdministrativeAreaInput>;
  country: CountryCreateNestedOneWithoutAdministrativeAreasInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  order?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdministrativeAreaListRelationFilter = {
  every?: InputMaybe<AdministrativeAreaWhereInput>;
  none?: InputMaybe<AdministrativeAreaWhereInput>;
  some?: InputMaybe<AdministrativeAreaWhereInput>;
};

export type AdministrativeAreaNameCountryIdCompoundUniqueInput = {
  countryId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type AdministrativeAreaRelationFilter = {
  is?: InputMaybe<AdministrativeAreaWhereInput>;
  isNot?: InputMaybe<AdministrativeAreaWhereInput>;
};

export type AdministrativeAreaResponsePayload = {
  __typename?: "AdministrativeAreaResponsePayload";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
};

export type AdministrativeAreaScalarWhereInput = {
  AND?: InputMaybe<Array<AdministrativeAreaScalarWhereInput>>;
  NOT?: InputMaybe<Array<AdministrativeAreaScalarWhereInput>>;
  OR?: InputMaybe<Array<AdministrativeAreaScalarWhereInput>>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdministrativeAreaUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdministrativeAreaUpdateManyWithWhereWithoutCountryInput = {
  data: AdministrativeAreaUpdateManyMutationInput;
  where: AdministrativeAreaScalarWhereInput;
};

export type AdministrativeAreaUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<AdministrativeAreaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AdministrativeAreaCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<AdministrativeAreaCreateWithoutCountryInput>>;
  createMany?: InputMaybe<AdministrativeAreaCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<AdministrativeAreaWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AdministrativeAreaScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AdministrativeAreaWhereUniqueInput>>;
  set?: InputMaybe<Array<AdministrativeAreaWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AdministrativeAreaUpdateWithWhereUniqueWithoutCountryInput>
  >;
  updateMany?: InputMaybe<
    Array<AdministrativeAreaUpdateManyWithWhereWithoutCountryInput>
  >;
  upsert?: InputMaybe<
    Array<AdministrativeAreaUpsertWithWhereUniqueWithoutCountryInput>
  >;
};

export type AdministrativeAreaUpdateOneRequiredWithoutLocalitiesNestedInput = {
  connect?: InputMaybe<AdministrativeAreaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdministrativeAreaCreateOrConnectWithoutLocalitiesInput>;
  create?: InputMaybe<AdministrativeAreaCreateWithoutLocalitiesInput>;
  update?: InputMaybe<AdministrativeAreaUpdateToOneWithWhereWithoutLocalitiesInput>;
  upsert?: InputMaybe<AdministrativeAreaUpsertWithoutLocalitiesInput>;
};

export type AdministrativeAreaUpdateOneWithoutAddressesNestedInput = {
  connect?: InputMaybe<AdministrativeAreaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdministrativeAreaCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<AdministrativeAreaCreateWithoutAddressesInput>;
  delete?: InputMaybe<AdministrativeAreaWhereInput>;
  disconnect?: InputMaybe<AdministrativeAreaWhereInput>;
  update?: InputMaybe<AdministrativeAreaUpdateToOneWithWhereWithoutAddressesInput>;
  upsert?: InputMaybe<AdministrativeAreaUpsertWithoutAddressesInput>;
};

export type AdministrativeAreaUpdateToOneWithWhereWithoutAddressesInput = {
  data: AdministrativeAreaUpdateWithoutAddressesInput;
  where?: InputMaybe<AdministrativeAreaWhereInput>;
};

export type AdministrativeAreaUpdateToOneWithWhereWithoutLocalitiesInput = {
  data: AdministrativeAreaUpdateWithoutLocalitiesInput;
  where?: InputMaybe<AdministrativeAreaWhereInput>;
};

export type AdministrativeAreaUpdateWithWhereUniqueWithoutCountryInput = {
  data: AdministrativeAreaUpdateWithoutCountryInput;
  where: AdministrativeAreaWhereUniqueInput;
};

export type AdministrativeAreaUpdateWithoutAddressesInput = {
  country?: InputMaybe<CountryUpdateOneRequiredWithoutAdministrativeAreasNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  localities?: InputMaybe<LocalityUpdateManyWithoutAdministrativeAreaNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdministrativeAreaUpdateWithoutCountryInput = {
  addresses?: InputMaybe<AddressUpdateManyWithoutAdministrativeAreaNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  localities?: InputMaybe<LocalityUpdateManyWithoutAdministrativeAreaNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdministrativeAreaUpdateWithoutLocalitiesInput = {
  addresses?: InputMaybe<AddressUpdateManyWithoutAdministrativeAreaNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutAdministrativeAreasNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AdministrativeAreaUpsertWithWhereUniqueWithoutCountryInput = {
  create: AdministrativeAreaCreateWithoutCountryInput;
  update: AdministrativeAreaUpdateWithoutCountryInput;
  where: AdministrativeAreaWhereUniqueInput;
};

export type AdministrativeAreaUpsertWithoutAddressesInput = {
  create: AdministrativeAreaCreateWithoutAddressesInput;
  update: AdministrativeAreaUpdateWithoutAddressesInput;
  where?: InputMaybe<AdministrativeAreaWhereInput>;
};

export type AdministrativeAreaUpsertWithoutLocalitiesInput = {
  create: AdministrativeAreaCreateWithoutLocalitiesInput;
  update: AdministrativeAreaUpdateWithoutLocalitiesInput;
  where?: InputMaybe<AdministrativeAreaWhereInput>;
};

export type AdministrativeAreaWhereInput = {
  AND?: InputMaybe<Array<AdministrativeAreaWhereInput>>;
  NOT?: InputMaybe<Array<AdministrativeAreaWhereInput>>;
  OR?: InputMaybe<Array<AdministrativeAreaWhereInput>>;
  addresses?: InputMaybe<AddressListRelationFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  localities?: InputMaybe<LocalityListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdministrativeAreaWhereUniqueInput = {
  AND?: InputMaybe<Array<AdministrativeAreaWhereInput>>;
  NOT?: InputMaybe<Array<AdministrativeAreaWhereInput>>;
  OR?: InputMaybe<Array<AdministrativeAreaWhereInput>>;
  addresses?: InputMaybe<AddressListRelationFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  localities?: InputMaybe<LocalityListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  name_countryId?: InputMaybe<AdministrativeAreaNameCountryIdCompoundUniqueInput>;
  order?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdministrativeAreasFilterInput = {
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Float"]["input"]>;
  take?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Agent = {
  __typename?: "Agent";
  _count: AgentCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  newAgentTripLogs?: Maybe<Array<TripLog>>;
  oldAgentTripLogs?: Maybe<Array<TripLog>>;
  routes?: Maybe<Array<RouteAgent>>;
  schoolRegistration?: Maybe<Array<SchoolRegistration>>;
  schoolRegistrationStatusChange?: Maybe<Array<SchoolRegistrationStatusChange>>;
  status: AgentStatus;
  terminatedBySchoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  trips?: Maybe<Array<Trip>>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type AgentCount = {
  __typename?: "AgentCount";
  newAgentTripLogs: Scalars["Int"]["output"];
  oldAgentTripLogs: Scalars["Int"]["output"];
  routes: Scalars["Int"]["output"];
  schoolRegistration: Scalars["Int"]["output"];
  schoolRegistrationStatusChange: Scalars["Int"]["output"];
  terminatedBySchoolRegistrations: Scalars["Int"]["output"];
  trips: Scalars["Int"]["output"];
};

export type AgentCreateNestedOneWithoutNewAgentTripLogsInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutNewAgentTripLogsInput>;
  create?: InputMaybe<AgentCreateWithoutNewAgentTripLogsInput>;
};

export type AgentCreateNestedOneWithoutOldAgentTripLogsInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutOldAgentTripLogsInput>;
  create?: InputMaybe<AgentCreateWithoutOldAgentTripLogsInput>;
};

export type AgentCreateNestedOneWithoutRoutesInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<AgentCreateWithoutRoutesInput>;
};

export type AgentCreateNestedOneWithoutSchoolRegistrationStatusChangeInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutSchoolRegistrationStatusChangeInput>;
  create?: InputMaybe<AgentCreateWithoutSchoolRegistrationStatusChangeInput>;
};

export type AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutTerminatedBySchoolRegistrationsInput>;
  create?: InputMaybe<AgentCreateWithoutTerminatedBySchoolRegistrationsInput>;
};

export type AgentCreateNestedOneWithoutTripsInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutTripsInput>;
  create?: InputMaybe<AgentCreateWithoutTripsInput>;
};

export type AgentCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<AgentCreateWithoutUserInput>;
};

export type AgentCreateOrConnectWithoutNewAgentTripLogsInput = {
  create: AgentCreateWithoutNewAgentTripLogsInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateOrConnectWithoutOldAgentTripLogsInput = {
  create: AgentCreateWithoutOldAgentTripLogsInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateOrConnectWithoutRoutesInput = {
  create: AgentCreateWithoutRoutesInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateOrConnectWithoutSchoolRegistrationStatusChangeInput = {
  create: AgentCreateWithoutSchoolRegistrationStatusChangeInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateOrConnectWithoutTerminatedBySchoolRegistrationsInput = {
  create: AgentCreateWithoutTerminatedBySchoolRegistrationsInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateOrConnectWithoutTripsInput = {
  create: AgentCreateWithoutTripsInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateOrConnectWithoutUserInput = {
  create: AgentCreateWithoutUserInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateWithoutNewAgentTripLogsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  oldAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldAgentInput>;
  routes?: InputMaybe<RouteAgentCreateNestedManyWithoutAgentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutAgentInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutAgentInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutTerminatedByInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutAgentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutAgentInput;
};

export type AgentCreateWithoutOldAgentTripLogsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewAgentInput>;
  routes?: InputMaybe<RouteAgentCreateNestedManyWithoutAgentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutAgentInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutAgentInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutTerminatedByInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutAgentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutAgentInput;
};

export type AgentCreateWithoutRoutesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewAgentInput>;
  oldAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldAgentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutAgentInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutAgentInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutTerminatedByInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutAgentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutAgentInput;
};

export type AgentCreateWithoutSchoolRegistrationStatusChangeInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewAgentInput>;
  oldAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldAgentInput>;
  routes?: InputMaybe<RouteAgentCreateNestedManyWithoutAgentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutAgentInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutTerminatedByInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutAgentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutAgentInput;
};

export type AgentCreateWithoutTerminatedBySchoolRegistrationsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewAgentInput>;
  oldAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldAgentInput>;
  routes?: InputMaybe<RouteAgentCreateNestedManyWithoutAgentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutAgentInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutAgentInput>;
  status?: InputMaybe<AgentStatus>;
  trips?: InputMaybe<TripCreateNestedManyWithoutAgentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutAgentInput;
};

export type AgentCreateWithoutTripsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewAgentInput>;
  oldAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldAgentInput>;
  routes?: InputMaybe<RouteAgentCreateNestedManyWithoutAgentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutAgentInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutAgentInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutTerminatedByInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutAgentInput;
};

export type AgentCreateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewAgentInput>;
  oldAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldAgentInput>;
  routes?: InputMaybe<RouteAgentCreateNestedManyWithoutAgentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutAgentInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutAgentInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutTerminatedByInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutAgentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AgentInfoExtraInfo = {
  __typename?: "AgentInfoExtraInfo";
  agentName?: Maybe<Scalars["String"]["output"]>;
  agentNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type AgentRelationFilter = {
  is?: InputMaybe<AgentWhereInput>;
  isNot?: InputMaybe<AgentWhereInput>;
};

export enum AgentStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type AgentUpdateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewAgentNestedInput>;
  oldAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldAgentNestedInput>;
  routes?: InputMaybe<RouteAgentUpdateManyWithoutAgentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutAgentNestedInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutAgentNestedInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutTerminatedByNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutAgentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAgentNestedInput>;
};

export type AgentUpdateOneRequiredWithoutRoutesNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<AgentCreateWithoutRoutesInput>;
  update?: InputMaybe<AgentUpdateToOneWithWhereWithoutRoutesInput>;
  upsert?: InputMaybe<AgentUpsertWithoutRoutesInput>;
};

export type AgentUpdateOneWithoutNewAgentTripLogsNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutNewAgentTripLogsInput>;
  create?: InputMaybe<AgentCreateWithoutNewAgentTripLogsInput>;
  delete?: InputMaybe<AgentWhereInput>;
  disconnect?: InputMaybe<AgentWhereInput>;
  update?: InputMaybe<AgentUpdateToOneWithWhereWithoutNewAgentTripLogsInput>;
  upsert?: InputMaybe<AgentUpsertWithoutNewAgentTripLogsInput>;
};

export type AgentUpdateOneWithoutOldAgentTripLogsNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutOldAgentTripLogsInput>;
  create?: InputMaybe<AgentCreateWithoutOldAgentTripLogsInput>;
  delete?: InputMaybe<AgentWhereInput>;
  disconnect?: InputMaybe<AgentWhereInput>;
  update?: InputMaybe<AgentUpdateToOneWithWhereWithoutOldAgentTripLogsInput>;
  upsert?: InputMaybe<AgentUpsertWithoutOldAgentTripLogsInput>;
};

export type AgentUpdateOneWithoutSchoolRegistrationStatusChangeNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutSchoolRegistrationStatusChangeInput>;
  create?: InputMaybe<AgentCreateWithoutSchoolRegistrationStatusChangeInput>;
  delete?: InputMaybe<AgentWhereInput>;
  disconnect?: InputMaybe<AgentWhereInput>;
  update?: InputMaybe<AgentUpdateToOneWithWhereWithoutSchoolRegistrationStatusChangeInput>;
  upsert?: InputMaybe<AgentUpsertWithoutSchoolRegistrationStatusChangeInput>;
};

export type AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutTerminatedBySchoolRegistrationsInput>;
  create?: InputMaybe<AgentCreateWithoutTerminatedBySchoolRegistrationsInput>;
  delete?: InputMaybe<AgentWhereInput>;
  disconnect?: InputMaybe<AgentWhereInput>;
  update?: InputMaybe<AgentUpdateToOneWithWhereWithoutTerminatedBySchoolRegistrationsInput>;
  upsert?: InputMaybe<AgentUpsertWithoutTerminatedBySchoolRegistrationsInput>;
};

export type AgentUpdateOneWithoutTripsNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutTripsInput>;
  create?: InputMaybe<AgentCreateWithoutTripsInput>;
  delete?: InputMaybe<AgentWhereInput>;
  disconnect?: InputMaybe<AgentWhereInput>;
  update?: InputMaybe<AgentUpdateToOneWithWhereWithoutTripsInput>;
  upsert?: InputMaybe<AgentUpsertWithoutTripsInput>;
};

export type AgentUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<AgentCreateWithoutUserInput>;
  delete?: InputMaybe<AgentWhereInput>;
  disconnect?: InputMaybe<AgentWhereInput>;
  update?: InputMaybe<AgentUpdateToOneWithWhereWithoutUserInput>;
  upsert?: InputMaybe<AgentUpsertWithoutUserInput>;
};

export type AgentUpdateToOneWithWhereWithoutNewAgentTripLogsInput = {
  data: AgentUpdateWithoutNewAgentTripLogsInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpdateToOneWithWhereWithoutOldAgentTripLogsInput = {
  data: AgentUpdateWithoutOldAgentTripLogsInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpdateToOneWithWhereWithoutRoutesInput = {
  data: AgentUpdateWithoutRoutesInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpdateToOneWithWhereWithoutSchoolRegistrationStatusChangeInput =
  {
    data: AgentUpdateWithoutSchoolRegistrationStatusChangeInput;
    where?: InputMaybe<AgentWhereInput>;
  };

export type AgentUpdateToOneWithWhereWithoutTerminatedBySchoolRegistrationsInput =
  {
    data: AgentUpdateWithoutTerminatedBySchoolRegistrationsInput;
    where?: InputMaybe<AgentWhereInput>;
  };

export type AgentUpdateToOneWithWhereWithoutTripsInput = {
  data: AgentUpdateWithoutTripsInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpdateToOneWithWhereWithoutUserInput = {
  data: AgentUpdateWithoutUserInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpdateWithoutNewAgentTripLogsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  oldAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldAgentNestedInput>;
  routes?: InputMaybe<RouteAgentUpdateManyWithoutAgentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutAgentNestedInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutAgentNestedInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutTerminatedByNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutAgentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAgentNestedInput>;
};

export type AgentUpdateWithoutOldAgentTripLogsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewAgentNestedInput>;
  routes?: InputMaybe<RouteAgentUpdateManyWithoutAgentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutAgentNestedInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutAgentNestedInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutTerminatedByNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutAgentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAgentNestedInput>;
};

export type AgentUpdateWithoutRoutesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewAgentNestedInput>;
  oldAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldAgentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutAgentNestedInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutAgentNestedInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutTerminatedByNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutAgentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAgentNestedInput>;
};

export type AgentUpdateWithoutSchoolRegistrationStatusChangeInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewAgentNestedInput>;
  oldAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldAgentNestedInput>;
  routes?: InputMaybe<RouteAgentUpdateManyWithoutAgentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutAgentNestedInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutTerminatedByNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutAgentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAgentNestedInput>;
};

export type AgentUpdateWithoutTerminatedBySchoolRegistrationsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewAgentNestedInput>;
  oldAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldAgentNestedInput>;
  routes?: InputMaybe<RouteAgentUpdateManyWithoutAgentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutAgentNestedInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutAgentNestedInput>;
  status?: InputMaybe<AgentStatus>;
  trips?: InputMaybe<TripUpdateManyWithoutAgentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAgentNestedInput>;
};

export type AgentUpdateWithoutTripsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewAgentNestedInput>;
  oldAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldAgentNestedInput>;
  routes?: InputMaybe<RouteAgentUpdateManyWithoutAgentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutAgentNestedInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutAgentNestedInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutTerminatedByNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAgentNestedInput>;
};

export type AgentUpdateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewAgentNestedInput>;
  oldAgentTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldAgentNestedInput>;
  routes?: InputMaybe<RouteAgentUpdateManyWithoutAgentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutAgentNestedInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutAgentNestedInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutTerminatedByNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutAgentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AgentUpsertWithoutNewAgentTripLogsInput = {
  create: AgentCreateWithoutNewAgentTripLogsInput;
  update: AgentUpdateWithoutNewAgentTripLogsInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpsertWithoutOldAgentTripLogsInput = {
  create: AgentCreateWithoutOldAgentTripLogsInput;
  update: AgentUpdateWithoutOldAgentTripLogsInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpsertWithoutRoutesInput = {
  create: AgentCreateWithoutRoutesInput;
  update: AgentUpdateWithoutRoutesInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpsertWithoutSchoolRegistrationStatusChangeInput = {
  create: AgentCreateWithoutSchoolRegistrationStatusChangeInput;
  update: AgentUpdateWithoutSchoolRegistrationStatusChangeInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpsertWithoutTerminatedBySchoolRegistrationsInput = {
  create: AgentCreateWithoutTerminatedBySchoolRegistrationsInput;
  update: AgentUpdateWithoutTerminatedBySchoolRegistrationsInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpsertWithoutTripsInput = {
  create: AgentCreateWithoutTripsInput;
  update: AgentUpdateWithoutTripsInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpsertWithoutUserInput = {
  create: AgentCreateWithoutUserInput;
  update: AgentUpdateWithoutUserInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentWhereInput = {
  AND?: InputMaybe<Array<AgentWhereInput>>;
  NOT?: InputMaybe<Array<AgentWhereInput>>;
  OR?: InputMaybe<Array<AgentWhereInput>>;
  id?: InputMaybe<StringFilter>;
  newAgentTripLogs?: InputMaybe<TripLogListRelationFilter>;
  oldAgentTripLogs?: InputMaybe<TripLogListRelationFilter>;
  routes?: InputMaybe<RouteAgentListRelationFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationListRelationFilter>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeListRelationFilter>;
  status?: InputMaybe<EnumAgentStatusFilter>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  trips?: InputMaybe<TripListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AgentWhereUniqueInput = {
  AND?: InputMaybe<Array<AgentWhereInput>>;
  NOT?: InputMaybe<Array<AgentWhereInput>>;
  OR?: InputMaybe<Array<AgentWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogListRelationFilter>;
  oldAgentTripLogs?: InputMaybe<TripLogListRelationFilter>;
  routes?: InputMaybe<RouteAgentListRelationFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationListRelationFilter>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeListRelationFilter>;
  status?: InputMaybe<EnumAgentStatusFilter>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  trips?: InputMaybe<TripListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type AgentWithIdentityProvider = {
  __typename?: "AgentWithIdentityProvider";
  _count: AgentCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isIdPManaged: Scalars["Boolean"]["output"];
  newAgentTripLogs?: Maybe<Array<TripLog>>;
  oldAgentTripLogs?: Maybe<Array<TripLog>>;
  routes?: Maybe<Array<RouteAgent>>;
  schoolRegistration?: Maybe<Array<SchoolRegistration>>;
  schoolRegistrationStatusChange?: Maybe<Array<SchoolRegistrationStatusChange>>;
  status: AgentStatus;
  terminatedBySchoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  trips?: Maybe<Array<Trip>>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type Alert = {
  __typename?: "Alert";
  alertLevel?: Maybe<AlertLevel>;
  alertType: AlertType;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  delayTripStopAlert?: Maybe<DelayTripStopAlert>;
  id: Scalars["String"]["output"];
  noPickUpAlert?: Maybe<NoPickUpAlert>;
  routeBusAttendantVacantAlert?: Maybe<RouteBusAttendantVacantAlert>;
  routeCapacityExceededAlert?: Maybe<RouteCapacityExceededAlert>;
  routeDateAlert?: Maybe<RouteDateAlert>;
  routeDriverVacantAlert?: Maybe<RouteDriverVacantAlert>;
  routeTimeLimitExceededAlert?: Maybe<RouteTimeLimitExceededAlert>;
  routeVehicleVacantAlert?: Maybe<RouteVehicleVacantAlert>;
  tripBusOverLoadedAlert?: Maybe<TripBusOverLoadedAlert>;
  tripDelayAlert?: Maybe<TripDelayAlert>;
  updatedAt: Scalars["DateTime"]["output"];
  vehicleSafetyEvent?: Maybe<VehicleSafetyEvent>;
  wrongDropOffLocation?: Maybe<WrongDropOffLocation>;
};

export type AlertAddedFilterInput = {
  alertLevel?: InputMaybe<EnumAlertLevelFilter>;
  alertType?: InputMaybe<EnumAlertTypeFilter>;
  vehicleSafetyEvent?: InputMaybe<AlertAddedVehicleSafetyEventFilterInput>;
};

export type AlertAddedVehicleSafetyEventFilterInput = {
  cause?: InputMaybe<EnumVehicleSafetyEventCauseFilter>;
  level?: InputMaybe<EnumVehicleSafetyEventLevelFilter>;
};

export type AlertCreateNestedOneWithoutDelayTripStopAlertInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutDelayTripStopAlertInput>;
  create?: InputMaybe<AlertCreateWithoutDelayTripStopAlertInput>;
};

export type AlertCreateNestedOneWithoutNoPickUpAlertInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutNoPickUpAlertInput>;
  create?: InputMaybe<AlertCreateWithoutNoPickUpAlertInput>;
};

export type AlertCreateNestedOneWithoutRouteBusAttendantVacantAlertInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteBusAttendantVacantAlertInput>;
  create?: InputMaybe<AlertCreateWithoutRouteBusAttendantVacantAlertInput>;
};

export type AlertCreateNestedOneWithoutRouteCapacityExceededAlertInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteCapacityExceededAlertInput>;
  create?: InputMaybe<AlertCreateWithoutRouteCapacityExceededAlertInput>;
};

export type AlertCreateNestedOneWithoutRouteDateAlertInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteDateAlertInput>;
  create?: InputMaybe<AlertCreateWithoutRouteDateAlertInput>;
};

export type AlertCreateNestedOneWithoutRouteDriverVacantAlertInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteDriverVacantAlertInput>;
  create?: InputMaybe<AlertCreateWithoutRouteDriverVacantAlertInput>;
};

export type AlertCreateNestedOneWithoutRouteTimeLimitExceededAlertInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteTimeLimitExceededAlertInput>;
  create?: InputMaybe<AlertCreateWithoutRouteTimeLimitExceededAlertInput>;
};

export type AlertCreateNestedOneWithoutRouteVehicleVacantAlertInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteVehicleVacantAlertInput>;
  create?: InputMaybe<AlertCreateWithoutRouteVehicleVacantAlertInput>;
};

export type AlertCreateNestedOneWithoutTripBusOverLoadedAlertInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutTripBusOverLoadedAlertInput>;
  create?: InputMaybe<AlertCreateWithoutTripBusOverLoadedAlertInput>;
};

export type AlertCreateNestedOneWithoutTripDelayAlertInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutTripDelayAlertInput>;
  create?: InputMaybe<AlertCreateWithoutTripDelayAlertInput>;
};

export type AlertCreateNestedOneWithoutVehicleSafetyEventInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutVehicleSafetyEventInput>;
  create?: InputMaybe<AlertCreateWithoutVehicleSafetyEventInput>;
};

export type AlertCreateNestedOneWithoutWrongDropOffLocationInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutWrongDropOffLocationInput>;
  create?: InputMaybe<AlertCreateWithoutWrongDropOffLocationInput>;
};

export type AlertCreateOrConnectWithoutDelayTripStopAlertInput = {
  create: AlertCreateWithoutDelayTripStopAlertInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateOrConnectWithoutNoPickUpAlertInput = {
  create: AlertCreateWithoutNoPickUpAlertInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateOrConnectWithoutRouteBusAttendantVacantAlertInput = {
  create: AlertCreateWithoutRouteBusAttendantVacantAlertInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateOrConnectWithoutRouteCapacityExceededAlertInput = {
  create: AlertCreateWithoutRouteCapacityExceededAlertInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateOrConnectWithoutRouteDateAlertInput = {
  create: AlertCreateWithoutRouteDateAlertInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateOrConnectWithoutRouteDriverVacantAlertInput = {
  create: AlertCreateWithoutRouteDriverVacantAlertInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateOrConnectWithoutRouteTimeLimitExceededAlertInput = {
  create: AlertCreateWithoutRouteTimeLimitExceededAlertInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateOrConnectWithoutRouteVehicleVacantAlertInput = {
  create: AlertCreateWithoutRouteVehicleVacantAlertInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateOrConnectWithoutTripBusOverLoadedAlertInput = {
  create: AlertCreateWithoutTripBusOverLoadedAlertInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateOrConnectWithoutTripDelayAlertInput = {
  create: AlertCreateWithoutTripDelayAlertInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateOrConnectWithoutVehicleSafetyEventInput = {
  create: AlertCreateWithoutVehicleSafetyEventInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateOrConnectWithoutWrongDropOffLocationInput = {
  create: AlertCreateWithoutWrongDropOffLocationInput;
  where: AlertWhereUniqueInput;
};

export type AlertCreateWithoutDelayTripStopAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertCreateNestedOneWithoutAlertInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertCreateNestedOneWithoutAlertInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertCreateNestedOneWithoutAlertInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventCreateNestedOneWithoutAlertInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationCreateNestedOneWithoutAlertInput>;
};

export type AlertCreateWithoutNoPickUpAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertCreateNestedOneWithoutAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertCreateNestedOneWithoutAlertInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertCreateNestedOneWithoutAlertInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventCreateNestedOneWithoutAlertInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationCreateNestedOneWithoutAlertInput>;
};

export type AlertCreateWithoutRouteBusAttendantVacantAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertCreateNestedOneWithoutAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertCreateNestedOneWithoutAlertInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertCreateNestedOneWithoutAlertInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertCreateNestedOneWithoutAlertInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventCreateNestedOneWithoutAlertInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationCreateNestedOneWithoutAlertInput>;
};

export type AlertCreateWithoutRouteCapacityExceededAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertCreateNestedOneWithoutAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertCreateNestedOneWithoutAlertInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertCreateNestedOneWithoutAlertInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertCreateNestedOneWithoutAlertInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventCreateNestedOneWithoutAlertInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationCreateNestedOneWithoutAlertInput>;
};

export type AlertCreateWithoutRouteDateAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertCreateNestedOneWithoutAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertCreateNestedOneWithoutAlertInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertCreateNestedOneWithoutAlertInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventCreateNestedOneWithoutAlertInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationCreateNestedOneWithoutAlertInput>;
};

export type AlertCreateWithoutRouteDriverVacantAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertCreateNestedOneWithoutAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertCreateNestedOneWithoutAlertInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertCreateNestedOneWithoutAlertInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventCreateNestedOneWithoutAlertInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationCreateNestedOneWithoutAlertInput>;
};

export type AlertCreateWithoutRouteTimeLimitExceededAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertCreateNestedOneWithoutAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertCreateNestedOneWithoutAlertInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertCreateNestedOneWithoutAlertInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertCreateNestedOneWithoutAlertInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventCreateNestedOneWithoutAlertInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationCreateNestedOneWithoutAlertInput>;
};

export type AlertCreateWithoutRouteVehicleVacantAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertCreateNestedOneWithoutAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertCreateNestedOneWithoutAlertInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertCreateNestedOneWithoutAlertInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertCreateNestedOneWithoutAlertInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventCreateNestedOneWithoutAlertInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationCreateNestedOneWithoutAlertInput>;
};

export type AlertCreateWithoutTripBusOverLoadedAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertCreateNestedOneWithoutAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertCreateNestedOneWithoutAlertInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertCreateNestedOneWithoutAlertInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertCreateNestedOneWithoutAlertInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventCreateNestedOneWithoutAlertInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationCreateNestedOneWithoutAlertInput>;
};

export type AlertCreateWithoutTripDelayAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertCreateNestedOneWithoutAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertCreateNestedOneWithoutAlertInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertCreateNestedOneWithoutAlertInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertCreateNestedOneWithoutAlertInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventCreateNestedOneWithoutAlertInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationCreateNestedOneWithoutAlertInput>;
};

export type AlertCreateWithoutVehicleSafetyEventInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertCreateNestedOneWithoutAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertCreateNestedOneWithoutAlertInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertCreateNestedOneWithoutAlertInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertCreateNestedOneWithoutAlertInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationCreateNestedOneWithoutAlertInput>;
};

export type AlertCreateWithoutWrongDropOffLocationInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType: AlertType;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertCreateNestedOneWithoutAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertCreateNestedOneWithoutAlertInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertCreateNestedOneWithoutAlertInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertCreateNestedOneWithoutAlertInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertCreateNestedOneWithoutAlertInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventCreateNestedOneWithoutAlertInput>;
};

export enum AlertLevel {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

export type AlertRelationFilter = {
  is?: InputMaybe<AlertWhereInput>;
  isNot?: InputMaybe<AlertWhereInput>;
};

export type AlertSearchInput = {
  skip: Scalars["Float"]["input"];
  take: Scalars["Float"]["input"];
};

export enum AlertType {
  BUS_OVERLOADED = "BUS_OVERLOADED",
  DELAY_TRIP_STOP = "DELAY_TRIP_STOP",
  NO_PICK_UP = "NO_PICK_UP",
  ROUTE_BUS_ATTENDANT_VACANT = "ROUTE_BUS_ATTENDANT_VACANT",
  ROUTE_CAPACITY_EXCEEDED = "ROUTE_CAPACITY_EXCEEDED",
  ROUTE_DATE = "ROUTE_DATE",
  ROUTE_DRIVER_VACANT = "ROUTE_DRIVER_VACANT",
  ROUTE_TIME_LIMIT = "ROUTE_TIME_LIMIT",
  ROUTE_VEHICLE_VACANT = "ROUTE_VEHICLE_VACANT",
  TRIP_DELAY = "TRIP_DELAY",
  VEHICLE_SAFETY = "VEHICLE_SAFETY",
  WRONG_DROPOFF_LOCATION = "WRONG_DROPOFF_LOCATION",
}

export type AlertUpdateOneRequiredWithoutNoPickUpAlertNestedInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutNoPickUpAlertInput>;
  create?: InputMaybe<AlertCreateWithoutNoPickUpAlertInput>;
  update?: InputMaybe<AlertUpdateToOneWithWhereWithoutNoPickUpAlertInput>;
  upsert?: InputMaybe<AlertUpsertWithoutNoPickUpAlertInput>;
};

export type AlertUpdateOneRequiredWithoutRouteBusAttendantVacantAlertNestedInput =
  {
    connect?: InputMaybe<AlertWhereUniqueInput>;
    connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteBusAttendantVacantAlertInput>;
    create?: InputMaybe<AlertCreateWithoutRouteBusAttendantVacantAlertInput>;
    update?: InputMaybe<AlertUpdateToOneWithWhereWithoutRouteBusAttendantVacantAlertInput>;
    upsert?: InputMaybe<AlertUpsertWithoutRouteBusAttendantVacantAlertInput>;
  };

export type AlertUpdateOneRequiredWithoutRouteCapacityExceededAlertNestedInput =
  {
    connect?: InputMaybe<AlertWhereUniqueInput>;
    connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteCapacityExceededAlertInput>;
    create?: InputMaybe<AlertCreateWithoutRouteCapacityExceededAlertInput>;
    update?: InputMaybe<AlertUpdateToOneWithWhereWithoutRouteCapacityExceededAlertInput>;
    upsert?: InputMaybe<AlertUpsertWithoutRouteCapacityExceededAlertInput>;
  };

export type AlertUpdateOneRequiredWithoutRouteDateAlertNestedInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteDateAlertInput>;
  create?: InputMaybe<AlertCreateWithoutRouteDateAlertInput>;
  update?: InputMaybe<AlertUpdateToOneWithWhereWithoutRouteDateAlertInput>;
  upsert?: InputMaybe<AlertUpsertWithoutRouteDateAlertInput>;
};

export type AlertUpdateOneRequiredWithoutRouteDriverVacantAlertNestedInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteDriverVacantAlertInput>;
  create?: InputMaybe<AlertCreateWithoutRouteDriverVacantAlertInput>;
  update?: InputMaybe<AlertUpdateToOneWithWhereWithoutRouteDriverVacantAlertInput>;
  upsert?: InputMaybe<AlertUpsertWithoutRouteDriverVacantAlertInput>;
};

export type AlertUpdateOneRequiredWithoutRouteTimeLimitExceededAlertNestedInput =
  {
    connect?: InputMaybe<AlertWhereUniqueInput>;
    connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteTimeLimitExceededAlertInput>;
    create?: InputMaybe<AlertCreateWithoutRouteTimeLimitExceededAlertInput>;
    update?: InputMaybe<AlertUpdateToOneWithWhereWithoutRouteTimeLimitExceededAlertInput>;
    upsert?: InputMaybe<AlertUpsertWithoutRouteTimeLimitExceededAlertInput>;
  };

export type AlertUpdateOneRequiredWithoutRouteVehicleVacantAlertNestedInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutRouteVehicleVacantAlertInput>;
  create?: InputMaybe<AlertCreateWithoutRouteVehicleVacantAlertInput>;
  update?: InputMaybe<AlertUpdateToOneWithWhereWithoutRouteVehicleVacantAlertInput>;
  upsert?: InputMaybe<AlertUpsertWithoutRouteVehicleVacantAlertInput>;
};

export type AlertUpdateOneRequiredWithoutTripDelayAlertNestedInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutTripDelayAlertInput>;
  create?: InputMaybe<AlertCreateWithoutTripDelayAlertInput>;
  update?: InputMaybe<AlertUpdateToOneWithWhereWithoutTripDelayAlertInput>;
  upsert?: InputMaybe<AlertUpsertWithoutTripDelayAlertInput>;
};

export type AlertUpdateOneRequiredWithoutWrongDropOffLocationNestedInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutWrongDropOffLocationInput>;
  create?: InputMaybe<AlertCreateWithoutWrongDropOffLocationInput>;
  update?: InputMaybe<AlertUpdateToOneWithWhereWithoutWrongDropOffLocationInput>;
  upsert?: InputMaybe<AlertUpsertWithoutWrongDropOffLocationInput>;
};

export type AlertUpdateOneWithoutDelayTripStopAlertNestedInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutDelayTripStopAlertInput>;
  create?: InputMaybe<AlertCreateWithoutDelayTripStopAlertInput>;
  delete?: InputMaybe<AlertWhereInput>;
  disconnect?: InputMaybe<AlertWhereInput>;
  update?: InputMaybe<AlertUpdateToOneWithWhereWithoutDelayTripStopAlertInput>;
  upsert?: InputMaybe<AlertUpsertWithoutDelayTripStopAlertInput>;
};

export type AlertUpdateOneWithoutTripBusOverLoadedAlertNestedInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutTripBusOverLoadedAlertInput>;
  create?: InputMaybe<AlertCreateWithoutTripBusOverLoadedAlertInput>;
  delete?: InputMaybe<AlertWhereInput>;
  disconnect?: InputMaybe<AlertWhereInput>;
  update?: InputMaybe<AlertUpdateToOneWithWhereWithoutTripBusOverLoadedAlertInput>;
  upsert?: InputMaybe<AlertUpsertWithoutTripBusOverLoadedAlertInput>;
};

export type AlertUpdateOneWithoutVehicleSafetyEventNestedInput = {
  connect?: InputMaybe<AlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AlertCreateOrConnectWithoutVehicleSafetyEventInput>;
  create?: InputMaybe<AlertCreateWithoutVehicleSafetyEventInput>;
  delete?: InputMaybe<AlertWhereInput>;
  disconnect?: InputMaybe<AlertWhereInput>;
  update?: InputMaybe<AlertUpdateToOneWithWhereWithoutVehicleSafetyEventInput>;
  upsert?: InputMaybe<AlertUpsertWithoutVehicleSafetyEventInput>;
};

export type AlertUpdateToOneWithWhereWithoutDelayTripStopAlertInput = {
  data: AlertUpdateWithoutDelayTripStopAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpdateToOneWithWhereWithoutNoPickUpAlertInput = {
  data: AlertUpdateWithoutNoPickUpAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpdateToOneWithWhereWithoutRouteBusAttendantVacantAlertInput =
  {
    data: AlertUpdateWithoutRouteBusAttendantVacantAlertInput;
    where?: InputMaybe<AlertWhereInput>;
  };

export type AlertUpdateToOneWithWhereWithoutRouteCapacityExceededAlertInput = {
  data: AlertUpdateWithoutRouteCapacityExceededAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpdateToOneWithWhereWithoutRouteDateAlertInput = {
  data: AlertUpdateWithoutRouteDateAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpdateToOneWithWhereWithoutRouteDriverVacantAlertInput = {
  data: AlertUpdateWithoutRouteDriverVacantAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpdateToOneWithWhereWithoutRouteTimeLimitExceededAlertInput = {
  data: AlertUpdateWithoutRouteTimeLimitExceededAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpdateToOneWithWhereWithoutRouteVehicleVacantAlertInput = {
  data: AlertUpdateWithoutRouteVehicleVacantAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpdateToOneWithWhereWithoutTripBusOverLoadedAlertInput = {
  data: AlertUpdateWithoutTripBusOverLoadedAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpdateToOneWithWhereWithoutTripDelayAlertInput = {
  data: AlertUpdateWithoutTripDelayAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpdateToOneWithWhereWithoutVehicleSafetyEventInput = {
  data: AlertUpdateWithoutVehicleSafetyEventInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpdateToOneWithWhereWithoutWrongDropOffLocationInput = {
  data: AlertUpdateWithoutWrongDropOffLocationInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpdateWithoutDelayTripStopAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertUpdateOneWithoutAlertNestedInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertUpdateOneWithoutAlertNestedInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventUpdateOneWithoutAlertNestedInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpdateWithoutNoPickUpAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertUpdateOneWithoutAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertUpdateOneWithoutAlertNestedInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventUpdateOneWithoutAlertNestedInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpdateWithoutRouteBusAttendantVacantAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertUpdateOneWithoutAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertUpdateOneWithoutAlertNestedInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertUpdateOneWithoutAlertNestedInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventUpdateOneWithoutAlertNestedInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpdateWithoutRouteCapacityExceededAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertUpdateOneWithoutAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertUpdateOneWithoutAlertNestedInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertUpdateOneWithoutAlertNestedInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventUpdateOneWithoutAlertNestedInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpdateWithoutRouteDateAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertUpdateOneWithoutAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertUpdateOneWithoutAlertNestedInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventUpdateOneWithoutAlertNestedInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpdateWithoutRouteDriverVacantAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertUpdateOneWithoutAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertUpdateOneWithoutAlertNestedInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertUpdateOneWithoutAlertNestedInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventUpdateOneWithoutAlertNestedInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpdateWithoutRouteTimeLimitExceededAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertUpdateOneWithoutAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertUpdateOneWithoutAlertNestedInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertUpdateOneWithoutAlertNestedInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventUpdateOneWithoutAlertNestedInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpdateWithoutRouteVehicleVacantAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertUpdateOneWithoutAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertUpdateOneWithoutAlertNestedInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertUpdateOneWithoutAlertNestedInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventUpdateOneWithoutAlertNestedInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpdateWithoutTripBusOverLoadedAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertUpdateOneWithoutAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertUpdateOneWithoutAlertNestedInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertUpdateOneWithoutAlertNestedInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventUpdateOneWithoutAlertNestedInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpdateWithoutTripDelayAlertInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertUpdateOneWithoutAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertUpdateOneWithoutAlertNestedInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertUpdateOneWithoutAlertNestedInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventUpdateOneWithoutAlertNestedInput>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpdateWithoutVehicleSafetyEventInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertUpdateOneWithoutAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertUpdateOneWithoutAlertNestedInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertUpdateOneWithoutAlertNestedInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpdateWithoutWrongDropOffLocationInput = {
  alertLevel?: InputMaybe<AlertLevel>;
  alertType?: InputMaybe<AlertType>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertUpdateOneWithoutAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertUpdateOneWithoutAlertNestedInput>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeDateAlert?: InputMaybe<RouteDateAlertUpdateOneWithoutAlertNestedInput>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput>;
  tripDelayAlert?: InputMaybe<TripDelayAlertUpdateOneWithoutAlertNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventUpdateOneWithoutAlertNestedInput>;
};

export type AlertUpsertWithoutDelayTripStopAlertInput = {
  create: AlertCreateWithoutDelayTripStopAlertInput;
  update: AlertUpdateWithoutDelayTripStopAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpsertWithoutNoPickUpAlertInput = {
  create: AlertCreateWithoutNoPickUpAlertInput;
  update: AlertUpdateWithoutNoPickUpAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpsertWithoutRouteBusAttendantVacantAlertInput = {
  create: AlertCreateWithoutRouteBusAttendantVacantAlertInput;
  update: AlertUpdateWithoutRouteBusAttendantVacantAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpsertWithoutRouteCapacityExceededAlertInput = {
  create: AlertCreateWithoutRouteCapacityExceededAlertInput;
  update: AlertUpdateWithoutRouteCapacityExceededAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpsertWithoutRouteDateAlertInput = {
  create: AlertCreateWithoutRouteDateAlertInput;
  update: AlertUpdateWithoutRouteDateAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpsertWithoutRouteDriverVacantAlertInput = {
  create: AlertCreateWithoutRouteDriverVacantAlertInput;
  update: AlertUpdateWithoutRouteDriverVacantAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpsertWithoutRouteTimeLimitExceededAlertInput = {
  create: AlertCreateWithoutRouteTimeLimitExceededAlertInput;
  update: AlertUpdateWithoutRouteTimeLimitExceededAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpsertWithoutRouteVehicleVacantAlertInput = {
  create: AlertCreateWithoutRouteVehicleVacantAlertInput;
  update: AlertUpdateWithoutRouteVehicleVacantAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpsertWithoutTripBusOverLoadedAlertInput = {
  create: AlertCreateWithoutTripBusOverLoadedAlertInput;
  update: AlertUpdateWithoutTripBusOverLoadedAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpsertWithoutTripDelayAlertInput = {
  create: AlertCreateWithoutTripDelayAlertInput;
  update: AlertUpdateWithoutTripDelayAlertInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpsertWithoutVehicleSafetyEventInput = {
  create: AlertCreateWithoutVehicleSafetyEventInput;
  update: AlertUpdateWithoutVehicleSafetyEventInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertUpsertWithoutWrongDropOffLocationInput = {
  create: AlertCreateWithoutWrongDropOffLocationInput;
  update: AlertUpdateWithoutWrongDropOffLocationInput;
  where?: InputMaybe<AlertWhereInput>;
};

export type AlertWhereInput = {
  AND?: InputMaybe<Array<AlertWhereInput>>;
  NOT?: InputMaybe<Array<AlertWhereInput>>;
  OR?: InputMaybe<Array<AlertWhereInput>>;
  alertLevel?: InputMaybe<EnumAlertLevelFilter>;
  alertType?: InputMaybe<EnumAlertTypeFilter>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertRelationFilter>;
  id?: InputMaybe<StringFilter>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertRelationFilter>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertRelationFilter>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertRelationFilter>;
  routeDateAlert?: InputMaybe<RouteDateAlertRelationFilter>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertRelationFilter>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertRelationFilter>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertRelationFilter>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertRelationFilter>;
  tripDelayAlert?: InputMaybe<TripDelayAlertRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventRelationFilter>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationRelationFilter>;
};

export type AlertWhereUniqueInput = {
  AND?: InputMaybe<Array<AlertWhereInput>>;
  NOT?: InputMaybe<Array<AlertWhereInput>>;
  OR?: InputMaybe<Array<AlertWhereInput>>;
  alertLevel?: InputMaybe<EnumAlertLevelFilter>;
  alertType?: InputMaybe<EnumAlertTypeFilter>;
  delayTripStopAlert?: InputMaybe<DelayTripStopAlertRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlert?: InputMaybe<NoPickUpAlertRelationFilter>;
  routeBusAttendantVacantAlert?: InputMaybe<RouteBusAttendantVacantAlertRelationFilter>;
  routeCapacityExceededAlert?: InputMaybe<RouteCapacityExceededAlertRelationFilter>;
  routeDateAlert?: InputMaybe<RouteDateAlertRelationFilter>;
  routeDriverVacantAlert?: InputMaybe<RouteDriverVacantAlertRelationFilter>;
  routeTimeLimitExceededAlert?: InputMaybe<RouteTimeLimitExceededAlertRelationFilter>;
  routeVehicleVacantAlert?: InputMaybe<RouteVehicleVacantAlertRelationFilter>;
  tripBusOverLoadedAlert?: InputMaybe<TripBusOverLoadedAlertRelationFilter>;
  tripDelayAlert?: InputMaybe<TripDelayAlertRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleSafetyEvent?: InputMaybe<VehicleSafetyEventRelationFilter>;
  wrongDropOffLocation?: InputMaybe<WrongDropOffLocationRelationFilter>;
};

export enum AppType {
  BUS_ATTENDANT_MOBILE = "BUS_ATTENDANT_MOBILE",
  DRIVER_MOBILE = "DRIVER_MOBILE",
  OPS_WEB = "OPS_WEB",
  PARENT_MOBILE = "PARENT_MOBILE",
}

export type AppTypeInput = {
  appType: AppType;
};

export type AppVersionPayload = {
  __typename?: "AppVersionPayload";
  appType: AppType;
  appVersionType: AppVersionType;
  description?: Maybe<Scalars["String"]["output"]>;
  descriptionAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  major: Scalars["Float"]["output"];
  minor: Scalars["Float"]["output"];
  patch: Scalars["Float"]["output"];
};

export enum AppVersionType {
  FORCEFUL = "FORCEFUL",
  RECOMMENDED = "RECOMMENDED",
  SILENT = "SILENT",
}

export enum AttendanceStatus {
  DROPPED_OFF = "DROPPED_OFF",
  INVALID = "INVALID",
  NO_SHOW = "NO_SHOW",
  PENDING = "PENDING",
  PICKED_UP = "PICKED_UP",
}

export type Bank = {
  __typename?: "Bank";
  BusAttendant?: Maybe<Array<BusAttendant>>;
  Driver?: Maybe<Array<Driver>>;
  _count: BankCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  banksDetails?: Maybe<Array<BankDetails>>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr?: Maybe<Scalars["String"]["output"]>;
  swiftCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type BankCount = {
  __typename?: "BankCount";
  BusAttendant: Scalars["Int"]["output"];
  Driver: Scalars["Int"]["output"];
  banksDetails: Scalars["Int"]["output"];
};

export type BankCreateManyCountryInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  swiftCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankCreateManyCountryInputEnvelope = {
  data: Array<BankCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BankCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<BankWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BankCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<BankCreateWithoutCountryInput>>;
  createMany?: InputMaybe<BankCreateManyCountryInputEnvelope>;
};

export type BankCreateNestedOneWithoutBanksDetailsInput = {
  connect?: InputMaybe<BankWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BankCreateOrConnectWithoutBanksDetailsInput>;
  create?: InputMaybe<BankCreateWithoutBanksDetailsInput>;
};

export type BankCreateNestedOneWithoutBusAttendantInput = {
  connect?: InputMaybe<BankWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BankCreateOrConnectWithoutBusAttendantInput>;
  create?: InputMaybe<BankCreateWithoutBusAttendantInput>;
};

export type BankCreateNestedOneWithoutDriverInput = {
  connect?: InputMaybe<BankWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BankCreateOrConnectWithoutDriverInput>;
  create?: InputMaybe<BankCreateWithoutDriverInput>;
};

export type BankCreateOrConnectWithoutBanksDetailsInput = {
  create: BankCreateWithoutBanksDetailsInput;
  where: BankWhereUniqueInput;
};

export type BankCreateOrConnectWithoutBusAttendantInput = {
  create: BankCreateWithoutBusAttendantInput;
  where: BankWhereUniqueInput;
};

export type BankCreateOrConnectWithoutCountryInput = {
  create: BankCreateWithoutCountryInput;
  where: BankWhereUniqueInput;
};

export type BankCreateOrConnectWithoutDriverInput = {
  create: BankCreateWithoutDriverInput;
  where: BankWhereUniqueInput;
};

export type BankCreateWithoutBanksDetailsInput = {
  BusAttendant?: InputMaybe<BusAttendantCreateNestedManyWithoutBankInput>;
  Driver?: InputMaybe<DriverCreateNestedManyWithoutBankInput>;
  country?: InputMaybe<CountryCreateNestedOneWithoutBanksInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  swiftCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankCreateWithoutBusAttendantInput = {
  Driver?: InputMaybe<DriverCreateNestedManyWithoutBankInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutBankInput>;
  country?: InputMaybe<CountryCreateNestedOneWithoutBanksInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  swiftCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankCreateWithoutCountryInput = {
  BusAttendant?: InputMaybe<BusAttendantCreateNestedManyWithoutBankInput>;
  Driver?: InputMaybe<DriverCreateNestedManyWithoutBankInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutBankInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  swiftCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankCreateWithoutDriverInput = {
  BusAttendant?: InputMaybe<BusAttendantCreateNestedManyWithoutBankInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutBankInput>;
  country?: InputMaybe<CountryCreateNestedOneWithoutBanksInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  swiftCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankDetails = {
  __typename?: "BankDetails";
  accountName?: Maybe<Scalars["String"]["output"]>;
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  bank?: Maybe<Bank>;
  bankId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type BankDetailsCreateManyBankInput = {
  accountName?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankDetailsCreateManyBankInputEnvelope = {
  data: Array<BankDetailsCreateManyBankInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BankDetailsCreateManyServiceProviderInput = {
  accountName?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankDetailsCreateManyServiceProviderInputEnvelope = {
  data: Array<BankDetailsCreateManyServiceProviderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BankDetailsCreateNestedManyWithoutBankInput = {
  connect?: InputMaybe<Array<BankDetailsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BankDetailsCreateOrConnectWithoutBankInput>
  >;
  create?: InputMaybe<Array<BankDetailsCreateWithoutBankInput>>;
  createMany?: InputMaybe<BankDetailsCreateManyBankInputEnvelope>;
};

export type BankDetailsCreateNestedManyWithoutServiceProviderInput = {
  connect?: InputMaybe<Array<BankDetailsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BankDetailsCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<BankDetailsCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<BankDetailsCreateManyServiceProviderInputEnvelope>;
};

export type BankDetailsCreateOrConnectWithoutBankInput = {
  create: BankDetailsCreateWithoutBankInput;
  where: BankDetailsWhereUniqueInput;
};

export type BankDetailsCreateOrConnectWithoutServiceProviderInput = {
  create: BankDetailsCreateWithoutServiceProviderInput;
  where: BankDetailsWhereUniqueInput;
};

export type BankDetailsCreateWithoutBankInput = {
  accountName?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutBanksDetailsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankDetailsCreateWithoutServiceProviderInput = {
  accountName?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBanksDetailsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankDetailsListRelationFilter = {
  every?: InputMaybe<BankDetailsWhereInput>;
  none?: InputMaybe<BankDetailsWhereInput>;
  some?: InputMaybe<BankDetailsWhereInput>;
};

export type BankDetailsScalarWhereInput = {
  AND?: InputMaybe<Array<BankDetailsScalarWhereInput>>;
  NOT?: InputMaybe<Array<BankDetailsScalarWhereInput>>;
  OR?: InputMaybe<Array<BankDetailsScalarWhereInput>>;
  accountName?: InputMaybe<StringFilter>;
  accountNumber?: InputMaybe<StringFilter>;
  bankId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BankDetailsUpdateManyMutationInput = {
  accountName?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankDetailsUpdateManyWithWhereWithoutBankInput = {
  data: BankDetailsUpdateManyMutationInput;
  where: BankDetailsScalarWhereInput;
};

export type BankDetailsUpdateManyWithWhereWithoutServiceProviderInput = {
  data: BankDetailsUpdateManyMutationInput;
  where: BankDetailsScalarWhereInput;
};

export type BankDetailsUpdateManyWithoutBankNestedInput = {
  connect?: InputMaybe<Array<BankDetailsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BankDetailsCreateOrConnectWithoutBankInput>
  >;
  create?: InputMaybe<Array<BankDetailsCreateWithoutBankInput>>;
  createMany?: InputMaybe<BankDetailsCreateManyBankInputEnvelope>;
  delete?: InputMaybe<Array<BankDetailsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BankDetailsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BankDetailsWhereUniqueInput>>;
  set?: InputMaybe<Array<BankDetailsWhereUniqueInput>>;
  update?: InputMaybe<Array<BankDetailsUpdateWithWhereUniqueWithoutBankInput>>;
  updateMany?: InputMaybe<
    Array<BankDetailsUpdateManyWithWhereWithoutBankInput>
  >;
  upsert?: InputMaybe<Array<BankDetailsUpsertWithWhereUniqueWithoutBankInput>>;
};

export type BankDetailsUpdateManyWithoutServiceProviderNestedInput = {
  connect?: InputMaybe<Array<BankDetailsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BankDetailsCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<BankDetailsCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<BankDetailsCreateManyServiceProviderInputEnvelope>;
  delete?: InputMaybe<Array<BankDetailsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BankDetailsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BankDetailsWhereUniqueInput>>;
  set?: InputMaybe<Array<BankDetailsWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BankDetailsUpdateWithWhereUniqueWithoutServiceProviderInput>
  >;
  updateMany?: InputMaybe<
    Array<BankDetailsUpdateManyWithWhereWithoutServiceProviderInput>
  >;
  upsert?: InputMaybe<
    Array<BankDetailsUpsertWithWhereUniqueWithoutServiceProviderInput>
  >;
};

export type BankDetailsUpdateWithWhereUniqueWithoutBankInput = {
  data: BankDetailsUpdateWithoutBankInput;
  where: BankDetailsWhereUniqueInput;
};

export type BankDetailsUpdateWithWhereUniqueWithoutServiceProviderInput = {
  data: BankDetailsUpdateWithoutServiceProviderInput;
  where: BankDetailsWhereUniqueInput;
};

export type BankDetailsUpdateWithoutBankInput = {
  accountName?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutBanksDetailsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankDetailsUpdateWithoutServiceProviderInput = {
  accountName?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bank?: InputMaybe<BankUpdateOneWithoutBanksDetailsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankDetailsUpsertWithWhereUniqueWithoutBankInput = {
  create: BankDetailsCreateWithoutBankInput;
  update: BankDetailsUpdateWithoutBankInput;
  where: BankDetailsWhereUniqueInput;
};

export type BankDetailsUpsertWithWhereUniqueWithoutServiceProviderInput = {
  create: BankDetailsCreateWithoutServiceProviderInput;
  update: BankDetailsUpdateWithoutServiceProviderInput;
  where: BankDetailsWhereUniqueInput;
};

export type BankDetailsWhereInput = {
  AND?: InputMaybe<Array<BankDetailsWhereInput>>;
  NOT?: InputMaybe<Array<BankDetailsWhereInput>>;
  OR?: InputMaybe<Array<BankDetailsWhereInput>>;
  accountName?: InputMaybe<StringFilter>;
  accountNumber?: InputMaybe<StringFilter>;
  bank?: InputMaybe<BankRelationFilter>;
  bankId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BankDetailsWhereUniqueInput = {
  AND?: InputMaybe<Array<BankDetailsWhereInput>>;
  NOT?: InputMaybe<Array<BankDetailsWhereInput>>;
  OR?: InputMaybe<Array<BankDetailsWhereInput>>;
  accountName?: InputMaybe<StringFilter>;
  accountNumber?: InputMaybe<StringFilter>;
  bank?: InputMaybe<BankRelationFilter>;
  bankId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BankListRelationFilter = {
  every?: InputMaybe<BankWhereInput>;
  none?: InputMaybe<BankWhereInput>;
  some?: InputMaybe<BankWhereInput>;
};

export type BankRelationFilter = {
  is?: InputMaybe<BankWhereInput>;
  isNot?: InputMaybe<BankWhereInput>;
};

export type BankScalarWhereInput = {
  AND?: InputMaybe<Array<BankScalarWhereInput>>;
  NOT?: InputMaybe<Array<BankScalarWhereInput>>;
  OR?: InputMaybe<Array<BankScalarWhereInput>>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  swiftCode?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BankUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  swiftCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankUpdateManyWithWhereWithoutCountryInput = {
  data: BankUpdateManyMutationInput;
  where: BankScalarWhereInput;
};

export type BankUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<BankWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BankCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<BankCreateWithoutCountryInput>>;
  createMany?: InputMaybe<BankCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<BankWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BankScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BankWhereUniqueInput>>;
  set?: InputMaybe<Array<BankWhereUniqueInput>>;
  update?: InputMaybe<Array<BankUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<Array<BankUpdateManyWithWhereWithoutCountryInput>>;
  upsert?: InputMaybe<Array<BankUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type BankUpdateOneWithoutBanksDetailsNestedInput = {
  connect?: InputMaybe<BankWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BankCreateOrConnectWithoutBanksDetailsInput>;
  create?: InputMaybe<BankCreateWithoutBanksDetailsInput>;
  delete?: InputMaybe<BankWhereInput>;
  disconnect?: InputMaybe<BankWhereInput>;
  update?: InputMaybe<BankUpdateToOneWithWhereWithoutBanksDetailsInput>;
  upsert?: InputMaybe<BankUpsertWithoutBanksDetailsInput>;
};

export type BankUpdateOneWithoutBusAttendantNestedInput = {
  connect?: InputMaybe<BankWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BankCreateOrConnectWithoutBusAttendantInput>;
  create?: InputMaybe<BankCreateWithoutBusAttendantInput>;
  delete?: InputMaybe<BankWhereInput>;
  disconnect?: InputMaybe<BankWhereInput>;
  update?: InputMaybe<BankUpdateToOneWithWhereWithoutBusAttendantInput>;
  upsert?: InputMaybe<BankUpsertWithoutBusAttendantInput>;
};

export type BankUpdateOneWithoutDriverNestedInput = {
  connect?: InputMaybe<BankWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BankCreateOrConnectWithoutDriverInput>;
  create?: InputMaybe<BankCreateWithoutDriverInput>;
  delete?: InputMaybe<BankWhereInput>;
  disconnect?: InputMaybe<BankWhereInput>;
  update?: InputMaybe<BankUpdateToOneWithWhereWithoutDriverInput>;
  upsert?: InputMaybe<BankUpsertWithoutDriverInput>;
};

export type BankUpdateToOneWithWhereWithoutBanksDetailsInput = {
  data: BankUpdateWithoutBanksDetailsInput;
  where?: InputMaybe<BankWhereInput>;
};

export type BankUpdateToOneWithWhereWithoutBusAttendantInput = {
  data: BankUpdateWithoutBusAttendantInput;
  where?: InputMaybe<BankWhereInput>;
};

export type BankUpdateToOneWithWhereWithoutDriverInput = {
  data: BankUpdateWithoutDriverInput;
  where?: InputMaybe<BankWhereInput>;
};

export type BankUpdateWithWhereUniqueWithoutCountryInput = {
  data: BankUpdateWithoutCountryInput;
  where: BankWhereUniqueInput;
};

export type BankUpdateWithoutBanksDetailsInput = {
  BusAttendant?: InputMaybe<BusAttendantUpdateManyWithoutBankNestedInput>;
  Driver?: InputMaybe<DriverUpdateManyWithoutBankNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutBanksNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  swiftCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankUpdateWithoutBusAttendantInput = {
  Driver?: InputMaybe<DriverUpdateManyWithoutBankNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutBankNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutBanksNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  swiftCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankUpdateWithoutCountryInput = {
  BusAttendant?: InputMaybe<BusAttendantUpdateManyWithoutBankNestedInput>;
  Driver?: InputMaybe<DriverUpdateManyWithoutBankNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutBankNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  swiftCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankUpdateWithoutDriverInput = {
  BusAttendant?: InputMaybe<BusAttendantUpdateManyWithoutBankNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutBankNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutBanksNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  swiftCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BankUpsertWithWhereUniqueWithoutCountryInput = {
  create: BankCreateWithoutCountryInput;
  update: BankUpdateWithoutCountryInput;
  where: BankWhereUniqueInput;
};

export type BankUpsertWithoutBanksDetailsInput = {
  create: BankCreateWithoutBanksDetailsInput;
  update: BankUpdateWithoutBanksDetailsInput;
  where?: InputMaybe<BankWhereInput>;
};

export type BankUpsertWithoutBusAttendantInput = {
  create: BankCreateWithoutBusAttendantInput;
  update: BankUpdateWithoutBusAttendantInput;
  where?: InputMaybe<BankWhereInput>;
};

export type BankUpsertWithoutDriverInput = {
  create: BankCreateWithoutDriverInput;
  update: BankUpdateWithoutDriverInput;
  where?: InputMaybe<BankWhereInput>;
};

export type BankWhereInput = {
  AND?: InputMaybe<Array<BankWhereInput>>;
  BusAttendant?: InputMaybe<BusAttendantListRelationFilter>;
  Driver?: InputMaybe<DriverListRelationFilter>;
  NOT?: InputMaybe<Array<BankWhereInput>>;
  OR?: InputMaybe<Array<BankWhereInput>>;
  banksDetails?: InputMaybe<BankDetailsListRelationFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  swiftCode?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BankWhereUniqueInput = {
  AND?: InputMaybe<Array<BankWhereInput>>;
  BusAttendant?: InputMaybe<BusAttendantListRelationFilter>;
  Driver?: InputMaybe<DriverListRelationFilter>;
  NOT?: InputMaybe<Array<BankWhereInput>>;
  OR?: InputMaybe<Array<BankWhereInput>>;
  banksDetails?: InputMaybe<BankDetailsListRelationFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  swiftCode?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum BleStatus {
  DETECTED = "DETECTED",
  NOT_ASSIGNED = "NOT_ASSIGNED",
  NOT_DETECTED = "NOT_DETECTED",
}

export type BoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<BoolFilter>;
};

export type Branch = {
  __typename?: "Branch";
  _count: BranchCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  departments?: Maybe<Array<BranchDepartment>>;
  id: Scalars["String"]["output"];
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type BranchCount = {
  __typename?: "BranchCount";
  departments: Scalars["Int"]["output"];
};

export type BranchCreateManyCountryInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchCreateManyCountryInputEnvelope = {
  data: Array<BranchCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BranchCreateManyServiceProviderInput = {
  countryId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchCreateManyServiceProviderInputEnvelope = {
  data: Array<BranchCreateManyServiceProviderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BranchCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<BranchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BranchCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<BranchCreateWithoutCountryInput>>;
  createMany?: InputMaybe<BranchCreateManyCountryInputEnvelope>;
};

export type BranchCreateNestedManyWithoutServiceProviderInput = {
  connect?: InputMaybe<Array<BranchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BranchCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<BranchCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<BranchCreateManyServiceProviderInputEnvelope>;
};

export type BranchCreateNestedOneWithoutDepartmentsInput = {
  connect?: InputMaybe<BranchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BranchCreateOrConnectWithoutDepartmentsInput>;
  create?: InputMaybe<BranchCreateWithoutDepartmentsInput>;
};

export type BranchCreateOrConnectWithoutCountryInput = {
  create: BranchCreateWithoutCountryInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutDepartmentsInput = {
  create: BranchCreateWithoutDepartmentsInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutServiceProviderInput = {
  create: BranchCreateWithoutServiceProviderInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateWithoutCountryInput = {
  departments?: InputMaybe<BranchDepartmentCreateNestedManyWithoutBranchInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutBranchesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchCreateWithoutDepartmentsInput = {
  country: CountryCreateNestedOneWithoutBranchesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutBranchesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchCreateWithoutServiceProviderInput = {
  country: CountryCreateNestedOneWithoutBranchesInput;
  departments?: InputMaybe<BranchDepartmentCreateNestedManyWithoutBranchInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchDepartment = {
  __typename?: "BranchDepartment";
  _count: BranchDepartmentCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  branch: Branch;
  branchId: Scalars["String"]["output"];
  contacts?: Maybe<Array<Contact>>;
  createdAt: Scalars["DateTime"]["output"];
  department: Department;
  departmentId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type BranchDepartmentCount = {
  __typename?: "BranchDepartmentCount";
  contacts: Scalars["Int"]["output"];
};

export type BranchDepartmentCreateManyBranchInput = {
  departmentId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchDepartmentCreateManyBranchInputEnvelope = {
  data: Array<BranchDepartmentCreateManyBranchInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BranchDepartmentCreateManyDepartmentInput = {
  branchId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchDepartmentCreateManyDepartmentInputEnvelope = {
  data: Array<BranchDepartmentCreateManyDepartmentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BranchDepartmentCreateNestedManyWithoutBranchInput = {
  connect?: InputMaybe<Array<BranchDepartmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BranchDepartmentCreateOrConnectWithoutBranchInput>
  >;
  create?: InputMaybe<Array<BranchDepartmentCreateWithoutBranchInput>>;
  createMany?: InputMaybe<BranchDepartmentCreateManyBranchInputEnvelope>;
};

export type BranchDepartmentCreateNestedManyWithoutDepartmentInput = {
  connect?: InputMaybe<Array<BranchDepartmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BranchDepartmentCreateOrConnectWithoutDepartmentInput>
  >;
  create?: InputMaybe<Array<BranchDepartmentCreateWithoutDepartmentInput>>;
  createMany?: InputMaybe<BranchDepartmentCreateManyDepartmentInputEnvelope>;
};

export type BranchDepartmentCreateOrConnectWithoutBranchInput = {
  create: BranchDepartmentCreateWithoutBranchInput;
  where: BranchDepartmentWhereUniqueInput;
};

export type BranchDepartmentCreateOrConnectWithoutDepartmentInput = {
  create: BranchDepartmentCreateWithoutDepartmentInput;
  where: BranchDepartmentWhereUniqueInput;
};

export type BranchDepartmentCreateWithoutBranchInput = {
  contacts?: InputMaybe<ContactCreateNestedManyWithoutBranchDepartmentInput>;
  department: DepartmentCreateNestedOneWithoutBranchesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchDepartmentCreateWithoutDepartmentInput = {
  branch: BranchCreateNestedOneWithoutDepartmentsInput;
  contacts?: InputMaybe<ContactCreateNestedManyWithoutBranchDepartmentInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchDepartmentListRelationFilter = {
  every?: InputMaybe<BranchDepartmentWhereInput>;
  none?: InputMaybe<BranchDepartmentWhereInput>;
  some?: InputMaybe<BranchDepartmentWhereInput>;
};

export type BranchDepartmentRelationFilter = {
  is?: InputMaybe<BranchDepartmentWhereInput>;
  isNot?: InputMaybe<BranchDepartmentWhereInput>;
};

export type BranchDepartmentScalarWhereInput = {
  AND?: InputMaybe<Array<BranchDepartmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<BranchDepartmentScalarWhereInput>>;
  OR?: InputMaybe<Array<BranchDepartmentScalarWhereInput>>;
  branchId?: InputMaybe<StringFilter>;
  departmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BranchDepartmentUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchDepartmentUpdateManyWithWhereWithoutBranchInput = {
  data: BranchDepartmentUpdateManyMutationInput;
  where: BranchDepartmentScalarWhereInput;
};

export type BranchDepartmentUpdateManyWithWhereWithoutDepartmentInput = {
  data: BranchDepartmentUpdateManyMutationInput;
  where: BranchDepartmentScalarWhereInput;
};

export type BranchDepartmentUpdateManyWithoutBranchNestedInput = {
  connect?: InputMaybe<Array<BranchDepartmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BranchDepartmentCreateOrConnectWithoutBranchInput>
  >;
  create?: InputMaybe<Array<BranchDepartmentCreateWithoutBranchInput>>;
  createMany?: InputMaybe<BranchDepartmentCreateManyBranchInputEnvelope>;
  delete?: InputMaybe<Array<BranchDepartmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BranchDepartmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BranchDepartmentWhereUniqueInput>>;
  set?: InputMaybe<Array<BranchDepartmentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BranchDepartmentUpdateWithWhereUniqueWithoutBranchInput>
  >;
  updateMany?: InputMaybe<
    Array<BranchDepartmentUpdateManyWithWhereWithoutBranchInput>
  >;
  upsert?: InputMaybe<
    Array<BranchDepartmentUpsertWithWhereUniqueWithoutBranchInput>
  >;
};

export type BranchDepartmentUpdateManyWithoutDepartmentNestedInput = {
  connect?: InputMaybe<Array<BranchDepartmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BranchDepartmentCreateOrConnectWithoutDepartmentInput>
  >;
  create?: InputMaybe<Array<BranchDepartmentCreateWithoutDepartmentInput>>;
  createMany?: InputMaybe<BranchDepartmentCreateManyDepartmentInputEnvelope>;
  delete?: InputMaybe<Array<BranchDepartmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BranchDepartmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BranchDepartmentWhereUniqueInput>>;
  set?: InputMaybe<Array<BranchDepartmentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BranchDepartmentUpdateWithWhereUniqueWithoutDepartmentInput>
  >;
  updateMany?: InputMaybe<
    Array<BranchDepartmentUpdateManyWithWhereWithoutDepartmentInput>
  >;
  upsert?: InputMaybe<
    Array<BranchDepartmentUpsertWithWhereUniqueWithoutDepartmentInput>
  >;
};

export type BranchDepartmentUpdateWithWhereUniqueWithoutBranchInput = {
  data: BranchDepartmentUpdateWithoutBranchInput;
  where: BranchDepartmentWhereUniqueInput;
};

export type BranchDepartmentUpdateWithWhereUniqueWithoutDepartmentInput = {
  data: BranchDepartmentUpdateWithoutDepartmentInput;
  where: BranchDepartmentWhereUniqueInput;
};

export type BranchDepartmentUpdateWithoutBranchInput = {
  contacts?: InputMaybe<ContactUpdateManyWithoutBranchDepartmentNestedInput>;
  department?: InputMaybe<DepartmentUpdateOneRequiredWithoutBranchesNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchDepartmentUpdateWithoutDepartmentInput = {
  branch?: InputMaybe<BranchUpdateOneRequiredWithoutDepartmentsNestedInput>;
  contacts?: InputMaybe<ContactUpdateManyWithoutBranchDepartmentNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchDepartmentUpsertWithWhereUniqueWithoutBranchInput = {
  create: BranchDepartmentCreateWithoutBranchInput;
  update: BranchDepartmentUpdateWithoutBranchInput;
  where: BranchDepartmentWhereUniqueInput;
};

export type BranchDepartmentUpsertWithWhereUniqueWithoutDepartmentInput = {
  create: BranchDepartmentCreateWithoutDepartmentInput;
  update: BranchDepartmentUpdateWithoutDepartmentInput;
  where: BranchDepartmentWhereUniqueInput;
};

export type BranchDepartmentWhereInput = {
  AND?: InputMaybe<Array<BranchDepartmentWhereInput>>;
  NOT?: InputMaybe<Array<BranchDepartmentWhereInput>>;
  OR?: InputMaybe<Array<BranchDepartmentWhereInput>>;
  branch?: InputMaybe<BranchRelationFilter>;
  branchId?: InputMaybe<StringFilter>;
  contacts?: InputMaybe<ContactListRelationFilter>;
  department?: InputMaybe<DepartmentRelationFilter>;
  departmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BranchDepartmentWhereUniqueInput = {
  AND?: InputMaybe<Array<BranchDepartmentWhereInput>>;
  NOT?: InputMaybe<Array<BranchDepartmentWhereInput>>;
  OR?: InputMaybe<Array<BranchDepartmentWhereInput>>;
  branch?: InputMaybe<BranchRelationFilter>;
  branchId?: InputMaybe<StringFilter>;
  contacts?: InputMaybe<ContactListRelationFilter>;
  department?: InputMaybe<DepartmentRelationFilter>;
  departmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BranchListRelationFilter = {
  every?: InputMaybe<BranchWhereInput>;
  none?: InputMaybe<BranchWhereInput>;
  some?: InputMaybe<BranchWhereInput>;
};

export type BranchRelationFilter = {
  is?: InputMaybe<BranchWhereInput>;
  isNot?: InputMaybe<BranchWhereInput>;
};

export type BranchScalarWhereInput = {
  AND?: InputMaybe<Array<BranchScalarWhereInput>>;
  NOT?: InputMaybe<Array<BranchScalarWhereInput>>;
  OR?: InputMaybe<Array<BranchScalarWhereInput>>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BranchUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchUpdateManyWithWhereWithoutCountryInput = {
  data: BranchUpdateManyMutationInput;
  where: BranchScalarWhereInput;
};

export type BranchUpdateManyWithWhereWithoutServiceProviderInput = {
  data: BranchUpdateManyMutationInput;
  where: BranchScalarWhereInput;
};

export type BranchUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<BranchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BranchCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<BranchCreateWithoutCountryInput>>;
  createMany?: InputMaybe<BranchCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<BranchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BranchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BranchWhereUniqueInput>>;
  set?: InputMaybe<Array<BranchWhereUniqueInput>>;
  update?: InputMaybe<Array<BranchUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<Array<BranchUpdateManyWithWhereWithoutCountryInput>>;
  upsert?: InputMaybe<Array<BranchUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type BranchUpdateManyWithoutServiceProviderNestedInput = {
  connect?: InputMaybe<Array<BranchWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BranchCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<BranchCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<BranchCreateManyServiceProviderInputEnvelope>;
  delete?: InputMaybe<Array<BranchWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BranchScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BranchWhereUniqueInput>>;
  set?: InputMaybe<Array<BranchWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BranchUpdateWithWhereUniqueWithoutServiceProviderInput>
  >;
  updateMany?: InputMaybe<
    Array<BranchUpdateManyWithWhereWithoutServiceProviderInput>
  >;
  upsert?: InputMaybe<
    Array<BranchUpsertWithWhereUniqueWithoutServiceProviderInput>
  >;
};

export type BranchUpdateOneRequiredWithoutDepartmentsNestedInput = {
  connect?: InputMaybe<BranchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BranchCreateOrConnectWithoutDepartmentsInput>;
  create?: InputMaybe<BranchCreateWithoutDepartmentsInput>;
  update?: InputMaybe<BranchUpdateToOneWithWhereWithoutDepartmentsInput>;
  upsert?: InputMaybe<BranchUpsertWithoutDepartmentsInput>;
};

export type BranchUpdateToOneWithWhereWithoutDepartmentsInput = {
  data: BranchUpdateWithoutDepartmentsInput;
  where?: InputMaybe<BranchWhereInput>;
};

export type BranchUpdateWithWhereUniqueWithoutCountryInput = {
  data: BranchUpdateWithoutCountryInput;
  where: BranchWhereUniqueInput;
};

export type BranchUpdateWithWhereUniqueWithoutServiceProviderInput = {
  data: BranchUpdateWithoutServiceProviderInput;
  where: BranchWhereUniqueInput;
};

export type BranchUpdateWithoutCountryInput = {
  departments?: InputMaybe<BranchDepartmentUpdateManyWithoutBranchNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutBranchesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchUpdateWithoutDepartmentsInput = {
  country?: InputMaybe<CountryUpdateOneRequiredWithoutBranchesNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutBranchesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchUpdateWithoutServiceProviderInput = {
  country?: InputMaybe<CountryUpdateOneRequiredWithoutBranchesNestedInput>;
  departments?: InputMaybe<BranchDepartmentUpdateManyWithoutBranchNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BranchUpsertWithWhereUniqueWithoutCountryInput = {
  create: BranchCreateWithoutCountryInput;
  update: BranchUpdateWithoutCountryInput;
  where: BranchWhereUniqueInput;
};

export type BranchUpsertWithWhereUniqueWithoutServiceProviderInput = {
  create: BranchCreateWithoutServiceProviderInput;
  update: BranchUpdateWithoutServiceProviderInput;
  where: BranchWhereUniqueInput;
};

export type BranchUpsertWithoutDepartmentsInput = {
  create: BranchCreateWithoutDepartmentsInput;
  update: BranchUpdateWithoutDepartmentsInput;
  where?: InputMaybe<BranchWhereInput>;
};

export type BranchWhereInput = {
  AND?: InputMaybe<Array<BranchWhereInput>>;
  NOT?: InputMaybe<Array<BranchWhereInput>>;
  OR?: InputMaybe<Array<BranchWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  departments?: InputMaybe<BranchDepartmentListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BranchWhereUniqueInput = {
  AND?: InputMaybe<Array<BranchWhereInput>>;
  NOT?: InputMaybe<Array<BranchWhereInput>>;
  OR?: InputMaybe<Array<BranchWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  departments?: InputMaybe<BranchDepartmentListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum BuildingType {
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  SCHOOL = "SCHOOL",
}

export type BusAttendant = {
  __typename?: "BusAttendant";
  BusAttendantHoursWorked?: Maybe<Array<BusAttendantHoursWorked>>;
  BusAttendantStatusHistory?: Maybe<Array<BusAttendantStatusHistory>>;
  BusAttendantViolationCompliance?: Maybe<
    Array<BusAttendantViolationCompliance>
  >;
  _count: BusAttendantCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  bank?: Maybe<Bank>;
  bankAccountName?: Maybe<Scalars["String"]["output"]>;
  bankAccountNumber?: Maybe<Scalars["String"]["output"]>;
  bankId?: Maybe<Scalars["String"]["output"]>;
  contractOriginalStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  contractStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  contractType?: Maybe<ContractType>;
  contractTypeId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  nationalId?: Maybe<Scalars["String"]["output"]>;
  nationalIdExpiryDate?: Maybe<Scalars["DateTime"]["output"]>;
  newBusAttendantTripLogs?: Maybe<Array<TripLog>>;
  oldBusAttendantTripLogs?: Maybe<Array<TripLog>>;
  routeBusAttendantVacantAlerts?: Maybe<Array<RouteBusAttendantVacantAlert>>;
  routes?: Maybe<Array<RouteBusAttendant>>;
  status: BusAttendantStatus;
  submittedAt?: Maybe<Scalars["DateTime"]["output"]>;
  terminationDate?: Maybe<Scalars["DateTime"]["output"]>;
  trips?: Maybe<Array<Trip>>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
  zone?: Maybe<EmployeeZone>;
  zoneId?: Maybe<Scalars["String"]["output"]>;
};

export type BusAttendantCount = {
  __typename?: "BusAttendantCount";
  BusAttendantHoursWorked: Scalars["Int"]["output"];
  BusAttendantStatusHistory: Scalars["Int"]["output"];
  BusAttendantViolationCompliance: Scalars["Int"]["output"];
  newBusAttendantTripLogs: Scalars["Int"]["output"];
  oldBusAttendantTripLogs: Scalars["Int"]["output"];
  routeBusAttendantVacantAlerts: Scalars["Int"]["output"];
  routes: Scalars["Int"]["output"];
  trips: Scalars["Int"]["output"];
};

export type BusAttendantCreateInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateManyBankInput = {
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractTypeId?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
  zoneId?: InputMaybe<Scalars["String"]["input"]>;
};

export type BusAttendantCreateManyBankInputEnvelope = {
  data: Array<BusAttendantCreateManyBankInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BusAttendantCreateManyContractTypeInput = {
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankId?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
  zoneId?: InputMaybe<Scalars["String"]["input"]>;
};

export type BusAttendantCreateManyContractTypeInputEnvelope = {
  data: Array<BusAttendantCreateManyContractTypeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BusAttendantCreateManyZoneInput = {
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankId?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractTypeId?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type BusAttendantCreateManyZoneInputEnvelope = {
  data: Array<BusAttendantCreateManyZoneInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BusAttendantCreateNestedManyWithoutBankInput = {
  connect?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BusAttendantCreateOrConnectWithoutBankInput>
  >;
  create?: InputMaybe<Array<BusAttendantCreateWithoutBankInput>>;
  createMany?: InputMaybe<BusAttendantCreateManyBankInputEnvelope>;
};

export type BusAttendantCreateNestedManyWithoutContractTypeInput = {
  connect?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BusAttendantCreateOrConnectWithoutContractTypeInput>
  >;
  create?: InputMaybe<Array<BusAttendantCreateWithoutContractTypeInput>>;
  createMany?: InputMaybe<BusAttendantCreateManyContractTypeInputEnvelope>;
};

export type BusAttendantCreateNestedManyWithoutZoneInput = {
  connect?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BusAttendantCreateOrConnectWithoutZoneInput>
  >;
  create?: InputMaybe<Array<BusAttendantCreateWithoutZoneInput>>;
  createMany?: InputMaybe<BusAttendantCreateManyZoneInputEnvelope>;
};

export type BusAttendantCreateNestedOneWithoutBusAttendantHoursWorkedInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutBusAttendantHoursWorkedInput>;
  create?: InputMaybe<BusAttendantCreateWithoutBusAttendantHoursWorkedInput>;
};

export type BusAttendantCreateNestedOneWithoutBusAttendantStatusHistoryInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutBusAttendantStatusHistoryInput>;
  create?: InputMaybe<BusAttendantCreateWithoutBusAttendantStatusHistoryInput>;
};

export type BusAttendantCreateNestedOneWithoutBusAttendantViolationComplianceInput =
  {
    connect?: InputMaybe<BusAttendantWhereUniqueInput>;
    connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutBusAttendantViolationComplianceInput>;
    create?: InputMaybe<BusAttendantCreateWithoutBusAttendantViolationComplianceInput>;
  };

export type BusAttendantCreateNestedOneWithoutNewBusAttendantTripLogsInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutNewBusAttendantTripLogsInput>;
  create?: InputMaybe<BusAttendantCreateWithoutNewBusAttendantTripLogsInput>;
};

export type BusAttendantCreateNestedOneWithoutOldBusAttendantTripLogsInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutOldBusAttendantTripLogsInput>;
  create?: InputMaybe<BusAttendantCreateWithoutOldBusAttendantTripLogsInput>;
};

export type BusAttendantCreateNestedOneWithoutRouteBusAttendantVacantAlertsInput =
  {
    connect?: InputMaybe<BusAttendantWhereUniqueInput>;
    connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutRouteBusAttendantVacantAlertsInput>;
    create?: InputMaybe<BusAttendantCreateWithoutRouteBusAttendantVacantAlertsInput>;
  };

export type BusAttendantCreateNestedOneWithoutRoutesInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<BusAttendantCreateWithoutRoutesInput>;
};

export type BusAttendantCreateNestedOneWithoutTripsInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutTripsInput>;
  create?: InputMaybe<BusAttendantCreateWithoutTripsInput>;
};

export type BusAttendantCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<BusAttendantCreateWithoutUserInput>;
};

export type BusAttendantCreateOrConnectWithoutBankInput = {
  create: BusAttendantCreateWithoutBankInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantCreateOrConnectWithoutBusAttendantHoursWorkedInput = {
  create: BusAttendantCreateWithoutBusAttendantHoursWorkedInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantCreateOrConnectWithoutBusAttendantStatusHistoryInput = {
  create: BusAttendantCreateWithoutBusAttendantStatusHistoryInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantCreateOrConnectWithoutBusAttendantViolationComplianceInput =
  {
    create: BusAttendantCreateWithoutBusAttendantViolationComplianceInput;
    where: BusAttendantWhereUniqueInput;
  };

export type BusAttendantCreateOrConnectWithoutContractTypeInput = {
  create: BusAttendantCreateWithoutContractTypeInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantCreateOrConnectWithoutNewBusAttendantTripLogsInput = {
  create: BusAttendantCreateWithoutNewBusAttendantTripLogsInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantCreateOrConnectWithoutOldBusAttendantTripLogsInput = {
  create: BusAttendantCreateWithoutOldBusAttendantTripLogsInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantCreateOrConnectWithoutRouteBusAttendantVacantAlertsInput =
  {
    create: BusAttendantCreateWithoutRouteBusAttendantVacantAlertsInput;
    where: BusAttendantWhereUniqueInput;
  };

export type BusAttendantCreateOrConnectWithoutRoutesInput = {
  create: BusAttendantCreateWithoutRoutesInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantCreateOrConnectWithoutTripsInput = {
  create: BusAttendantCreateWithoutTripsInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantCreateOrConnectWithoutUserInput = {
  create: BusAttendantCreateWithoutUserInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantCreateOrConnectWithoutZoneInput = {
  create: BusAttendantCreateWithoutZoneInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantCreateWithoutBankInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateWithoutBusAttendantHoursWorkedInput = {
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateWithoutBusAttendantStatusHistoryInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateWithoutBusAttendantViolationComplianceInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateWithoutContractTypeInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateWithoutNewBusAttendantTripLogsInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateWithoutOldBusAttendantTripLogsInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateWithoutRouteBusAttendantVacantAlertsInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateWithoutRoutesInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateWithoutTripsInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateWithoutUserInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutBusAttendantInput>;
};

export type BusAttendantCreateWithoutZoneInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBusAttendantInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutBusAttendantInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewBusAttendantInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldBusAttendantInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput>;
  routes?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutBusAttendantInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutBusAttendantInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutBusAttendantInput;
};

export type BusAttendantForBaBd = {
  __typename?: "BusAttendantForBaBd";
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  middleNameAr?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
};

export type BusAttendantForDriver = {
  __typename?: "BusAttendantForDriver";
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type BusAttendantForSponsor = {
  __typename?: "BusAttendantForSponsor";
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type BusAttendantHoursWorked = {
  __typename?: "BusAttendantHoursWorked";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendant: BusAttendant;
  busAttendantId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  fromDate: Scalars["DateTime"]["output"];
  hours: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  toDate: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type BusAttendantHoursWorkedCreateInput = {
  busAttendant: BusAttendantCreateNestedOneWithoutBusAttendantHoursWorkedInput;
  fromDate: Scalars["DateTime"]["input"];
  hours: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantHoursWorkedCreateManyBusAttendantInput = {
  fromDate: Scalars["DateTime"]["input"];
  hours: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantHoursWorkedCreateManyBusAttendantInputEnvelope = {
  data: Array<BusAttendantHoursWorkedCreateManyBusAttendantInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BusAttendantHoursWorkedCreateNestedManyWithoutBusAttendantInput = {
  connect?: InputMaybe<Array<BusAttendantHoursWorkedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BusAttendantHoursWorkedCreateOrConnectWithoutBusAttendantInput>
  >;
  create?: InputMaybe<
    Array<BusAttendantHoursWorkedCreateWithoutBusAttendantInput>
  >;
  createMany?: InputMaybe<BusAttendantHoursWorkedCreateManyBusAttendantInputEnvelope>;
};

export type BusAttendantHoursWorkedCreateOrConnectWithoutBusAttendantInput = {
  create: BusAttendantHoursWorkedCreateWithoutBusAttendantInput;
  where: BusAttendantHoursWorkedWhereUniqueInput;
};

export type BusAttendantHoursWorkedCreateWithoutBusAttendantInput = {
  fromDate: Scalars["DateTime"]["input"];
  hours: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantHoursWorkedListRelationFilter = {
  every?: InputMaybe<BusAttendantHoursWorkedWhereInput>;
  none?: InputMaybe<BusAttendantHoursWorkedWhereInput>;
  some?: InputMaybe<BusAttendantHoursWorkedWhereInput>;
};

export type BusAttendantHoursWorkedScalarWhereInput = {
  AND?: InputMaybe<Array<BusAttendantHoursWorkedScalarWhereInput>>;
  NOT?: InputMaybe<Array<BusAttendantHoursWorkedScalarWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantHoursWorkedScalarWhereInput>>;
  busAttendantId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  hours?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusAttendantHoursWorkedUpdateInput = {
  busAttendant?: InputMaybe<BusAttendantUpdateOneRequiredWithoutBusAttendantHoursWorkedNestedInput>;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  hours?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantHoursWorkedUpdateManyMutationInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  hours?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantHoursWorkedUpdateManyWithWhereWithoutBusAttendantInput =
  {
    data: BusAttendantHoursWorkedUpdateManyMutationInput;
    where: BusAttendantHoursWorkedScalarWhereInput;
  };

export type BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput = {
  connect?: InputMaybe<Array<BusAttendantHoursWorkedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BusAttendantHoursWorkedCreateOrConnectWithoutBusAttendantInput>
  >;
  create?: InputMaybe<
    Array<BusAttendantHoursWorkedCreateWithoutBusAttendantInput>
  >;
  createMany?: InputMaybe<BusAttendantHoursWorkedCreateManyBusAttendantInputEnvelope>;
  delete?: InputMaybe<Array<BusAttendantHoursWorkedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BusAttendantHoursWorkedScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BusAttendantHoursWorkedWhereUniqueInput>>;
  set?: InputMaybe<Array<BusAttendantHoursWorkedWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BusAttendantHoursWorkedUpdateWithWhereUniqueWithoutBusAttendantInput>
  >;
  updateMany?: InputMaybe<
    Array<BusAttendantHoursWorkedUpdateManyWithWhereWithoutBusAttendantInput>
  >;
  upsert?: InputMaybe<
    Array<BusAttendantHoursWorkedUpsertWithWhereUniqueWithoutBusAttendantInput>
  >;
};

export type BusAttendantHoursWorkedUpdateWithWhereUniqueWithoutBusAttendantInput =
  {
    data: BusAttendantHoursWorkedUpdateWithoutBusAttendantInput;
    where: BusAttendantHoursWorkedWhereUniqueInput;
  };

export type BusAttendantHoursWorkedUpdateWithoutBusAttendantInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  hours?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantHoursWorkedUpsertWithWhereUniqueWithoutBusAttendantInput =
  {
    create: BusAttendantHoursWorkedCreateWithoutBusAttendantInput;
    update: BusAttendantHoursWorkedUpdateWithoutBusAttendantInput;
    where: BusAttendantHoursWorkedWhereUniqueInput;
  };

export type BusAttendantHoursWorkedWhereInput = {
  AND?: InputMaybe<Array<BusAttendantHoursWorkedWhereInput>>;
  NOT?: InputMaybe<Array<BusAttendantHoursWorkedWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantHoursWorkedWhereInput>>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  hours?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusAttendantHoursWorkedWhereUniqueInput = {
  AND?: InputMaybe<Array<BusAttendantHoursWorkedWhereInput>>;
  NOT?: InputMaybe<Array<BusAttendantHoursWorkedWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantHoursWorkedWhereInput>>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  hours?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusAttendantInfoExtraInfo = {
  __typename?: "BusAttendantInfoExtraInfo";
  busAttendantName?: Maybe<Scalars["String"]["output"]>;
  busAttendantNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type BusAttendantListRelationFilter = {
  every?: InputMaybe<BusAttendantWhereInput>;
  none?: InputMaybe<BusAttendantWhereInput>;
  some?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantRelationFilter = {
  is?: InputMaybe<BusAttendantWhereInput>;
  isNot?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantScalarWhereInput = {
  AND?: InputMaybe<Array<BusAttendantScalarWhereInput>>;
  NOT?: InputMaybe<Array<BusAttendantScalarWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantScalarWhereInput>>;
  bankAccountName?: InputMaybe<StringFilter>;
  bankAccountNumber?: InputMaybe<StringFilter>;
  bankId?: InputMaybe<StringFilter>;
  contractOriginalStartDate?: InputMaybe<DateTimeFilter>;
  contractStartDate?: InputMaybe<DateTimeFilter>;
  contractTypeId?: InputMaybe<StringFilter>;
  dateOfBirth?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  nationalId?: InputMaybe<StringFilter>;
  nationalIdExpiryDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumBusAttendantStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  terminationDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  zoneId?: InputMaybe<StringFilter>;
};

export enum BusAttendantStatus {
  ACTIVE = "ACTIVE",
  ASSIGNED = "ASSIGNED",
  EMERGENCY = "EMERGENCY",
  INACTIVE = "INACTIVE",
  ONBOARDING = "ONBOARDING",
  POOL = "POOL",
  RESIGNED = "RESIGNED",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
}

export type BusAttendantStatusHistory = {
  __typename?: "BusAttendantStatusHistory";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendant: BusAttendant;
  busAttendantId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  status: BusAttendantStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type BusAttendantStatusHistoryCreateInput = {
  busAttendant: BusAttendantCreateNestedOneWithoutBusAttendantStatusHistoryInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status: BusAttendantStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantStatusHistoryCreateManyBusAttendantInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status: BusAttendantStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantStatusHistoryCreateManyBusAttendantInputEnvelope = {
  data: Array<BusAttendantStatusHistoryCreateManyBusAttendantInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BusAttendantStatusHistoryCreateNestedManyWithoutBusAttendantInput =
  {
    connect?: InputMaybe<Array<BusAttendantStatusHistoryWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<BusAttendantStatusHistoryCreateOrConnectWithoutBusAttendantInput>
    >;
    create?: InputMaybe<
      Array<BusAttendantStatusHistoryCreateWithoutBusAttendantInput>
    >;
    createMany?: InputMaybe<BusAttendantStatusHistoryCreateManyBusAttendantInputEnvelope>;
  };

export type BusAttendantStatusHistoryCreateOrConnectWithoutBusAttendantInput = {
  create: BusAttendantStatusHistoryCreateWithoutBusAttendantInput;
  where: BusAttendantStatusHistoryWhereUniqueInput;
};

export type BusAttendantStatusHistoryCreateWithoutBusAttendantInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status: BusAttendantStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantStatusHistoryListRelationFilter = {
  every?: InputMaybe<BusAttendantStatusHistoryWhereInput>;
  none?: InputMaybe<BusAttendantStatusHistoryWhereInput>;
  some?: InputMaybe<BusAttendantStatusHistoryWhereInput>;
};

export type BusAttendantStatusHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<BusAttendantStatusHistoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<BusAttendantStatusHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantStatusHistoryScalarWhereInput>>;
  busAttendantId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumBusAttendantStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusAttendantStatusHistoryUpdateInput = {
  busAttendant?: InputMaybe<BusAttendantUpdateOneRequiredWithoutBusAttendantStatusHistoryNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<BusAttendantStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantStatusHistoryUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<BusAttendantStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantStatusHistoryUpdateManyWithWhereWithoutBusAttendantInput =
  {
    data: BusAttendantStatusHistoryUpdateManyMutationInput;
    where: BusAttendantStatusHistoryScalarWhereInput;
  };

export type BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput =
  {
    connect?: InputMaybe<Array<BusAttendantStatusHistoryWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<BusAttendantStatusHistoryCreateOrConnectWithoutBusAttendantInput>
    >;
    create?: InputMaybe<
      Array<BusAttendantStatusHistoryCreateWithoutBusAttendantInput>
    >;
    createMany?: InputMaybe<BusAttendantStatusHistoryCreateManyBusAttendantInputEnvelope>;
    delete?: InputMaybe<Array<BusAttendantStatusHistoryWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<BusAttendantStatusHistoryScalarWhereInput>>;
    disconnect?: InputMaybe<Array<BusAttendantStatusHistoryWhereUniqueInput>>;
    set?: InputMaybe<Array<BusAttendantStatusHistoryWhereUniqueInput>>;
    update?: InputMaybe<
      Array<BusAttendantStatusHistoryUpdateWithWhereUniqueWithoutBusAttendantInput>
    >;
    updateMany?: InputMaybe<
      Array<BusAttendantStatusHistoryUpdateManyWithWhereWithoutBusAttendantInput>
    >;
    upsert?: InputMaybe<
      Array<BusAttendantStatusHistoryUpsertWithWhereUniqueWithoutBusAttendantInput>
    >;
  };

export type BusAttendantStatusHistoryUpdateWithWhereUniqueWithoutBusAttendantInput =
  {
    data: BusAttendantStatusHistoryUpdateWithoutBusAttendantInput;
    where: BusAttendantStatusHistoryWhereUniqueInput;
  };

export type BusAttendantStatusHistoryUpdateWithoutBusAttendantInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<BusAttendantStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantStatusHistoryUpsertWithWhereUniqueWithoutBusAttendantInput =
  {
    create: BusAttendantStatusHistoryCreateWithoutBusAttendantInput;
    update: BusAttendantStatusHistoryUpdateWithoutBusAttendantInput;
    where: BusAttendantStatusHistoryWhereUniqueInput;
  };

export type BusAttendantStatusHistoryWhereInput = {
  AND?: InputMaybe<Array<BusAttendantStatusHistoryWhereInput>>;
  NOT?: InputMaybe<Array<BusAttendantStatusHistoryWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantStatusHistoryWhereInput>>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumBusAttendantStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusAttendantStatusHistoryWhereUniqueInput = {
  AND?: InputMaybe<Array<BusAttendantStatusHistoryWhereInput>>;
  NOT?: InputMaybe<Array<BusAttendantStatusHistoryWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantStatusHistoryWhereInput>>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumBusAttendantStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusAttendantUpdateInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateManyMutationInput = {
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantUpdateManyWithWhereWithoutBankInput = {
  data: BusAttendantUpdateManyMutationInput;
  where: BusAttendantScalarWhereInput;
};

export type BusAttendantUpdateManyWithWhereWithoutContractTypeInput = {
  data: BusAttendantUpdateManyMutationInput;
  where: BusAttendantScalarWhereInput;
};

export type BusAttendantUpdateManyWithWhereWithoutZoneInput = {
  data: BusAttendantUpdateManyMutationInput;
  where: BusAttendantScalarWhereInput;
};

export type BusAttendantUpdateManyWithoutBankNestedInput = {
  connect?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BusAttendantCreateOrConnectWithoutBankInput>
  >;
  create?: InputMaybe<Array<BusAttendantCreateWithoutBankInput>>;
  createMany?: InputMaybe<BusAttendantCreateManyBankInputEnvelope>;
  delete?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BusAttendantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  set?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  update?: InputMaybe<Array<BusAttendantUpdateWithWhereUniqueWithoutBankInput>>;
  updateMany?: InputMaybe<
    Array<BusAttendantUpdateManyWithWhereWithoutBankInput>
  >;
  upsert?: InputMaybe<Array<BusAttendantUpsertWithWhereUniqueWithoutBankInput>>;
};

export type BusAttendantUpdateManyWithoutContractTypeNestedInput = {
  connect?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BusAttendantCreateOrConnectWithoutContractTypeInput>
  >;
  create?: InputMaybe<Array<BusAttendantCreateWithoutContractTypeInput>>;
  createMany?: InputMaybe<BusAttendantCreateManyContractTypeInputEnvelope>;
  delete?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BusAttendantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  set?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BusAttendantUpdateWithWhereUniqueWithoutContractTypeInput>
  >;
  updateMany?: InputMaybe<
    Array<BusAttendantUpdateManyWithWhereWithoutContractTypeInput>
  >;
  upsert?: InputMaybe<
    Array<BusAttendantUpsertWithWhereUniqueWithoutContractTypeInput>
  >;
};

export type BusAttendantUpdateManyWithoutZoneNestedInput = {
  connect?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BusAttendantCreateOrConnectWithoutZoneInput>
  >;
  create?: InputMaybe<Array<BusAttendantCreateWithoutZoneInput>>;
  createMany?: InputMaybe<BusAttendantCreateManyZoneInputEnvelope>;
  delete?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BusAttendantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  set?: InputMaybe<Array<BusAttendantWhereUniqueInput>>;
  update?: InputMaybe<Array<BusAttendantUpdateWithWhereUniqueWithoutZoneInput>>;
  updateMany?: InputMaybe<
    Array<BusAttendantUpdateManyWithWhereWithoutZoneInput>
  >;
  upsert?: InputMaybe<Array<BusAttendantUpsertWithWhereUniqueWithoutZoneInput>>;
};

export type BusAttendantUpdateOneRequiredWithoutBusAttendantHoursWorkedNestedInput =
  {
    connect?: InputMaybe<BusAttendantWhereUniqueInput>;
    connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutBusAttendantHoursWorkedInput>;
    create?: InputMaybe<BusAttendantCreateWithoutBusAttendantHoursWorkedInput>;
    update?: InputMaybe<BusAttendantUpdateToOneWithWhereWithoutBusAttendantHoursWorkedInput>;
    upsert?: InputMaybe<BusAttendantUpsertWithoutBusAttendantHoursWorkedInput>;
  };

export type BusAttendantUpdateOneRequiredWithoutBusAttendantStatusHistoryNestedInput =
  {
    connect?: InputMaybe<BusAttendantWhereUniqueInput>;
    connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutBusAttendantStatusHistoryInput>;
    create?: InputMaybe<BusAttendantCreateWithoutBusAttendantStatusHistoryInput>;
    update?: InputMaybe<BusAttendantUpdateToOneWithWhereWithoutBusAttendantStatusHistoryInput>;
    upsert?: InputMaybe<BusAttendantUpsertWithoutBusAttendantStatusHistoryInput>;
  };

export type BusAttendantUpdateOneRequiredWithoutBusAttendantViolationComplianceNestedInput =
  {
    connect?: InputMaybe<BusAttendantWhereUniqueInput>;
    connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutBusAttendantViolationComplianceInput>;
    create?: InputMaybe<BusAttendantCreateWithoutBusAttendantViolationComplianceInput>;
    update?: InputMaybe<BusAttendantUpdateToOneWithWhereWithoutBusAttendantViolationComplianceInput>;
    upsert?: InputMaybe<BusAttendantUpsertWithoutBusAttendantViolationComplianceInput>;
  };

export type BusAttendantUpdateOneRequiredWithoutRoutesNestedInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<BusAttendantCreateWithoutRoutesInput>;
  update?: InputMaybe<BusAttendantUpdateToOneWithWhereWithoutRoutesInput>;
  upsert?: InputMaybe<BusAttendantUpsertWithoutRoutesInput>;
};

export type BusAttendantUpdateOneWithoutNewBusAttendantTripLogsNestedInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutNewBusAttendantTripLogsInput>;
  create?: InputMaybe<BusAttendantCreateWithoutNewBusAttendantTripLogsInput>;
  delete?: InputMaybe<BusAttendantWhereInput>;
  disconnect?: InputMaybe<BusAttendantWhereInput>;
  update?: InputMaybe<BusAttendantUpdateToOneWithWhereWithoutNewBusAttendantTripLogsInput>;
  upsert?: InputMaybe<BusAttendantUpsertWithoutNewBusAttendantTripLogsInput>;
};

export type BusAttendantUpdateOneWithoutOldBusAttendantTripLogsNestedInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutOldBusAttendantTripLogsInput>;
  create?: InputMaybe<BusAttendantCreateWithoutOldBusAttendantTripLogsInput>;
  delete?: InputMaybe<BusAttendantWhereInput>;
  disconnect?: InputMaybe<BusAttendantWhereInput>;
  update?: InputMaybe<BusAttendantUpdateToOneWithWhereWithoutOldBusAttendantTripLogsInput>;
  upsert?: InputMaybe<BusAttendantUpsertWithoutOldBusAttendantTripLogsInput>;
};

export type BusAttendantUpdateOneWithoutRouteBusAttendantVacantAlertsNestedInput =
  {
    connect?: InputMaybe<BusAttendantWhereUniqueInput>;
    connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutRouteBusAttendantVacantAlertsInput>;
    create?: InputMaybe<BusAttendantCreateWithoutRouteBusAttendantVacantAlertsInput>;
    delete?: InputMaybe<BusAttendantWhereInput>;
    disconnect?: InputMaybe<BusAttendantWhereInput>;
    update?: InputMaybe<BusAttendantUpdateToOneWithWhereWithoutRouteBusAttendantVacantAlertsInput>;
    upsert?: InputMaybe<BusAttendantUpsertWithoutRouteBusAttendantVacantAlertsInput>;
  };

export type BusAttendantUpdateOneWithoutTripsNestedInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutTripsInput>;
  create?: InputMaybe<BusAttendantCreateWithoutTripsInput>;
  delete?: InputMaybe<BusAttendantWhereInput>;
  disconnect?: InputMaybe<BusAttendantWhereInput>;
  update?: InputMaybe<BusAttendantUpdateToOneWithWhereWithoutTripsInput>;
  upsert?: InputMaybe<BusAttendantUpsertWithoutTripsInput>;
};

export type BusAttendantUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<BusAttendantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusAttendantCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<BusAttendantCreateWithoutUserInput>;
  delete?: InputMaybe<BusAttendantWhereInput>;
  disconnect?: InputMaybe<BusAttendantWhereInput>;
  update?: InputMaybe<BusAttendantUpdateToOneWithWhereWithoutUserInput>;
  upsert?: InputMaybe<BusAttendantUpsertWithoutUserInput>;
};

export type BusAttendantUpdateToOneWithWhereWithoutBusAttendantHoursWorkedInput =
  {
    data: BusAttendantUpdateWithoutBusAttendantHoursWorkedInput;
    where?: InputMaybe<BusAttendantWhereInput>;
  };

export type BusAttendantUpdateToOneWithWhereWithoutBusAttendantStatusHistoryInput =
  {
    data: BusAttendantUpdateWithoutBusAttendantStatusHistoryInput;
    where?: InputMaybe<BusAttendantWhereInput>;
  };

export type BusAttendantUpdateToOneWithWhereWithoutBusAttendantViolationComplianceInput =
  {
    data: BusAttendantUpdateWithoutBusAttendantViolationComplianceInput;
    where?: InputMaybe<BusAttendantWhereInput>;
  };

export type BusAttendantUpdateToOneWithWhereWithoutNewBusAttendantTripLogsInput =
  {
    data: BusAttendantUpdateWithoutNewBusAttendantTripLogsInput;
    where?: InputMaybe<BusAttendantWhereInput>;
  };

export type BusAttendantUpdateToOneWithWhereWithoutOldBusAttendantTripLogsInput =
  {
    data: BusAttendantUpdateWithoutOldBusAttendantTripLogsInput;
    where?: InputMaybe<BusAttendantWhereInput>;
  };

export type BusAttendantUpdateToOneWithWhereWithoutRouteBusAttendantVacantAlertsInput =
  {
    data: BusAttendantUpdateWithoutRouteBusAttendantVacantAlertsInput;
    where?: InputMaybe<BusAttendantWhereInput>;
  };

export type BusAttendantUpdateToOneWithWhereWithoutRoutesInput = {
  data: BusAttendantUpdateWithoutRoutesInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantUpdateToOneWithWhereWithoutTripsInput = {
  data: BusAttendantUpdateWithoutTripsInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantUpdateToOneWithWhereWithoutUserInput = {
  data: BusAttendantUpdateWithoutUserInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantUpdateWithWhereUniqueWithoutBankInput = {
  data: BusAttendantUpdateWithoutBankInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantUpdateWithWhereUniqueWithoutContractTypeInput = {
  data: BusAttendantUpdateWithoutContractTypeInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantUpdateWithWhereUniqueWithoutZoneInput = {
  data: BusAttendantUpdateWithoutZoneInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantUpdateWithoutBankInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateWithoutBusAttendantHoursWorkedInput = {
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateWithoutBusAttendantStatusHistoryInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateWithoutBusAttendantViolationComplianceInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateWithoutContractTypeInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateWithoutNewBusAttendantTripLogsInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateWithoutOldBusAttendantTripLogsInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateWithoutRouteBusAttendantVacantAlertsInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateWithoutRoutesInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateWithoutTripsInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateWithoutUserInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpdateWithoutZoneInput = {
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryUpdateManyWithoutBusAttendantNestedInput>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutBusAttendantNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutBusAttendantNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewBusAttendantNestedInput>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldBusAttendantNestedInput>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput>;
  routes?: InputMaybe<RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput>;
  status?: InputMaybe<BusAttendantStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutBusAttendantNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutBusAttendantNestedInput>;
};

export type BusAttendantUpsertWithWhereUniqueWithoutBankInput = {
  create: BusAttendantCreateWithoutBankInput;
  update: BusAttendantUpdateWithoutBankInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantUpsertWithWhereUniqueWithoutContractTypeInput = {
  create: BusAttendantCreateWithoutContractTypeInput;
  update: BusAttendantUpdateWithoutContractTypeInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantUpsertWithWhereUniqueWithoutZoneInput = {
  create: BusAttendantCreateWithoutZoneInput;
  update: BusAttendantUpdateWithoutZoneInput;
  where: BusAttendantWhereUniqueInput;
};

export type BusAttendantUpsertWithoutBusAttendantHoursWorkedInput = {
  create: BusAttendantCreateWithoutBusAttendantHoursWorkedInput;
  update: BusAttendantUpdateWithoutBusAttendantHoursWorkedInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantUpsertWithoutBusAttendantStatusHistoryInput = {
  create: BusAttendantCreateWithoutBusAttendantStatusHistoryInput;
  update: BusAttendantUpdateWithoutBusAttendantStatusHistoryInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantUpsertWithoutBusAttendantViolationComplianceInput = {
  create: BusAttendantCreateWithoutBusAttendantViolationComplianceInput;
  update: BusAttendantUpdateWithoutBusAttendantViolationComplianceInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantUpsertWithoutNewBusAttendantTripLogsInput = {
  create: BusAttendantCreateWithoutNewBusAttendantTripLogsInput;
  update: BusAttendantUpdateWithoutNewBusAttendantTripLogsInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantUpsertWithoutOldBusAttendantTripLogsInput = {
  create: BusAttendantCreateWithoutOldBusAttendantTripLogsInput;
  update: BusAttendantUpdateWithoutOldBusAttendantTripLogsInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantUpsertWithoutRouteBusAttendantVacantAlertsInput = {
  create: BusAttendantCreateWithoutRouteBusAttendantVacantAlertsInput;
  update: BusAttendantUpdateWithoutRouteBusAttendantVacantAlertsInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantUpsertWithoutRoutesInput = {
  create: BusAttendantCreateWithoutRoutesInput;
  update: BusAttendantUpdateWithoutRoutesInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantUpsertWithoutTripsInput = {
  create: BusAttendantCreateWithoutTripsInput;
  update: BusAttendantUpdateWithoutTripsInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantUpsertWithoutUserInput = {
  create: BusAttendantCreateWithoutUserInput;
  update: BusAttendantUpdateWithoutUserInput;
  where?: InputMaybe<BusAttendantWhereInput>;
};

export type BusAttendantViolationCompliance = {
  __typename?: "BusAttendantViolationCompliance";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendant: BusAttendant;
  busAttendantId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  date: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  type: BusAttendantViolationComplianceType;
  updatedAt: Scalars["DateTime"]["output"];
};

export type BusAttendantViolationComplianceCreateInput = {
  busAttendant: BusAttendantCreateNestedOneWithoutBusAttendantViolationComplianceInput;
  date: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type: BusAttendantViolationComplianceType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantViolationComplianceCreateManyBusAttendantInput = {
  date: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type: BusAttendantViolationComplianceType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantViolationComplianceCreateManyBusAttendantInputEnvelope =
  {
    data: Array<BusAttendantViolationComplianceCreateManyBusAttendantInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type BusAttendantViolationComplianceCreateNestedManyWithoutBusAttendantInput =
  {
    connect?: InputMaybe<
      Array<BusAttendantViolationComplianceWhereUniqueInput>
    >;
    connectOrCreate?: InputMaybe<
      Array<BusAttendantViolationComplianceCreateOrConnectWithoutBusAttendantInput>
    >;
    create?: InputMaybe<
      Array<BusAttendantViolationComplianceCreateWithoutBusAttendantInput>
    >;
    createMany?: InputMaybe<BusAttendantViolationComplianceCreateManyBusAttendantInputEnvelope>;
  };

export type BusAttendantViolationComplianceCreateOrConnectWithoutBusAttendantInput =
  {
    create: BusAttendantViolationComplianceCreateWithoutBusAttendantInput;
    where: BusAttendantViolationComplianceWhereUniqueInput;
  };

export type BusAttendantViolationComplianceCreateWithoutBusAttendantInput = {
  date: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type: BusAttendantViolationComplianceType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantViolationComplianceListRelationFilter = {
  every?: InputMaybe<BusAttendantViolationComplianceWhereInput>;
  none?: InputMaybe<BusAttendantViolationComplianceWhereInput>;
  some?: InputMaybe<BusAttendantViolationComplianceWhereInput>;
};

export type BusAttendantViolationComplianceScalarWhereInput = {
  AND?: InputMaybe<Array<BusAttendantViolationComplianceScalarWhereInput>>;
  NOT?: InputMaybe<Array<BusAttendantViolationComplianceScalarWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantViolationComplianceScalarWhereInput>>;
  busAttendantId?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumBusAttendantViolationComplianceTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum BusAttendantViolationComplianceType {
  ABSENCE = "ABSENCE",
  ABSENCE_EVENING_SHIFT = "ABSENCE_EVENING_SHIFT",
  ABSENCE_MORNING_SHIFT = "ABSENCE_MORNING_SHIFT",
  ALLOWING_EAT_AND_FOOD = "ALLOWING_EAT_AND_FOOD",
  ATTITUDE_MANNERS = "ATTITUDE_MANNERS",
  DELAY = "DELAY",
  KID_HAND_OVER_TO_STRANGERS = "KID_HAND_OVER_TO_STRANGERS",
  STUDENT_PICK_AND_DROP = "STUDENT_PICK_AND_DROP",
  TAKING_PHOTOS_OF_STUDENT = "TAKING_PHOTOS_OF_STUDENT",
}

export type BusAttendantViolationComplianceUpdateInput = {
  busAttendant?: InputMaybe<BusAttendantUpdateOneRequiredWithoutBusAttendantViolationComplianceNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<BusAttendantViolationComplianceType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantViolationComplianceUpdateManyMutationInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<BusAttendantViolationComplianceType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantViolationComplianceUpdateManyWithWhereWithoutBusAttendantInput =
  {
    data: BusAttendantViolationComplianceUpdateManyMutationInput;
    where: BusAttendantViolationComplianceScalarWhereInput;
  };

export type BusAttendantViolationComplianceUpdateManyWithoutBusAttendantNestedInput =
  {
    connect?: InputMaybe<
      Array<BusAttendantViolationComplianceWhereUniqueInput>
    >;
    connectOrCreate?: InputMaybe<
      Array<BusAttendantViolationComplianceCreateOrConnectWithoutBusAttendantInput>
    >;
    create?: InputMaybe<
      Array<BusAttendantViolationComplianceCreateWithoutBusAttendantInput>
    >;
    createMany?: InputMaybe<BusAttendantViolationComplianceCreateManyBusAttendantInputEnvelope>;
    delete?: InputMaybe<Array<BusAttendantViolationComplianceWhereUniqueInput>>;
    deleteMany?: InputMaybe<
      Array<BusAttendantViolationComplianceScalarWhereInput>
    >;
    disconnect?: InputMaybe<
      Array<BusAttendantViolationComplianceWhereUniqueInput>
    >;
    set?: InputMaybe<Array<BusAttendantViolationComplianceWhereUniqueInput>>;
    update?: InputMaybe<
      Array<BusAttendantViolationComplianceUpdateWithWhereUniqueWithoutBusAttendantInput>
    >;
    updateMany?: InputMaybe<
      Array<BusAttendantViolationComplianceUpdateManyWithWhereWithoutBusAttendantInput>
    >;
    upsert?: InputMaybe<
      Array<BusAttendantViolationComplianceUpsertWithWhereUniqueWithoutBusAttendantInput>
    >;
  };

export type BusAttendantViolationComplianceUpdateWithWhereUniqueWithoutBusAttendantInput =
  {
    data: BusAttendantViolationComplianceUpdateWithoutBusAttendantInput;
    where: BusAttendantViolationComplianceWhereUniqueInput;
  };

export type BusAttendantViolationComplianceUpdateWithoutBusAttendantInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<BusAttendantViolationComplianceType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BusAttendantViolationComplianceUpsertWithWhereUniqueWithoutBusAttendantInput =
  {
    create: BusAttendantViolationComplianceCreateWithoutBusAttendantInput;
    update: BusAttendantViolationComplianceUpdateWithoutBusAttendantInput;
    where: BusAttendantViolationComplianceWhereUniqueInput;
  };

export type BusAttendantViolationComplianceWhereInput = {
  AND?: InputMaybe<Array<BusAttendantViolationComplianceWhereInput>>;
  NOT?: InputMaybe<Array<BusAttendantViolationComplianceWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantViolationComplianceWhereInput>>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumBusAttendantViolationComplianceTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusAttendantViolationComplianceWhereUniqueInput = {
  AND?: InputMaybe<Array<BusAttendantViolationComplianceWhereInput>>;
  NOT?: InputMaybe<Array<BusAttendantViolationComplianceWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantViolationComplianceWhereInput>>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumBusAttendantViolationComplianceTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusAttendantWhereInput = {
  AND?: InputMaybe<Array<BusAttendantWhereInput>>;
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedListRelationFilter>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryListRelationFilter>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceListRelationFilter>;
  NOT?: InputMaybe<Array<BusAttendantWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantWhereInput>>;
  bank?: InputMaybe<BankRelationFilter>;
  bankAccountName?: InputMaybe<StringFilter>;
  bankAccountNumber?: InputMaybe<StringFilter>;
  bankId?: InputMaybe<StringFilter>;
  contractOriginalStartDate?: InputMaybe<DateTimeFilter>;
  contractStartDate?: InputMaybe<DateTimeFilter>;
  contractType?: InputMaybe<ContractTypeRelationFilter>;
  contractTypeId?: InputMaybe<StringFilter>;
  dateOfBirth?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  nationalId?: InputMaybe<StringFilter>;
  nationalIdExpiryDate?: InputMaybe<DateTimeFilter>;
  newBusAttendantTripLogs?: InputMaybe<TripLogListRelationFilter>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogListRelationFilter>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertListRelationFilter>;
  routes?: InputMaybe<RouteBusAttendantListRelationFilter>;
  status?: InputMaybe<EnumBusAttendantStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  terminationDate?: InputMaybe<DateTimeFilter>;
  trips?: InputMaybe<TripListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  zone?: InputMaybe<EmployeeZoneRelationFilter>;
  zoneId?: InputMaybe<StringFilter>;
};

export type BusAttendantWhereUniqueInput = {
  AND?: InputMaybe<Array<BusAttendantWhereInput>>;
  BusAttendantHoursWorked?: InputMaybe<BusAttendantHoursWorkedListRelationFilter>;
  BusAttendantStatusHistory?: InputMaybe<BusAttendantStatusHistoryListRelationFilter>;
  BusAttendantViolationCompliance?: InputMaybe<BusAttendantViolationComplianceListRelationFilter>;
  NOT?: InputMaybe<Array<BusAttendantWhereInput>>;
  OR?: InputMaybe<Array<BusAttendantWhereInput>>;
  bank?: InputMaybe<BankRelationFilter>;
  bankAccountName?: InputMaybe<StringFilter>;
  bankAccountNumber?: InputMaybe<StringFilter>;
  bankId?: InputMaybe<StringFilter>;
  contractOriginalStartDate?: InputMaybe<DateTimeFilter>;
  contractStartDate?: InputMaybe<DateTimeFilter>;
  contractType?: InputMaybe<ContractTypeRelationFilter>;
  contractTypeId?: InputMaybe<StringFilter>;
  dateOfBirth?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<DateTimeFilter>;
  newBusAttendantTripLogs?: InputMaybe<TripLogListRelationFilter>;
  oldBusAttendantTripLogs?: InputMaybe<TripLogListRelationFilter>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertListRelationFilter>;
  routes?: InputMaybe<RouteBusAttendantListRelationFilter>;
  status?: InputMaybe<EnumBusAttendantStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  terminationDate?: InputMaybe<DateTimeFilter>;
  trips?: InputMaybe<TripListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  zone?: InputMaybe<EmployeeZoneRelationFilter>;
  zoneId?: InputMaybe<StringFilter>;
};

export type CancelLeaveRequestInput = {
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel?: InputMaybe<LeaveRequestChannel>;
  sponsorUserId: Scalars["String"]["input"];
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CancelledLeaveRequest = {
  __typename?: "CancelledLeaveRequest";
  cancellationReason?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  leaveRequest: LeaveRequest;
  leaveRequestId: Scalars["String"]["output"];
  startDate: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type CancelledLeaveRequestCreateManyLeaveRequestInput = {
  cancellationReason?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CancelledLeaveRequestCreateManyLeaveRequestInputEnvelope = {
  data: Array<CancelledLeaveRequestCreateManyLeaveRequestInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CancelledLeaveRequestCreateNestedManyWithoutLeaveRequestInput = {
  connect?: InputMaybe<Array<CancelledLeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CancelledLeaveRequestCreateOrConnectWithoutLeaveRequestInput>
  >;
  create?: InputMaybe<
    Array<CancelledLeaveRequestCreateWithoutLeaveRequestInput>
  >;
  createMany?: InputMaybe<CancelledLeaveRequestCreateManyLeaveRequestInputEnvelope>;
};

export type CancelledLeaveRequestCreateOrConnectWithoutLeaveRequestInput = {
  create: CancelledLeaveRequestCreateWithoutLeaveRequestInput;
  where: CancelledLeaveRequestWhereUniqueInput;
};

export type CancelledLeaveRequestCreateWithoutLeaveRequestInput = {
  cancellationReason?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CancelledLeaveRequestListRelationFilter = {
  every?: InputMaybe<CancelledLeaveRequestWhereInput>;
  none?: InputMaybe<CancelledLeaveRequestWhereInput>;
  some?: InputMaybe<CancelledLeaveRequestWhereInput>;
};

export type CancelledLeaveRequestScalarWhereInput = {
  AND?: InputMaybe<Array<CancelledLeaveRequestScalarWhereInput>>;
  NOT?: InputMaybe<Array<CancelledLeaveRequestScalarWhereInput>>;
  OR?: InputMaybe<Array<CancelledLeaveRequestScalarWhereInput>>;
  cancellationReason?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  leaveRequestId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CancelledLeaveRequestUpdateManyMutationInput = {
  cancellationReason?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CancelledLeaveRequestUpdateManyWithWhereWithoutLeaveRequestInput = {
  data: CancelledLeaveRequestUpdateManyMutationInput;
  where: CancelledLeaveRequestScalarWhereInput;
};

export type CancelledLeaveRequestUpdateManyWithoutLeaveRequestNestedInput = {
  connect?: InputMaybe<Array<CancelledLeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CancelledLeaveRequestCreateOrConnectWithoutLeaveRequestInput>
  >;
  create?: InputMaybe<
    Array<CancelledLeaveRequestCreateWithoutLeaveRequestInput>
  >;
  createMany?: InputMaybe<CancelledLeaveRequestCreateManyLeaveRequestInputEnvelope>;
  delete?: InputMaybe<Array<CancelledLeaveRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CancelledLeaveRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CancelledLeaveRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<CancelledLeaveRequestWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CancelledLeaveRequestUpdateWithWhereUniqueWithoutLeaveRequestInput>
  >;
  updateMany?: InputMaybe<
    Array<CancelledLeaveRequestUpdateManyWithWhereWithoutLeaveRequestInput>
  >;
  upsert?: InputMaybe<
    Array<CancelledLeaveRequestUpsertWithWhereUniqueWithoutLeaveRequestInput>
  >;
};

export type CancelledLeaveRequestUpdateWithWhereUniqueWithoutLeaveRequestInput =
  {
    data: CancelledLeaveRequestUpdateWithoutLeaveRequestInput;
    where: CancelledLeaveRequestWhereUniqueInput;
  };

export type CancelledLeaveRequestUpdateWithoutLeaveRequestInput = {
  cancellationReason?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CancelledLeaveRequestUpsertWithWhereUniqueWithoutLeaveRequestInput =
  {
    create: CancelledLeaveRequestCreateWithoutLeaveRequestInput;
    update: CancelledLeaveRequestUpdateWithoutLeaveRequestInput;
    where: CancelledLeaveRequestWhereUniqueInput;
  };

export type CancelledLeaveRequestWhereInput = {
  AND?: InputMaybe<Array<CancelledLeaveRequestWhereInput>>;
  NOT?: InputMaybe<Array<CancelledLeaveRequestWhereInput>>;
  OR?: InputMaybe<Array<CancelledLeaveRequestWhereInput>>;
  cancellationReason?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  leaveRequest?: InputMaybe<LeaveRequestRelationFilter>;
  leaveRequestId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CancelledLeaveRequestWhereUniqueInput = {
  AND?: InputMaybe<Array<CancelledLeaveRequestWhereInput>>;
  NOT?: InputMaybe<Array<CancelledLeaveRequestWhereInput>>;
  OR?: InputMaybe<Array<CancelledLeaveRequestWhereInput>>;
  cancellationReason?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequest?: InputMaybe<LeaveRequestRelationFilter>;
  leaveRequestId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum CardStatus {
  DROP_OFF = "DROP_OFF",
  LOST = "LOST",
  NEARBY = "NEARBY",
  ONBOARD = "ONBOARD",
  UNKNOWN = "UNKNOWN",
}

export type CashOutRequest = {
  __typename?: "CashOutRequest";
  amount: Scalars["Float"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  cashOutAt?: Maybe<Scalars["DateTime"]["output"]>;
  cashOutPaymentTransactionId?: Maybe<Scalars["String"]["output"]>;
  comments?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  creditNote?: Maybe<CreditNote>;
  creditNoteId?: Maybe<Scalars["String"]["output"]>;
  creditSource: CashOutRequestCreditSource;
  currency: CurrencyCode;
  id: Scalars["String"]["output"];
  isBulkRequest?: Maybe<Scalars["Boolean"]["output"]>;
  requestId: Scalars["Int"]["output"];
  requestStatus: CashOutRequestStatus;
  requestedBy?: Maybe<User>;
  requestedById?: Maybe<Scalars["String"]["output"]>;
  sponsor: Sponsor;
  sponsorId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type CashOutRequestCreateInputWithConnectSponsor = {
  amount: Scalars["Float"]["input"];
  currency: CurrencyCode;
  sponsor: SponsorCreateNestedOneWithoutCashOutRequestsInputAndWithoutCreate;
};

export type CashOutRequestCreateManyCreditNoteInput = {
  amount: Scalars["Float"]["input"];
  cashOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  creditSource?: InputMaybe<CashOutRequestCreditSource>;
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isBulkRequest?: InputMaybe<Scalars["Boolean"]["input"]>;
  requestId?: InputMaybe<Scalars["Int"]["input"]>;
  requestStatus: CashOutRequestStatus;
  requestedById?: InputMaybe<Scalars["String"]["input"]>;
  sponsorId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CashOutRequestCreateManyCreditNoteInputEnvelope = {
  data: Array<CashOutRequestCreateManyCreditNoteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CashOutRequestCreateManyRequestedByInput = {
  amount: Scalars["Float"]["input"];
  cashOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  creditNoteId?: InputMaybe<Scalars["String"]["input"]>;
  creditSource?: InputMaybe<CashOutRequestCreditSource>;
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isBulkRequest?: InputMaybe<Scalars["Boolean"]["input"]>;
  requestId?: InputMaybe<Scalars["Int"]["input"]>;
  requestStatus: CashOutRequestStatus;
  sponsorId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CashOutRequestCreateManyRequestedByInputEnvelope = {
  data: Array<CashOutRequestCreateManyRequestedByInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CashOutRequestCreateManySponsorInput = {
  amount: Scalars["Float"]["input"];
  cashOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  creditNoteId?: InputMaybe<Scalars["String"]["input"]>;
  creditSource?: InputMaybe<CashOutRequestCreditSource>;
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isBulkRequest?: InputMaybe<Scalars["Boolean"]["input"]>;
  requestId?: InputMaybe<Scalars["Int"]["input"]>;
  requestStatus: CashOutRequestStatus;
  requestedById?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CashOutRequestCreateManySponsorInputEnvelope = {
  data: Array<CashOutRequestCreateManySponsorInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CashOutRequestCreateNestedManyWithoutCreditNoteInput = {
  connect?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CashOutRequestCreateOrConnectWithoutCreditNoteInput>
  >;
  create?: InputMaybe<Array<CashOutRequestCreateWithoutCreditNoteInput>>;
  createMany?: InputMaybe<CashOutRequestCreateManyCreditNoteInputEnvelope>;
};

export type CashOutRequestCreateNestedManyWithoutRequestedByInput = {
  connect?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CashOutRequestCreateOrConnectWithoutRequestedByInput>
  >;
  create?: InputMaybe<Array<CashOutRequestCreateWithoutRequestedByInput>>;
  createMany?: InputMaybe<CashOutRequestCreateManyRequestedByInputEnvelope>;
};

export type CashOutRequestCreateNestedManyWithoutSponsorInput = {
  connect?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CashOutRequestCreateOrConnectWithoutSponsorInput>
  >;
  create?: InputMaybe<Array<CashOutRequestCreateWithoutSponsorInput>>;
  createMany?: InputMaybe<CashOutRequestCreateManySponsorInputEnvelope>;
};

export type CashOutRequestCreateOrConnectWithoutCreditNoteInput = {
  create: CashOutRequestCreateWithoutCreditNoteInput;
  where: CashOutRequestWhereUniqueInput;
};

export type CashOutRequestCreateOrConnectWithoutRequestedByInput = {
  create: CashOutRequestCreateWithoutRequestedByInput;
  where: CashOutRequestWhereUniqueInput;
};

export type CashOutRequestCreateOrConnectWithoutSponsorInput = {
  create: CashOutRequestCreateWithoutSponsorInput;
  where: CashOutRequestWhereUniqueInput;
};

export type CashOutRequestCreateWithoutCreditNoteInput = {
  amount: Scalars["Float"]["input"];
  cashOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  creditSource?: InputMaybe<CashOutRequestCreditSource>;
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isBulkRequest?: InputMaybe<Scalars["Boolean"]["input"]>;
  requestId?: InputMaybe<Scalars["Int"]["input"]>;
  requestStatus: CashOutRequestStatus;
  requestedBy?: InputMaybe<UserCreateNestedOneWithoutCashOutRequestsInput>;
  sponsor: SponsorCreateNestedOneWithoutCashOutRequestsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CashOutRequestCreateWithoutRequestedByInput = {
  amount: Scalars["Float"]["input"];
  cashOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  creditNote?: InputMaybe<CreditNoteCreateNestedOneWithoutCashOutRequestInput>;
  creditSource?: InputMaybe<CashOutRequestCreditSource>;
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isBulkRequest?: InputMaybe<Scalars["Boolean"]["input"]>;
  requestId?: InputMaybe<Scalars["Int"]["input"]>;
  requestStatus: CashOutRequestStatus;
  sponsor: SponsorCreateNestedOneWithoutCashOutRequestsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CashOutRequestCreateWithoutSponsorInput = {
  amount: Scalars["Float"]["input"];
  cashOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  creditNote?: InputMaybe<CreditNoteCreateNestedOneWithoutCashOutRequestInput>;
  creditSource?: InputMaybe<CashOutRequestCreditSource>;
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isBulkRequest?: InputMaybe<Scalars["Boolean"]["input"]>;
  requestId?: InputMaybe<Scalars["Int"]["input"]>;
  requestStatus: CashOutRequestStatus;
  requestedBy?: InputMaybe<UserCreateNestedOneWithoutCashOutRequestsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum CashOutRequestCreditSource {
  CUSTOMER_RETENTION = "CUSTOMER_RETENTION",
  HISTORICAL_TRANSACTION = "HISTORICAL_TRANSACTION",
  OTHER = "OTHER",
  SERVICE_DISRUPTION = "SERVICE_DISRUPTION",
}

export type CashOutRequestListRelationFilter = {
  every?: InputMaybe<CashOutRequestWhereInput>;
  none?: InputMaybe<CashOutRequestWhereInput>;
  some?: InputMaybe<CashOutRequestWhereInput>;
};

export type CashOutRequestOrderByWithRelationInputFirstOrder = {
  amount?: InputMaybe<SortOrder>;
  cashOutAt?: InputMaybe<SortOrderInput>;
  cashOutPaymentTransactionId?: InputMaybe<SortOrderInput>;
  comments?: InputMaybe<SortOrderInput>;
  creditNoteId?: InputMaybe<SortOrderInput>;
  creditSource?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isBulkRequest?: InputMaybe<SortOrderInput>;
  requestId?: InputMaybe<SortOrder>;
  requestStatus?: InputMaybe<SortOrder>;
  requestedById?: InputMaybe<SortOrderInput>;
  sponsorId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CashOutRequestScalarWhereInput = {
  AND?: InputMaybe<Array<CashOutRequestScalarWhereInput>>;
  NOT?: InputMaybe<Array<CashOutRequestScalarWhereInput>>;
  OR?: InputMaybe<Array<CashOutRequestScalarWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  cashOutAt?: InputMaybe<DateTimeFilter>;
  cashOutPaymentTransactionId?: InputMaybe<StringFilter>;
  comments?: InputMaybe<StringFilter>;
  creditNoteId?: InputMaybe<StringFilter>;
  creditSource?: InputMaybe<EnumCashOutRequestCreditSourceFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  id?: InputMaybe<StringFilter>;
  isBulkRequest?: InputMaybe<BoolFilter>;
  requestId?: InputMaybe<IntFilter>;
  requestStatus?: InputMaybe<EnumCashOutRequestStatusFilter>;
  requestedById?: InputMaybe<StringFilter>;
  sponsorId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum CashOutRequestStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
}

export type CashOutRequestUpdateManyFinanceInput = {
  cashOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  requestStatus?: InputMaybe<CashOutRequestStatus>;
};

export type CashOutRequestUpdateManyMutationInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  cashOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  creditSource?: InputMaybe<CashOutRequestCreditSource>;
  currency?: InputMaybe<CurrencyCode>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isBulkRequest?: InputMaybe<Scalars["Boolean"]["input"]>;
  requestId?: InputMaybe<Scalars["Int"]["input"]>;
  requestStatus?: InputMaybe<CashOutRequestStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CashOutRequestUpdateManyWithWhereWithoutCreditNoteInput = {
  data: CashOutRequestUpdateManyMutationInput;
  where: CashOutRequestScalarWhereInput;
};

export type CashOutRequestUpdateManyWithWhereWithoutRequestedByInput = {
  data: CashOutRequestUpdateManyMutationInput;
  where: CashOutRequestScalarWhereInput;
};

export type CashOutRequestUpdateManyWithWhereWithoutSponsorInput = {
  data: CashOutRequestUpdateManyMutationInput;
  where: CashOutRequestScalarWhereInput;
};

export type CashOutRequestUpdateManyWithoutCreditNoteNestedInput = {
  connect?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CashOutRequestCreateOrConnectWithoutCreditNoteInput>
  >;
  create?: InputMaybe<Array<CashOutRequestCreateWithoutCreditNoteInput>>;
  createMany?: InputMaybe<CashOutRequestCreateManyCreditNoteInputEnvelope>;
  delete?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CashOutRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CashOutRequestUpdateWithWhereUniqueWithoutCreditNoteInput>
  >;
  updateMany?: InputMaybe<
    Array<CashOutRequestUpdateManyWithWhereWithoutCreditNoteInput>
  >;
  upsert?: InputMaybe<
    Array<CashOutRequestUpsertWithWhereUniqueWithoutCreditNoteInput>
  >;
};

export type CashOutRequestUpdateManyWithoutRequestedByNestedInput = {
  connect?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CashOutRequestCreateOrConnectWithoutRequestedByInput>
  >;
  create?: InputMaybe<Array<CashOutRequestCreateWithoutRequestedByInput>>;
  createMany?: InputMaybe<CashOutRequestCreateManyRequestedByInputEnvelope>;
  delete?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CashOutRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CashOutRequestUpdateWithWhereUniqueWithoutRequestedByInput>
  >;
  updateMany?: InputMaybe<
    Array<CashOutRequestUpdateManyWithWhereWithoutRequestedByInput>
  >;
  upsert?: InputMaybe<
    Array<CashOutRequestUpsertWithWhereUniqueWithoutRequestedByInput>
  >;
};

export type CashOutRequestUpdateManyWithoutSponsorNestedInput = {
  connect?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CashOutRequestCreateOrConnectWithoutSponsorInput>
  >;
  create?: InputMaybe<Array<CashOutRequestCreateWithoutSponsorInput>>;
  createMany?: InputMaybe<CashOutRequestCreateManySponsorInputEnvelope>;
  delete?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CashOutRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<CashOutRequestWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CashOutRequestUpdateWithWhereUniqueWithoutSponsorInput>
  >;
  updateMany?: InputMaybe<
    Array<CashOutRequestUpdateManyWithWhereWithoutSponsorInput>
  >;
  upsert?: InputMaybe<
    Array<CashOutRequestUpsertWithWhereUniqueWithoutSponsorInput>
  >;
};

export type CashOutRequestUpdateWithWhereUniqueWithoutCreditNoteInput = {
  data: CashOutRequestUpdateWithoutCreditNoteInput;
  where: CashOutRequestWhereUniqueInput;
};

export type CashOutRequestUpdateWithWhereUniqueWithoutRequestedByInput = {
  data: CashOutRequestUpdateWithoutRequestedByInput;
  where: CashOutRequestWhereUniqueInput;
};

export type CashOutRequestUpdateWithWhereUniqueWithoutSponsorInput = {
  data: CashOutRequestUpdateWithoutSponsorInput;
  where: CashOutRequestWhereUniqueInput;
};

export type CashOutRequestUpdateWithoutCreditNoteInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  cashOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  creditSource?: InputMaybe<CashOutRequestCreditSource>;
  currency?: InputMaybe<CurrencyCode>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isBulkRequest?: InputMaybe<Scalars["Boolean"]["input"]>;
  requestId?: InputMaybe<Scalars["Int"]["input"]>;
  requestStatus?: InputMaybe<CashOutRequestStatus>;
  requestedBy?: InputMaybe<UserUpdateOneWithoutCashOutRequestsNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutCashOutRequestsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CashOutRequestUpdateWithoutRequestedByInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  cashOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  creditNote?: InputMaybe<CreditNoteUpdateOneWithoutCashOutRequestNestedInput>;
  creditSource?: InputMaybe<CashOutRequestCreditSource>;
  currency?: InputMaybe<CurrencyCode>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isBulkRequest?: InputMaybe<Scalars["Boolean"]["input"]>;
  requestId?: InputMaybe<Scalars["Int"]["input"]>;
  requestStatus?: InputMaybe<CashOutRequestStatus>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutCashOutRequestsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CashOutRequestUpdateWithoutSponsorInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  cashOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<Scalars["String"]["input"]>;
  creditNote?: InputMaybe<CreditNoteUpdateOneWithoutCashOutRequestNestedInput>;
  creditSource?: InputMaybe<CashOutRequestCreditSource>;
  currency?: InputMaybe<CurrencyCode>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isBulkRequest?: InputMaybe<Scalars["Boolean"]["input"]>;
  requestId?: InputMaybe<Scalars["Int"]["input"]>;
  requestStatus?: InputMaybe<CashOutRequestStatus>;
  requestedBy?: InputMaybe<UserUpdateOneWithoutCashOutRequestsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CashOutRequestUpsertWithWhereUniqueWithoutCreditNoteInput = {
  create: CashOutRequestCreateWithoutCreditNoteInput;
  update: CashOutRequestUpdateWithoutCreditNoteInput;
  where: CashOutRequestWhereUniqueInput;
};

export type CashOutRequestUpsertWithWhereUniqueWithoutRequestedByInput = {
  create: CashOutRequestCreateWithoutRequestedByInput;
  update: CashOutRequestUpdateWithoutRequestedByInput;
  where: CashOutRequestWhereUniqueInput;
};

export type CashOutRequestUpsertWithWhereUniqueWithoutSponsorInput = {
  create: CashOutRequestCreateWithoutSponsorInput;
  update: CashOutRequestUpdateWithoutSponsorInput;
  where: CashOutRequestWhereUniqueInput;
};

export type CashOutRequestWhereInput = {
  AND?: InputMaybe<Array<CashOutRequestWhereInput>>;
  NOT?: InputMaybe<Array<CashOutRequestWhereInput>>;
  OR?: InputMaybe<Array<CashOutRequestWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  cashOutAt?: InputMaybe<DateTimeFilter>;
  cashOutPaymentTransactionId?: InputMaybe<StringFilter>;
  comments?: InputMaybe<StringFilter>;
  creditNote?: InputMaybe<CreditNoteRelationFilter>;
  creditNoteId?: InputMaybe<StringFilter>;
  creditSource?: InputMaybe<EnumCashOutRequestCreditSourceFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  id?: InputMaybe<StringFilter>;
  isBulkRequest?: InputMaybe<BoolFilter>;
  requestId?: InputMaybe<IntFilter>;
  requestStatus?: InputMaybe<EnumCashOutRequestStatusFilter>;
  requestedBy?: InputMaybe<UserRelationFilter>;
  requestedById?: InputMaybe<StringFilter>;
  sponsor?: InputMaybe<SponsorRelationFilter>;
  sponsorId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CashOutRequestWhereUniqueInput = {
  AND?: InputMaybe<Array<CashOutRequestWhereInput>>;
  NOT?: InputMaybe<Array<CashOutRequestWhereInput>>;
  OR?: InputMaybe<Array<CashOutRequestWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  cashOutAt?: InputMaybe<DateTimeFilter>;
  cashOutPaymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<StringFilter>;
  creditNote?: InputMaybe<CreditNoteRelationFilter>;
  creditNoteId?: InputMaybe<StringFilter>;
  creditSource?: InputMaybe<EnumCashOutRequestCreditSourceFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isBulkRequest?: InputMaybe<BoolFilter>;
  requestId?: InputMaybe<IntFilter>;
  requestStatus?: InputMaybe<EnumCashOutRequestStatusFilter>;
  requestedBy?: InputMaybe<UserRelationFilter>;
  requestedById?: InputMaybe<StringFilter>;
  sponsor?: InputMaybe<SponsorRelationFilter>;
  sponsorId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ChangeRequestDirectionality = {
  isToHome: Scalars["Boolean"]["input"];
  isToSchool: Scalars["Boolean"]["input"];
};

export type ChangeRequestInput = {
  directionality?: InputMaybe<ChangeRequestDirectionality>;
  homeLocation?: InputMaybe<LatLonInput>;
  newInstallmentPlanId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChangeRequestOutput = {
  __typename?: "ChangeRequestOutput";
  defaultChildServicePricing: ChildServicePricing;
  oneWayChildServicePricing: ChildServicePricing;
  roundTripChildServicePricing: ChildServicePricing;
};

export type CheckActiveTripsForSchoolRegistration = {
  __typename?: "CheckActiveTripsForSchoolRegistration";
  hasActiveTrips: Scalars["Boolean"]["output"];
};

export type CheckCoverageInput = {
  children: Array<ParentChildInput>;
  countryId: Scalars["String"]["input"];
  offerCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChildServicePricing = {
  __typename?: "ChildServicePricing";
  allowOnlinePayment: Scalars["Boolean"]["output"];
  basePrice: Scalars["Float"]["output"];
  basePriceWithModifiers: Scalars["Float"]["output"];
  basePriceWithVat: Scalars["Float"]["output"];
  basePriceWithVatAndModifiers: Scalars["Float"]["output"];
  basePriceWithVatAndModifiersAndOffer: Scalars["Float"]["output"];
  childId: Scalars["Float"]["output"];
  currency: CurrencyCode;
  /** @deprecated in favor of installmentPaymentPlans.(depositType, depositPaymentDate) */
  depositCollectionType: DepositCollectionType;
  firstName?: Maybe<Scalars["String"]["output"]>;
  hasVat: Scalars["Boolean"]["output"];
  installmentPaymentPlans: Array<InstallmentPaymentPlan>;
  isEligible: Scalars["Boolean"]["output"];
  isServedByEmushrif: Scalars["Boolean"]["output"];
  isVatInclusive: Scalars["Boolean"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  offerDiscount?: Maybe<OfferDiscount>;
  pricePreview?: Maybe<ServicePricePreview>;
  service?: Maybe<ServicePeriod>;
  serviceAcademicYear?: Maybe<Scalars["Float"]["output"]>;
  serviceDiscount?: Maybe<ServiceDiscount>;
  serviceProviderContact?: Maybe<Array<Contact>>;
  serviceProviderEmail?: Maybe<Scalars["String"]["output"]>;
  serviceProviderName: Scalars["String"]["output"];
  serviceProviderNameAr: Scalars["String"]["output"];
  vatFactor: Scalars["Float"]["output"];
};

export type Class = {
  __typename?: "Class";
  _count: ClassCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  className: Scalars["String"]["output"];
  classNameAr: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  schoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  schools?: Maybe<Array<SchoolClass>>;
  students?: Maybe<Array<SchoolStudent>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ClassCount = {
  __typename?: "ClassCount";
  schoolRegistrations: Scalars["Int"]["output"];
  schools: Scalars["Int"]["output"];
  students: Scalars["Int"]["output"];
};

export type ClassCreateInput = {
  className: Scalars["String"]["input"];
  classNameAr: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutClassInput>;
  schools?: InputMaybe<SchoolClassCreateNestedManyWithoutClassInput>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutClassInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ClassCreateNestedOneWithoutSchoolRegistrationsInput = {
  connect?: InputMaybe<ClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClassCreateOrConnectWithoutSchoolRegistrationsInput>;
  create?: InputMaybe<ClassCreateWithoutSchoolRegistrationsInput>;
};

export type ClassCreateNestedOneWithoutSchoolsInput = {
  connect?: InputMaybe<ClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClassCreateOrConnectWithoutSchoolsInput>;
  create?: InputMaybe<ClassCreateWithoutSchoolsInput>;
};

export type ClassCreateNestedOneWithoutStudentsInput = {
  connect?: InputMaybe<ClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClassCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<ClassCreateWithoutStudentsInput>;
};

export type ClassCreateOrConnectWithoutSchoolRegistrationsInput = {
  create: ClassCreateWithoutSchoolRegistrationsInput;
  where: ClassWhereUniqueInput;
};

export type ClassCreateOrConnectWithoutSchoolsInput = {
  create: ClassCreateWithoutSchoolsInput;
  where: ClassWhereUniqueInput;
};

export type ClassCreateOrConnectWithoutStudentsInput = {
  create: ClassCreateWithoutStudentsInput;
  where: ClassWhereUniqueInput;
};

export type ClassCreateWithoutSchoolRegistrationsInput = {
  className: Scalars["String"]["input"];
  classNameAr: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  schools?: InputMaybe<SchoolClassCreateNestedManyWithoutClassInput>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutClassInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ClassCreateWithoutSchoolsInput = {
  className: Scalars["String"]["input"];
  classNameAr: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutClassInput>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutClassInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ClassCreateWithoutStudentsInput = {
  className: Scalars["String"]["input"];
  classNameAr: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutClassInput>;
  schools?: InputMaybe<SchoolClassCreateNestedManyWithoutClassInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ClassPayload = {
  __typename?: "ClassPayload";
  className: Scalars["String"]["output"];
  classNameAr: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

export type ClassRelationFilter = {
  is?: InputMaybe<ClassWhereInput>;
  isNot?: InputMaybe<ClassWhereInput>;
};

export type ClassUpdateInput = {
  className?: InputMaybe<Scalars["String"]["input"]>;
  classNameAr?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutClassNestedInput>;
  schools?: InputMaybe<SchoolClassUpdateManyWithoutClassNestedInput>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutClassNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ClassUpdateOneRequiredWithoutSchoolsNestedInput = {
  connect?: InputMaybe<ClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClassCreateOrConnectWithoutSchoolsInput>;
  create?: InputMaybe<ClassCreateWithoutSchoolsInput>;
  update?: InputMaybe<ClassUpdateToOneWithWhereWithoutSchoolsInput>;
  upsert?: InputMaybe<ClassUpsertWithoutSchoolsInput>;
};

export type ClassUpdateOneRequiredWithoutStudentsNestedInput = {
  connect?: InputMaybe<ClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClassCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<ClassCreateWithoutStudentsInput>;
  update?: InputMaybe<ClassUpdateToOneWithWhereWithoutStudentsInput>;
  upsert?: InputMaybe<ClassUpsertWithoutStudentsInput>;
};

export type ClassUpdateOneWithoutSchoolRegistrationsNestedInput = {
  connect?: InputMaybe<ClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClassCreateOrConnectWithoutSchoolRegistrationsInput>;
  create?: InputMaybe<ClassCreateWithoutSchoolRegistrationsInput>;
  delete?: InputMaybe<ClassWhereInput>;
  disconnect?: InputMaybe<ClassWhereInput>;
  update?: InputMaybe<ClassUpdateToOneWithWhereWithoutSchoolRegistrationsInput>;
  upsert?: InputMaybe<ClassUpsertWithoutSchoolRegistrationsInput>;
};

export type ClassUpdateToOneWithWhereWithoutSchoolRegistrationsInput = {
  data: ClassUpdateWithoutSchoolRegistrationsInput;
  where?: InputMaybe<ClassWhereInput>;
};

export type ClassUpdateToOneWithWhereWithoutSchoolsInput = {
  data: ClassUpdateWithoutSchoolsInput;
  where?: InputMaybe<ClassWhereInput>;
};

export type ClassUpdateToOneWithWhereWithoutStudentsInput = {
  data: ClassUpdateWithoutStudentsInput;
  where?: InputMaybe<ClassWhereInput>;
};

export type ClassUpdateWithoutSchoolRegistrationsInput = {
  className?: InputMaybe<Scalars["String"]["input"]>;
  classNameAr?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schools?: InputMaybe<SchoolClassUpdateManyWithoutClassNestedInput>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutClassNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ClassUpdateWithoutSchoolsInput = {
  className?: InputMaybe<Scalars["String"]["input"]>;
  classNameAr?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutClassNestedInput>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutClassNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ClassUpdateWithoutStudentsInput = {
  className?: InputMaybe<Scalars["String"]["input"]>;
  classNameAr?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutClassNestedInput>;
  schools?: InputMaybe<SchoolClassUpdateManyWithoutClassNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ClassUpsertInput = {
  create?: InputMaybe<ClassCreateInput>;
  update?: InputMaybe<ClassUpdateInput>;
  where?: InputMaybe<ClassWhereUniqueInput>;
};

export type ClassUpsertWithoutSchoolRegistrationsInput = {
  create: ClassCreateWithoutSchoolRegistrationsInput;
  update: ClassUpdateWithoutSchoolRegistrationsInput;
  where?: InputMaybe<ClassWhereInput>;
};

export type ClassUpsertWithoutSchoolsInput = {
  create: ClassCreateWithoutSchoolsInput;
  update: ClassUpdateWithoutSchoolsInput;
  where?: InputMaybe<ClassWhereInput>;
};

export type ClassUpsertWithoutStudentsInput = {
  create: ClassCreateWithoutStudentsInput;
  update: ClassUpdateWithoutStudentsInput;
  where?: InputMaybe<ClassWhereInput>;
};

export type ClassWhereInput = {
  AND?: InputMaybe<Array<ClassWhereInput>>;
  NOT?: InputMaybe<Array<ClassWhereInput>>;
  OR?: InputMaybe<Array<ClassWhereInput>>;
  className?: InputMaybe<StringFilter>;
  classNameAr?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  schools?: InputMaybe<SchoolClassListRelationFilter>;
  students?: InputMaybe<SchoolStudentListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ClassWhereUniqueInput = {
  AND?: InputMaybe<Array<ClassWhereInput>>;
  NOT?: InputMaybe<Array<ClassWhereInput>>;
  OR?: InputMaybe<Array<ClassWhereInput>>;
  className?: InputMaybe<Scalars["String"]["input"]>;
  classNameAr?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  schools?: InputMaybe<SchoolClassListRelationFilter>;
  students?: InputMaybe<SchoolStudentListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CollectionPoint = {
  __typename?: "CollectionPoint";
  _count: CollectionPointCount;
  address: Address;
  addressId: Scalars["String"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  dropoffSchoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  id: Scalars["String"]["output"];
  pickupSchoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  routeStops?: Maybe<Array<RouteStop>>;
  schools?: Maybe<Array<SchoolCollectionPoint>>;
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars["String"]["output"];
  status: CollectionPointStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type CollectionPointCodeServiceProviderIdCompoundUniqueInput = {
  code: Scalars["String"]["input"];
  serviceProviderId: Scalars["String"]["input"];
};

export type CollectionPointCodeValidationResponse = {
  __typename?: "CollectionPointCodeValidationResponse";
  isValid: Scalars["Boolean"]["output"];
};

export type CollectionPointCount = {
  __typename?: "CollectionPointCount";
  dropoffSchoolRegistrations: Scalars["Int"]["output"];
  pickupSchoolRegistrations: Scalars["Int"]["output"];
  routeStops: Scalars["Int"]["output"];
  schools: Scalars["Int"]["output"];
};

export type CollectionPointCreateManyServiceProviderInput = {
  addressId: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  status: CollectionPointStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointCreateManyServiceProviderInputEnvelope = {
  data: Array<CollectionPointCreateManyServiceProviderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CollectionPointCreateNestedManyWithoutServiceProviderInput = {
  connect?: InputMaybe<Array<CollectionPointWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CollectionPointCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<CollectionPointCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<CollectionPointCreateManyServiceProviderInputEnvelope>;
};

export type CollectionPointCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<CollectionPointWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CollectionPointCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<CollectionPointCreateWithoutAddressInput>;
};

export type CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput =
  {
    connect?: InputMaybe<CollectionPointWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CollectionPointCreateOrConnectWithoutDropoffSchoolRegistrationsInput>;
    create?: InputMaybe<CollectionPointCreateWithoutDropoffSchoolRegistrationsInput>;
  };

export type CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput =
  {
    connect?: InputMaybe<CollectionPointWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CollectionPointCreateOrConnectWithoutPickupSchoolRegistrationsInput>;
    create?: InputMaybe<CollectionPointCreateWithoutPickupSchoolRegistrationsInput>;
  };

export type CollectionPointCreateNestedOneWithoutRouteStopsInput = {
  connect?: InputMaybe<CollectionPointWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CollectionPointCreateOrConnectWithoutRouteStopsInput>;
  create?: InputMaybe<CollectionPointCreateWithoutRouteStopsInput>;
};

export type CollectionPointCreateNestedOneWithoutSchoolsInput = {
  connect?: InputMaybe<CollectionPointWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CollectionPointCreateOrConnectWithoutSchoolsInput>;
  create?: InputMaybe<CollectionPointCreateWithoutSchoolsInput>;
};

export type CollectionPointCreateOrConnectWithoutAddressInput = {
  create: CollectionPointCreateWithoutAddressInput;
  where: CollectionPointWhereUniqueInput;
};

export type CollectionPointCreateOrConnectWithoutDropoffSchoolRegistrationsInput =
  {
    create: CollectionPointCreateWithoutDropoffSchoolRegistrationsInput;
    where: CollectionPointWhereUniqueInput;
  };

export type CollectionPointCreateOrConnectWithoutPickupSchoolRegistrationsInput =
  {
    create: CollectionPointCreateWithoutPickupSchoolRegistrationsInput;
    where: CollectionPointWhereUniqueInput;
  };

export type CollectionPointCreateOrConnectWithoutRouteStopsInput = {
  create: CollectionPointCreateWithoutRouteStopsInput;
  where: CollectionPointWhereUniqueInput;
};

export type CollectionPointCreateOrConnectWithoutSchoolsInput = {
  create: CollectionPointCreateWithoutSchoolsInput;
  where: CollectionPointWhereUniqueInput;
};

export type CollectionPointCreateOrConnectWithoutServiceProviderInput = {
  create: CollectionPointCreateWithoutServiceProviderInput;
  where: CollectionPointWhereUniqueInput;
};

export type CollectionPointCreateWithoutAddressInput = {
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutDropoffCollectionPointInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutPickupCollectionPointInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutCollectionPointInput>;
  schools?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutCollectionPointInput>;
  serviceProvider: ServiceProviderCreateNestedOneWithoutCollectionPointsInput;
  status: CollectionPointStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointCreateWithoutDropoffSchoolRegistrationsInput = {
  address: AddressCreateNestedOneWithoutCollectionPointInput;
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutPickupCollectionPointInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutCollectionPointInput>;
  schools?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutCollectionPointInput>;
  serviceProvider: ServiceProviderCreateNestedOneWithoutCollectionPointsInput;
  status: CollectionPointStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointCreateWithoutPickupSchoolRegistrationsInput = {
  address: AddressCreateNestedOneWithoutCollectionPointInput;
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutDropoffCollectionPointInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutCollectionPointInput>;
  schools?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutCollectionPointInput>;
  serviceProvider: ServiceProviderCreateNestedOneWithoutCollectionPointsInput;
  status: CollectionPointStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointCreateWithoutRouteStopsInput = {
  address: AddressCreateNestedOneWithoutCollectionPointInput;
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutDropoffCollectionPointInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutPickupCollectionPointInput>;
  schools?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutCollectionPointInput>;
  serviceProvider: ServiceProviderCreateNestedOneWithoutCollectionPointsInput;
  status: CollectionPointStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointCreateWithoutSchoolsInput = {
  address: AddressCreateNestedOneWithoutCollectionPointInput;
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutDropoffCollectionPointInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutPickupCollectionPointInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutCollectionPointInput>;
  serviceProvider: ServiceProviderCreateNestedOneWithoutCollectionPointsInput;
  status: CollectionPointStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointCreateWithoutServiceProviderInput = {
  address: AddressCreateNestedOneWithoutCollectionPointInput;
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutDropoffCollectionPointInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutPickupCollectionPointInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutCollectionPointInput>;
  schools?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutCollectionPointInput>;
  status: CollectionPointStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointListRelationFilter = {
  every?: InputMaybe<CollectionPointWhereInput>;
  none?: InputMaybe<CollectionPointWhereInput>;
  some?: InputMaybe<CollectionPointWhereInput>;
};

export type CollectionPointRelationFilter = {
  is?: InputMaybe<CollectionPointWhereInput>;
  isNot?: InputMaybe<CollectionPointWhereInput>;
};

export type CollectionPointResponse = {
  __typename?: "CollectionPointResponse";
  _count: CollectionPointCount;
  address: Address;
  addressId: Scalars["String"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  dropoffRegistrationsCount: Scalars["Int"]["output"];
  dropoffSchoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  id: Scalars["String"]["output"];
  pickupRegistrationsCount: Scalars["Int"]["output"];
  pickupSchoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  routeStops?: Maybe<Array<RouteStop>>;
  schools?: Maybe<Array<SchoolCollectionPoint>>;
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars["String"]["output"];
  status: CollectionPointStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type CollectionPointScalarWhereInput = {
  AND?: InputMaybe<Array<CollectionPointScalarWhereInput>>;
  NOT?: InputMaybe<Array<CollectionPointScalarWhereInput>>;
  OR?: InputMaybe<Array<CollectionPointScalarWhereInput>>;
  addressId?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumCollectionPointStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum CollectionPointStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type CollectionPointUpdateManyMutationInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<CollectionPointStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointUpdateManyWithWhereWithoutServiceProviderInput = {
  data: CollectionPointUpdateManyMutationInput;
  where: CollectionPointScalarWhereInput;
};

export type CollectionPointUpdateManyWithoutServiceProviderNestedInput = {
  connect?: InputMaybe<Array<CollectionPointWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CollectionPointCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<CollectionPointCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<CollectionPointCreateManyServiceProviderInputEnvelope>;
  delete?: InputMaybe<Array<CollectionPointWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CollectionPointScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CollectionPointWhereUniqueInput>>;
  set?: InputMaybe<Array<CollectionPointWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CollectionPointUpdateWithWhereUniqueWithoutServiceProviderInput>
  >;
  updateMany?: InputMaybe<
    Array<CollectionPointUpdateManyWithWhereWithoutServiceProviderInput>
  >;
  upsert?: InputMaybe<
    Array<CollectionPointUpsertWithWhereUniqueWithoutServiceProviderInput>
  >;
};

export type CollectionPointUpdateOneRequiredWithoutSchoolsNestedInput = {
  connect?: InputMaybe<CollectionPointWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CollectionPointCreateOrConnectWithoutSchoolsInput>;
  create?: InputMaybe<CollectionPointCreateWithoutSchoolsInput>;
  update?: InputMaybe<CollectionPointUpdateToOneWithWhereWithoutSchoolsInput>;
  upsert?: InputMaybe<CollectionPointUpsertWithoutSchoolsInput>;
};

export type CollectionPointUpdateOneWithoutAddressNestedInput = {
  connect?: InputMaybe<CollectionPointWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CollectionPointCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<CollectionPointCreateWithoutAddressInput>;
  delete?: InputMaybe<CollectionPointWhereInput>;
  disconnect?: InputMaybe<CollectionPointWhereInput>;
  update?: InputMaybe<CollectionPointUpdateToOneWithWhereWithoutAddressInput>;
  upsert?: InputMaybe<CollectionPointUpsertWithoutAddressInput>;
};

export type CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput =
  {
    connect?: InputMaybe<CollectionPointWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CollectionPointCreateOrConnectWithoutDropoffSchoolRegistrationsInput>;
    create?: InputMaybe<CollectionPointCreateWithoutDropoffSchoolRegistrationsInput>;
    delete?: InputMaybe<CollectionPointWhereInput>;
    disconnect?: InputMaybe<CollectionPointWhereInput>;
    update?: InputMaybe<CollectionPointUpdateToOneWithWhereWithoutDropoffSchoolRegistrationsInput>;
    upsert?: InputMaybe<CollectionPointUpsertWithoutDropoffSchoolRegistrationsInput>;
  };

export type CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput =
  {
    connect?: InputMaybe<CollectionPointWhereUniqueInput>;
    connectOrCreate?: InputMaybe<CollectionPointCreateOrConnectWithoutPickupSchoolRegistrationsInput>;
    create?: InputMaybe<CollectionPointCreateWithoutPickupSchoolRegistrationsInput>;
    delete?: InputMaybe<CollectionPointWhereInput>;
    disconnect?: InputMaybe<CollectionPointWhereInput>;
    update?: InputMaybe<CollectionPointUpdateToOneWithWhereWithoutPickupSchoolRegistrationsInput>;
    upsert?: InputMaybe<CollectionPointUpsertWithoutPickupSchoolRegistrationsInput>;
  };

export type CollectionPointUpdateOneWithoutRouteStopsNestedInput = {
  connect?: InputMaybe<CollectionPointWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CollectionPointCreateOrConnectWithoutRouteStopsInput>;
  create?: InputMaybe<CollectionPointCreateWithoutRouteStopsInput>;
  delete?: InputMaybe<CollectionPointWhereInput>;
  disconnect?: InputMaybe<CollectionPointWhereInput>;
  update?: InputMaybe<CollectionPointUpdateToOneWithWhereWithoutRouteStopsInput>;
  upsert?: InputMaybe<CollectionPointUpsertWithoutRouteStopsInput>;
};

export type CollectionPointUpdateToOneWithWhereWithoutAddressInput = {
  data: CollectionPointUpdateWithoutAddressInput;
  where?: InputMaybe<CollectionPointWhereInput>;
};

export type CollectionPointUpdateToOneWithWhereWithoutDropoffSchoolRegistrationsInput =
  {
    data: CollectionPointUpdateWithoutDropoffSchoolRegistrationsInput;
    where?: InputMaybe<CollectionPointWhereInput>;
  };

export type CollectionPointUpdateToOneWithWhereWithoutPickupSchoolRegistrationsInput =
  {
    data: CollectionPointUpdateWithoutPickupSchoolRegistrationsInput;
    where?: InputMaybe<CollectionPointWhereInput>;
  };

export type CollectionPointUpdateToOneWithWhereWithoutRouteStopsInput = {
  data: CollectionPointUpdateWithoutRouteStopsInput;
  where?: InputMaybe<CollectionPointWhereInput>;
};

export type CollectionPointUpdateToOneWithWhereWithoutSchoolsInput = {
  data: CollectionPointUpdateWithoutSchoolsInput;
  where?: InputMaybe<CollectionPointWhereInput>;
};

export type CollectionPointUpdateWithWhereUniqueWithoutServiceProviderInput = {
  data: CollectionPointUpdateWithoutServiceProviderInput;
  where: CollectionPointWhereUniqueInput;
};

export type CollectionPointUpdateWithoutAddressInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutDropoffCollectionPointNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutPickupCollectionPointNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutCollectionPointNestedInput>;
  schools?: InputMaybe<SchoolCollectionPointUpdateManyWithoutCollectionPointNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutCollectionPointsNestedInput>;
  status?: InputMaybe<CollectionPointStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointUpdateWithoutDropoffSchoolRegistrationsInput = {
  address?: InputMaybe<AddressUpdateOneRequiredWithoutCollectionPointNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutPickupCollectionPointNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutCollectionPointNestedInput>;
  schools?: InputMaybe<SchoolCollectionPointUpdateManyWithoutCollectionPointNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutCollectionPointsNestedInput>;
  status?: InputMaybe<CollectionPointStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointUpdateWithoutPickupSchoolRegistrationsInput = {
  address?: InputMaybe<AddressUpdateOneRequiredWithoutCollectionPointNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutDropoffCollectionPointNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutCollectionPointNestedInput>;
  schools?: InputMaybe<SchoolCollectionPointUpdateManyWithoutCollectionPointNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutCollectionPointsNestedInput>;
  status?: InputMaybe<CollectionPointStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointUpdateWithoutRouteStopsInput = {
  address?: InputMaybe<AddressUpdateOneRequiredWithoutCollectionPointNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutDropoffCollectionPointNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutPickupCollectionPointNestedInput>;
  schools?: InputMaybe<SchoolCollectionPointUpdateManyWithoutCollectionPointNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutCollectionPointsNestedInput>;
  status?: InputMaybe<CollectionPointStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointUpdateWithoutSchoolsInput = {
  address?: InputMaybe<AddressUpdateOneRequiredWithoutCollectionPointNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutDropoffCollectionPointNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutPickupCollectionPointNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutCollectionPointNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutCollectionPointsNestedInput>;
  status?: InputMaybe<CollectionPointStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointUpdateWithoutServiceProviderInput = {
  address?: InputMaybe<AddressUpdateOneRequiredWithoutCollectionPointNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutDropoffCollectionPointNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutPickupCollectionPointNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutCollectionPointNestedInput>;
  schools?: InputMaybe<SchoolCollectionPointUpdateManyWithoutCollectionPointNestedInput>;
  status?: InputMaybe<CollectionPointStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionPointUpsertWithWhereUniqueWithoutServiceProviderInput = {
  create: CollectionPointCreateWithoutServiceProviderInput;
  update: CollectionPointUpdateWithoutServiceProviderInput;
  where: CollectionPointWhereUniqueInput;
};

export type CollectionPointUpsertWithoutAddressInput = {
  create: CollectionPointCreateWithoutAddressInput;
  update: CollectionPointUpdateWithoutAddressInput;
  where?: InputMaybe<CollectionPointWhereInput>;
};

export type CollectionPointUpsertWithoutDropoffSchoolRegistrationsInput = {
  create: CollectionPointCreateWithoutDropoffSchoolRegistrationsInput;
  update: CollectionPointUpdateWithoutDropoffSchoolRegistrationsInput;
  where?: InputMaybe<CollectionPointWhereInput>;
};

export type CollectionPointUpsertWithoutPickupSchoolRegistrationsInput = {
  create: CollectionPointCreateWithoutPickupSchoolRegistrationsInput;
  update: CollectionPointUpdateWithoutPickupSchoolRegistrationsInput;
  where?: InputMaybe<CollectionPointWhereInput>;
};

export type CollectionPointUpsertWithoutRouteStopsInput = {
  create: CollectionPointCreateWithoutRouteStopsInput;
  update: CollectionPointUpdateWithoutRouteStopsInput;
  where?: InputMaybe<CollectionPointWhereInput>;
};

export type CollectionPointUpsertWithoutSchoolsInput = {
  create: CollectionPointCreateWithoutSchoolsInput;
  update: CollectionPointUpdateWithoutSchoolsInput;
  where?: InputMaybe<CollectionPointWhereInput>;
};

export type CollectionPointWhereInput = {
  AND?: InputMaybe<Array<CollectionPointWhereInput>>;
  NOT?: InputMaybe<Array<CollectionPointWhereInput>>;
  OR?: InputMaybe<Array<CollectionPointWhereInput>>;
  address?: InputMaybe<AddressRelationFilter>;
  addressId?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  routeStops?: InputMaybe<RouteStopListRelationFilter>;
  schools?: InputMaybe<SchoolCollectionPointListRelationFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumCollectionPointStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CollectionPointWhereUniqueInput = {
  AND?: InputMaybe<Array<CollectionPointWhereInput>>;
  NOT?: InputMaybe<Array<CollectionPointWhereInput>>;
  OR?: InputMaybe<Array<CollectionPointWhereInput>>;
  address?: InputMaybe<AddressRelationFilter>;
  addressId?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<StringFilter>;
  code_serviceProviderId?: InputMaybe<CollectionPointCodeServiceProviderIdCompoundUniqueInput>;
  description?: InputMaybe<StringFilter>;
  dropoffSchoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pickupSchoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  routeStops?: InputMaybe<RouteStopListRelationFilter>;
  schools?: InputMaybe<SchoolCollectionPointListRelationFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumCollectionPointStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum CommunicationChannel {
  EMAIL = "EMAIL",
  PUSH = "PUSH",
  SMS = "SMS",
}

export type Configuration = {
  __typename?: "Configuration";
  configs: Array<ConfigurationKeyValue>;
};

export type ConfigurationKeyValue = {
  __typename?: "ConfigurationKeyValue";
  description: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type Contact = {
  __typename?: "Contact";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  branchDepartment: BranchDepartment;
  branchDepartmentId: Scalars["String"]["output"];
  channel: ContactChannel;
  contact: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export enum ContactChannel {
  CHAT = "CHAT",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  WHATSAPP = "WHATSAPP",
}

export type ContactCreateManyBranchDepartmentInput = {
  channel: ContactChannel;
  contact: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ContactCreateManyBranchDepartmentInputEnvelope = {
  data: Array<ContactCreateManyBranchDepartmentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ContactCreateNestedManyWithoutBranchDepartmentInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ContactCreateOrConnectWithoutBranchDepartmentInput>
  >;
  create?: InputMaybe<Array<ContactCreateWithoutBranchDepartmentInput>>;
  createMany?: InputMaybe<ContactCreateManyBranchDepartmentInputEnvelope>;
};

export type ContactCreateOrConnectWithoutBranchDepartmentInput = {
  create: ContactCreateWithoutBranchDepartmentInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateWithoutBranchDepartmentInput = {
  channel: ContactChannel;
  contact: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ContactListRelationFilter = {
  every?: InputMaybe<ContactWhereInput>;
  none?: InputMaybe<ContactWhereInput>;
  some?: InputMaybe<ContactWhereInput>;
};

export type ContactResponsePayload = {
  __typename?: "ContactResponsePayload";
  channel: ContactChannel;
  contact: Scalars["String"]["output"];
  /** The creation time */
  createdAt: Scalars["DateTime"]["output"];
  department?: Maybe<DepartmentResponsePayload>;
  /** A UUID */
  id: Scalars["ID"]["output"];
  /** The last update time */
  updatedAt: Scalars["DateTime"]["output"];
};

export type ContactScalarWhereInput = {
  AND?: InputMaybe<Array<ContactScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContactScalarWhereInput>>;
  OR?: InputMaybe<Array<ContactScalarWhereInput>>;
  branchDepartmentId?: InputMaybe<StringFilter>;
  channel?: InputMaybe<EnumContactChannelFilter>;
  contact?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContactUpdateManyMutationInput = {
  channel?: InputMaybe<ContactChannel>;
  contact?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ContactUpdateManyWithWhereWithoutBranchDepartmentInput = {
  data: ContactUpdateManyMutationInput;
  where: ContactScalarWhereInput;
};

export type ContactUpdateManyWithoutBranchDepartmentNestedInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ContactCreateOrConnectWithoutBranchDepartmentInput>
  >;
  create?: InputMaybe<Array<ContactCreateWithoutBranchDepartmentInput>>;
  createMany?: InputMaybe<ContactCreateManyBranchDepartmentInputEnvelope>;
  delete?: InputMaybe<Array<ContactWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ContactUpdateWithWhereUniqueWithoutBranchDepartmentInput>
  >;
  updateMany?: InputMaybe<
    Array<ContactUpdateManyWithWhereWithoutBranchDepartmentInput>
  >;
  upsert?: InputMaybe<
    Array<ContactUpsertWithWhereUniqueWithoutBranchDepartmentInput>
  >;
};

export type ContactUpdateWithWhereUniqueWithoutBranchDepartmentInput = {
  data: ContactUpdateWithoutBranchDepartmentInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpdateWithoutBranchDepartmentInput = {
  channel?: InputMaybe<ContactChannel>;
  contact?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ContactUpsertWithWhereUniqueWithoutBranchDepartmentInput = {
  create: ContactCreateWithoutBranchDepartmentInput;
  update: ContactUpdateWithoutBranchDepartmentInput;
  where: ContactWhereUniqueInput;
};

export type ContactWhereInput = {
  AND?: InputMaybe<Array<ContactWhereInput>>;
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  OR?: InputMaybe<Array<ContactWhereInput>>;
  branchDepartment?: InputMaybe<BranchDepartmentRelationFilter>;
  branchDepartmentId?: InputMaybe<StringFilter>;
  channel?: InputMaybe<EnumContactChannelFilter>;
  contact?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContactWhereUniqueInput = {
  AND?: InputMaybe<Array<ContactWhereInput>>;
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  OR?: InputMaybe<Array<ContactWhereInput>>;
  branchDepartment?: InputMaybe<BranchDepartmentRelationFilter>;
  branchDepartmentId?: InputMaybe<StringFilter>;
  channel?: InputMaybe<EnumContactChannelFilter>;
  contact?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContractType = {
  __typename?: "ContractType";
  BusAttendant?: Maybe<Array<BusAttendant>>;
  Driver?: Maybe<Array<Driver>>;
  _count: ContractTypeCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ContractTypeCount = {
  __typename?: "ContractTypeCount";
  BusAttendant: Scalars["Int"]["output"];
  Driver: Scalars["Int"]["output"];
};

export type ContractTypeCreateNestedOneWithoutBusAttendantInput = {
  connect?: InputMaybe<ContractTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContractTypeCreateOrConnectWithoutBusAttendantInput>;
  create?: InputMaybe<ContractTypeCreateWithoutBusAttendantInput>;
};

export type ContractTypeCreateNestedOneWithoutDriverInput = {
  connect?: InputMaybe<ContractTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContractTypeCreateOrConnectWithoutDriverInput>;
  create?: InputMaybe<ContractTypeCreateWithoutDriverInput>;
};

export type ContractTypeCreateOrConnectWithoutBusAttendantInput = {
  create: ContractTypeCreateWithoutBusAttendantInput;
  where: ContractTypeWhereUniqueInput;
};

export type ContractTypeCreateOrConnectWithoutDriverInput = {
  create: ContractTypeCreateWithoutDriverInput;
  where: ContractTypeWhereUniqueInput;
};

export type ContractTypeCreateWithoutBusAttendantInput = {
  Driver?: InputMaybe<DriverCreateNestedManyWithoutContractTypeInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ContractTypeCreateWithoutDriverInput = {
  BusAttendant?: InputMaybe<BusAttendantCreateNestedManyWithoutContractTypeInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ContractTypeRelationFilter = {
  is?: InputMaybe<ContractTypeWhereInput>;
  isNot?: InputMaybe<ContractTypeWhereInput>;
};

export type ContractTypeUpdateOneWithoutBusAttendantNestedInput = {
  connect?: InputMaybe<ContractTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContractTypeCreateOrConnectWithoutBusAttendantInput>;
  create?: InputMaybe<ContractTypeCreateWithoutBusAttendantInput>;
  delete?: InputMaybe<ContractTypeWhereInput>;
  disconnect?: InputMaybe<ContractTypeWhereInput>;
  update?: InputMaybe<ContractTypeUpdateToOneWithWhereWithoutBusAttendantInput>;
  upsert?: InputMaybe<ContractTypeUpsertWithoutBusAttendantInput>;
};

export type ContractTypeUpdateOneWithoutDriverNestedInput = {
  connect?: InputMaybe<ContractTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContractTypeCreateOrConnectWithoutDriverInput>;
  create?: InputMaybe<ContractTypeCreateWithoutDriverInput>;
  delete?: InputMaybe<ContractTypeWhereInput>;
  disconnect?: InputMaybe<ContractTypeWhereInput>;
  update?: InputMaybe<ContractTypeUpdateToOneWithWhereWithoutDriverInput>;
  upsert?: InputMaybe<ContractTypeUpsertWithoutDriverInput>;
};

export type ContractTypeUpdateToOneWithWhereWithoutBusAttendantInput = {
  data: ContractTypeUpdateWithoutBusAttendantInput;
  where?: InputMaybe<ContractTypeWhereInput>;
};

export type ContractTypeUpdateToOneWithWhereWithoutDriverInput = {
  data: ContractTypeUpdateWithoutDriverInput;
  where?: InputMaybe<ContractTypeWhereInput>;
};

export type ContractTypeUpdateWithoutBusAttendantInput = {
  Driver?: InputMaybe<DriverUpdateManyWithoutContractTypeNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ContractTypeUpdateWithoutDriverInput = {
  BusAttendant?: InputMaybe<BusAttendantUpdateManyWithoutContractTypeNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ContractTypeUpsertWithoutBusAttendantInput = {
  create: ContractTypeCreateWithoutBusAttendantInput;
  update: ContractTypeUpdateWithoutBusAttendantInput;
  where?: InputMaybe<ContractTypeWhereInput>;
};

export type ContractTypeUpsertWithoutDriverInput = {
  create: ContractTypeCreateWithoutDriverInput;
  update: ContractTypeUpdateWithoutDriverInput;
  where?: InputMaybe<ContractTypeWhereInput>;
};

export type ContractTypeWhereInput = {
  AND?: InputMaybe<Array<ContractTypeWhereInput>>;
  BusAttendant?: InputMaybe<BusAttendantListRelationFilter>;
  Driver?: InputMaybe<DriverListRelationFilter>;
  NOT?: InputMaybe<Array<ContractTypeWhereInput>>;
  OR?: InputMaybe<Array<ContractTypeWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContractTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<ContractTypeWhereInput>>;
  BusAttendant?: InputMaybe<BusAttendantListRelationFilter>;
  Driver?: InputMaybe<DriverListRelationFilter>;
  NOT?: InputMaybe<Array<ContractTypeWhereInput>>;
  OR?: InputMaybe<Array<ContractTypeWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CoordinateInput = {
  lat: Scalars["Float"]["input"];
  lon: Scalars["Float"]["input"];
};

export type Country = {
  __typename?: "Country";
  _count: CountryCount;
  address?: Maybe<Array<Address>>;
  administrativeAreas?: Maybe<Array<AdministrativeArea>>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  banks?: Maybe<Array<Bank>>;
  branches?: Maybe<Array<Branch>>;
  callingCode: Scalars["String"]["output"];
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  creditNotes?: Maybe<Array<CreditNote>>;
  currencyBasicToSubunit: Scalars["Int"]["output"];
  currencyCalcPrecision: Scalars["Int"]["output"];
  currencyCode: CurrencyCode;
  currencyDisplayPrecision: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  lat?: Maybe<Scalars["Float"]["output"]>;
  levels?: Maybe<Array<CountryDivisionLevel>>;
  lon?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  routeConfiguration?: Maybe<Array<RouteConfiguration>>;
  scheduleExclusions?: Maybe<Array<ScheduleExclusion>>;
  schedules?: Maybe<Array<Schedule>>;
  services?: Maybe<Array<Service>>;
  specialNeeds?: Maybe<Array<SpecialNeed>>;
  updatedAt: Scalars["DateTime"]["output"];
  vehicle?: Maybe<Array<Vehicle>>;
};

export enum CountryCode {
  ARE = "ARE",
  KWT = "KWT",
  OMN = "OMN",
  SAU = "SAU",
  UNKNOWN = "UNKNOWN",
}

export type CountryCount = {
  __typename?: "CountryCount";
  address: Scalars["Int"]["output"];
  administrativeAreas: Scalars["Int"]["output"];
  banks: Scalars["Int"]["output"];
  branches: Scalars["Int"]["output"];
  creditNotes: Scalars["Int"]["output"];
  levels: Scalars["Int"]["output"];
  routeConfiguration: Scalars["Int"]["output"];
  scheduleExclusions: Scalars["Int"]["output"];
  schedules: Scalars["Int"]["output"];
  services: Scalars["Int"]["output"];
  specialNeeds: Scalars["Int"]["output"];
  vehicle: Scalars["Int"]["output"];
};

export type CountryCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<CountryCreateWithoutAddressInput>;
};

export type CountryCreateNestedOneWithoutAdministrativeAreasInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutAdministrativeAreasInput>;
  create?: InputMaybe<CountryCreateWithoutAdministrativeAreasInput>;
};

export type CountryCreateNestedOneWithoutBanksInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutBanksInput>;
  create?: InputMaybe<CountryCreateWithoutBanksInput>;
};

export type CountryCreateNestedOneWithoutBranchesInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutBranchesInput>;
  create?: InputMaybe<CountryCreateWithoutBranchesInput>;
};

export type CountryCreateNestedOneWithoutCreditNotesInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutCreditNotesInput>;
  create?: InputMaybe<CountryCreateWithoutCreditNotesInput>;
};

export type CountryCreateNestedOneWithoutScheduleExclusionsInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutScheduleExclusionsInput>;
  create?: InputMaybe<CountryCreateWithoutScheduleExclusionsInput>;
};

export type CountryCreateNestedOneWithoutSchedulesInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutSchedulesInput>;
  create?: InputMaybe<CountryCreateWithoutSchedulesInput>;
};

export type CountryCreateNestedOneWithoutServicesInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutServicesInput>;
  create?: InputMaybe<CountryCreateWithoutServicesInput>;
};

export type CountryCreateNestedOneWithoutSpecialNeedsInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutSpecialNeedsInput>;
  create?: InputMaybe<CountryCreateWithoutSpecialNeedsInput>;
};

export type CountryCreateNestedOneWithoutVehicleInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutVehicleInput>;
  create?: InputMaybe<CountryCreateWithoutVehicleInput>;
};

export type CountryCreateOrConnectWithoutAddressInput = {
  create: CountryCreateWithoutAddressInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutAdministrativeAreasInput = {
  create: CountryCreateWithoutAdministrativeAreasInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutBanksInput = {
  create: CountryCreateWithoutBanksInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutBranchesInput = {
  create: CountryCreateWithoutBranchesInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutCreditNotesInput = {
  create: CountryCreateWithoutCreditNotesInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutScheduleExclusionsInput = {
  create: CountryCreateWithoutScheduleExclusionsInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutSchedulesInput = {
  create: CountryCreateWithoutSchedulesInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutServicesInput = {
  create: CountryCreateWithoutServicesInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutSpecialNeedsInput = {
  create: CountryCreateWithoutSpecialNeedsInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutVehicleInput = {
  create: CountryCreateWithoutVehicleInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateWithoutAddressInput = {
  administrativeAreas?: InputMaybe<AdministrativeAreaCreateNestedManyWithoutCountryInput>;
  banks?: InputMaybe<BankCreateNestedManyWithoutCountryInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutCountryInput>;
  callingCode: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutCountryInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode: CurrencyCode;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelCreateNestedManyWithoutCountryInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  routeConfiguration?: InputMaybe<RouteConfigurationCreateNestedManyWithoutCountryInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutCountryInput>;
  schedules?: InputMaybe<ScheduleCreateNestedManyWithoutCountryInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCountryInput>;
  specialNeeds?: InputMaybe<SpecialNeedCreateNestedManyWithoutCountryInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedManyWithoutCountryInput>;
};

export type CountryCreateWithoutAdministrativeAreasInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutCountryInput>;
  banks?: InputMaybe<BankCreateNestedManyWithoutCountryInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutCountryInput>;
  callingCode: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutCountryInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode: CurrencyCode;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelCreateNestedManyWithoutCountryInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  routeConfiguration?: InputMaybe<RouteConfigurationCreateNestedManyWithoutCountryInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutCountryInput>;
  schedules?: InputMaybe<ScheduleCreateNestedManyWithoutCountryInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCountryInput>;
  specialNeeds?: InputMaybe<SpecialNeedCreateNestedManyWithoutCountryInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedManyWithoutCountryInput>;
};

export type CountryCreateWithoutBanksInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutCountryInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaCreateNestedManyWithoutCountryInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutCountryInput>;
  callingCode: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutCountryInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode: CurrencyCode;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelCreateNestedManyWithoutCountryInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  routeConfiguration?: InputMaybe<RouteConfigurationCreateNestedManyWithoutCountryInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutCountryInput>;
  schedules?: InputMaybe<ScheduleCreateNestedManyWithoutCountryInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCountryInput>;
  specialNeeds?: InputMaybe<SpecialNeedCreateNestedManyWithoutCountryInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedManyWithoutCountryInput>;
};

export type CountryCreateWithoutBranchesInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutCountryInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaCreateNestedManyWithoutCountryInput>;
  banks?: InputMaybe<BankCreateNestedManyWithoutCountryInput>;
  callingCode: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutCountryInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode: CurrencyCode;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelCreateNestedManyWithoutCountryInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  routeConfiguration?: InputMaybe<RouteConfigurationCreateNestedManyWithoutCountryInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutCountryInput>;
  schedules?: InputMaybe<ScheduleCreateNestedManyWithoutCountryInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCountryInput>;
  specialNeeds?: InputMaybe<SpecialNeedCreateNestedManyWithoutCountryInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedManyWithoutCountryInput>;
};

export type CountryCreateWithoutCreditNotesInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutCountryInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaCreateNestedManyWithoutCountryInput>;
  banks?: InputMaybe<BankCreateNestedManyWithoutCountryInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutCountryInput>;
  callingCode: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode: CurrencyCode;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelCreateNestedManyWithoutCountryInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  routeConfiguration?: InputMaybe<RouteConfigurationCreateNestedManyWithoutCountryInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutCountryInput>;
  schedules?: InputMaybe<ScheduleCreateNestedManyWithoutCountryInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCountryInput>;
  specialNeeds?: InputMaybe<SpecialNeedCreateNestedManyWithoutCountryInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedManyWithoutCountryInput>;
};

export type CountryCreateWithoutScheduleExclusionsInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutCountryInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaCreateNestedManyWithoutCountryInput>;
  banks?: InputMaybe<BankCreateNestedManyWithoutCountryInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutCountryInput>;
  callingCode: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutCountryInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode: CurrencyCode;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelCreateNestedManyWithoutCountryInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  routeConfiguration?: InputMaybe<RouteConfigurationCreateNestedManyWithoutCountryInput>;
  schedules?: InputMaybe<ScheduleCreateNestedManyWithoutCountryInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCountryInput>;
  specialNeeds?: InputMaybe<SpecialNeedCreateNestedManyWithoutCountryInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedManyWithoutCountryInput>;
};

export type CountryCreateWithoutSchedulesInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutCountryInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaCreateNestedManyWithoutCountryInput>;
  banks?: InputMaybe<BankCreateNestedManyWithoutCountryInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutCountryInput>;
  callingCode: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutCountryInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode: CurrencyCode;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelCreateNestedManyWithoutCountryInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  routeConfiguration?: InputMaybe<RouteConfigurationCreateNestedManyWithoutCountryInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutCountryInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCountryInput>;
  specialNeeds?: InputMaybe<SpecialNeedCreateNestedManyWithoutCountryInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedManyWithoutCountryInput>;
};

export type CountryCreateWithoutServicesInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutCountryInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaCreateNestedManyWithoutCountryInput>;
  banks?: InputMaybe<BankCreateNestedManyWithoutCountryInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutCountryInput>;
  callingCode: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutCountryInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode: CurrencyCode;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelCreateNestedManyWithoutCountryInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  routeConfiguration?: InputMaybe<RouteConfigurationCreateNestedManyWithoutCountryInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutCountryInput>;
  schedules?: InputMaybe<ScheduleCreateNestedManyWithoutCountryInput>;
  specialNeeds?: InputMaybe<SpecialNeedCreateNestedManyWithoutCountryInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedManyWithoutCountryInput>;
};

export type CountryCreateWithoutSpecialNeedsInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutCountryInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaCreateNestedManyWithoutCountryInput>;
  banks?: InputMaybe<BankCreateNestedManyWithoutCountryInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutCountryInput>;
  callingCode: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutCountryInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode: CurrencyCode;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelCreateNestedManyWithoutCountryInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  routeConfiguration?: InputMaybe<RouteConfigurationCreateNestedManyWithoutCountryInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutCountryInput>;
  schedules?: InputMaybe<ScheduleCreateNestedManyWithoutCountryInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCountryInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedManyWithoutCountryInput>;
};

export type CountryCreateWithoutVehicleInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutCountryInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaCreateNestedManyWithoutCountryInput>;
  banks?: InputMaybe<BankCreateNestedManyWithoutCountryInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutCountryInput>;
  callingCode: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutCountryInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode: CurrencyCode;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelCreateNestedManyWithoutCountryInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  routeConfiguration?: InputMaybe<RouteConfigurationCreateNestedManyWithoutCountryInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutCountryInput>;
  schedules?: InputMaybe<ScheduleCreateNestedManyWithoutCountryInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCountryInput>;
  specialNeeds?: InputMaybe<SpecialNeedCreateNestedManyWithoutCountryInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CountryDivisionLevel = {
  __typename?: "CountryDivisionLevel";
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  divisionLevelId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  level: DivisionLevel;
  type: DivisionLevelType;
  updatedAt: Scalars["DateTime"]["output"];
};

export type CountryDivisionLevelCountryIdDivisionLevelIdCompoundUniqueInput = {
  countryId: Scalars["String"]["input"];
  divisionLevelId: Scalars["String"]["input"];
};

export type CountryDivisionLevelCreateManyCountryInput = {
  divisionLevelId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  type: DivisionLevelType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CountryDivisionLevelCreateManyCountryInputEnvelope = {
  data: Array<CountryDivisionLevelCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CountryDivisionLevelCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<CountryDivisionLevelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CountryDivisionLevelCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<CountryDivisionLevelCreateWithoutCountryInput>>;
  createMany?: InputMaybe<CountryDivisionLevelCreateManyCountryInputEnvelope>;
};

export type CountryDivisionLevelCreateOrConnectWithoutCountryInput = {
  create: CountryDivisionLevelCreateWithoutCountryInput;
  where: CountryDivisionLevelWhereUniqueInput;
};

export type CountryDivisionLevelCreateWithoutCountryInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  level: DivisionLevelCreateNestedOneWithoutCountriesInput;
  type: DivisionLevelType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CountryDivisionLevelListRelationFilter = {
  every?: InputMaybe<CountryDivisionLevelWhereInput>;
  none?: InputMaybe<CountryDivisionLevelWhereInput>;
  some?: InputMaybe<CountryDivisionLevelWhereInput>;
};

export type CountryDivisionLevelResponsePayload = {
  __typename?: "CountryDivisionLevelResponsePayload";
  /** The creation time */
  createdAt: Scalars["DateTime"]["output"];
  /** A UUID */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  type: DivisionLevelType;
  /** The last update time */
  updatedAt: Scalars["DateTime"]["output"];
};

export type CountryDivisionLevelScalarWhereInput = {
  AND?: InputMaybe<Array<CountryDivisionLevelScalarWhereInput>>;
  NOT?: InputMaybe<Array<CountryDivisionLevelScalarWhereInput>>;
  OR?: InputMaybe<Array<CountryDivisionLevelScalarWhereInput>>;
  countryId?: InputMaybe<StringFilter>;
  divisionLevelId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumDivisionLevelTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CountryDivisionLevelUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<DivisionLevelType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CountryDivisionLevelUpdateManyWithWhereWithoutCountryInput = {
  data: CountryDivisionLevelUpdateManyMutationInput;
  where: CountryDivisionLevelScalarWhereInput;
};

export type CountryDivisionLevelUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<CountryDivisionLevelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CountryDivisionLevelCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<CountryDivisionLevelCreateWithoutCountryInput>>;
  createMany?: InputMaybe<CountryDivisionLevelCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<CountryDivisionLevelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CountryDivisionLevelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CountryDivisionLevelWhereUniqueInput>>;
  set?: InputMaybe<Array<CountryDivisionLevelWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CountryDivisionLevelUpdateWithWhereUniqueWithoutCountryInput>
  >;
  updateMany?: InputMaybe<
    Array<CountryDivisionLevelUpdateManyWithWhereWithoutCountryInput>
  >;
  upsert?: InputMaybe<
    Array<CountryDivisionLevelUpsertWithWhereUniqueWithoutCountryInput>
  >;
};

export type CountryDivisionLevelUpdateWithWhereUniqueWithoutCountryInput = {
  data: CountryDivisionLevelUpdateWithoutCountryInput;
  where: CountryDivisionLevelWhereUniqueInput;
};

export type CountryDivisionLevelUpdateWithoutCountryInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<DivisionLevelUpdateOneRequiredWithoutCountriesNestedInput>;
  type?: InputMaybe<DivisionLevelType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CountryDivisionLevelUpsertWithWhereUniqueWithoutCountryInput = {
  create: CountryDivisionLevelCreateWithoutCountryInput;
  update: CountryDivisionLevelUpdateWithoutCountryInput;
  where: CountryDivisionLevelWhereUniqueInput;
};

export type CountryDivisionLevelWhereInput = {
  AND?: InputMaybe<Array<CountryDivisionLevelWhereInput>>;
  NOT?: InputMaybe<Array<CountryDivisionLevelWhereInput>>;
  OR?: InputMaybe<Array<CountryDivisionLevelWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  divisionLevelId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  level?: InputMaybe<DivisionLevelRelationFilter>;
  type?: InputMaybe<EnumDivisionLevelTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CountryDivisionLevelWhereUniqueInput = {
  AND?: InputMaybe<Array<CountryDivisionLevelWhereInput>>;
  NOT?: InputMaybe<Array<CountryDivisionLevelWhereInput>>;
  OR?: InputMaybe<Array<CountryDivisionLevelWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  countryId_divisionLevelId?: InputMaybe<CountryDivisionLevelCountryIdDivisionLevelIdCompoundUniqueInput>;
  divisionLevelId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<DivisionLevelRelationFilter>;
  type?: InputMaybe<EnumDivisionLevelTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CountryForAdhocNotification = {
  __typename?: "CountryForAdhocNotification";
  code: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
};

export type CountryRelationFilter = {
  is?: InputMaybe<CountryWhereInput>;
  isNot?: InputMaybe<CountryWhereInput>;
};

export type CountryResponsePayload = {
  __typename?: "CountryResponsePayload";
  callingCode: Scalars["String"]["output"];
  code: Scalars["String"]["output"];
  currencyCode: CurrencyCode;
  id: Scalars["String"]["output"];
  lat: Scalars["Float"]["output"];
  lon: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
};

export type CountryUpdateOneRequiredWithoutAddressNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<CountryCreateWithoutAddressInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutAddressInput>;
  upsert?: InputMaybe<CountryUpsertWithoutAddressInput>;
};

export type CountryUpdateOneRequiredWithoutAdministrativeAreasNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutAdministrativeAreasInput>;
  create?: InputMaybe<CountryCreateWithoutAdministrativeAreasInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutAdministrativeAreasInput>;
  upsert?: InputMaybe<CountryUpsertWithoutAdministrativeAreasInput>;
};

export type CountryUpdateOneRequiredWithoutBranchesNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutBranchesInput>;
  create?: InputMaybe<CountryCreateWithoutBranchesInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutBranchesInput>;
  upsert?: InputMaybe<CountryUpsertWithoutBranchesInput>;
};

export type CountryUpdateOneRequiredWithoutCreditNotesNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutCreditNotesInput>;
  create?: InputMaybe<CountryCreateWithoutCreditNotesInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutCreditNotesInput>;
  upsert?: InputMaybe<CountryUpsertWithoutCreditNotesInput>;
};

export type CountryUpdateOneRequiredWithoutSchedulesNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutSchedulesInput>;
  create?: InputMaybe<CountryCreateWithoutSchedulesInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutSchedulesInput>;
  upsert?: InputMaybe<CountryUpsertWithoutSchedulesInput>;
};

export type CountryUpdateOneRequiredWithoutServicesNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutServicesInput>;
  create?: InputMaybe<CountryCreateWithoutServicesInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutServicesInput>;
  upsert?: InputMaybe<CountryUpsertWithoutServicesInput>;
};

export type CountryUpdateOneRequiredWithoutSpecialNeedsNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutSpecialNeedsInput>;
  create?: InputMaybe<CountryCreateWithoutSpecialNeedsInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutSpecialNeedsInput>;
  upsert?: InputMaybe<CountryUpsertWithoutSpecialNeedsInput>;
};

export type CountryUpdateOneWithoutBanksNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutBanksInput>;
  create?: InputMaybe<CountryCreateWithoutBanksInput>;
  delete?: InputMaybe<CountryWhereInput>;
  disconnect?: InputMaybe<CountryWhereInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutBanksInput>;
  upsert?: InputMaybe<CountryUpsertWithoutBanksInput>;
};

export type CountryUpdateOneWithoutScheduleExclusionsNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutScheduleExclusionsInput>;
  create?: InputMaybe<CountryCreateWithoutScheduleExclusionsInput>;
  delete?: InputMaybe<CountryWhereInput>;
  disconnect?: InputMaybe<CountryWhereInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutScheduleExclusionsInput>;
  upsert?: InputMaybe<CountryUpsertWithoutScheduleExclusionsInput>;
};

export type CountryUpdateOneWithoutVehicleNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutVehicleInput>;
  create?: InputMaybe<CountryCreateWithoutVehicleInput>;
  delete?: InputMaybe<CountryWhereInput>;
  disconnect?: InputMaybe<CountryWhereInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutVehicleInput>;
  upsert?: InputMaybe<CountryUpsertWithoutVehicleInput>;
};

export type CountryUpdateToOneWithWhereWithoutAddressInput = {
  data: CountryUpdateWithoutAddressInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateToOneWithWhereWithoutAdministrativeAreasInput = {
  data: CountryUpdateWithoutAdministrativeAreasInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateToOneWithWhereWithoutBanksInput = {
  data: CountryUpdateWithoutBanksInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateToOneWithWhereWithoutBranchesInput = {
  data: CountryUpdateWithoutBranchesInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateToOneWithWhereWithoutCreditNotesInput = {
  data: CountryUpdateWithoutCreditNotesInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateToOneWithWhereWithoutScheduleExclusionsInput = {
  data: CountryUpdateWithoutScheduleExclusionsInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateToOneWithWhereWithoutSchedulesInput = {
  data: CountryUpdateWithoutSchedulesInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateToOneWithWhereWithoutServicesInput = {
  data: CountryUpdateWithoutServicesInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateToOneWithWhereWithoutSpecialNeedsInput = {
  data: CountryUpdateWithoutSpecialNeedsInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateToOneWithWhereWithoutVehicleInput = {
  data: CountryUpdateWithoutVehicleInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateWithoutAddressInput = {
  administrativeAreas?: InputMaybe<AdministrativeAreaUpdateManyWithoutCountryNestedInput>;
  banks?: InputMaybe<BankUpdateManyWithoutCountryNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutCountryNestedInput>;
  callingCode?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutCountryNestedInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelUpdateManyWithoutCountryNestedInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeConfiguration?: InputMaybe<RouteConfigurationUpdateManyWithoutCountryNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutCountryNestedInput>;
  schedules?: InputMaybe<ScheduleUpdateManyWithoutCountryNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCountryNestedInput>;
  specialNeeds?: InputMaybe<SpecialNeedUpdateManyWithoutCountryNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateManyWithoutCountryNestedInput>;
};

export type CountryUpdateWithoutAdministrativeAreasInput = {
  address?: InputMaybe<AddressUpdateManyWithoutCountryNestedInput>;
  banks?: InputMaybe<BankUpdateManyWithoutCountryNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutCountryNestedInput>;
  callingCode?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutCountryNestedInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelUpdateManyWithoutCountryNestedInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeConfiguration?: InputMaybe<RouteConfigurationUpdateManyWithoutCountryNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutCountryNestedInput>;
  schedules?: InputMaybe<ScheduleUpdateManyWithoutCountryNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCountryNestedInput>;
  specialNeeds?: InputMaybe<SpecialNeedUpdateManyWithoutCountryNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateManyWithoutCountryNestedInput>;
};

export type CountryUpdateWithoutBanksInput = {
  address?: InputMaybe<AddressUpdateManyWithoutCountryNestedInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaUpdateManyWithoutCountryNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutCountryNestedInput>;
  callingCode?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutCountryNestedInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelUpdateManyWithoutCountryNestedInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeConfiguration?: InputMaybe<RouteConfigurationUpdateManyWithoutCountryNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutCountryNestedInput>;
  schedules?: InputMaybe<ScheduleUpdateManyWithoutCountryNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCountryNestedInput>;
  specialNeeds?: InputMaybe<SpecialNeedUpdateManyWithoutCountryNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateManyWithoutCountryNestedInput>;
};

export type CountryUpdateWithoutBranchesInput = {
  address?: InputMaybe<AddressUpdateManyWithoutCountryNestedInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaUpdateManyWithoutCountryNestedInput>;
  banks?: InputMaybe<BankUpdateManyWithoutCountryNestedInput>;
  callingCode?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutCountryNestedInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelUpdateManyWithoutCountryNestedInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeConfiguration?: InputMaybe<RouteConfigurationUpdateManyWithoutCountryNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutCountryNestedInput>;
  schedules?: InputMaybe<ScheduleUpdateManyWithoutCountryNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCountryNestedInput>;
  specialNeeds?: InputMaybe<SpecialNeedUpdateManyWithoutCountryNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateManyWithoutCountryNestedInput>;
};

export type CountryUpdateWithoutCreditNotesInput = {
  address?: InputMaybe<AddressUpdateManyWithoutCountryNestedInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaUpdateManyWithoutCountryNestedInput>;
  banks?: InputMaybe<BankUpdateManyWithoutCountryNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutCountryNestedInput>;
  callingCode?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelUpdateManyWithoutCountryNestedInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeConfiguration?: InputMaybe<RouteConfigurationUpdateManyWithoutCountryNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutCountryNestedInput>;
  schedules?: InputMaybe<ScheduleUpdateManyWithoutCountryNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCountryNestedInput>;
  specialNeeds?: InputMaybe<SpecialNeedUpdateManyWithoutCountryNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateManyWithoutCountryNestedInput>;
};

export type CountryUpdateWithoutScheduleExclusionsInput = {
  address?: InputMaybe<AddressUpdateManyWithoutCountryNestedInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaUpdateManyWithoutCountryNestedInput>;
  banks?: InputMaybe<BankUpdateManyWithoutCountryNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutCountryNestedInput>;
  callingCode?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutCountryNestedInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelUpdateManyWithoutCountryNestedInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeConfiguration?: InputMaybe<RouteConfigurationUpdateManyWithoutCountryNestedInput>;
  schedules?: InputMaybe<ScheduleUpdateManyWithoutCountryNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCountryNestedInput>;
  specialNeeds?: InputMaybe<SpecialNeedUpdateManyWithoutCountryNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateManyWithoutCountryNestedInput>;
};

export type CountryUpdateWithoutSchedulesInput = {
  address?: InputMaybe<AddressUpdateManyWithoutCountryNestedInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaUpdateManyWithoutCountryNestedInput>;
  banks?: InputMaybe<BankUpdateManyWithoutCountryNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutCountryNestedInput>;
  callingCode?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutCountryNestedInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelUpdateManyWithoutCountryNestedInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeConfiguration?: InputMaybe<RouteConfigurationUpdateManyWithoutCountryNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutCountryNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCountryNestedInput>;
  specialNeeds?: InputMaybe<SpecialNeedUpdateManyWithoutCountryNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateManyWithoutCountryNestedInput>;
};

export type CountryUpdateWithoutServicesInput = {
  address?: InputMaybe<AddressUpdateManyWithoutCountryNestedInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaUpdateManyWithoutCountryNestedInput>;
  banks?: InputMaybe<BankUpdateManyWithoutCountryNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutCountryNestedInput>;
  callingCode?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutCountryNestedInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelUpdateManyWithoutCountryNestedInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeConfiguration?: InputMaybe<RouteConfigurationUpdateManyWithoutCountryNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutCountryNestedInput>;
  schedules?: InputMaybe<ScheduleUpdateManyWithoutCountryNestedInput>;
  specialNeeds?: InputMaybe<SpecialNeedUpdateManyWithoutCountryNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateManyWithoutCountryNestedInput>;
};

export type CountryUpdateWithoutSpecialNeedsInput = {
  address?: InputMaybe<AddressUpdateManyWithoutCountryNestedInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaUpdateManyWithoutCountryNestedInput>;
  banks?: InputMaybe<BankUpdateManyWithoutCountryNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutCountryNestedInput>;
  callingCode?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutCountryNestedInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelUpdateManyWithoutCountryNestedInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeConfiguration?: InputMaybe<RouteConfigurationUpdateManyWithoutCountryNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutCountryNestedInput>;
  schedules?: InputMaybe<ScheduleUpdateManyWithoutCountryNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCountryNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateManyWithoutCountryNestedInput>;
};

export type CountryUpdateWithoutVehicleInput = {
  address?: InputMaybe<AddressUpdateManyWithoutCountryNestedInput>;
  administrativeAreas?: InputMaybe<AdministrativeAreaUpdateManyWithoutCountryNestedInput>;
  banks?: InputMaybe<BankUpdateManyWithoutCountryNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutCountryNestedInput>;
  callingCode?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutCountryNestedInput>;
  currencyBasicToSubunit?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCalcPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  currencyDisplayPrecision?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  levels?: InputMaybe<CountryDivisionLevelUpdateManyWithoutCountryNestedInput>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeConfiguration?: InputMaybe<RouteConfigurationUpdateManyWithoutCountryNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutCountryNestedInput>;
  schedules?: InputMaybe<ScheduleUpdateManyWithoutCountryNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCountryNestedInput>;
  specialNeeds?: InputMaybe<SpecialNeedUpdateManyWithoutCountryNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CountryUpsertWithoutAddressInput = {
  create: CountryCreateWithoutAddressInput;
  update: CountryUpdateWithoutAddressInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpsertWithoutAdministrativeAreasInput = {
  create: CountryCreateWithoutAdministrativeAreasInput;
  update: CountryUpdateWithoutAdministrativeAreasInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpsertWithoutBanksInput = {
  create: CountryCreateWithoutBanksInput;
  update: CountryUpdateWithoutBanksInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpsertWithoutBranchesInput = {
  create: CountryCreateWithoutBranchesInput;
  update: CountryUpdateWithoutBranchesInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpsertWithoutCreditNotesInput = {
  create: CountryCreateWithoutCreditNotesInput;
  update: CountryUpdateWithoutCreditNotesInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpsertWithoutScheduleExclusionsInput = {
  create: CountryCreateWithoutScheduleExclusionsInput;
  update: CountryUpdateWithoutScheduleExclusionsInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpsertWithoutSchedulesInput = {
  create: CountryCreateWithoutSchedulesInput;
  update: CountryUpdateWithoutSchedulesInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpsertWithoutServicesInput = {
  create: CountryCreateWithoutServicesInput;
  update: CountryUpdateWithoutServicesInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpsertWithoutSpecialNeedsInput = {
  create: CountryCreateWithoutSpecialNeedsInput;
  update: CountryUpdateWithoutSpecialNeedsInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpsertWithoutVehicleInput = {
  create: CountryCreateWithoutVehicleInput;
  update: CountryUpdateWithoutVehicleInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryWhereInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  address?: InputMaybe<AddressListRelationFilter>;
  administrativeAreas?: InputMaybe<AdministrativeAreaListRelationFilter>;
  banks?: InputMaybe<BankListRelationFilter>;
  branches?: InputMaybe<BranchListRelationFilter>;
  callingCode?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  creditNotes?: InputMaybe<CreditNoteListRelationFilter>;
  currencyBasicToSubunit?: InputMaybe<IntFilter>;
  currencyCalcPrecision?: InputMaybe<IntFilter>;
  currencyCode?: InputMaybe<EnumCurrencyCodeFilter>;
  currencyDisplayPrecision?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  lat?: InputMaybe<FloatFilter>;
  levels?: InputMaybe<CountryDivisionLevelListRelationFilter>;
  lon?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  routeConfiguration?: InputMaybe<RouteConfigurationListRelationFilter>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionListRelationFilter>;
  schedules?: InputMaybe<ScheduleListRelationFilter>;
  services?: InputMaybe<ServiceListRelationFilter>;
  specialNeeds?: InputMaybe<SpecialNeedListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleListRelationFilter>;
};

export type CountryWhereUniqueInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  address?: InputMaybe<AddressListRelationFilter>;
  administrativeAreas?: InputMaybe<AdministrativeAreaListRelationFilter>;
  banks?: InputMaybe<BankListRelationFilter>;
  branches?: InputMaybe<BranchListRelationFilter>;
  callingCode?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteListRelationFilter>;
  currencyBasicToSubunit?: InputMaybe<IntFilter>;
  currencyCalcPrecision?: InputMaybe<IntFilter>;
  currencyCode?: InputMaybe<CurrencyCode>;
  currencyDisplayPrecision?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<FloatFilter>;
  levels?: InputMaybe<CountryDivisionLevelListRelationFilter>;
  lon?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  routeConfiguration?: InputMaybe<RouteConfigurationListRelationFilter>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionListRelationFilter>;
  schedules?: InputMaybe<ScheduleListRelationFilter>;
  services?: InputMaybe<ServiceListRelationFilter>;
  specialNeeds?: InputMaybe<SpecialNeedListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleListRelationFilter>;
};

export type CreateAddressInput = {
  administrativeAreaId?: InputMaybe<Scalars["String"]["input"]>;
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  countryId: Scalars["String"]["input"];
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary: Scalars["Boolean"]["input"];
  lat: Scalars["Float"]["input"];
  localityId?: InputMaybe<Scalars["String"]["input"]>;
  lon: Scalars["Float"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  subLocalityId?: InputMaybe<Scalars["String"]["input"]>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateAgentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewAgentInput>;
  oldAgentTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldAgentInput>;
  routes?: InputMaybe<RouteAgentCreateNestedManyWithoutAgentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutAgentInput>;
  schoolRegistrationStatusChange?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutAgentInput>;
  status?: InputMaybe<AgentStatus>;
  terminatedBySchoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutTerminatedByInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutAgentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: CreateAgentUser;
};

export type CreateAgentUser = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAgentInput>;
  create?: InputMaybe<CreateAgentUserInput>;
};

export type CreateAgentUserInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName: Scalars["String"]["input"];
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone: Scalars["String"]["input"];
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CreateBankDetails = {
  accountName: Scalars["String"]["input"];
  accountNumber: Scalars["String"]["input"];
  bank: BankCreateNestedOneWithoutBanksDetailsInput;
};

export type CreateCollectionPointInput = {
  address: AddressCreateNestedOneWithoutCollectionPointInput;
  code: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  schools?: InputMaybe<CreateCollectionPointSchool>;
  serviceProvider?: InputMaybe<CreateCollectionPointServiceProvider>;
  status: CollectionPointStatus;
};

export type CreateCollectionPointSchool = {
  create?: InputMaybe<
    Array<SchoolCollectionPointCreateWithoutCollectionPointInput>
  >;
};

export type CreateCollectionPointServiceProvider = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
};

export type CreateLeaveRequestInput = {
  endDate: Scalars["DateTime"]["input"];
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  routesIds: Array<Scalars["String"]["input"]>;
  schoolRegistrationId: Scalars["String"]["input"];
  sponsorUserId: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
};

export type CreateLeaveRequestInputForSponsor = {
  directionality: TripType;
  endDate: Scalars["DateTime"]["input"];
  requestChannel?: InputMaybe<LeaveRequestChannel>;
  schoolRegistrationId: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
};

export type CreateServiceProviderAddress = {
  create: AddressCreateInput;
};

export type CreateServiceProviderBankDetails = {
  create: CreateBankDetails;
};

export type CreateServiceProviderGroupInput = {
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateServiceProviderInput = {
  address: CreateServiceProviderAddress;
  bankDetails?: InputMaybe<CreateServiceProviderBankDetails>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: CreateServiceProviderOwnerUser;
  serviceProviderGroupId?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderSchools: ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput;
  status?: InputMaybe<ServiceProviderStatus>;
  subscriptionPlan?: InputMaybe<CreateServiceProviderSubscriptionPlan>;
  type?: InputMaybe<ServiceProviderType>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CreateServiceProviderOwnerUser = {
  create: ServiceProviderOwnerUser;
};

export type CreateServiceProviderSubscriptionPlan = {
  endDate: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["String"]["input"];
  status?: InputMaybe<ServiceProviderPlanStatus>;
};

export type CreateUserAddress = {
  create: Array<AddressCreateInput>;
};

export type CreateUserInput = {
  countryCode?: InputMaybe<CountryCode>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  phone: Scalars["String"]["input"];
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<Array<CreateUserRoleInput>>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
};

export type CreateUserRoleInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  googleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type CreditInfo = {
  __typename?: "CreditInfo";
  cashableCredit: Scalars["Float"]["output"];
  creditSpent: Scalars["Float"]["output"];
  currency: CurrencyCode;
  currentCredit: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  nonCashableCredit: Scalars["Float"]["output"];
};

export type CreditNote = {
  __typename?: "CreditNote";
  _count: CreditNoteCount;
  amount: Scalars["Float"]["output"];
  cashOutReason?: Maybe<Scalars["String"]["output"]>;
  cashOutRequest?: Maybe<Array<CashOutRequest>>;
  cashedInAt?: Maybe<Scalars["DateTime"]["output"]>;
  cashedOutAt?: Maybe<Scalars["DateTime"]["output"]>;
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  creditSource: CreditNoteCreditSource;
  currency: CurrencyCode;
  evidenceFileLink?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isCashOut: Scalars["Boolean"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  otherCreditReason?: Maybe<Scalars["String"]["output"]>;
  otherData?: Maybe<Scalars["JSON"]["output"]>;
  paymentTransaction?: Maybe<PaymentTransaction>;
  paymentTransactionId?: Maybe<Scalars["String"]["output"]>;
  paymentTransactionRef?: Maybe<PaymentTransaction>;
  paymentTransactionRefId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type CreditNoteCount = {
  __typename?: "CreditNoteCount";
  cashOutRequest: Scalars["Int"]["output"];
};

export type CreditNoteCreateManyCountryInput = {
  amount: Scalars["Float"]["input"];
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency: CurrencyCode;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut: Scalars["Boolean"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionRefId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type CreditNoteCreateManyCountryInputEnvelope = {
  data: Array<CreditNoteCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CreditNoteCreateManyOpsAgentInput = {
  amount: Scalars["Float"]["input"];
  creditSource: CreditNoteCreditSource;
  currency: CurrencyCode;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut: Scalars["Boolean"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  studentIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  user: UserCreateNestedOneWithoutCreditNotesInputWithoutCreate;
};

export type CreditNoteCreateManyPaymentTransactionRefInput = {
  amount: Scalars["Float"]["input"];
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryId: Scalars["String"]["input"];
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency: CurrencyCode;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut: Scalars["Boolean"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type CreditNoteCreateManyPaymentTransactionRefInputEnvelope = {
  data: Array<CreditNoteCreateManyPaymentTransactionRefInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CreditNoteCreateManyUserInput = {
  amount: Scalars["Float"]["input"];
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryId: Scalars["String"]["input"];
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency: CurrencyCode;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut: Scalars["Boolean"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionRefId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CreditNoteCreateManyUserInputEnvelope = {
  data: Array<CreditNoteCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CreditNoteCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CreditNoteCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<CreditNoteCreateWithoutCountryInput>>;
  createMany?: InputMaybe<CreditNoteCreateManyCountryInputEnvelope>;
};

export type CreditNoteCreateNestedManyWithoutPaymentTransactionRefInput = {
  connect?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CreditNoteCreateOrConnectWithoutPaymentTransactionRefInput>
  >;
  create?: InputMaybe<Array<CreditNoteCreateWithoutPaymentTransactionRefInput>>;
  createMany?: InputMaybe<CreditNoteCreateManyPaymentTransactionRefInputEnvelope>;
};

export type CreditNoteCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CreditNoteCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<CreditNoteCreateWithoutUserInput>>;
  createMany?: InputMaybe<CreditNoteCreateManyUserInputEnvelope>;
};

export type CreditNoteCreateNestedOneWithoutCashOutRequestInput = {
  connect?: InputMaybe<CreditNoteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CreditNoteCreateOrConnectWithoutCashOutRequestInput>;
  create?: InputMaybe<CreditNoteCreateWithoutCashOutRequestInput>;
};

export type CreditNoteCreateNestedOneWithoutPaymentTransactionInput = {
  connect?: InputMaybe<CreditNoteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CreditNoteCreateOrConnectWithoutPaymentTransactionInput>;
  create?: InputMaybe<CreditNoteCreateWithoutPaymentTransactionInput>;
};

export type CreditNoteCreateOrConnectWithoutCashOutRequestInput = {
  create: CreditNoteCreateWithoutCashOutRequestInput;
  where: CreditNoteWhereUniqueInput;
};

export type CreditNoteCreateOrConnectWithoutCountryInput = {
  create: CreditNoteCreateWithoutCountryInput;
  where: CreditNoteWhereUniqueInput;
};

export type CreditNoteCreateOrConnectWithoutPaymentTransactionInput = {
  create: CreditNoteCreateWithoutPaymentTransactionInput;
  where: CreditNoteWhereUniqueInput;
};

export type CreditNoteCreateOrConnectWithoutPaymentTransactionRefInput = {
  create: CreditNoteCreateWithoutPaymentTransactionRefInput;
  where: CreditNoteWhereUniqueInput;
};

export type CreditNoteCreateOrConnectWithoutUserInput = {
  create: CreditNoteCreateWithoutUserInput;
  where: CreditNoteWhereUniqueInput;
};

export type CreditNoteCreateWithoutCashOutRequestInput = {
  amount: Scalars["Float"]["input"];
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  country: CountryCreateNestedOneWithoutCreditNotesInput;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency: CurrencyCode;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut: Scalars["Boolean"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutCreditNoteInput>;
  paymentTransactionRef?: InputMaybe<PaymentTransactionCreateNestedOneWithoutCreditNotesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutCreditNotesInput;
};

export type CreditNoteCreateWithoutCountryInput = {
  amount: Scalars["Float"]["input"];
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashOutRequest?: InputMaybe<CashOutRequestCreateNestedManyWithoutCreditNoteInput>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency: CurrencyCode;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut: Scalars["Boolean"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutCreditNoteInput>;
  paymentTransactionRef?: InputMaybe<PaymentTransactionCreateNestedOneWithoutCreditNotesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutCreditNotesInput;
};

export type CreditNoteCreateWithoutPaymentTransactionInput = {
  amount: Scalars["Float"]["input"];
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashOutRequest?: InputMaybe<CashOutRequestCreateNestedManyWithoutCreditNoteInput>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  country: CountryCreateNestedOneWithoutCreditNotesInput;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency: CurrencyCode;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut: Scalars["Boolean"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransactionRef?: InputMaybe<PaymentTransactionCreateNestedOneWithoutCreditNotesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutCreditNotesInput;
};

export type CreditNoteCreateWithoutPaymentTransactionRefInput = {
  amount: Scalars["Float"]["input"];
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashOutRequest?: InputMaybe<CashOutRequestCreateNestedManyWithoutCreditNoteInput>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  country: CountryCreateNestedOneWithoutCreditNotesInput;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency: CurrencyCode;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut: Scalars["Boolean"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutCreditNoteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutCreditNotesInput;
};

export type CreditNoteCreateWithoutUserInput = {
  amount: Scalars["Float"]["input"];
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashOutRequest?: InputMaybe<CashOutRequestCreateNestedManyWithoutCreditNoteInput>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  country: CountryCreateNestedOneWithoutCreditNotesInput;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency: CurrencyCode;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut: Scalars["Boolean"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutCreditNoteInput>;
  paymentTransactionRef?: InputMaybe<PaymentTransactionCreateNestedOneWithoutCreditNotesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum CreditNoteCreditSource {
  CUSTOMER_RETENTION = "CUSTOMER_RETENTION",
  HISTORICAL_TRANSACTION = "HISTORICAL_TRANSACTION",
  OTHER = "OTHER",
  SERVICE_DISRUPTION = "SERVICE_DISRUPTION",
}

export type CreditNoteListRelationFilter = {
  every?: InputMaybe<CreditNoteWhereInput>;
  none?: InputMaybe<CreditNoteWhereInput>;
  some?: InputMaybe<CreditNoteWhereInput>;
};

export type CreditNoteRelationFilter = {
  is?: InputMaybe<CreditNoteWhereInput>;
  isNot?: InputMaybe<CreditNoteWhereInput>;
};

export type CreditNoteScalarWhereInput = {
  AND?: InputMaybe<Array<CreditNoteScalarWhereInput>>;
  NOT?: InputMaybe<Array<CreditNoteScalarWhereInput>>;
  OR?: InputMaybe<Array<CreditNoteScalarWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  cashOutReason?: InputMaybe<StringFilter>;
  cashedInAt?: InputMaybe<DateTimeFilter>;
  cashedOutAt?: InputMaybe<DateTimeFilter>;
  countryId?: InputMaybe<StringFilter>;
  creditSource?: InputMaybe<EnumCreditNoteCreditSourceFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  evidenceFileLink?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isCashOut?: InputMaybe<BoolFilter>;
  notes?: InputMaybe<StringFilter>;
  otherCreditReason?: InputMaybe<StringFilter>;
  otherData?: InputMaybe<JsonFilter>;
  paymentTransactionId?: InputMaybe<StringFilter>;
  paymentTransactionRefId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CreditNoteUpdateManyMutationInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency?: InputMaybe<CurrencyCode>;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CreditNoteUpdateManyWithWhereWithoutCountryInput = {
  data: CreditNoteUpdateManyMutationInput;
  where: CreditNoteScalarWhereInput;
};

export type CreditNoteUpdateManyWithWhereWithoutPaymentTransactionRefInput = {
  data: CreditNoteUpdateManyMutationInput;
  where: CreditNoteScalarWhereInput;
};

export type CreditNoteUpdateManyWithWhereWithoutUserInput = {
  data: CreditNoteUpdateManyMutationInput;
  where: CreditNoteScalarWhereInput;
};

export type CreditNoteUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CreditNoteCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<CreditNoteCreateWithoutCountryInput>>;
  createMany?: InputMaybe<CreditNoteCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CreditNoteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  set?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CreditNoteUpdateWithWhereUniqueWithoutCountryInput>
  >;
  updateMany?: InputMaybe<
    Array<CreditNoteUpdateManyWithWhereWithoutCountryInput>
  >;
  upsert?: InputMaybe<
    Array<CreditNoteUpsertWithWhereUniqueWithoutCountryInput>
  >;
};

export type CreditNoteUpdateManyWithoutPaymentTransactionRefNestedInput = {
  connect?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CreditNoteCreateOrConnectWithoutPaymentTransactionRefInput>
  >;
  create?: InputMaybe<Array<CreditNoteCreateWithoutPaymentTransactionRefInput>>;
  createMany?: InputMaybe<CreditNoteCreateManyPaymentTransactionRefInputEnvelope>;
  delete?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CreditNoteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  set?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CreditNoteUpdateWithWhereUniqueWithoutPaymentTransactionRefInput>
  >;
  updateMany?: InputMaybe<
    Array<CreditNoteUpdateManyWithWhereWithoutPaymentTransactionRefInput>
  >;
  upsert?: InputMaybe<
    Array<CreditNoteUpsertWithWhereUniqueWithoutPaymentTransactionRefInput>
  >;
};

export type CreditNoteUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CreditNoteCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<CreditNoteCreateWithoutUserInput>>;
  createMany?: InputMaybe<CreditNoteCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CreditNoteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  set?: InputMaybe<Array<CreditNoteWhereUniqueInput>>;
  update?: InputMaybe<Array<CreditNoteUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CreditNoteUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CreditNoteUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CreditNoteUpdateOneWithoutCashOutRequestNestedInput = {
  connect?: InputMaybe<CreditNoteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CreditNoteCreateOrConnectWithoutCashOutRequestInput>;
  create?: InputMaybe<CreditNoteCreateWithoutCashOutRequestInput>;
  delete?: InputMaybe<CreditNoteWhereInput>;
  disconnect?: InputMaybe<CreditNoteWhereInput>;
  update?: InputMaybe<CreditNoteUpdateToOneWithWhereWithoutCashOutRequestInput>;
  upsert?: InputMaybe<CreditNoteUpsertWithoutCashOutRequestInput>;
};

export type CreditNoteUpdateOneWithoutPaymentTransactionNestedInput = {
  connect?: InputMaybe<CreditNoteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CreditNoteCreateOrConnectWithoutPaymentTransactionInput>;
  create?: InputMaybe<CreditNoteCreateWithoutPaymentTransactionInput>;
  delete?: InputMaybe<CreditNoteWhereInput>;
  disconnect?: InputMaybe<CreditNoteWhereInput>;
  update?: InputMaybe<CreditNoteUpdateToOneWithWhereWithoutPaymentTransactionInput>;
  upsert?: InputMaybe<CreditNoteUpsertWithoutPaymentTransactionInput>;
};

export type CreditNoteUpdateToOneWithWhereWithoutCashOutRequestInput = {
  data: CreditNoteUpdateWithoutCashOutRequestInput;
  where?: InputMaybe<CreditNoteWhereInput>;
};

export type CreditNoteUpdateToOneWithWhereWithoutPaymentTransactionInput = {
  data: CreditNoteUpdateWithoutPaymentTransactionInput;
  where?: InputMaybe<CreditNoteWhereInput>;
};

export type CreditNoteUpdateWithWhereUniqueWithoutCountryInput = {
  data: CreditNoteUpdateWithoutCountryInput;
  where: CreditNoteWhereUniqueInput;
};

export type CreditNoteUpdateWithWhereUniqueWithoutPaymentTransactionRefInput = {
  data: CreditNoteUpdateWithoutPaymentTransactionRefInput;
  where: CreditNoteWhereUniqueInput;
};

export type CreditNoteUpdateWithWhereUniqueWithoutUserInput = {
  data: CreditNoteUpdateWithoutUserInput;
  where: CreditNoteWhereUniqueInput;
};

export type CreditNoteUpdateWithoutCashOutRequestInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutCreditNotesNestedInput>;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency?: InputMaybe<CurrencyCode>;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutCreditNoteNestedInput>;
  paymentTransactionRef?: InputMaybe<PaymentTransactionUpdateOneWithoutCreditNotesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCreditNotesNestedInput>;
};

export type CreditNoteUpdateWithoutCountryInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashOutRequest?: InputMaybe<CashOutRequestUpdateManyWithoutCreditNoteNestedInput>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency?: InputMaybe<CurrencyCode>;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutCreditNoteNestedInput>;
  paymentTransactionRef?: InputMaybe<PaymentTransactionUpdateOneWithoutCreditNotesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCreditNotesNestedInput>;
};

export type CreditNoteUpdateWithoutPaymentTransactionInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashOutRequest?: InputMaybe<CashOutRequestUpdateManyWithoutCreditNoteNestedInput>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutCreditNotesNestedInput>;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency?: InputMaybe<CurrencyCode>;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransactionRef?: InputMaybe<PaymentTransactionUpdateOneWithoutCreditNotesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCreditNotesNestedInput>;
};

export type CreditNoteUpdateWithoutPaymentTransactionRefInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashOutRequest?: InputMaybe<CashOutRequestUpdateManyWithoutCreditNoteNestedInput>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutCreditNotesNestedInput>;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency?: InputMaybe<CurrencyCode>;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutCreditNoteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCreditNotesNestedInput>;
};

export type CreditNoteUpdateWithoutUserInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  cashOutReason?: InputMaybe<Scalars["String"]["input"]>;
  cashOutRequest?: InputMaybe<CashOutRequestUpdateManyWithoutCreditNoteNestedInput>;
  cashedInAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cashedOutAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutCreditNotesNestedInput>;
  creditSource?: InputMaybe<CreditNoteCreditSource>;
  currency?: InputMaybe<CurrencyCode>;
  evidenceFileLink?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  otherCreditReason?: InputMaybe<Scalars["String"]["input"]>;
  otherData?: InputMaybe<Scalars["JSON"]["input"]>;
  paymentTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutCreditNoteNestedInput>;
  paymentTransactionRef?: InputMaybe<PaymentTransactionUpdateOneWithoutCreditNotesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CreditNoteUpsertWithWhereUniqueWithoutCountryInput = {
  create: CreditNoteCreateWithoutCountryInput;
  update: CreditNoteUpdateWithoutCountryInput;
  where: CreditNoteWhereUniqueInput;
};

export type CreditNoteUpsertWithWhereUniqueWithoutPaymentTransactionRefInput = {
  create: CreditNoteCreateWithoutPaymentTransactionRefInput;
  update: CreditNoteUpdateWithoutPaymentTransactionRefInput;
  where: CreditNoteWhereUniqueInput;
};

export type CreditNoteUpsertWithWhereUniqueWithoutUserInput = {
  create: CreditNoteCreateWithoutUserInput;
  update: CreditNoteUpdateWithoutUserInput;
  where: CreditNoteWhereUniqueInput;
};

export type CreditNoteUpsertWithoutCashOutRequestInput = {
  create: CreditNoteCreateWithoutCashOutRequestInput;
  update: CreditNoteUpdateWithoutCashOutRequestInput;
  where?: InputMaybe<CreditNoteWhereInput>;
};

export type CreditNoteUpsertWithoutPaymentTransactionInput = {
  create: CreditNoteCreateWithoutPaymentTransactionInput;
  update: CreditNoteUpdateWithoutPaymentTransactionInput;
  where?: InputMaybe<CreditNoteWhereInput>;
};

export type CreditNoteWhereInput = {
  AND?: InputMaybe<Array<CreditNoteWhereInput>>;
  NOT?: InputMaybe<Array<CreditNoteWhereInput>>;
  OR?: InputMaybe<Array<CreditNoteWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  cashOutReason?: InputMaybe<StringFilter>;
  cashOutRequest?: InputMaybe<CashOutRequestListRelationFilter>;
  cashedInAt?: InputMaybe<DateTimeFilter>;
  cashedOutAt?: InputMaybe<DateTimeFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  creditSource?: InputMaybe<EnumCreditNoteCreditSourceFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  evidenceFileLink?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isCashOut?: InputMaybe<BoolFilter>;
  notes?: InputMaybe<StringFilter>;
  otherCreditReason?: InputMaybe<StringFilter>;
  otherData?: InputMaybe<JsonFilter>;
  paymentTransaction?: InputMaybe<PaymentTransactionRelationFilter>;
  paymentTransactionId?: InputMaybe<StringFilter>;
  paymentTransactionRef?: InputMaybe<PaymentTransactionRelationFilter>;
  paymentTransactionRefId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CreditNoteWhereUniqueInput = {
  AND?: InputMaybe<Array<CreditNoteWhereInput>>;
  NOT?: InputMaybe<Array<CreditNoteWhereInput>>;
  OR?: InputMaybe<Array<CreditNoteWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  cashOutReason?: InputMaybe<StringFilter>;
  cashOutRequest?: InputMaybe<CashOutRequestListRelationFilter>;
  cashedInAt?: InputMaybe<DateTimeFilter>;
  cashedOutAt?: InputMaybe<DateTimeFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  creditSource?: InputMaybe<EnumCreditNoteCreditSourceFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  evidenceFileLink?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCashOut?: InputMaybe<BoolFilter>;
  notes?: InputMaybe<StringFilter>;
  otherCreditReason?: InputMaybe<StringFilter>;
  otherData?: InputMaybe<JsonFilter>;
  paymentTransaction?: InputMaybe<PaymentTransactionRelationFilter>;
  paymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionRef?: InputMaybe<PaymentTransactionRelationFilter>;
  paymentTransactionRefId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CreditNoteWithReferenceNumberAndStudents = {
  __typename?: "CreditNoteWithReferenceNumberAndStudents";
  _count: CreditNoteCount;
  amount: Scalars["Float"]["output"];
  cashOutReason?: Maybe<Scalars["String"]["output"]>;
  cashOutRequest?: Maybe<Array<CashOutRequest>>;
  cashedInAt?: Maybe<Scalars["DateTime"]["output"]>;
  cashedOutAt?: Maybe<Scalars["DateTime"]["output"]>;
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  creditSource: CreditNoteCreditSource;
  currency: CurrencyCode;
  evidenceFileLink?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isCashOut: Scalars["Boolean"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  otherCreditReason?: Maybe<Scalars["String"]["output"]>;
  otherData?: Maybe<Scalars["JSON"]["output"]>;
  paymentTransaction?: Maybe<PaymentTransaction>;
  paymentTransactionId?: Maybe<Scalars["String"]["output"]>;
  paymentTransactionRef?: Maybe<PaymentTransaction>;
  paymentTransactionRefId?: Maybe<Scalars["String"]["output"]>;
  referenceNumber?: Maybe<Scalars["String"]["output"]>;
  students?: Maybe<Array<Student>>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type CronJob = {
  __typename?: "CronJob";
  _count: CronJobCount;
  createdAt: Scalars["DateTime"]["output"];
  cronJobInstance?: Maybe<Array<CronJobInstance>>;
  description: Scalars["String"]["output"];
  eventName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  pattern: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type CronJobCount = {
  __typename?: "CronJobCount";
  cronJobInstance: Scalars["Int"]["output"];
};

export type CronJobInstance = {
  __typename?: "CronJobInstance";
  completedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  cronJob: CronJob;
  cronJobId: Scalars["String"]["output"];
  error?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  status: CronJobStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum CronJobStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  RUNNING = "RUNNING",
}

export enum CurrencyCode {
  AED = "AED",
  KWD = "KWD",
  OMR = "OMR",
  SAR = "SAR",
}

export type CustomerOrderByWithRelationInputFirstOrder = {
  firstName?: InputMaybe<SortOrderInput>;
  firstNameAr?: InputMaybe<SortOrderInput>;
  lastName?: InputMaybe<SortOrderInput>;
  lastNameAr?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  not?: InputMaybe<DateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
};

export type DelayTripStopAlert = {
  __typename?: "DelayTripStopAlert";
  alert?: Maybe<Alert>;
  alertId: Scalars["String"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  delayInMinutes: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  trip: Trip;
  tripId: Scalars["String"]["output"];
  tripStop: TripStop;
  tripStopId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type DelayTripStopAlertCreateManyTripInput = {
  alertId: Scalars["String"]["input"];
  delayInMinutes: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  tripStopId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DelayTripStopAlertCreateManyTripInputEnvelope = {
  data: Array<DelayTripStopAlertCreateManyTripInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DelayTripStopAlertCreateManyTripStopInput = {
  alertId: Scalars["String"]["input"];
  delayInMinutes: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DelayTripStopAlertCreateManyTripStopInputEnvelope = {
  data: Array<DelayTripStopAlertCreateManyTripStopInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DelayTripStopAlertCreateNestedManyWithoutTripInput = {
  connect?: InputMaybe<Array<DelayTripStopAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DelayTripStopAlertCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<DelayTripStopAlertCreateWithoutTripInput>>;
  createMany?: InputMaybe<DelayTripStopAlertCreateManyTripInputEnvelope>;
};

export type DelayTripStopAlertCreateNestedManyWithoutTripStopInput = {
  connect?: InputMaybe<Array<DelayTripStopAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DelayTripStopAlertCreateOrConnectWithoutTripStopInput>
  >;
  create?: InputMaybe<Array<DelayTripStopAlertCreateWithoutTripStopInput>>;
  createMany?: InputMaybe<DelayTripStopAlertCreateManyTripStopInputEnvelope>;
};

export type DelayTripStopAlertCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<DelayTripStopAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DelayTripStopAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<DelayTripStopAlertCreateWithoutAlertInput>;
};

export type DelayTripStopAlertCreateOrConnectWithoutAlertInput = {
  create: DelayTripStopAlertCreateWithoutAlertInput;
  where: DelayTripStopAlertWhereUniqueInput;
};

export type DelayTripStopAlertCreateOrConnectWithoutTripInput = {
  create: DelayTripStopAlertCreateWithoutTripInput;
  where: DelayTripStopAlertWhereUniqueInput;
};

export type DelayTripStopAlertCreateOrConnectWithoutTripStopInput = {
  create: DelayTripStopAlertCreateWithoutTripStopInput;
  where: DelayTripStopAlertWhereUniqueInput;
};

export type DelayTripStopAlertCreateWithoutAlertInput = {
  delayInMinutes: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  trip: TripCreateNestedOneWithoutDelayTripStopAlertsInput;
  tripStop: TripStopCreateNestedOneWithoutDelayTripStopAlertsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DelayTripStopAlertCreateWithoutTripInput = {
  alert?: InputMaybe<AlertCreateNestedOneWithoutDelayTripStopAlertInput>;
  delayInMinutes: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  tripStop: TripStopCreateNestedOneWithoutDelayTripStopAlertsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DelayTripStopAlertCreateWithoutTripStopInput = {
  alert?: InputMaybe<AlertCreateNestedOneWithoutDelayTripStopAlertInput>;
  delayInMinutes: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  trip: TripCreateNestedOneWithoutDelayTripStopAlertsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DelayTripStopAlertListRelationFilter = {
  every?: InputMaybe<DelayTripStopAlertWhereInput>;
  none?: InputMaybe<DelayTripStopAlertWhereInput>;
  some?: InputMaybe<DelayTripStopAlertWhereInput>;
};

export type DelayTripStopAlertRelationFilter = {
  is?: InputMaybe<DelayTripStopAlertWhereInput>;
  isNot?: InputMaybe<DelayTripStopAlertWhereInput>;
};

export type DelayTripStopAlertScalarWhereInput = {
  AND?: InputMaybe<Array<DelayTripStopAlertScalarWhereInput>>;
  NOT?: InputMaybe<Array<DelayTripStopAlertScalarWhereInput>>;
  OR?: InputMaybe<Array<DelayTripStopAlertScalarWhereInput>>;
  alertId?: InputMaybe<StringFilter>;
  delayInMinutes?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  tripId?: InputMaybe<StringFilter>;
  tripStopId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DelayTripStopAlertUpdateManyMutationInput = {
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DelayTripStopAlertUpdateManyWithWhereWithoutTripInput = {
  data: DelayTripStopAlertUpdateManyMutationInput;
  where: DelayTripStopAlertScalarWhereInput;
};

export type DelayTripStopAlertUpdateManyWithWhereWithoutTripStopInput = {
  data: DelayTripStopAlertUpdateManyMutationInput;
  where: DelayTripStopAlertScalarWhereInput;
};

export type DelayTripStopAlertUpdateManyWithoutTripNestedInput = {
  connect?: InputMaybe<Array<DelayTripStopAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DelayTripStopAlertCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<DelayTripStopAlertCreateWithoutTripInput>>;
  createMany?: InputMaybe<DelayTripStopAlertCreateManyTripInputEnvelope>;
  delete?: InputMaybe<Array<DelayTripStopAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DelayTripStopAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DelayTripStopAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<DelayTripStopAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DelayTripStopAlertUpdateWithWhereUniqueWithoutTripInput>
  >;
  updateMany?: InputMaybe<
    Array<DelayTripStopAlertUpdateManyWithWhereWithoutTripInput>
  >;
  upsert?: InputMaybe<
    Array<DelayTripStopAlertUpsertWithWhereUniqueWithoutTripInput>
  >;
};

export type DelayTripStopAlertUpdateManyWithoutTripStopNestedInput = {
  connect?: InputMaybe<Array<DelayTripStopAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DelayTripStopAlertCreateOrConnectWithoutTripStopInput>
  >;
  create?: InputMaybe<Array<DelayTripStopAlertCreateWithoutTripStopInput>>;
  createMany?: InputMaybe<DelayTripStopAlertCreateManyTripStopInputEnvelope>;
  delete?: InputMaybe<Array<DelayTripStopAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DelayTripStopAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DelayTripStopAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<DelayTripStopAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DelayTripStopAlertUpdateWithWhereUniqueWithoutTripStopInput>
  >;
  updateMany?: InputMaybe<
    Array<DelayTripStopAlertUpdateManyWithWhereWithoutTripStopInput>
  >;
  upsert?: InputMaybe<
    Array<DelayTripStopAlertUpsertWithWhereUniqueWithoutTripStopInput>
  >;
};

export type DelayTripStopAlertUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<DelayTripStopAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DelayTripStopAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<DelayTripStopAlertCreateWithoutAlertInput>;
  delete?: InputMaybe<DelayTripStopAlertWhereInput>;
  disconnect?: InputMaybe<DelayTripStopAlertWhereInput>;
  update?: InputMaybe<DelayTripStopAlertUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<DelayTripStopAlertUpsertWithoutAlertInput>;
};

export type DelayTripStopAlertUpdateToOneWithWhereWithoutAlertInput = {
  data: DelayTripStopAlertUpdateWithoutAlertInput;
  where?: InputMaybe<DelayTripStopAlertWhereInput>;
};

export type DelayTripStopAlertUpdateWithWhereUniqueWithoutTripInput = {
  data: DelayTripStopAlertUpdateWithoutTripInput;
  where: DelayTripStopAlertWhereUniqueInput;
};

export type DelayTripStopAlertUpdateWithWhereUniqueWithoutTripStopInput = {
  data: DelayTripStopAlertUpdateWithoutTripStopInput;
  where: DelayTripStopAlertWhereUniqueInput;
};

export type DelayTripStopAlertUpdateWithoutAlertInput = {
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutDelayTripStopAlertsNestedInput>;
  tripStop?: InputMaybe<TripStopUpdateOneRequiredWithoutDelayTripStopAlertsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DelayTripStopAlertUpdateWithoutTripInput = {
  alert?: InputMaybe<AlertUpdateOneWithoutDelayTripStopAlertNestedInput>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  tripStop?: InputMaybe<TripStopUpdateOneRequiredWithoutDelayTripStopAlertsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DelayTripStopAlertUpdateWithoutTripStopInput = {
  alert?: InputMaybe<AlertUpdateOneWithoutDelayTripStopAlertNestedInput>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutDelayTripStopAlertsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DelayTripStopAlertUpsertWithWhereUniqueWithoutTripInput = {
  create: DelayTripStopAlertCreateWithoutTripInput;
  update: DelayTripStopAlertUpdateWithoutTripInput;
  where: DelayTripStopAlertWhereUniqueInput;
};

export type DelayTripStopAlertUpsertWithWhereUniqueWithoutTripStopInput = {
  create: DelayTripStopAlertCreateWithoutTripStopInput;
  update: DelayTripStopAlertUpdateWithoutTripStopInput;
  where: DelayTripStopAlertWhereUniqueInput;
};

export type DelayTripStopAlertUpsertWithoutAlertInput = {
  create: DelayTripStopAlertCreateWithoutAlertInput;
  update: DelayTripStopAlertUpdateWithoutAlertInput;
  where?: InputMaybe<DelayTripStopAlertWhereInput>;
};

export type DelayTripStopAlertWhereInput = {
  AND?: InputMaybe<Array<DelayTripStopAlertWhereInput>>;
  NOT?: InputMaybe<Array<DelayTripStopAlertWhereInput>>;
  OR?: InputMaybe<Array<DelayTripStopAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  delayInMinutes?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
  tripStop?: InputMaybe<TripStopRelationFilter>;
  tripStopId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DelayTripStopAlertWhereUniqueInput = {
  AND?: InputMaybe<Array<DelayTripStopAlertWhereInput>>;
  NOT?: InputMaybe<Array<DelayTripStopAlertWhereInput>>;
  OR?: InputMaybe<Array<DelayTripStopAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  delayInMinutes?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
  tripStop?: InputMaybe<TripStopRelationFilter>;
  tripStopId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Department = {
  __typename?: "Department";
  _count: DepartmentCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  branches?: Maybe<Array<BranchDepartment>>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  offers?: Maybe<Array<Offer>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type DepartmentCount = {
  __typename?: "DepartmentCount";
  branches: Scalars["Int"]["output"];
  offers: Scalars["Int"]["output"];
};

export type DepartmentCreateNestedOneWithoutBranchesInput = {
  connect?: InputMaybe<DepartmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DepartmentCreateOrConnectWithoutBranchesInput>;
  create?: InputMaybe<DepartmentCreateWithoutBranchesInput>;
};

export type DepartmentCreateNestedOneWithoutOffersInput = {
  connect?: InputMaybe<DepartmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DepartmentCreateOrConnectWithoutOffersInput>;
  create?: InputMaybe<DepartmentCreateWithoutOffersInput>;
};

export type DepartmentCreateOrConnectWithoutBranchesInput = {
  create: DepartmentCreateWithoutBranchesInput;
  where: DepartmentWhereUniqueInput;
};

export type DepartmentCreateOrConnectWithoutOffersInput = {
  create: DepartmentCreateWithoutOffersInput;
  where: DepartmentWhereUniqueInput;
};

export type DepartmentCreateWithoutBranchesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  offers?: InputMaybe<OfferCreateNestedManyWithoutDepartmentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DepartmentCreateWithoutOffersInput = {
  branches?: InputMaybe<BranchDepartmentCreateNestedManyWithoutDepartmentInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DepartmentRelationFilter = {
  is?: InputMaybe<DepartmentWhereInput>;
  isNot?: InputMaybe<DepartmentWhereInput>;
};

export type DepartmentResponsePayload = {
  __typename?: "DepartmentResponsePayload";
  contacts: Array<ContactResponsePayload>;
  /** The creation time */
  createdAt: Scalars["DateTime"]["output"];
  /** A UUID */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  /** The last update time */
  updatedAt: Scalars["DateTime"]["output"];
};

export type DepartmentUpdateOneRequiredWithoutBranchesNestedInput = {
  connect?: InputMaybe<DepartmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DepartmentCreateOrConnectWithoutBranchesInput>;
  create?: InputMaybe<DepartmentCreateWithoutBranchesInput>;
  update?: InputMaybe<DepartmentUpdateToOneWithWhereWithoutBranchesInput>;
  upsert?: InputMaybe<DepartmentUpsertWithoutBranchesInput>;
};

export type DepartmentUpdateOneWithoutOffersNestedInput = {
  connect?: InputMaybe<DepartmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DepartmentCreateOrConnectWithoutOffersInput>;
  create?: InputMaybe<DepartmentCreateWithoutOffersInput>;
  delete?: InputMaybe<DepartmentWhereInput>;
  disconnect?: InputMaybe<DepartmentWhereInput>;
  update?: InputMaybe<DepartmentUpdateToOneWithWhereWithoutOffersInput>;
  upsert?: InputMaybe<DepartmentUpsertWithoutOffersInput>;
};

export type DepartmentUpdateToOneWithWhereWithoutBranchesInput = {
  data: DepartmentUpdateWithoutBranchesInput;
  where?: InputMaybe<DepartmentWhereInput>;
};

export type DepartmentUpdateToOneWithWhereWithoutOffersInput = {
  data: DepartmentUpdateWithoutOffersInput;
  where?: InputMaybe<DepartmentWhereInput>;
};

export type DepartmentUpdateWithoutBranchesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  offers?: InputMaybe<OfferUpdateManyWithoutDepartmentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DepartmentUpdateWithoutOffersInput = {
  branches?: InputMaybe<BranchDepartmentUpdateManyWithoutDepartmentNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DepartmentUpsertWithoutBranchesInput = {
  create: DepartmentCreateWithoutBranchesInput;
  update: DepartmentUpdateWithoutBranchesInput;
  where?: InputMaybe<DepartmentWhereInput>;
};

export type DepartmentUpsertWithoutOffersInput = {
  create: DepartmentCreateWithoutOffersInput;
  update: DepartmentUpdateWithoutOffersInput;
  where?: InputMaybe<DepartmentWhereInput>;
};

export type DepartmentWhereInput = {
  AND?: InputMaybe<Array<DepartmentWhereInput>>;
  NOT?: InputMaybe<Array<DepartmentWhereInput>>;
  OR?: InputMaybe<Array<DepartmentWhereInput>>;
  branches?: InputMaybe<BranchDepartmentListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  offers?: InputMaybe<OfferListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DepartmentWhereUniqueInput = {
  AND?: InputMaybe<Array<DepartmentWhereInput>>;
  NOT?: InputMaybe<Array<DepartmentWhereInput>>;
  OR?: InputMaybe<Array<DepartmentWhereInput>>;
  branches?: InputMaybe<BranchDepartmentListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  offers?: InputMaybe<OfferListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Deposit = {
  __typename?: "Deposit";
  _count: DepositCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  depositActiveFromDays: Scalars["Int"]["output"];
  depositActiveToDays: Scalars["Int"]["output"];
  depositPaymentDate?: Maybe<Scalars["DateTime"]["output"]>;
  depositPercentage: Scalars["Float"]["output"];
  depositRefundCutoffDays: Scalars["Int"]["output"];
  depositRefundMaxPenalty?: Maybe<Scalars["Int"]["output"]>;
  depositType: DepositType;
  id: Scalars["String"]["output"];
  paymentLineItems?: Maybe<Array<PaymentLineItem>>;
  servicePricing?: Maybe<Array<ServicePricing>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type DepositCollectionType = {
  __typename?: "DepositCollectionType";
  date?: Maybe<Scalars["DateTime"]["output"]>;
  type: DepositType;
};

export type DepositCount = {
  __typename?: "DepositCount";
  paymentLineItems: Scalars["Int"]["output"];
  servicePricing: Scalars["Int"]["output"];
};

export type DepositCreateNestedOneWithoutPaymentLineItemsInput = {
  connect?: InputMaybe<DepositWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DepositCreateOrConnectWithoutPaymentLineItemsInput>;
  create?: InputMaybe<DepositCreateWithoutPaymentLineItemsInput>;
};

export type DepositCreateNestedOneWithoutServicePricingInput = {
  connect?: InputMaybe<DepositWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DepositCreateOrConnectWithoutServicePricingInput>;
  create?: InputMaybe<DepositCreateWithoutServicePricingInput>;
};

export type DepositCreateOrConnectWithoutPaymentLineItemsInput = {
  create: DepositCreateWithoutPaymentLineItemsInput;
  where: DepositWhereUniqueInput;
};

export type DepositCreateOrConnectWithoutServicePricingInput = {
  create: DepositCreateWithoutServicePricingInput;
  where: DepositWhereUniqueInput;
};

export type DepositCreateWithoutPaymentLineItemsInput = {
  depositActiveFromDays: Scalars["Int"]["input"];
  depositActiveToDays: Scalars["Int"]["input"];
  depositPaymentDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  depositPercentage: Scalars["Float"]["input"];
  depositRefundCutoffDays: Scalars["Int"]["input"];
  depositRefundMaxPenalty?: InputMaybe<Scalars["Int"]["input"]>;
  depositType?: InputMaybe<DepositType>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  servicePricing?: InputMaybe<ServicePricingCreateNestedManyWithoutDepositInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DepositCreateWithoutServicePricingInput = {
  depositActiveFromDays: Scalars["Int"]["input"];
  depositActiveToDays: Scalars["Int"]["input"];
  depositPaymentDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  depositPercentage: Scalars["Float"]["input"];
  depositRefundCutoffDays: Scalars["Int"]["input"];
  depositRefundMaxPenalty?: InputMaybe<Scalars["Int"]["input"]>;
  depositType?: InputMaybe<DepositType>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemCreateNestedManyWithoutDepositInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DepositRelationFilter = {
  is?: InputMaybe<DepositWhereInput>;
  isNot?: InputMaybe<DepositWhereInput>;
};

export enum DepositType {
  FIRST_PAYMENT = "FIRST_PAYMENT",
  IMMEDIATE = "IMMEDIATE",
  SPECIFIC_DATE = "SPECIFIC_DATE",
}

export type DepositUpdateOneWithoutPaymentLineItemsNestedInput = {
  connect?: InputMaybe<DepositWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DepositCreateOrConnectWithoutPaymentLineItemsInput>;
  create?: InputMaybe<DepositCreateWithoutPaymentLineItemsInput>;
  delete?: InputMaybe<DepositWhereInput>;
  disconnect?: InputMaybe<DepositWhereInput>;
  update?: InputMaybe<DepositUpdateToOneWithWhereWithoutPaymentLineItemsInput>;
  upsert?: InputMaybe<DepositUpsertWithoutPaymentLineItemsInput>;
};

export type DepositUpdateOneWithoutServicePricingNestedInput = {
  connect?: InputMaybe<DepositWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DepositCreateOrConnectWithoutServicePricingInput>;
  create?: InputMaybe<DepositCreateWithoutServicePricingInput>;
  delete?: InputMaybe<DepositWhereInput>;
  disconnect?: InputMaybe<DepositWhereInput>;
  update?: InputMaybe<DepositUpdateToOneWithWhereWithoutServicePricingInput>;
  upsert?: InputMaybe<DepositUpsertWithoutServicePricingInput>;
};

export type DepositUpdateToOneWithWhereWithoutPaymentLineItemsInput = {
  data: DepositUpdateWithoutPaymentLineItemsInput;
  where?: InputMaybe<DepositWhereInput>;
};

export type DepositUpdateToOneWithWhereWithoutServicePricingInput = {
  data: DepositUpdateWithoutServicePricingInput;
  where?: InputMaybe<DepositWhereInput>;
};

export type DepositUpdateWithoutPaymentLineItemsInput = {
  depositActiveFromDays?: InputMaybe<Scalars["Int"]["input"]>;
  depositActiveToDays?: InputMaybe<Scalars["Int"]["input"]>;
  depositPaymentDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  depositPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  depositRefundCutoffDays?: InputMaybe<Scalars["Int"]["input"]>;
  depositRefundMaxPenalty?: InputMaybe<Scalars["Int"]["input"]>;
  depositType?: InputMaybe<DepositType>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  servicePricing?: InputMaybe<ServicePricingUpdateManyWithoutDepositNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DepositUpdateWithoutServicePricingInput = {
  depositActiveFromDays?: InputMaybe<Scalars["Int"]["input"]>;
  depositActiveToDays?: InputMaybe<Scalars["Int"]["input"]>;
  depositPaymentDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  depositPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  depositRefundCutoffDays?: InputMaybe<Scalars["Int"]["input"]>;
  depositRefundMaxPenalty?: InputMaybe<Scalars["Int"]["input"]>;
  depositType?: InputMaybe<DepositType>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemUpdateManyWithoutDepositNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DepositUpsertWithoutPaymentLineItemsInput = {
  create: DepositCreateWithoutPaymentLineItemsInput;
  update: DepositUpdateWithoutPaymentLineItemsInput;
  where?: InputMaybe<DepositWhereInput>;
};

export type DepositUpsertWithoutServicePricingInput = {
  create: DepositCreateWithoutServicePricingInput;
  update: DepositUpdateWithoutServicePricingInput;
  where?: InputMaybe<DepositWhereInput>;
};

export type DepositWhereInput = {
  AND?: InputMaybe<Array<DepositWhereInput>>;
  NOT?: InputMaybe<Array<DepositWhereInput>>;
  OR?: InputMaybe<Array<DepositWhereInput>>;
  depositActiveFromDays?: InputMaybe<IntFilter>;
  depositActiveToDays?: InputMaybe<IntFilter>;
  depositPaymentDate?: InputMaybe<DateTimeFilter>;
  depositPercentage?: InputMaybe<FloatFilter>;
  depositRefundCutoffDays?: InputMaybe<IntFilter>;
  depositRefundMaxPenalty?: InputMaybe<IntFilter>;
  depositType?: InputMaybe<EnumDepositTypeFilter>;
  id?: InputMaybe<StringFilter>;
  paymentLineItems?: InputMaybe<PaymentLineItemListRelationFilter>;
  servicePricing?: InputMaybe<ServicePricingListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DepositWhereUniqueInput = {
  AND?: InputMaybe<Array<DepositWhereInput>>;
  NOT?: InputMaybe<Array<DepositWhereInput>>;
  OR?: InputMaybe<Array<DepositWhereInput>>;
  depositActiveFromDays?: InputMaybe<IntFilter>;
  depositActiveToDays?: InputMaybe<IntFilter>;
  depositPaymentDate?: InputMaybe<DateTimeFilter>;
  depositPercentage?: InputMaybe<FloatFilter>;
  depositRefundCutoffDays?: InputMaybe<IntFilter>;
  depositRefundMaxPenalty?: InputMaybe<IntFilter>;
  depositType?: InputMaybe<EnumDepositTypeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemListRelationFilter>;
  servicePricing?: InputMaybe<ServicePricingListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DeviceDetail = {
  __typename?: "DeviceDetail";
  createdAt: Scalars["DateTime"]["output"];
  deviceModel: Scalars["String"]["output"];
  deviceVersion: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lang?: Maybe<Language>;
  otp: Otp;
  otpId: Scalars["String"]["output"];
  platform: Platform;
  updatedAt: Scalars["DateTime"]["output"];
};

export type DeviceDetailCreateNestedOneWithoutOtpInput = {
  connect?: InputMaybe<DeviceDetailWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceDetailCreateOrConnectWithoutOtpInput>;
  create?: InputMaybe<DeviceDetailCreateWithoutOtpInput>;
};

export type DeviceDetailCreateOrConnectWithoutOtpInput = {
  create: DeviceDetailCreateWithoutOtpInput;
  where: DeviceDetailWhereUniqueInput;
};

export type DeviceDetailCreateWithoutOtpInput = {
  deviceModel: Scalars["String"]["input"];
  deviceVersion: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lang?: InputMaybe<Language>;
  platform: Platform;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DeviceDetailRelationFilter = {
  is?: InputMaybe<DeviceDetailWhereInput>;
  isNot?: InputMaybe<DeviceDetailWhereInput>;
};

export type DeviceDetailUpdateOneWithoutOtpNestedInput = {
  connect?: InputMaybe<DeviceDetailWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceDetailCreateOrConnectWithoutOtpInput>;
  create?: InputMaybe<DeviceDetailCreateWithoutOtpInput>;
  delete?: InputMaybe<DeviceDetailWhereInput>;
  disconnect?: InputMaybe<DeviceDetailWhereInput>;
  update?: InputMaybe<DeviceDetailUpdateToOneWithWhereWithoutOtpInput>;
  upsert?: InputMaybe<DeviceDetailUpsertWithoutOtpInput>;
};

export type DeviceDetailUpdateToOneWithWhereWithoutOtpInput = {
  data: DeviceDetailUpdateWithoutOtpInput;
  where?: InputMaybe<DeviceDetailWhereInput>;
};

export type DeviceDetailUpdateWithoutOtpInput = {
  deviceModel?: InputMaybe<Scalars["String"]["input"]>;
  deviceVersion?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lang?: InputMaybe<Language>;
  platform?: InputMaybe<Platform>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DeviceDetailUpsertWithoutOtpInput = {
  create: DeviceDetailCreateWithoutOtpInput;
  update: DeviceDetailUpdateWithoutOtpInput;
  where?: InputMaybe<DeviceDetailWhereInput>;
};

export type DeviceDetailWhereInput = {
  AND?: InputMaybe<Array<DeviceDetailWhereInput>>;
  NOT?: InputMaybe<Array<DeviceDetailWhereInput>>;
  OR?: InputMaybe<Array<DeviceDetailWhereInput>>;
  deviceModel?: InputMaybe<StringFilter>;
  deviceVersion?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lang?: InputMaybe<EnumLanguageFilter>;
  otp?: InputMaybe<OtpRelationFilter>;
  otpId?: InputMaybe<StringFilter>;
  platform?: InputMaybe<EnumPlatformFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DeviceDetailWhereUniqueInput = {
  AND?: InputMaybe<Array<DeviceDetailWhereInput>>;
  NOT?: InputMaybe<Array<DeviceDetailWhereInput>>;
  OR?: InputMaybe<Array<DeviceDetailWhereInput>>;
  deviceModel?: InputMaybe<StringFilter>;
  deviceVersion?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lang?: InputMaybe<EnumLanguageFilter>;
  otp?: InputMaybe<OtpRelationFilter>;
  otpId?: InputMaybe<Scalars["String"]["input"]>;
  platform?: InputMaybe<EnumPlatformFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DeviceDetailsInput = {
  deviceModel: Scalars["String"]["input"];
  deviceVersion: Scalars["String"]["input"];
  platform: Platform;
};

export type DivisionLevel = {
  __typename?: "DivisionLevel";
  _count: DivisionLevelCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  countries?: Maybe<Array<CountryDivisionLevel>>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type DivisionLevelCount = {
  __typename?: "DivisionLevelCount";
  countries: Scalars["Int"]["output"];
};

export type DivisionLevelCreateNestedOneWithoutCountriesInput = {
  connect?: InputMaybe<DivisionLevelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DivisionLevelCreateOrConnectWithoutCountriesInput>;
  create?: InputMaybe<DivisionLevelCreateWithoutCountriesInput>;
};

export type DivisionLevelCreateOrConnectWithoutCountriesInput = {
  create: DivisionLevelCreateWithoutCountriesInput;
  where: DivisionLevelWhereUniqueInput;
};

export type DivisionLevelCreateWithoutCountriesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DivisionLevelRelationFilter = {
  is?: InputMaybe<DivisionLevelWhereInput>;
  isNot?: InputMaybe<DivisionLevelWhereInput>;
};

export enum DivisionLevelType {
  ADMINISTRATIVE_AREA = "ADMINISTRATIVE_AREA",
  LOCALITY = "LOCALITY",
  SUB_LOCALITY = "SUB_LOCALITY",
}

export type DivisionLevelUpdateOneRequiredWithoutCountriesNestedInput = {
  connect?: InputMaybe<DivisionLevelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DivisionLevelCreateOrConnectWithoutCountriesInput>;
  create?: InputMaybe<DivisionLevelCreateWithoutCountriesInput>;
  update?: InputMaybe<DivisionLevelUpdateToOneWithWhereWithoutCountriesInput>;
  upsert?: InputMaybe<DivisionLevelUpsertWithoutCountriesInput>;
};

export type DivisionLevelUpdateToOneWithWhereWithoutCountriesInput = {
  data: DivisionLevelUpdateWithoutCountriesInput;
  where?: InputMaybe<DivisionLevelWhereInput>;
};

export type DivisionLevelUpdateWithoutCountriesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DivisionLevelUpsertWithoutCountriesInput = {
  create: DivisionLevelCreateWithoutCountriesInput;
  update: DivisionLevelUpdateWithoutCountriesInput;
  where?: InputMaybe<DivisionLevelWhereInput>;
};

export type DivisionLevelWhereInput = {
  AND?: InputMaybe<Array<DivisionLevelWhereInput>>;
  NOT?: InputMaybe<Array<DivisionLevelWhereInput>>;
  OR?: InputMaybe<Array<DivisionLevelWhereInput>>;
  countries?: InputMaybe<CountryDivisionLevelListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DivisionLevelWhereUniqueInput = {
  AND?: InputMaybe<Array<DivisionLevelWhereInput>>;
  NOT?: InputMaybe<Array<DivisionLevelWhereInput>>;
  OR?: InputMaybe<Array<DivisionLevelWhereInput>>;
  countries?: InputMaybe<CountryDivisionLevelListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Driver = {
  __typename?: "Driver";
  DriverHoursWorked?: Maybe<Array<DriverHoursWorked>>;
  DriverStatusHistory?: Maybe<Array<DriverStatusHistory>>;
  DriverViolationCompliance?: Maybe<Array<DriverViolationCompliance>>;
  _count: DriverCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  bank?: Maybe<Bank>;
  bankAccountName?: Maybe<Scalars["String"]["output"]>;
  bankAccountNumber?: Maybe<Scalars["String"]["output"]>;
  bankId?: Maybe<Scalars["String"]["output"]>;
  contractOriginalStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  contractStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  contractType?: Maybe<ContractType>;
  contractTypeId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTime"]["output"]>;
  driverLicenseExpiryDate?: Maybe<Scalars["DateTime"]["output"]>;
  driverLicenseNumber?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  nationalId?: Maybe<Scalars["String"]["output"]>;
  nationalIdExpiryDate?: Maybe<Scalars["DateTime"]["output"]>;
  newDriverTripLogs?: Maybe<Array<TripLog>>;
  oldDriverTripLogs?: Maybe<Array<TripLog>>;
  routeDriverVacantAlerts?: Maybe<Array<RouteDriverVacantAlert>>;
  routes?: Maybe<Array<RouteDriver>>;
  status: DriverStatus;
  submittedAt?: Maybe<Scalars["DateTime"]["output"]>;
  terminationDate?: Maybe<Scalars["DateTime"]["output"]>;
  trips?: Maybe<Array<Trip>>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
  vehicleSafetyEvents?: Maybe<Array<VehicleSafetyEvent>>;
  vehicles?: Maybe<Array<VehicleDriver>>;
  zone?: Maybe<EmployeeZone>;
  zoneId?: Maybe<Scalars["String"]["output"]>;
};

export type DriverCount = {
  __typename?: "DriverCount";
  DriverHoursWorked: Scalars["Int"]["output"];
  DriverStatusHistory: Scalars["Int"]["output"];
  DriverViolationCompliance: Scalars["Int"]["output"];
  newDriverTripLogs: Scalars["Int"]["output"];
  oldDriverTripLogs: Scalars["Int"]["output"];
  routeDriverVacantAlerts: Scalars["Int"]["output"];
  routes: Scalars["Int"]["output"];
  trips: Scalars["Int"]["output"];
  vehicleSafetyEvents: Scalars["Int"]["output"];
  vehicles: Scalars["Int"]["output"];
};

export type DriverCreateInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateManyBankInput = {
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractTypeId?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
  zoneId?: InputMaybe<Scalars["String"]["input"]>;
};

export type DriverCreateManyBankInputEnvelope = {
  data: Array<DriverCreateManyBankInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DriverCreateManyContractTypeInput = {
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankId?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
  zoneId?: InputMaybe<Scalars["String"]["input"]>;
};

export type DriverCreateManyContractTypeInputEnvelope = {
  data: Array<DriverCreateManyContractTypeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DriverCreateManyZoneInput = {
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bankId?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractTypeId?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type DriverCreateManyZoneInputEnvelope = {
  data: Array<DriverCreateManyZoneInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DriverCreateNestedManyWithoutBankInput = {
  connect?: InputMaybe<Array<DriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DriverCreateOrConnectWithoutBankInput>>;
  create?: InputMaybe<Array<DriverCreateWithoutBankInput>>;
  createMany?: InputMaybe<DriverCreateManyBankInputEnvelope>;
};

export type DriverCreateNestedManyWithoutContractTypeInput = {
  connect?: InputMaybe<Array<DriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DriverCreateOrConnectWithoutContractTypeInput>
  >;
  create?: InputMaybe<Array<DriverCreateWithoutContractTypeInput>>;
  createMany?: InputMaybe<DriverCreateManyContractTypeInputEnvelope>;
};

export type DriverCreateNestedManyWithoutZoneInput = {
  connect?: InputMaybe<Array<DriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DriverCreateOrConnectWithoutZoneInput>>;
  create?: InputMaybe<Array<DriverCreateWithoutZoneInput>>;
  createMany?: InputMaybe<DriverCreateManyZoneInputEnvelope>;
};

export type DriverCreateNestedOneWithoutDriverHoursWorkedInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutDriverHoursWorkedInput>;
  create?: InputMaybe<DriverCreateWithoutDriverHoursWorkedInput>;
};

export type DriverCreateNestedOneWithoutDriverStatusHistoryInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutDriverStatusHistoryInput>;
  create?: InputMaybe<DriverCreateWithoutDriverStatusHistoryInput>;
};

export type DriverCreateNestedOneWithoutDriverViolationComplianceInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutDriverViolationComplianceInput>;
  create?: InputMaybe<DriverCreateWithoutDriverViolationComplianceInput>;
};

export type DriverCreateNestedOneWithoutNewDriverTripLogsInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutNewDriverTripLogsInput>;
  create?: InputMaybe<DriverCreateWithoutNewDriverTripLogsInput>;
};

export type DriverCreateNestedOneWithoutOldDriverTripLogsInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutOldDriverTripLogsInput>;
  create?: InputMaybe<DriverCreateWithoutOldDriverTripLogsInput>;
};

export type DriverCreateNestedOneWithoutRouteDriverVacantAlertsInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutRouteDriverVacantAlertsInput>;
  create?: InputMaybe<DriverCreateWithoutRouteDriverVacantAlertsInput>;
};

export type DriverCreateNestedOneWithoutRoutesInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<DriverCreateWithoutRoutesInput>;
};

export type DriverCreateNestedOneWithoutTripsInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutTripsInput>;
  create?: InputMaybe<DriverCreateWithoutTripsInput>;
};

export type DriverCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<DriverCreateWithoutUserInput>;
};

export type DriverCreateNestedOneWithoutVehicleSafetyEventsInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutVehicleSafetyEventsInput>;
  create?: InputMaybe<DriverCreateWithoutVehicleSafetyEventsInput>;
};

export type DriverCreateNestedOneWithoutVehiclesInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<DriverCreateWithoutVehiclesInput>;
};

export type DriverCreateOrConnectWithoutBankInput = {
  create: DriverCreateWithoutBankInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutContractTypeInput = {
  create: DriverCreateWithoutContractTypeInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutDriverHoursWorkedInput = {
  create: DriverCreateWithoutDriverHoursWorkedInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutDriverStatusHistoryInput = {
  create: DriverCreateWithoutDriverStatusHistoryInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutDriverViolationComplianceInput = {
  create: DriverCreateWithoutDriverViolationComplianceInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutNewDriverTripLogsInput = {
  create: DriverCreateWithoutNewDriverTripLogsInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutOldDriverTripLogsInput = {
  create: DriverCreateWithoutOldDriverTripLogsInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutRouteDriverVacantAlertsInput = {
  create: DriverCreateWithoutRouteDriverVacantAlertsInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutRoutesInput = {
  create: DriverCreateWithoutRoutesInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutTripsInput = {
  create: DriverCreateWithoutTripsInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutUserInput = {
  create: DriverCreateWithoutUserInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutVehicleSafetyEventsInput = {
  create: DriverCreateWithoutVehicleSafetyEventsInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutVehiclesInput = {
  create: DriverCreateWithoutVehiclesInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutZoneInput = {
  create: DriverCreateWithoutZoneInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateWithoutBankInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutContractTypeInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutDriverHoursWorkedInput = {
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutDriverStatusHistoryInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutDriverViolationComplianceInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutNewDriverTripLogsInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutOldDriverTripLogsInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutRouteDriverVacantAlertsInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutRoutesInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutTripsInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutUserInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutVehicleSafetyEventsInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutVehiclesInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  zone?: InputMaybe<EmployeeZoneCreateNestedOneWithoutDriverInput>;
};

export type DriverCreateWithoutZoneInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedCreateNestedManyWithoutDriverInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryCreateNestedManyWithoutDriverInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceCreateNestedManyWithoutDriverInput>;
  bank?: InputMaybe<BankCreateNestedOneWithoutDriverInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeCreateNestedOneWithoutDriverInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewDriverInput>;
  oldDriverTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldDriverInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutDriverInput>;
  routes?: InputMaybe<RouteDriverCreateNestedManyWithoutDriverInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutDriverInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutDriverInput;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutDriverInput>;
  vehicles?: InputMaybe<VehicleDriverCreateNestedManyWithoutDriverInput>;
};

export type DriverForBaBd = {
  __typename?: "DriverForBaBd";
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  middleNameAr?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
};

export type DriverForBusAttendant = {
  __typename?: "DriverForBusAttendant";
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type DriverForDriver = {
  __typename?: "DriverForDriver";
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type DriverForSponsor = {
  __typename?: "DriverForSponsor";
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type DriverHoursWorked = {
  __typename?: "DriverHoursWorked";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  driver: Driver;
  driverId: Scalars["String"]["output"];
  fromDate: Scalars["DateTime"]["output"];
  hours: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  toDate: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type DriverHoursWorkedCreateInput = {
  driver: DriverCreateNestedOneWithoutDriverHoursWorkedInput;
  fromDate: Scalars["DateTime"]["input"];
  hours: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverHoursWorkedCreateManyDriverInput = {
  fromDate: Scalars["DateTime"]["input"];
  hours: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverHoursWorkedCreateManyDriverInputEnvelope = {
  data: Array<DriverHoursWorkedCreateManyDriverInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DriverHoursWorkedCreateNestedManyWithoutDriverInput = {
  connect?: InputMaybe<Array<DriverHoursWorkedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DriverHoursWorkedCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<DriverHoursWorkedCreateWithoutDriverInput>>;
  createMany?: InputMaybe<DriverHoursWorkedCreateManyDriverInputEnvelope>;
};

export type DriverHoursWorkedCreateOrConnectWithoutDriverInput = {
  create: DriverHoursWorkedCreateWithoutDriverInput;
  where: DriverHoursWorkedWhereUniqueInput;
};

export type DriverHoursWorkedCreateWithoutDriverInput = {
  fromDate: Scalars["DateTime"]["input"];
  hours: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverHoursWorkedListRelationFilter = {
  every?: InputMaybe<DriverHoursWorkedWhereInput>;
  none?: InputMaybe<DriverHoursWorkedWhereInput>;
  some?: InputMaybe<DriverHoursWorkedWhereInput>;
};

export type DriverHoursWorkedScalarWhereInput = {
  AND?: InputMaybe<Array<DriverHoursWorkedScalarWhereInput>>;
  NOT?: InputMaybe<Array<DriverHoursWorkedScalarWhereInput>>;
  OR?: InputMaybe<Array<DriverHoursWorkedScalarWhereInput>>;
  driverId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  hours?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DriverHoursWorkedUpdateInput = {
  driver?: InputMaybe<DriverUpdateOneRequiredWithoutDriverHoursWorkedNestedInput>;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  hours?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverHoursWorkedUpdateManyMutationInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  hours?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverHoursWorkedUpdateManyWithWhereWithoutDriverInput = {
  data: DriverHoursWorkedUpdateManyMutationInput;
  where: DriverHoursWorkedScalarWhereInput;
};

export type DriverHoursWorkedUpdateManyWithoutDriverNestedInput = {
  connect?: InputMaybe<Array<DriverHoursWorkedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DriverHoursWorkedCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<DriverHoursWorkedCreateWithoutDriverInput>>;
  createMany?: InputMaybe<DriverHoursWorkedCreateManyDriverInputEnvelope>;
  delete?: InputMaybe<Array<DriverHoursWorkedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DriverHoursWorkedScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DriverHoursWorkedWhereUniqueInput>>;
  set?: InputMaybe<Array<DriverHoursWorkedWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DriverHoursWorkedUpdateWithWhereUniqueWithoutDriverInput>
  >;
  updateMany?: InputMaybe<
    Array<DriverHoursWorkedUpdateManyWithWhereWithoutDriverInput>
  >;
  upsert?: InputMaybe<
    Array<DriverHoursWorkedUpsertWithWhereUniqueWithoutDriverInput>
  >;
};

export type DriverHoursWorkedUpdateWithWhereUniqueWithoutDriverInput = {
  data: DriverHoursWorkedUpdateWithoutDriverInput;
  where: DriverHoursWorkedWhereUniqueInput;
};

export type DriverHoursWorkedUpdateWithoutDriverInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  hours?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverHoursWorkedUpsertWithWhereUniqueWithoutDriverInput = {
  create: DriverHoursWorkedCreateWithoutDriverInput;
  update: DriverHoursWorkedUpdateWithoutDriverInput;
  where: DriverHoursWorkedWhereUniqueInput;
};

export type DriverHoursWorkedWhereInput = {
  AND?: InputMaybe<Array<DriverHoursWorkedWhereInput>>;
  NOT?: InputMaybe<Array<DriverHoursWorkedWhereInput>>;
  OR?: InputMaybe<Array<DriverHoursWorkedWhereInput>>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  hours?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DriverHoursWorkedWhereUniqueInput = {
  AND?: InputMaybe<Array<DriverHoursWorkedWhereInput>>;
  NOT?: InputMaybe<Array<DriverHoursWorkedWhereInput>>;
  OR?: InputMaybe<Array<DriverHoursWorkedWhereInput>>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  hours?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DriverInfoExtraInfo = {
  __typename?: "DriverInfoExtraInfo";
  driverName?: Maybe<Scalars["String"]["output"]>;
  driverNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type DriverListRelationFilter = {
  every?: InputMaybe<DriverWhereInput>;
  none?: InputMaybe<DriverWhereInput>;
  some?: InputMaybe<DriverWhereInput>;
};

export type DriverRelationFilter = {
  is?: InputMaybe<DriverWhereInput>;
  isNot?: InputMaybe<DriverWhereInput>;
};

export type DriverScalarWhereInput = {
  AND?: InputMaybe<Array<DriverScalarWhereInput>>;
  NOT?: InputMaybe<Array<DriverScalarWhereInput>>;
  OR?: InputMaybe<Array<DriverScalarWhereInput>>;
  bankAccountName?: InputMaybe<StringFilter>;
  bankAccountNumber?: InputMaybe<StringFilter>;
  bankId?: InputMaybe<StringFilter>;
  contractOriginalStartDate?: InputMaybe<DateTimeFilter>;
  contractStartDate?: InputMaybe<DateTimeFilter>;
  contractTypeId?: InputMaybe<StringFilter>;
  dateOfBirth?: InputMaybe<DateTimeFilter>;
  driverLicenseExpiryDate?: InputMaybe<DateTimeFilter>;
  driverLicenseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  nationalId?: InputMaybe<StringFilter>;
  nationalIdExpiryDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumDriverStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  terminationDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  zoneId?: InputMaybe<StringFilter>;
};

export enum DriverStatus {
  ACTIVE = "ACTIVE",
  ASSIGNED = "ASSIGNED",
  EMERGENCY = "EMERGENCY",
  INACTIVE = "INACTIVE",
  ONBOARDING = "ONBOARDING",
  POOL = "POOL",
  RESIGNED = "RESIGNED",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
}

export type DriverStatusHistory = {
  __typename?: "DriverStatusHistory";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  driver: Driver;
  driverId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  status: DriverStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type DriverStatusHistoryCreateInput = {
  driver: DriverCreateNestedOneWithoutDriverStatusHistoryInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status: DriverStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverStatusHistoryCreateManyDriverInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status: DriverStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverStatusHistoryCreateManyDriverInputEnvelope = {
  data: Array<DriverStatusHistoryCreateManyDriverInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DriverStatusHistoryCreateNestedManyWithoutDriverInput = {
  connect?: InputMaybe<Array<DriverStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DriverStatusHistoryCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<DriverStatusHistoryCreateWithoutDriverInput>>;
  createMany?: InputMaybe<DriverStatusHistoryCreateManyDriverInputEnvelope>;
};

export type DriverStatusHistoryCreateOrConnectWithoutDriverInput = {
  create: DriverStatusHistoryCreateWithoutDriverInput;
  where: DriverStatusHistoryWhereUniqueInput;
};

export type DriverStatusHistoryCreateWithoutDriverInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status: DriverStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverStatusHistoryListRelationFilter = {
  every?: InputMaybe<DriverStatusHistoryWhereInput>;
  none?: InputMaybe<DriverStatusHistoryWhereInput>;
  some?: InputMaybe<DriverStatusHistoryWhereInput>;
};

export type DriverStatusHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<DriverStatusHistoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<DriverStatusHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<DriverStatusHistoryScalarWhereInput>>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumDriverStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DriverStatusHistoryUpdateInput = {
  driver?: InputMaybe<DriverUpdateOneRequiredWithoutDriverStatusHistoryNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<DriverStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverStatusHistoryUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<DriverStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverStatusHistoryUpdateManyWithWhereWithoutDriverInput = {
  data: DriverStatusHistoryUpdateManyMutationInput;
  where: DriverStatusHistoryScalarWhereInput;
};

export type DriverStatusHistoryUpdateManyWithoutDriverNestedInput = {
  connect?: InputMaybe<Array<DriverStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DriverStatusHistoryCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<DriverStatusHistoryCreateWithoutDriverInput>>;
  createMany?: InputMaybe<DriverStatusHistoryCreateManyDriverInputEnvelope>;
  delete?: InputMaybe<Array<DriverStatusHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DriverStatusHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DriverStatusHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<DriverStatusHistoryWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DriverStatusHistoryUpdateWithWhereUniqueWithoutDriverInput>
  >;
  updateMany?: InputMaybe<
    Array<DriverStatusHistoryUpdateManyWithWhereWithoutDriverInput>
  >;
  upsert?: InputMaybe<
    Array<DriverStatusHistoryUpsertWithWhereUniqueWithoutDriverInput>
  >;
};

export type DriverStatusHistoryUpdateWithWhereUniqueWithoutDriverInput = {
  data: DriverStatusHistoryUpdateWithoutDriverInput;
  where: DriverStatusHistoryWhereUniqueInput;
};

export type DriverStatusHistoryUpdateWithoutDriverInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<DriverStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverStatusHistoryUpsertWithWhereUniqueWithoutDriverInput = {
  create: DriverStatusHistoryCreateWithoutDriverInput;
  update: DriverStatusHistoryUpdateWithoutDriverInput;
  where: DriverStatusHistoryWhereUniqueInput;
};

export type DriverStatusHistoryWhereInput = {
  AND?: InputMaybe<Array<DriverStatusHistoryWhereInput>>;
  NOT?: InputMaybe<Array<DriverStatusHistoryWhereInput>>;
  OR?: InputMaybe<Array<DriverStatusHistoryWhereInput>>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumDriverStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DriverStatusHistoryWhereUniqueInput = {
  AND?: InputMaybe<Array<DriverStatusHistoryWhereInput>>;
  NOT?: InputMaybe<Array<DriverStatusHistoryWhereInput>>;
  OR?: InputMaybe<Array<DriverStatusHistoryWhereInput>>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumDriverStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DriverUpdateInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateManyMutationInput = {
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverUpdateManyWithWhereWithoutBankInput = {
  data: DriverUpdateManyMutationInput;
  where: DriverScalarWhereInput;
};

export type DriverUpdateManyWithWhereWithoutContractTypeInput = {
  data: DriverUpdateManyMutationInput;
  where: DriverScalarWhereInput;
};

export type DriverUpdateManyWithWhereWithoutZoneInput = {
  data: DriverUpdateManyMutationInput;
  where: DriverScalarWhereInput;
};

export type DriverUpdateManyWithoutBankNestedInput = {
  connect?: InputMaybe<Array<DriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DriverCreateOrConnectWithoutBankInput>>;
  create?: InputMaybe<Array<DriverCreateWithoutBankInput>>;
  createMany?: InputMaybe<DriverCreateManyBankInputEnvelope>;
  delete?: InputMaybe<Array<DriverWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DriverScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DriverWhereUniqueInput>>;
  set?: InputMaybe<Array<DriverWhereUniqueInput>>;
  update?: InputMaybe<Array<DriverUpdateWithWhereUniqueWithoutBankInput>>;
  updateMany?: InputMaybe<Array<DriverUpdateManyWithWhereWithoutBankInput>>;
  upsert?: InputMaybe<Array<DriverUpsertWithWhereUniqueWithoutBankInput>>;
};

export type DriverUpdateManyWithoutContractTypeNestedInput = {
  connect?: InputMaybe<Array<DriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DriverCreateOrConnectWithoutContractTypeInput>
  >;
  create?: InputMaybe<Array<DriverCreateWithoutContractTypeInput>>;
  createMany?: InputMaybe<DriverCreateManyContractTypeInputEnvelope>;
  delete?: InputMaybe<Array<DriverWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DriverScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DriverWhereUniqueInput>>;
  set?: InputMaybe<Array<DriverWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DriverUpdateWithWhereUniqueWithoutContractTypeInput>
  >;
  updateMany?: InputMaybe<
    Array<DriverUpdateManyWithWhereWithoutContractTypeInput>
  >;
  upsert?: InputMaybe<
    Array<DriverUpsertWithWhereUniqueWithoutContractTypeInput>
  >;
};

export type DriverUpdateManyWithoutZoneNestedInput = {
  connect?: InputMaybe<Array<DriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DriverCreateOrConnectWithoutZoneInput>>;
  create?: InputMaybe<Array<DriverCreateWithoutZoneInput>>;
  createMany?: InputMaybe<DriverCreateManyZoneInputEnvelope>;
  delete?: InputMaybe<Array<DriverWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DriverScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DriverWhereUniqueInput>>;
  set?: InputMaybe<Array<DriverWhereUniqueInput>>;
  update?: InputMaybe<Array<DriverUpdateWithWhereUniqueWithoutZoneInput>>;
  updateMany?: InputMaybe<Array<DriverUpdateManyWithWhereWithoutZoneInput>>;
  upsert?: InputMaybe<Array<DriverUpsertWithWhereUniqueWithoutZoneInput>>;
};

export type DriverUpdateOneRequiredWithoutDriverHoursWorkedNestedInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutDriverHoursWorkedInput>;
  create?: InputMaybe<DriverCreateWithoutDriverHoursWorkedInput>;
  update?: InputMaybe<DriverUpdateToOneWithWhereWithoutDriverHoursWorkedInput>;
  upsert?: InputMaybe<DriverUpsertWithoutDriverHoursWorkedInput>;
};

export type DriverUpdateOneRequiredWithoutDriverStatusHistoryNestedInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutDriverStatusHistoryInput>;
  create?: InputMaybe<DriverCreateWithoutDriverStatusHistoryInput>;
  update?: InputMaybe<DriverUpdateToOneWithWhereWithoutDriverStatusHistoryInput>;
  upsert?: InputMaybe<DriverUpsertWithoutDriverStatusHistoryInput>;
};

export type DriverUpdateOneRequiredWithoutDriverViolationComplianceNestedInput =
  {
    connect?: InputMaybe<DriverWhereUniqueInput>;
    connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutDriverViolationComplianceInput>;
    create?: InputMaybe<DriverCreateWithoutDriverViolationComplianceInput>;
    update?: InputMaybe<DriverUpdateToOneWithWhereWithoutDriverViolationComplianceInput>;
    upsert?: InputMaybe<DriverUpsertWithoutDriverViolationComplianceInput>;
  };

export type DriverUpdateOneRequiredWithoutRoutesNestedInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<DriverCreateWithoutRoutesInput>;
  update?: InputMaybe<DriverUpdateToOneWithWhereWithoutRoutesInput>;
  upsert?: InputMaybe<DriverUpsertWithoutRoutesInput>;
};

export type DriverUpdateOneRequiredWithoutVehiclesNestedInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<DriverCreateWithoutVehiclesInput>;
  update?: InputMaybe<DriverUpdateToOneWithWhereWithoutVehiclesInput>;
  upsert?: InputMaybe<DriverUpsertWithoutVehiclesInput>;
};

export type DriverUpdateOneWithoutNewDriverTripLogsNestedInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutNewDriverTripLogsInput>;
  create?: InputMaybe<DriverCreateWithoutNewDriverTripLogsInput>;
  delete?: InputMaybe<DriverWhereInput>;
  disconnect?: InputMaybe<DriverWhereInput>;
  update?: InputMaybe<DriverUpdateToOneWithWhereWithoutNewDriverTripLogsInput>;
  upsert?: InputMaybe<DriverUpsertWithoutNewDriverTripLogsInput>;
};

export type DriverUpdateOneWithoutOldDriverTripLogsNestedInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutOldDriverTripLogsInput>;
  create?: InputMaybe<DriverCreateWithoutOldDriverTripLogsInput>;
  delete?: InputMaybe<DriverWhereInput>;
  disconnect?: InputMaybe<DriverWhereInput>;
  update?: InputMaybe<DriverUpdateToOneWithWhereWithoutOldDriverTripLogsInput>;
  upsert?: InputMaybe<DriverUpsertWithoutOldDriverTripLogsInput>;
};

export type DriverUpdateOneWithoutRouteDriverVacantAlertsNestedInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutRouteDriverVacantAlertsInput>;
  create?: InputMaybe<DriverCreateWithoutRouteDriverVacantAlertsInput>;
  delete?: InputMaybe<DriverWhereInput>;
  disconnect?: InputMaybe<DriverWhereInput>;
  update?: InputMaybe<DriverUpdateToOneWithWhereWithoutRouteDriverVacantAlertsInput>;
  upsert?: InputMaybe<DriverUpsertWithoutRouteDriverVacantAlertsInput>;
};

export type DriverUpdateOneWithoutTripsNestedInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutTripsInput>;
  create?: InputMaybe<DriverCreateWithoutTripsInput>;
  delete?: InputMaybe<DriverWhereInput>;
  disconnect?: InputMaybe<DriverWhereInput>;
  update?: InputMaybe<DriverUpdateToOneWithWhereWithoutTripsInput>;
  upsert?: InputMaybe<DriverUpsertWithoutTripsInput>;
};

export type DriverUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<DriverCreateWithoutUserInput>;
  delete?: InputMaybe<DriverWhereInput>;
  disconnect?: InputMaybe<DriverWhereInput>;
  update?: InputMaybe<DriverUpdateToOneWithWhereWithoutUserInput>;
  upsert?: InputMaybe<DriverUpsertWithoutUserInput>;
};

export type DriverUpdateOneWithoutVehicleSafetyEventsNestedInput = {
  connect?: InputMaybe<DriverWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DriverCreateOrConnectWithoutVehicleSafetyEventsInput>;
  create?: InputMaybe<DriverCreateWithoutVehicleSafetyEventsInput>;
  delete?: InputMaybe<DriverWhereInput>;
  disconnect?: InputMaybe<DriverWhereInput>;
  update?: InputMaybe<DriverUpdateToOneWithWhereWithoutVehicleSafetyEventsInput>;
  upsert?: InputMaybe<DriverUpsertWithoutVehicleSafetyEventsInput>;
};

export type DriverUpdateToOneWithWhereWithoutDriverHoursWorkedInput = {
  data: DriverUpdateWithoutDriverHoursWorkedInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpdateToOneWithWhereWithoutDriverStatusHistoryInput = {
  data: DriverUpdateWithoutDriverStatusHistoryInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpdateToOneWithWhereWithoutDriverViolationComplianceInput = {
  data: DriverUpdateWithoutDriverViolationComplianceInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpdateToOneWithWhereWithoutNewDriverTripLogsInput = {
  data: DriverUpdateWithoutNewDriverTripLogsInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpdateToOneWithWhereWithoutOldDriverTripLogsInput = {
  data: DriverUpdateWithoutOldDriverTripLogsInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpdateToOneWithWhereWithoutRouteDriverVacantAlertsInput = {
  data: DriverUpdateWithoutRouteDriverVacantAlertsInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpdateToOneWithWhereWithoutRoutesInput = {
  data: DriverUpdateWithoutRoutesInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpdateToOneWithWhereWithoutTripsInput = {
  data: DriverUpdateWithoutTripsInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpdateToOneWithWhereWithoutUserInput = {
  data: DriverUpdateWithoutUserInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpdateToOneWithWhereWithoutVehicleSafetyEventsInput = {
  data: DriverUpdateWithoutVehicleSafetyEventsInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpdateToOneWithWhereWithoutVehiclesInput = {
  data: DriverUpdateWithoutVehiclesInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpdateWithWhereUniqueWithoutBankInput = {
  data: DriverUpdateWithoutBankInput;
  where: DriverWhereUniqueInput;
};

export type DriverUpdateWithWhereUniqueWithoutContractTypeInput = {
  data: DriverUpdateWithoutContractTypeInput;
  where: DriverWhereUniqueInput;
};

export type DriverUpdateWithWhereUniqueWithoutZoneInput = {
  data: DriverUpdateWithoutZoneInput;
  where: DriverWhereUniqueInput;
};

export type DriverUpdateWithoutBankInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutContractTypeInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutDriverHoursWorkedInput = {
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutDriverStatusHistoryInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutDriverViolationComplianceInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutNewDriverTripLogsInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutOldDriverTripLogsInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutRouteDriverVacantAlertsInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutRoutesInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutTripsInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutUserInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutVehicleSafetyEventsInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutVehiclesInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  zone?: InputMaybe<EmployeeZoneUpdateOneWithoutDriverNestedInput>;
};

export type DriverUpdateWithoutZoneInput = {
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedUpdateManyWithoutDriverNestedInput>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryUpdateManyWithoutDriverNestedInput>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceUpdateManyWithoutDriverNestedInput>;
  bank?: InputMaybe<BankUpdateOneWithoutDriverNestedInput>;
  bankAccountName?: InputMaybe<Scalars["String"]["input"]>;
  bankAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  contractOriginalStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  contractType?: InputMaybe<ContractTypeUpdateOneWithoutDriverNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewDriverNestedInput>;
  oldDriverTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldDriverNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput>;
  routes?: InputMaybe<RouteDriverUpdateManyWithoutDriverNestedInput>;
  status?: InputMaybe<DriverStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutDriverNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutDriverNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutDriverNestedInput>;
  vehicles?: InputMaybe<VehicleDriverUpdateManyWithoutDriverNestedInput>;
};

export type DriverUpsertWithWhereUniqueWithoutBankInput = {
  create: DriverCreateWithoutBankInput;
  update: DriverUpdateWithoutBankInput;
  where: DriverWhereUniqueInput;
};

export type DriverUpsertWithWhereUniqueWithoutContractTypeInput = {
  create: DriverCreateWithoutContractTypeInput;
  update: DriverUpdateWithoutContractTypeInput;
  where: DriverWhereUniqueInput;
};

export type DriverUpsertWithWhereUniqueWithoutZoneInput = {
  create: DriverCreateWithoutZoneInput;
  update: DriverUpdateWithoutZoneInput;
  where: DriverWhereUniqueInput;
};

export type DriverUpsertWithoutDriverHoursWorkedInput = {
  create: DriverCreateWithoutDriverHoursWorkedInput;
  update: DriverUpdateWithoutDriverHoursWorkedInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpsertWithoutDriverStatusHistoryInput = {
  create: DriverCreateWithoutDriverStatusHistoryInput;
  update: DriverUpdateWithoutDriverStatusHistoryInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpsertWithoutDriverViolationComplianceInput = {
  create: DriverCreateWithoutDriverViolationComplianceInput;
  update: DriverUpdateWithoutDriverViolationComplianceInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpsertWithoutNewDriverTripLogsInput = {
  create: DriverCreateWithoutNewDriverTripLogsInput;
  update: DriverUpdateWithoutNewDriverTripLogsInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpsertWithoutOldDriverTripLogsInput = {
  create: DriverCreateWithoutOldDriverTripLogsInput;
  update: DriverUpdateWithoutOldDriverTripLogsInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpsertWithoutRouteDriverVacantAlertsInput = {
  create: DriverCreateWithoutRouteDriverVacantAlertsInput;
  update: DriverUpdateWithoutRouteDriverVacantAlertsInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpsertWithoutRoutesInput = {
  create: DriverCreateWithoutRoutesInput;
  update: DriverUpdateWithoutRoutesInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpsertWithoutTripsInput = {
  create: DriverCreateWithoutTripsInput;
  update: DriverUpdateWithoutTripsInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpsertWithoutUserInput = {
  create: DriverCreateWithoutUserInput;
  update: DriverUpdateWithoutUserInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpsertWithoutVehicleSafetyEventsInput = {
  create: DriverCreateWithoutVehicleSafetyEventsInput;
  update: DriverUpdateWithoutVehicleSafetyEventsInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverUpsertWithoutVehiclesInput = {
  create: DriverCreateWithoutVehiclesInput;
  update: DriverUpdateWithoutVehiclesInput;
  where?: InputMaybe<DriverWhereInput>;
};

export type DriverViolationCompliance = {
  __typename?: "DriverViolationCompliance";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  date: Scalars["DateTime"]["output"];
  driver: Driver;
  driverId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  type: DriverViolationComplianceType;
  updatedAt: Scalars["DateTime"]["output"];
};

export type DriverViolationComplianceCreateInput = {
  date: Scalars["DateTime"]["input"];
  driver: DriverCreateNestedOneWithoutDriverViolationComplianceInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type: DriverViolationComplianceType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverViolationComplianceCreateManyDriverInput = {
  date: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type: DriverViolationComplianceType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverViolationComplianceCreateManyDriverInputEnvelope = {
  data: Array<DriverViolationComplianceCreateManyDriverInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DriverViolationComplianceCreateNestedManyWithoutDriverInput = {
  connect?: InputMaybe<Array<DriverViolationComplianceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DriverViolationComplianceCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<DriverViolationComplianceCreateWithoutDriverInput>>;
  createMany?: InputMaybe<DriverViolationComplianceCreateManyDriverInputEnvelope>;
};

export type DriverViolationComplianceCreateOrConnectWithoutDriverInput = {
  create: DriverViolationComplianceCreateWithoutDriverInput;
  where: DriverViolationComplianceWhereUniqueInput;
};

export type DriverViolationComplianceCreateWithoutDriverInput = {
  date: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type: DriverViolationComplianceType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverViolationComplianceListRelationFilter = {
  every?: InputMaybe<DriverViolationComplianceWhereInput>;
  none?: InputMaybe<DriverViolationComplianceWhereInput>;
  some?: InputMaybe<DriverViolationComplianceWhereInput>;
};

export type DriverViolationComplianceScalarWhereInput = {
  AND?: InputMaybe<Array<DriverViolationComplianceScalarWhereInput>>;
  NOT?: InputMaybe<Array<DriverViolationComplianceScalarWhereInput>>;
  OR?: InputMaybe<Array<DriverViolationComplianceScalarWhereInput>>;
  date?: InputMaybe<DateTimeFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumDriverViolationComplianceTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum DriverViolationComplianceType {
  ABSENCE = "ABSENCE",
  ALLOW_OTHERS_INTO_BUS = "ALLOW_OTHERS_INTO_BUS",
  ALTER_BUS = "ALTER_BUS",
  ATTITUDE_MANNERS = "ATTITUDE_MANNERS",
  BUS_CLEANLINESS = "BUS_CLEANLINESS",
  CHANGE_IN_ROUTE = "CHANGE_IN_ROUTE",
  DELAY = "DELAY",
  HORN = "HORN",
  NOT_SHOWING_FOR_MAINTENANCE = "NOT_SHOWING_FOR_MAINTENANCE",
  OVERSPEED = "OVERSPEED",
  PARKING = "PARKING",
  SEATBELTS = "SEATBELTS",
  SMOKING = "SMOKING",
  USE_BUS_AFTER_WORK_HOURS = "USE_BUS_AFTER_WORK_HOURS",
}

export type DriverViolationComplianceUpdateInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneRequiredWithoutDriverViolationComplianceNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<DriverViolationComplianceType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverViolationComplianceUpdateManyMutationInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<DriverViolationComplianceType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverViolationComplianceUpdateManyWithWhereWithoutDriverInput = {
  data: DriverViolationComplianceUpdateManyMutationInput;
  where: DriverViolationComplianceScalarWhereInput;
};

export type DriverViolationComplianceUpdateManyWithoutDriverNestedInput = {
  connect?: InputMaybe<Array<DriverViolationComplianceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DriverViolationComplianceCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<DriverViolationComplianceCreateWithoutDriverInput>>;
  createMany?: InputMaybe<DriverViolationComplianceCreateManyDriverInputEnvelope>;
  delete?: InputMaybe<Array<DriverViolationComplianceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DriverViolationComplianceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DriverViolationComplianceWhereUniqueInput>>;
  set?: InputMaybe<Array<DriverViolationComplianceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DriverViolationComplianceUpdateWithWhereUniqueWithoutDriverInput>
  >;
  updateMany?: InputMaybe<
    Array<DriverViolationComplianceUpdateManyWithWhereWithoutDriverInput>
  >;
  upsert?: InputMaybe<
    Array<DriverViolationComplianceUpsertWithWhereUniqueWithoutDriverInput>
  >;
};

export type DriverViolationComplianceUpdateWithWhereUniqueWithoutDriverInput = {
  data: DriverViolationComplianceUpdateWithoutDriverInput;
  where: DriverViolationComplianceWhereUniqueInput;
};

export type DriverViolationComplianceUpdateWithoutDriverInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<DriverViolationComplianceType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DriverViolationComplianceUpsertWithWhereUniqueWithoutDriverInput = {
  create: DriverViolationComplianceCreateWithoutDriverInput;
  update: DriverViolationComplianceUpdateWithoutDriverInput;
  where: DriverViolationComplianceWhereUniqueInput;
};

export type DriverViolationComplianceWhereInput = {
  AND?: InputMaybe<Array<DriverViolationComplianceWhereInput>>;
  NOT?: InputMaybe<Array<DriverViolationComplianceWhereInput>>;
  OR?: InputMaybe<Array<DriverViolationComplianceWhereInput>>;
  date?: InputMaybe<DateTimeFilter>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumDriverViolationComplianceTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DriverViolationComplianceWhereUniqueInput = {
  AND?: InputMaybe<Array<DriverViolationComplianceWhereInput>>;
  NOT?: InputMaybe<Array<DriverViolationComplianceWhereInput>>;
  OR?: InputMaybe<Array<DriverViolationComplianceWhereInput>>;
  date?: InputMaybe<DateTimeFilter>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumDriverViolationComplianceTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DriverWhereInput = {
  AND?: InputMaybe<Array<DriverWhereInput>>;
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedListRelationFilter>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryListRelationFilter>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceListRelationFilter>;
  NOT?: InputMaybe<Array<DriverWhereInput>>;
  OR?: InputMaybe<Array<DriverWhereInput>>;
  bank?: InputMaybe<BankRelationFilter>;
  bankAccountName?: InputMaybe<StringFilter>;
  bankAccountNumber?: InputMaybe<StringFilter>;
  bankId?: InputMaybe<StringFilter>;
  contractOriginalStartDate?: InputMaybe<DateTimeFilter>;
  contractStartDate?: InputMaybe<DateTimeFilter>;
  contractType?: InputMaybe<ContractTypeRelationFilter>;
  contractTypeId?: InputMaybe<StringFilter>;
  dateOfBirth?: InputMaybe<DateTimeFilter>;
  driverLicenseExpiryDate?: InputMaybe<DateTimeFilter>;
  driverLicenseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  nationalId?: InputMaybe<StringFilter>;
  nationalIdExpiryDate?: InputMaybe<DateTimeFilter>;
  newDriverTripLogs?: InputMaybe<TripLogListRelationFilter>;
  oldDriverTripLogs?: InputMaybe<TripLogListRelationFilter>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertListRelationFilter>;
  routes?: InputMaybe<RouteDriverListRelationFilter>;
  status?: InputMaybe<EnumDriverStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  terminationDate?: InputMaybe<DateTimeFilter>;
  trips?: InputMaybe<TripListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventListRelationFilter>;
  vehicles?: InputMaybe<VehicleDriverListRelationFilter>;
  zone?: InputMaybe<EmployeeZoneRelationFilter>;
  zoneId?: InputMaybe<StringFilter>;
};

export type DriverWhereUniqueInput = {
  AND?: InputMaybe<Array<DriverWhereInput>>;
  DriverHoursWorked?: InputMaybe<DriverHoursWorkedListRelationFilter>;
  DriverStatusHistory?: InputMaybe<DriverStatusHistoryListRelationFilter>;
  DriverViolationCompliance?: InputMaybe<DriverViolationComplianceListRelationFilter>;
  NOT?: InputMaybe<Array<DriverWhereInput>>;
  OR?: InputMaybe<Array<DriverWhereInput>>;
  bank?: InputMaybe<BankRelationFilter>;
  bankAccountName?: InputMaybe<StringFilter>;
  bankAccountNumber?: InputMaybe<StringFilter>;
  bankId?: InputMaybe<StringFilter>;
  contractOriginalStartDate?: InputMaybe<DateTimeFilter>;
  contractStartDate?: InputMaybe<DateTimeFilter>;
  contractType?: InputMaybe<ContractTypeRelationFilter>;
  contractTypeId?: InputMaybe<StringFilter>;
  dateOfBirth?: InputMaybe<DateTimeFilter>;
  driverLicenseExpiryDate?: InputMaybe<DateTimeFilter>;
  driverLicenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  nationalId?: InputMaybe<Scalars["String"]["input"]>;
  nationalIdExpiryDate?: InputMaybe<DateTimeFilter>;
  newDriverTripLogs?: InputMaybe<TripLogListRelationFilter>;
  oldDriverTripLogs?: InputMaybe<TripLogListRelationFilter>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertListRelationFilter>;
  routes?: InputMaybe<RouteDriverListRelationFilter>;
  status?: InputMaybe<EnumDriverStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  terminationDate?: InputMaybe<DateTimeFilter>;
  trips?: InputMaybe<TripListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventListRelationFilter>;
  vehicles?: InputMaybe<VehicleDriverListRelationFilter>;
  zone?: InputMaybe<EmployeeZoneRelationFilter>;
  zoneId?: InputMaybe<StringFilter>;
};

export type Eligibility = {
  __typename?: "Eligibility";
  distance?: Maybe<Scalars["Float"]["output"]>;
  duration?: Maybe<Scalars["Float"]["output"]>;
  isEligible: Scalars["Boolean"]["output"];
  reason: Scalars["String"]["output"];
};

export type EligibilityInput = {
  homeLocation?: InputMaybe<SimpleLatLon>;
  schoolId: Scalars["String"]["input"];
  serviceId: Scalars["String"]["input"];
  studentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type EmployeeZone = {
  __typename?: "EmployeeZone";
  BusAttendant?: Maybe<Array<BusAttendant>>;
  Driver?: Maybe<Array<Driver>>;
  _count: EmployeeZoneCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type EmployeeZoneCount = {
  __typename?: "EmployeeZoneCount";
  BusAttendant: Scalars["Int"]["output"];
  Driver: Scalars["Int"]["output"];
};

export type EmployeeZoneCreateNestedOneWithoutBusAttendantInput = {
  connect?: InputMaybe<EmployeeZoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EmployeeZoneCreateOrConnectWithoutBusAttendantInput>;
  create?: InputMaybe<EmployeeZoneCreateWithoutBusAttendantInput>;
};

export type EmployeeZoneCreateNestedOneWithoutDriverInput = {
  connect?: InputMaybe<EmployeeZoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EmployeeZoneCreateOrConnectWithoutDriverInput>;
  create?: InputMaybe<EmployeeZoneCreateWithoutDriverInput>;
};

export type EmployeeZoneCreateOrConnectWithoutBusAttendantInput = {
  create: EmployeeZoneCreateWithoutBusAttendantInput;
  where: EmployeeZoneWhereUniqueInput;
};

export type EmployeeZoneCreateOrConnectWithoutDriverInput = {
  create: EmployeeZoneCreateWithoutDriverInput;
  where: EmployeeZoneWhereUniqueInput;
};

export type EmployeeZoneCreateWithoutBusAttendantInput = {
  Driver?: InputMaybe<DriverCreateNestedManyWithoutZoneInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type EmployeeZoneCreateWithoutDriverInput = {
  BusAttendant?: InputMaybe<BusAttendantCreateNestedManyWithoutZoneInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type EmployeeZoneRelationFilter = {
  is?: InputMaybe<EmployeeZoneWhereInput>;
  isNot?: InputMaybe<EmployeeZoneWhereInput>;
};

export type EmployeeZoneUpdateOneWithoutBusAttendantNestedInput = {
  connect?: InputMaybe<EmployeeZoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EmployeeZoneCreateOrConnectWithoutBusAttendantInput>;
  create?: InputMaybe<EmployeeZoneCreateWithoutBusAttendantInput>;
  delete?: InputMaybe<EmployeeZoneWhereInput>;
  disconnect?: InputMaybe<EmployeeZoneWhereInput>;
  update?: InputMaybe<EmployeeZoneUpdateToOneWithWhereWithoutBusAttendantInput>;
  upsert?: InputMaybe<EmployeeZoneUpsertWithoutBusAttendantInput>;
};

export type EmployeeZoneUpdateOneWithoutDriverNestedInput = {
  connect?: InputMaybe<EmployeeZoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EmployeeZoneCreateOrConnectWithoutDriverInput>;
  create?: InputMaybe<EmployeeZoneCreateWithoutDriverInput>;
  delete?: InputMaybe<EmployeeZoneWhereInput>;
  disconnect?: InputMaybe<EmployeeZoneWhereInput>;
  update?: InputMaybe<EmployeeZoneUpdateToOneWithWhereWithoutDriverInput>;
  upsert?: InputMaybe<EmployeeZoneUpsertWithoutDriverInput>;
};

export type EmployeeZoneUpdateToOneWithWhereWithoutBusAttendantInput = {
  data: EmployeeZoneUpdateWithoutBusAttendantInput;
  where?: InputMaybe<EmployeeZoneWhereInput>;
};

export type EmployeeZoneUpdateToOneWithWhereWithoutDriverInput = {
  data: EmployeeZoneUpdateWithoutDriverInput;
  where?: InputMaybe<EmployeeZoneWhereInput>;
};

export type EmployeeZoneUpdateWithoutBusAttendantInput = {
  Driver?: InputMaybe<DriverUpdateManyWithoutZoneNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type EmployeeZoneUpdateWithoutDriverInput = {
  BusAttendant?: InputMaybe<BusAttendantUpdateManyWithoutZoneNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type EmployeeZoneUpsertWithoutBusAttendantInput = {
  create: EmployeeZoneCreateWithoutBusAttendantInput;
  update: EmployeeZoneUpdateWithoutBusAttendantInput;
  where?: InputMaybe<EmployeeZoneWhereInput>;
};

export type EmployeeZoneUpsertWithoutDriverInput = {
  create: EmployeeZoneCreateWithoutDriverInput;
  update: EmployeeZoneUpdateWithoutDriverInput;
  where?: InputMaybe<EmployeeZoneWhereInput>;
};

export type EmployeeZoneWhereInput = {
  AND?: InputMaybe<Array<EmployeeZoneWhereInput>>;
  BusAttendant?: InputMaybe<BusAttendantListRelationFilter>;
  Driver?: InputMaybe<DriverListRelationFilter>;
  NOT?: InputMaybe<Array<EmployeeZoneWhereInput>>;
  OR?: InputMaybe<Array<EmployeeZoneWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EmployeeZoneWhereUniqueInput = {
  AND?: InputMaybe<Array<EmployeeZoneWhereInput>>;
  BusAttendant?: InputMaybe<BusAttendantListRelationFilter>;
  Driver?: InputMaybe<DriverListRelationFilter>;
  NOT?: InputMaybe<Array<EmployeeZoneWhereInput>>;
  OR?: InputMaybe<Array<EmployeeZoneWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EnumAddressSourceFilter = {
  equals?: InputMaybe<AddressSource>;
  in?: InputMaybe<Array<AddressSource>>;
  not?: InputMaybe<EnumAddressSourceFilter>;
  notIn?: InputMaybe<Array<AddressSource>>;
};

export type EnumAdhocNotificationChannelFilter = {
  equals?: InputMaybe<AdhocNotificationChannel>;
  in?: InputMaybe<Array<AdhocNotificationChannel>>;
  not?: InputMaybe<EnumAdhocNotificationChannelFilter>;
  notIn?: InputMaybe<Array<AdhocNotificationChannel>>;
};

export type EnumAgentStatusFilter = {
  equals?: InputMaybe<AgentStatus>;
  in?: InputMaybe<Array<AgentStatus>>;
  not?: InputMaybe<EnumAgentStatusFilter>;
  notIn?: InputMaybe<Array<AgentStatus>>;
};

export type EnumAlertLevelFilter = {
  equals?: InputMaybe<AlertLevel>;
  in?: InputMaybe<Array<AlertLevel>>;
  not?: InputMaybe<EnumAlertLevelFilter>;
  notIn?: InputMaybe<Array<AlertLevel>>;
};

export type EnumAlertTypeFilter = {
  equals?: InputMaybe<AlertType>;
  in?: InputMaybe<Array<AlertType>>;
  not?: InputMaybe<EnumAlertTypeFilter>;
  notIn?: InputMaybe<Array<AlertType>>;
};

export type EnumAttendanceStatusFilter = {
  equals?: InputMaybe<AttendanceStatus>;
  in?: InputMaybe<Array<AttendanceStatus>>;
  not?: InputMaybe<EnumAttendanceStatusFilter>;
  notIn?: InputMaybe<Array<AttendanceStatus>>;
};

export type EnumBuildingTypeFilter = {
  equals?: InputMaybe<BuildingType>;
  in?: InputMaybe<Array<BuildingType>>;
  not?: InputMaybe<EnumBuildingTypeFilter>;
  notIn?: InputMaybe<Array<BuildingType>>;
};

export type EnumBusAttendantStatusFilter = {
  equals?: InputMaybe<BusAttendantStatus>;
  in?: InputMaybe<Array<BusAttendantStatus>>;
  not?: InputMaybe<EnumBusAttendantStatusFilter>;
  notIn?: InputMaybe<Array<BusAttendantStatus>>;
};

export type EnumBusAttendantViolationComplianceTypeFilter = {
  equals?: InputMaybe<BusAttendantViolationComplianceType>;
  in?: InputMaybe<Array<BusAttendantViolationComplianceType>>;
  not?: InputMaybe<EnumBusAttendantViolationComplianceTypeFilter>;
  notIn?: InputMaybe<Array<BusAttendantViolationComplianceType>>;
};

export type EnumCashOutRequestCreditSourceFilter = {
  equals?: InputMaybe<CashOutRequestCreditSource>;
  in?: InputMaybe<Array<CashOutRequestCreditSource>>;
  not?: InputMaybe<EnumCashOutRequestCreditSourceFilter>;
  notIn?: InputMaybe<Array<CashOutRequestCreditSource>>;
};

export type EnumCashOutRequestStatusFilter = {
  equals?: InputMaybe<CashOutRequestStatus>;
  in?: InputMaybe<Array<CashOutRequestStatus>>;
  not?: InputMaybe<EnumCashOutRequestStatusFilter>;
  notIn?: InputMaybe<Array<CashOutRequestStatus>>;
};

export type EnumCollectionPointStatusFilter = {
  equals?: InputMaybe<CollectionPointStatus>;
  in?: InputMaybe<Array<CollectionPointStatus>>;
  not?: InputMaybe<EnumCollectionPointStatusFilter>;
  notIn?: InputMaybe<Array<CollectionPointStatus>>;
};

export type EnumCommunicationChannelFilter = {
  equals?: InputMaybe<CommunicationChannel>;
  in?: InputMaybe<Array<CommunicationChannel>>;
  not?: InputMaybe<EnumCommunicationChannelFilter>;
  notIn?: InputMaybe<Array<CommunicationChannel>>;
};

export type EnumContactChannelFilter = {
  equals?: InputMaybe<ContactChannel>;
  in?: InputMaybe<Array<ContactChannel>>;
  not?: InputMaybe<EnumContactChannelFilter>;
  notIn?: InputMaybe<Array<ContactChannel>>;
};

export type EnumCreditNoteCreditSourceFilter = {
  equals?: InputMaybe<CreditNoteCreditSource>;
  in?: InputMaybe<Array<CreditNoteCreditSource>>;
  not?: InputMaybe<EnumCreditNoteCreditSourceFilter>;
  notIn?: InputMaybe<Array<CreditNoteCreditSource>>;
};

export type EnumCurrencyCodeFilter = {
  equals?: InputMaybe<CurrencyCode>;
  in?: InputMaybe<Array<CurrencyCode>>;
  not?: InputMaybe<EnumCurrencyCodeFilter>;
  notIn?: InputMaybe<Array<CurrencyCode>>;
};

export type EnumDepositTypeFilter = {
  equals?: InputMaybe<DepositType>;
  in?: InputMaybe<Array<DepositType>>;
  not?: InputMaybe<EnumDepositTypeFilter>;
  notIn?: InputMaybe<Array<DepositType>>;
};

export type EnumDivisionLevelTypeFilter = {
  equals?: InputMaybe<DivisionLevelType>;
  in?: InputMaybe<Array<DivisionLevelType>>;
  not?: InputMaybe<EnumDivisionLevelTypeFilter>;
  notIn?: InputMaybe<Array<DivisionLevelType>>;
};

export type EnumDriverStatusFilter = {
  equals?: InputMaybe<DriverStatus>;
  in?: InputMaybe<Array<DriverStatus>>;
  not?: InputMaybe<EnumDriverStatusFilter>;
  notIn?: InputMaybe<Array<DriverStatus>>;
};

export type EnumDriverViolationComplianceTypeFilter = {
  equals?: InputMaybe<DriverViolationComplianceType>;
  in?: InputMaybe<Array<DriverViolationComplianceType>>;
  not?: InputMaybe<EnumDriverViolationComplianceTypeFilter>;
  notIn?: InputMaybe<Array<DriverViolationComplianceType>>;
};

export type EnumGenderFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  not?: InputMaybe<EnumGenderFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type EnumIncidentReportTypeFilter = {
  equals?: InputMaybe<IncidentReportType>;
  in?: InputMaybe<Array<IncidentReportType>>;
  not?: InputMaybe<EnumIncidentReportTypeFilter>;
  notIn?: InputMaybe<Array<IncidentReportType>>;
};

export type EnumInstallmentPlansOrderingStrategyFilter = {
  equals?: InputMaybe<InstallmentPlansOrderingStrategy>;
  in?: InputMaybe<Array<InstallmentPlansOrderingStrategy>>;
  not?: InputMaybe<EnumInstallmentPlansOrderingStrategyFilter>;
  notIn?: InputMaybe<Array<InstallmentPlansOrderingStrategy>>;
};

export type EnumLanguageFilter = {
  equals?: InputMaybe<Language>;
  in?: InputMaybe<Array<Language>>;
  not?: InputMaybe<EnumLanguageFilter>;
  notIn?: InputMaybe<Array<Language>>;
};

export type EnumLeaveRequestChannelFilter = {
  equals?: InputMaybe<LeaveRequestChannel>;
  in?: InputMaybe<Array<LeaveRequestChannel>>;
  not?: InputMaybe<EnumLeaveRequestChannelFilter>;
  notIn?: InputMaybe<Array<LeaveRequestChannel>>;
};

export type EnumLeaveRequestStatusFilter = {
  equals?: InputMaybe<LeaveRequestStatus>;
  in?: InputMaybe<Array<LeaveRequestStatus>>;
  not?: InputMaybe<EnumLeaveRequestStatusFilter>;
  notIn?: InputMaybe<Array<LeaveRequestStatus>>;
};

export type EnumOtpReasonFilter = {
  equals?: InputMaybe<OtpReason>;
  in?: InputMaybe<Array<OtpReason>>;
  not?: InputMaybe<EnumOtpReasonFilter>;
  notIn?: InputMaybe<Array<OtpReason>>;
};

export type EnumOtpTypeFilter = {
  equals?: InputMaybe<OtpType>;
  in?: InputMaybe<Array<OtpType>>;
  not?: InputMaybe<EnumOtpTypeFilter>;
  notIn?: InputMaybe<Array<OtpType>>;
};

export type EnumPaymentSolutionFilter = {
  equals?: InputMaybe<PaymentSolution>;
  in?: InputMaybe<Array<PaymentSolution>>;
  not?: InputMaybe<EnumPaymentSolutionFilter>;
  notIn?: InputMaybe<Array<PaymentSolution>>;
};

export type EnumPaymentStatusFilter = {
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<EnumPaymentStatusFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type EnumPaymentStatusListFilter = {
  equals?: InputMaybe<Array<PaymentStatus>>;
  has?: InputMaybe<PaymentStatus>;
  hasEvery?: InputMaybe<Array<PaymentStatus>>;
  hasSome?: InputMaybe<Array<PaymentStatus>>;
  isEmpty?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EnumPaymentTypeFilter = {
  equals?: InputMaybe<PaymentType>;
  in?: InputMaybe<Array<PaymentType>>;
  not?: InputMaybe<EnumPaymentTypeFilter>;
  notIn?: InputMaybe<Array<PaymentType>>;
};

export type EnumPermissionActionListFilter = {
  equals?: InputMaybe<Array<PermissionAction>>;
  has?: InputMaybe<PermissionAction>;
  hasEvery?: InputMaybe<Array<PermissionAction>>;
  hasSome?: InputMaybe<Array<PermissionAction>>;
  isEmpty?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EnumPlatformFilter = {
  equals?: InputMaybe<Platform>;
  in?: InputMaybe<Array<Platform>>;
  not?: InputMaybe<EnumPlatformFilter>;
  notIn?: InputMaybe<Array<Platform>>;
};

export type EnumRelationshipFilter = {
  equals?: InputMaybe<Relationship>;
  in?: InputMaybe<Array<Relationship>>;
  not?: InputMaybe<EnumRelationshipFilter>;
  notIn?: InputMaybe<Array<Relationship>>;
};

export type EnumRoleClassFilter = {
  equals?: InputMaybe<RoleClass>;
  in?: InputMaybe<Array<RoleClass>>;
  not?: InputMaybe<EnumRoleClassFilter>;
  notIn?: InputMaybe<Array<RoleClass>>;
};

export type EnumRouteDirectionalityFilter = {
  equals?: InputMaybe<RouteDirectionality>;
  in?: InputMaybe<Array<RouteDirectionality>>;
  not?: InputMaybe<EnumRouteDirectionalityFilter>;
  notIn?: InputMaybe<Array<RouteDirectionality>>;
};

export type EnumRouteDirectionalityListFilter = {
  equals?: InputMaybe<Array<RouteDirectionality>>;
  has?: InputMaybe<RouteDirectionality>;
  hasEvery?: InputMaybe<Array<RouteDirectionality>>;
  hasSome?: InputMaybe<Array<RouteDirectionality>>;
  isEmpty?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EnumRouteGroupStatusFilter = {
  equals?: InputMaybe<RouteGroupStatus>;
  in?: InputMaybe<Array<RouteGroupStatus>>;
  not?: InputMaybe<EnumRouteGroupStatusFilter>;
  notIn?: InputMaybe<Array<RouteGroupStatus>>;
};

export type EnumRouteStatusFilter = {
  equals?: InputMaybe<RouteStatus>;
  in?: InputMaybe<Array<RouteStatus>>;
  not?: InputMaybe<EnumRouteStatusFilter>;
  notIn?: InputMaybe<Array<RouteStatus>>;
};

export type EnumRouteStopStatusFilter = {
  equals?: InputMaybe<RouteStopStatus>;
  in?: InputMaybe<Array<RouteStopStatus>>;
  not?: InputMaybe<EnumRouteStopStatusFilter>;
  notIn?: InputMaybe<Array<RouteStopStatus>>;
};

export type EnumScheduleFilterTypeFilter = {
  equals?: InputMaybe<ScheduleFilterType>;
  in?: InputMaybe<Array<ScheduleFilterType>>;
  not?: InputMaybe<EnumScheduleFilterTypeFilter>;
  notIn?: InputMaybe<Array<ScheduleFilterType>>;
};

export type EnumScheduleRepeatModeFilter = {
  equals?: InputMaybe<ScheduleRepeatMode>;
  in?: InputMaybe<Array<ScheduleRepeatMode>>;
  not?: InputMaybe<EnumScheduleRepeatModeFilter>;
  notIn?: InputMaybe<Array<ScheduleRepeatMode>>;
};

export type EnumScheduleStateFilter = {
  equals?: InputMaybe<ScheduleState>;
  in?: InputMaybe<Array<ScheduleState>>;
  not?: InputMaybe<EnumScheduleStateFilter>;
  notIn?: InputMaybe<Array<ScheduleState>>;
};

export type EnumSchoolRegistrationInternalStatusFilter = {
  equals?: InputMaybe<SchoolRegistrationInternalStatus>;
  in?: InputMaybe<Array<SchoolRegistrationInternalStatus>>;
  not?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  notIn?: InputMaybe<Array<SchoolRegistrationInternalStatus>>;
};

export type EnumSchoolRegistrationInternalStatusListFilter = {
  equals?: InputMaybe<Array<SchoolRegistrationInternalStatus>>;
  has?: InputMaybe<SchoolRegistrationInternalStatus>;
  hasEvery?: InputMaybe<Array<SchoolRegistrationInternalStatus>>;
  hasSome?: InputMaybe<Array<SchoolRegistrationInternalStatus>>;
  isEmpty?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EnumSchoolRegistrationPaymentStatusFilter = {
  equals?: InputMaybe<SchoolRegistrationPaymentStatus>;
  in?: InputMaybe<Array<SchoolRegistrationPaymentStatus>>;
  not?: InputMaybe<EnumSchoolRegistrationPaymentStatusFilter>;
  notIn?: InputMaybe<Array<SchoolRegistrationPaymentStatus>>;
};

export type EnumSchoolRegistrationSponsorDisplayStatusFilter = {
  equals?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  in?: InputMaybe<Array<SchoolRegistrationSponsorDisplayStatus>>;
  not?: InputMaybe<EnumSchoolRegistrationSponsorDisplayStatusFilter>;
  notIn?: InputMaybe<Array<SchoolRegistrationSponsorDisplayStatus>>;
};

export type EnumSchoolRegistrationStatusFilter = {
  equals?: InputMaybe<SchoolRegistrationStatus>;
  in?: InputMaybe<Array<SchoolRegistrationStatus>>;
  not?: InputMaybe<EnumSchoolRegistrationStatusFilter>;
  notIn?: InputMaybe<Array<SchoolRegistrationStatus>>;
};

export type EnumSchoolRegistrationTerminationReasonFilter = {
  equals?: InputMaybe<SchoolRegistrationTerminationReason>;
  in?: InputMaybe<Array<SchoolRegistrationTerminationReason>>;
  not?: InputMaybe<EnumSchoolRegistrationTerminationReasonFilter>;
  notIn?: InputMaybe<Array<SchoolRegistrationTerminationReason>>;
};

export type EnumSchoolStatusFilter = {
  equals?: InputMaybe<SchoolStatus>;
  in?: InputMaybe<Array<SchoolStatus>>;
  not?: InputMaybe<EnumSchoolStatusFilter>;
  notIn?: InputMaybe<Array<SchoolStatus>>;
};

export type EnumSchoolTripStopStatusFilter = {
  equals?: InputMaybe<SchoolTripStopStatus>;
  in?: InputMaybe<Array<SchoolTripStopStatus>>;
  not?: InputMaybe<EnumSchoolTripStopStatusFilter>;
  notIn?: InputMaybe<Array<SchoolTripStopStatus>>;
};

export type EnumServicePricePreviewFilter = {
  equals?: InputMaybe<ServicePricePreview>;
  in?: InputMaybe<Array<ServicePricePreview>>;
  not?: InputMaybe<EnumServicePricePreviewFilter>;
  notIn?: InputMaybe<Array<ServicePricePreview>>;
};

export type EnumServiceProviderPlanStatusFilter = {
  equals?: InputMaybe<ServiceProviderPlanStatus>;
  in?: InputMaybe<Array<ServiceProviderPlanStatus>>;
  not?: InputMaybe<EnumServiceProviderPlanStatusFilter>;
  notIn?: InputMaybe<Array<ServiceProviderPlanStatus>>;
};

export type EnumServiceProviderStatusFilter = {
  equals?: InputMaybe<ServiceProviderStatus>;
  in?: InputMaybe<Array<ServiceProviderStatus>>;
  not?: InputMaybe<EnumServiceProviderStatusFilter>;
  notIn?: InputMaybe<Array<ServiceProviderStatus>>;
};

export type EnumServiceProviderTypeFilter = {
  equals?: InputMaybe<ServiceProviderType>;
  in?: InputMaybe<Array<ServiceProviderType>>;
  not?: InputMaybe<EnumServiceProviderTypeFilter>;
  notIn?: InputMaybe<Array<ServiceProviderType>>;
};

export type EnumServiceTypeFilter = {
  equals?: InputMaybe<ServiceType>;
  in?: InputMaybe<Array<ServiceType>>;
  not?: InputMaybe<EnumServiceTypeFilter>;
  notIn?: InputMaybe<Array<ServiceType>>;
};

export type EnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<EnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumStopLocationSourceFilter = {
  equals?: InputMaybe<StopLocationSource>;
  in?: InputMaybe<Array<StopLocationSource>>;
  not?: InputMaybe<EnumStopLocationSourceFilter>;
  notIn?: InputMaybe<Array<StopLocationSource>>;
};

export type EnumStopLocationStatusFilter = {
  equals?: InputMaybe<StopLocationStatus>;
  in?: InputMaybe<Array<StopLocationStatus>>;
  not?: InputMaybe<EnumStopLocationStatusFilter>;
  notIn?: InputMaybe<Array<StopLocationStatus>>;
};

export type EnumStopStatusFilter = {
  equals?: InputMaybe<StopStatus>;
  in?: InputMaybe<Array<StopStatus>>;
  not?: InputMaybe<EnumStopStatusFilter>;
  notIn?: InputMaybe<Array<StopStatus>>;
};

export type EnumStudentTripStopStatusFilter = {
  equals?: InputMaybe<StudentTripStopStatus>;
  in?: InputMaybe<Array<StudentTripStopStatus>>;
  not?: InputMaybe<EnumStudentTripStopStatusFilter>;
  notIn?: InputMaybe<Array<StudentTripStopStatus>>;
};

export type EnumTagTypeFilter = {
  equals?: InputMaybe<TagType>;
  in?: InputMaybe<Array<TagType>>;
  not?: InputMaybe<EnumTagTypeFilter>;
  notIn?: InputMaybe<Array<TagType>>;
};

export type EnumTimestampSourceFilter = {
  equals?: InputMaybe<TimestampSource>;
  in?: InputMaybe<Array<TimestampSource>>;
  not?: InputMaybe<EnumTimestampSourceFilter>;
  notIn?: InputMaybe<Array<TimestampSource>>;
};

export type EnumTravelTagStatusFilter = {
  equals?: InputMaybe<TravelTagStatus>;
  in?: InputMaybe<Array<TravelTagStatus>>;
  not?: InputMaybe<EnumTravelTagStatusFilter>;
  notIn?: InputMaybe<Array<TravelTagStatus>>;
};

export type EnumTripStatusFilter = {
  equals?: InputMaybe<TripStatus>;
  in?: InputMaybe<Array<TripStatus>>;
  not?: InputMaybe<EnumTripStatusFilter>;
  notIn?: InputMaybe<Array<TripStatus>>;
};

export type EnumTripStopEventSourceFilter = {
  equals?: InputMaybe<TripStopEventSource>;
  in?: InputMaybe<Array<TripStopEventSource>>;
  not?: InputMaybe<EnumTripStopEventSourceFilter>;
  notIn?: InputMaybe<Array<TripStopEventSource>>;
};

export type EnumTripStopUpdateSourceFilter = {
  equals?: InputMaybe<TripStopUpdateSource>;
  in?: InputMaybe<Array<TripStopUpdateSource>>;
  not?: InputMaybe<EnumTripStopUpdateSourceFilter>;
  notIn?: InputMaybe<Array<TripStopUpdateSource>>;
};

export type EnumTripVehicleAssignmentTypeFilter = {
  equals?: InputMaybe<TripVehicleAssignmentType>;
  in?: InputMaybe<Array<TripVehicleAssignmentType>>;
  not?: InputMaybe<EnumTripVehicleAssignmentTypeFilter>;
  notIn?: InputMaybe<Array<TripVehicleAssignmentType>>;
};

export type EnumVehicleEventTypeFilter = {
  equals?: InputMaybe<VehicleEventType>;
  in?: InputMaybe<Array<VehicleEventType>>;
  not?: InputMaybe<EnumVehicleEventTypeFilter>;
  notIn?: InputMaybe<Array<VehicleEventType>>;
};

export type EnumVehicleSafetyEventCauseFilter = {
  equals?: InputMaybe<VehicleSafetyEventCause>;
  in?: InputMaybe<Array<VehicleSafetyEventCause>>;
  not?: InputMaybe<EnumVehicleSafetyEventCauseFilter>;
  notIn?: InputMaybe<Array<VehicleSafetyEventCause>>;
};

export type EnumVehicleSafetyEventLevelFilter = {
  equals?: InputMaybe<VehicleSafetyEventLevel>;
  in?: InputMaybe<Array<VehicleSafetyEventLevel>>;
  not?: InputMaybe<EnumVehicleSafetyEventLevelFilter>;
  notIn?: InputMaybe<Array<VehicleSafetyEventLevel>>;
};

export type EnumVehicleSafetyEventPriorityFilter = {
  equals?: InputMaybe<VehicleSafetyEventPriority>;
  in?: InputMaybe<Array<VehicleSafetyEventPriority>>;
  not?: InputMaybe<EnumVehicleSafetyEventPriorityFilter>;
  notIn?: InputMaybe<Array<VehicleSafetyEventPriority>>;
};

export type EnumVehicleSafetyEventTypeFilter = {
  equals?: InputMaybe<VehicleSafetyEventType>;
  in?: InputMaybe<Array<VehicleSafetyEventType>>;
  not?: InputMaybe<EnumVehicleSafetyEventTypeFilter>;
  notIn?: InputMaybe<Array<VehicleSafetyEventType>>;
};

export type EnumVehicleStatusFilter = {
  equals?: InputMaybe<VehicleStatus>;
  in?: InputMaybe<Array<VehicleStatus>>;
  not?: InputMaybe<EnumVehicleStatusFilter>;
  notIn?: InputMaybe<Array<VehicleStatus>>;
};

export type EnumZoneZoneDirectionalityFilter = {
  equals?: InputMaybe<ZoneZoneDirectionality>;
  in?: InputMaybe<Array<ZoneZoneDirectionality>>;
  not?: InputMaybe<EnumZoneZoneDirectionalityFilter>;
  notIn?: InputMaybe<Array<ZoneZoneDirectionality>>;
};

export type FcmSubscriber = {
  __typename?: "FCMSubscriber";
  _count: FcmSubscriberCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  country: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  location?: Maybe<Scalars["String"]["output"]>;
  model: Scalars["String"]["output"];
  notificationTopics?: Maybe<Array<SubscriberNotificationTopic>>;
  os: Scalars["String"]["output"];
  osVersion: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type FcmSubscriberCount = {
  __typename?: "FCMSubscriberCount";
  notificationTopics: Scalars["Int"]["output"];
};

export type FcmSubscriberCreateManyUserInput = {
  country: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  model: Scalars["String"]["input"];
  os: Scalars["String"]["input"];
  osVersion: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FcmSubscriberCreateManyUserInputEnvelope = {
  data: Array<FcmSubscriberCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FcmSubscriberCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<FcmSubscriberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FcmSubscriberCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<FcmSubscriberCreateWithoutUserInput>>;
  createMany?: InputMaybe<FcmSubscriberCreateManyUserInputEnvelope>;
};

export type FcmSubscriberCreateOrConnectWithoutUserInput = {
  create: FcmSubscriberCreateWithoutUserInput;
  where: FcmSubscriberWhereUniqueInput;
};

export type FcmSubscriberCreateWithoutUserInput = {
  country: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  model: Scalars["String"]["input"];
  notificationTopics?: InputMaybe<SubscriberNotificationTopicCreateNestedManyWithoutSubscriberInput>;
  os: Scalars["String"]["input"];
  osVersion: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FcmSubscriberListRelationFilter = {
  every?: InputMaybe<FcmSubscriberWhereInput>;
  none?: InputMaybe<FcmSubscriberWhereInput>;
  some?: InputMaybe<FcmSubscriberWhereInput>;
};

export type FcmSubscriberRelationFilter = {
  is?: InputMaybe<FcmSubscriberWhereInput>;
  isNot?: InputMaybe<FcmSubscriberWhereInput>;
};

export type FcmSubscriberScalarWhereInput = {
  AND?: InputMaybe<Array<FcmSubscriberScalarWhereInput>>;
  NOT?: InputMaybe<Array<FcmSubscriberScalarWhereInput>>;
  OR?: InputMaybe<Array<FcmSubscriberScalarWhereInput>>;
  country?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<StringFilter>;
  model?: InputMaybe<StringFilter>;
  os?: InputMaybe<StringFilter>;
  osVersion?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type FcmSubscriberUpdateManyMutationInput = {
  country?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  os?: InputMaybe<Scalars["String"]["input"]>;
  osVersion?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FcmSubscriberUpdateManyWithWhereWithoutUserInput = {
  data: FcmSubscriberUpdateManyMutationInput;
  where: FcmSubscriberScalarWhereInput;
};

export type FcmSubscriberUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<FcmSubscriberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<FcmSubscriberCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<FcmSubscriberCreateWithoutUserInput>>;
  createMany?: InputMaybe<FcmSubscriberCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<FcmSubscriberWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FcmSubscriberScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FcmSubscriberWhereUniqueInput>>;
  set?: InputMaybe<Array<FcmSubscriberWhereUniqueInput>>;
  update?: InputMaybe<
    Array<FcmSubscriberUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<FcmSubscriberUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<FcmSubscriberUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type FcmSubscriberUpdateWithWhereUniqueWithoutUserInput = {
  data: FcmSubscriberUpdateWithoutUserInput;
  where: FcmSubscriberWhereUniqueInput;
};

export type FcmSubscriberUpdateWithoutUserInput = {
  country?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  notificationTopics?: InputMaybe<SubscriberNotificationTopicUpdateManyWithoutSubscriberNestedInput>;
  os?: InputMaybe<Scalars["String"]["input"]>;
  osVersion?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FcmSubscriberUpsertWithWhereUniqueWithoutUserInput = {
  create: FcmSubscriberCreateWithoutUserInput;
  update: FcmSubscriberUpdateWithoutUserInput;
  where: FcmSubscriberWhereUniqueInput;
};

export type FcmSubscriberWhereInput = {
  AND?: InputMaybe<Array<FcmSubscriberWhereInput>>;
  NOT?: InputMaybe<Array<FcmSubscriberWhereInput>>;
  OR?: InputMaybe<Array<FcmSubscriberWhereInput>>;
  country?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<StringFilter>;
  model?: InputMaybe<StringFilter>;
  notificationTopics?: InputMaybe<SubscriberNotificationTopicListRelationFilter>;
  os?: InputMaybe<StringFilter>;
  osVersion?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type FcmSubscriberWhereUniqueInput = {
  AND?: InputMaybe<Array<FcmSubscriberWhereInput>>;
  NOT?: InputMaybe<Array<FcmSubscriberWhereInput>>;
  OR?: InputMaybe<Array<FcmSubscriberWhereInput>>;
  country?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<StringFilter>;
  model?: InputMaybe<StringFilter>;
  notificationTopics?: InputMaybe<SubscriberNotificationTopicListRelationFilter>;
  os?: InputMaybe<StringFilter>;
  osVersion?: InputMaybe<StringFilter>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type FindAllClassesFilterInput = {
  skip: Scalars["Float"]["input"];
  take: Scalars["Float"]["input"];
};

export type FindAllContactsFilterInput = {
  channel?: InputMaybe<ContactChannel>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  departmentId?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  skip: Scalars["Float"]["input"];
  take: Scalars["Float"]["input"];
};

export type FindAllDepartmentsFilterInput = {
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  skip: Scalars["Float"]["input"];
  take: Scalars["Float"]["input"];
};

export type FindAllSchoolsFilterInput = {
  search?: InputMaybe<Scalars["String"]["input"]>;
  skip: Scalars["Float"]["input"];
  take: Scalars["Float"]["input"];
};

export type FindAllSpecialNeedsFilterInput = {
  skip: Scalars["Float"]["input"];
  take: Scalars["Float"]["input"];
};

export type FindAllUsersFilterInput = {
  search?: InputMaybe<Scalars["String"]["input"]>;
  skip: Scalars["Float"]["input"];
  take: Scalars["Float"]["input"];
};

export type FleetVehicle = {
  __typename?: "FleetVehicle";
  driver?: Maybe<FleetVehicleDriver>;
  gpsStatus: VehicleGpsStatus;
  id: Scalars["ID"]["output"];
  ignitionStatus: VehicleIgnitionStatus;
  lastSeen?: Maybe<Scalars["DateTime"]["output"]>;
  licensePlate?: Maybe<Scalars["String"]["output"]>;
  make?: Maybe<Scalars["String"]["output"]>;
  packageNumber?: Maybe<Scalars["Int"]["output"]>;
  safetyEvents: Array<FleetVehicleSafetyEvent>;
  status: VehicleOperationalStatus;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type FleetVehicleDriver = {
  __typename?: "FleetVehicleDriver";
  firstName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type FleetVehicleSafetyEvent = {
  __typename?: "FleetVehicleSafetyEvent";
  cause: VehicleSafetyEventCause;
  priority: VehicleSafetyEventPriority;
};

export type FleetVehicleWhereInput = {
  packageNumber: Scalars["Int"]["input"];
};

export type FleetVehiclesFilterInput = {
  driverIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  licensePlates?: InputMaybe<Array<Scalars["String"]["input"]>>;
  spIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  gt?: InputMaybe<Scalars["Float"]["input"]>;
  gte?: InputMaybe<Scalars["Float"]["input"]>;
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  lt?: InputMaybe<Scalars["Float"]["input"]>;
  lte?: InputMaybe<Scalars["Float"]["input"]>;
  not?: InputMaybe<FloatFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export type GeoJsonCoordinate = {
  __typename?: "GeoJSONCoordinate";
  coordinates: Array<Array<LatLon>>;
  order: Scalars["Float"]["output"];
};

export type GeoJsonPolygonCoordinatesInput = {
  geojsonContent: Scalars["JSON"]["input"];
};

export type GetBulkServicesWithPriceInput = {
  students: Array<GetServiceWithPriceInput>;
};

export type GetBusAttendantsOrderClause = {
  email?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  submittedAt?: InputMaybe<SortOrder>;
};

export type GetCountryDivisionLevelsInput = {
  countryId: Scalars["String"]["input"];
};

export type GetCustomerManyOutput = {
  __typename?: "GetCustomerManyOutput";
  cashableCredit: Scalars["Float"]["output"];
  creditSpent: Scalars["Float"]["output"];
  currency?: Maybe<CurrencyCode>;
  currentCredit: Scalars["Float"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  nonCashableCredit: Scalars["Float"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrationsCount?: Maybe<Scalars["Float"]["output"]>;
  schoolsCount: Scalars["Int"]["output"];
};

export type GetCustomerOutput = {
  __typename?: "GetCustomerOutput";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  customerCreditInfo: GetCustomerManyOutput;
  id: Scalars["String"]["output"];
  isPrimary: Scalars["Boolean"]["output"];
  passenger: Passenger;
  passengerId: Scalars["String"]["output"];
  relationship?: Maybe<Relationship>;
  sponsor: SponsorWithWithReferenceNumberAndStudents;
  sponsorId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type GetCustomersFilterInput = {
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  schoolIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type GetDriversOrderClause = {
  country?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  homeLocation?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  route?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  submittedAt?: InputMaybe<SortOrder>;
  tagIdNumber?: InputMaybe<SortOrder>;
  tagIdType?: InputMaybe<SortOrder>;
  tagStatus?: InputMaybe<SortOrder>;
};

export type GetSafetyEventsOrderClause = {
  priority?: InputMaybe<SortOrder>;
};

export type GetSchoolRegistrationsFilter = {
  collectionPointIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  internalStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  locationStatus?: InputMaybe<StopLocationStatus>;
  parentPhones?: InputMaybe<Array<Scalars["String"]["input"]>>;
  routeDirectionality?: InputMaybe<RouteDirectionality>;
  routeStatus?: InputMaybe<RegistrationRoutingStatus>;
  schoolIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type GetSchoolRegistrationsInput = {
  activityStatus?: InputMaybe<ActivityStatus>;
  parentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetSchoolRegistrationsOrderClause = {
  className?: InputMaybe<SortOrder>;
  classNameAr?: InputMaybe<SortOrder>;
  countryName?: InputMaybe<SortOrder>;
  countryNameAr?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  firstNameAr?: InputMaybe<SortOrder>;
  installmentPlanName?: InputMaybe<SortOrder>;
  installmentPlanNameAr?: InputMaybe<SortOrder>;
  internalStatus?: InputMaybe<SortOrder>;
  isApproved?: InputMaybe<SortOrder>;
  isToHome?: InputMaybe<SortOrder>;
  isToSchool?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  lastNameAr?: InputMaybe<SortOrder>;
  middleName?: InputMaybe<SortOrder>;
  middleNameAr?: InputMaybe<SortOrder>;
  registeredAt?: InputMaybe<SortOrder>;
  scheduleGroupTitle?: InputMaybe<SortOrder>;
  scheduleGroupTitleAr?: InputMaybe<SortOrder>;
  schoolName?: InputMaybe<SortOrder>;
  schoolNameAr?: InputMaybe<SortOrder>;
  studentCode?: InputMaybe<SortOrder>;
};

export type GetSchoolsOrderClause = {
  countryName?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  schoolCode?: InputMaybe<SortOrder>;
  schoolName?: InputMaybe<SortOrder>;
  semestersCount?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  submittedAt?: InputMaybe<SortOrder>;
  typeName?: InputMaybe<SortOrder>;
};

export type GetServiceProvidersOrderClause = {
  providerName?: InputMaybe<SortOrder>;
};

export type GetServiceWithPriceInput = {
  modifiers: ServicePriceModifiers;
  offerCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
  serviceId?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetSponsorExtraInfoOutput = {
  __typename?: "GetSponsorExtraInfoOutput";
  availableCreditNoteAmount?: Maybe<Scalars["Float"]["output"]>;
  creditNoteHistory?: Maybe<Array<CreditNote>>;
  paymentTransactions?: Maybe<Array<PaymentTransaction>>;
  remainingCashableCreditNoteAmount?: Maybe<Scalars["Float"]["output"]>;
  remainingNonCashableCreditNoteAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type GetTripForBaBdInput = {
  date: Scalars["DateTime"]["input"];
  routeId: Scalars["String"]["input"];
};

export type GetVehiclesOrderClause = {
  capacity?: InputMaybe<SortOrder>;
  chassisNumber?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  groupName?: InputMaybe<SortOrder>;
  licensePlate?: InputMaybe<SortOrder>;
  make?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  packageNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  submittedAt?: InputMaybe<SortOrder>;
  typeName?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export enum IncidentReportType {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

export type InstallmentPaymentPlan = {
  __typename?: "InstallmentPaymentPlan";
  depositAmount: Scalars["Float"]["output"];
  depositAmountWithoutVat?: Maybe<Scalars["Float"]["output"]>;
  depositPaymentDate?: Maybe<Scalars["DateTime"]["output"]>;
  depositType: DepositType;
  discountAmount: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  /** @deprecated in favor of paymentLineItems.amount */
  installmentAmount: Scalars["Float"]["output"];
  /** @deprecated in favor of paymentLineItems.amountWithoutVat */
  installmentAmountWithoutVat?: Maybe<Scalars["Float"]["output"]>;
  installmentsDueDates: Array<Scalars["DateTime"]["output"]>;
  isDefault?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  offerDiscountAmount?: Maybe<Scalars["Float"]["output"]>;
  paymentLineItems: Array<PaymentLineItemForPricing>;
  /** @deprecated in favor of paymentLineItems.dueDate */
  paymentLineItemsDates: Array<PaymentLineItemDate>;
  planAmount: Scalars["Float"]["output"];
  planAmountWithoutVat?: Maybe<Scalars["Float"]["output"]>;
  terms: Array<InstallmentPlanTerms>;
  timePeriods: Array<TimePeriod>;
  vatAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type InstallmentPlan = {
  __typename?: "InstallmentPlan";
  _count: InstallmentPlanCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  collectionTimePeriods?: Maybe<Array<TimePeriod>>;
  createdAt: Scalars["DateTime"]["output"];
  discountPercentage: Scalars["Float"]["output"];
  expiredAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  instalmentPlanTermInstallmentPlan?: Maybe<
    Array<InstalmentPlanTermInstallmentPlan>
  >;
  isDefault?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  offers?: Maybe<Array<InstallmentPlanOffer>>;
  paymentPlanPeriod?: Maybe<PaymentPlanPeriod>;
  paymentPlanPeriodId?: Maybe<Scalars["String"]["output"]>;
  publishedAt: Scalars["DateTime"]["output"];
  refundNoticePeriod?: Maybe<Scalars["Int"]["output"]>;
  schoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  servicePricing?: Maybe<ServicePricing>;
  servicePricingId?: Maybe<Scalars["String"]["output"]>;
  status: Status;
  updatedAt: Scalars["DateTime"]["output"];
};

export type InstallmentPlanCount = {
  __typename?: "InstallmentPlanCount";
  collectionTimePeriods: Scalars["Int"]["output"];
  instalmentPlanTermInstallmentPlan: Scalars["Int"]["output"];
  offers: Scalars["Int"]["output"];
  schoolRegistrations: Scalars["Int"]["output"];
};

export type InstallmentPlanCreateManyPaymentPlanPeriodInput = {
  discountPercentage: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  servicePricingId?: InputMaybe<Scalars["String"]["input"]>;
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanCreateManyPaymentPlanPeriodInputEnvelope = {
  data: Array<InstallmentPlanCreateManyPaymentPlanPeriodInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InstallmentPlanCreateManyServicePricingInput = {
  discountPercentage: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  paymentPlanPeriodId?: InputMaybe<Scalars["String"]["input"]>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanCreateManyServicePricingInputEnvelope = {
  data: Array<InstallmentPlanCreateManyServicePricingInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InstallmentPlanCreateNestedManyWithoutPaymentPlanPeriodInput = {
  connect?: InputMaybe<Array<InstallmentPlanWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<InstallmentPlanCreateOrConnectWithoutPaymentPlanPeriodInput>
  >;
  create?: InputMaybe<
    Array<InstallmentPlanCreateWithoutPaymentPlanPeriodInput>
  >;
  createMany?: InputMaybe<InstallmentPlanCreateManyPaymentPlanPeriodInputEnvelope>;
};

export type InstallmentPlanCreateNestedManyWithoutServicePricingInput = {
  connect?: InputMaybe<Array<InstallmentPlanWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<InstallmentPlanCreateOrConnectWithoutServicePricingInput>
  >;
  create?: InputMaybe<Array<InstallmentPlanCreateWithoutServicePricingInput>>;
  createMany?: InputMaybe<InstallmentPlanCreateManyServicePricingInputEnvelope>;
};

export type InstallmentPlanCreateNestedOneWithoutCollectionTimePeriodsInput = {
  connect?: InputMaybe<InstallmentPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InstallmentPlanCreateOrConnectWithoutCollectionTimePeriodsInput>;
  create?: InputMaybe<InstallmentPlanCreateWithoutCollectionTimePeriodsInput>;
};

export type InstallmentPlanCreateNestedOneWithoutOffersInput = {
  connect?: InputMaybe<InstallmentPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InstallmentPlanCreateOrConnectWithoutOffersInput>;
  create?: InputMaybe<InstallmentPlanCreateWithoutOffersInput>;
};

export type InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput = {
  connect?: InputMaybe<InstallmentPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InstallmentPlanCreateOrConnectWithoutSchoolRegistrationsInput>;
  create?: InputMaybe<InstallmentPlanCreateWithoutSchoolRegistrationsInput>;
};

export type InstallmentPlanCreateOrConnectWithoutCollectionTimePeriodsInput = {
  create: InstallmentPlanCreateWithoutCollectionTimePeriodsInput;
  where: InstallmentPlanWhereUniqueInput;
};

export type InstallmentPlanCreateOrConnectWithoutOffersInput = {
  create: InstallmentPlanCreateWithoutOffersInput;
  where: InstallmentPlanWhereUniqueInput;
};

export type InstallmentPlanCreateOrConnectWithoutPaymentPlanPeriodInput = {
  create: InstallmentPlanCreateWithoutPaymentPlanPeriodInput;
  where: InstallmentPlanWhereUniqueInput;
};

export type InstallmentPlanCreateOrConnectWithoutSchoolRegistrationsInput = {
  create: InstallmentPlanCreateWithoutSchoolRegistrationsInput;
  where: InstallmentPlanWhereUniqueInput;
};

export type InstallmentPlanCreateOrConnectWithoutServicePricingInput = {
  create: InstallmentPlanCreateWithoutServicePricingInput;
  where: InstallmentPlanWhereUniqueInput;
};

export type InstallmentPlanCreateWithoutCollectionTimePeriodsInput = {
  discountPercentage: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanCreateNestedManyWithoutInstallmentPlanInput>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  offers?: InputMaybe<InstallmentPlanOfferCreateNestedManyWithoutInstallmentPlanInput>;
  paymentPlanPeriod?: InputMaybe<PaymentPlanPeriodCreateNestedOneWithoutGroupedInstallmentPlansInput>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutInstallmentPlanInput>;
  servicePricing?: InputMaybe<ServicePricingCreateNestedOneWithoutInstallmentPlanInput>;
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanCreateWithoutOffersInput = {
  collectionTimePeriods?: InputMaybe<TimePeriodCreateNestedManyWithoutInstallmentPlanInput>;
  discountPercentage: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanCreateNestedManyWithoutInstallmentPlanInput>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  paymentPlanPeriod?: InputMaybe<PaymentPlanPeriodCreateNestedOneWithoutGroupedInstallmentPlansInput>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutInstallmentPlanInput>;
  servicePricing?: InputMaybe<ServicePricingCreateNestedOneWithoutInstallmentPlanInput>;
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanCreateWithoutPaymentPlanPeriodInput = {
  collectionTimePeriods?: InputMaybe<TimePeriodCreateNestedManyWithoutInstallmentPlanInput>;
  discountPercentage: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanCreateNestedManyWithoutInstallmentPlanInput>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  offers?: InputMaybe<InstallmentPlanOfferCreateNestedManyWithoutInstallmentPlanInput>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutInstallmentPlanInput>;
  servicePricing?: InputMaybe<ServicePricingCreateNestedOneWithoutInstallmentPlanInput>;
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanCreateWithoutSchoolRegistrationsInput = {
  collectionTimePeriods?: InputMaybe<TimePeriodCreateNestedManyWithoutInstallmentPlanInput>;
  discountPercentage: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanCreateNestedManyWithoutInstallmentPlanInput>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  offers?: InputMaybe<InstallmentPlanOfferCreateNestedManyWithoutInstallmentPlanInput>;
  paymentPlanPeriod?: InputMaybe<PaymentPlanPeriodCreateNestedOneWithoutGroupedInstallmentPlansInput>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  servicePricing?: InputMaybe<ServicePricingCreateNestedOneWithoutInstallmentPlanInput>;
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanCreateWithoutServicePricingInput = {
  collectionTimePeriods?: InputMaybe<TimePeriodCreateNestedManyWithoutInstallmentPlanInput>;
  discountPercentage: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanCreateNestedManyWithoutInstallmentPlanInput>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  offers?: InputMaybe<InstallmentPlanOfferCreateNestedManyWithoutInstallmentPlanInput>;
  paymentPlanPeriod?: InputMaybe<PaymentPlanPeriodCreateNestedOneWithoutGroupedInstallmentPlansInput>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutInstallmentPlanInput>;
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanForSponsor = {
  __typename?: "InstallmentPlanForSponsor";
  discountPercentage: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  status: Status;
  timePeriods: Array<TimePeriodForSponsor>;
};

export type InstallmentPlanListRelationFilter = {
  every?: InputMaybe<InstallmentPlanWhereInput>;
  none?: InputMaybe<InstallmentPlanWhereInput>;
  some?: InputMaybe<InstallmentPlanWhereInput>;
};

export type InstallmentPlanOffer = {
  __typename?: "InstallmentPlanOffer";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  installmentPlan: InstallmentPlan;
  installmentPlanId: Scalars["String"]["output"];
  offer: Offer;
  offerId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type InstallmentPlanOfferCreateManyInstallmentPlanInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  offerId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanOfferCreateManyInstallmentPlanInputEnvelope = {
  data: Array<InstallmentPlanOfferCreateManyInstallmentPlanInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InstallmentPlanOfferCreateManyOfferInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlanId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanOfferCreateManyOfferInputEnvelope = {
  data: Array<InstallmentPlanOfferCreateManyOfferInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InstallmentPlanOfferCreateNestedManyWithoutInstallmentPlanInput = {
  connect?: InputMaybe<Array<InstallmentPlanOfferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<InstallmentPlanOfferCreateOrConnectWithoutInstallmentPlanInput>
  >;
  create?: InputMaybe<
    Array<InstallmentPlanOfferCreateWithoutInstallmentPlanInput>
  >;
  createMany?: InputMaybe<InstallmentPlanOfferCreateManyInstallmentPlanInputEnvelope>;
};

export type InstallmentPlanOfferCreateNestedManyWithoutOfferInput = {
  connect?: InputMaybe<Array<InstallmentPlanOfferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<InstallmentPlanOfferCreateOrConnectWithoutOfferInput>
  >;
  create?: InputMaybe<Array<InstallmentPlanOfferCreateWithoutOfferInput>>;
  createMany?: InputMaybe<InstallmentPlanOfferCreateManyOfferInputEnvelope>;
};

export type InstallmentPlanOfferCreateOrConnectWithoutInstallmentPlanInput = {
  create: InstallmentPlanOfferCreateWithoutInstallmentPlanInput;
  where: InstallmentPlanOfferWhereUniqueInput;
};

export type InstallmentPlanOfferCreateOrConnectWithoutOfferInput = {
  create: InstallmentPlanOfferCreateWithoutOfferInput;
  where: InstallmentPlanOfferWhereUniqueInput;
};

export type InstallmentPlanOfferCreateWithoutInstallmentPlanInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  offer: OfferCreateNestedOneWithoutInstallmentPlansInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanOfferCreateWithoutOfferInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutOffersInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanOfferListRelationFilter = {
  every?: InputMaybe<InstallmentPlanOfferWhereInput>;
  none?: InputMaybe<InstallmentPlanOfferWhereInput>;
  some?: InputMaybe<InstallmentPlanOfferWhereInput>;
};

export type InstallmentPlanOfferScalarWhereInput = {
  AND?: InputMaybe<Array<InstallmentPlanOfferScalarWhereInput>>;
  NOT?: InputMaybe<Array<InstallmentPlanOfferScalarWhereInput>>;
  OR?: InputMaybe<Array<InstallmentPlanOfferScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  offerId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstallmentPlanOfferUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanOfferUpdateManyWithWhereWithoutInstallmentPlanInput =
  {
    data: InstallmentPlanOfferUpdateManyMutationInput;
    where: InstallmentPlanOfferScalarWhereInput;
  };

export type InstallmentPlanOfferUpdateManyWithWhereWithoutOfferInput = {
  data: InstallmentPlanOfferUpdateManyMutationInput;
  where: InstallmentPlanOfferScalarWhereInput;
};

export type InstallmentPlanOfferUpdateManyWithoutInstallmentPlanNestedInput = {
  connect?: InputMaybe<Array<InstallmentPlanOfferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<InstallmentPlanOfferCreateOrConnectWithoutInstallmentPlanInput>
  >;
  create?: InputMaybe<
    Array<InstallmentPlanOfferCreateWithoutInstallmentPlanInput>
  >;
  createMany?: InputMaybe<InstallmentPlanOfferCreateManyInstallmentPlanInputEnvelope>;
  delete?: InputMaybe<Array<InstallmentPlanOfferWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InstallmentPlanOfferScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InstallmentPlanOfferWhereUniqueInput>>;
  set?: InputMaybe<Array<InstallmentPlanOfferWhereUniqueInput>>;
  update?: InputMaybe<
    Array<InstallmentPlanOfferUpdateWithWhereUniqueWithoutInstallmentPlanInput>
  >;
  updateMany?: InputMaybe<
    Array<InstallmentPlanOfferUpdateManyWithWhereWithoutInstallmentPlanInput>
  >;
  upsert?: InputMaybe<
    Array<InstallmentPlanOfferUpsertWithWhereUniqueWithoutInstallmentPlanInput>
  >;
};

export type InstallmentPlanOfferUpdateManyWithoutOfferNestedInput = {
  connect?: InputMaybe<Array<InstallmentPlanOfferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<InstallmentPlanOfferCreateOrConnectWithoutOfferInput>
  >;
  create?: InputMaybe<Array<InstallmentPlanOfferCreateWithoutOfferInput>>;
  createMany?: InputMaybe<InstallmentPlanOfferCreateManyOfferInputEnvelope>;
  delete?: InputMaybe<Array<InstallmentPlanOfferWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InstallmentPlanOfferScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InstallmentPlanOfferWhereUniqueInput>>;
  set?: InputMaybe<Array<InstallmentPlanOfferWhereUniqueInput>>;
  update?: InputMaybe<
    Array<InstallmentPlanOfferUpdateWithWhereUniqueWithoutOfferInput>
  >;
  updateMany?: InputMaybe<
    Array<InstallmentPlanOfferUpdateManyWithWhereWithoutOfferInput>
  >;
  upsert?: InputMaybe<
    Array<InstallmentPlanOfferUpsertWithWhereUniqueWithoutOfferInput>
  >;
};

export type InstallmentPlanOfferUpdateWithWhereUniqueWithoutInstallmentPlanInput =
  {
    data: InstallmentPlanOfferUpdateWithoutInstallmentPlanInput;
    where: InstallmentPlanOfferWhereUniqueInput;
  };

export type InstallmentPlanOfferUpdateWithWhereUniqueWithoutOfferInput = {
  data: InstallmentPlanOfferUpdateWithoutOfferInput;
  where: InstallmentPlanOfferWhereUniqueInput;
};

export type InstallmentPlanOfferUpdateWithoutInstallmentPlanInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  offer?: InputMaybe<OfferUpdateOneRequiredWithoutInstallmentPlansNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanOfferUpdateWithoutOfferInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutOffersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanOfferUpsertWithWhereUniqueWithoutInstallmentPlanInput =
  {
    create: InstallmentPlanOfferCreateWithoutInstallmentPlanInput;
    update: InstallmentPlanOfferUpdateWithoutInstallmentPlanInput;
    where: InstallmentPlanOfferWhereUniqueInput;
  };

export type InstallmentPlanOfferUpsertWithWhereUniqueWithoutOfferInput = {
  create: InstallmentPlanOfferCreateWithoutOfferInput;
  update: InstallmentPlanOfferUpdateWithoutOfferInput;
  where: InstallmentPlanOfferWhereUniqueInput;
};

export type InstallmentPlanOfferWhereInput = {
  AND?: InputMaybe<Array<InstallmentPlanOfferWhereInput>>;
  NOT?: InputMaybe<Array<InstallmentPlanOfferWhereInput>>;
  OR?: InputMaybe<Array<InstallmentPlanOfferWhereInput>>;
  id?: InputMaybe<StringFilter>;
  installmentPlan?: InputMaybe<InstallmentPlanRelationFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  offer?: InputMaybe<OfferRelationFilter>;
  offerId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstallmentPlanOfferWhereUniqueInput = {
  AND?: InputMaybe<Array<InstallmentPlanOfferWhereInput>>;
  NOT?: InputMaybe<Array<InstallmentPlanOfferWhereInput>>;
  OR?: InputMaybe<Array<InstallmentPlanOfferWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanRelationFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  offer?: InputMaybe<OfferRelationFilter>;
  offerId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstallmentPlanRelationFilter = {
  is?: InputMaybe<InstallmentPlanWhereInput>;
  isNot?: InputMaybe<InstallmentPlanWhereInput>;
};

export type InstallmentPlanScalarWhereInput = {
  AND?: InputMaybe<Array<InstallmentPlanScalarWhereInput>>;
  NOT?: InputMaybe<Array<InstallmentPlanScalarWhereInput>>;
  OR?: InputMaybe<Array<InstallmentPlanScalarWhereInput>>;
  discountPercentage?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  paymentPlanPeriodId?: InputMaybe<StringFilter>;
  refundNoticePeriod?: InputMaybe<IntFilter>;
  servicePricingId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstallmentPlanTerm = {
  __typename?: "InstallmentPlanTerm";
  _count: InstallmentPlanTermCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  instalmentPlanTermInstallmentPlan?: Maybe<
    Array<InstalmentPlanTermInstallmentPlan>
  >;
  text: Scalars["String"]["output"];
  textAr: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type InstallmentPlanTermCount = {
  __typename?: "InstallmentPlanTermCount";
  instalmentPlanTermInstallmentPlan: Scalars["Int"]["output"];
};

export type InstallmentPlanTermCreateNestedOneWithoutInstalmentPlanTermInstallmentPlanInput =
  {
    connect?: InputMaybe<InstallmentPlanTermWhereUniqueInput>;
    connectOrCreate?: InputMaybe<InstallmentPlanTermCreateOrConnectWithoutInstalmentPlanTermInstallmentPlanInput>;
    create?: InputMaybe<InstallmentPlanTermCreateWithoutInstalmentPlanTermInstallmentPlanInput>;
  };

export type InstallmentPlanTermCreateOrConnectWithoutInstalmentPlanTermInstallmentPlanInput =
  {
    create: InstallmentPlanTermCreateWithoutInstalmentPlanTermInstallmentPlanInput;
    where: InstallmentPlanTermWhereUniqueInput;
  };

export type InstallmentPlanTermCreateWithoutInstalmentPlanTermInstallmentPlanInput =
  {
    id?: InputMaybe<Scalars["String"]["input"]>;
    text: Scalars["String"]["input"];
    textAr: Scalars["String"]["input"];
    updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  };

export type InstallmentPlanTermRelationFilter = {
  is?: InputMaybe<InstallmentPlanTermWhereInput>;
  isNot?: InputMaybe<InstallmentPlanTermWhereInput>;
};

export type InstallmentPlanTermUpdateOneRequiredWithoutInstalmentPlanTermInstallmentPlanNestedInput =
  {
    connect?: InputMaybe<InstallmentPlanTermWhereUniqueInput>;
    connectOrCreate?: InputMaybe<InstallmentPlanTermCreateOrConnectWithoutInstalmentPlanTermInstallmentPlanInput>;
    create?: InputMaybe<InstallmentPlanTermCreateWithoutInstalmentPlanTermInstallmentPlanInput>;
    update?: InputMaybe<InstallmentPlanTermUpdateToOneWithWhereWithoutInstalmentPlanTermInstallmentPlanInput>;
    upsert?: InputMaybe<InstallmentPlanTermUpsertWithoutInstalmentPlanTermInstallmentPlanInput>;
  };

export type InstallmentPlanTermUpdateToOneWithWhereWithoutInstalmentPlanTermInstallmentPlanInput =
  {
    data: InstallmentPlanTermUpdateWithoutInstalmentPlanTermInstallmentPlanInput;
    where?: InputMaybe<InstallmentPlanTermWhereInput>;
  };

export type InstallmentPlanTermUpdateWithoutInstalmentPlanTermInstallmentPlanInput =
  {
    id?: InputMaybe<Scalars["String"]["input"]>;
    text?: InputMaybe<Scalars["String"]["input"]>;
    textAr?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  };

export type InstallmentPlanTermUpsertWithoutInstalmentPlanTermInstallmentPlanInput =
  {
    create: InstallmentPlanTermCreateWithoutInstalmentPlanTermInstallmentPlanInput;
    update: InstallmentPlanTermUpdateWithoutInstalmentPlanTermInstallmentPlanInput;
    where?: InputMaybe<InstallmentPlanTermWhereInput>;
  };

export type InstallmentPlanTermWhereInput = {
  AND?: InputMaybe<Array<InstallmentPlanTermWhereInput>>;
  NOT?: InputMaybe<Array<InstallmentPlanTermWhereInput>>;
  OR?: InputMaybe<Array<InstallmentPlanTermWhereInput>>;
  id?: InputMaybe<StringFilter>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanListRelationFilter>;
  text?: InputMaybe<StringFilter>;
  textAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstallmentPlanTermWhereUniqueInput = {
  AND?: InputMaybe<Array<InstallmentPlanTermWhereInput>>;
  NOT?: InputMaybe<Array<InstallmentPlanTermWhereInput>>;
  OR?: InputMaybe<Array<InstallmentPlanTermWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanListRelationFilter>;
  text?: InputMaybe<StringFilter>;
  textAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstallmentPlanTerms = {
  __typename?: "InstallmentPlanTerms";
  text: Scalars["String"]["output"];
  textAr: Scalars["String"]["output"];
};

export type InstallmentPlanUpdateManyMutationInput = {
  discountPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanUpdateManyWithWhereWithoutPaymentPlanPeriodInput = {
  data: InstallmentPlanUpdateManyMutationInput;
  where: InstallmentPlanScalarWhereInput;
};

export type InstallmentPlanUpdateManyWithWhereWithoutServicePricingInput = {
  data: InstallmentPlanUpdateManyMutationInput;
  where: InstallmentPlanScalarWhereInput;
};

export type InstallmentPlanUpdateManyWithoutPaymentPlanPeriodNestedInput = {
  connect?: InputMaybe<Array<InstallmentPlanWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<InstallmentPlanCreateOrConnectWithoutPaymentPlanPeriodInput>
  >;
  create?: InputMaybe<
    Array<InstallmentPlanCreateWithoutPaymentPlanPeriodInput>
  >;
  createMany?: InputMaybe<InstallmentPlanCreateManyPaymentPlanPeriodInputEnvelope>;
  delete?: InputMaybe<Array<InstallmentPlanWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InstallmentPlanScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InstallmentPlanWhereUniqueInput>>;
  set?: InputMaybe<Array<InstallmentPlanWhereUniqueInput>>;
  update?: InputMaybe<
    Array<InstallmentPlanUpdateWithWhereUniqueWithoutPaymentPlanPeriodInput>
  >;
  updateMany?: InputMaybe<
    Array<InstallmentPlanUpdateManyWithWhereWithoutPaymentPlanPeriodInput>
  >;
  upsert?: InputMaybe<
    Array<InstallmentPlanUpsertWithWhereUniqueWithoutPaymentPlanPeriodInput>
  >;
};

export type InstallmentPlanUpdateManyWithoutServicePricingNestedInput = {
  connect?: InputMaybe<Array<InstallmentPlanWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<InstallmentPlanCreateOrConnectWithoutServicePricingInput>
  >;
  create?: InputMaybe<Array<InstallmentPlanCreateWithoutServicePricingInput>>;
  createMany?: InputMaybe<InstallmentPlanCreateManyServicePricingInputEnvelope>;
  delete?: InputMaybe<Array<InstallmentPlanWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InstallmentPlanScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InstallmentPlanWhereUniqueInput>>;
  set?: InputMaybe<Array<InstallmentPlanWhereUniqueInput>>;
  update?: InputMaybe<
    Array<InstallmentPlanUpdateWithWhereUniqueWithoutServicePricingInput>
  >;
  updateMany?: InputMaybe<
    Array<InstallmentPlanUpdateManyWithWhereWithoutServicePricingInput>
  >;
  upsert?: InputMaybe<
    Array<InstallmentPlanUpsertWithWhereUniqueWithoutServicePricingInput>
  >;
};

export type InstallmentPlanUpdateOneRequiredWithoutOffersNestedInput = {
  connect?: InputMaybe<InstallmentPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InstallmentPlanCreateOrConnectWithoutOffersInput>;
  create?: InputMaybe<InstallmentPlanCreateWithoutOffersInput>;
  update?: InputMaybe<InstallmentPlanUpdateToOneWithWhereWithoutOffersInput>;
  upsert?: InputMaybe<InstallmentPlanUpsertWithoutOffersInput>;
};

export type InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput =
  {
    connect?: InputMaybe<InstallmentPlanWhereUniqueInput>;
    connectOrCreate?: InputMaybe<InstallmentPlanCreateOrConnectWithoutSchoolRegistrationsInput>;
    create?: InputMaybe<InstallmentPlanCreateWithoutSchoolRegistrationsInput>;
    update?: InputMaybe<InstallmentPlanUpdateToOneWithWhereWithoutSchoolRegistrationsInput>;
    upsert?: InputMaybe<InstallmentPlanUpsertWithoutSchoolRegistrationsInput>;
  };

export type InstallmentPlanUpdateOneWithoutCollectionTimePeriodsNestedInput = {
  connect?: InputMaybe<InstallmentPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InstallmentPlanCreateOrConnectWithoutCollectionTimePeriodsInput>;
  create?: InputMaybe<InstallmentPlanCreateWithoutCollectionTimePeriodsInput>;
  delete?: InputMaybe<InstallmentPlanWhereInput>;
  disconnect?: InputMaybe<InstallmentPlanWhereInput>;
  update?: InputMaybe<InstallmentPlanUpdateToOneWithWhereWithoutCollectionTimePeriodsInput>;
  upsert?: InputMaybe<InstallmentPlanUpsertWithoutCollectionTimePeriodsInput>;
};

export type InstallmentPlanUpdateToOneWithWhereWithoutCollectionTimePeriodsInput =
  {
    data: InstallmentPlanUpdateWithoutCollectionTimePeriodsInput;
    where?: InputMaybe<InstallmentPlanWhereInput>;
  };

export type InstallmentPlanUpdateToOneWithWhereWithoutOffersInput = {
  data: InstallmentPlanUpdateWithoutOffersInput;
  where?: InputMaybe<InstallmentPlanWhereInput>;
};

export type InstallmentPlanUpdateToOneWithWhereWithoutSchoolRegistrationsInput =
  {
    data: InstallmentPlanUpdateWithoutSchoolRegistrationsInput;
    where?: InputMaybe<InstallmentPlanWhereInput>;
  };

export type InstallmentPlanUpdateWithWhereUniqueWithoutPaymentPlanPeriodInput =
  {
    data: InstallmentPlanUpdateWithoutPaymentPlanPeriodInput;
    where: InstallmentPlanWhereUniqueInput;
  };

export type InstallmentPlanUpdateWithWhereUniqueWithoutServicePricingInput = {
  data: InstallmentPlanUpdateWithoutServicePricingInput;
  where: InstallmentPlanWhereUniqueInput;
};

export type InstallmentPlanUpdateWithoutCollectionTimePeriodsInput = {
  discountPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanUpdateManyWithoutInstallmentPlanNestedInput>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  offers?: InputMaybe<InstallmentPlanOfferUpdateManyWithoutInstallmentPlanNestedInput>;
  paymentPlanPeriod?: InputMaybe<PaymentPlanPeriodUpdateOneWithoutGroupedInstallmentPlansNestedInput>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutInstallmentPlanNestedInput>;
  servicePricing?: InputMaybe<ServicePricingUpdateOneWithoutInstallmentPlanNestedInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanUpdateWithoutOffersInput = {
  collectionTimePeriods?: InputMaybe<TimePeriodUpdateManyWithoutInstallmentPlanNestedInput>;
  discountPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanUpdateManyWithoutInstallmentPlanNestedInput>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentPlanPeriod?: InputMaybe<PaymentPlanPeriodUpdateOneWithoutGroupedInstallmentPlansNestedInput>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutInstallmentPlanNestedInput>;
  servicePricing?: InputMaybe<ServicePricingUpdateOneWithoutInstallmentPlanNestedInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanUpdateWithoutPaymentPlanPeriodInput = {
  collectionTimePeriods?: InputMaybe<TimePeriodUpdateManyWithoutInstallmentPlanNestedInput>;
  discountPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanUpdateManyWithoutInstallmentPlanNestedInput>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  offers?: InputMaybe<InstallmentPlanOfferUpdateManyWithoutInstallmentPlanNestedInput>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutInstallmentPlanNestedInput>;
  servicePricing?: InputMaybe<ServicePricingUpdateOneWithoutInstallmentPlanNestedInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanUpdateWithoutSchoolRegistrationsInput = {
  collectionTimePeriods?: InputMaybe<TimePeriodUpdateManyWithoutInstallmentPlanNestedInput>;
  discountPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanUpdateManyWithoutInstallmentPlanNestedInput>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  offers?: InputMaybe<InstallmentPlanOfferUpdateManyWithoutInstallmentPlanNestedInput>;
  paymentPlanPeriod?: InputMaybe<PaymentPlanPeriodUpdateOneWithoutGroupedInstallmentPlansNestedInput>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  servicePricing?: InputMaybe<ServicePricingUpdateOneWithoutInstallmentPlanNestedInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanUpdateWithoutServicePricingInput = {
  collectionTimePeriods?: InputMaybe<TimePeriodUpdateManyWithoutInstallmentPlanNestedInput>;
  discountPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanUpdateManyWithoutInstallmentPlanNestedInput>;
  isDefault?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  offers?: InputMaybe<InstallmentPlanOfferUpdateManyWithoutInstallmentPlanNestedInput>;
  paymentPlanPeriod?: InputMaybe<PaymentPlanPeriodUpdateOneWithoutGroupedInstallmentPlansNestedInput>;
  refundNoticePeriod?: InputMaybe<Scalars["Int"]["input"]>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutInstallmentPlanNestedInput>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstallmentPlanUpsertWithWhereUniqueWithoutPaymentPlanPeriodInput =
  {
    create: InstallmentPlanCreateWithoutPaymentPlanPeriodInput;
    update: InstallmentPlanUpdateWithoutPaymentPlanPeriodInput;
    where: InstallmentPlanWhereUniqueInput;
  };

export type InstallmentPlanUpsertWithWhereUniqueWithoutServicePricingInput = {
  create: InstallmentPlanCreateWithoutServicePricingInput;
  update: InstallmentPlanUpdateWithoutServicePricingInput;
  where: InstallmentPlanWhereUniqueInput;
};

export type InstallmentPlanUpsertWithoutCollectionTimePeriodsInput = {
  create: InstallmentPlanCreateWithoutCollectionTimePeriodsInput;
  update: InstallmentPlanUpdateWithoutCollectionTimePeriodsInput;
  where?: InputMaybe<InstallmentPlanWhereInput>;
};

export type InstallmentPlanUpsertWithoutOffersInput = {
  create: InstallmentPlanCreateWithoutOffersInput;
  update: InstallmentPlanUpdateWithoutOffersInput;
  where?: InputMaybe<InstallmentPlanWhereInput>;
};

export type InstallmentPlanUpsertWithoutSchoolRegistrationsInput = {
  create: InstallmentPlanCreateWithoutSchoolRegistrationsInput;
  update: InstallmentPlanUpdateWithoutSchoolRegistrationsInput;
  where?: InputMaybe<InstallmentPlanWhereInput>;
};

export type InstallmentPlanWhereInput = {
  AND?: InputMaybe<Array<InstallmentPlanWhereInput>>;
  NOT?: InputMaybe<Array<InstallmentPlanWhereInput>>;
  OR?: InputMaybe<Array<InstallmentPlanWhereInput>>;
  collectionTimePeriods?: InputMaybe<TimePeriodListRelationFilter>;
  discountPercentage?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanListRelationFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  offers?: InputMaybe<InstallmentPlanOfferListRelationFilter>;
  paymentPlanPeriod?: InputMaybe<PaymentPlanPeriodRelationFilter>;
  paymentPlanPeriodId?: InputMaybe<StringFilter>;
  refundNoticePeriod?: InputMaybe<IntFilter>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  servicePricing?: InputMaybe<ServicePricingRelationFilter>;
  servicePricingId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstallmentPlanWhereUniqueInput = {
  AND?: InputMaybe<Array<InstallmentPlanWhereInput>>;
  NOT?: InputMaybe<Array<InstallmentPlanWhereInput>>;
  OR?: InputMaybe<Array<InstallmentPlanWhereInput>>;
  collectionTimePeriods?: InputMaybe<TimePeriodListRelationFilter>;
  discountPercentage?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  instalmentPlanTermInstallmentPlan?: InputMaybe<InstalmentPlanTermInstallmentPlanListRelationFilter>;
  isDefault?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  offers?: InputMaybe<InstallmentPlanOfferListRelationFilter>;
  paymentPlanPeriod?: InputMaybe<PaymentPlanPeriodRelationFilter>;
  paymentPlanPeriodId?: InputMaybe<StringFilter>;
  refundNoticePeriod?: InputMaybe<IntFilter>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  servicePricing?: InputMaybe<ServicePricingRelationFilter>;
  servicePricingId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstallmentPlanWithPrice = {
  __typename?: "InstallmentPlanWithPrice";
  _count: InstallmentPlanCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  collectionTimePeriods?: Maybe<Array<TimePeriod>>;
  createdAt: Scalars["DateTime"]["output"];
  depositAmount: Scalars["Float"]["output"];
  depositAmountBeforePlanDiscount: Scalars["Float"]["output"];
  discountPercentage: Scalars["Float"]["output"];
  expiredAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  /** @deprecated in favor of paymentLineItems.amount */
  installmentAmount: Scalars["Float"]["output"];
  installmentAmountBeforePlanDiscount: Scalars["Float"]["output"];
  instalmentPlanTermInstallmentPlan?: Maybe<
    Array<InstalmentPlanTermInstallmentPlan>
  >;
  isDefault?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  offerDiscount: Scalars["Float"]["output"];
  offers?: Maybe<Array<InstallmentPlanOffer>>;
  paymentLineItems: Array<PaymentLineItemForPricing>;
  paymentPlanPeriod?: Maybe<PaymentPlanPeriod>;
  paymentPlanPeriodId?: Maybe<Scalars["String"]["output"]>;
  planAmount: Scalars["Float"]["output"];
  publishedAt: Scalars["DateTime"]["output"];
  refundNoticePeriod?: Maybe<Scalars["Int"]["output"]>;
  schoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  servicePricing?: Maybe<ServicePricing>;
  servicePricingId?: Maybe<Scalars["String"]["output"]>;
  status: Status;
  updatedAt: Scalars["DateTime"]["output"];
};

export type InstallmentPlanWithPriceAndRefund = {
  __typename?: "InstallmentPlanWithPriceAndRefund";
  _count: InstallmentPlanCount;
  amountPaid: Scalars["Float"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  collectionTimePeriods?: Maybe<Array<TimePeriod>>;
  consumedAmount: Scalars["Float"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  depositAmount: Scalars["Float"]["output"];
  depositAmountBeforePlanDiscount: Scalars["Float"]["output"];
  discountPercentage: Scalars["Float"]["output"];
  expiredAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  /** @deprecated in favor of paymentLineItems.amount */
  installmentAmount: Scalars["Float"]["output"];
  installmentAmountBeforePlanDiscount: Scalars["Float"]["output"];
  instalmentPlanTermInstallmentPlan?: Maybe<
    Array<InstalmentPlanTermInstallmentPlan>
  >;
  isDefault?: Maybe<Scalars["Boolean"]["output"]>;
  lastServiceDate: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  offerDiscount: Scalars["Float"]["output"];
  offers?: Maybe<Array<InstallmentPlanOffer>>;
  paymentLineItems: Array<PaymentLineItemForPricing>;
  paymentPlanPeriod?: Maybe<PaymentPlanPeriod>;
  paymentPlanPeriodId?: Maybe<Scalars["String"]["output"]>;
  planAmount: Scalars["Float"]["output"];
  publishedAt: Scalars["DateTime"]["output"];
  refundAmount: Scalars["Float"]["output"];
  refundNoticePeriod?: Maybe<Scalars["Int"]["output"]>;
  schoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  servicePricing?: Maybe<ServicePricing>;
  servicePricingId?: Maybe<Scalars["String"]["output"]>;
  status: Status;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum InstallmentPlansOrderingStrategy {
  INSTALLMENT_AMOUNT = "INSTALLMENT_AMOUNT",
  TOTAL_AMOUNT = "TOTAL_AMOUNT",
}

export type InstalmentPlanTermInstallmentPlan = {
  __typename?: "InstalmentPlanTermInstallmentPlan";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  installmentPlan: InstallmentPlan;
  installmentPlanId: Scalars["String"]["output"];
  installmentPlanTerm: InstallmentPlanTerm;
  installmentPlanTermId: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type InstalmentPlanTermInstallmentPlanCreateManyInstallmentPlanInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlanTermId: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstalmentPlanTermInstallmentPlanCreateManyInstallmentPlanInputEnvelope =
  {
    data: Array<InstalmentPlanTermInstallmentPlanCreateManyInstallmentPlanInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type InstalmentPlanTermInstallmentPlanCreateNestedManyWithoutInstallmentPlanInput =
  {
    connect?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanWhereUniqueInput>
    >;
    connectOrCreate?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanCreateOrConnectWithoutInstallmentPlanInput>
    >;
    create?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanCreateWithoutInstallmentPlanInput>
    >;
    createMany?: InputMaybe<InstalmentPlanTermInstallmentPlanCreateManyInstallmentPlanInputEnvelope>;
  };

export type InstalmentPlanTermInstallmentPlanCreateOrConnectWithoutInstallmentPlanInput =
  {
    create: InstalmentPlanTermInstallmentPlanCreateWithoutInstallmentPlanInput;
    where: InstalmentPlanTermInstallmentPlanWhereUniqueInput;
  };

export type InstalmentPlanTermInstallmentPlanCreateWithoutInstallmentPlanInput =
  {
    id?: InputMaybe<Scalars["String"]["input"]>;
    installmentPlanTerm: InstallmentPlanTermCreateNestedOneWithoutInstalmentPlanTermInstallmentPlanInput;
    order: Scalars["Int"]["input"];
    updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  };

export type InstalmentPlanTermInstallmentPlanInstallmentPlanIdInstallmentPlanTermIdCompoundUniqueInput =
  {
    installmentPlanId: Scalars["String"]["input"];
    installmentPlanTermId: Scalars["String"]["input"];
  };

export type InstalmentPlanTermInstallmentPlanListRelationFilter = {
  every?: InputMaybe<InstalmentPlanTermInstallmentPlanWhereInput>;
  none?: InputMaybe<InstalmentPlanTermInstallmentPlanWhereInput>;
  some?: InputMaybe<InstalmentPlanTermInstallmentPlanWhereInput>;
};

export type InstalmentPlanTermInstallmentPlanScalarWhereInput = {
  AND?: InputMaybe<Array<InstalmentPlanTermInstallmentPlanScalarWhereInput>>;
  NOT?: InputMaybe<Array<InstalmentPlanTermInstallmentPlanScalarWhereInput>>;
  OR?: InputMaybe<Array<InstalmentPlanTermInstallmentPlanScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  installmentPlanTermId?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstalmentPlanTermInstallmentPlanUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InstalmentPlanTermInstallmentPlanUpdateManyWithWhereWithoutInstallmentPlanInput =
  {
    data: InstalmentPlanTermInstallmentPlanUpdateManyMutationInput;
    where: InstalmentPlanTermInstallmentPlanScalarWhereInput;
  };

export type InstalmentPlanTermInstallmentPlanUpdateManyWithoutInstallmentPlanNestedInput =
  {
    connect?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanWhereUniqueInput>
    >;
    connectOrCreate?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanCreateOrConnectWithoutInstallmentPlanInput>
    >;
    create?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanCreateWithoutInstallmentPlanInput>
    >;
    createMany?: InputMaybe<InstalmentPlanTermInstallmentPlanCreateManyInstallmentPlanInputEnvelope>;
    delete?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanWhereUniqueInput>
    >;
    deleteMany?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanScalarWhereInput>
    >;
    disconnect?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanWhereUniqueInput>
    >;
    set?: InputMaybe<Array<InstalmentPlanTermInstallmentPlanWhereUniqueInput>>;
    update?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanUpdateWithWhereUniqueWithoutInstallmentPlanInput>
    >;
    updateMany?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanUpdateManyWithWhereWithoutInstallmentPlanInput>
    >;
    upsert?: InputMaybe<
      Array<InstalmentPlanTermInstallmentPlanUpsertWithWhereUniqueWithoutInstallmentPlanInput>
    >;
  };

export type InstalmentPlanTermInstallmentPlanUpdateWithWhereUniqueWithoutInstallmentPlanInput =
  {
    data: InstalmentPlanTermInstallmentPlanUpdateWithoutInstallmentPlanInput;
    where: InstalmentPlanTermInstallmentPlanWhereUniqueInput;
  };

export type InstalmentPlanTermInstallmentPlanUpdateWithoutInstallmentPlanInput =
  {
    id?: InputMaybe<Scalars["String"]["input"]>;
    installmentPlanTerm?: InputMaybe<InstallmentPlanTermUpdateOneRequiredWithoutInstalmentPlanTermInstallmentPlanNestedInput>;
    order?: InputMaybe<Scalars["Int"]["input"]>;
    updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  };

export type InstalmentPlanTermInstallmentPlanUpsertWithWhereUniqueWithoutInstallmentPlanInput =
  {
    create: InstalmentPlanTermInstallmentPlanCreateWithoutInstallmentPlanInput;
    update: InstalmentPlanTermInstallmentPlanUpdateWithoutInstallmentPlanInput;
    where: InstalmentPlanTermInstallmentPlanWhereUniqueInput;
  };

export type InstalmentPlanTermInstallmentPlanWhereInput = {
  AND?: InputMaybe<Array<InstalmentPlanTermInstallmentPlanWhereInput>>;
  NOT?: InputMaybe<Array<InstalmentPlanTermInstallmentPlanWhereInput>>;
  OR?: InputMaybe<Array<InstalmentPlanTermInstallmentPlanWhereInput>>;
  id?: InputMaybe<StringFilter>;
  installmentPlan?: InputMaybe<InstallmentPlanRelationFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  installmentPlanTerm?: InputMaybe<InstallmentPlanTermRelationFilter>;
  installmentPlanTermId?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstalmentPlanTermInstallmentPlanWhereUniqueInput = {
  AND?: InputMaybe<Array<InstalmentPlanTermInstallmentPlanWhereInput>>;
  NOT?: InputMaybe<Array<InstalmentPlanTermInstallmentPlanWhereInput>>;
  OR?: InputMaybe<Array<InstalmentPlanTermInstallmentPlanWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanRelationFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  installmentPlanId_installmentPlanTermId?: InputMaybe<InstalmentPlanTermInstallmentPlanInstallmentPlanIdInstallmentPlanTermIdCompoundUniqueInput>;
  installmentPlanTerm?: InputMaybe<InstallmentPlanTermRelationFilter>;
  installmentPlanTermId?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<IntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type IntListFilter = {
  equals?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  has?: InputMaybe<Scalars["Int"]["input"]>;
  hasEvery?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  hasSome?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  isEmpty?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type JwtObj = {
  __typename?: "JWTObj";
  accessToken: Scalars["String"]["output"];
  refreshToken: Scalars["String"]["output"];
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum Language {
  AR = "AR",
  EN = "EN",
}

export type LatLon = {
  __typename?: "LatLon";
  lat?: Maybe<Scalars["Float"]["output"]>;
  lon?: Maybe<Scalars["Float"]["output"]>;
};

export type LatLonInput = {
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
};

export type LatestVehicleEventObjectType = {
  __typename?: "LatestVehicleEventObjectType";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  heading?: Maybe<Scalars["Float"]["output"]>;
  humidity?: Maybe<Scalars["Float"]["output"]>;
  ignition?: Maybe<Scalars["Boolean"]["output"]>;
  lastSeen?: Maybe<Scalars["DateTime"]["output"]>;
  latitude?: Maybe<Scalars["Float"]["output"]>;
  locationPrecision?: Maybe<Scalars["Float"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
  packageNumber?: Maybe<Scalars["Float"]["output"]>;
  speed?: Maybe<Scalars["Float"]["output"]>;
  temperature?: Maybe<Scalars["Float"]["output"]>;
};

export type LeaveRequest = {
  __typename?: "LeaveRequest";
  _count: LeaveRequestCount;
  agent?: Maybe<User>;
  agentUserId?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  cancelledAt?: Maybe<Scalars["DateTime"]["output"]>;
  cancelledLeaveRequests?: Maybe<Array<CancelledLeaveRequest>>;
  createdAt: Scalars["DateTime"]["output"];
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  lastUpdateAgentUser?: Maybe<User>;
  lastUpdateAgentUserId?: Maybe<Scalars["String"]["output"]>;
  lastUpdateSponsorUser?: Maybe<User>;
  lastUpdateSponsorUserId?: Maybe<Scalars["String"]["output"]>;
  leaveRequestSubmission?: Maybe<LeaveRequestSubmission>;
  leaveRequestSubmissionId?: Maybe<Scalars["String"]["output"]>;
  otherRequestChannel?: Maybe<Scalars["String"]["output"]>;
  requestChannel: LeaveRequestChannel;
  route: Route;
  routeId: Scalars["String"]["output"];
  schoolRegistration: SchoolRegistration;
  schoolRegistrationId: Scalars["String"]["output"];
  sponsor: User;
  sponsorUserId: Scalars["String"]["output"];
  startDate: Scalars["DateTime"]["output"];
  status: LeaveRequestStatus;
  submittedAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export enum LeaveRequestChannel {
  OTHER = "OTHER",
  PARENT_APP = "PARENT_APP",
  PHONE_CALL = "PHONE_CALL",
  WHATSAPP = "WHATSAPP",
}

export type LeaveRequestCount = {
  __typename?: "LeaveRequestCount";
  cancelledLeaveRequests: Scalars["Int"]["output"];
};

export type LeaveRequestCreateManyAgentInput = {
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUserId?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateSponsorUserId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequestSubmissionId?: InputMaybe<Scalars["String"]["input"]>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  routeId: Scalars["String"]["input"];
  schoolRegistrationId: Scalars["String"]["input"];
  sponsorUserId: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestCreateManyAgentInputEnvelope = {
  data: Array<LeaveRequestCreateManyAgentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LeaveRequestCreateManyLastUpdateAgentUserInput = {
  agentUserId?: InputMaybe<Scalars["String"]["input"]>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateSponsorUserId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequestSubmissionId?: InputMaybe<Scalars["String"]["input"]>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  routeId: Scalars["String"]["input"];
  schoolRegistrationId: Scalars["String"]["input"];
  sponsorUserId: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestCreateManyLastUpdateAgentUserInputEnvelope = {
  data: Array<LeaveRequestCreateManyLastUpdateAgentUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LeaveRequestCreateManyLastUpdateSponsorUserInput = {
  agentUserId?: InputMaybe<Scalars["String"]["input"]>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUserId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequestSubmissionId?: InputMaybe<Scalars["String"]["input"]>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  routeId: Scalars["String"]["input"];
  schoolRegistrationId: Scalars["String"]["input"];
  sponsorUserId: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestCreateManyLastUpdateSponsorUserInputEnvelope = {
  data: Array<LeaveRequestCreateManyLastUpdateSponsorUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LeaveRequestCreateManyRouteInput = {
  agentUserId?: InputMaybe<Scalars["String"]["input"]>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUserId?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateSponsorUserId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequestSubmissionId?: InputMaybe<Scalars["String"]["input"]>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  schoolRegistrationId: Scalars["String"]["input"];
  sponsorUserId: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestCreateManyRouteInputEnvelope = {
  data: Array<LeaveRequestCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LeaveRequestCreateManySchoolRegistrationInput = {
  agentUserId?: InputMaybe<Scalars["String"]["input"]>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUserId?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateSponsorUserId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequestSubmissionId?: InputMaybe<Scalars["String"]["input"]>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  routeId: Scalars["String"]["input"];
  sponsorUserId: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestCreateManySchoolRegistrationInputEnvelope = {
  data: Array<LeaveRequestCreateManySchoolRegistrationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LeaveRequestCreateManySponsorInput = {
  agentUserId?: InputMaybe<Scalars["String"]["input"]>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUserId?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateSponsorUserId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequestSubmissionId?: InputMaybe<Scalars["String"]["input"]>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  routeId: Scalars["String"]["input"];
  schoolRegistrationId: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestCreateManySponsorInputEnvelope = {
  data: Array<LeaveRequestCreateManySponsorInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LeaveRequestCreateNestedManyWithoutAgentInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutAgentInput>
  >;
  create?: InputMaybe<Array<LeaveRequestCreateWithoutAgentInput>>;
  createMany?: InputMaybe<LeaveRequestCreateManyAgentInputEnvelope>;
};

export type LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutLastUpdateAgentUserInput>
  >;
  create?: InputMaybe<Array<LeaveRequestCreateWithoutLastUpdateAgentUserInput>>;
  createMany?: InputMaybe<LeaveRequestCreateManyLastUpdateAgentUserInputEnvelope>;
};

export type LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutLastUpdateSponsorUserInput>
  >;
  create?: InputMaybe<
    Array<LeaveRequestCreateWithoutLastUpdateSponsorUserInput>
  >;
  createMany?: InputMaybe<LeaveRequestCreateManyLastUpdateSponsorUserInputEnvelope>;
};

export type LeaveRequestCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<LeaveRequestCreateWithoutRouteInput>>;
  createMany?: InputMaybe<LeaveRequestCreateManyRouteInputEnvelope>;
};

export type LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutSchoolRegistrationInput>
  >;
  create?: InputMaybe<Array<LeaveRequestCreateWithoutSchoolRegistrationInput>>;
  createMany?: InputMaybe<LeaveRequestCreateManySchoolRegistrationInputEnvelope>;
};

export type LeaveRequestCreateNestedManyWithoutSponsorInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutSponsorInput>
  >;
  create?: InputMaybe<Array<LeaveRequestCreateWithoutSponsorInput>>;
  createMany?: InputMaybe<LeaveRequestCreateManySponsorInputEnvelope>;
};

export type LeaveRequestCreateOrConnectWithoutAgentInput = {
  create: LeaveRequestCreateWithoutAgentInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestCreateOrConnectWithoutLastUpdateAgentUserInput = {
  create: LeaveRequestCreateWithoutLastUpdateAgentUserInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestCreateOrConnectWithoutLastUpdateSponsorUserInput = {
  create: LeaveRequestCreateWithoutLastUpdateSponsorUserInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestCreateOrConnectWithoutRouteInput = {
  create: LeaveRequestCreateWithoutRouteInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestCreateOrConnectWithoutSchoolRegistrationInput = {
  create: LeaveRequestCreateWithoutSchoolRegistrationInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestCreateOrConnectWithoutSponsorInput = {
  create: LeaveRequestCreateWithoutSponsorInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestCreateWithoutAgentInput = {
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestCreateNestedManyWithoutLeaveRequestInput>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUser?: InputMaybe<UserCreateNestedOneWithoutAgentModifiedLeaveRequestsInput>;
  lastUpdateSponsorUser?: InputMaybe<UserCreateNestedOneWithoutSponsorModifiedLeaveRequestsInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionCreateNestedOneWithoutLeaveRequestsInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  route: RouteCreateNestedOneWithoutLeaveRequestsInput;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutLeaveRequestsInput;
  sponsor: UserCreateNestedOneWithoutSponsorLeaveRequestsInput;
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestCreateWithoutLastUpdateAgentUserInput = {
  agent?: InputMaybe<UserCreateNestedOneWithoutAgentLeaveRequestsInput>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestCreateNestedManyWithoutLeaveRequestInput>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateSponsorUser?: InputMaybe<UserCreateNestedOneWithoutSponsorModifiedLeaveRequestsInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionCreateNestedOneWithoutLeaveRequestsInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  route: RouteCreateNestedOneWithoutLeaveRequestsInput;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutLeaveRequestsInput;
  sponsor: UserCreateNestedOneWithoutSponsorLeaveRequestsInput;
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestCreateWithoutLastUpdateSponsorUserInput = {
  agent?: InputMaybe<UserCreateNestedOneWithoutAgentLeaveRequestsInput>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestCreateNestedManyWithoutLeaveRequestInput>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUser?: InputMaybe<UserCreateNestedOneWithoutAgentModifiedLeaveRequestsInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionCreateNestedOneWithoutLeaveRequestsInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  route: RouteCreateNestedOneWithoutLeaveRequestsInput;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutLeaveRequestsInput;
  sponsor: UserCreateNestedOneWithoutSponsorLeaveRequestsInput;
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestCreateWithoutRouteInput = {
  agent?: InputMaybe<UserCreateNestedOneWithoutAgentLeaveRequestsInput>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestCreateNestedManyWithoutLeaveRequestInput>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUser?: InputMaybe<UserCreateNestedOneWithoutAgentModifiedLeaveRequestsInput>;
  lastUpdateSponsorUser?: InputMaybe<UserCreateNestedOneWithoutSponsorModifiedLeaveRequestsInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionCreateNestedOneWithoutLeaveRequestsInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutLeaveRequestsInput;
  sponsor: UserCreateNestedOneWithoutSponsorLeaveRequestsInput;
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestCreateWithoutSchoolRegistrationInput = {
  agent?: InputMaybe<UserCreateNestedOneWithoutAgentLeaveRequestsInput>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestCreateNestedManyWithoutLeaveRequestInput>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUser?: InputMaybe<UserCreateNestedOneWithoutAgentModifiedLeaveRequestsInput>;
  lastUpdateSponsorUser?: InputMaybe<UserCreateNestedOneWithoutSponsorModifiedLeaveRequestsInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionCreateNestedOneWithoutLeaveRequestsInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  route: RouteCreateNestedOneWithoutLeaveRequestsInput;
  sponsor: UserCreateNestedOneWithoutSponsorLeaveRequestsInput;
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestCreateWithoutSponsorInput = {
  agent?: InputMaybe<UserCreateNestedOneWithoutAgentLeaveRequestsInput>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestCreateNestedManyWithoutLeaveRequestInput>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUser?: InputMaybe<UserCreateNestedOneWithoutAgentModifiedLeaveRequestsInput>;
  lastUpdateSponsorUser?: InputMaybe<UserCreateNestedOneWithoutSponsorModifiedLeaveRequestsInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionCreateNestedOneWithoutLeaveRequestsInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel: LeaveRequestChannel;
  route: RouteCreateNestedOneWithoutLeaveRequestsInput;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutLeaveRequestsInput;
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestDetailsForSponsor = {
  __typename?: "LeaveRequestDetailsForSponsor";
  cancelledBy?: Maybe<Scalars["String"]["output"]>;
  cancelledEndDate?: Maybe<Scalars["DateTime"]["output"]>;
  cancelledOn?: Maybe<Scalars["DateTime"]["output"]>;
  cancelledStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  onBehalfBy?: Maybe<Scalars["String"]["output"]>;
  routeDirectionality: Scalars["String"]["output"];
  startDate: Scalars["DateTime"]["output"];
  status: LeaveRequestStatus;
  studentName: Scalars["String"]["output"];
  submittedAt: Scalars["DateTime"]["output"];
  submittedBy: Scalars["String"]["output"];
};

export type LeaveRequestForSponsor = {
  __typename?: "LeaveRequestForSponsor";
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  routeDirectionality: Scalars["String"]["output"];
  startDate: Scalars["DateTime"]["output"];
  status: LeaveRequestStatus;
};

export type LeaveRequestListRelationFilter = {
  every?: InputMaybe<LeaveRequestWhereInput>;
  none?: InputMaybe<LeaveRequestWhereInput>;
  some?: InputMaybe<LeaveRequestWhereInput>;
};

export type LeaveRequestRelationFilter = {
  is?: InputMaybe<LeaveRequestWhereInput>;
  isNot?: InputMaybe<LeaveRequestWhereInput>;
};

export type LeaveRequestScalarWhereInput = {
  AND?: InputMaybe<Array<LeaveRequestScalarWhereInput>>;
  NOT?: InputMaybe<Array<LeaveRequestScalarWhereInput>>;
  OR?: InputMaybe<Array<LeaveRequestScalarWhereInput>>;
  agentUserId?: InputMaybe<StringFilter>;
  cancelledAt?: InputMaybe<DateTimeFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lastUpdateAgentUserId?: InputMaybe<StringFilter>;
  lastUpdateSponsorUserId?: InputMaybe<StringFilter>;
  leaveRequestSubmissionId?: InputMaybe<StringFilter>;
  otherRequestChannel?: InputMaybe<StringFilter>;
  requestChannel?: InputMaybe<EnumLeaveRequestChannelFilter>;
  routeId?: InputMaybe<StringFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  sponsorUserId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumLeaveRequestStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum LeaveRequestStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  ON_LEAVE = "ON_LEAVE",
  UPCOMING = "UPCOMING",
}

export type LeaveRequestSubmission = {
  __typename?: "LeaveRequestSubmission";
  _count: LeaveRequestSubmissionCount;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  leaveRequests?: Maybe<Array<LeaveRequest>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type LeaveRequestSubmissionCount = {
  __typename?: "LeaveRequestSubmissionCount";
  leaveRequests: Scalars["Int"]["output"];
};

export type LeaveRequestSubmissionCreateNestedOneWithoutLeaveRequestsInput = {
  connect?: InputMaybe<LeaveRequestSubmissionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeaveRequestSubmissionCreateOrConnectWithoutLeaveRequestsInput>;
  create?: InputMaybe<LeaveRequestSubmissionCreateWithoutLeaveRequestsInput>;
};

export type LeaveRequestSubmissionCreateOrConnectWithoutLeaveRequestsInput = {
  create: LeaveRequestSubmissionCreateWithoutLeaveRequestsInput;
  where: LeaveRequestSubmissionWhereUniqueInput;
};

export type LeaveRequestSubmissionCreateWithoutLeaveRequestsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestSubmissionRelationFilter = {
  is?: InputMaybe<LeaveRequestSubmissionWhereInput>;
  isNot?: InputMaybe<LeaveRequestSubmissionWhereInput>;
};

export type LeaveRequestSubmissionUpdateOneWithoutLeaveRequestsNestedInput = {
  connect?: InputMaybe<LeaveRequestSubmissionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeaveRequestSubmissionCreateOrConnectWithoutLeaveRequestsInput>;
  create?: InputMaybe<LeaveRequestSubmissionCreateWithoutLeaveRequestsInput>;
  delete?: InputMaybe<LeaveRequestSubmissionWhereInput>;
  disconnect?: InputMaybe<LeaveRequestSubmissionWhereInput>;
  update?: InputMaybe<LeaveRequestSubmissionUpdateToOneWithWhereWithoutLeaveRequestsInput>;
  upsert?: InputMaybe<LeaveRequestSubmissionUpsertWithoutLeaveRequestsInput>;
};

export type LeaveRequestSubmissionUpdateToOneWithWhereWithoutLeaveRequestsInput =
  {
    data: LeaveRequestSubmissionUpdateWithoutLeaveRequestsInput;
    where?: InputMaybe<LeaveRequestSubmissionWhereInput>;
  };

export type LeaveRequestSubmissionUpdateWithoutLeaveRequestsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestSubmissionUpsertWithoutLeaveRequestsInput = {
  create: LeaveRequestSubmissionCreateWithoutLeaveRequestsInput;
  update: LeaveRequestSubmissionUpdateWithoutLeaveRequestsInput;
  where?: InputMaybe<LeaveRequestSubmissionWhereInput>;
};

export type LeaveRequestSubmissionWhereInput = {
  AND?: InputMaybe<Array<LeaveRequestSubmissionWhereInput>>;
  NOT?: InputMaybe<Array<LeaveRequestSubmissionWhereInput>>;
  OR?: InputMaybe<Array<LeaveRequestSubmissionWhereInput>>;
  id?: InputMaybe<StringFilter>;
  leaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LeaveRequestSubmissionWhereUniqueInput = {
  AND?: InputMaybe<Array<LeaveRequestSubmissionWhereInput>>;
  NOT?: InputMaybe<Array<LeaveRequestSubmissionWhereInput>>;
  OR?: InputMaybe<Array<LeaveRequestSubmissionWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LeaveRequestUpdateManyMutationInput = {
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel?: InputMaybe<LeaveRequestChannel>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestUpdateManyWithWhereWithoutAgentInput = {
  data: LeaveRequestUpdateManyMutationInput;
  where: LeaveRequestScalarWhereInput;
};

export type LeaveRequestUpdateManyWithWhereWithoutLastUpdateAgentUserInput = {
  data: LeaveRequestUpdateManyMutationInput;
  where: LeaveRequestScalarWhereInput;
};

export type LeaveRequestUpdateManyWithWhereWithoutLastUpdateSponsorUserInput = {
  data: LeaveRequestUpdateManyMutationInput;
  where: LeaveRequestScalarWhereInput;
};

export type LeaveRequestUpdateManyWithWhereWithoutRouteInput = {
  data: LeaveRequestUpdateManyMutationInput;
  where: LeaveRequestScalarWhereInput;
};

export type LeaveRequestUpdateManyWithWhereWithoutSchoolRegistrationInput = {
  data: LeaveRequestUpdateManyMutationInput;
  where: LeaveRequestScalarWhereInput;
};

export type LeaveRequestUpdateManyWithWhereWithoutSponsorInput = {
  data: LeaveRequestUpdateManyMutationInput;
  where: LeaveRequestScalarWhereInput;
};

export type LeaveRequestUpdateManyWithoutAgentNestedInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutAgentInput>
  >;
  create?: InputMaybe<Array<LeaveRequestCreateWithoutAgentInput>>;
  createMany?: InputMaybe<LeaveRequestCreateManyAgentInputEnvelope>;
  delete?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeaveRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LeaveRequestUpdateWithWhereUniqueWithoutAgentInput>
  >;
  updateMany?: InputMaybe<
    Array<LeaveRequestUpdateManyWithWhereWithoutAgentInput>
  >;
  upsert?: InputMaybe<
    Array<LeaveRequestUpsertWithWhereUniqueWithoutAgentInput>
  >;
};

export type LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutLastUpdateAgentUserInput>
  >;
  create?: InputMaybe<Array<LeaveRequestCreateWithoutLastUpdateAgentUserInput>>;
  createMany?: InputMaybe<LeaveRequestCreateManyLastUpdateAgentUserInputEnvelope>;
  delete?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeaveRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LeaveRequestUpdateWithWhereUniqueWithoutLastUpdateAgentUserInput>
  >;
  updateMany?: InputMaybe<
    Array<LeaveRequestUpdateManyWithWhereWithoutLastUpdateAgentUserInput>
  >;
  upsert?: InputMaybe<
    Array<LeaveRequestUpsertWithWhereUniqueWithoutLastUpdateAgentUserInput>
  >;
};

export type LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutLastUpdateSponsorUserInput>
  >;
  create?: InputMaybe<
    Array<LeaveRequestCreateWithoutLastUpdateSponsorUserInput>
  >;
  createMany?: InputMaybe<LeaveRequestCreateManyLastUpdateSponsorUserInputEnvelope>;
  delete?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeaveRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LeaveRequestUpdateWithWhereUniqueWithoutLastUpdateSponsorUserInput>
  >;
  updateMany?: InputMaybe<
    Array<LeaveRequestUpdateManyWithWhereWithoutLastUpdateSponsorUserInput>
  >;
  upsert?: InputMaybe<
    Array<LeaveRequestUpsertWithWhereUniqueWithoutLastUpdateSponsorUserInput>
  >;
};

export type LeaveRequestUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<LeaveRequestCreateWithoutRouteInput>>;
  createMany?: InputMaybe<LeaveRequestCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeaveRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LeaveRequestUpdateWithWhereUniqueWithoutRouteInput>
  >;
  updateMany?: InputMaybe<
    Array<LeaveRequestUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<
    Array<LeaveRequestUpsertWithWhereUniqueWithoutRouteInput>
  >;
};

export type LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutSchoolRegistrationInput>
  >;
  create?: InputMaybe<Array<LeaveRequestCreateWithoutSchoolRegistrationInput>>;
  createMany?: InputMaybe<LeaveRequestCreateManySchoolRegistrationInputEnvelope>;
  delete?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeaveRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LeaveRequestUpdateWithWhereUniqueWithoutSchoolRegistrationInput>
  >;
  updateMany?: InputMaybe<
    Array<LeaveRequestUpdateManyWithWhereWithoutSchoolRegistrationInput>
  >;
  upsert?: InputMaybe<
    Array<LeaveRequestUpsertWithWhereUniqueWithoutSchoolRegistrationInput>
  >;
};

export type LeaveRequestUpdateManyWithoutSponsorNestedInput = {
  connect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LeaveRequestCreateOrConnectWithoutSponsorInput>
  >;
  create?: InputMaybe<Array<LeaveRequestCreateWithoutSponsorInput>>;
  createMany?: InputMaybe<LeaveRequestCreateManySponsorInputEnvelope>;
  delete?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeaveRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<LeaveRequestWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LeaveRequestUpdateWithWhereUniqueWithoutSponsorInput>
  >;
  updateMany?: InputMaybe<
    Array<LeaveRequestUpdateManyWithWhereWithoutSponsorInput>
  >;
  upsert?: InputMaybe<
    Array<LeaveRequestUpsertWithWhereUniqueWithoutSponsorInput>
  >;
};

export type LeaveRequestUpdateWithWhereUniqueWithoutAgentInput = {
  data: LeaveRequestUpdateWithoutAgentInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestUpdateWithWhereUniqueWithoutLastUpdateAgentUserInput = {
  data: LeaveRequestUpdateWithoutLastUpdateAgentUserInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestUpdateWithWhereUniqueWithoutLastUpdateSponsorUserInput =
  {
    data: LeaveRequestUpdateWithoutLastUpdateSponsorUserInput;
    where: LeaveRequestWhereUniqueInput;
  };

export type LeaveRequestUpdateWithWhereUniqueWithoutRouteInput = {
  data: LeaveRequestUpdateWithoutRouteInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestUpdateWithWhereUniqueWithoutSchoolRegistrationInput = {
  data: LeaveRequestUpdateWithoutSchoolRegistrationInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestUpdateWithWhereUniqueWithoutSponsorInput = {
  data: LeaveRequestUpdateWithoutSponsorInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestUpdateWithoutAgentInput = {
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestUpdateManyWithoutLeaveRequestNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUser?: InputMaybe<UserUpdateOneWithoutAgentModifiedLeaveRequestsNestedInput>;
  lastUpdateSponsorUser?: InputMaybe<UserUpdateOneWithoutSponsorModifiedLeaveRequestsNestedInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionUpdateOneWithoutLeaveRequestsNestedInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel?: InputMaybe<LeaveRequestChannel>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutLeaveRequestsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutLeaveRequestsNestedInput>;
  sponsor?: InputMaybe<UserUpdateOneRequiredWithoutSponsorLeaveRequestsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestUpdateWithoutLastUpdateAgentUserInput = {
  agent?: InputMaybe<UserUpdateOneWithoutAgentLeaveRequestsNestedInput>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestUpdateManyWithoutLeaveRequestNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateSponsorUser?: InputMaybe<UserUpdateOneWithoutSponsorModifiedLeaveRequestsNestedInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionUpdateOneWithoutLeaveRequestsNestedInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel?: InputMaybe<LeaveRequestChannel>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutLeaveRequestsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutLeaveRequestsNestedInput>;
  sponsor?: InputMaybe<UserUpdateOneRequiredWithoutSponsorLeaveRequestsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestUpdateWithoutLastUpdateSponsorUserInput = {
  agent?: InputMaybe<UserUpdateOneWithoutAgentLeaveRequestsNestedInput>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestUpdateManyWithoutLeaveRequestNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUser?: InputMaybe<UserUpdateOneWithoutAgentModifiedLeaveRequestsNestedInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionUpdateOneWithoutLeaveRequestsNestedInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel?: InputMaybe<LeaveRequestChannel>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutLeaveRequestsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutLeaveRequestsNestedInput>;
  sponsor?: InputMaybe<UserUpdateOneRequiredWithoutSponsorLeaveRequestsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestUpdateWithoutRouteInput = {
  agent?: InputMaybe<UserUpdateOneWithoutAgentLeaveRequestsNestedInput>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestUpdateManyWithoutLeaveRequestNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUser?: InputMaybe<UserUpdateOneWithoutAgentModifiedLeaveRequestsNestedInput>;
  lastUpdateSponsorUser?: InputMaybe<UserUpdateOneWithoutSponsorModifiedLeaveRequestsNestedInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionUpdateOneWithoutLeaveRequestsNestedInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel?: InputMaybe<LeaveRequestChannel>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutLeaveRequestsNestedInput>;
  sponsor?: InputMaybe<UserUpdateOneRequiredWithoutSponsorLeaveRequestsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestUpdateWithoutSchoolRegistrationInput = {
  agent?: InputMaybe<UserUpdateOneWithoutAgentLeaveRequestsNestedInput>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestUpdateManyWithoutLeaveRequestNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUser?: InputMaybe<UserUpdateOneWithoutAgentModifiedLeaveRequestsNestedInput>;
  lastUpdateSponsorUser?: InputMaybe<UserUpdateOneWithoutSponsorModifiedLeaveRequestsNestedInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionUpdateOneWithoutLeaveRequestsNestedInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel?: InputMaybe<LeaveRequestChannel>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutLeaveRequestsNestedInput>;
  sponsor?: InputMaybe<UserUpdateOneRequiredWithoutSponsorLeaveRequestsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestUpdateWithoutSponsorInput = {
  agent?: InputMaybe<UserUpdateOneWithoutAgentLeaveRequestsNestedInput>;
  cancelledAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestUpdateManyWithoutLeaveRequestNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUser?: InputMaybe<UserUpdateOneWithoutAgentModifiedLeaveRequestsNestedInput>;
  lastUpdateSponsorUser?: InputMaybe<UserUpdateOneWithoutSponsorModifiedLeaveRequestsNestedInput>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionUpdateOneWithoutLeaveRequestsNestedInput>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel?: InputMaybe<LeaveRequestChannel>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutLeaveRequestsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutLeaveRequestsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<LeaveRequestStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LeaveRequestUpsertWithWhereUniqueWithoutAgentInput = {
  create: LeaveRequestCreateWithoutAgentInput;
  update: LeaveRequestUpdateWithoutAgentInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestUpsertWithWhereUniqueWithoutLastUpdateAgentUserInput = {
  create: LeaveRequestCreateWithoutLastUpdateAgentUserInput;
  update: LeaveRequestUpdateWithoutLastUpdateAgentUserInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestUpsertWithWhereUniqueWithoutLastUpdateSponsorUserInput =
  {
    create: LeaveRequestCreateWithoutLastUpdateSponsorUserInput;
    update: LeaveRequestUpdateWithoutLastUpdateSponsorUserInput;
    where: LeaveRequestWhereUniqueInput;
  };

export type LeaveRequestUpsertWithWhereUniqueWithoutRouteInput = {
  create: LeaveRequestCreateWithoutRouteInput;
  update: LeaveRequestUpdateWithoutRouteInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestUpsertWithWhereUniqueWithoutSchoolRegistrationInput = {
  create: LeaveRequestCreateWithoutSchoolRegistrationInput;
  update: LeaveRequestUpdateWithoutSchoolRegistrationInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestUpsertWithWhereUniqueWithoutSponsorInput = {
  create: LeaveRequestCreateWithoutSponsorInput;
  update: LeaveRequestUpdateWithoutSponsorInput;
  where: LeaveRequestWhereUniqueInput;
};

export type LeaveRequestWhereInput = {
  AND?: InputMaybe<Array<LeaveRequestWhereInput>>;
  NOT?: InputMaybe<Array<LeaveRequestWhereInput>>;
  OR?: InputMaybe<Array<LeaveRequestWhereInput>>;
  agent?: InputMaybe<UserRelationFilter>;
  agentUserId?: InputMaybe<StringFilter>;
  cancelledAt?: InputMaybe<DateTimeFilter>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestListRelationFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lastUpdateAgentUser?: InputMaybe<UserRelationFilter>;
  lastUpdateAgentUserId?: InputMaybe<StringFilter>;
  lastUpdateSponsorUser?: InputMaybe<UserRelationFilter>;
  lastUpdateSponsorUserId?: InputMaybe<StringFilter>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionRelationFilter>;
  leaveRequestSubmissionId?: InputMaybe<StringFilter>;
  otherRequestChannel?: InputMaybe<StringFilter>;
  requestChannel?: InputMaybe<EnumLeaveRequestChannelFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  sponsor?: InputMaybe<UserRelationFilter>;
  sponsorUserId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumLeaveRequestStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LeaveRequestWhereUniqueInput = {
  AND?: InputMaybe<Array<LeaveRequestWhereInput>>;
  NOT?: InputMaybe<Array<LeaveRequestWhereInput>>;
  OR?: InputMaybe<Array<LeaveRequestWhereInput>>;
  agent?: InputMaybe<UserRelationFilter>;
  agentUserId?: InputMaybe<StringFilter>;
  cancelledAt?: InputMaybe<DateTimeFilter>;
  cancelledLeaveRequests?: InputMaybe<CancelledLeaveRequestListRelationFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdateAgentUser?: InputMaybe<UserRelationFilter>;
  lastUpdateAgentUserId?: InputMaybe<StringFilter>;
  lastUpdateSponsorUser?: InputMaybe<UserRelationFilter>;
  lastUpdateSponsorUserId?: InputMaybe<StringFilter>;
  leaveRequestSubmission?: InputMaybe<LeaveRequestSubmissionRelationFilter>;
  leaveRequestSubmissionId?: InputMaybe<StringFilter>;
  otherRequestChannel?: InputMaybe<StringFilter>;
  requestChannel?: InputMaybe<EnumLeaveRequestChannelFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  sponsor?: InputMaybe<UserRelationFilter>;
  sponsorUserId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumLeaveRequestStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ListResponse = {
  __typename?: "ListResponse";
  count: Scalars["Int"]["output"];
};

export type LocalitiesFilterInput = {
  administrativeAreaId?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Float"]["input"]>;
  subLocalityId?: InputMaybe<Scalars["String"]["input"]>;
  take?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Locality = {
  __typename?: "Locality";
  _count: LocalityCount;
  address?: Maybe<Array<Address>>;
  administrativeArea: AdministrativeArea;
  administrativeAreaId: Scalars["String"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  subLocalities?: Maybe<Array<SubLocality>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type LocalityCount = {
  __typename?: "LocalityCount";
  address: Scalars["Int"]["output"];
  subLocalities: Scalars["Int"]["output"];
};

export type LocalityCreateManyAdministrativeAreaInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LocalityCreateManyAdministrativeAreaInputEnvelope = {
  data: Array<LocalityCreateManyAdministrativeAreaInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LocalityCreateNestedManyWithoutAdministrativeAreaInput = {
  connect?: InputMaybe<Array<LocalityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LocalityCreateOrConnectWithoutAdministrativeAreaInput>
  >;
  create?: InputMaybe<Array<LocalityCreateWithoutAdministrativeAreaInput>>;
  createMany?: InputMaybe<LocalityCreateManyAdministrativeAreaInputEnvelope>;
};

export type LocalityCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<LocalityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocalityCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<LocalityCreateWithoutAddressInput>;
};

export type LocalityCreateNestedOneWithoutSubLocalitiesInput = {
  connect?: InputMaybe<LocalityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocalityCreateOrConnectWithoutSubLocalitiesInput>;
  create?: InputMaybe<LocalityCreateWithoutSubLocalitiesInput>;
};

export type LocalityCreateOrConnectWithoutAddressInput = {
  create: LocalityCreateWithoutAddressInput;
  where: LocalityWhereUniqueInput;
};

export type LocalityCreateOrConnectWithoutAdministrativeAreaInput = {
  create: LocalityCreateWithoutAdministrativeAreaInput;
  where: LocalityWhereUniqueInput;
};

export type LocalityCreateOrConnectWithoutSubLocalitiesInput = {
  create: LocalityCreateWithoutSubLocalitiesInput;
  where: LocalityWhereUniqueInput;
};

export type LocalityCreateWithoutAddressInput = {
  administrativeArea: AdministrativeAreaCreateNestedOneWithoutLocalitiesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  subLocalities?: InputMaybe<SubLocalityCreateNestedManyWithoutLocalityInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LocalityCreateWithoutAdministrativeAreaInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutLocalityInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  subLocalities?: InputMaybe<SubLocalityCreateNestedManyWithoutLocalityInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LocalityCreateWithoutSubLocalitiesInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutLocalityInput>;
  administrativeArea: AdministrativeAreaCreateNestedOneWithoutLocalitiesInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LocalityListRelationFilter = {
  every?: InputMaybe<LocalityWhereInput>;
  none?: InputMaybe<LocalityWhereInput>;
  some?: InputMaybe<LocalityWhereInput>;
};

export type LocalityRelationFilter = {
  is?: InputMaybe<LocalityWhereInput>;
  isNot?: InputMaybe<LocalityWhereInput>;
};

export type LocalityResponsePayload = {
  __typename?: "LocalityResponsePayload";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
};

export type LocalityScalarWhereInput = {
  AND?: InputMaybe<Array<LocalityScalarWhereInput>>;
  NOT?: InputMaybe<Array<LocalityScalarWhereInput>>;
  OR?: InputMaybe<Array<LocalityScalarWhereInput>>;
  administrativeAreaId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LocalityUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LocalityUpdateManyWithWhereWithoutAdministrativeAreaInput = {
  data: LocalityUpdateManyMutationInput;
  where: LocalityScalarWhereInput;
};

export type LocalityUpdateManyWithoutAdministrativeAreaNestedInput = {
  connect?: InputMaybe<Array<LocalityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<LocalityCreateOrConnectWithoutAdministrativeAreaInput>
  >;
  create?: InputMaybe<Array<LocalityCreateWithoutAdministrativeAreaInput>>;
  createMany?: InputMaybe<LocalityCreateManyAdministrativeAreaInputEnvelope>;
  delete?: InputMaybe<Array<LocalityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LocalityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LocalityWhereUniqueInput>>;
  set?: InputMaybe<Array<LocalityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<LocalityUpdateWithWhereUniqueWithoutAdministrativeAreaInput>
  >;
  updateMany?: InputMaybe<
    Array<LocalityUpdateManyWithWhereWithoutAdministrativeAreaInput>
  >;
  upsert?: InputMaybe<
    Array<LocalityUpsertWithWhereUniqueWithoutAdministrativeAreaInput>
  >;
};

export type LocalityUpdateOneRequiredWithoutSubLocalitiesNestedInput = {
  connect?: InputMaybe<LocalityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocalityCreateOrConnectWithoutSubLocalitiesInput>;
  create?: InputMaybe<LocalityCreateWithoutSubLocalitiesInput>;
  update?: InputMaybe<LocalityUpdateToOneWithWhereWithoutSubLocalitiesInput>;
  upsert?: InputMaybe<LocalityUpsertWithoutSubLocalitiesInput>;
};

export type LocalityUpdateOneWithoutAddressNestedInput = {
  connect?: InputMaybe<LocalityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocalityCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<LocalityCreateWithoutAddressInput>;
  delete?: InputMaybe<LocalityWhereInput>;
  disconnect?: InputMaybe<LocalityWhereInput>;
  update?: InputMaybe<LocalityUpdateToOneWithWhereWithoutAddressInput>;
  upsert?: InputMaybe<LocalityUpsertWithoutAddressInput>;
};

export type LocalityUpdateToOneWithWhereWithoutAddressInput = {
  data: LocalityUpdateWithoutAddressInput;
  where?: InputMaybe<LocalityWhereInput>;
};

export type LocalityUpdateToOneWithWhereWithoutSubLocalitiesInput = {
  data: LocalityUpdateWithoutSubLocalitiesInput;
  where?: InputMaybe<LocalityWhereInput>;
};

export type LocalityUpdateWithWhereUniqueWithoutAdministrativeAreaInput = {
  data: LocalityUpdateWithoutAdministrativeAreaInput;
  where: LocalityWhereUniqueInput;
};

export type LocalityUpdateWithoutAddressInput = {
  administrativeArea?: InputMaybe<AdministrativeAreaUpdateOneRequiredWithoutLocalitiesNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  subLocalities?: InputMaybe<SubLocalityUpdateManyWithoutLocalityNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LocalityUpdateWithoutAdministrativeAreaInput = {
  address?: InputMaybe<AddressUpdateManyWithoutLocalityNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  subLocalities?: InputMaybe<SubLocalityUpdateManyWithoutLocalityNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LocalityUpdateWithoutSubLocalitiesInput = {
  address?: InputMaybe<AddressUpdateManyWithoutLocalityNestedInput>;
  administrativeArea?: InputMaybe<AdministrativeAreaUpdateOneRequiredWithoutLocalitiesNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LocalityUpsertWithWhereUniqueWithoutAdministrativeAreaInput = {
  create: LocalityCreateWithoutAdministrativeAreaInput;
  update: LocalityUpdateWithoutAdministrativeAreaInput;
  where: LocalityWhereUniqueInput;
};

export type LocalityUpsertWithoutAddressInput = {
  create: LocalityCreateWithoutAddressInput;
  update: LocalityUpdateWithoutAddressInput;
  where?: InputMaybe<LocalityWhereInput>;
};

export type LocalityUpsertWithoutSubLocalitiesInput = {
  create: LocalityCreateWithoutSubLocalitiesInput;
  update: LocalityUpdateWithoutSubLocalitiesInput;
  where?: InputMaybe<LocalityWhereInput>;
};

export type LocalityWhereInput = {
  AND?: InputMaybe<Array<LocalityWhereInput>>;
  NOT?: InputMaybe<Array<LocalityWhereInput>>;
  OR?: InputMaybe<Array<LocalityWhereInput>>;
  address?: InputMaybe<AddressListRelationFilter>;
  administrativeArea?: InputMaybe<AdministrativeAreaRelationFilter>;
  administrativeAreaId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  subLocalities?: InputMaybe<SubLocalityListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LocalityWhereUniqueInput = {
  AND?: InputMaybe<Array<LocalityWhereInput>>;
  NOT?: InputMaybe<Array<LocalityWhereInput>>;
  OR?: InputMaybe<Array<LocalityWhereInput>>;
  address?: InputMaybe<AddressListRelationFilter>;
  administrativeArea?: InputMaybe<AdministrativeAreaRelationFilter>;
  administrativeAreaId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  subLocalities?: InputMaybe<SubLocalityListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LoginExtraInfos = {
  __typename?: "LoginExtraInfos";
  agentInfo?: Maybe<AgentInfoExtraInfo>;
  busAttendantInfo?: Maybe<BusAttendantInfoExtraInfo>;
  countryCode: CountryCode;
  countryId: Scalars["String"]["output"];
  driverInfo?: Maybe<DriverInfoExtraInfo>;
  sponsorInfo?: Maybe<SponsorInfoExtraInfo>;
};

export type LoginResponse = {
  __typename?: "LoginResponse";
  agentId?: Maybe<Scalars["String"]["output"]>;
  busAttendantId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated! Use What LoginResponse.extraInfo */
  countryCode?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated! Use What LoginResponse.extraInfo */
  countryId?: Maybe<Scalars["String"]["output"]>;
  driverId?: Maybe<Scalars["String"]["output"]>;
  /** Extra information to be sent back to the client */
  extraInfos: LoginExtraInfos;
  jwtObj: JwtObj;
  /** Use LoginResponse.sponsorId instead! */
  parentId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated! Use What LoginResponse.extraInfo */
  parentName?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated! Use What LoginResponse.extraInfo */
  parentNameAr?: Maybe<Scalars["String"]["output"]>;
  sponsorId?: Maybe<Scalars["String"]["output"]>;
  userId: Scalars["String"]["output"];
};

export type LoginWithGoogleInput = {
  idToken: Scalars["String"]["input"];
};

export type LogoutInput = {
  token: Scalars["String"]["input"];
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type LogoutResponse = {
  __typename?: "LogoutResponse";
  count: Scalars["Float"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  addAppVersion: AppVersionPayload;
  archiveAgent: Agent;
  archiveBusAttendant: BusAttendant;
  archiveDriver: Driver;
  archiveOffer: Offer;
  archiveParentFromMobile: Scalars["Boolean"]["output"];
  archiveRoute: Route;
  archiveSchool: School;
  archiveSchoolRegistration: SchoolRegistration;
  archiveService: Service;
  archiveSponsor: Sponsor;
  archiveStudent: Student;
  archiveTrip: Trip;
  archiveVehicle: Vehicle;
  assignAgentToRoute: Array<RouteAgent>;
  cancelLeaveRequest: LeaveRequest;
  cancelLeaveRequestForSponsor: LeaveRequest;
  cancelSchoolRegistrationWithCreditAmount: SchoolRegistration;
  changeSchoolRegistrationInstallmentPlan: SchoolRegistration;
  checkCoverage: Array<ChildServicePricing>;
  clearAllTripsAndTripStopsCache: Scalars["String"]["output"];
  createAccessToken: Scalars["String"]["output"];
  createAgent: Agent;
  createBusAttendant: BusAttendant;
  createBusAttendantHoursWorked: BusAttendantHoursWorked;
  createBusAttendantStatusHistory: BusAttendantStatusHistory;
  createBusAttendantViolationCompliance: BusAttendantViolationCompliance;
  createCollectionPoint: CollectionPointResponse;
  createCreditNote: CreditNote;
  createDriver: Driver;
  createDriverHoursWorked: DriverHoursWorked;
  createDriverStatusHistory: DriverStatusHistory;
  createDriverViolationCompliance: DriverViolationCompliance;
  createLeaveRequests: Array<LeaveRequest>;
  createLeaveRequestsForSponsor: Array<LeaveRequest>;
  createManyBusAttendantHoursWorked: Array<BusAttendantHoursWorked>;
  createManyBusAttendantStatusHistory: Array<BusAttendantStatusHistory>;
  createManyBusAttendantViolationCompliance: Array<BusAttendantViolationCompliance>;
  createManyDriverHoursWorked: Array<DriverHoursWorked>;
  createManyDriverStatusHistory: Array<DriverStatusHistory>;
  createManyDriverViolationCompliances: Array<DriverViolationCompliance>;
  createOffer: Offer;
  createPaymentCashOutRequest: CashOutRequest;
  /** @deprecated in favor of createRoutes */
  createRoute: Route;
  createRoutes: Array<Route>;
  createSchool: School;
  createSchoolRegistration: SchoolRegistration;
  createSchoolRegistrations: Array<SchoolRegistration>;
  createSchoolRegistrationsForMobile: Array<SchoolRegistrationWithPaymentLineItemStatus>;
  createSchoolRegistrationsForSponsor: Scalars["Boolean"]["output"];
  createService: Service;
  createServiceProvider: ServiceProvider;
  createServiceProviderGroup: ServiceProviderGroup;
  createStudent: Student;
  createTestTrip: Array<Trip>;
  createTripFeedback: TripFeedback;
  createTripsForWeb: Array<Trip>;
  createUser: UserResponsePayload;
  createVehicle: Vehicle;
  createVehicleSafetyEventForTesting: VehicleSafetyEvent;
  duplicateService: Service;
  fakeLoginFor: LoginResponse;
  forceEndTripForBaBd: TripForBaBd;
  forceStartTripForBaBd: TripForBaBd;
  logOut: LogoutResponse;
  loginWithGoogle: LoginResponse;
  markNotificationAsRead: Scalars["Boolean"]["output"];
  orderRoute: Scalars["String"]["output"];
  pushNotification: Scalars["Boolean"]["output"];
  pushRoutesUpdatedNotificationForDriver: Scalars["String"]["output"];
  reCacheSponsors: Array<Sponsor>;
  reCacheTrips: Array<Trip>;
  refreshToken: JwtObj;
  registerSubscriber: Scalars["Boolean"]["output"];
  rejectSchoolRegistration: SchoolRegistration;
  removeSubscriberFromTopics: Scalars["Boolean"]["output"];
  removeUser: UserResponsePayload;
  requestSchoolRegistrationChange: SchoolRegistration;
  resetRolesCache: Scalars["Boolean"]["output"];
  resetTripsForTesting: Array<Trip>;
  revertForceEndTripForBaBd: TripForBaBd;
  revertForceStartTripForBaBd: TripForBaBd;
  sendNotificationsToSponsorsForWeb: AdhocNotificationWithoutJsonTypes;
  setStudentTagsForTesting: Scalars["Boolean"]["output"];
  signUpSponsor: SignUpOutput;
  syncUsersWithGoogle: Scalars["Boolean"]["output"];
  terminateSchoolRegistrationWithCreditAmount: SchoolRegistration;
  terminateSchoolRegistrationsWithCreditAmount: Array<SchoolRegistration>;
  updateAgent: Agent;
  updateAttendanceStatus: Array<TripStopForBusAttendant>;
  updateBusAttendant: BusAttendant;
  updateBusAttendantHoursWorked: BusAttendantHoursWorked;
  updateBusAttendantStatusHistory: BusAttendantStatusHistory;
  updateBusAttendantViolationCompliance: BusAttendantViolationCompliance;
  updateCollectionPoint: CollectionPointResponse;
  updateDriver: Driver;
  updateDriverHoursWorked: DriverHoursWorked;
  updateDriverStatusHistory: DriverStatusHistory;
  updateDriverViolationCompliance: DriverViolationCompliance;
  updateGlobalConfiguration: ConfigurationKeyValue;
  updateLeaveRequest: LeaveRequest;
  updateOffer: Offer;
  updatePaymentCashOutRequest: CashOutRequest;
  updatePaymentCashOutRequests: Array<CashOutRequest>;
  updateProfileForSponsor: User;
  updateRoute: Route;
  updateSchool: School;
  updateSchoolRegistration: SchoolRegistration;
  updateSchoolRegistrations: Array<SchoolRegistration>;
  updateSchoolRegistrationsAddressForMobile: Array<SchoolRegistration>;
  updateService: Service;
  updateServiceProvider: ServiceProvider;
  updateServiceProviderGroup: ServiceProviderGroup;
  updateSponsor: Sponsor;
  updateStudent: Student;
  updateStudentsLocations: UpdateStudentsLocationsResponse;
  updateTripForWeb: TripForWeb;
  updateTripsForWeb: Array<TripForWeb>;
  updateVehicle: Vehicle;
  updateVehicleSafetyEvent: VehicleSafetyEvent;
  uploadFile: UploadedFileResponse;
  uploadSchoolRegistrations: UploadSchoolRegistrationsOutput;
  uploadServiceDefinition: ServiceResponsePayload;
  upsertClass: Class;
  upsertRouteGroup: RouteGroup;
  upsertSchoolGroup: SchoolGroup;
  upsertSchoolStage: SchoolStage;
  upsertSchoolType: SchoolType;
  upsertServiceProviderGroup: ServiceProviderGroup;
  upsertSponsor: Sponsor;
  upsertVehicleGroup: VehicleGroup;
  upsertVehicleMake: VehicleMake;
  upsertVehicleModel: VehicleModel;
  upsertVehicleType: VehicleType;
  verifyOtp: OtpVerification;
  waitListSchoolRegistration: SchoolRegistration;
};

export type MutationAddAppVersionArgs = {
  appVersionInput: AddAppVersionInput;
};

export type MutationArchiveAgentArgs = {
  id: Scalars["String"]["input"];
};

export type MutationArchiveBusAttendantArgs = {
  id: Scalars["String"]["input"];
};

export type MutationArchiveDriverArgs = {
  id: Scalars["String"]["input"];
};

export type MutationArchiveOfferArgs = {
  id: Scalars["String"]["input"];
};

export type MutationArchiveParentFromMobileArgs = {
  parentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationArchiveRouteArgs = {
  id: Scalars["String"]["input"];
};

export type MutationArchiveSchoolArgs = {
  id: Scalars["String"]["input"];
};

export type MutationArchiveSchoolRegistrationArgs = {
  id: Scalars["String"]["input"];
};

export type MutationArchiveServiceArgs = {
  id: Scalars["String"]["input"];
};

export type MutationArchiveSponsorArgs = {
  id: Scalars["String"]["input"];
};

export type MutationArchiveStudentArgs = {
  id: Scalars["String"]["input"];
};

export type MutationArchiveTripArgs = {
  id: Scalars["String"]["input"];
};

export type MutationArchiveVehicleArgs = {
  id: Scalars["String"]["input"];
};

export type MutationAssignAgentToRouteArgs = {
  agentRoute: RouteAgentTypes;
};

export type MutationCancelLeaveRequestArgs = {
  cancelLeaveRequestInput: CancelLeaveRequestInput;
  leaveRequestId: Scalars["String"]["input"];
};

export type MutationCancelLeaveRequestForSponsorArgs = {
  cancellationDate: Scalars["String"]["input"];
  leaveRequestId: Scalars["String"]["input"];
};

export type MutationCancelSchoolRegistrationWithCreditAmountArgs = {
  cancelDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["String"]["input"];
  ignoreDepositCutoffDate?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationChangeSchoolRegistrationInstallmentPlanArgs = {
  newInstallmentPlanId?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCheckCoverageArgs = {
  checkCoverageInput: CheckCoverageInput;
};

export type MutationCreateAccessTokenArgs = {
  refreshToken: Scalars["String"]["input"];
};

export type MutationCreateAgentArgs = {
  agent: CreateAgentInput;
};

export type MutationCreateBusAttendantArgs = {
  busAttendant: BusAttendantCreateInput;
};

export type MutationCreateBusAttendantHoursWorkedArgs = {
  busAttendantHoursWorked: BusAttendantHoursWorkedCreateInput;
};

export type MutationCreateBusAttendantStatusHistoryArgs = {
  busAttendantStatusHistory: BusAttendantStatusHistoryCreateInput;
};

export type MutationCreateBusAttendantViolationComplianceArgs = {
  busAttendantViolationCompliance: BusAttendantViolationComplianceCreateInput;
};

export type MutationCreateCollectionPointArgs = {
  input: CreateCollectionPointInput;
};

export type MutationCreateCreditNoteArgs = {
  creditNoteCreateManyOpsAgentInput: CreditNoteCreateManyOpsAgentInput;
};

export type MutationCreateDriverArgs = {
  driver: DriverCreateInput;
};

export type MutationCreateDriverHoursWorkedArgs = {
  driverHoursWorked: DriverHoursWorkedCreateInput;
};

export type MutationCreateDriverStatusHistoryArgs = {
  driverStatusHistory: DriverStatusHistoryCreateInput;
};

export type MutationCreateDriverViolationComplianceArgs = {
  driverViolationCompliance: DriverViolationComplianceCreateInput;
};

export type MutationCreateLeaveRequestsArgs = {
  leaveRequestInput: CreateLeaveRequestInput;
};

export type MutationCreateLeaveRequestsForSponsorArgs = {
  leaveRequestInput: CreateLeaveRequestInputForSponsor;
};

export type MutationCreateManyBusAttendantHoursWorkedArgs = {
  busAttendantHoursWorked: Array<BusAttendantHoursWorkedCreateInput>;
};

export type MutationCreateManyBusAttendantStatusHistoryArgs = {
  busAttendantStatusHistory: Array<BusAttendantStatusHistoryCreateInput>;
};

export type MutationCreateManyBusAttendantViolationComplianceArgs = {
  busAttendantViolationCompliances: Array<BusAttendantViolationComplianceCreateInput>;
};

export type MutationCreateManyDriverHoursWorkedArgs = {
  driverHoursWorked: Array<DriverHoursWorkedCreateInput>;
};

export type MutationCreateManyDriverStatusHistoryArgs = {
  driverStatusHistory: Array<DriverStatusHistoryCreateInput>;
};

export type MutationCreateManyDriverViolationCompliancesArgs = {
  driverViolationCompliances: Array<DriverViolationComplianceCreateInput>;
};

export type MutationCreateOfferArgs = {
  offer: OfferCreateInput;
};

export type MutationCreatePaymentCashOutRequestArgs = {
  cashOutRequestCreateInputWithConnectSponsor: CashOutRequestCreateInputWithConnectSponsor;
};

export type MutationCreateRouteArgs = {
  routeInput: RouteCreateInput;
};

export type MutationCreateRoutesArgs = {
  routes: Array<RouteCreateInput>;
};

export type MutationCreateSchoolArgs = {
  school: SchoolCreateInput;
};

export type MutationCreateSchoolRegistrationArgs = {
  schoolRegistration: SchoolRegistrationSingleCreateInput;
};

export type MutationCreateSchoolRegistrationsArgs = {
  schoolRegistrations: SchoolRegistrationsCreateInput;
};

export type MutationCreateSchoolRegistrationsForMobileArgs = {
  registerChildrenInput: RegisterChildrenFromMobileInput;
};

export type MutationCreateSchoolRegistrationsForSponsorArgs = {
  input: SchoolRegistrationsForSponsorCreateInput;
};

export type MutationCreateServiceArgs = {
  service: ServiceCreateExtendedInput;
};

export type MutationCreateServiceProviderArgs = {
  serviceProviderInput: CreateServiceProviderInput;
};

export type MutationCreateServiceProviderGroupArgs = {
  serviceProviderGroup: CreateServiceProviderGroupInput;
};

export type MutationCreateStudentArgs = {
  student: StudentCreateInput;
};

export type MutationCreateTestTripArgs = {
  date: Scalars["DateTime"]["input"];
  routeIds: Array<Scalars["String"]["input"]>;
};

export type MutationCreateTripFeedbackArgs = {
  tripFeedbackInput: TripFeedbackCreateInput;
};

export type MutationCreateTripsForWebArgs = {
  date: Scalars["DateTime"]["input"];
  forceCreate: Scalars["Boolean"]["input"];
  routeIds: Array<Scalars["String"]["input"]>;
};

export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};

export type MutationCreateVehicleArgs = {
  vehicle: VehicleCreateInput;
};

export type MutationCreateVehicleSafetyEventForTestingArgs = {
  createVehicleSafetyEventInput: VehicleSafetyEventCreateInput;
};

export type MutationDuplicateServiceArgs = {
  serviceDuplicateInput: ServiceDuplicateInput;
};

export type MutationFakeLoginForArgs = {
  userIdentifier: UserIdentifier;
};

export type MutationForceEndTripForBaBdArgs = {
  tripId: Scalars["String"]["input"];
};

export type MutationForceStartTripForBaBdArgs = {
  tripId: Scalars["String"]["input"];
};

export type MutationLogOutArgs = {
  logout: LogoutInput;
};

export type MutationLoginWithGoogleArgs = {
  loginWithGoogleInput: LoginWithGoogleInput;
};

export type MutationMarkNotificationAsReadArgs = {
  notificationIds: Array<Scalars["String"]["input"]>;
};

export type MutationOrderRouteArgs = {
  orderRouteInput: OrderRouteInput;
};

export type MutationPushNotificationArgs = {
  pushNotificationInput: PushNotificationInput;
};

export type MutationPushRoutesUpdatedNotificationForDriverArgs = {
  routeIds: Array<Scalars["String"]["input"]>;
};

export type MutationReCacheTripsArgs = {
  date: Scalars["DateTime"]["input"];
};

export type MutationRefreshTokenArgs = {
  refreshTokenInput: RefreshTokenInput;
};

export type MutationRegisterSubscriberArgs = {
  registerSubscriberInput: RegisterSubscriberInput;
};

export type MutationRejectSchoolRegistrationArgs = {
  agentId: Scalars["String"]["input"];
  rejectReason: Scalars["String"]["input"];
  schoolRegistrationId: Scalars["String"]["input"];
};

export type MutationRemoveSubscriberFromTopicsArgs = {
  removeSubscriberFromTopicsInput: RemoveSubscriberFromTopicsInput;
};

export type MutationRemoveUserArgs = {
  userIdInput: UserIdInput;
};

export type MutationRequestSchoolRegistrationChangeArgs = {
  changeRequestInput?: InputMaybe<ChangeRequestInput>;
  schoolRegistrationId: Scalars["String"]["input"];
};

export type MutationResetTripsForTestingArgs = {
  id: Scalars["String"]["input"];
};

export type MutationRevertForceEndTripForBaBdArgs = {
  tripId: Scalars["String"]["input"];
};

export type MutationRevertForceStartTripForBaBdArgs = {
  tripId: Scalars["String"]["input"];
};

export type MutationSendNotificationsToSponsorsForWebArgs = {
  schoolRegistrationNotificationInput: SchoolRegistrationNotificationInput;
};

export type MutationSetStudentTagsForTestingArgs = {
  studentsTags: Array<StudentTag>;
};

export type MutationSignUpSponsorArgs = {
  sponsorInput: SignUpInput;
};

export type MutationTerminateSchoolRegistrationWithCreditAmountArgs = {
  terminateManySchoolRegistrationWithCreditAmountInput: TerminateManySchoolRegistrationWithCreditAmountInput;
};

export type MutationTerminateSchoolRegistrationsWithCreditAmountArgs = {
  terminateManySchoolRegistrationsWithCreditAmountInput: Array<TerminateManySchoolRegistrationWithCreditAmountInput>;
};

export type MutationUpdateAgentArgs = {
  agent: AgentUpdateInput;
  id: Scalars["String"]["input"];
};

export type MutationUpdateAttendanceStatusArgs = {
  updateManyAttendanceStatusInput: UpdateManyAttendanceStatusInput;
};

export type MutationUpdateBusAttendantArgs = {
  busAttendant: BusAttendantUpdateInput;
  id: Scalars["String"]["input"];
};

export type MutationUpdateBusAttendantHoursWorkedArgs = {
  busAttendantHoursWorked: BusAttendantHoursWorkedUpdateInput;
  id: Scalars["String"]["input"];
};

export type MutationUpdateBusAttendantStatusHistoryArgs = {
  busAttendantStatusHistory: BusAttendantStatusHistoryUpdateInput;
  id: Scalars["String"]["input"];
};

export type MutationUpdateBusAttendantViolationComplianceArgs = {
  busAttendantViolationCompliance: BusAttendantViolationComplianceUpdateInput;
  id: Scalars["String"]["input"];
};

export type MutationUpdateCollectionPointArgs = {
  id: Scalars["String"]["input"];
  updates: UpdateCollectionPointInput;
};

export type MutationUpdateDriverArgs = {
  driver: DriverUpdateInput;
  id: Scalars["String"]["input"];
};

export type MutationUpdateDriverHoursWorkedArgs = {
  driverHoursWorked: DriverHoursWorkedUpdateInput;
  id: Scalars["String"]["input"];
};

export type MutationUpdateDriverStatusHistoryArgs = {
  driverStatusHistory: DriverStatusHistoryUpdateInput;
  id: Scalars["String"]["input"];
};

export type MutationUpdateDriverViolationComplianceArgs = {
  driverViolationCompliance: DriverViolationComplianceUpdateInput;
  id: Scalars["String"]["input"];
};

export type MutationUpdateGlobalConfigurationArgs = {
  updateGlobalConfigurationInput: UpdateGlobalConfigurationInput;
};

export type MutationUpdateLeaveRequestArgs = {
  leaveRequestId: Scalars["String"]["input"];
  leaveRequestUpdateInput: UpdateLeaveRequestInput;
};

export type MutationUpdateOfferArgs = {
  id: Scalars["String"]["input"];
  offer: OfferUpdateInput;
};

export type MutationUpdatePaymentCashOutRequestArgs = {
  cashOutRequestUpdateInput: CashOutRequestUpdateManyFinanceInput;
};

export type MutationUpdatePaymentCashOutRequestsArgs = {
  cashOutRequestUpdateInput: Array<CashOutRequestUpdateManyFinanceInput>;
};

export type MutationUpdateProfileForSponsorArgs = {
  updates: UpdateProfileForSponsorInput;
};

export type MutationUpdateRouteArgs = {
  id: Scalars["String"]["input"];
  routeInput: RouteUpdateInput;
};

export type MutationUpdateSchoolArgs = {
  id: Scalars["String"]["input"];
  school: SchoolUpdateInput;
};

export type MutationUpdateSchoolRegistrationArgs = {
  id: Scalars["String"]["input"];
  schoolRegistration: SchoolRegistrationSingleUpdateInput;
};

export type MutationUpdateSchoolRegistrationsArgs = {
  schoolRegistrationsInput: SchoolRegistrationUpdateManyMutationWhereInput;
};

export type MutationUpdateSchoolRegistrationsAddressForMobileArgs = {
  input: UpdateSchoolRegistrationsAddressesForMobileInput;
};

export type MutationUpdateServiceArgs = {
  id: Scalars["String"]["input"];
  service?: InputMaybe<ServiceUpdateInput>;
};

export type MutationUpdateServiceProviderArgs = {
  id: Scalars["String"]["input"];
  updateServiceProviderInput: UpdateServiceProviderInput;
};

export type MutationUpdateServiceProviderGroupArgs = {
  serviceProviderGroup: UpdateServiceProviderGroupInput;
  serviceProviderGroupId: Scalars["String"]["input"];
};

export type MutationUpdateSponsorArgs = {
  id: Scalars["String"]["input"];
  sponsor: SponsorUpdateInput;
};

export type MutationUpdateStudentArgs = {
  id: Scalars["String"]["input"];
  student: StudentUpdateInput;
};

export type MutationUpdateStudentsLocationsArgs = {
  days?: InputMaybe<Scalars["Float"]["input"]>;
  schoolId?: InputMaybe<Scalars["String"]["input"]>;
  studentsTagsIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type MutationUpdateTripForWebArgs = {
  id: Scalars["String"]["input"];
  tripUpdate: TripUpdateInput;
};

export type MutationUpdateTripsForWebArgs = {
  ids: Array<Scalars["String"]["input"]>;
  tripUpdateManyInput: TripUpdateManyInputForWeb;
};

export type MutationUpdateVehicleArgs = {
  id: Scalars["String"]["input"];
  vehicle: VehicleUpdateInput;
};

export type MutationUpdateVehicleSafetyEventArgs = {
  id: Scalars["String"]["input"];
  safetyEvent: VehicleSafetyEventUpdateInput;
};

export type MutationUploadFileArgs = {
  file: Scalars["Upload"]["input"];
};

export type MutationUploadSchoolRegistrationsArgs = {
  file: Scalars["Upload"]["input"];
  usesCollectionPoints: Scalars["Boolean"]["input"];
};

export type MutationUploadServiceDefinitionArgs = {
  file: Scalars["Upload"]["input"];
};

export type MutationUpsertClassArgs = {
  classInput: ClassUpsertInput;
};

export type MutationUpsertRouteGroupArgs = {
  routeGroupInput: RouteGroupUpsertInput;
};

export type MutationUpsertSchoolGroupArgs = {
  schoolGroupInput: SchoolGroupUpsertInput;
};

export type MutationUpsertSchoolStageArgs = {
  schoolStageInput: SchoolStageUpsertInput;
};

export type MutationUpsertSchoolTypeArgs = {
  schoolTypeInput: SchoolTypeUpsertInput;
};

export type MutationUpsertServiceProviderGroupArgs = {
  serviceProviderGroup: ServiceProviderGroupUpsertInput;
};

export type MutationUpsertSponsorArgs = {
  sponsorInput: SponsorUpsertInput;
};

export type MutationUpsertVehicleGroupArgs = {
  vehicleGroupInput: VehicleGroupUpsertInput;
};

export type MutationUpsertVehicleMakeArgs = {
  vehicleMakeInput: VehicleMakeUpsertInput;
};

export type MutationUpsertVehicleModelArgs = {
  vehicleModelInput: VehicleModelUpsertInput;
};

export type MutationUpsertVehicleTypeArgs = {
  vehicleTypeInput: VehicleTypeUpsertInput;
};

export type MutationVerifyOtpArgs = {
  otp: OtpVerifyInput;
};

export type MutationWaitListSchoolRegistrationArgs = {
  agentId: Scalars["String"]["input"];
  schoolRegistrationId: Scalars["String"]["input"];
  waitListReason: Scalars["String"]["input"];
};

export type NoPickUpAlert = {
  __typename?: "NoPickUpAlert";
  alert: Alert;
  alertId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  source: TripStopEventSource;
  student: Student;
  studentId: Scalars["String"]["output"];
  trip: Trip;
  tripId: Scalars["String"]["output"];
};

export type NoPickUpAlertCreateManyStudentInput = {
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  source: TripStopEventSource;
  tripId: Scalars["String"]["input"];
};

export type NoPickUpAlertCreateManyStudentInputEnvelope = {
  data: Array<NoPickUpAlertCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type NoPickUpAlertCreateManyTripInput = {
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  source: TripStopEventSource;
  studentId: Scalars["String"]["input"];
};

export type NoPickUpAlertCreateManyTripInputEnvelope = {
  data: Array<NoPickUpAlertCreateManyTripInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type NoPickUpAlertCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<NoPickUpAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<NoPickUpAlertCreateOrConnectWithoutStudentInput>
  >;
  create?: InputMaybe<Array<NoPickUpAlertCreateWithoutStudentInput>>;
  createMany?: InputMaybe<NoPickUpAlertCreateManyStudentInputEnvelope>;
};

export type NoPickUpAlertCreateNestedManyWithoutTripInput = {
  connect?: InputMaybe<Array<NoPickUpAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<NoPickUpAlertCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<NoPickUpAlertCreateWithoutTripInput>>;
  createMany?: InputMaybe<NoPickUpAlertCreateManyTripInputEnvelope>;
};

export type NoPickUpAlertCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<NoPickUpAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NoPickUpAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<NoPickUpAlertCreateWithoutAlertInput>;
};

export type NoPickUpAlertCreateOrConnectWithoutAlertInput = {
  create: NoPickUpAlertCreateWithoutAlertInput;
  where: NoPickUpAlertWhereUniqueInput;
};

export type NoPickUpAlertCreateOrConnectWithoutStudentInput = {
  create: NoPickUpAlertCreateWithoutStudentInput;
  where: NoPickUpAlertWhereUniqueInput;
};

export type NoPickUpAlertCreateOrConnectWithoutTripInput = {
  create: NoPickUpAlertCreateWithoutTripInput;
  where: NoPickUpAlertWhereUniqueInput;
};

export type NoPickUpAlertCreateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  source: TripStopEventSource;
  student: StudentCreateNestedOneWithoutNoPickUpAlertsInput;
  trip: TripCreateNestedOneWithoutNoPickUpAlertsInput;
};

export type NoPickUpAlertCreateWithoutStudentInput = {
  alert: AlertCreateNestedOneWithoutNoPickUpAlertInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  source: TripStopEventSource;
  trip: TripCreateNestedOneWithoutNoPickUpAlertsInput;
};

export type NoPickUpAlertCreateWithoutTripInput = {
  alert: AlertCreateNestedOneWithoutNoPickUpAlertInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  source: TripStopEventSource;
  student: StudentCreateNestedOneWithoutNoPickUpAlertsInput;
};

export type NoPickUpAlertListRelationFilter = {
  every?: InputMaybe<NoPickUpAlertWhereInput>;
  none?: InputMaybe<NoPickUpAlertWhereInput>;
  some?: InputMaybe<NoPickUpAlertWhereInput>;
};

export type NoPickUpAlertRelationFilter = {
  is?: InputMaybe<NoPickUpAlertWhereInput>;
  isNot?: InputMaybe<NoPickUpAlertWhereInput>;
};

export type NoPickUpAlertScalarWhereInput = {
  AND?: InputMaybe<Array<NoPickUpAlertScalarWhereInput>>;
  NOT?: InputMaybe<Array<NoPickUpAlertScalarWhereInput>>;
  OR?: InputMaybe<Array<NoPickUpAlertScalarWhereInput>>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  source?: InputMaybe<EnumTripStopEventSourceFilter>;
  studentId?: InputMaybe<StringFilter>;
  tripId?: InputMaybe<StringFilter>;
};

export type NoPickUpAlertUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<TripStopEventSource>;
};

export type NoPickUpAlertUpdateManyWithWhereWithoutStudentInput = {
  data: NoPickUpAlertUpdateManyMutationInput;
  where: NoPickUpAlertScalarWhereInput;
};

export type NoPickUpAlertUpdateManyWithWhereWithoutTripInput = {
  data: NoPickUpAlertUpdateManyMutationInput;
  where: NoPickUpAlertScalarWhereInput;
};

export type NoPickUpAlertUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<NoPickUpAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<NoPickUpAlertCreateOrConnectWithoutStudentInput>
  >;
  create?: InputMaybe<Array<NoPickUpAlertCreateWithoutStudentInput>>;
  createMany?: InputMaybe<NoPickUpAlertCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<NoPickUpAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NoPickUpAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NoPickUpAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<NoPickUpAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<NoPickUpAlertUpdateWithWhereUniqueWithoutStudentInput>
  >;
  updateMany?: InputMaybe<
    Array<NoPickUpAlertUpdateManyWithWhereWithoutStudentInput>
  >;
  upsert?: InputMaybe<
    Array<NoPickUpAlertUpsertWithWhereUniqueWithoutStudentInput>
  >;
};

export type NoPickUpAlertUpdateManyWithoutTripNestedInput = {
  connect?: InputMaybe<Array<NoPickUpAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<NoPickUpAlertCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<NoPickUpAlertCreateWithoutTripInput>>;
  createMany?: InputMaybe<NoPickUpAlertCreateManyTripInputEnvelope>;
  delete?: InputMaybe<Array<NoPickUpAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NoPickUpAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NoPickUpAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<NoPickUpAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<NoPickUpAlertUpdateWithWhereUniqueWithoutTripInput>
  >;
  updateMany?: InputMaybe<
    Array<NoPickUpAlertUpdateManyWithWhereWithoutTripInput>
  >;
  upsert?: InputMaybe<
    Array<NoPickUpAlertUpsertWithWhereUniqueWithoutTripInput>
  >;
};

export type NoPickUpAlertUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<NoPickUpAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NoPickUpAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<NoPickUpAlertCreateWithoutAlertInput>;
  delete?: InputMaybe<NoPickUpAlertWhereInput>;
  disconnect?: InputMaybe<NoPickUpAlertWhereInput>;
  update?: InputMaybe<NoPickUpAlertUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<NoPickUpAlertUpsertWithoutAlertInput>;
};

export type NoPickUpAlertUpdateToOneWithWhereWithoutAlertInput = {
  data: NoPickUpAlertUpdateWithoutAlertInput;
  where?: InputMaybe<NoPickUpAlertWhereInput>;
};

export type NoPickUpAlertUpdateWithWhereUniqueWithoutStudentInput = {
  data: NoPickUpAlertUpdateWithoutStudentInput;
  where: NoPickUpAlertWhereUniqueInput;
};

export type NoPickUpAlertUpdateWithWhereUniqueWithoutTripInput = {
  data: NoPickUpAlertUpdateWithoutTripInput;
  where: NoPickUpAlertWhereUniqueInput;
};

export type NoPickUpAlertUpdateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<TripStopEventSource>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutNoPickUpAlertsNestedInput>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutNoPickUpAlertsNestedInput>;
};

export type NoPickUpAlertUpdateWithoutStudentInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutNoPickUpAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<TripStopEventSource>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutNoPickUpAlertsNestedInput>;
};

export type NoPickUpAlertUpdateWithoutTripInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutNoPickUpAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<TripStopEventSource>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutNoPickUpAlertsNestedInput>;
};

export type NoPickUpAlertUpsertWithWhereUniqueWithoutStudentInput = {
  create: NoPickUpAlertCreateWithoutStudentInput;
  update: NoPickUpAlertUpdateWithoutStudentInput;
  where: NoPickUpAlertWhereUniqueInput;
};

export type NoPickUpAlertUpsertWithWhereUniqueWithoutTripInput = {
  create: NoPickUpAlertCreateWithoutTripInput;
  update: NoPickUpAlertUpdateWithoutTripInput;
  where: NoPickUpAlertWhereUniqueInput;
};

export type NoPickUpAlertUpsertWithoutAlertInput = {
  create: NoPickUpAlertCreateWithoutAlertInput;
  update: NoPickUpAlertUpdateWithoutAlertInput;
  where?: InputMaybe<NoPickUpAlertWhereInput>;
};

export type NoPickUpAlertWhereInput = {
  AND?: InputMaybe<Array<NoPickUpAlertWhereInput>>;
  NOT?: InputMaybe<Array<NoPickUpAlertWhereInput>>;
  OR?: InputMaybe<Array<NoPickUpAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  source?: InputMaybe<EnumTripStopEventSourceFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<StringFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
};

export type NoPickUpAlertWhereUniqueInput = {
  AND?: InputMaybe<Array<NoPickUpAlertWhereInput>>;
  NOT?: InputMaybe<Array<NoPickUpAlertWhereInput>>;
  OR?: InputMaybe<Array<NoPickUpAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<EnumTripStopEventSourceFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<StringFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
};

export enum NotificationAction {
  GROUP_OF_DEVICES = "GROUP_OF_DEVICES",
  SINGLE_DEVICE = "SINGLE_DEVICE",
  TOPIC = "TOPIC",
}

export type NotificationDataInput = {
  body: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type NotificationHistoryResponsePayload = {
  __typename?: "NotificationHistoryResponsePayload";
  body: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isRead: Scalars["Boolean"]["output"];
  pushNotificationType: PushNotificationType;
  title: Scalars["String"]["output"];
};

export type NotificationHistoryWithCountResponsePayload = {
  __typename?: "NotificationHistoryWithCountResponsePayload";
  count: Scalars["Int"]["output"];
  notifications: Array<NotificationHistoryResponsePayload>;
};

export type NotificationTopic = {
  __typename?: "NotificationTopic";
  _count: NotificationTopicCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  subscribers?: Maybe<Array<SubscriberNotificationTopic>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type NotificationTopicCount = {
  __typename?: "NotificationTopicCount";
  subscribers: Scalars["Int"]["output"];
};

export type NotificationTopicCreateNestedOneWithoutSubscribersInput = {
  connect?: InputMaybe<NotificationTopicWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NotificationTopicCreateOrConnectWithoutSubscribersInput>;
  create?: InputMaybe<NotificationTopicCreateWithoutSubscribersInput>;
};

export type NotificationTopicCreateOrConnectWithoutSubscribersInput = {
  create: NotificationTopicCreateWithoutSubscribersInput;
  where: NotificationTopicWhereUniqueInput;
};

export type NotificationTopicCreateWithoutSubscribersInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type NotificationTopicRelationFilter = {
  is?: InputMaybe<NotificationTopicWhereInput>;
  isNot?: InputMaybe<NotificationTopicWhereInput>;
};

export type NotificationTopicResponsePayload = {
  __typename?: "NotificationTopicResponsePayload";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type NotificationTopicUpdateOneRequiredWithoutSubscribersNestedInput = {
  connect?: InputMaybe<NotificationTopicWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NotificationTopicCreateOrConnectWithoutSubscribersInput>;
  create?: InputMaybe<NotificationTopicCreateWithoutSubscribersInput>;
  update?: InputMaybe<NotificationTopicUpdateToOneWithWhereWithoutSubscribersInput>;
  upsert?: InputMaybe<NotificationTopicUpsertWithoutSubscribersInput>;
};

export type NotificationTopicUpdateToOneWithWhereWithoutSubscribersInput = {
  data: NotificationTopicUpdateWithoutSubscribersInput;
  where?: InputMaybe<NotificationTopicWhereInput>;
};

export type NotificationTopicUpdateWithoutSubscribersInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type NotificationTopicUpsertWithoutSubscribersInput = {
  create: NotificationTopicCreateWithoutSubscribersInput;
  update: NotificationTopicUpdateWithoutSubscribersInput;
  where?: InputMaybe<NotificationTopicWhereInput>;
};

export type NotificationTopicWhereInput = {
  AND?: InputMaybe<Array<NotificationTopicWhereInput>>;
  NOT?: InputMaybe<Array<NotificationTopicWhereInput>>;
  OR?: InputMaybe<Array<NotificationTopicWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  subscribers?: InputMaybe<SubscriberNotificationTopicListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NotificationTopicWhereUniqueInput = {
  AND?: InputMaybe<Array<NotificationTopicWhereInput>>;
  NOT?: InputMaybe<Array<NotificationTopicWhereInput>>;
  OR?: InputMaybe<Array<NotificationTopicWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  subscribers?: InputMaybe<SubscriberNotificationTopicListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum NullsOrder {
  FIRST = "first",
  LAST = "last",
}

export type Offer = {
  __typename?: "Offer";
  _count: OfferCount;
  amountFactor?: Maybe<Scalars["Float"]["output"]>;
  amountValue?: Maybe<Scalars["Float"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  budgetLimit?: Maybe<Scalars["Float"]["output"]>;
  budgetUsed?: Maybe<Scalars["Float"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  currency?: Maybe<CurrencyCode>;
  currentUsageCount?: Maybe<Scalars["Int"]["output"]>;
  department?: Maybe<Department>;
  departmentId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  installmentPlans?: Maybe<Array<InstallmentPlanOffer>>;
  /** ///////////////////////////// */
  isActive: Scalars["Boolean"]["output"];
  maxUsageCount?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  offerCodes?: Maybe<Array<OfferCode>>;
  offerTypeId?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Status>;
  type?: Maybe<OfferType>;
  updatedAt: Scalars["DateTime"]["output"];
  validFrom?: Maybe<Scalars["DateTime"]["output"]>;
  validTimePeriodsCount?: Maybe<Scalars["Int"]["output"]>;
  validTo?: Maybe<Scalars["DateTime"]["output"]>;
};

export type OfferCode = {
  __typename?: "OfferCode";
  _count: OfferCodeCount;
  affiliate?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isOneTime: Scalars["Boolean"]["output"];
  isUsed: Scalars["Boolean"]["output"];
  offer?: Maybe<Offer>;
  offerId: Scalars["String"]["output"];
  paymentLineItems?: Maybe<Array<PaymentLineItem>>;
  paymentPackages?: Maybe<Array<PaymentPackage>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type OfferCodeCount = {
  __typename?: "OfferCodeCount";
  paymentLineItems: Scalars["Int"]["output"];
  paymentPackages: Scalars["Int"]["output"];
};

export type OfferCodeCreateManyOfferInput = {
  affiliate?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  isOneTime?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCodeCreateManyOfferInputEnvelope = {
  data: Array<OfferCodeCreateManyOfferInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type OfferCodeCreateNestedManyWithoutOfferInput = {
  connect?: InputMaybe<Array<OfferCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OfferCodeCreateOrConnectWithoutOfferInput>
  >;
  create?: InputMaybe<Array<OfferCodeCreateWithoutOfferInput>>;
  createMany?: InputMaybe<OfferCodeCreateManyOfferInputEnvelope>;
};

export type OfferCodeCreateNestedOneWithoutPaymentLineItemsInput = {
  connect?: InputMaybe<OfferCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfferCodeCreateOrConnectWithoutPaymentLineItemsInput>;
  create?: InputMaybe<OfferCodeCreateWithoutPaymentLineItemsInput>;
};

export type OfferCodeCreateNestedOneWithoutPaymentPackagesInput = {
  connect?: InputMaybe<OfferCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfferCodeCreateOrConnectWithoutPaymentPackagesInput>;
  create?: InputMaybe<OfferCodeCreateWithoutPaymentPackagesInput>;
};

export type OfferCodeCreateOrConnectWithoutOfferInput = {
  create: OfferCodeCreateWithoutOfferInput;
  where: OfferCodeWhereUniqueInput;
};

export type OfferCodeCreateOrConnectWithoutPaymentLineItemsInput = {
  create: OfferCodeCreateWithoutPaymentLineItemsInput;
  where: OfferCodeWhereUniqueInput;
};

export type OfferCodeCreateOrConnectWithoutPaymentPackagesInput = {
  create: OfferCodeCreateWithoutPaymentPackagesInput;
  where: OfferCodeWhereUniqueInput;
};

export type OfferCodeCreateWithoutOfferInput = {
  affiliate?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  isOneTime?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemCreateNestedManyWithoutOfferCodeInput>;
  paymentPackages?: InputMaybe<PaymentPackageCreateNestedManyWithoutOfferCodeInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCodeCreateWithoutPaymentLineItemsInput = {
  affiliate?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  isOneTime?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  offer?: InputMaybe<OfferCreateNestedOneWithoutOfferCodesInput>;
  paymentPackages?: InputMaybe<PaymentPackageCreateNestedManyWithoutOfferCodeInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCodeCreateWithoutPaymentPackagesInput = {
  affiliate?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  isOneTime?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  offer?: InputMaybe<OfferCreateNestedOneWithoutOfferCodesInput>;
  paymentLineItems?: InputMaybe<PaymentLineItemCreateNestedManyWithoutOfferCodeInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCodeListRelationFilter = {
  every?: InputMaybe<OfferCodeWhereInput>;
  none?: InputMaybe<OfferCodeWhereInput>;
  some?: InputMaybe<OfferCodeWhereInput>;
};

export type OfferCodeRelationFilter = {
  is?: InputMaybe<OfferCodeWhereInput>;
  isNot?: InputMaybe<OfferCodeWhereInput>;
};

export type OfferCodeScalarWhereInput = {
  AND?: InputMaybe<Array<OfferCodeScalarWhereInput>>;
  NOT?: InputMaybe<Array<OfferCodeScalarWhereInput>>;
  OR?: InputMaybe<Array<OfferCodeScalarWhereInput>>;
  affiliate?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isOneTime?: InputMaybe<BoolFilter>;
  isUsed?: InputMaybe<BoolFilter>;
  offerId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OfferCodeUpdateManyMutationInput = {
  affiliate?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isOneTime?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCodeUpdateManyWithWhereWithoutOfferInput = {
  data: OfferCodeUpdateManyMutationInput;
  where: OfferCodeScalarWhereInput;
};

export type OfferCodeUpdateManyWithoutOfferNestedInput = {
  connect?: InputMaybe<Array<OfferCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OfferCodeCreateOrConnectWithoutOfferInput>
  >;
  create?: InputMaybe<Array<OfferCodeCreateWithoutOfferInput>>;
  createMany?: InputMaybe<OfferCodeCreateManyOfferInputEnvelope>;
  delete?: InputMaybe<Array<OfferCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OfferCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OfferCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<OfferCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<OfferCodeUpdateWithWhereUniqueWithoutOfferInput>>;
  updateMany?: InputMaybe<Array<OfferCodeUpdateManyWithWhereWithoutOfferInput>>;
  upsert?: InputMaybe<Array<OfferCodeUpsertWithWhereUniqueWithoutOfferInput>>;
};

export type OfferCodeUpdateOneWithoutPaymentLineItemsNestedInput = {
  connect?: InputMaybe<OfferCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfferCodeCreateOrConnectWithoutPaymentLineItemsInput>;
  create?: InputMaybe<OfferCodeCreateWithoutPaymentLineItemsInput>;
  delete?: InputMaybe<OfferCodeWhereInput>;
  disconnect?: InputMaybe<OfferCodeWhereInput>;
  update?: InputMaybe<OfferCodeUpdateToOneWithWhereWithoutPaymentLineItemsInput>;
  upsert?: InputMaybe<OfferCodeUpsertWithoutPaymentLineItemsInput>;
};

export type OfferCodeUpdateOneWithoutPaymentPackagesNestedInput = {
  connect?: InputMaybe<OfferCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfferCodeCreateOrConnectWithoutPaymentPackagesInput>;
  create?: InputMaybe<OfferCodeCreateWithoutPaymentPackagesInput>;
  delete?: InputMaybe<OfferCodeWhereInput>;
  disconnect?: InputMaybe<OfferCodeWhereInput>;
  update?: InputMaybe<OfferCodeUpdateToOneWithWhereWithoutPaymentPackagesInput>;
  upsert?: InputMaybe<OfferCodeUpsertWithoutPaymentPackagesInput>;
};

export type OfferCodeUpdateToOneWithWhereWithoutPaymentLineItemsInput = {
  data: OfferCodeUpdateWithoutPaymentLineItemsInput;
  where?: InputMaybe<OfferCodeWhereInput>;
};

export type OfferCodeUpdateToOneWithWhereWithoutPaymentPackagesInput = {
  data: OfferCodeUpdateWithoutPaymentPackagesInput;
  where?: InputMaybe<OfferCodeWhereInput>;
};

export type OfferCodeUpdateWithWhereUniqueWithoutOfferInput = {
  data: OfferCodeUpdateWithoutOfferInput;
  where: OfferCodeWhereUniqueInput;
};

export type OfferCodeUpdateWithoutOfferInput = {
  affiliate?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isOneTime?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemUpdateManyWithoutOfferCodeNestedInput>;
  paymentPackages?: InputMaybe<PaymentPackageUpdateManyWithoutOfferCodeNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCodeUpdateWithoutPaymentLineItemsInput = {
  affiliate?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isOneTime?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  offer?: InputMaybe<OfferUpdateOneWithoutOfferCodesNestedInput>;
  paymentPackages?: InputMaybe<PaymentPackageUpdateManyWithoutOfferCodeNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCodeUpdateWithoutPaymentPackagesInput = {
  affiliate?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isOneTime?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUsed?: InputMaybe<Scalars["Boolean"]["input"]>;
  offer?: InputMaybe<OfferUpdateOneWithoutOfferCodesNestedInput>;
  paymentLineItems?: InputMaybe<PaymentLineItemUpdateManyWithoutOfferCodeNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCodeUpsertWithWhereUniqueWithoutOfferInput = {
  create: OfferCodeCreateWithoutOfferInput;
  update: OfferCodeUpdateWithoutOfferInput;
  where: OfferCodeWhereUniqueInput;
};

export type OfferCodeUpsertWithoutPaymentLineItemsInput = {
  create: OfferCodeCreateWithoutPaymentLineItemsInput;
  update: OfferCodeUpdateWithoutPaymentLineItemsInput;
  where?: InputMaybe<OfferCodeWhereInput>;
};

export type OfferCodeUpsertWithoutPaymentPackagesInput = {
  create: OfferCodeCreateWithoutPaymentPackagesInput;
  update: OfferCodeUpdateWithoutPaymentPackagesInput;
  where?: InputMaybe<OfferCodeWhereInput>;
};

export type OfferCodeWhereInput = {
  AND?: InputMaybe<Array<OfferCodeWhereInput>>;
  NOT?: InputMaybe<Array<OfferCodeWhereInput>>;
  OR?: InputMaybe<Array<OfferCodeWhereInput>>;
  affiliate?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isOneTime?: InputMaybe<BoolFilter>;
  isUsed?: InputMaybe<BoolFilter>;
  offer?: InputMaybe<OfferRelationFilter>;
  offerId?: InputMaybe<StringFilter>;
  paymentLineItems?: InputMaybe<PaymentLineItemListRelationFilter>;
  paymentPackages?: InputMaybe<PaymentPackageListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OfferCodeWhereUniqueInput = {
  AND?: InputMaybe<Array<OfferCodeWhereInput>>;
  NOT?: InputMaybe<Array<OfferCodeWhereInput>>;
  OR?: InputMaybe<Array<OfferCodeWhereInput>>;
  affiliate?: InputMaybe<StringFilter>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isOneTime?: InputMaybe<BoolFilter>;
  isUsed?: InputMaybe<BoolFilter>;
  offer?: InputMaybe<OfferRelationFilter>;
  offerId?: InputMaybe<StringFilter>;
  paymentLineItems?: InputMaybe<PaymentLineItemListRelationFilter>;
  paymentPackages?: InputMaybe<PaymentPackageListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OfferCount = {
  __typename?: "OfferCount";
  installmentPlans: Scalars["Int"]["output"];
  offerCodes: Scalars["Int"]["output"];
};

export type OfferCreateInput = {
  amountFactor?: InputMaybe<Scalars["Float"]["input"]>;
  amountValue?: InputMaybe<Scalars["Float"]["input"]>;
  budgetLimit?: InputMaybe<Scalars["Float"]["input"]>;
  budgetUsed?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  currentUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  department?: InputMaybe<DepartmentCreateNestedOneWithoutOffersInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlans?: InputMaybe<InstallmentPlanOfferCreateNestedManyWithoutOfferInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  offerCodes?: InputMaybe<OfferCodeCreateNestedManyWithoutOfferInput>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<OfferTypeCreateNestedOneWithoutOffersInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  validTimePeriodsCount?: InputMaybe<Scalars["Int"]["input"]>;
  validTo?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCreateManyDepartmentInput = {
  amountFactor?: InputMaybe<Scalars["Float"]["input"]>;
  amountValue?: InputMaybe<Scalars["Float"]["input"]>;
  budgetLimit?: InputMaybe<Scalars["Float"]["input"]>;
  budgetUsed?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  currentUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  offerTypeId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  validTimePeriodsCount?: InputMaybe<Scalars["Int"]["input"]>;
  validTo?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCreateManyDepartmentInputEnvelope = {
  data: Array<OfferCreateManyDepartmentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type OfferCreateNestedManyWithoutDepartmentInput = {
  connect?: InputMaybe<Array<OfferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OfferCreateOrConnectWithoutDepartmentInput>
  >;
  create?: InputMaybe<Array<OfferCreateWithoutDepartmentInput>>;
  createMany?: InputMaybe<OfferCreateManyDepartmentInputEnvelope>;
};

export type OfferCreateNestedOneWithoutInstallmentPlansInput = {
  connect?: InputMaybe<OfferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfferCreateOrConnectWithoutInstallmentPlansInput>;
  create?: InputMaybe<OfferCreateWithoutInstallmentPlansInput>;
};

export type OfferCreateNestedOneWithoutOfferCodesInput = {
  connect?: InputMaybe<OfferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfferCreateOrConnectWithoutOfferCodesInput>;
  create?: InputMaybe<OfferCreateWithoutOfferCodesInput>;
};

export type OfferCreateOrConnectWithoutDepartmentInput = {
  create: OfferCreateWithoutDepartmentInput;
  where: OfferWhereUniqueInput;
};

export type OfferCreateOrConnectWithoutInstallmentPlansInput = {
  create: OfferCreateWithoutInstallmentPlansInput;
  where: OfferWhereUniqueInput;
};

export type OfferCreateOrConnectWithoutOfferCodesInput = {
  create: OfferCreateWithoutOfferCodesInput;
  where: OfferWhereUniqueInput;
};

export type OfferCreateWithoutDepartmentInput = {
  amountFactor?: InputMaybe<Scalars["Float"]["input"]>;
  amountValue?: InputMaybe<Scalars["Float"]["input"]>;
  budgetLimit?: InputMaybe<Scalars["Float"]["input"]>;
  budgetUsed?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  currentUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlans?: InputMaybe<InstallmentPlanOfferCreateNestedManyWithoutOfferInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  offerCodes?: InputMaybe<OfferCodeCreateNestedManyWithoutOfferInput>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<OfferTypeCreateNestedOneWithoutOffersInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  validTimePeriodsCount?: InputMaybe<Scalars["Int"]["input"]>;
  validTo?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCreateWithoutInstallmentPlansInput = {
  amountFactor?: InputMaybe<Scalars["Float"]["input"]>;
  amountValue?: InputMaybe<Scalars["Float"]["input"]>;
  budgetLimit?: InputMaybe<Scalars["Float"]["input"]>;
  budgetUsed?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  currentUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  department?: InputMaybe<DepartmentCreateNestedOneWithoutOffersInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  offerCodes?: InputMaybe<OfferCodeCreateNestedManyWithoutOfferInput>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<OfferTypeCreateNestedOneWithoutOffersInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  validTimePeriodsCount?: InputMaybe<Scalars["Int"]["input"]>;
  validTo?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferCreateWithoutOfferCodesInput = {
  amountFactor?: InputMaybe<Scalars["Float"]["input"]>;
  amountValue?: InputMaybe<Scalars["Float"]["input"]>;
  budgetLimit?: InputMaybe<Scalars["Float"]["input"]>;
  budgetUsed?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  currentUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  department?: InputMaybe<DepartmentCreateNestedOneWithoutOffersInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlans?: InputMaybe<InstallmentPlanOfferCreateNestedManyWithoutOfferInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  status?: InputMaybe<Status>;
  type?: InputMaybe<OfferTypeCreateNestedOneWithoutOffersInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  validTimePeriodsCount?: InputMaybe<Scalars["Int"]["input"]>;
  validTo?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferDiscount = {
  __typename?: "OfferDiscount";
  amount: Scalars["Float"]["output"];
  factor: Scalars["Float"]["output"];
};

export type OfferListRelationFilter = {
  every?: InputMaybe<OfferWhereInput>;
  none?: InputMaybe<OfferWhereInput>;
  some?: InputMaybe<OfferWhereInput>;
};

export type OfferRelationFilter = {
  is?: InputMaybe<OfferWhereInput>;
  isNot?: InputMaybe<OfferWhereInput>;
};

export type OfferScalarWhereInput = {
  AND?: InputMaybe<Array<OfferScalarWhereInput>>;
  NOT?: InputMaybe<Array<OfferScalarWhereInput>>;
  OR?: InputMaybe<Array<OfferScalarWhereInput>>;
  amountFactor?: InputMaybe<FloatFilter>;
  amountValue?: InputMaybe<FloatFilter>;
  budgetLimit?: InputMaybe<FloatFilter>;
  budgetUsed?: InputMaybe<FloatFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  currentUsageCount?: InputMaybe<IntFilter>;
  departmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  maxUsageCount?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  offerTypeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  validFrom?: InputMaybe<DateTimeFilter>;
  validTimePeriodsCount?: InputMaybe<IntFilter>;
  validTo?: InputMaybe<DateTimeFilter>;
};

export type OfferType = {
  __typename?: "OfferType";
  _count: OfferTypeCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  offers?: Maybe<Array<Offer>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type OfferTypeCount = {
  __typename?: "OfferTypeCount";
  offers: Scalars["Int"]["output"];
};

export type OfferTypeCreateNestedOneWithoutOffersInput = {
  connect?: InputMaybe<OfferTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfferTypeCreateOrConnectWithoutOffersInput>;
  create?: InputMaybe<OfferTypeCreateWithoutOffersInput>;
};

export type OfferTypeCreateOrConnectWithoutOffersInput = {
  create: OfferTypeCreateWithoutOffersInput;
  where: OfferTypeWhereUniqueInput;
};

export type OfferTypeCreateWithoutOffersInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferTypeRelationFilter = {
  is?: InputMaybe<OfferTypeWhereInput>;
  isNot?: InputMaybe<OfferTypeWhereInput>;
};

export type OfferTypeUpdateOneWithoutOffersNestedInput = {
  connect?: InputMaybe<OfferTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfferTypeCreateOrConnectWithoutOffersInput>;
  create?: InputMaybe<OfferTypeCreateWithoutOffersInput>;
  delete?: InputMaybe<OfferTypeWhereInput>;
  disconnect?: InputMaybe<OfferTypeWhereInput>;
  update?: InputMaybe<OfferTypeUpdateToOneWithWhereWithoutOffersInput>;
  upsert?: InputMaybe<OfferTypeUpsertWithoutOffersInput>;
};

export type OfferTypeUpdateToOneWithWhereWithoutOffersInput = {
  data: OfferTypeUpdateWithoutOffersInput;
  where?: InputMaybe<OfferTypeWhereInput>;
};

export type OfferTypeUpdateWithoutOffersInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferTypeUpsertWithoutOffersInput = {
  create: OfferTypeCreateWithoutOffersInput;
  update: OfferTypeUpdateWithoutOffersInput;
  where?: InputMaybe<OfferTypeWhereInput>;
};

export type OfferTypeWhereInput = {
  AND?: InputMaybe<Array<OfferTypeWhereInput>>;
  NOT?: InputMaybe<Array<OfferTypeWhereInput>>;
  OR?: InputMaybe<Array<OfferTypeWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  offers?: InputMaybe<OfferListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OfferTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<OfferTypeWhereInput>>;
  NOT?: InputMaybe<Array<OfferTypeWhereInput>>;
  OR?: InputMaybe<Array<OfferTypeWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  offers?: InputMaybe<OfferListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OfferUpdateInput = {
  amountFactor?: InputMaybe<Scalars["Float"]["input"]>;
  amountValue?: InputMaybe<Scalars["Float"]["input"]>;
  budgetLimit?: InputMaybe<Scalars["Float"]["input"]>;
  budgetUsed?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  currentUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  department?: InputMaybe<DepartmentUpdateOneWithoutOffersNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlans?: InputMaybe<InstallmentPlanOfferUpdateManyWithoutOfferNestedInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  offerCodes?: InputMaybe<OfferCodeUpdateManyWithoutOfferNestedInput>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<OfferTypeUpdateOneWithoutOffersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  validTimePeriodsCount?: InputMaybe<Scalars["Int"]["input"]>;
  validTo?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferUpdateManyMutationInput = {
  amountFactor?: InputMaybe<Scalars["Float"]["input"]>;
  amountValue?: InputMaybe<Scalars["Float"]["input"]>;
  budgetLimit?: InputMaybe<Scalars["Float"]["input"]>;
  budgetUsed?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  currentUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  validTimePeriodsCount?: InputMaybe<Scalars["Int"]["input"]>;
  validTo?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferUpdateManyWithWhereWithoutDepartmentInput = {
  data: OfferUpdateManyMutationInput;
  where: OfferScalarWhereInput;
};

export type OfferUpdateManyWithoutDepartmentNestedInput = {
  connect?: InputMaybe<Array<OfferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<OfferCreateOrConnectWithoutDepartmentInput>
  >;
  create?: InputMaybe<Array<OfferCreateWithoutDepartmentInput>>;
  createMany?: InputMaybe<OfferCreateManyDepartmentInputEnvelope>;
  delete?: InputMaybe<Array<OfferWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OfferScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OfferWhereUniqueInput>>;
  set?: InputMaybe<Array<OfferWhereUniqueInput>>;
  update?: InputMaybe<Array<OfferUpdateWithWhereUniqueWithoutDepartmentInput>>;
  updateMany?: InputMaybe<
    Array<OfferUpdateManyWithWhereWithoutDepartmentInput>
  >;
  upsert?: InputMaybe<Array<OfferUpsertWithWhereUniqueWithoutDepartmentInput>>;
};

export type OfferUpdateOneRequiredWithoutInstallmentPlansNestedInput = {
  connect?: InputMaybe<OfferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfferCreateOrConnectWithoutInstallmentPlansInput>;
  create?: InputMaybe<OfferCreateWithoutInstallmentPlansInput>;
  update?: InputMaybe<OfferUpdateToOneWithWhereWithoutInstallmentPlansInput>;
  upsert?: InputMaybe<OfferUpsertWithoutInstallmentPlansInput>;
};

export type OfferUpdateOneWithoutOfferCodesNestedInput = {
  connect?: InputMaybe<OfferWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfferCreateOrConnectWithoutOfferCodesInput>;
  create?: InputMaybe<OfferCreateWithoutOfferCodesInput>;
  delete?: InputMaybe<OfferWhereInput>;
  disconnect?: InputMaybe<OfferWhereInput>;
  update?: InputMaybe<OfferUpdateToOneWithWhereWithoutOfferCodesInput>;
  upsert?: InputMaybe<OfferUpsertWithoutOfferCodesInput>;
};

export type OfferUpdateToOneWithWhereWithoutInstallmentPlansInput = {
  data: OfferUpdateWithoutInstallmentPlansInput;
  where?: InputMaybe<OfferWhereInput>;
};

export type OfferUpdateToOneWithWhereWithoutOfferCodesInput = {
  data: OfferUpdateWithoutOfferCodesInput;
  where?: InputMaybe<OfferWhereInput>;
};

export type OfferUpdateWithWhereUniqueWithoutDepartmentInput = {
  data: OfferUpdateWithoutDepartmentInput;
  where: OfferWhereUniqueInput;
};

export type OfferUpdateWithoutDepartmentInput = {
  amountFactor?: InputMaybe<Scalars["Float"]["input"]>;
  amountValue?: InputMaybe<Scalars["Float"]["input"]>;
  budgetLimit?: InputMaybe<Scalars["Float"]["input"]>;
  budgetUsed?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  currentUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlans?: InputMaybe<InstallmentPlanOfferUpdateManyWithoutOfferNestedInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  offerCodes?: InputMaybe<OfferCodeUpdateManyWithoutOfferNestedInput>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<OfferTypeUpdateOneWithoutOffersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  validTimePeriodsCount?: InputMaybe<Scalars["Int"]["input"]>;
  validTo?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferUpdateWithoutInstallmentPlansInput = {
  amountFactor?: InputMaybe<Scalars["Float"]["input"]>;
  amountValue?: InputMaybe<Scalars["Float"]["input"]>;
  budgetLimit?: InputMaybe<Scalars["Float"]["input"]>;
  budgetUsed?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  currentUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  department?: InputMaybe<DepartmentUpdateOneWithoutOffersNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  offerCodes?: InputMaybe<OfferCodeUpdateManyWithoutOfferNestedInput>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<OfferTypeUpdateOneWithoutOffersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  validTimePeriodsCount?: InputMaybe<Scalars["Int"]["input"]>;
  validTo?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferUpdateWithoutOfferCodesInput = {
  amountFactor?: InputMaybe<Scalars["Float"]["input"]>;
  amountValue?: InputMaybe<Scalars["Float"]["input"]>;
  budgetLimit?: InputMaybe<Scalars["Float"]["input"]>;
  budgetUsed?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  currentUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  department?: InputMaybe<DepartmentUpdateOneWithoutOffersNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlans?: InputMaybe<InstallmentPlanOfferUpdateManyWithoutOfferNestedInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxUsageCount?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<OfferTypeUpdateOneWithoutOffersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  validTimePeriodsCount?: InputMaybe<Scalars["Int"]["input"]>;
  validTo?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OfferUpsertWithWhereUniqueWithoutDepartmentInput = {
  create: OfferCreateWithoutDepartmentInput;
  update: OfferUpdateWithoutDepartmentInput;
  where: OfferWhereUniqueInput;
};

export type OfferUpsertWithoutInstallmentPlansInput = {
  create: OfferCreateWithoutInstallmentPlansInput;
  update: OfferUpdateWithoutInstallmentPlansInput;
  where?: InputMaybe<OfferWhereInput>;
};

export type OfferUpsertWithoutOfferCodesInput = {
  create: OfferCreateWithoutOfferCodesInput;
  update: OfferUpdateWithoutOfferCodesInput;
  where?: InputMaybe<OfferWhereInput>;
};

export type OfferUsage = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  count?: InputMaybe<Scalars["Float"]["input"]>;
};

export type OfferValidity = {
  __typename?: "OfferValidity";
  discountAmount?: Maybe<Scalars["Float"]["output"]>;
  isValid: Scalars["Boolean"]["output"];
  reason: Scalars["String"]["output"];
};

export type OfferWhereInput = {
  AND?: InputMaybe<Array<OfferWhereInput>>;
  NOT?: InputMaybe<Array<OfferWhereInput>>;
  OR?: InputMaybe<Array<OfferWhereInput>>;
  amountFactor?: InputMaybe<FloatFilter>;
  amountValue?: InputMaybe<FloatFilter>;
  budgetLimit?: InputMaybe<FloatFilter>;
  budgetUsed?: InputMaybe<FloatFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  currentUsageCount?: InputMaybe<IntFilter>;
  department?: InputMaybe<DepartmentRelationFilter>;
  departmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  installmentPlans?: InputMaybe<InstallmentPlanOfferListRelationFilter>;
  isActive?: InputMaybe<BoolFilter>;
  maxUsageCount?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  offerCodes?: InputMaybe<OfferCodeListRelationFilter>;
  offerTypeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  type?: InputMaybe<OfferTypeRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  validFrom?: InputMaybe<DateTimeFilter>;
  validTimePeriodsCount?: InputMaybe<IntFilter>;
  validTo?: InputMaybe<DateTimeFilter>;
};

export type OfferWhereUniqueInput = {
  AND?: InputMaybe<Array<OfferWhereInput>>;
  NOT?: InputMaybe<Array<OfferWhereInput>>;
  OR?: InputMaybe<Array<OfferWhereInput>>;
  amountFactor?: InputMaybe<FloatFilter>;
  amountValue?: InputMaybe<FloatFilter>;
  budgetLimit?: InputMaybe<FloatFilter>;
  budgetUsed?: InputMaybe<FloatFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  currentUsageCount?: InputMaybe<IntFilter>;
  department?: InputMaybe<DepartmentRelationFilter>;
  departmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlans?: InputMaybe<InstallmentPlanOfferListRelationFilter>;
  isActive?: InputMaybe<BoolFilter>;
  maxUsageCount?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  offerCodes?: InputMaybe<OfferCodeListRelationFilter>;
  offerTypeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  type?: InputMaybe<OfferTypeRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  validFrom?: InputMaybe<DateTimeFilter>;
  validTimePeriodsCount?: InputMaybe<IntFilter>;
  validTo?: InputMaybe<DateTimeFilter>;
};

export type OrderRouteInput = {
  routeDirectionality: RouteDirectionality;
  routeOptimizationConfig?: InputMaybe<RouteOptimizationConfigInput>;
  routeStopDataInput: Array<RouteStopDataInput>;
};

export type Otp = {
  __typename?: "Otp";
  attempts?: Maybe<Scalars["Int"]["output"]>;
  contact: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  deviceDetail?: Maybe<DeviceDetail>;
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  maxResendCooldown?: Maybe<Scalars["Int"]["output"]>;
  otpCode?: Maybe<Scalars["String"]["output"]>;
  otpReason?: Maybe<OtpReason>;
  otpStatus: OtpStatus;
  otpType: OtpType;
  resendCooldown?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type OtpConfig = {
  __typename?: "OtpConfig";
  attemptsCooldown: Scalars["Int"]["output"];
  maxAttempts: Scalars["Int"]["output"];
  maxResend: Scalars["Int"]["output"];
  maxResendCooldown: Scalars["Int"]["output"];
  resendCooldown: Scalars["Int"]["output"];
};

export type OtpCreateManyUserInput = {
  contact: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  maxResendCooldown?: InputMaybe<Scalars["Int"]["input"]>;
  otpReason?: InputMaybe<OtpReason>;
  otpType: OtpType;
  resendCooldown?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OtpCreateManyUserInputEnvelope = {
  data: Array<OtpCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type OtpCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<OtpWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OtpCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<OtpCreateWithoutUserInput>>;
  createMany?: InputMaybe<OtpCreateManyUserInputEnvelope>;
};

export type OtpCreateOrConnectWithoutUserInput = {
  create: OtpCreateWithoutUserInput;
  where: OtpWhereUniqueInput;
};

export type OtpCreateWithoutUserInput = {
  contact: Scalars["String"]["input"];
  deviceDetail?: InputMaybe<DeviceDetailCreateNestedOneWithoutOtpInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  maxResendCooldown?: InputMaybe<Scalars["Int"]["input"]>;
  otpReason?: InputMaybe<OtpReason>;
  otpType: OtpType;
  resendCooldown?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OtpListRelationFilter = {
  every?: InputMaybe<OtpWhereInput>;
  none?: InputMaybe<OtpWhereInput>;
  some?: InputMaybe<OtpWhereInput>;
};

export enum OtpReason {
  LOGIN = "LOGIN",
  RESET_PASSWORD = "RESET_PASSWORD",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  VERIFY_PHONE = "VERIFY_PHONE",
}

export type OtpRelationFilter = {
  is?: InputMaybe<OtpWhereInput>;
  isNot?: InputMaybe<OtpWhereInput>;
};

export type OtpRequest = {
  __typename?: "OtpRequest";
  attempts?: Maybe<Scalars["Float"]["output"]>;
  contact: Scalars["String"]["output"];
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  maxResendCooldown?: Maybe<Scalars["Float"]["output"]>;
  otpReason: OtpReason;
  otpStatus: OtpStatus;
  otpType: OtpType;
  plainOTP?: Maybe<Scalars["String"]["output"]>;
  resendCooldown?: Maybe<Scalars["Float"]["output"]>;
};

export type OtpRequestInput = {
  contact: Scalars["String"]["input"];
  deviceDetails: DeviceDetailsInput;
  lang?: InputMaybe<Language>;
  otpReason: OtpReason;
  otpType: OtpType;
};

export type OtpScalarWhereInput = {
  AND?: InputMaybe<Array<OtpScalarWhereInput>>;
  NOT?: InputMaybe<Array<OtpScalarWhereInput>>;
  OR?: InputMaybe<Array<OtpScalarWhereInput>>;
  contact?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  maxResendCooldown?: InputMaybe<IntFilter>;
  otpReason?: InputMaybe<EnumOtpReasonFilter>;
  otpType?: InputMaybe<EnumOtpTypeFilter>;
  resendCooldown?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum OtpStatus {
  BAD_CONTACT = "BAD_CONTACT",
  COOLDOWN = "COOLDOWN",
  MAX_ATTEMPTS = "MAX_ATTEMPTS",
  MAX_RESEND_PER_DAY = "MAX_RESEND_PER_DAY",
  NO_OTP = "NO_OTP",
  NO_USER = "NO_USER",
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
}

export enum OtpType {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export type OtpUpdateManyMutationInput = {
  contact?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  maxResendCooldown?: InputMaybe<Scalars["Int"]["input"]>;
  otpReason?: InputMaybe<OtpReason>;
  otpType?: InputMaybe<OtpType>;
  resendCooldown?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OtpUpdateManyWithWhereWithoutUserInput = {
  data: OtpUpdateManyMutationInput;
  where: OtpScalarWhereInput;
};

export type OtpUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<OtpWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OtpCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<OtpCreateWithoutUserInput>>;
  createMany?: InputMaybe<OtpCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<OtpWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OtpScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OtpWhereUniqueInput>>;
  set?: InputMaybe<Array<OtpWhereUniqueInput>>;
  update?: InputMaybe<Array<OtpUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<OtpUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<OtpUpsertWithWhereUniqueWithoutUserInput>>;
};

export type OtpUpdateWithWhereUniqueWithoutUserInput = {
  data: OtpUpdateWithoutUserInput;
  where: OtpWhereUniqueInput;
};

export type OtpUpdateWithoutUserInput = {
  contact?: InputMaybe<Scalars["String"]["input"]>;
  deviceDetail?: InputMaybe<DeviceDetailUpdateOneWithoutOtpNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  maxResendCooldown?: InputMaybe<Scalars["Int"]["input"]>;
  otpReason?: InputMaybe<OtpReason>;
  otpType?: InputMaybe<OtpType>;
  resendCooldown?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OtpUpsertWithWhereUniqueWithoutUserInput = {
  create: OtpCreateWithoutUserInput;
  update: OtpUpdateWithoutUserInput;
  where: OtpWhereUniqueInput;
};

export type OtpVerification = {
  __typename?: "OtpVerification";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  attempts?: Maybe<Scalars["Float"]["output"]>;
  attemptsCooldown?: Maybe<Scalars["Float"]["output"]>;
  contact: Scalars["String"]["output"];
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  otpReason?: Maybe<OtpReason>;
  otpStatus: OtpStatus;
  otpType: OtpType;
  refreshToken?: Maybe<Scalars["String"]["output"]>;
  roles?: Maybe<Array<Scalars["String"]["output"]>>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type OtpVerifyInput = {
  contact: Scalars["String"]["input"];
  otpCode: Scalars["String"]["input"];
  otpType: OtpType;
};

export type OtpWhereInput = {
  AND?: InputMaybe<Array<OtpWhereInput>>;
  NOT?: InputMaybe<Array<OtpWhereInput>>;
  OR?: InputMaybe<Array<OtpWhereInput>>;
  contact?: InputMaybe<StringFilter>;
  deviceDetail?: InputMaybe<DeviceDetailRelationFilter>;
  id?: InputMaybe<StringFilter>;
  maxResendCooldown?: InputMaybe<IntFilter>;
  otpReason?: InputMaybe<EnumOtpReasonFilter>;
  otpType?: InputMaybe<EnumOtpTypeFilter>;
  resendCooldown?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OtpWhereUniqueInput = {
  AND?: InputMaybe<Array<OtpWhereInput>>;
  NOT?: InputMaybe<Array<OtpWhereInput>>;
  OR?: InputMaybe<Array<OtpWhereInput>>;
  contact?: InputMaybe<StringFilter>;
  deviceDetail?: InputMaybe<DeviceDetailRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  maxResendCooldown?: InputMaybe<IntFilter>;
  otpReason?: InputMaybe<EnumOtpReasonFilter>;
  otpType?: InputMaybe<EnumOtpTypeFilter>;
  resendCooldown?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaginatedFleetVehicles = {
  __typename?: "PaginatedFleetVehicles";
  data?: Maybe<Array<FleetVehicle>>;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedRequestInput = {
  skip: Scalars["Float"]["input"];
  take: Scalars["Float"]["input"];
};

export type ParentChildInput = {
  academicYear?: InputMaybe<Scalars["Float"]["input"]>;
  address: CoordinateInput;
  childId: Scalars["Float"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
  tripType: TripType;
};

export type PartialCreateServiceProviderBankDetails = {
  accountName?: InputMaybe<Scalars["String"]["input"]>;
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  bank?: InputMaybe<BankCreateNestedOneWithoutBanksDetailsInput>;
};

export type PartialCreateServiceProviderSubscriptionPlan = {
  endDate?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ServiceProviderPlanStatus>;
};

export type Passenger = {
  __typename?: "Passenger";
  _count: PassengerCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  passengerSpecialNeeds?: Maybe<Array<SpecialNeedPassenger>>;
  sponsors?: Maybe<Array<PassengerSponsor>>;
  student?: Maybe<Student>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type PassengerCount = {
  __typename?: "PassengerCount";
  passengerSpecialNeeds: Scalars["Int"]["output"];
  sponsors: Scalars["Int"]["output"];
};

export type PassengerCreateNestedOneWithoutPassengerSpecialNeedsInput = {
  connect?: InputMaybe<PassengerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassengerCreateOrConnectWithoutPassengerSpecialNeedsInput>;
  create?: InputMaybe<PassengerCreateWithoutPassengerSpecialNeedsInput>;
};

export type PassengerCreateNestedOneWithoutSponsorsInput = {
  connect?: InputMaybe<PassengerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassengerCreateOrConnectWithoutSponsorsInput>;
  create?: InputMaybe<PassengerCreateWithoutSponsorsInput>;
};

export type PassengerCreateNestedOneWithoutStudentInput = {
  connect?: InputMaybe<PassengerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassengerCreateOrConnectWithoutStudentInput>;
  create?: InputMaybe<PassengerCreateWithoutStudentInput>;
};

export type PassengerCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<PassengerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassengerCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<PassengerCreateWithoutUserInput>;
};

export type PassengerCreateOrConnectWithoutPassengerSpecialNeedsInput = {
  create: PassengerCreateWithoutPassengerSpecialNeedsInput;
  where: PassengerWhereUniqueInput;
};

export type PassengerCreateOrConnectWithoutSponsorsInput = {
  create: PassengerCreateWithoutSponsorsInput;
  where: PassengerWhereUniqueInput;
};

export type PassengerCreateOrConnectWithoutStudentInput = {
  create: PassengerCreateWithoutStudentInput;
  where: PassengerWhereUniqueInput;
};

export type PassengerCreateOrConnectWithoutUserInput = {
  create: PassengerCreateWithoutUserInput;
  where: PassengerWhereUniqueInput;
};

export type PassengerCreateWithoutPassengerSpecialNeedsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  sponsors?: InputMaybe<PassengerSponsorCreateNestedManyWithoutPassengerInput>;
  student?: InputMaybe<StudentCreateNestedOneWithoutPassengerInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutPassengerInput;
};

export type PassengerCreateWithoutSponsorsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengerSpecialNeeds?: InputMaybe<SpecialNeedPassengerCreateNestedManyWithoutPassengerInput>;
  student?: InputMaybe<StudentCreateNestedOneWithoutPassengerInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutPassengerInput;
};

export type PassengerCreateWithoutStudentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengerSpecialNeeds?: InputMaybe<SpecialNeedPassengerCreateNestedManyWithoutPassengerInput>;
  sponsors?: InputMaybe<PassengerSponsorCreateNestedManyWithoutPassengerInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutPassengerInput;
};

export type PassengerCreateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengerSpecialNeeds?: InputMaybe<SpecialNeedPassengerCreateNestedManyWithoutPassengerInput>;
  sponsors?: InputMaybe<PassengerSponsorCreateNestedManyWithoutPassengerInput>;
  student?: InputMaybe<StudentCreateNestedOneWithoutPassengerInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PassengerRelationFilter = {
  is?: InputMaybe<PassengerWhereInput>;
  isNot?: InputMaybe<PassengerWhereInput>;
};

export type PassengerSponsor = {
  __typename?: "PassengerSponsor";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isPrimary: Scalars["Boolean"]["output"];
  passenger: Passenger;
  passengerId: Scalars["String"]["output"];
  relationship?: Maybe<Relationship>;
  sponsor: Sponsor;
  sponsorId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type PassengerSponsorCreateManyPassengerInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  relationship?: InputMaybe<Relationship>;
  sponsorId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PassengerSponsorCreateManyPassengerInputEnvelope = {
  data: Array<PassengerSponsorCreateManyPassengerInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PassengerSponsorCreateManySponsorInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  passengerId: Scalars["String"]["input"];
  relationship?: InputMaybe<Relationship>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PassengerSponsorCreateManySponsorInputEnvelope = {
  data: Array<PassengerSponsorCreateManySponsorInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PassengerSponsorCreateNestedManyWithoutPassengerInput = {
  connect?: InputMaybe<Array<PassengerSponsorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PassengerSponsorCreateOrConnectWithoutPassengerInput>
  >;
  create?: InputMaybe<Array<PassengerSponsorCreateWithoutPassengerInput>>;
  createMany?: InputMaybe<PassengerSponsorCreateManyPassengerInputEnvelope>;
};

export type PassengerSponsorCreateNestedManyWithoutSponsorInput = {
  connect?: InputMaybe<Array<PassengerSponsorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PassengerSponsorCreateOrConnectWithoutSponsorInput>
  >;
  create?: InputMaybe<Array<PassengerSponsorCreateWithoutSponsorInput>>;
  createMany?: InputMaybe<PassengerSponsorCreateManySponsorInputEnvelope>;
};

export type PassengerSponsorCreateOrConnectWithoutPassengerInput = {
  create: PassengerSponsorCreateWithoutPassengerInput;
  where: PassengerSponsorWhereUniqueInput;
};

export type PassengerSponsorCreateOrConnectWithoutSponsorInput = {
  create: PassengerSponsorCreateWithoutSponsorInput;
  where: PassengerSponsorWhereUniqueInput;
};

export type PassengerSponsorCreateWithoutPassengerInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  relationship?: InputMaybe<Relationship>;
  sponsor: SponsorCreateNestedOneWithoutPassengersInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PassengerSponsorCreateWithoutSponsorInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  passenger: PassengerCreateNestedOneWithoutSponsorsInput;
  relationship?: InputMaybe<Relationship>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PassengerSponsorListRelationFilter = {
  every?: InputMaybe<PassengerSponsorWhereInput>;
  none?: InputMaybe<PassengerSponsorWhereInput>;
  some?: InputMaybe<PassengerSponsorWhereInput>;
};

export type PassengerSponsorScalarWhereInput = {
  AND?: InputMaybe<Array<PassengerSponsorScalarWhereInput>>;
  NOT?: InputMaybe<Array<PassengerSponsorScalarWhereInput>>;
  OR?: InputMaybe<Array<PassengerSponsorScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isPrimary?: InputMaybe<BoolFilter>;
  passengerId?: InputMaybe<StringFilter>;
  relationship?: InputMaybe<EnumRelationshipFilter>;
  sponsorId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PassengerSponsorSponsorIdPassengerIdArchivedAtCompoundUniqueInput =
  {
    passengerId: Scalars["String"]["input"];
    sponsorId: Scalars["String"]["input"];
  };

export type PassengerSponsorUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  relationship?: InputMaybe<Relationship>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PassengerSponsorUpdateManyWithWhereWithoutPassengerInput = {
  data: PassengerSponsorUpdateManyMutationInput;
  where: PassengerSponsorScalarWhereInput;
};

export type PassengerSponsorUpdateManyWithWhereWithoutSponsorInput = {
  data: PassengerSponsorUpdateManyMutationInput;
  where: PassengerSponsorScalarWhereInput;
};

export type PassengerSponsorUpdateManyWithoutPassengerNestedInput = {
  connect?: InputMaybe<Array<PassengerSponsorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PassengerSponsorCreateOrConnectWithoutPassengerInput>
  >;
  create?: InputMaybe<Array<PassengerSponsorCreateWithoutPassengerInput>>;
  createMany?: InputMaybe<PassengerSponsorCreateManyPassengerInputEnvelope>;
  delete?: InputMaybe<Array<PassengerSponsorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PassengerSponsorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PassengerSponsorWhereUniqueInput>>;
  set?: InputMaybe<Array<PassengerSponsorWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PassengerSponsorUpdateWithWhereUniqueWithoutPassengerInput>
  >;
  updateMany?: InputMaybe<
    Array<PassengerSponsorUpdateManyWithWhereWithoutPassengerInput>
  >;
  upsert?: InputMaybe<
    Array<PassengerSponsorUpsertWithWhereUniqueWithoutPassengerInput>
  >;
};

export type PassengerSponsorUpdateManyWithoutSponsorNestedInput = {
  connect?: InputMaybe<Array<PassengerSponsorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PassengerSponsorCreateOrConnectWithoutSponsorInput>
  >;
  create?: InputMaybe<Array<PassengerSponsorCreateWithoutSponsorInput>>;
  createMany?: InputMaybe<PassengerSponsorCreateManySponsorInputEnvelope>;
  delete?: InputMaybe<Array<PassengerSponsorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PassengerSponsorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PassengerSponsorWhereUniqueInput>>;
  set?: InputMaybe<Array<PassengerSponsorWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PassengerSponsorUpdateWithWhereUniqueWithoutSponsorInput>
  >;
  updateMany?: InputMaybe<
    Array<PassengerSponsorUpdateManyWithWhereWithoutSponsorInput>
  >;
  upsert?: InputMaybe<
    Array<PassengerSponsorUpsertWithWhereUniqueWithoutSponsorInput>
  >;
};

export type PassengerSponsorUpdateWithWhereUniqueWithoutPassengerInput = {
  data: PassengerSponsorUpdateWithoutPassengerInput;
  where: PassengerSponsorWhereUniqueInput;
};

export type PassengerSponsorUpdateWithWhereUniqueWithoutSponsorInput = {
  data: PassengerSponsorUpdateWithoutSponsorInput;
  where: PassengerSponsorWhereUniqueInput;
};

export type PassengerSponsorUpdateWithoutPassengerInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  relationship?: InputMaybe<Relationship>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutPassengersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PassengerSponsorUpdateWithoutSponsorInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  passenger?: InputMaybe<PassengerUpdateOneRequiredWithoutSponsorsNestedInput>;
  relationship?: InputMaybe<Relationship>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PassengerSponsorUpsertWithWhereUniqueWithoutPassengerInput = {
  create: PassengerSponsorCreateWithoutPassengerInput;
  update: PassengerSponsorUpdateWithoutPassengerInput;
  where: PassengerSponsorWhereUniqueInput;
};

export type PassengerSponsorUpsertWithWhereUniqueWithoutSponsorInput = {
  create: PassengerSponsorCreateWithoutSponsorInput;
  update: PassengerSponsorUpdateWithoutSponsorInput;
  where: PassengerSponsorWhereUniqueInput;
};

export type PassengerSponsorWhereInput = {
  AND?: InputMaybe<Array<PassengerSponsorWhereInput>>;
  NOT?: InputMaybe<Array<PassengerSponsorWhereInput>>;
  OR?: InputMaybe<Array<PassengerSponsorWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isPrimary?: InputMaybe<BoolFilter>;
  passenger?: InputMaybe<PassengerRelationFilter>;
  passengerId?: InputMaybe<StringFilter>;
  relationship?: InputMaybe<EnumRelationshipFilter>;
  sponsor?: InputMaybe<SponsorRelationFilter>;
  sponsorId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PassengerSponsorWhereUniqueInput = {
  AND?: InputMaybe<Array<PassengerSponsorWhereInput>>;
  NOT?: InputMaybe<Array<PassengerSponsorWhereInput>>;
  OR?: InputMaybe<Array<PassengerSponsorWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<BoolFilter>;
  passenger?: InputMaybe<PassengerRelationFilter>;
  passengerId?: InputMaybe<StringFilter>;
  relationship?: InputMaybe<EnumRelationshipFilter>;
  sponsor?: InputMaybe<SponsorRelationFilter>;
  sponsorId?: InputMaybe<StringFilter>;
  sponsorId_passengerId_archivedAt?: InputMaybe<PassengerSponsorSponsorIdPassengerIdArchivedAtCompoundUniqueInput>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PassengerUpdateOneRequiredWithoutPassengerSpecialNeedsNestedInput =
  {
    connect?: InputMaybe<PassengerWhereUniqueInput>;
    connectOrCreate?: InputMaybe<PassengerCreateOrConnectWithoutPassengerSpecialNeedsInput>;
    create?: InputMaybe<PassengerCreateWithoutPassengerSpecialNeedsInput>;
    update?: InputMaybe<PassengerUpdateToOneWithWhereWithoutPassengerSpecialNeedsInput>;
    upsert?: InputMaybe<PassengerUpsertWithoutPassengerSpecialNeedsInput>;
  };

export type PassengerUpdateOneRequiredWithoutSponsorsNestedInput = {
  connect?: InputMaybe<PassengerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassengerCreateOrConnectWithoutSponsorsInput>;
  create?: InputMaybe<PassengerCreateWithoutSponsorsInput>;
  update?: InputMaybe<PassengerUpdateToOneWithWhereWithoutSponsorsInput>;
  upsert?: InputMaybe<PassengerUpsertWithoutSponsorsInput>;
};

export type PassengerUpdateOneWithoutStudentNestedInput = {
  connect?: InputMaybe<PassengerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassengerCreateOrConnectWithoutStudentInput>;
  create?: InputMaybe<PassengerCreateWithoutStudentInput>;
  delete?: InputMaybe<PassengerWhereInput>;
  disconnect?: InputMaybe<PassengerWhereInput>;
  update?: InputMaybe<PassengerUpdateToOneWithWhereWithoutStudentInput>;
  upsert?: InputMaybe<PassengerUpsertWithoutStudentInput>;
};

export type PassengerUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<PassengerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PassengerCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<PassengerCreateWithoutUserInput>;
  delete?: InputMaybe<PassengerWhereInput>;
  disconnect?: InputMaybe<PassengerWhereInput>;
  update?: InputMaybe<PassengerUpdateToOneWithWhereWithoutUserInput>;
  upsert?: InputMaybe<PassengerUpsertWithoutUserInput>;
};

export type PassengerUpdateToOneWithWhereWithoutPassengerSpecialNeedsInput = {
  data: PassengerUpdateWithoutPassengerSpecialNeedsInput;
  where?: InputMaybe<PassengerWhereInput>;
};

export type PassengerUpdateToOneWithWhereWithoutSponsorsInput = {
  data: PassengerUpdateWithoutSponsorsInput;
  where?: InputMaybe<PassengerWhereInput>;
};

export type PassengerUpdateToOneWithWhereWithoutStudentInput = {
  data: PassengerUpdateWithoutStudentInput;
  where?: InputMaybe<PassengerWhereInput>;
};

export type PassengerUpdateToOneWithWhereWithoutUserInput = {
  data: PassengerUpdateWithoutUserInput;
  where?: InputMaybe<PassengerWhereInput>;
};

export type PassengerUpdateWithoutPassengerSpecialNeedsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  sponsors?: InputMaybe<PassengerSponsorUpdateManyWithoutPassengerNestedInput>;
  student?: InputMaybe<StudentUpdateOneWithoutPassengerNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPassengerNestedInput>;
};

export type PassengerUpdateWithoutSponsorsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengerSpecialNeeds?: InputMaybe<SpecialNeedPassengerUpdateManyWithoutPassengerNestedInput>;
  student?: InputMaybe<StudentUpdateOneWithoutPassengerNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPassengerNestedInput>;
};

export type PassengerUpdateWithoutStudentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengerSpecialNeeds?: InputMaybe<SpecialNeedPassengerUpdateManyWithoutPassengerNestedInput>;
  sponsors?: InputMaybe<PassengerSponsorUpdateManyWithoutPassengerNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPassengerNestedInput>;
};

export type PassengerUpdateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengerSpecialNeeds?: InputMaybe<SpecialNeedPassengerUpdateManyWithoutPassengerNestedInput>;
  sponsors?: InputMaybe<PassengerSponsorUpdateManyWithoutPassengerNestedInput>;
  student?: InputMaybe<StudentUpdateOneWithoutPassengerNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PassengerUpsertWithoutPassengerSpecialNeedsInput = {
  create: PassengerCreateWithoutPassengerSpecialNeedsInput;
  update: PassengerUpdateWithoutPassengerSpecialNeedsInput;
  where?: InputMaybe<PassengerWhereInput>;
};

export type PassengerUpsertWithoutSponsorsInput = {
  create: PassengerCreateWithoutSponsorsInput;
  update: PassengerUpdateWithoutSponsorsInput;
  where?: InputMaybe<PassengerWhereInput>;
};

export type PassengerUpsertWithoutStudentInput = {
  create: PassengerCreateWithoutStudentInput;
  update: PassengerUpdateWithoutStudentInput;
  where?: InputMaybe<PassengerWhereInput>;
};

export type PassengerUpsertWithoutUserInput = {
  create: PassengerCreateWithoutUserInput;
  update: PassengerUpdateWithoutUserInput;
  where?: InputMaybe<PassengerWhereInput>;
};

export type PassengerWhereInput = {
  AND?: InputMaybe<Array<PassengerWhereInput>>;
  NOT?: InputMaybe<Array<PassengerWhereInput>>;
  OR?: InputMaybe<Array<PassengerWhereInput>>;
  id?: InputMaybe<StringFilter>;
  passengerSpecialNeeds?: InputMaybe<SpecialNeedPassengerListRelationFilter>;
  sponsors?: InputMaybe<PassengerSponsorListRelationFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type PassengerWhereUniqueInput = {
  AND?: InputMaybe<Array<PassengerWhereInput>>;
  NOT?: InputMaybe<Array<PassengerWhereInput>>;
  OR?: InputMaybe<Array<PassengerWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengerSpecialNeeds?: InputMaybe<SpecialNeedPassengerListRelationFilter>;
  sponsors?: InputMaybe<PassengerSponsorListRelationFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type PaymentLineItem = {
  __typename?: "PaymentLineItem";
  amount: Scalars["Float"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  currency: CurrencyCode;
  deposit?: Maybe<Deposit>;
  depositId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  idempotencyId?: Maybe<Scalars["String"]["output"]>;
  offerCode?: Maybe<OfferCode>;
  offerCodeId?: Maybe<Scalars["String"]["output"]>;
  paymentTransaction?: Maybe<PaymentTransaction>;
  paymentTransactionId?: Maybe<Scalars["String"]["output"]>;
  refundTransaction?: Maybe<PaymentTransaction>;
  refundTransactionId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistration: SchoolRegistration;
  schoolRegistrationId: Scalars["String"]["output"];
  timePeriod?: Maybe<TimePeriod>;
  timePeriodId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type PaymentLineItemCreateManyDepositInput = {
  amount: Scalars["Float"]["input"];
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCodeId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  refundTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId: Scalars["String"]["input"];
  timePeriodId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemCreateManyDepositInputEnvelope = {
  data: Array<PaymentLineItemCreateManyDepositInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PaymentLineItemCreateManyOfferCodeInput = {
  amount: Scalars["Float"]["input"];
  currency: CurrencyCode;
  depositId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  refundTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId: Scalars["String"]["input"];
  timePeriodId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemCreateManyOfferCodeInputEnvelope = {
  data: Array<PaymentLineItemCreateManyOfferCodeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PaymentLineItemCreateManyPaymentTransactionInput = {
  amount: Scalars["Float"]["input"];
  currency: CurrencyCode;
  depositId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCodeId?: InputMaybe<Scalars["String"]["input"]>;
  refundTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId: Scalars["String"]["input"];
  timePeriodId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemCreateManyPaymentTransactionInputEnvelope = {
  data: Array<PaymentLineItemCreateManyPaymentTransactionInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PaymentLineItemCreateManyRefundTransactionInput = {
  amount: Scalars["Float"]["input"];
  currency: CurrencyCode;
  depositId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCodeId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId: Scalars["String"]["input"];
  timePeriodId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemCreateManyRefundTransactionInputEnvelope = {
  data: Array<PaymentLineItemCreateManyRefundTransactionInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PaymentLineItemCreateManyTimePeriodInput = {
  amount: Scalars["Float"]["input"];
  currency: CurrencyCode;
  depositId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCodeId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  refundTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemCreateManyTimePeriodInputEnvelope = {
  data: Array<PaymentLineItemCreateManyTimePeriodInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PaymentLineItemCreateNestedManyWithoutDepositInput = {
  connect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentLineItemCreateOrConnectWithoutDepositInput>
  >;
  create?: InputMaybe<Array<PaymentLineItemCreateWithoutDepositInput>>;
  createMany?: InputMaybe<PaymentLineItemCreateManyDepositInputEnvelope>;
};

export type PaymentLineItemCreateNestedManyWithoutOfferCodeInput = {
  connect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentLineItemCreateOrConnectWithoutOfferCodeInput>
  >;
  create?: InputMaybe<Array<PaymentLineItemCreateWithoutOfferCodeInput>>;
  createMany?: InputMaybe<PaymentLineItemCreateManyOfferCodeInputEnvelope>;
};

export type PaymentLineItemCreateNestedManyWithoutPaymentTransactionInput = {
  connect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentLineItemCreateOrConnectWithoutPaymentTransactionInput>
  >;
  create?: InputMaybe<
    Array<PaymentLineItemCreateWithoutPaymentTransactionInput>
  >;
  createMany?: InputMaybe<PaymentLineItemCreateManyPaymentTransactionInputEnvelope>;
};

export type PaymentLineItemCreateNestedManyWithoutRefundTransactionInput = {
  connect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentLineItemCreateOrConnectWithoutRefundTransactionInput>
  >;
  create?: InputMaybe<
    Array<PaymentLineItemCreateWithoutRefundTransactionInput>
  >;
  createMany?: InputMaybe<PaymentLineItemCreateManyRefundTransactionInputEnvelope>;
};

export type PaymentLineItemCreateNestedManyWithoutTimePeriodInput = {
  connect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentLineItemCreateOrConnectWithoutTimePeriodInput>
  >;
  create?: InputMaybe<Array<PaymentLineItemCreateWithoutTimePeriodInput>>;
  createMany?: InputMaybe<PaymentLineItemCreateManyTimePeriodInputEnvelope>;
};

export type PaymentLineItemCreateOrConnectWithoutDepositInput = {
  create: PaymentLineItemCreateWithoutDepositInput;
  where: PaymentLineItemWhereUniqueInput;
};

export type PaymentLineItemCreateOrConnectWithoutOfferCodeInput = {
  create: PaymentLineItemCreateWithoutOfferCodeInput;
  where: PaymentLineItemWhereUniqueInput;
};

export type PaymentLineItemCreateOrConnectWithoutPaymentTransactionInput = {
  create: PaymentLineItemCreateWithoutPaymentTransactionInput;
  where: PaymentLineItemWhereUniqueInput;
};

export type PaymentLineItemCreateOrConnectWithoutRefundTransactionInput = {
  create: PaymentLineItemCreateWithoutRefundTransactionInput;
  where: PaymentLineItemWhereUniqueInput;
};

export type PaymentLineItemCreateOrConnectWithoutTimePeriodInput = {
  create: PaymentLineItemCreateWithoutTimePeriodInput;
  where: PaymentLineItemWhereUniqueInput;
};

export type PaymentLineItemCreateWithoutDepositInput = {
  amount: Scalars["Float"]["input"];
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeCreateNestedOneWithoutPaymentLineItemsInput>;
  paymentTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutPaymentLineItemsInput>;
  refundTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutRefundLineItemsInput>;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutPaymentLineItemsInput;
  timePeriod?: InputMaybe<TimePeriodCreateNestedOneWithoutPaymentLineItemsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemCreateWithoutOfferCodeInput = {
  amount: Scalars["Float"]["input"];
  currency: CurrencyCode;
  deposit?: InputMaybe<DepositCreateNestedOneWithoutPaymentLineItemsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutPaymentLineItemsInput>;
  refundTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutRefundLineItemsInput>;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutPaymentLineItemsInput;
  timePeriod?: InputMaybe<TimePeriodCreateNestedOneWithoutPaymentLineItemsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemCreateWithoutPaymentTransactionInput = {
  amount: Scalars["Float"]["input"];
  currency: CurrencyCode;
  deposit?: InputMaybe<DepositCreateNestedOneWithoutPaymentLineItemsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeCreateNestedOneWithoutPaymentLineItemsInput>;
  refundTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutRefundLineItemsInput>;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutPaymentLineItemsInput;
  timePeriod?: InputMaybe<TimePeriodCreateNestedOneWithoutPaymentLineItemsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemCreateWithoutRefundTransactionInput = {
  amount: Scalars["Float"]["input"];
  currency: CurrencyCode;
  deposit?: InputMaybe<DepositCreateNestedOneWithoutPaymentLineItemsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeCreateNestedOneWithoutPaymentLineItemsInput>;
  paymentTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutPaymentLineItemsInput>;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutPaymentLineItemsInput;
  timePeriod?: InputMaybe<TimePeriodCreateNestedOneWithoutPaymentLineItemsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemCreateWithoutTimePeriodInput = {
  amount: Scalars["Float"]["input"];
  currency: CurrencyCode;
  deposit?: InputMaybe<DepositCreateNestedOneWithoutPaymentLineItemsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeCreateNestedOneWithoutPaymentLineItemsInput>;
  paymentTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutPaymentLineItemsInput>;
  refundTransaction?: InputMaybe<PaymentTransactionCreateNestedOneWithoutRefundLineItemsInput>;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutPaymentLineItemsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemDate = {
  __typename?: "PaymentLineItemDate";
  date?: Maybe<Scalars["DateTime"]["output"]>;
  isDeposit: Scalars["Boolean"]["output"];
  isRequired: Scalars["Boolean"]["output"];
  timePeriodId?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentLineItemForPricing = {
  __typename?: "PaymentLineItemForPricing";
  amount: Scalars["Float"]["output"];
  amountWithoutVat: Scalars["Float"]["output"];
  dueDate?: Maybe<Scalars["DateTime"]["output"]>;
  isDeposit: Scalars["Boolean"]["output"];
  isRequired: Scalars["Boolean"]["output"];
  timePeriodId?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentLineItemListRelationFilter = {
  every?: InputMaybe<PaymentLineItemWhereInput>;
  none?: InputMaybe<PaymentLineItemWhereInput>;
  some?: InputMaybe<PaymentLineItemWhereInput>;
};

export type PaymentLineItemScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentLineItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentLineItemScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentLineItemScalarWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  depositId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  idempotencyId?: InputMaybe<StringFilter>;
  offerCodeId?: InputMaybe<StringFilter>;
  paymentTransactionId?: InputMaybe<StringFilter>;
  refundTransactionId?: InputMaybe<StringFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  timePeriodId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum PaymentLineItemStatus {
  NOT_REQUIRED = "NOT_REQUIRED",
  PAID = "PAID",
  REQUIRED = "REQUIRED",
}

export type PaymentLineItemUpdateInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  deposit?: InputMaybe<DepositUpdateOneWithoutPaymentLineItemsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeUpdateOneWithoutPaymentLineItemsNestedInput>;
  paymentTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutPaymentLineItemsNestedInput>;
  refundTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutRefundLineItemsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutPaymentLineItemsNestedInput>;
  timePeriod?: InputMaybe<TimePeriodUpdateOneWithoutPaymentLineItemsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemUpdateManyMutationInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemUpdateManyWithWhereWithoutDepositInput = {
  data: PaymentLineItemUpdateManyMutationInput;
  where: PaymentLineItemScalarWhereInput;
};

export type PaymentLineItemUpdateManyWithWhereWithoutOfferCodeInput = {
  data: PaymentLineItemUpdateManyMutationInput;
  where: PaymentLineItemScalarWhereInput;
};

export type PaymentLineItemUpdateManyWithWhereWithoutPaymentTransactionInput = {
  data: PaymentLineItemUpdateManyMutationInput;
  where: PaymentLineItemScalarWhereInput;
};

export type PaymentLineItemUpdateManyWithWhereWithoutRefundTransactionInput = {
  data: PaymentLineItemUpdateManyMutationInput;
  where: PaymentLineItemScalarWhereInput;
};

export type PaymentLineItemUpdateManyWithWhereWithoutTimePeriodInput = {
  data: PaymentLineItemUpdateManyMutationInput;
  where: PaymentLineItemScalarWhereInput;
};

export type PaymentLineItemUpdateManyWithoutDepositNestedInput = {
  connect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentLineItemCreateOrConnectWithoutDepositInput>
  >;
  create?: InputMaybe<Array<PaymentLineItemCreateWithoutDepositInput>>;
  createMany?: InputMaybe<PaymentLineItemCreateManyDepositInputEnvelope>;
  delete?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentLineItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PaymentLineItemUpdateWithWhereUniqueWithoutDepositInput>
  >;
  updateMany?: InputMaybe<
    Array<PaymentLineItemUpdateManyWithWhereWithoutDepositInput>
  >;
  upsert?: InputMaybe<
    Array<PaymentLineItemUpsertWithWhereUniqueWithoutDepositInput>
  >;
};

export type PaymentLineItemUpdateManyWithoutOfferCodeNestedInput = {
  connect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentLineItemCreateOrConnectWithoutOfferCodeInput>
  >;
  create?: InputMaybe<Array<PaymentLineItemCreateWithoutOfferCodeInput>>;
  createMany?: InputMaybe<PaymentLineItemCreateManyOfferCodeInputEnvelope>;
  delete?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentLineItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PaymentLineItemUpdateWithWhereUniqueWithoutOfferCodeInput>
  >;
  updateMany?: InputMaybe<
    Array<PaymentLineItemUpdateManyWithWhereWithoutOfferCodeInput>
  >;
  upsert?: InputMaybe<
    Array<PaymentLineItemUpsertWithWhereUniqueWithoutOfferCodeInput>
  >;
};

export type PaymentLineItemUpdateManyWithoutPaymentTransactionNestedInput = {
  connect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentLineItemCreateOrConnectWithoutPaymentTransactionInput>
  >;
  create?: InputMaybe<
    Array<PaymentLineItemCreateWithoutPaymentTransactionInput>
  >;
  createMany?: InputMaybe<PaymentLineItemCreateManyPaymentTransactionInputEnvelope>;
  delete?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentLineItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PaymentLineItemUpdateWithWhereUniqueWithoutPaymentTransactionInput>
  >;
  updateMany?: InputMaybe<
    Array<PaymentLineItemUpdateManyWithWhereWithoutPaymentTransactionInput>
  >;
  upsert?: InputMaybe<
    Array<PaymentLineItemUpsertWithWhereUniqueWithoutPaymentTransactionInput>
  >;
};

export type PaymentLineItemUpdateManyWithoutRefundTransactionNestedInput = {
  connect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentLineItemCreateOrConnectWithoutRefundTransactionInput>
  >;
  create?: InputMaybe<
    Array<PaymentLineItemCreateWithoutRefundTransactionInput>
  >;
  createMany?: InputMaybe<PaymentLineItemCreateManyRefundTransactionInputEnvelope>;
  delete?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentLineItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PaymentLineItemUpdateWithWhereUniqueWithoutRefundTransactionInput>
  >;
  updateMany?: InputMaybe<
    Array<PaymentLineItemUpdateManyWithWhereWithoutRefundTransactionInput>
  >;
  upsert?: InputMaybe<
    Array<PaymentLineItemUpsertWithWhereUniqueWithoutRefundTransactionInput>
  >;
};

export type PaymentLineItemUpdateManyWithoutTimePeriodNestedInput = {
  connect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentLineItemCreateOrConnectWithoutTimePeriodInput>
  >;
  create?: InputMaybe<Array<PaymentLineItemCreateWithoutTimePeriodInput>>;
  createMany?: InputMaybe<PaymentLineItemCreateManyTimePeriodInputEnvelope>;
  delete?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentLineItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentLineItemWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PaymentLineItemUpdateWithWhereUniqueWithoutTimePeriodInput>
  >;
  updateMany?: InputMaybe<
    Array<PaymentLineItemUpdateManyWithWhereWithoutTimePeriodInput>
  >;
  upsert?: InputMaybe<
    Array<PaymentLineItemUpsertWithWhereUniqueWithoutTimePeriodInput>
  >;
};

export type PaymentLineItemUpdateWithWhereUniqueWithoutDepositInput = {
  data: PaymentLineItemUpdateWithoutDepositInput;
  where: PaymentLineItemWhereUniqueInput;
};

export type PaymentLineItemUpdateWithWhereUniqueWithoutOfferCodeInput = {
  data: PaymentLineItemUpdateWithoutOfferCodeInput;
  where: PaymentLineItemWhereUniqueInput;
};

export type PaymentLineItemUpdateWithWhereUniqueWithoutPaymentTransactionInput =
  {
    data: PaymentLineItemUpdateWithoutPaymentTransactionInput;
    where: PaymentLineItemWhereUniqueInput;
  };

export type PaymentLineItemUpdateWithWhereUniqueWithoutRefundTransactionInput =
  {
    data: PaymentLineItemUpdateWithoutRefundTransactionInput;
    where: PaymentLineItemWhereUniqueInput;
  };

export type PaymentLineItemUpdateWithWhereUniqueWithoutTimePeriodInput = {
  data: PaymentLineItemUpdateWithoutTimePeriodInput;
  where: PaymentLineItemWhereUniqueInput;
};

export type PaymentLineItemUpdateWithoutDepositInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeUpdateOneWithoutPaymentLineItemsNestedInput>;
  paymentTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutPaymentLineItemsNestedInput>;
  refundTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutRefundLineItemsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutPaymentLineItemsNestedInput>;
  timePeriod?: InputMaybe<TimePeriodUpdateOneWithoutPaymentLineItemsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemUpdateWithoutOfferCodeInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  deposit?: InputMaybe<DepositUpdateOneWithoutPaymentLineItemsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutPaymentLineItemsNestedInput>;
  refundTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutRefundLineItemsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutPaymentLineItemsNestedInput>;
  timePeriod?: InputMaybe<TimePeriodUpdateOneWithoutPaymentLineItemsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemUpdateWithoutPaymentTransactionInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  deposit?: InputMaybe<DepositUpdateOneWithoutPaymentLineItemsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeUpdateOneWithoutPaymentLineItemsNestedInput>;
  refundTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutRefundLineItemsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutPaymentLineItemsNestedInput>;
  timePeriod?: InputMaybe<TimePeriodUpdateOneWithoutPaymentLineItemsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemUpdateWithoutRefundTransactionInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  deposit?: InputMaybe<DepositUpdateOneWithoutPaymentLineItemsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeUpdateOneWithoutPaymentLineItemsNestedInput>;
  paymentTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutPaymentLineItemsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutPaymentLineItemsNestedInput>;
  timePeriod?: InputMaybe<TimePeriodUpdateOneWithoutPaymentLineItemsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemUpdateWithoutTimePeriodInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  deposit?: InputMaybe<DepositUpdateOneWithoutPaymentLineItemsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeUpdateOneWithoutPaymentLineItemsNestedInput>;
  paymentTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutPaymentLineItemsNestedInput>;
  refundTransaction?: InputMaybe<PaymentTransactionUpdateOneWithoutRefundLineItemsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutPaymentLineItemsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentLineItemUpsertWithWhereUniqueWithoutDepositInput = {
  create: PaymentLineItemCreateWithoutDepositInput;
  update: PaymentLineItemUpdateWithoutDepositInput;
  where: PaymentLineItemWhereUniqueInput;
};

export type PaymentLineItemUpsertWithWhereUniqueWithoutOfferCodeInput = {
  create: PaymentLineItemCreateWithoutOfferCodeInput;
  update: PaymentLineItemUpdateWithoutOfferCodeInput;
  where: PaymentLineItemWhereUniqueInput;
};

export type PaymentLineItemUpsertWithWhereUniqueWithoutPaymentTransactionInput =
  {
    create: PaymentLineItemCreateWithoutPaymentTransactionInput;
    update: PaymentLineItemUpdateWithoutPaymentTransactionInput;
    where: PaymentLineItemWhereUniqueInput;
  };

export type PaymentLineItemUpsertWithWhereUniqueWithoutRefundTransactionInput =
  {
    create: PaymentLineItemCreateWithoutRefundTransactionInput;
    update: PaymentLineItemUpdateWithoutRefundTransactionInput;
    where: PaymentLineItemWhereUniqueInput;
  };

export type PaymentLineItemUpsertWithWhereUniqueWithoutTimePeriodInput = {
  create: PaymentLineItemCreateWithoutTimePeriodInput;
  update: PaymentLineItemUpdateWithoutTimePeriodInput;
  where: PaymentLineItemWhereUniqueInput;
};

export type PaymentLineItemWhereInput = {
  AND?: InputMaybe<Array<PaymentLineItemWhereInput>>;
  NOT?: InputMaybe<Array<PaymentLineItemWhereInput>>;
  OR?: InputMaybe<Array<PaymentLineItemWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  deposit?: InputMaybe<DepositRelationFilter>;
  depositId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  idempotencyId?: InputMaybe<StringFilter>;
  offerCode?: InputMaybe<OfferCodeRelationFilter>;
  offerCodeId?: InputMaybe<StringFilter>;
  paymentTransaction?: InputMaybe<PaymentTransactionRelationFilter>;
  paymentTransactionId?: InputMaybe<StringFilter>;
  refundTransaction?: InputMaybe<PaymentTransactionRelationFilter>;
  refundTransactionId?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  timePeriod?: InputMaybe<TimePeriodRelationFilter>;
  timePeriodId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentLineItemWhereUniqueInput = {
  AND?: InputMaybe<Array<PaymentLineItemWhereInput>>;
  NOT?: InputMaybe<Array<PaymentLineItemWhereInput>>;
  OR?: InputMaybe<Array<PaymentLineItemWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  deposit?: InputMaybe<DepositRelationFilter>;
  depositId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idempotencyId?: InputMaybe<StringFilter>;
  offerCode?: InputMaybe<OfferCodeRelationFilter>;
  offerCodeId?: InputMaybe<StringFilter>;
  paymentTransaction?: InputMaybe<PaymentTransactionRelationFilter>;
  paymentTransactionId?: InputMaybe<StringFilter>;
  refundTransaction?: InputMaybe<PaymentTransactionRelationFilter>;
  refundTransactionId?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  timePeriod?: InputMaybe<TimePeriodRelationFilter>;
  timePeriodId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentLineItemWithStatus = {
  __typename?: "PaymentLineItemWithStatus";
  amount: Scalars["Float"]["output"];
  amountWithoutVat?: Maybe<Scalars["Float"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  currency: CurrencyCode;
  deposit?: Maybe<Deposit>;
  depositId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  idempotencyId?: Maybe<Scalars["String"]["output"]>;
  offerCode?: Maybe<OfferCode>;
  offerCodeId?: Maybe<Scalars["String"]["output"]>;
  paymentTransaction?: Maybe<PaymentTransaction>;
  paymentTransactionId?: Maybe<Scalars["String"]["output"]>;
  refundTransaction?: Maybe<PaymentTransaction>;
  refundTransactionId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistration: SchoolRegistration;
  schoolRegistrationId: Scalars["String"]["output"];
  status: PaymentLineItemStatus;
  timePeriod?: Maybe<TimePeriod>;
  timePeriodId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type PaymentPackage = {
  __typename?: "PaymentPackage";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  basePrice: Scalars["Float"]["output"];
  basePriceWithVat: Scalars["Float"]["output"];
  basePriceWithVatAndModifiers: Scalars["Float"]["output"];
  basePriceWithVatAndModifiersAndOffer: Scalars["Float"]["output"];
  basePriceWithVatAndModifiersAndOfferAndPlanDiscount: Scalars["Float"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  offerCode?: Maybe<OfferCode>;
  offerCodeId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistration: SchoolRegistration;
  schoolRegistrationId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type PaymentPackageCreateManyOfferCodeInput = {
  basePrice: Scalars["Float"]["input"];
  basePriceWithVat: Scalars["Float"]["input"];
  basePriceWithVatAndModifiers: Scalars["Float"]["input"];
  basePriceWithVatAndModifiersAndOffer: Scalars["Float"]["input"];
  basePriceWithVatAndModifiersAndOfferAndPlanDiscount: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPackageCreateManyOfferCodeInputEnvelope = {
  data: Array<PaymentPackageCreateManyOfferCodeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PaymentPackageCreateNestedManyWithoutOfferCodeInput = {
  connect?: InputMaybe<Array<PaymentPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentPackageCreateOrConnectWithoutOfferCodeInput>
  >;
  create?: InputMaybe<Array<PaymentPackageCreateWithoutOfferCodeInput>>;
  createMany?: InputMaybe<PaymentPackageCreateManyOfferCodeInputEnvelope>;
};

export type PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput = {
  connect?: InputMaybe<PaymentPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentPackageCreateOrConnectWithoutSchoolRegistrationInput>;
  create?: InputMaybe<PaymentPackageCreateWithoutSchoolRegistrationInput>;
};

export type PaymentPackageCreateOrConnectWithoutOfferCodeInput = {
  create: PaymentPackageCreateWithoutOfferCodeInput;
  where: PaymentPackageWhereUniqueInput;
};

export type PaymentPackageCreateOrConnectWithoutSchoolRegistrationInput = {
  create: PaymentPackageCreateWithoutSchoolRegistrationInput;
  where: PaymentPackageWhereUniqueInput;
};

export type PaymentPackageCreateWithoutOfferCodeInput = {
  basePrice: Scalars["Float"]["input"];
  basePriceWithVat: Scalars["Float"]["input"];
  basePriceWithVatAndModifiers: Scalars["Float"]["input"];
  basePriceWithVatAndModifiersAndOffer: Scalars["Float"]["input"];
  basePriceWithVatAndModifiersAndOfferAndPlanDiscount: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutPaymentPackageInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPackageCreateWithoutSchoolRegistrationInput = {
  basePrice: Scalars["Float"]["input"];
  basePriceWithVat: Scalars["Float"]["input"];
  basePriceWithVatAndModifiers: Scalars["Float"]["input"];
  basePriceWithVatAndModifiersAndOffer: Scalars["Float"]["input"];
  basePriceWithVatAndModifiersAndOfferAndPlanDiscount: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeCreateNestedOneWithoutPaymentPackagesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPackageListRelationFilter = {
  every?: InputMaybe<PaymentPackageWhereInput>;
  none?: InputMaybe<PaymentPackageWhereInput>;
  some?: InputMaybe<PaymentPackageWhereInput>;
};

export type PaymentPackageRelationFilter = {
  is?: InputMaybe<PaymentPackageWhereInput>;
  isNot?: InputMaybe<PaymentPackageWhereInput>;
};

export type PaymentPackageScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentPackageScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentPackageScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentPackageScalarWhereInput>>;
  basePrice?: InputMaybe<FloatFilter>;
  basePriceWithVat?: InputMaybe<FloatFilter>;
  basePriceWithVatAndModifiers?: InputMaybe<FloatFilter>;
  basePriceWithVatAndModifiersAndOffer?: InputMaybe<FloatFilter>;
  basePriceWithVatAndModifiersAndOfferAndPlanDiscount?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  offerCodeId?: InputMaybe<StringFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentPackageUpdateManyMutationInput = {
  basePrice?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVat?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVatAndModifiers?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVatAndModifiersAndOffer?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVatAndModifiersAndOfferAndPlanDiscount?: InputMaybe<
    Scalars["Float"]["input"]
  >;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPackageUpdateManyWithWhereWithoutOfferCodeInput = {
  data: PaymentPackageUpdateManyMutationInput;
  where: PaymentPackageScalarWhereInput;
};

export type PaymentPackageUpdateManyWithoutOfferCodeNestedInput = {
  connect?: InputMaybe<Array<PaymentPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentPackageCreateOrConnectWithoutOfferCodeInput>
  >;
  create?: InputMaybe<Array<PaymentPackageCreateWithoutOfferCodeInput>>;
  createMany?: InputMaybe<PaymentPackageCreateManyOfferCodeInputEnvelope>;
  delete?: InputMaybe<Array<PaymentPackageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentPackageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentPackageWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentPackageWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PaymentPackageUpdateWithWhereUniqueWithoutOfferCodeInput>
  >;
  updateMany?: InputMaybe<
    Array<PaymentPackageUpdateManyWithWhereWithoutOfferCodeInput>
  >;
  upsert?: InputMaybe<
    Array<PaymentPackageUpsertWithWhereUniqueWithoutOfferCodeInput>
  >;
};

export type PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput = {
  connect?: InputMaybe<PaymentPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentPackageCreateOrConnectWithoutSchoolRegistrationInput>;
  create?: InputMaybe<PaymentPackageCreateWithoutSchoolRegistrationInput>;
  delete?: InputMaybe<PaymentPackageWhereInput>;
  disconnect?: InputMaybe<PaymentPackageWhereInput>;
  update?: InputMaybe<PaymentPackageUpdateToOneWithWhereWithoutSchoolRegistrationInput>;
  upsert?: InputMaybe<PaymentPackageUpsertWithoutSchoolRegistrationInput>;
};

export type PaymentPackageUpdateToOneWithWhereWithoutSchoolRegistrationInput = {
  data: PaymentPackageUpdateWithoutSchoolRegistrationInput;
  where?: InputMaybe<PaymentPackageWhereInput>;
};

export type PaymentPackageUpdateWithWhereUniqueWithoutOfferCodeInput = {
  data: PaymentPackageUpdateWithoutOfferCodeInput;
  where: PaymentPackageWhereUniqueInput;
};

export type PaymentPackageUpdateWithoutOfferCodeInput = {
  basePrice?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVat?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVatAndModifiers?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVatAndModifiersAndOffer?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVatAndModifiersAndOfferAndPlanDiscount?: InputMaybe<
    Scalars["Float"]["input"]
  >;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutPaymentPackageNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPackageUpdateWithoutSchoolRegistrationInput = {
  basePrice?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVat?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVatAndModifiers?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVatAndModifiersAndOffer?: InputMaybe<Scalars["Float"]["input"]>;
  basePriceWithVatAndModifiersAndOfferAndPlanDiscount?: InputMaybe<
    Scalars["Float"]["input"]
  >;
  id?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeUpdateOneWithoutPaymentPackagesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPackageUpsertWithWhereUniqueWithoutOfferCodeInput = {
  create: PaymentPackageCreateWithoutOfferCodeInput;
  update: PaymentPackageUpdateWithoutOfferCodeInput;
  where: PaymentPackageWhereUniqueInput;
};

export type PaymentPackageUpsertWithoutSchoolRegistrationInput = {
  create: PaymentPackageCreateWithoutSchoolRegistrationInput;
  update: PaymentPackageUpdateWithoutSchoolRegistrationInput;
  where?: InputMaybe<PaymentPackageWhereInput>;
};

export type PaymentPackageWhereInput = {
  AND?: InputMaybe<Array<PaymentPackageWhereInput>>;
  NOT?: InputMaybe<Array<PaymentPackageWhereInput>>;
  OR?: InputMaybe<Array<PaymentPackageWhereInput>>;
  basePrice?: InputMaybe<FloatFilter>;
  basePriceWithVat?: InputMaybe<FloatFilter>;
  basePriceWithVatAndModifiers?: InputMaybe<FloatFilter>;
  basePriceWithVatAndModifiersAndOffer?: InputMaybe<FloatFilter>;
  basePriceWithVatAndModifiersAndOfferAndPlanDiscount?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  offerCode?: InputMaybe<OfferCodeRelationFilter>;
  offerCodeId?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentPackageWhereUniqueInput = {
  AND?: InputMaybe<Array<PaymentPackageWhereInput>>;
  NOT?: InputMaybe<Array<PaymentPackageWhereInput>>;
  OR?: InputMaybe<Array<PaymentPackageWhereInput>>;
  basePrice?: InputMaybe<FloatFilter>;
  basePriceWithVat?: InputMaybe<FloatFilter>;
  basePriceWithVatAndModifiers?: InputMaybe<FloatFilter>;
  basePriceWithVatAndModifiersAndOffer?: InputMaybe<FloatFilter>;
  basePriceWithVatAndModifiersAndOfferAndPlanDiscount?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<OfferCodeRelationFilter>;
  offerCodeId?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentPlanPeriod = {
  __typename?: "PaymentPlanPeriod";
  _count: PaymentPlanPeriodCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  groupedInstallmentPlans?: Maybe<Array<InstallmentPlan>>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type PaymentPlanPeriodCount = {
  __typename?: "PaymentPlanPeriodCount";
  groupedInstallmentPlans: Scalars["Int"]["output"];
};

export type PaymentPlanPeriodCreateManyServiceInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPlanPeriodCreateManyServiceInputEnvelope = {
  data: Array<PaymentPlanPeriodCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PaymentPlanPeriodCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<PaymentPlanPeriodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentPlanPeriodCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<PaymentPlanPeriodCreateWithoutServiceInput>>;
  createMany?: InputMaybe<PaymentPlanPeriodCreateManyServiceInputEnvelope>;
};

export type PaymentPlanPeriodCreateNestedOneWithoutGroupedInstallmentPlansInput =
  {
    connect?: InputMaybe<PaymentPlanPeriodWhereUniqueInput>;
    connectOrCreate?: InputMaybe<PaymentPlanPeriodCreateOrConnectWithoutGroupedInstallmentPlansInput>;
    create?: InputMaybe<PaymentPlanPeriodCreateWithoutGroupedInstallmentPlansInput>;
  };

export type PaymentPlanPeriodCreateOrConnectWithoutGroupedInstallmentPlansInput =
  {
    create: PaymentPlanPeriodCreateWithoutGroupedInstallmentPlansInput;
    where: PaymentPlanPeriodWhereUniqueInput;
  };

export type PaymentPlanPeriodCreateOrConnectWithoutServiceInput = {
  create: PaymentPlanPeriodCreateWithoutServiceInput;
  where: PaymentPlanPeriodWhereUniqueInput;
};

export type PaymentPlanPeriodCreateWithoutGroupedInstallmentPlansInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutPaymentPlansPeriodsInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPlanPeriodCreateWithoutServiceInput = {
  groupedInstallmentPlans?: InputMaybe<InstallmentPlanCreateNestedManyWithoutPaymentPlanPeriodInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPlanPeriodListRelationFilter = {
  every?: InputMaybe<PaymentPlanPeriodWhereInput>;
  none?: InputMaybe<PaymentPlanPeriodWhereInput>;
  some?: InputMaybe<PaymentPlanPeriodWhereInput>;
};

export type PaymentPlanPeriodRelationFilter = {
  is?: InputMaybe<PaymentPlanPeriodWhereInput>;
  isNot?: InputMaybe<PaymentPlanPeriodWhereInput>;
};

export type PaymentPlanPeriodScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentPlanPeriodScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentPlanPeriodScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentPlanPeriodScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  serviceId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentPlanPeriodUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPlanPeriodUpdateManyWithWhereWithoutServiceInput = {
  data: PaymentPlanPeriodUpdateManyMutationInput;
  where: PaymentPlanPeriodScalarWhereInput;
};

export type PaymentPlanPeriodUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<PaymentPlanPeriodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentPlanPeriodCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<PaymentPlanPeriodCreateWithoutServiceInput>>;
  createMany?: InputMaybe<PaymentPlanPeriodCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<PaymentPlanPeriodWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PaymentPlanPeriodScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PaymentPlanPeriodWhereUniqueInput>>;
  set?: InputMaybe<Array<PaymentPlanPeriodWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PaymentPlanPeriodUpdateWithWhereUniqueWithoutServiceInput>
  >;
  updateMany?: InputMaybe<
    Array<PaymentPlanPeriodUpdateManyWithWhereWithoutServiceInput>
  >;
  upsert?: InputMaybe<
    Array<PaymentPlanPeriodUpsertWithWhereUniqueWithoutServiceInput>
  >;
};

export type PaymentPlanPeriodUpdateOneWithoutGroupedInstallmentPlansNestedInput =
  {
    connect?: InputMaybe<PaymentPlanPeriodWhereUniqueInput>;
    connectOrCreate?: InputMaybe<PaymentPlanPeriodCreateOrConnectWithoutGroupedInstallmentPlansInput>;
    create?: InputMaybe<PaymentPlanPeriodCreateWithoutGroupedInstallmentPlansInput>;
    delete?: InputMaybe<PaymentPlanPeriodWhereInput>;
    disconnect?: InputMaybe<PaymentPlanPeriodWhereInput>;
    update?: InputMaybe<PaymentPlanPeriodUpdateToOneWithWhereWithoutGroupedInstallmentPlansInput>;
    upsert?: InputMaybe<PaymentPlanPeriodUpsertWithoutGroupedInstallmentPlansInput>;
  };

export type PaymentPlanPeriodUpdateToOneWithWhereWithoutGroupedInstallmentPlansInput =
  {
    data: PaymentPlanPeriodUpdateWithoutGroupedInstallmentPlansInput;
    where?: InputMaybe<PaymentPlanPeriodWhereInput>;
  };

export type PaymentPlanPeriodUpdateWithWhereUniqueWithoutServiceInput = {
  data: PaymentPlanPeriodUpdateWithoutServiceInput;
  where: PaymentPlanPeriodWhereUniqueInput;
};

export type PaymentPlanPeriodUpdateWithoutGroupedInstallmentPlansInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  service?: InputMaybe<ServiceUpdateOneWithoutPaymentPlansPeriodsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPlanPeriodUpdateWithoutServiceInput = {
  groupedInstallmentPlans?: InputMaybe<InstallmentPlanUpdateManyWithoutPaymentPlanPeriodNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentPlanPeriodUpsertWithWhereUniqueWithoutServiceInput = {
  create: PaymentPlanPeriodCreateWithoutServiceInput;
  update: PaymentPlanPeriodUpdateWithoutServiceInput;
  where: PaymentPlanPeriodWhereUniqueInput;
};

export type PaymentPlanPeriodUpsertWithoutGroupedInstallmentPlansInput = {
  create: PaymentPlanPeriodCreateWithoutGroupedInstallmentPlansInput;
  update: PaymentPlanPeriodUpdateWithoutGroupedInstallmentPlansInput;
  where?: InputMaybe<PaymentPlanPeriodWhereInput>;
};

export type PaymentPlanPeriodWhereInput = {
  AND?: InputMaybe<Array<PaymentPlanPeriodWhereInput>>;
  NOT?: InputMaybe<Array<PaymentPlanPeriodWhereInput>>;
  OR?: InputMaybe<Array<PaymentPlanPeriodWhereInput>>;
  groupedInstallmentPlans?: InputMaybe<InstallmentPlanListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentPlanPeriodWhereUniqueInput = {
  AND?: InputMaybe<Array<PaymentPlanPeriodWhereInput>>;
  NOT?: InputMaybe<Array<PaymentPlanPeriodWhereInput>>;
  OR?: InputMaybe<Array<PaymentPlanPeriodWhereInput>>;
  groupedInstallmentPlans?: InputMaybe<InstallmentPlanListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentReminderNotificationLog = {
  __typename?: "PaymentReminderNotificationLog";
  channel: CommunicationChannel;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  sentAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type PaymentReminderNotificationLogCreateManyUserInput = {
  channel: CommunicationChannel;
  id?: InputMaybe<Scalars["String"]["input"]>;
  sentAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentReminderNotificationLogCreateManyUserInputEnvelope = {
  data: Array<PaymentReminderNotificationLogCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PaymentReminderNotificationLogCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<PaymentReminderNotificationLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentReminderNotificationLogCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<
    Array<PaymentReminderNotificationLogCreateWithoutUserInput>
  >;
  createMany?: InputMaybe<PaymentReminderNotificationLogCreateManyUserInputEnvelope>;
};

export type PaymentReminderNotificationLogCreateOrConnectWithoutUserInput = {
  create: PaymentReminderNotificationLogCreateWithoutUserInput;
  where: PaymentReminderNotificationLogWhereUniqueInput;
};

export type PaymentReminderNotificationLogCreateWithoutUserInput = {
  channel: CommunicationChannel;
  id?: InputMaybe<Scalars["String"]["input"]>;
  sentAt: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentReminderNotificationLogListRelationFilter = {
  every?: InputMaybe<PaymentReminderNotificationLogWhereInput>;
  none?: InputMaybe<PaymentReminderNotificationLogWhereInput>;
  some?: InputMaybe<PaymentReminderNotificationLogWhereInput>;
};

export type PaymentReminderNotificationLogScalarWhereInput = {
  AND?: InputMaybe<Array<PaymentReminderNotificationLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<PaymentReminderNotificationLogScalarWhereInput>>;
  OR?: InputMaybe<Array<PaymentReminderNotificationLogScalarWhereInput>>;
  channel?: InputMaybe<EnumCommunicationChannelFilter>;
  id?: InputMaybe<StringFilter>;
  sentAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type PaymentReminderNotificationLogUpdateManyMutationInput = {
  channel?: InputMaybe<CommunicationChannel>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  sentAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentReminderNotificationLogUpdateManyWithWhereWithoutUserInput =
  {
    data: PaymentReminderNotificationLogUpdateManyMutationInput;
    where: PaymentReminderNotificationLogScalarWhereInput;
  };

export type PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<PaymentReminderNotificationLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<PaymentReminderNotificationLogCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<
    Array<PaymentReminderNotificationLogCreateWithoutUserInput>
  >;
  createMany?: InputMaybe<PaymentReminderNotificationLogCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<PaymentReminderNotificationLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<
    Array<PaymentReminderNotificationLogScalarWhereInput>
  >;
  disconnect?: InputMaybe<
    Array<PaymentReminderNotificationLogWhereUniqueInput>
  >;
  set?: InputMaybe<Array<PaymentReminderNotificationLogWhereUniqueInput>>;
  update?: InputMaybe<
    Array<PaymentReminderNotificationLogUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<PaymentReminderNotificationLogUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<PaymentReminderNotificationLogUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type PaymentReminderNotificationLogUpdateWithWhereUniqueWithoutUserInput =
  {
    data: PaymentReminderNotificationLogUpdateWithoutUserInput;
    where: PaymentReminderNotificationLogWhereUniqueInput;
  };

export type PaymentReminderNotificationLogUpdateWithoutUserInput = {
  channel?: InputMaybe<CommunicationChannel>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  sentAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentReminderNotificationLogUpsertWithWhereUniqueWithoutUserInput =
  {
    create: PaymentReminderNotificationLogCreateWithoutUserInput;
    update: PaymentReminderNotificationLogUpdateWithoutUserInput;
    where: PaymentReminderNotificationLogWhereUniqueInput;
  };

export type PaymentReminderNotificationLogWhereInput = {
  AND?: InputMaybe<Array<PaymentReminderNotificationLogWhereInput>>;
  NOT?: InputMaybe<Array<PaymentReminderNotificationLogWhereInput>>;
  OR?: InputMaybe<Array<PaymentReminderNotificationLogWhereInput>>;
  channel?: InputMaybe<EnumCommunicationChannelFilter>;
  id?: InputMaybe<StringFilter>;
  sentAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type PaymentReminderNotificationLogWhereUniqueInput = {
  AND?: InputMaybe<Array<PaymentReminderNotificationLogWhereInput>>;
  NOT?: InputMaybe<Array<PaymentReminderNotificationLogWhereInput>>;
  OR?: InputMaybe<Array<PaymentReminderNotificationLogWhereInput>>;
  channel?: InputMaybe<EnumCommunicationChannelFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  sentAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum PaymentSolution {
  MYFATOORAH = "MYFATOORAH",
  THAWANI = "THAWANI",
}

export enum PaymentStatus {
  PAID = "PAID",
  UNPAID = "UNPAID",
}

export type PaymentStatusResponse = {
  __typename?: "PaymentStatusResponse";
  paymentStatus: Scalars["String"]["output"];
};

export type PaymentTransaction = {
  __typename?: "PaymentTransaction";
  _count: PaymentTransactionCount;
  amount?: Maybe<Scalars["Float"]["output"]>;
  authCode?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  creditNote?: Maybe<CreditNote>;
  creditNoteAmount?: Maybe<Scalars["Float"]["output"]>;
  creditNotes?: Maybe<Array<CreditNote>>;
  currency: CurrencyCode;
  id: Scalars["String"]["output"];
  paidAt: Scalars["DateTime"]["output"];
  paymentLineItems?: Maybe<Array<PaymentLineItem>>;
  paymentSolution?: Maybe<PaymentSolution>;
  paymentSolutionTransactionId?: Maybe<Scalars["String"]["output"]>;
  paymentTransactionReference: Scalars["Int"]["output"];
  paymentType: PaymentType;
  refundLineItems?: Maybe<Array<PaymentLineItem>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type PaymentTransactionCount = {
  __typename?: "PaymentTransactionCount";
  creditNotes: Scalars["Int"]["output"];
  paymentLineItems: Scalars["Int"]["output"];
  refundLineItems: Scalars["Int"]["output"];
};

export type PaymentTransactionCreateNestedOneWithoutCreditNoteInput = {
  connect?: InputMaybe<PaymentTransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentTransactionCreateOrConnectWithoutCreditNoteInput>;
  create?: InputMaybe<PaymentTransactionCreateWithoutCreditNoteInput>;
};

export type PaymentTransactionCreateNestedOneWithoutCreditNotesInput = {
  connect?: InputMaybe<PaymentTransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentTransactionCreateOrConnectWithoutCreditNotesInput>;
  create?: InputMaybe<PaymentTransactionCreateWithoutCreditNotesInput>;
};

export type PaymentTransactionCreateNestedOneWithoutPaymentLineItemsInput = {
  connect?: InputMaybe<PaymentTransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentTransactionCreateOrConnectWithoutPaymentLineItemsInput>;
  create?: InputMaybe<PaymentTransactionCreateWithoutPaymentLineItemsInput>;
};

export type PaymentTransactionCreateNestedOneWithoutRefundLineItemsInput = {
  connect?: InputMaybe<PaymentTransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentTransactionCreateOrConnectWithoutRefundLineItemsInput>;
  create?: InputMaybe<PaymentTransactionCreateWithoutRefundLineItemsInput>;
};

export type PaymentTransactionCreateOrConnectWithoutCreditNoteInput = {
  create: PaymentTransactionCreateWithoutCreditNoteInput;
  where: PaymentTransactionWhereUniqueInput;
};

export type PaymentTransactionCreateOrConnectWithoutCreditNotesInput = {
  create: PaymentTransactionCreateWithoutCreditNotesInput;
  where: PaymentTransactionWhereUniqueInput;
};

export type PaymentTransactionCreateOrConnectWithoutPaymentLineItemsInput = {
  create: PaymentTransactionCreateWithoutPaymentLineItemsInput;
  where: PaymentTransactionWhereUniqueInput;
};

export type PaymentTransactionCreateOrConnectWithoutRefundLineItemsInput = {
  create: PaymentTransactionCreateWithoutRefundLineItemsInput;
  where: PaymentTransactionWhereUniqueInput;
};

export type PaymentTransactionCreateWithoutCreditNoteInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  authCode?: InputMaybe<Scalars["String"]["input"]>;
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutPaymentTransactionRefInput>;
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paidAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemCreateNestedManyWithoutPaymentTransactionInput>;
  paymentSolution?: InputMaybe<PaymentSolution>;
  paymentSolutionTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionReference?: InputMaybe<Scalars["Int"]["input"]>;
  paymentType: PaymentType;
  refundLineItems?: InputMaybe<PaymentLineItemCreateNestedManyWithoutRefundTransactionInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentTransactionCreateWithoutCreditNotesInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  authCode?: InputMaybe<Scalars["String"]["input"]>;
  creditNote?: InputMaybe<CreditNoteCreateNestedOneWithoutPaymentTransactionInput>;
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paidAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemCreateNestedManyWithoutPaymentTransactionInput>;
  paymentSolution?: InputMaybe<PaymentSolution>;
  paymentSolutionTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionReference?: InputMaybe<Scalars["Int"]["input"]>;
  paymentType: PaymentType;
  refundLineItems?: InputMaybe<PaymentLineItemCreateNestedManyWithoutRefundTransactionInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentTransactionCreateWithoutPaymentLineItemsInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  authCode?: InputMaybe<Scalars["String"]["input"]>;
  creditNote?: InputMaybe<CreditNoteCreateNestedOneWithoutPaymentTransactionInput>;
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutPaymentTransactionRefInput>;
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paidAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  paymentSolution?: InputMaybe<PaymentSolution>;
  paymentSolutionTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionReference?: InputMaybe<Scalars["Int"]["input"]>;
  paymentType: PaymentType;
  refundLineItems?: InputMaybe<PaymentLineItemCreateNestedManyWithoutRefundTransactionInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentTransactionCreateWithoutRefundLineItemsInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  authCode?: InputMaybe<Scalars["String"]["input"]>;
  creditNote?: InputMaybe<CreditNoteCreateNestedOneWithoutPaymentTransactionInput>;
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutPaymentTransactionRefInput>;
  currency: CurrencyCode;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paidAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemCreateNestedManyWithoutPaymentTransactionInput>;
  paymentSolution?: InputMaybe<PaymentSolution>;
  paymentSolutionTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionReference?: InputMaybe<Scalars["Int"]["input"]>;
  paymentType: PaymentType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentTransactionPaymentSolutionPaymentSolutionTransactionIdCompoundUniqueInput =
  {
    paymentSolution: PaymentSolution;
    paymentSolutionTransactionId: Scalars["String"]["input"];
  };

export type PaymentTransactionRelationFilter = {
  is?: InputMaybe<PaymentTransactionWhereInput>;
  isNot?: InputMaybe<PaymentTransactionWhereInput>;
};

export type PaymentTransactionUpdateOneWithoutCreditNoteNestedInput = {
  connect?: InputMaybe<PaymentTransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentTransactionCreateOrConnectWithoutCreditNoteInput>;
  create?: InputMaybe<PaymentTransactionCreateWithoutCreditNoteInput>;
  delete?: InputMaybe<PaymentTransactionWhereInput>;
  disconnect?: InputMaybe<PaymentTransactionWhereInput>;
  update?: InputMaybe<PaymentTransactionUpdateToOneWithWhereWithoutCreditNoteInput>;
  upsert?: InputMaybe<PaymentTransactionUpsertWithoutCreditNoteInput>;
};

export type PaymentTransactionUpdateOneWithoutCreditNotesNestedInput = {
  connect?: InputMaybe<PaymentTransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentTransactionCreateOrConnectWithoutCreditNotesInput>;
  create?: InputMaybe<PaymentTransactionCreateWithoutCreditNotesInput>;
  delete?: InputMaybe<PaymentTransactionWhereInput>;
  disconnect?: InputMaybe<PaymentTransactionWhereInput>;
  update?: InputMaybe<PaymentTransactionUpdateToOneWithWhereWithoutCreditNotesInput>;
  upsert?: InputMaybe<PaymentTransactionUpsertWithoutCreditNotesInput>;
};

export type PaymentTransactionUpdateOneWithoutPaymentLineItemsNestedInput = {
  connect?: InputMaybe<PaymentTransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentTransactionCreateOrConnectWithoutPaymentLineItemsInput>;
  create?: InputMaybe<PaymentTransactionCreateWithoutPaymentLineItemsInput>;
  delete?: InputMaybe<PaymentTransactionWhereInput>;
  disconnect?: InputMaybe<PaymentTransactionWhereInput>;
  update?: InputMaybe<PaymentTransactionUpdateToOneWithWhereWithoutPaymentLineItemsInput>;
  upsert?: InputMaybe<PaymentTransactionUpsertWithoutPaymentLineItemsInput>;
};

export type PaymentTransactionUpdateOneWithoutRefundLineItemsNestedInput = {
  connect?: InputMaybe<PaymentTransactionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PaymentTransactionCreateOrConnectWithoutRefundLineItemsInput>;
  create?: InputMaybe<PaymentTransactionCreateWithoutRefundLineItemsInput>;
  delete?: InputMaybe<PaymentTransactionWhereInput>;
  disconnect?: InputMaybe<PaymentTransactionWhereInput>;
  update?: InputMaybe<PaymentTransactionUpdateToOneWithWhereWithoutRefundLineItemsInput>;
  upsert?: InputMaybe<PaymentTransactionUpsertWithoutRefundLineItemsInput>;
};

export type PaymentTransactionUpdateToOneWithWhereWithoutCreditNoteInput = {
  data: PaymentTransactionUpdateWithoutCreditNoteInput;
  where?: InputMaybe<PaymentTransactionWhereInput>;
};

export type PaymentTransactionUpdateToOneWithWhereWithoutCreditNotesInput = {
  data: PaymentTransactionUpdateWithoutCreditNotesInput;
  where?: InputMaybe<PaymentTransactionWhereInput>;
};

export type PaymentTransactionUpdateToOneWithWhereWithoutPaymentLineItemsInput =
  {
    data: PaymentTransactionUpdateWithoutPaymentLineItemsInput;
    where?: InputMaybe<PaymentTransactionWhereInput>;
  };

export type PaymentTransactionUpdateToOneWithWhereWithoutRefundLineItemsInput =
  {
    data: PaymentTransactionUpdateWithoutRefundLineItemsInput;
    where?: InputMaybe<PaymentTransactionWhereInput>;
  };

export type PaymentTransactionUpdateWithoutCreditNoteInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  authCode?: InputMaybe<Scalars["String"]["input"]>;
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutPaymentTransactionRefNestedInput>;
  currency?: InputMaybe<CurrencyCode>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paidAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemUpdateManyWithoutPaymentTransactionNestedInput>;
  paymentSolution?: InputMaybe<PaymentSolution>;
  paymentSolutionTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionReference?: InputMaybe<Scalars["Int"]["input"]>;
  paymentType?: InputMaybe<PaymentType>;
  refundLineItems?: InputMaybe<PaymentLineItemUpdateManyWithoutRefundTransactionNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentTransactionUpdateWithoutCreditNotesInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  authCode?: InputMaybe<Scalars["String"]["input"]>;
  creditNote?: InputMaybe<CreditNoteUpdateOneWithoutPaymentTransactionNestedInput>;
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<CurrencyCode>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paidAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemUpdateManyWithoutPaymentTransactionNestedInput>;
  paymentSolution?: InputMaybe<PaymentSolution>;
  paymentSolutionTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionReference?: InputMaybe<Scalars["Int"]["input"]>;
  paymentType?: InputMaybe<PaymentType>;
  refundLineItems?: InputMaybe<PaymentLineItemUpdateManyWithoutRefundTransactionNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentTransactionUpdateWithoutPaymentLineItemsInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  authCode?: InputMaybe<Scalars["String"]["input"]>;
  creditNote?: InputMaybe<CreditNoteUpdateOneWithoutPaymentTransactionNestedInput>;
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutPaymentTransactionRefNestedInput>;
  currency?: InputMaybe<CurrencyCode>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paidAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  paymentSolution?: InputMaybe<PaymentSolution>;
  paymentSolutionTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionReference?: InputMaybe<Scalars["Int"]["input"]>;
  paymentType?: InputMaybe<PaymentType>;
  refundLineItems?: InputMaybe<PaymentLineItemUpdateManyWithoutRefundTransactionNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentTransactionUpdateWithoutRefundLineItemsInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  authCode?: InputMaybe<Scalars["String"]["input"]>;
  creditNote?: InputMaybe<CreditNoteUpdateOneWithoutPaymentTransactionNestedInput>;
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutPaymentTransactionRefNestedInput>;
  currency?: InputMaybe<CurrencyCode>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paidAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemUpdateManyWithoutPaymentTransactionNestedInput>;
  paymentSolution?: InputMaybe<PaymentSolution>;
  paymentSolutionTransactionId?: InputMaybe<Scalars["String"]["input"]>;
  paymentTransactionReference?: InputMaybe<Scalars["Int"]["input"]>;
  paymentType?: InputMaybe<PaymentType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PaymentTransactionUpsertWithoutCreditNoteInput = {
  create: PaymentTransactionCreateWithoutCreditNoteInput;
  update: PaymentTransactionUpdateWithoutCreditNoteInput;
  where?: InputMaybe<PaymentTransactionWhereInput>;
};

export type PaymentTransactionUpsertWithoutCreditNotesInput = {
  create: PaymentTransactionCreateWithoutCreditNotesInput;
  update: PaymentTransactionUpdateWithoutCreditNotesInput;
  where?: InputMaybe<PaymentTransactionWhereInput>;
};

export type PaymentTransactionUpsertWithoutPaymentLineItemsInput = {
  create: PaymentTransactionCreateWithoutPaymentLineItemsInput;
  update: PaymentTransactionUpdateWithoutPaymentLineItemsInput;
  where?: InputMaybe<PaymentTransactionWhereInput>;
};

export type PaymentTransactionUpsertWithoutRefundLineItemsInput = {
  create: PaymentTransactionCreateWithoutRefundLineItemsInput;
  update: PaymentTransactionUpdateWithoutRefundLineItemsInput;
  where?: InputMaybe<PaymentTransactionWhereInput>;
};

export type PaymentTransactionWhereInput = {
  AND?: InputMaybe<Array<PaymentTransactionWhereInput>>;
  NOT?: InputMaybe<Array<PaymentTransactionWhereInput>>;
  OR?: InputMaybe<Array<PaymentTransactionWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  authCode?: InputMaybe<StringFilter>;
  creditNote?: InputMaybe<CreditNoteRelationFilter>;
  creditNoteAmount?: InputMaybe<FloatFilter>;
  creditNotes?: InputMaybe<CreditNoteListRelationFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  id?: InputMaybe<StringFilter>;
  paidAt?: InputMaybe<DateTimeFilter>;
  paymentLineItems?: InputMaybe<PaymentLineItemListRelationFilter>;
  paymentSolution?: InputMaybe<EnumPaymentSolutionFilter>;
  paymentSolutionTransactionId?: InputMaybe<StringFilter>;
  paymentTransactionReference?: InputMaybe<IntFilter>;
  paymentType?: InputMaybe<EnumPaymentTypeFilter>;
  refundLineItems?: InputMaybe<PaymentLineItemListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentTransactionWhereUniqueInput = {
  AND?: InputMaybe<Array<PaymentTransactionWhereInput>>;
  NOT?: InputMaybe<Array<PaymentTransactionWhereInput>>;
  OR?: InputMaybe<Array<PaymentTransactionWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  authCode?: InputMaybe<StringFilter>;
  creditNote?: InputMaybe<CreditNoteRelationFilter>;
  creditNoteAmount?: InputMaybe<FloatFilter>;
  creditNotes?: InputMaybe<CreditNoteListRelationFilter>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  paidAt?: InputMaybe<DateTimeFilter>;
  paymentLineItems?: InputMaybe<PaymentLineItemListRelationFilter>;
  paymentSolution?: InputMaybe<EnumPaymentSolutionFilter>;
  paymentSolutionTransactionId?: InputMaybe<StringFilter>;
  paymentSolution_paymentSolutionTransactionId?: InputMaybe<PaymentTransactionPaymentSolutionPaymentSolutionTransactionIdCompoundUniqueInput>;
  paymentTransactionReference?: InputMaybe<IntFilter>;
  paymentType?: InputMaybe<EnumPaymentTypeFilter>;
  refundLineItems?: InputMaybe<PaymentLineItemListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum PaymentType {
  CARD = "CARD",
  CASH = "CASH",
  CREDIT_NOTE = "CREDIT_NOTE",
  ONLINE = "ONLINE",
  PAYMENT_LINK = "PAYMENT_LINK",
}

export type PaymentUrlResponse = {
  __typename?: "PaymentUrlResponse";
  failureUrl?: Maybe<Scalars["String"]["output"]>;
  isOnlinePaymentRequired: Scalars["Boolean"]["output"];
  paymentUrl?: Maybe<Scalars["String"]["output"]>;
  successUrl?: Maybe<Scalars["String"]["output"]>;
};

export type Permission = {
  __typename?: "Permission";
  _count: PermissionCount;
  actions?: Maybe<Array<PermissionAction>>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  conditions?: Maybe<Scalars["JSON"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  fields?: Maybe<Array<Scalars["String"]["output"]>>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  roles?: Maybe<Array<RolePermission>>;
  subject: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export enum PermissionAction {
  ARCHIVE = "archive",
  BULKUPDATE = "bulkUpdate",
  DELETE = "delete",
  MANAGE = "manage",
  READ = "read",
  UPDATE = "update",
  VIEW = "view",
  WRITE = "write",
}

export type PermissionCount = {
  __typename?: "PermissionCount";
  roles: Scalars["Int"]["output"];
};

export type PermissionCreateNestedOneWithoutRolesInput = {
  connect?: InputMaybe<PermissionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PermissionCreateOrConnectWithoutRolesInput>;
  create?: InputMaybe<PermissionCreateWithoutRolesInput>;
};

export type PermissionCreateOrConnectWithoutRolesInput = {
  create: PermissionCreateWithoutRolesInput;
  where: PermissionWhereUniqueInput;
};

export type PermissionCreateWithoutRolesInput = {
  actions?: InputMaybe<Array<PermissionAction>>;
  conditions?: InputMaybe<Scalars["JSON"]["input"]>;
  fields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  subject: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PermissionRelationFilter = {
  is?: InputMaybe<PermissionWhereInput>;
  isNot?: InputMaybe<PermissionWhereInput>;
};

export type PermissionUpdateOneRequiredWithoutRolesNestedInput = {
  connect?: InputMaybe<PermissionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PermissionCreateOrConnectWithoutRolesInput>;
  create?: InputMaybe<PermissionCreateWithoutRolesInput>;
  update?: InputMaybe<PermissionUpdateToOneWithWhereWithoutRolesInput>;
  upsert?: InputMaybe<PermissionUpsertWithoutRolesInput>;
};

export type PermissionUpdateToOneWithWhereWithoutRolesInput = {
  data: PermissionUpdateWithoutRolesInput;
  where?: InputMaybe<PermissionWhereInput>;
};

export type PermissionUpdateWithoutRolesInput = {
  actions?: InputMaybe<Array<PermissionAction>>;
  conditions?: InputMaybe<Scalars["JSON"]["input"]>;
  fields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  subject?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PermissionUpsertWithoutRolesInput = {
  create: PermissionCreateWithoutRolesInput;
  update: PermissionUpdateWithoutRolesInput;
  where?: InputMaybe<PermissionWhereInput>;
};

export type PermissionWhereInput = {
  AND?: InputMaybe<Array<PermissionWhereInput>>;
  NOT?: InputMaybe<Array<PermissionWhereInput>>;
  OR?: InputMaybe<Array<PermissionWhereInput>>;
  actions?: InputMaybe<EnumPermissionActionListFilter>;
  conditions?: InputMaybe<JsonFilter>;
  fields?: InputMaybe<StringListFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  roles?: InputMaybe<RolePermissionListRelationFilter>;
  subject?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PermissionWhereUniqueInput = {
  AND?: InputMaybe<Array<PermissionWhereInput>>;
  NOT?: InputMaybe<Array<PermissionWhereInput>>;
  OR?: InputMaybe<Array<PermissionWhereInput>>;
  actions?: InputMaybe<EnumPermissionActionListFilter>;
  conditions?: InputMaybe<JsonFilter>;
  fields?: InputMaybe<StringListFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<RolePermissionListRelationFilter>;
  subject?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum Platform {
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export type Poi = {
  __typename?: "Poi";
  address: Address;
  addressId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  lat: Scalars["Float"]["output"];
  lon?: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type PoiCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<PoiWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PoiCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<PoiCreateWithoutAddressInput>;
};

export type PoiCreateOrConnectWithoutAddressInput = {
  create: PoiCreateWithoutAddressInput;
  where: PoiWhereUniqueInput;
};

export type PoiCreateWithoutAddressInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat: Scalars["Float"]["input"];
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PoiRelationFilter = {
  is?: InputMaybe<PoiWhereInput>;
  isNot?: InputMaybe<PoiWhereInput>;
};

export type PoiUpdateOneWithoutAddressNestedInput = {
  connect?: InputMaybe<PoiWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PoiCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<PoiCreateWithoutAddressInput>;
  delete?: InputMaybe<PoiWhereInput>;
  disconnect?: InputMaybe<PoiWhereInput>;
  update?: InputMaybe<PoiUpdateToOneWithWhereWithoutAddressInput>;
  upsert?: InputMaybe<PoiUpsertWithoutAddressInput>;
};

export type PoiUpdateToOneWithWhereWithoutAddressInput = {
  data: PoiUpdateWithoutAddressInput;
  where?: InputMaybe<PoiWhereInput>;
};

export type PoiUpdateWithoutAddressInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PoiUpsertWithoutAddressInput = {
  create: PoiCreateWithoutAddressInput;
  update: PoiUpdateWithoutAddressInput;
  where?: InputMaybe<PoiWhereInput>;
};

export type PoiWhereInput = {
  AND?: InputMaybe<Array<PoiWhereInput>>;
  NOT?: InputMaybe<Array<PoiWhereInput>>;
  OR?: InputMaybe<Array<PoiWhereInput>>;
  address?: InputMaybe<AddressRelationFilter>;
  addressId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lat?: InputMaybe<FloatFilter>;
  lon?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PoiWhereUniqueInput = {
  AND?: InputMaybe<Array<PoiWhereInput>>;
  NOT?: InputMaybe<Array<PoiWhereInput>>;
  OR?: InputMaybe<Array<PoiWhereInput>>;
  address?: InputMaybe<AddressRelationFilter>;
  addressId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<FloatFilter>;
  lon?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PushNotificationDataInput = {
  groupOfDevices?: InputMaybe<Array<Scalars["String"]["input"]>>;
  notificationData: NotificationDataInput;
  token?: InputMaybe<Scalars["String"]["input"]>;
  topic?: InputMaybe<Scalars["String"]["input"]>;
};

export type PushNotificationInput = {
  action: NotificationAction;
  data: PushNotificationDataInput;
  dateTime?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum PushNotificationType {
  HOME_STOP_TRACKING = "HOME_STOP_TRACKING",
  OTHERS = "OTHERS",
  PAYMENT_REMINDER = "PAYMENT_REMINDER",
  SCHOOL_STOP_TRACKING = "SCHOOL_STOP_TRACKING",
  STUDENT_VEHICLE_TRACKING = "STUDENT_VEHICLE_TRACKING",
}

export type Query = {
  __typename?: "Query";
  areEligibleForService: Array<Eligibility>;
  calculateRouteFromPoints: ValhallaRouteResponse;
  checkActiveTripsForSchoolRegistration: CheckActiveTripsForSchoolRegistration;
  filterSchoolRegistrationsForAgent: SchoolRegistrationFilterOutput;
  generateRoutes: Array<RouteTemp>;
  generateRoutesV2: Scalars["String"]["output"];
  getAccessiblePackageNumbers: Array<Scalars["Float"]["output"]>;
  getActiveTripStopsForSponsor: Array<ActiveTripStopForSponsor>;
  getActiveTripsForSponsor: Array<ActiveTripForSponsor>;
  getAdhocNotificationForWeb: AdhocNotificationWithoutJsonTypes;
  getAdhocNotificationsForWeb: Array<AdhocNotificationWithoutJsonTypes>;
  getAdministrativeAreasForMobile: Array<AdministrativeAreaResponsePayload>;
  getAgent: AgentWithIdentityProvider;
  getAgents: Array<Agent>;
  getAlerts: Array<Alert>;
  getBulkServicesWithPrice: Array<ServiceWithPrice>;
  getBusAttendant: BusAttendant;
  getBusAttendantHoursWorked: Array<BusAttendantHoursWorked>;
  getBusAttendantStatusHistory: Array<BusAttendantStatusHistory>;
  getBusAttendantViolationCompliance: Array<BusAttendantViolationCompliance>;
  getBusAttendants: Array<BusAttendant>;
  getClassesForMobile: Array<ClassPayload>;
  getCollectionPoint: CollectionPointResponse;
  getCollectionPoints: Array<CollectionPointResponse>;
  getContacts: Array<ContactResponsePayload>;
  getCountriesForMobile: Array<CountryResponsePayload>;
  getCountriesForWeb: Array<Country>;
  getCountryDivisionLevelsForMobile: Array<CountryDivisionLevelResponsePayload>;
  getCustomer: GetCustomerOutput;
  getCustomers: Array<GetCustomerManyOutput>;
  getDepartments: Array<DepartmentResponsePayload>;
  getDriver: Driver;
  getDriverHoursWorked: Array<DriverHoursWorked>;
  getDriverStatusHistory: Array<DriverStatusHistory>;
  getDriverViolationCompliance: Array<DriverViolationCompliance>;
  getDrivers: Array<Driver>;
  getFleetVehicle: FleetVehicle;
  getFleetVehicles: PaginatedFleetVehicles;
  getGeoJSONPolygonCoordinates: Array<GeoJsonCoordinate>;
  getLatestAppVersion: AppVersionPayload;
  getLeaveRequest: LeaveRequest;
  getLeaveRequestForSponsor: LeaveRequestDetailsForSponsor;
  getLeaveRequests: Array<LeaveRequest>;
  getLeaveRequestsForSponsor: Array<LeaveRequestForSponsor>;
  getLocalitiesForMobile: Array<LocalityResponsePayload>;
  getMyPermissions: Array<Permission>;
  getNotificationHistoryForMobile: Array<NotificationHistoryResponsePayload>;
  getNotificationHistoryWithCountForMobile: NotificationHistoryWithCountResponsePayload;
  getNotificationTopics: Array<NotificationTopicResponsePayload>;
  getOffer: Offer;
  getOffers: Array<Offer>;
  getOtp: OtpRequest;
  getOtpConfig: OtpConfig;
  getOtpConfiguration: Configuration;
  getPaymentCashOutRequest: CashOutRequest;
  getPaymentCashOutRequests: Array<CashOutRequest>;
  getPaymentResult: PaymentStatusResponse;
  getPaymentUrlByLineItemIds: PaymentUrlResponse;
  getPublishedServiceForSponsor: ServiceForSponsor;
  getRole: Role;
  getRoles: Array<Role>;
  getRoute: Route;
  getRoutes: Array<Route>;
  getSafetyEvents: Array<VehicleSafetyEvent>;
  getSchool: School;
  getSchoolForMobile: SchoolResponsePayload;
  getSchoolRegistration: SchoolRegistrationWithCredit;
  getSchoolRegistrationConfigurations: SchoolRegistrationConfiguration;
  getSchoolRegistrations: Array<SchoolRegistrationWithComputedFields>;
  getSchoolRegistrationsForMobile: Array<SchoolRegistrationWithPaymentStatusAndUpcomingTripAndTimePeriodsAndVat>;
  getSchoolRegistrationsForRouting: Array<SchoolRegistration>;
  getSchoolServiceDefinitions: Array<SchoolWithServiceDefinitions>;
  getSchools: Array<SchoolsForWeb>;
  getSchoolsForMobile: Array<SchoolResponsePayload>;
  getService: Service;
  getServicePricingChange: ChangeRequestOutput;
  getServiceProvider: ServiceProvider;
  getServiceProviders: Array<ServiceProvider>;
  getServiceWithPrice: ServiceWithPrice;
  /** @deprecated Not used anymore */
  getServiceWithPriceAndRefund: ServiceWithPriceAndRefund;
  getServices: Array<Service>;
  getSignedUrl: Scalars["String"]["output"];
  getSpecialNeeds: Array<SpecialNeedResponsePayload>;
  getSponsor: Sponsor;
  getSponsorExtraData: GetSponsorExtraInfoOutput;
  getSponsorProfile: SponsorProfile;
  getSponsors: Array<Sponsor>;
  getStudent: Student;
  getStudents: Array<Student>;
  getStudentsWithSpDetailsForMobile: Array<SpContactWithGroupedStudents>;
  getSubLocalitiesForMobile: Array<SubLocalityResponsePayload>;
  getTripFeedback: TripFeedback;
  getTripForBaBd: TripForBaBd;
  getTripForSponsor: TripDetailsForSponsor;
  getTripManifest?: Maybe<TripManifest>;
  getTripsCountForWeb: ListResponse;
  getTripsForBaBd: Array<TripForBaBd>;
  getTripsForBusAttendant: Array<TripsForDriver>;
  getTripsForDriver: Array<TripsForDriver>;
  getTripsForSponsor?: Maybe<Array<TripForSponsor>>;
  getTripsForWeb: Array<TripForWeb>;
  getUpcomingTripForSponsor: UpcomingTripForSponsor;
  getUser: UserResponsePayload;
  getUserCreditNoteAmount: Array<UserCreditNote>;
  getUsers: Array<UserResponsePayload>;
  getVehicle: Vehicle;
  getVehicleSafetyEvent: VehicleSafetyEvent;
  getVehicleSafetyEventCount: Scalars["Float"]["output"];
  getVehicles: Array<VehicleWithLastLocation>;
  isAgentPhoneNumberExistForWeb: ValidationResponse;
  isBusAttendancePhoneNumberExistForWeb: ValidationResponse;
  isDriverPhoneNumberExistForWeb: ValidationResponse;
  isEligibleForService: Eligibility;
  isEligibleForServices: Array<Eligibility>;
  isEmailValidForWeb: Array<ValidationResponse>;
  isOfferValid: OfferValidity;
  isPhoneNew: Scalars["Boolean"]["output"];
  isPhoneNumberValidForWeb: Array<ValidationResponse>;
  isSponsorPhoneNumberExistForWeb: ValidationResponse;
  isValidCollectionPointCode: CollectionPointCodeValidationResponse;
  isVehicleGroupNameValidForWeb: ValidationResponse;
  publishBoardingEvent: Scalars["Boolean"]["output"];
  publishVehicleEvent: Scalars["Boolean"]["output"];
  searchAdministrativeAreas: Array<AdministrativeArea>;
  searchAgents: Array<Agent>;
  searchBanks: Array<Bank>;
  searchBusAttendants: Array<BusAttendant>;
  searchClasses: Array<Class>;
  searchCollectionPoints: Array<CollectionPointResponse>;
  searchContractTypes: Array<ContractType>;
  searchDrivers: Array<Driver>;
  searchLocalities: Array<Locality>;
  searchRoles: Array<Role>;
  searchRouteGroups: Array<RouteGroup>;
  searchRoutes: Array<Route>;
  searchScheduleGroups: Array<ScheduleGroup>;
  searchSchedules: Array<Schedule>;
  searchSchoolClusters: Array<Array<School>>;
  searchSchoolGroups: Array<SchoolGroup>;
  searchSchoolRegistrations: Array<SchoolRegistration>;
  searchSchoolStages: Array<SchoolStage>;
  searchSchoolTypes: Array<SchoolType>;
  searchSchools: Array<School>;
  searchServiceCategories: Array<ServiceCategory>;
  searchServiceProviderGroups: Array<ServiceProviderGroup>;
  searchServiceProviders: Array<ServiceProvider>;
  searchServices: Array<Service>;
  searchSpecialNeeds: Array<SpecialNeed>;
  searchSponsors: Array<Sponsor>;
  searchStudents: Array<Student>;
  searchSubLocalities: Array<SubLocality>;
  searchTravelTags: Array<TravelTag>;
  searchTrips: Array<Trip>;
  searchVehicleGroups: Array<VehicleGroup>;
  searchVehicleMakes: Array<VehicleMake>;
  searchVehicleModels: Array<VehicleModel>;
  searchVehicleTypes: Array<VehicleType>;
  searchVehicles: Array<Vehicle>;
  searchZones: Array<Zone>;
  whoAmI: UserWithAccessibleCountries;
};

export type QueryAreEligibleForServiceArgs = {
  eligibilitiesInput: Array<EligibilityInput>;
};

export type QueryCalculateRouteFromPointsArgs = {
  points: Array<LatLonInput>;
};

export type QueryCheckActiveTripsForSchoolRegistrationArgs = {
  schoolRegistrationId: Scalars["String"]["input"];
};

export type QueryFilterSchoolRegistrationsForAgentArgs = {
  schoolRegistrationsFilterInput?: InputMaybe<SchoolRegistrationsFilterInput>;
};

export type QueryGenerateRoutesArgs = {
  isUnknownLocationsIgnored?: InputMaybe<Scalars["Boolean"]["input"]>;
  optimizationConfig?: InputMaybe<RouteOptimizationConfigInput>;
  order?: InputMaybe<Array<Scalars["String"]["input"]>>;
  routeDirectionality: RouteDirectionality;
  stopIds: Array<Scalars["String"]["input"]>;
};

export type QueryGenerateRoutesV2Args = {
  isUnknownLocationsIgnored?: InputMaybe<Scalars["Boolean"]["input"]>;
  optimizationConfig?: InputMaybe<RouteOptimizationConfigInput>;
  order?: InputMaybe<Array<Scalars["String"]["input"]>>;
  routeDirectionality: RouteDirectionality;
  stopIds: Array<Scalars["String"]["input"]>;
};

export type QueryGetAccessiblePackageNumbersArgs = {
  filterBy?: InputMaybe<FleetVehiclesFilterInput>;
};

export type QueryGetActiveTripStopsForSponsorArgs = {
  tripStopIds: Array<Scalars["String"]["input"]>;
};

export type QueryGetActiveTripsForSponsorArgs = {
  sponsorId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAdhocNotificationForWebArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetAdministrativeAreasForMobileArgs = {
  administrativeAreasFilterInput: AdministrativeAreasFilterInput;
};

export type QueryGetAgentArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetAgentsArgs = {
  agentWhere?: InputMaybe<AgentWhereInput>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAlertsArgs = {
  AlertSearchInput: AlertSearchInput;
  filter: AlertWhereInput;
};

export type QueryGetBulkServicesWithPriceArgs = {
  getBulkServicesWithPriceInput: GetBulkServicesWithPriceInput;
};

export type QueryGetBusAttendantArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetBusAttendantHoursWorkedArgs = {
  busAttendantId: Scalars["String"]["input"];
};

export type QueryGetBusAttendantStatusHistoryArgs = {
  busAttendantId: Scalars["String"]["input"];
};

export type QueryGetBusAttendantViolationComplianceArgs = {
  busAttendantId: Scalars["String"]["input"];
};

export type QueryGetBusAttendantsArgs = {
  busAttendantWhere?: InputMaybe<BusAttendantWhereInput>;
  orderBy?: InputMaybe<GetBusAttendantsOrderClause>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetClassesForMobileArgs = {
  findAllClassesFilterInput: FindAllClassesFilterInput;
};

export type QueryGetCollectionPointArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetCollectionPointsArgs = {
  pagination?: InputMaybe<PaginatedRequestInput>;
};

export type QueryGetContactsArgs = {
  findAllContactsFilterInput: FindAllContactsFilterInput;
};

export type QueryGetCountriesForMobileArgs = {
  addressesInfoFilterInput: AddressesInfoFilterInput;
};

export type QueryGetCountriesForWebArgs = {
  where?: InputMaybe<CountryWhereInput>;
};

export type QueryGetCountryDivisionLevelsForMobileArgs = {
  getCountryDivisionLevelsInput: GetCountryDivisionLevelsInput;
};

export type QueryGetCustomerArgs = {
  customerId: Scalars["String"]["input"];
};

export type QueryGetCustomersArgs = {
  filter?: InputMaybe<GetCustomersFilterInput>;
  orderBy?: InputMaybe<CustomerOrderByWithRelationInputFirstOrder>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetDepartmentsArgs = {
  findAllDepartmentsFilterInput: FindAllDepartmentsFilterInput;
};

export type QueryGetDriverArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetDriverHoursWorkedArgs = {
  driverId: Scalars["String"]["input"];
};

export type QueryGetDriverStatusHistoryArgs = {
  driverId: Scalars["String"]["input"];
};

export type QueryGetDriverViolationComplianceArgs = {
  driverId: Scalars["String"]["input"];
};

export type QueryGetDriversArgs = {
  driverWhere?: InputMaybe<DriverWhereInput>;
  order?: InputMaybe<GetDriversOrderClause>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetFleetVehicleArgs = {
  where: FleetVehicleWhereInput;
};

export type QueryGetFleetVehiclesArgs = {
  filterBy?: InputMaybe<FleetVehiclesFilterInput>;
  pagination: PaginatedRequestInput;
};

export type QueryGetGeoJsonPolygonCoordinatesArgs = {
  geojsonInput: GeoJsonPolygonCoordinatesInput;
};

export type QueryGetLatestAppVersionArgs = {
  appTypeInput: AppTypeInput;
};

export type QueryGetLeaveRequestArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetLeaveRequestForSponsorArgs = {
  leaveRequestId: Scalars["String"]["input"];
};

export type QueryGetLeaveRequestsArgs = {
  pagination?: InputMaybe<PaginatedRequestInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  where?: InputMaybe<LeaveRequestWhereInput>;
};

export type QueryGetLeaveRequestsForSponsorArgs = {
  childRegistrationId: Scalars["String"]["input"];
  pagination?: InputMaybe<PaginatedRequestInput>;
};

export type QueryGetLocalitiesForMobileArgs = {
  localitiesFilterInput: LocalitiesFilterInput;
};

export type QueryGetMyPermissionsArgs = {
  filter?: InputMaybe<PermissionWhereInput>;
};

export type QueryGetNotificationHistoryForMobileArgs = {
  paginatedRequestInput: PaginatedRequestInput;
};

export type QueryGetNotificationHistoryWithCountForMobileArgs = {
  paginatedRequestInput: PaginatedRequestInput;
};

export type QueryGetOfferArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetOffersArgs = {
  offerWhere?: InputMaybe<OfferWhereInput>;
};

export type QueryGetOtpArgs = {
  otp: OtpRequestInput;
};

export type QueryGetPaymentCashOutRequestArgs = {
  cashOutRequestId: Scalars["String"]["input"];
};

export type QueryGetPaymentCashOutRequestsArgs = {
  orderBy?: InputMaybe<CashOutRequestOrderByWithRelationInputFirstOrder>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetPaymentResultArgs = {
  IdempotencyId: Scalars["String"]["input"];
};

export type QueryGetPaymentUrlByLineItemIdsArgs = {
  IdempotencyId: Scalars["String"]["input"];
  PaymentLineItemsIds: Array<PaymentLineItemUpdateInput>;
  creditNote?: InputMaybe<Scalars["Float"]["input"]>;
};

export type QueryGetRoleArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetRolesArgs = {
  where?: InputMaybe<RoleWhereInput>;
};

export type QueryGetRouteArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetRoutesArgs = {
  filter?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  routeWhere?: InputMaybe<RouteWhereInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetSafetyEventsArgs = {
  orderBy?: InputMaybe<GetSafetyEventsOrderClause>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  where?: InputMaybe<SafetyEventWhereInput>;
};

export type QueryGetSchoolArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetSchoolForMobileArgs = {
  schoolIdInput: SchoolIdInput;
};

export type QueryGetSchoolRegistrationArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetSchoolRegistrationConfigurationsArgs = {
  countryCode: CountryCode;
};

export type QueryGetSchoolRegistrationsArgs = {
  filter?: InputMaybe<GetSchoolRegistrationsFilter>;
  orderBy?: InputMaybe<GetSchoolRegistrationsOrderClause>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  schoolRegistrationWhere?: InputMaybe<SchoolRegistrationWhereInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetSchoolRegistrationsForMobileArgs = {
  schoolRegistrationsInput: GetSchoolRegistrationsInput;
};

export type QueryGetSchoolRegistrationsForRoutingArgs = {
  countryCode: Scalars["String"]["input"];
  directionality: RouteDirectionality;
  registeredAtFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  registeredAtTo?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStatusExclude?: InputMaybe<Array<RouteStatus>>;
  routeUsesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  scheduleEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduleStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  schoolIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryGetSchoolServiceDefinitionsArgs = {
  schoolIds: Array<Scalars["String"]["input"]>;
};

export type QueryGetSchoolsArgs = {
  orderBy?: InputMaybe<GetSchoolsOrderClause>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  schoolWhere?: InputMaybe<SchoolWhereInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetSchoolsForMobileArgs = {
  findAllSchoolsFilterInput: FindAllSchoolsFilterInput;
};

export type QueryGetServiceArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetServicePricingChangeArgs = {
  schoolRegistrationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetServiceProviderArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetServiceProvidersArgs = {
  order?: InputMaybe<GetServiceProvidersOrderClause>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type QueryGetServiceWithPriceArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  modifiers: ServicePriceModifiers;
  offerCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
};

export type QueryGetServiceWithPriceAndRefundArgs = {
  id: Scalars["String"]["input"];
  modifiers: ServicePriceAndRefundModifiers;
  offerCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
};

export type QueryGetServicesArgs = {
  serviceWhere?: InputMaybe<ServiceWhereInput>;
};

export type QueryGetSignedUrlArgs = {
  bucketName: Scalars["String"]["input"];
  key: Scalars["String"]["input"];
};

export type QueryGetSpecialNeedsArgs = {
  findAllSpecialNeedsFilterInput: FindAllSpecialNeedsFilterInput;
};

export type QueryGetSponsorArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetSponsorsArgs = {
  sponsorWhere?: InputMaybe<SponsorWhereInput>;
};

export type QueryGetStudentArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetStudentsArgs = {
  studentWhere?: InputMaybe<StudentWhereInput>;
};

export type QueryGetSubLocalitiesForMobileArgs = {
  subLocalitiesFilterInput: SubLocalitiesFilterInput;
};

export type QueryGetTripFeedbackArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetTripForBaBdArgs = {
  getTripForBaBdInput: GetTripForBaBdInput;
};

export type QueryGetTripForSponsorArgs = {
  date: Scalars["DateTime"]["input"];
  schoolRegistrationId: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
};

export type QueryGetTripManifestArgs = {
  date: Scalars["DateTime"]["input"];
  routeId: Scalars["String"]["input"];
};

export type QueryGetTripsCountForWebArgs = {
  isAlwaysPlanned?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  tripWhere?: InputMaybe<TripWhereInputForWeb>;
};

export type QueryGetTripsForBaBdArgs = {
  date: Scalars["DateTime"]["input"];
};

export type QueryGetTripsForBusAttendantArgs = {
  busAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  date: Scalars["DateTime"]["input"];
};

export type QueryGetTripsForDriverArgs = {
  date: Scalars["DateTime"]["input"];
  driverId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetTripsForSponsorArgs = {
  date: Scalars["DateTime"]["input"];
  schoolRegistrationId: Scalars["String"]["input"];
};

export type QueryGetTripsForWebArgs = {
  isAlwaysPlanned?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderBy?: InputMaybe<Array<TripOrderByForWeb>>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  tripWhere?: InputMaybe<TripWhereInputForWeb>;
};

export type QueryGetUpcomingTripForSponsorArgs = {
  date: Scalars["DateTime"]["input"];
  schoolRegistrationId: Scalars["String"]["input"];
};

export type QueryGetUserArgs = {
  userIdInput: UserIdInput;
};

export type QueryGetUserCreditNoteAmountArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryGetUsersArgs = {
  findAllUsersFilterInput: FindAllUsersFilterInput;
};

export type QueryGetVehicleArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetVehicleSafetyEventArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetVehicleSafetyEventCountArgs = {
  cause: VehicleSafetyEventCause;
  vehicleId: Scalars["String"]["input"];
};

export type QueryGetVehiclesArgs = {
  orderBy?: InputMaybe<GetVehiclesOrderClause>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  vehicleWhere?: InputMaybe<VehicleWhereInput>;
};

export type QueryIsAgentPhoneNumberExistForWebArgs = {
  phoneNumber: Scalars["String"]["input"];
};

export type QueryIsBusAttendancePhoneNumberExistForWebArgs = {
  phoneNumber: Scalars["String"]["input"];
};

export type QueryIsDriverPhoneNumberExistForWebArgs = {
  phoneNumber: Scalars["String"]["input"];
};

export type QueryIsEligibleForServiceArgs = {
  homeLocation?: InputMaybe<SimpleLatLon>;
  schoolId: Scalars["String"]["input"];
  serviceId: Scalars["String"]["input"];
  studentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryIsEligibleForServicesArgs = {
  countryCode: Scalars["String"]["input"];
  homeLocation?: InputMaybe<SimpleLatLon>;
  schoolId: Scalars["String"]["input"];
  studentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryIsEmailValidForWebArgs = {
  email: Scalars["String"]["input"];
};

export type QueryIsOfferValidArgs = {
  offerCode: Scalars["String"]["input"];
  usage: OfferUsage;
};

export type QueryIsPhoneNewArgs = {
  phone: Scalars["String"]["input"];
};

export type QueryIsPhoneNumberValidForWebArgs = {
  phoneNumber: Scalars["String"]["input"];
};

export type QueryIsSponsorPhoneNumberExistForWebArgs = {
  phoneNumber: Scalars["String"]["input"];
};

export type QueryIsValidCollectionPointCodeArgs = {
  code: Scalars["String"]["input"];
  serviceProviderId: Scalars["String"]["input"];
};

export type QueryIsVehicleGroupNameValidForWebArgs = {
  vehicleGroupName: Scalars["String"]["input"];
};

export type QueryPublishBoardingEventArgs = {
  boardingEvent: SimulationBoardingEvent;
};

export type QueryPublishVehicleEventArgs = {
  vehicleEvent: SimulationVehicleEvent;
};

export type QuerySearchAdministrativeAreasArgs = {
  countryCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  search: Scalars["String"]["input"];
};

export type QuerySearchAgentsArgs = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search: Scalars["String"]["input"];
};

export type QuerySearchBanksArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchBusAttendantsArgs = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search: Scalars["String"]["input"];
};

export type QuerySearchClassesArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchCollectionPointsArgs = {
  filter?: InputMaybe<SearchCollectionPointsFilterInput>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search: Scalars["String"]["input"];
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchContractTypesArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchDriversArgs = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search: Scalars["String"]["input"];
};

export type QuerySearchLocalitiesArgs = {
  administrativeAreaIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  countryCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  search: Scalars["String"]["input"];
};

export type QuerySearchRolesArgs = {
  notIdpManagedOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchRouteGroupsArgs = {
  pagination?: InputMaybe<PaginatedRequestInput>;
  search: Scalars["String"]["input"];
};

export type QuerySearchRoutesArgs = {
  filter?: InputMaybe<SearchRoutesFilterInput>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  routeGroupIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  routeWhere?: InputMaybe<RouteWhereInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchScheduleGroupsArgs = {
  pagination?: InputMaybe<PaginatedRequestInput>;
  schoolId?: InputMaybe<Scalars["String"]["input"]>;
  search: Scalars["String"]["input"];
};

export type QuerySearchSchedulesArgs = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search: Scalars["String"]["input"];
};

export type QuerySearchSchoolClustersArgs = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  countryCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  radius?: InputMaybe<Scalars["Float"]["input"]>;
  search: Scalars["String"]["input"];
};

export type QuerySearchSchoolGroupsArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchSchoolRegistrationsArgs = {
  pagination?: InputMaybe<PaginatedRequestInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  searchClause?: InputMaybe<SearchSchoolRegistrationClause>;
  searchSchoolRegistrationsFilter?: InputMaybe<SearchSchoolRegistrationsFilter>;
};

export type QuerySearchSchoolStagesArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchSchoolTypesArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchSchoolsArgs = {
  activeServiceSchoolsOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  countryCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search: Scalars["String"]["input"];
  serviceId?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderUsesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QuerySearchServiceCategoriesArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchServiceProviderGroupsArgs = {
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchServiceProvidersArgs = {
  filter?: InputMaybe<SearchServiceProvidersFilterInput>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search: Scalars["String"]["input"];
};

export type QuerySearchServicesArgs = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  search: Scalars["String"]["input"];
};

export type QuerySearchSpecialNeedsArgs = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchSponsorsArgs = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search: Scalars["String"]["input"];
};

export type QuerySearchStudentsArgs = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search: Scalars["String"]["input"];
};

export type QuerySearchSubLocalitiesArgs = {
  administrativeAreaIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  countryCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  localityIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  search: Scalars["String"]["input"];
};

export type QuerySearchTravelTagsArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchTripsArgs = {
  pagination?: InputMaybe<PaginatedRequestInput>;
  serviceProviderId: Scalars["String"]["input"];
};

export type QuerySearchVehicleGroupsArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchVehicleMakesArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchVehicleModelsArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchVehicleTypesArgs = {
  search: Scalars["String"]["input"];
};

export type QuerySearchVehiclesArgs = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<PaginatedRequestInput>;
  search: Scalars["String"]["input"];
};

export type QuerySearchZonesArgs = {
  search: Scalars["String"]["input"];
};

export enum QueryMode {
  DEFAULT = "default",
  INSENSITIVE = "insensitive",
}

export type RefreshTokenInput = {
  refreshToken: Scalars["String"]["input"];
};

export type RegisterChildInfoInput = {
  address: CreateAddressInput;
  dateOfBirth: Scalars["DateTime"]["input"];
  firstName: Scalars["String"]["input"];
  gender: Gender;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  otherSpecialNeed?: InputMaybe<Scalars["String"]["input"]>;
  photoKey?: InputMaybe<Scalars["String"]["input"]>;
  relationship: Relationship;
  school: RegisterStudentSchoolFromMobileInput;
  selectedInstallmentPlanId: Scalars["String"]["input"];
  specialNeeds?: InputMaybe<Array<RegisterStudentSpecialNeedInput>>;
};

export type RegisterChildrenFromMobileInput = {
  children: Array<RegisterChildInfoInput>;
  lang: Language;
  offerCode?: InputMaybe<Scalars["String"]["input"]>;
  parentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RegisterStudentSchoolFromMobileInput = {
  classId: Scalars["String"]["input"];
  division?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroupId: Scalars["String"]["input"];
  schoolId: Scalars["String"]["input"];
  tripType: TripType;
};

export type RegisterStudentSpecialNeedInput = {
  specialNeedId: Scalars["String"]["input"];
};

export type RegisterSubscriberInput = {
  country?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  model: Scalars["String"]["input"];
  os: Scalars["String"]["input"];
  osVersion: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export enum RegistrationRoutingStatus {
  COMPLETE = "COMPLETE",
  NONE = "NONE",
  PARTIAL_COMPLETE = "PARTIAL_COMPLETE",
}

export enum Relationship {
  FATHER = "FATHER",
  MOTHER = "MOTHER",
}

export type RemoveSubscriberFromTopicsInput = {
  token: Scalars["String"]["input"];
  topics: Array<Scalars["String"]["input"]>;
};

/** ////////////////////////////////////////////////////////////////////////////////////////////////////// */
export type Role = {
  __typename?: "Role";
  _count: RoleCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  class: RoleClass;
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  googleGroupId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  permissions?: Maybe<Array<RolePermission>>;
  serviceProviderGroup?: Maybe<ServiceProviderGroup>;
  serviceProviderGroupId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  users?: Maybe<Array<UserRole>>;
};

export enum RoleClass {
  AGENT = "AGENT",
  BABD = "BABD",
  SPONSOR = "SPONSOR",
}

export type RoleCount = {
  __typename?: "RoleCount";
  permissions: Scalars["Int"]["output"];
  users: Scalars["Int"]["output"];
};

export type RoleCreateManyServiceProviderGroupInput = {
  class?: InputMaybe<RoleClass>;
  description: Scalars["String"]["input"];
  googleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RoleCreateManyServiceProviderGroupInputEnvelope = {
  data: Array<RoleCreateManyServiceProviderGroupInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RoleCreateNestedManyWithoutServiceProviderGroupInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RoleCreateOrConnectWithoutServiceProviderGroupInput>
  >;
  create?: InputMaybe<Array<RoleCreateWithoutServiceProviderGroupInput>>;
  createMany?: InputMaybe<RoleCreateManyServiceProviderGroupInputEnvelope>;
};

export type RoleCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<RoleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoleCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<RoleCreateWithoutUsersInput>;
};

export type RoleCreateOrConnectWithoutServiceProviderGroupInput = {
  create: RoleCreateWithoutServiceProviderGroupInput;
  where: RoleWhereUniqueInput;
};

export type RoleCreateOrConnectWithoutUsersInput = {
  create: RoleCreateWithoutUsersInput;
  where: RoleWhereUniqueInput;
};

export type RoleCreateWithoutServiceProviderGroupInput = {
  class?: InputMaybe<RoleClass>;
  description: Scalars["String"]["input"];
  googleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  permissions?: InputMaybe<RolePermissionCreateNestedManyWithoutRoleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<UserRoleCreateNestedManyWithoutRoleInput>;
};

export type RoleCreateWithoutUsersInput = {
  class?: InputMaybe<RoleClass>;
  description: Scalars["String"]["input"];
  googleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  permissions?: InputMaybe<RolePermissionCreateNestedManyWithoutRoleInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutRolesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RoleListRelationFilter = {
  every?: InputMaybe<RoleWhereInput>;
  none?: InputMaybe<RoleWhereInput>;
  some?: InputMaybe<RoleWhereInput>;
};

export type RolePermission = {
  __typename?: "RolePermission";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  permission: Permission;
  permissionId: Scalars["String"]["output"];
  role: Role;
  roleId: Scalars["String"]["output"];
  serviceProviderId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type RolePermissionCreateManyRoleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  permissionId: Scalars["String"]["input"];
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RolePermissionCreateManyRoleInputEnvelope = {
  data: Array<RolePermissionCreateManyRoleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RolePermissionCreateNestedManyWithoutRoleInput = {
  connect?: InputMaybe<Array<RolePermissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RolePermissionCreateOrConnectWithoutRoleInput>
  >;
  create?: InputMaybe<Array<RolePermissionCreateWithoutRoleInput>>;
  createMany?: InputMaybe<RolePermissionCreateManyRoleInputEnvelope>;
};

export type RolePermissionCreateOrConnectWithoutRoleInput = {
  create: RolePermissionCreateWithoutRoleInput;
  where: RolePermissionWhereUniqueInput;
};

export type RolePermissionCreateWithoutRoleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  permission: PermissionCreateNestedOneWithoutRolesInput;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RolePermissionListRelationFilter = {
  every?: InputMaybe<RolePermissionWhereInput>;
  none?: InputMaybe<RolePermissionWhereInput>;
  some?: InputMaybe<RolePermissionWhereInput>;
};

export type RolePermissionScalarWhereInput = {
  AND?: InputMaybe<Array<RolePermissionScalarWhereInput>>;
  NOT?: InputMaybe<Array<RolePermissionScalarWhereInput>>;
  OR?: InputMaybe<Array<RolePermissionScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  permissionId?: InputMaybe<StringFilter>;
  roleId?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RolePermissionUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RolePermissionUpdateManyWithWhereWithoutRoleInput = {
  data: RolePermissionUpdateManyMutationInput;
  where: RolePermissionScalarWhereInput;
};

export type RolePermissionUpdateManyWithoutRoleNestedInput = {
  connect?: InputMaybe<Array<RolePermissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RolePermissionCreateOrConnectWithoutRoleInput>
  >;
  create?: InputMaybe<Array<RolePermissionCreateWithoutRoleInput>>;
  createMany?: InputMaybe<RolePermissionCreateManyRoleInputEnvelope>;
  delete?: InputMaybe<Array<RolePermissionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RolePermissionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RolePermissionWhereUniqueInput>>;
  set?: InputMaybe<Array<RolePermissionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RolePermissionUpdateWithWhereUniqueWithoutRoleInput>
  >;
  updateMany?: InputMaybe<
    Array<RolePermissionUpdateManyWithWhereWithoutRoleInput>
  >;
  upsert?: InputMaybe<
    Array<RolePermissionUpsertWithWhereUniqueWithoutRoleInput>
  >;
};

export type RolePermissionUpdateWithWhereUniqueWithoutRoleInput = {
  data: RolePermissionUpdateWithoutRoleInput;
  where: RolePermissionWhereUniqueInput;
};

export type RolePermissionUpdateWithoutRoleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  permission?: InputMaybe<PermissionUpdateOneRequiredWithoutRolesNestedInput>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RolePermissionUpsertWithWhereUniqueWithoutRoleInput = {
  create: RolePermissionCreateWithoutRoleInput;
  update: RolePermissionUpdateWithoutRoleInput;
  where: RolePermissionWhereUniqueInput;
};

export type RolePermissionWhereInput = {
  AND?: InputMaybe<Array<RolePermissionWhereInput>>;
  NOT?: InputMaybe<Array<RolePermissionWhereInput>>;
  OR?: InputMaybe<Array<RolePermissionWhereInput>>;
  id?: InputMaybe<StringFilter>;
  permission?: InputMaybe<PermissionRelationFilter>;
  permissionId?: InputMaybe<StringFilter>;
  role?: InputMaybe<RoleRelationFilter>;
  roleId?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RolePermissionWhereUniqueInput = {
  AND?: InputMaybe<Array<RolePermissionWhereInput>>;
  NOT?: InputMaybe<Array<RolePermissionWhereInput>>;
  OR?: InputMaybe<Array<RolePermissionWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  permission?: InputMaybe<PermissionRelationFilter>;
  permissionId?: InputMaybe<StringFilter>;
  role?: InputMaybe<RoleRelationFilter>;
  roleId?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RoleRelationFilter = {
  is?: InputMaybe<RoleWhereInput>;
  isNot?: InputMaybe<RoleWhereInput>;
};

export type RoleResponsePayload = {
  __typename?: "RoleResponsePayload";
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type RoleScalarWhereInput = {
  AND?: InputMaybe<Array<RoleScalarWhereInput>>;
  NOT?: InputMaybe<Array<RoleScalarWhereInput>>;
  OR?: InputMaybe<Array<RoleScalarWhereInput>>;
  class?: InputMaybe<EnumRoleClassFilter>;
  description?: InputMaybe<StringFilter>;
  googleGroupId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  serviceProviderGroupId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RoleUpdateManyMutationInput = {
  class?: InputMaybe<RoleClass>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  googleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RoleUpdateManyWithWhereWithoutServiceProviderGroupInput = {
  data: RoleUpdateManyMutationInput;
  where: RoleScalarWhereInput;
};

export type RoleUpdateManyWithoutServiceProviderGroupNestedInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RoleCreateOrConnectWithoutServiceProviderGroupInput>
  >;
  create?: InputMaybe<Array<RoleCreateWithoutServiceProviderGroupInput>>;
  createMany?: InputMaybe<RoleCreateManyServiceProviderGroupInputEnvelope>;
  delete?: InputMaybe<Array<RoleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  set?: InputMaybe<Array<RoleWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RoleUpdateWithWhereUniqueWithoutServiceProviderGroupInput>
  >;
  updateMany?: InputMaybe<
    Array<RoleUpdateManyWithWhereWithoutServiceProviderGroupInput>
  >;
  upsert?: InputMaybe<
    Array<RoleUpsertWithWhereUniqueWithoutServiceProviderGroupInput>
  >;
};

export type RoleUpdateOneRequiredWithoutUsersNestedInput = {
  connect?: InputMaybe<RoleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoleCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<RoleCreateWithoutUsersInput>;
  update?: InputMaybe<RoleUpdateToOneWithWhereWithoutUsersInput>;
  upsert?: InputMaybe<RoleUpsertWithoutUsersInput>;
};

export type RoleUpdateToOneWithWhereWithoutUsersInput = {
  data: RoleUpdateWithoutUsersInput;
  where?: InputMaybe<RoleWhereInput>;
};

export type RoleUpdateWithWhereUniqueWithoutServiceProviderGroupInput = {
  data: RoleUpdateWithoutServiceProviderGroupInput;
  where: RoleWhereUniqueInput;
};

export type RoleUpdateWithoutServiceProviderGroupInput = {
  class?: InputMaybe<RoleClass>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  googleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  permissions?: InputMaybe<RolePermissionUpdateManyWithoutRoleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<UserRoleUpdateManyWithoutRoleNestedInput>;
};

export type RoleUpdateWithoutUsersInput = {
  class?: InputMaybe<RoleClass>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  googleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  permissions?: InputMaybe<RolePermissionUpdateManyWithoutRoleNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutRolesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RoleUpsertWithWhereUniqueWithoutServiceProviderGroupInput = {
  create: RoleCreateWithoutServiceProviderGroupInput;
  update: RoleUpdateWithoutServiceProviderGroupInput;
  where: RoleWhereUniqueInput;
};

export type RoleUpsertWithoutUsersInput = {
  create: RoleCreateWithoutUsersInput;
  update: RoleUpdateWithoutUsersInput;
  where?: InputMaybe<RoleWhereInput>;
};

export type RoleWhereInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  class?: InputMaybe<EnumRoleClassFilter>;
  description?: InputMaybe<StringFilter>;
  googleGroupId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  permissions?: InputMaybe<RolePermissionListRelationFilter>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupRelationFilter>;
  serviceProviderGroupId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserRoleListRelationFilter>;
};

export type RoleWhereUniqueInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  class?: InputMaybe<EnumRoleClassFilter>;
  description?: InputMaybe<StringFilter>;
  googleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  permissions?: InputMaybe<RolePermissionListRelationFilter>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupRelationFilter>;
  serviceProviderGroupId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserRoleListRelationFilter>;
};

export type Route = {
  __typename?: "Route";
  _count: RouteCount;
  agents?: Maybe<Array<RouteAgent>>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendants?: Maybe<Array<RouteBusAttendant>>;
  code?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  directionality: RouteDirectionality;
  displayName?: Maybe<Scalars["String"]["output"]>;
  drivers?: Maybe<Array<RouteDriver>>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  estimatedDistance?: Maybe<Scalars["Float"]["output"]>;
  estimatedTime?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["String"]["output"];
  isAutoActive: Scalars["Boolean"]["output"];
  leaveRequests?: Maybe<Array<LeaveRequest>>;
  manualAttendanceEndOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  manualAttendanceStartOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  plannedEndTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedSchoolStopDuration: Scalars["Int"]["output"];
  plannedStartTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedStudentStopDuration: Scalars["Int"]["output"];
  reference?: Maybe<Scalars["String"]["output"]>;
  routeBusAttendantVacantAlerts?: Maybe<Array<RouteBusAttendantVacantAlert>>;
  routeCapacityExceededAlerts?: Maybe<Array<RouteCapacityExceededAlert>>;
  routeDateAlerts?: Maybe<Array<RouteDateAlert>>;
  routeDriverVacantAlerts?: Maybe<Array<RouteDriverVacantAlert>>;
  /** /////////////////////////////////// */
  routeGroup?: Maybe<RouteGroup>;
  routeGroupId?: Maybe<Scalars["String"]["output"]>;
  routeOptimizationConfigs?: Maybe<Array<RouteOptimizationConfig>>;
  routeStops?: Maybe<Array<RouteStop>>;
  routeTimeLimitExceededAlerts?: Maybe<Array<RouteTimeLimitExceededAlert>>;
  routeVehicleVacantAlerts?: Maybe<Array<RouteVehicleVacantAlert>>;
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars["String"]["output"];
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status: RouteStatus;
  suggestedEndTime?: Maybe<Scalars["DateTime"]["output"]>;
  suggestedStartTime?: Maybe<Scalars["DateTime"]["output"]>;
  tripEndOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  tripStartOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  trips?: Maybe<Array<Trip>>;
  updatedAt: Scalars["DateTime"]["output"];
  useDriverVehicle: Scalars["Boolean"]["output"];
  usesCollectionPoints?: Maybe<Scalars["Boolean"]["output"]>;
  vehicles?: Maybe<Array<RouteVehicle>>;
};

export type RouteAgent = {
  __typename?: "RouteAgent";
  agent: Agent;
  agentId: Scalars["String"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  fromDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  precedence: Scalars["Int"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
  toDate?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type RouteAgentCreateManyAgentInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  routeId: Scalars["String"]["input"];
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteAgentCreateManyAgentInputEnvelope = {
  data: Array<RouteAgentCreateManyAgentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteAgentCreateManyRouteInput = {
  agentId: Scalars["String"]["input"];
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteAgentCreateManyRouteInputEnvelope = {
  data: Array<RouteAgentCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteAgentCreateNestedManyWithoutAgentInput = {
  connect?: InputMaybe<Array<RouteAgentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteAgentCreateOrConnectWithoutAgentInput>
  >;
  create?: InputMaybe<Array<RouteAgentCreateWithoutAgentInput>>;
  createMany?: InputMaybe<RouteAgentCreateManyAgentInputEnvelope>;
};

export type RouteAgentCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteAgentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteAgentCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteAgentCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteAgentCreateManyRouteInputEnvelope>;
};

export type RouteAgentCreateOrConnectWithoutAgentInput = {
  create: RouteAgentCreateWithoutAgentInput;
  where: RouteAgentWhereUniqueInput;
};

export type RouteAgentCreateOrConnectWithoutRouteInput = {
  create: RouteAgentCreateWithoutRouteInput;
  where: RouteAgentWhereUniqueInput;
};

export type RouteAgentCreateWithoutAgentInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  route: RouteCreateNestedOneWithoutAgentsInput;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteAgentCreateWithoutRouteInput = {
  agent: AgentCreateNestedOneWithoutRoutesInput;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteAgentListRelationFilter = {
  every?: InputMaybe<RouteAgentWhereInput>;
  none?: InputMaybe<RouteAgentWhereInput>;
  some?: InputMaybe<RouteAgentWhereInput>;
};

export type RouteAgentScalarWhereInput = {
  AND?: InputMaybe<Array<RouteAgentScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteAgentScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteAgentScalarWhereInput>>;
  agentId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteAgentTypes = {
  agentId: Array<Scalars["String"]["input"]>;
  fromDate: Scalars["DateTime"]["input"];
  routeId: Array<Scalars["String"]["input"]>;
  toDate: Scalars["DateTime"]["input"];
};

export type RouteAgentUpdateManyMutationInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteAgentUpdateManyWithWhereWithoutAgentInput = {
  data: RouteAgentUpdateManyMutationInput;
  where: RouteAgentScalarWhereInput;
};

export type RouteAgentUpdateManyWithWhereWithoutRouteInput = {
  data: RouteAgentUpdateManyMutationInput;
  where: RouteAgentScalarWhereInput;
};

export type RouteAgentUpdateManyWithoutAgentNestedInput = {
  connect?: InputMaybe<Array<RouteAgentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteAgentCreateOrConnectWithoutAgentInput>
  >;
  create?: InputMaybe<Array<RouteAgentCreateWithoutAgentInput>>;
  createMany?: InputMaybe<RouteAgentCreateManyAgentInputEnvelope>;
  delete?: InputMaybe<Array<RouteAgentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteAgentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteAgentWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteAgentWhereUniqueInput>>;
  update?: InputMaybe<Array<RouteAgentUpdateWithWhereUniqueWithoutAgentInput>>;
  updateMany?: InputMaybe<
    Array<RouteAgentUpdateManyWithWhereWithoutAgentInput>
  >;
  upsert?: InputMaybe<Array<RouteAgentUpsertWithWhereUniqueWithoutAgentInput>>;
};

export type RouteAgentUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteAgentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteAgentCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteAgentCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteAgentCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteAgentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteAgentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteAgentWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteAgentWhereUniqueInput>>;
  update?: InputMaybe<Array<RouteAgentUpdateWithWhereUniqueWithoutRouteInput>>;
  updateMany?: InputMaybe<
    Array<RouteAgentUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<Array<RouteAgentUpsertWithWhereUniqueWithoutRouteInput>>;
};

export type RouteAgentUpdateWithWhereUniqueWithoutAgentInput = {
  data: RouteAgentUpdateWithoutAgentInput;
  where: RouteAgentWhereUniqueInput;
};

export type RouteAgentUpdateWithWhereUniqueWithoutRouteInput = {
  data: RouteAgentUpdateWithoutRouteInput;
  where: RouteAgentWhereUniqueInput;
};

export type RouteAgentUpdateWithoutAgentInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutAgentsNestedInput>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteAgentUpdateWithoutRouteInput = {
  agent?: InputMaybe<AgentUpdateOneRequiredWithoutRoutesNestedInput>;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteAgentUpsertWithWhereUniqueWithoutAgentInput = {
  create: RouteAgentCreateWithoutAgentInput;
  update: RouteAgentUpdateWithoutAgentInput;
  where: RouteAgentWhereUniqueInput;
};

export type RouteAgentUpsertWithWhereUniqueWithoutRouteInput = {
  create: RouteAgentCreateWithoutRouteInput;
  update: RouteAgentUpdateWithoutRouteInput;
  where: RouteAgentWhereUniqueInput;
};

export type RouteAgentWhereInput = {
  AND?: InputMaybe<Array<RouteAgentWhereInput>>;
  NOT?: InputMaybe<Array<RouteAgentWhereInput>>;
  OR?: InputMaybe<Array<RouteAgentWhereInput>>;
  agent?: InputMaybe<AgentRelationFilter>;
  agentId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteAgentWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteAgentWhereInput>>;
  NOT?: InputMaybe<Array<RouteAgentWhereInput>>;
  OR?: InputMaybe<Array<RouteAgentWhereInput>>;
  agent?: InputMaybe<AgentRelationFilter>;
  agentId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteBusAttendant = {
  __typename?: "RouteBusAttendant";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendant: BusAttendant;
  busAttendantId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  fromDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  precedence: Scalars["Int"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
  toDate?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type RouteBusAttendantCreateManyBusAttendantInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  routeId: Scalars["String"]["input"];
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteBusAttendantCreateManyBusAttendantInputEnvelope = {
  data: Array<RouteBusAttendantCreateManyBusAttendantInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteBusAttendantCreateManyRouteInput = {
  busAttendantId: Scalars["String"]["input"];
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteBusAttendantCreateManyRouteInputEnvelope = {
  data: Array<RouteBusAttendantCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteBusAttendantCreateNestedManyWithoutBusAttendantInput = {
  connect?: InputMaybe<Array<RouteBusAttendantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteBusAttendantCreateOrConnectWithoutBusAttendantInput>
  >;
  create?: InputMaybe<Array<RouteBusAttendantCreateWithoutBusAttendantInput>>;
  createMany?: InputMaybe<RouteBusAttendantCreateManyBusAttendantInputEnvelope>;
};

export type RouteBusAttendantCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteBusAttendantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteBusAttendantCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteBusAttendantCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteBusAttendantCreateManyRouteInputEnvelope>;
};

export type RouteBusAttendantCreateOrConnectWithoutBusAttendantInput = {
  create: RouteBusAttendantCreateWithoutBusAttendantInput;
  where: RouteBusAttendantWhereUniqueInput;
};

export type RouteBusAttendantCreateOrConnectWithoutRouteInput = {
  create: RouteBusAttendantCreateWithoutRouteInput;
  where: RouteBusAttendantWhereUniqueInput;
};

export type RouteBusAttendantCreateWithoutBusAttendantInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  route: RouteCreateNestedOneWithoutBusAttendantsInput;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteBusAttendantCreateWithoutRouteInput = {
  busAttendant: BusAttendantCreateNestedOneWithoutRoutesInput;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteBusAttendantListRelationFilter = {
  every?: InputMaybe<RouteBusAttendantWhereInput>;
  none?: InputMaybe<RouteBusAttendantWhereInput>;
  some?: InputMaybe<RouteBusAttendantWhereInput>;
};

export type RouteBusAttendantScalarWhereInput = {
  AND?: InputMaybe<Array<RouteBusAttendantScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteBusAttendantScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteBusAttendantScalarWhereInput>>;
  busAttendantId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteBusAttendantUpdateManyMutationInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteBusAttendantUpdateManyWithWhereWithoutBusAttendantInput = {
  data: RouteBusAttendantUpdateManyMutationInput;
  where: RouteBusAttendantScalarWhereInput;
};

export type RouteBusAttendantUpdateManyWithWhereWithoutRouteInput = {
  data: RouteBusAttendantUpdateManyMutationInput;
  where: RouteBusAttendantScalarWhereInput;
};

export type RouteBusAttendantUpdateManyWithoutBusAttendantNestedInput = {
  connect?: InputMaybe<Array<RouteBusAttendantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteBusAttendantCreateOrConnectWithoutBusAttendantInput>
  >;
  create?: InputMaybe<Array<RouteBusAttendantCreateWithoutBusAttendantInput>>;
  createMany?: InputMaybe<RouteBusAttendantCreateManyBusAttendantInputEnvelope>;
  delete?: InputMaybe<Array<RouteBusAttendantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteBusAttendantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteBusAttendantWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteBusAttendantWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteBusAttendantUpdateWithWhereUniqueWithoutBusAttendantInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteBusAttendantUpdateManyWithWhereWithoutBusAttendantInput>
  >;
  upsert?: InputMaybe<
    Array<RouteBusAttendantUpsertWithWhereUniqueWithoutBusAttendantInput>
  >;
};

export type RouteBusAttendantUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteBusAttendantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteBusAttendantCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteBusAttendantCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteBusAttendantCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteBusAttendantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteBusAttendantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteBusAttendantWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteBusAttendantWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteBusAttendantUpdateWithWhereUniqueWithoutRouteInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteBusAttendantUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<
    Array<RouteBusAttendantUpsertWithWhereUniqueWithoutRouteInput>
  >;
};

export type RouteBusAttendantUpdateWithWhereUniqueWithoutBusAttendantInput = {
  data: RouteBusAttendantUpdateWithoutBusAttendantInput;
  where: RouteBusAttendantWhereUniqueInput;
};

export type RouteBusAttendantUpdateWithWhereUniqueWithoutRouteInput = {
  data: RouteBusAttendantUpdateWithoutRouteInput;
  where: RouteBusAttendantWhereUniqueInput;
};

export type RouteBusAttendantUpdateWithoutBusAttendantInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutBusAttendantsNestedInput>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteBusAttendantUpdateWithoutRouteInput = {
  busAttendant?: InputMaybe<BusAttendantUpdateOneRequiredWithoutRoutesNestedInput>;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteBusAttendantUpsertWithWhereUniqueWithoutBusAttendantInput = {
  create: RouteBusAttendantCreateWithoutBusAttendantInput;
  update: RouteBusAttendantUpdateWithoutBusAttendantInput;
  where: RouteBusAttendantWhereUniqueInput;
};

export type RouteBusAttendantUpsertWithWhereUniqueWithoutRouteInput = {
  create: RouteBusAttendantCreateWithoutRouteInput;
  update: RouteBusAttendantUpdateWithoutRouteInput;
  where: RouteBusAttendantWhereUniqueInput;
};

export type RouteBusAttendantVacantAlert = {
  __typename?: "RouteBusAttendantVacantAlert";
  alert: Alert;
  alertId: Scalars["String"]["output"];
  busAttendant?: Maybe<BusAttendant>;
  busAttendantId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
};

export type RouteBusAttendantVacantAlertCreateManyBusAttendantInput = {
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeId: Scalars["String"]["input"];
};

export type RouteBusAttendantVacantAlertCreateManyBusAttendantInputEnvelope = {
  data: Array<RouteBusAttendantVacantAlertCreateManyBusAttendantInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteBusAttendantVacantAlertCreateManyRouteInput = {
  alertId: Scalars["String"]["input"];
  busAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteBusAttendantVacantAlertCreateManyRouteInputEnvelope = {
  data: Array<RouteBusAttendantVacantAlertCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteBusAttendantVacantAlertCreateNestedManyWithoutBusAttendantInput =
  {
    connect?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<RouteBusAttendantVacantAlertCreateOrConnectWithoutBusAttendantInput>
    >;
    create?: InputMaybe<
      Array<RouteBusAttendantVacantAlertCreateWithoutBusAttendantInput>
    >;
    createMany?: InputMaybe<RouteBusAttendantVacantAlertCreateManyBusAttendantInputEnvelope>;
  };

export type RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteBusAttendantVacantAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<
    Array<RouteBusAttendantVacantAlertCreateWithoutRouteInput>
  >;
  createMany?: InputMaybe<RouteBusAttendantVacantAlertCreateManyRouteInputEnvelope>;
};

export type RouteBusAttendantVacantAlertCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<RouteBusAttendantVacantAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteBusAttendantVacantAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteBusAttendantVacantAlertCreateWithoutAlertInput>;
};

export type RouteBusAttendantVacantAlertCreateOrConnectWithoutAlertInput = {
  create: RouteBusAttendantVacantAlertCreateWithoutAlertInput;
  where: RouteBusAttendantVacantAlertWhereUniqueInput;
};

export type RouteBusAttendantVacantAlertCreateOrConnectWithoutBusAttendantInput =
  {
    create: RouteBusAttendantVacantAlertCreateWithoutBusAttendantInput;
    where: RouteBusAttendantVacantAlertWhereUniqueInput;
  };

export type RouteBusAttendantVacantAlertCreateOrConnectWithoutRouteInput = {
  create: RouteBusAttendantVacantAlertCreateWithoutRouteInput;
  where: RouteBusAttendantVacantAlertWhereUniqueInput;
};

export type RouteBusAttendantVacantAlertCreateWithoutAlertInput = {
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutRouteBusAttendantVacantAlertsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route: RouteCreateNestedOneWithoutRouteBusAttendantVacantAlertsInput;
};

export type RouteBusAttendantVacantAlertCreateWithoutBusAttendantInput = {
  alert: AlertCreateNestedOneWithoutRouteBusAttendantVacantAlertInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route: RouteCreateNestedOneWithoutRouteBusAttendantVacantAlertsInput;
};

export type RouteBusAttendantVacantAlertCreateWithoutRouteInput = {
  alert: AlertCreateNestedOneWithoutRouteBusAttendantVacantAlertInput;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutRouteBusAttendantVacantAlertsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteBusAttendantVacantAlertListRelationFilter = {
  every?: InputMaybe<RouteBusAttendantVacantAlertWhereInput>;
  none?: InputMaybe<RouteBusAttendantVacantAlertWhereInput>;
  some?: InputMaybe<RouteBusAttendantVacantAlertWhereInput>;
};

export type RouteBusAttendantVacantAlertRelationFilter = {
  is?: InputMaybe<RouteBusAttendantVacantAlertWhereInput>;
  isNot?: InputMaybe<RouteBusAttendantVacantAlertWhereInput>;
};

export type RouteBusAttendantVacantAlertScalarWhereInput = {
  AND?: InputMaybe<Array<RouteBusAttendantVacantAlertScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteBusAttendantVacantAlertScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteBusAttendantVacantAlertScalarWhereInput>>;
  alertId?: InputMaybe<StringFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteBusAttendantVacantAlertUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteBusAttendantVacantAlertUpdateManyWithWhereWithoutBusAttendantInput =
  {
    data: RouteBusAttendantVacantAlertUpdateManyMutationInput;
    where: RouteBusAttendantVacantAlertScalarWhereInput;
  };

export type RouteBusAttendantVacantAlertUpdateManyWithWhereWithoutRouteInput = {
  data: RouteBusAttendantVacantAlertUpdateManyMutationInput;
  where: RouteBusAttendantVacantAlertScalarWhereInput;
};

export type RouteBusAttendantVacantAlertUpdateManyWithoutBusAttendantNestedInput =
  {
    connect?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<RouteBusAttendantVacantAlertCreateOrConnectWithoutBusAttendantInput>
    >;
    create?: InputMaybe<
      Array<RouteBusAttendantVacantAlertCreateWithoutBusAttendantInput>
    >;
    createMany?: InputMaybe<RouteBusAttendantVacantAlertCreateManyBusAttendantInputEnvelope>;
    delete?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereUniqueInput>>;
    deleteMany?: InputMaybe<
      Array<RouteBusAttendantVacantAlertScalarWhereInput>
    >;
    disconnect?: InputMaybe<
      Array<RouteBusAttendantVacantAlertWhereUniqueInput>
    >;
    set?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereUniqueInput>>;
    update?: InputMaybe<
      Array<RouteBusAttendantVacantAlertUpdateWithWhereUniqueWithoutBusAttendantInput>
    >;
    updateMany?: InputMaybe<
      Array<RouteBusAttendantVacantAlertUpdateManyWithWhereWithoutBusAttendantInput>
    >;
    upsert?: InputMaybe<
      Array<RouteBusAttendantVacantAlertUpsertWithWhereUniqueWithoutBusAttendantInput>
    >;
  };

export type RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteBusAttendantVacantAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<
    Array<RouteBusAttendantVacantAlertCreateWithoutRouteInput>
  >;
  createMany?: InputMaybe<RouteBusAttendantVacantAlertCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteBusAttendantVacantAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteBusAttendantVacantAlertUpdateWithWhereUniqueWithoutRouteInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteBusAttendantVacantAlertUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<
    Array<RouteBusAttendantVacantAlertUpsertWithWhereUniqueWithoutRouteInput>
  >;
};

export type RouteBusAttendantVacantAlertUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<RouteBusAttendantVacantAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteBusAttendantVacantAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteBusAttendantVacantAlertCreateWithoutAlertInput>;
  delete?: InputMaybe<RouteBusAttendantVacantAlertWhereInput>;
  disconnect?: InputMaybe<RouteBusAttendantVacantAlertWhereInput>;
  update?: InputMaybe<RouteBusAttendantVacantAlertUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<RouteBusAttendantVacantAlertUpsertWithoutAlertInput>;
};

export type RouteBusAttendantVacantAlertUpdateToOneWithWhereWithoutAlertInput =
  {
    data: RouteBusAttendantVacantAlertUpdateWithoutAlertInput;
    where?: InputMaybe<RouteBusAttendantVacantAlertWhereInput>;
  };

export type RouteBusAttendantVacantAlertUpdateWithWhereUniqueWithoutBusAttendantInput =
  {
    data: RouteBusAttendantVacantAlertUpdateWithoutBusAttendantInput;
    where: RouteBusAttendantVacantAlertWhereUniqueInput;
  };

export type RouteBusAttendantVacantAlertUpdateWithWhereUniqueWithoutRouteInput =
  {
    data: RouteBusAttendantVacantAlertUpdateWithoutRouteInput;
    where: RouteBusAttendantVacantAlertWhereUniqueInput;
  };

export type RouteBusAttendantVacantAlertUpdateWithoutAlertInput = {
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutRouteBusAttendantVacantAlertsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteBusAttendantVacantAlertsNestedInput>;
};

export type RouteBusAttendantVacantAlertUpdateWithoutBusAttendantInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutRouteBusAttendantVacantAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteBusAttendantVacantAlertsNestedInput>;
};

export type RouteBusAttendantVacantAlertUpdateWithoutRouteInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutRouteBusAttendantVacantAlertNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutRouteBusAttendantVacantAlertsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteBusAttendantVacantAlertUpsertWithWhereUniqueWithoutBusAttendantInput =
  {
    create: RouteBusAttendantVacantAlertCreateWithoutBusAttendantInput;
    update: RouteBusAttendantVacantAlertUpdateWithoutBusAttendantInput;
    where: RouteBusAttendantVacantAlertWhereUniqueInput;
  };

export type RouteBusAttendantVacantAlertUpsertWithWhereUniqueWithoutRouteInput =
  {
    create: RouteBusAttendantVacantAlertCreateWithoutRouteInput;
    update: RouteBusAttendantVacantAlertUpdateWithoutRouteInput;
    where: RouteBusAttendantVacantAlertWhereUniqueInput;
  };

export type RouteBusAttendantVacantAlertUpsertWithoutAlertInput = {
  create: RouteBusAttendantVacantAlertCreateWithoutAlertInput;
  update: RouteBusAttendantVacantAlertUpdateWithoutAlertInput;
  where?: InputMaybe<RouteBusAttendantVacantAlertWhereInput>;
};

export type RouteBusAttendantVacantAlertWhereInput = {
  AND?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteBusAttendantVacantAlertWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteBusAttendantVacantAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteBusAttendantWhereInput = {
  AND?: InputMaybe<Array<RouteBusAttendantWhereInput>>;
  NOT?: InputMaybe<Array<RouteBusAttendantWhereInput>>;
  OR?: InputMaybe<Array<RouteBusAttendantWhereInput>>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteBusAttendantWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteBusAttendantWhereInput>>;
  NOT?: InputMaybe<Array<RouteBusAttendantWhereInput>>;
  OR?: InputMaybe<Array<RouteBusAttendantWhereInput>>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteBusCapacity = {
  __typename?: "RouteBusCapacity";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busCount: Scalars["Int"]["output"];
  capacity: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  routeOptimizationConfig?: Maybe<RouteOptimizationConfig>;
  routeOptimizationConfigId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type RouteBusCapacityCreateManyRouteOptimizationConfigInput = {
  busCount: Scalars["Int"]["input"];
  capacity: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteBusCapacityCreateManyRouteOptimizationConfigInputEnvelope = {
  data: Array<RouteBusCapacityCreateManyRouteOptimizationConfigInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteBusCapacityCreateNestedManyWithoutRouteOptimizationConfigInput =
  {
    connect?: InputMaybe<Array<RouteBusCapacityWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<RouteBusCapacityCreateOrConnectWithoutRouteOptimizationConfigInput>
    >;
    create?: InputMaybe<
      Array<RouteBusCapacityCreateWithoutRouteOptimizationConfigInput>
    >;
    createMany?: InputMaybe<RouteBusCapacityCreateManyRouteOptimizationConfigInputEnvelope>;
  };

export type RouteBusCapacityCreateOrConnectWithoutRouteOptimizationConfigInput =
  {
    create: RouteBusCapacityCreateWithoutRouteOptimizationConfigInput;
    where: RouteBusCapacityWhereUniqueInput;
  };

export type RouteBusCapacityCreateWithoutRouteOptimizationConfigInput = {
  busCount: Scalars["Int"]["input"];
  capacity: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteBusCapacityInput = {
  busCount: Scalars["Int"]["input"];
  capacity: Scalars["Int"]["input"];
};

export type RouteBusCapacityListRelationFilter = {
  every?: InputMaybe<RouteBusCapacityWhereInput>;
  none?: InputMaybe<RouteBusCapacityWhereInput>;
  some?: InputMaybe<RouteBusCapacityWhereInput>;
};

export type RouteBusCapacityScalarWhereInput = {
  AND?: InputMaybe<Array<RouteBusCapacityScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteBusCapacityScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteBusCapacityScalarWhereInput>>;
  busCount?: InputMaybe<IntFilter>;
  capacity?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  routeOptimizationConfigId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteBusCapacityUpdateManyMutationInput = {
  busCount?: InputMaybe<Scalars["Int"]["input"]>;
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteBusCapacityUpdateManyWithWhereWithoutRouteOptimizationConfigInput =
  {
    data: RouteBusCapacityUpdateManyMutationInput;
    where: RouteBusCapacityScalarWhereInput;
  };

export type RouteBusCapacityUpdateManyWithoutRouteOptimizationConfigNestedInput =
  {
    connect?: InputMaybe<Array<RouteBusCapacityWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<RouteBusCapacityCreateOrConnectWithoutRouteOptimizationConfigInput>
    >;
    create?: InputMaybe<
      Array<RouteBusCapacityCreateWithoutRouteOptimizationConfigInput>
    >;
    createMany?: InputMaybe<RouteBusCapacityCreateManyRouteOptimizationConfigInputEnvelope>;
    delete?: InputMaybe<Array<RouteBusCapacityWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RouteBusCapacityScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RouteBusCapacityWhereUniqueInput>>;
    set?: InputMaybe<Array<RouteBusCapacityWhereUniqueInput>>;
    update?: InputMaybe<
      Array<RouteBusCapacityUpdateWithWhereUniqueWithoutRouteOptimizationConfigInput>
    >;
    updateMany?: InputMaybe<
      Array<RouteBusCapacityUpdateManyWithWhereWithoutRouteOptimizationConfigInput>
    >;
    upsert?: InputMaybe<
      Array<RouteBusCapacityUpsertWithWhereUniqueWithoutRouteOptimizationConfigInput>
    >;
  };

export type RouteBusCapacityUpdateWithWhereUniqueWithoutRouteOptimizationConfigInput =
  {
    data: RouteBusCapacityUpdateWithoutRouteOptimizationConfigInput;
    where: RouteBusCapacityWhereUniqueInput;
  };

export type RouteBusCapacityUpdateWithoutRouteOptimizationConfigInput = {
  busCount?: InputMaybe<Scalars["Int"]["input"]>;
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteBusCapacityUpsertWithWhereUniqueWithoutRouteOptimizationConfigInput =
  {
    create: RouteBusCapacityCreateWithoutRouteOptimizationConfigInput;
    update: RouteBusCapacityUpdateWithoutRouteOptimizationConfigInput;
    where: RouteBusCapacityWhereUniqueInput;
  };

export type RouteBusCapacityWhereInput = {
  AND?: InputMaybe<Array<RouteBusCapacityWhereInput>>;
  NOT?: InputMaybe<Array<RouteBusCapacityWhereInput>>;
  OR?: InputMaybe<Array<RouteBusCapacityWhereInput>>;
  busCount?: InputMaybe<IntFilter>;
  capacity?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  routeOptimizationConfig?: InputMaybe<RouteOptimizationConfigRelationFilter>;
  routeOptimizationConfigId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteBusCapacityWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteBusCapacityWhereInput>>;
  NOT?: InputMaybe<Array<RouteBusCapacityWhereInput>>;
  OR?: InputMaybe<Array<RouteBusCapacityWhereInput>>;
  busCount?: InputMaybe<IntFilter>;
  capacity?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeOptimizationConfig?: InputMaybe<RouteOptimizationConfigRelationFilter>;
  routeOptimizationConfigId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteCapacityExceededAlert = {
  __typename?: "RouteCapacityExceededAlert";
  alert: Alert;
  alertId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
};

export type RouteCapacityExceededAlertCreateManyRouteInput = {
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteCapacityExceededAlertCreateManyRouteInputEnvelope = {
  data: Array<RouteCapacityExceededAlertCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteCapacityExceededAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteCapacityExceededAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteCapacityExceededAlertCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteCapacityExceededAlertCreateManyRouteInputEnvelope>;
};

export type RouteCapacityExceededAlertCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<RouteCapacityExceededAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCapacityExceededAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteCapacityExceededAlertCreateWithoutAlertInput>;
};

export type RouteCapacityExceededAlertCreateOrConnectWithoutAlertInput = {
  create: RouteCapacityExceededAlertCreateWithoutAlertInput;
  where: RouteCapacityExceededAlertWhereUniqueInput;
};

export type RouteCapacityExceededAlertCreateOrConnectWithoutRouteInput = {
  create: RouteCapacityExceededAlertCreateWithoutRouteInput;
  where: RouteCapacityExceededAlertWhereUniqueInput;
};

export type RouteCapacityExceededAlertCreateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  route: RouteCreateNestedOneWithoutRouteCapacityExceededAlertsInput;
};

export type RouteCapacityExceededAlertCreateWithoutRouteInput = {
  alert: AlertCreateNestedOneWithoutRouteCapacityExceededAlertInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteCapacityExceededAlertListRelationFilter = {
  every?: InputMaybe<RouteCapacityExceededAlertWhereInput>;
  none?: InputMaybe<RouteCapacityExceededAlertWhereInput>;
  some?: InputMaybe<RouteCapacityExceededAlertWhereInput>;
};

export type RouteCapacityExceededAlertRelationFilter = {
  is?: InputMaybe<RouteCapacityExceededAlertWhereInput>;
  isNot?: InputMaybe<RouteCapacityExceededAlertWhereInput>;
};

export type RouteCapacityExceededAlertScalarWhereInput = {
  AND?: InputMaybe<Array<RouteCapacityExceededAlertScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteCapacityExceededAlertScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteCapacityExceededAlertScalarWhereInput>>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteCapacityExceededAlertUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteCapacityExceededAlertUpdateManyWithWhereWithoutRouteInput = {
  data: RouteCapacityExceededAlertUpdateManyMutationInput;
  where: RouteCapacityExceededAlertScalarWhereInput;
};

export type RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteCapacityExceededAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteCapacityExceededAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteCapacityExceededAlertCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteCapacityExceededAlertCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteCapacityExceededAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteCapacityExceededAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteCapacityExceededAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteCapacityExceededAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteCapacityExceededAlertUpdateWithWhereUniqueWithoutRouteInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteCapacityExceededAlertUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<
    Array<RouteCapacityExceededAlertUpsertWithWhereUniqueWithoutRouteInput>
  >;
};

export type RouteCapacityExceededAlertUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<RouteCapacityExceededAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCapacityExceededAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteCapacityExceededAlertCreateWithoutAlertInput>;
  delete?: InputMaybe<RouteCapacityExceededAlertWhereInput>;
  disconnect?: InputMaybe<RouteCapacityExceededAlertWhereInput>;
  update?: InputMaybe<RouteCapacityExceededAlertUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<RouteCapacityExceededAlertUpsertWithoutAlertInput>;
};

export type RouteCapacityExceededAlertUpdateToOneWithWhereWithoutAlertInput = {
  data: RouteCapacityExceededAlertUpdateWithoutAlertInput;
  where?: InputMaybe<RouteCapacityExceededAlertWhereInput>;
};

export type RouteCapacityExceededAlertUpdateWithWhereUniqueWithoutRouteInput = {
  data: RouteCapacityExceededAlertUpdateWithoutRouteInput;
  where: RouteCapacityExceededAlertWhereUniqueInput;
};

export type RouteCapacityExceededAlertUpdateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteCapacityExceededAlertsNestedInput>;
};

export type RouteCapacityExceededAlertUpdateWithoutRouteInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutRouteCapacityExceededAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteCapacityExceededAlertUpsertWithWhereUniqueWithoutRouteInput = {
  create: RouteCapacityExceededAlertCreateWithoutRouteInput;
  update: RouteCapacityExceededAlertUpdateWithoutRouteInput;
  where: RouteCapacityExceededAlertWhereUniqueInput;
};

export type RouteCapacityExceededAlertUpsertWithoutAlertInput = {
  create: RouteCapacityExceededAlertCreateWithoutAlertInput;
  update: RouteCapacityExceededAlertUpdateWithoutAlertInput;
  where?: InputMaybe<RouteCapacityExceededAlertWhereInput>;
};

export type RouteCapacityExceededAlertWhereInput = {
  AND?: InputMaybe<Array<RouteCapacityExceededAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteCapacityExceededAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteCapacityExceededAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteCapacityExceededAlertWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteCapacityExceededAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteCapacityExceededAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteCapacityExceededAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteConfiguration = {
  __typename?: "RouteConfiguration";
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  routeDistanceThreshold: Scalars["Float"]["output"];
  routeTimeThreshold: Scalars["Float"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type RouteConfigurationCreateManyCountryInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeDistanceThreshold: Scalars["Float"]["input"];
  routeTimeThreshold: Scalars["Float"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteConfigurationCreateManyCountryInputEnvelope = {
  data: Array<RouteConfigurationCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteConfigurationCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<RouteConfigurationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteConfigurationCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<RouteConfigurationCreateWithoutCountryInput>>;
  createMany?: InputMaybe<RouteConfigurationCreateManyCountryInputEnvelope>;
};

export type RouteConfigurationCreateOrConnectWithoutCountryInput = {
  create: RouteConfigurationCreateWithoutCountryInput;
  where: RouteConfigurationWhereUniqueInput;
};

export type RouteConfigurationCreateWithoutCountryInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeDistanceThreshold: Scalars["Float"]["input"];
  routeTimeThreshold: Scalars["Float"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteConfigurationListRelationFilter = {
  every?: InputMaybe<RouteConfigurationWhereInput>;
  none?: InputMaybe<RouteConfigurationWhereInput>;
  some?: InputMaybe<RouteConfigurationWhereInput>;
};

export type RouteConfigurationScalarWhereInput = {
  AND?: InputMaybe<Array<RouteConfigurationScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteConfigurationScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteConfigurationScalarWhereInput>>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  routeDistanceThreshold?: InputMaybe<FloatFilter>;
  routeTimeThreshold?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteConfigurationUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeDistanceThreshold?: InputMaybe<Scalars["Float"]["input"]>;
  routeTimeThreshold?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteConfigurationUpdateManyWithWhereWithoutCountryInput = {
  data: RouteConfigurationUpdateManyMutationInput;
  where: RouteConfigurationScalarWhereInput;
};

export type RouteConfigurationUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<RouteConfigurationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteConfigurationCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<RouteConfigurationCreateWithoutCountryInput>>;
  createMany?: InputMaybe<RouteConfigurationCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<RouteConfigurationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteConfigurationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteConfigurationWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteConfigurationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteConfigurationUpdateWithWhereUniqueWithoutCountryInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteConfigurationUpdateManyWithWhereWithoutCountryInput>
  >;
  upsert?: InputMaybe<
    Array<RouteConfigurationUpsertWithWhereUniqueWithoutCountryInput>
  >;
};

export type RouteConfigurationUpdateWithWhereUniqueWithoutCountryInput = {
  data: RouteConfigurationUpdateWithoutCountryInput;
  where: RouteConfigurationWhereUniqueInput;
};

export type RouteConfigurationUpdateWithoutCountryInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeDistanceThreshold?: InputMaybe<Scalars["Float"]["input"]>;
  routeTimeThreshold?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteConfigurationUpsertWithWhereUniqueWithoutCountryInput = {
  create: RouteConfigurationCreateWithoutCountryInput;
  update: RouteConfigurationUpdateWithoutCountryInput;
  where: RouteConfigurationWhereUniqueInput;
};

export type RouteConfigurationWhereInput = {
  AND?: InputMaybe<Array<RouteConfigurationWhereInput>>;
  NOT?: InputMaybe<Array<RouteConfigurationWhereInput>>;
  OR?: InputMaybe<Array<RouteConfigurationWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  routeDistanceThreshold?: InputMaybe<FloatFilter>;
  routeTimeThreshold?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteConfigurationWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteConfigurationWhereInput>>;
  NOT?: InputMaybe<Array<RouteConfigurationWhereInput>>;
  OR?: InputMaybe<Array<RouteConfigurationWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeDistanceThreshold?: InputMaybe<FloatFilter>;
  routeTimeThreshold?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteCount = {
  __typename?: "RouteCount";
  agents: Scalars["Int"]["output"];
  busAttendants: Scalars["Int"]["output"];
  drivers: Scalars["Int"]["output"];
  leaveRequests: Scalars["Int"]["output"];
  routeBusAttendantVacantAlerts: Scalars["Int"]["output"];
  routeCapacityExceededAlerts: Scalars["Int"]["output"];
  routeDateAlerts: Scalars["Int"]["output"];
  routeDriverVacantAlerts: Scalars["Int"]["output"];
  routeOptimizationConfigs: Scalars["Int"]["output"];
  routeStops: Scalars["Int"]["output"];
  routeTimeLimitExceededAlerts: Scalars["Int"]["output"];
  routeVehicleVacantAlerts: Scalars["Int"]["output"];
  trips: Scalars["Int"]["output"];
  vehicles: Scalars["Int"]["output"];
};

export type RouteCreateInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateManyRouteGroupInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteCreateManyRouteGroupInputEnvelope = {
  data: Array<RouteCreateManyRouteGroupInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteCreateManyServiceProviderInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeGroupId?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteCreateManyServiceProviderInputEnvelope = {
  data: Array<RouteCreateManyServiceProviderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteCreateNestedManyWithoutRouteGroupInput = {
  connect?: InputMaybe<Array<RouteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteCreateOrConnectWithoutRouteGroupInput>
  >;
  create?: InputMaybe<Array<RouteCreateWithoutRouteGroupInput>>;
  createMany?: InputMaybe<RouteCreateManyRouteGroupInputEnvelope>;
};

export type RouteCreateNestedManyWithoutServiceProviderInput = {
  connect?: InputMaybe<Array<RouteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<RouteCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<RouteCreateManyServiceProviderInputEnvelope>;
};

export type RouteCreateNestedOneWithoutAgentsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutAgentsInput>;
  create?: InputMaybe<RouteCreateWithoutAgentsInput>;
};

export type RouteCreateNestedOneWithoutBusAttendantsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutBusAttendantsInput>;
  create?: InputMaybe<RouteCreateWithoutBusAttendantsInput>;
};

export type RouteCreateNestedOneWithoutDriversInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutDriversInput>;
  create?: InputMaybe<RouteCreateWithoutDriversInput>;
};

export type RouteCreateNestedOneWithoutLeaveRequestsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutLeaveRequestsInput>;
  create?: InputMaybe<RouteCreateWithoutLeaveRequestsInput>;
};

export type RouteCreateNestedOneWithoutRouteBusAttendantVacantAlertsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteBusAttendantVacantAlertsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteBusAttendantVacantAlertsInput>;
};

export type RouteCreateNestedOneWithoutRouteCapacityExceededAlertsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteCapacityExceededAlertsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteCapacityExceededAlertsInput>;
};

export type RouteCreateNestedOneWithoutRouteDateAlertsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteDateAlertsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteDateAlertsInput>;
};

export type RouteCreateNestedOneWithoutRouteDriverVacantAlertsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteDriverVacantAlertsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteDriverVacantAlertsInput>;
};

export type RouteCreateNestedOneWithoutRouteOptimizationConfigsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteOptimizationConfigsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteOptimizationConfigsInput>;
};

export type RouteCreateNestedOneWithoutRouteStopsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteStopsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteStopsInput>;
};

export type RouteCreateNestedOneWithoutRouteTimeLimitExceededAlertsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteTimeLimitExceededAlertsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteTimeLimitExceededAlertsInput>;
};

export type RouteCreateNestedOneWithoutRouteVehicleVacantAlertsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteVehicleVacantAlertsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteVehicleVacantAlertsInput>;
};

export type RouteCreateNestedOneWithoutTripsInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutTripsInput>;
  create?: InputMaybe<RouteCreateWithoutTripsInput>;
};

export type RouteCreateNestedOneWithoutVehiclesInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<RouteCreateWithoutVehiclesInput>;
};

export type RouteCreateOrConnectWithoutAgentsInput = {
  create: RouteCreateWithoutAgentsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutBusAttendantsInput = {
  create: RouteCreateWithoutBusAttendantsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutDriversInput = {
  create: RouteCreateWithoutDriversInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutLeaveRequestsInput = {
  create: RouteCreateWithoutLeaveRequestsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutRouteBusAttendantVacantAlertsInput = {
  create: RouteCreateWithoutRouteBusAttendantVacantAlertsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutRouteCapacityExceededAlertsInput = {
  create: RouteCreateWithoutRouteCapacityExceededAlertsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutRouteDateAlertsInput = {
  create: RouteCreateWithoutRouteDateAlertsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutRouteDriverVacantAlertsInput = {
  create: RouteCreateWithoutRouteDriverVacantAlertsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutRouteGroupInput = {
  create: RouteCreateWithoutRouteGroupInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutRouteOptimizationConfigsInput = {
  create: RouteCreateWithoutRouteOptimizationConfigsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutRouteStopsInput = {
  create: RouteCreateWithoutRouteStopsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutRouteTimeLimitExceededAlertsInput = {
  create: RouteCreateWithoutRouteTimeLimitExceededAlertsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutRouteVehicleVacantAlertsInput = {
  create: RouteCreateWithoutRouteVehicleVacantAlertsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutServiceProviderInput = {
  create: RouteCreateWithoutServiceProviderInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutTripsInput = {
  create: RouteCreateWithoutTripsInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateOrConnectWithoutVehiclesInput = {
  create: RouteCreateWithoutVehiclesInput;
  where: RouteWhereUniqueInput;
};

export type RouteCreateWithoutAgentsInput = {
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutBusAttendantsInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutDriversInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutLeaveRequestsInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutRouteBusAttendantVacantAlertsInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutRouteCapacityExceededAlertsInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutRouteDateAlertsInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutRouteDriverVacantAlertsInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutRouteGroupInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutRouteOptimizationConfigsInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutRouteStopsInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutRouteTimeLimitExceededAlertsInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutRouteVehicleVacantAlertsInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutServiceProviderInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutTripsInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleCreateNestedManyWithoutRouteInput>;
};

export type RouteCreateWithoutVehiclesInput = {
  agents?: InputMaybe<RouteAgentCreateNestedManyWithoutRouteInput>;
  busAttendants?: InputMaybe<RouteBusAttendantCreateNestedManyWithoutRouteInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality: RouteDirectionality;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverCreateNestedManyWithoutRouteInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutRouteInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertCreateNestedManyWithoutRouteInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertCreateNestedManyWithoutRouteInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertCreateNestedManyWithoutRouteInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertCreateNestedManyWithoutRouteInput>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRoutesInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteInput>;
  routeStops?: InputMaybe<RouteStopCreateNestedManyWithoutRouteInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutRoutesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripCreateNestedManyWithoutRouteInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteDateAlert = {
  __typename?: "RouteDateAlert";
  alert: Alert;
  alertId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
};

export type RouteDateAlertCreateManyRouteInput = {
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteDateAlertCreateManyRouteInputEnvelope = {
  data: Array<RouteDateAlertCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteDateAlertCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteDateAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteDateAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteDateAlertCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteDateAlertCreateManyRouteInputEnvelope>;
};

export type RouteDateAlertCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<RouteDateAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteDateAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteDateAlertCreateWithoutAlertInput>;
};

export type RouteDateAlertCreateOrConnectWithoutAlertInput = {
  create: RouteDateAlertCreateWithoutAlertInput;
  where: RouteDateAlertWhereUniqueInput;
};

export type RouteDateAlertCreateOrConnectWithoutRouteInput = {
  create: RouteDateAlertCreateWithoutRouteInput;
  where: RouteDateAlertWhereUniqueInput;
};

export type RouteDateAlertCreateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  route: RouteCreateNestedOneWithoutRouteDateAlertsInput;
};

export type RouteDateAlertCreateWithoutRouteInput = {
  alert: AlertCreateNestedOneWithoutRouteDateAlertInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteDateAlertListRelationFilter = {
  every?: InputMaybe<RouteDateAlertWhereInput>;
  none?: InputMaybe<RouteDateAlertWhereInput>;
  some?: InputMaybe<RouteDateAlertWhereInput>;
};

export type RouteDateAlertRelationFilter = {
  is?: InputMaybe<RouteDateAlertWhereInput>;
  isNot?: InputMaybe<RouteDateAlertWhereInput>;
};

export type RouteDateAlertScalarWhereInput = {
  AND?: InputMaybe<Array<RouteDateAlertScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteDateAlertScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteDateAlertScalarWhereInput>>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteDateAlertUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteDateAlertUpdateManyWithWhereWithoutRouteInput = {
  data: RouteDateAlertUpdateManyMutationInput;
  where: RouteDateAlertScalarWhereInput;
};

export type RouteDateAlertUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteDateAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteDateAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteDateAlertCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteDateAlertCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteDateAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteDateAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteDateAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteDateAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteDateAlertUpdateWithWhereUniqueWithoutRouteInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteDateAlertUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<
    Array<RouteDateAlertUpsertWithWhereUniqueWithoutRouteInput>
  >;
};

export type RouteDateAlertUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<RouteDateAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteDateAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteDateAlertCreateWithoutAlertInput>;
  delete?: InputMaybe<RouteDateAlertWhereInput>;
  disconnect?: InputMaybe<RouteDateAlertWhereInput>;
  update?: InputMaybe<RouteDateAlertUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<RouteDateAlertUpsertWithoutAlertInput>;
};

export type RouteDateAlertUpdateToOneWithWhereWithoutAlertInput = {
  data: RouteDateAlertUpdateWithoutAlertInput;
  where?: InputMaybe<RouteDateAlertWhereInput>;
};

export type RouteDateAlertUpdateWithWhereUniqueWithoutRouteInput = {
  data: RouteDateAlertUpdateWithoutRouteInput;
  where: RouteDateAlertWhereUniqueInput;
};

export type RouteDateAlertUpdateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteDateAlertsNestedInput>;
};

export type RouteDateAlertUpdateWithoutRouteInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutRouteDateAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteDateAlertUpsertWithWhereUniqueWithoutRouteInput = {
  create: RouteDateAlertCreateWithoutRouteInput;
  update: RouteDateAlertUpdateWithoutRouteInput;
  where: RouteDateAlertWhereUniqueInput;
};

export type RouteDateAlertUpsertWithoutAlertInput = {
  create: RouteDateAlertCreateWithoutAlertInput;
  update: RouteDateAlertUpdateWithoutAlertInput;
  where?: InputMaybe<RouteDateAlertWhereInput>;
};

export type RouteDateAlertWhereInput = {
  AND?: InputMaybe<Array<RouteDateAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteDateAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteDateAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteDateAlertWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteDateAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteDateAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteDateAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export enum RouteDirectionality {
  TO_HOME = "TO_HOME",
  TO_SCHOOL = "TO_SCHOOL",
}

export type RouteDriver = {
  __typename?: "RouteDriver";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  driver: Driver;
  driverId: Scalars["String"]["output"];
  fromDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  precedence: Scalars["Int"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
  toDate?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type RouteDriverCreateManyDriverInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  routeId: Scalars["String"]["input"];
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteDriverCreateManyDriverInputEnvelope = {
  data: Array<RouteDriverCreateManyDriverInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteDriverCreateManyRouteInput = {
  driverId: Scalars["String"]["input"];
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteDriverCreateManyRouteInputEnvelope = {
  data: Array<RouteDriverCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteDriverCreateNestedManyWithoutDriverInput = {
  connect?: InputMaybe<Array<RouteDriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteDriverCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<RouteDriverCreateWithoutDriverInput>>;
  createMany?: InputMaybe<RouteDriverCreateManyDriverInputEnvelope>;
};

export type RouteDriverCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteDriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteDriverCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteDriverCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteDriverCreateManyRouteInputEnvelope>;
};

export type RouteDriverCreateOrConnectWithoutDriverInput = {
  create: RouteDriverCreateWithoutDriverInput;
  where: RouteDriverWhereUniqueInput;
};

export type RouteDriverCreateOrConnectWithoutRouteInput = {
  create: RouteDriverCreateWithoutRouteInput;
  where: RouteDriverWhereUniqueInput;
};

export type RouteDriverCreateWithoutDriverInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  route: RouteCreateNestedOneWithoutDriversInput;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteDriverCreateWithoutRouteInput = {
  driver: DriverCreateNestedOneWithoutRoutesInput;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteDriverListRelationFilter = {
  every?: InputMaybe<RouteDriverWhereInput>;
  none?: InputMaybe<RouteDriverWhereInput>;
  some?: InputMaybe<RouteDriverWhereInput>;
};

export type RouteDriverScalarWhereInput = {
  AND?: InputMaybe<Array<RouteDriverScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteDriverScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteDriverScalarWhereInput>>;
  driverId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteDriverUpdateManyMutationInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteDriverUpdateManyWithWhereWithoutDriverInput = {
  data: RouteDriverUpdateManyMutationInput;
  where: RouteDriverScalarWhereInput;
};

export type RouteDriverUpdateManyWithWhereWithoutRouteInput = {
  data: RouteDriverUpdateManyMutationInput;
  where: RouteDriverScalarWhereInput;
};

export type RouteDriverUpdateManyWithoutDriverNestedInput = {
  connect?: InputMaybe<Array<RouteDriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteDriverCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<RouteDriverCreateWithoutDriverInput>>;
  createMany?: InputMaybe<RouteDriverCreateManyDriverInputEnvelope>;
  delete?: InputMaybe<Array<RouteDriverWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteDriverScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteDriverWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteDriverWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteDriverUpdateWithWhereUniqueWithoutDriverInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteDriverUpdateManyWithWhereWithoutDriverInput>
  >;
  upsert?: InputMaybe<
    Array<RouteDriverUpsertWithWhereUniqueWithoutDriverInput>
  >;
};

export type RouteDriverUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteDriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteDriverCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteDriverCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteDriverCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteDriverWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteDriverScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteDriverWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteDriverWhereUniqueInput>>;
  update?: InputMaybe<Array<RouteDriverUpdateWithWhereUniqueWithoutRouteInput>>;
  updateMany?: InputMaybe<
    Array<RouteDriverUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<Array<RouteDriverUpsertWithWhereUniqueWithoutRouteInput>>;
};

export type RouteDriverUpdateWithWhereUniqueWithoutDriverInput = {
  data: RouteDriverUpdateWithoutDriverInput;
  where: RouteDriverWhereUniqueInput;
};

export type RouteDriverUpdateWithWhereUniqueWithoutRouteInput = {
  data: RouteDriverUpdateWithoutRouteInput;
  where: RouteDriverWhereUniqueInput;
};

export type RouteDriverUpdateWithoutDriverInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutDriversNestedInput>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteDriverUpdateWithoutRouteInput = {
  driver?: InputMaybe<DriverUpdateOneRequiredWithoutRoutesNestedInput>;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteDriverUpsertWithWhereUniqueWithoutDriverInput = {
  create: RouteDriverCreateWithoutDriverInput;
  update: RouteDriverUpdateWithoutDriverInput;
  where: RouteDriverWhereUniqueInput;
};

export type RouteDriverUpsertWithWhereUniqueWithoutRouteInput = {
  create: RouteDriverCreateWithoutRouteInput;
  update: RouteDriverUpdateWithoutRouteInput;
  where: RouteDriverWhereUniqueInput;
};

export type RouteDriverVacantAlert = {
  __typename?: "RouteDriverVacantAlert";
  alert: Alert;
  alertId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
};

export type RouteDriverVacantAlertCreateManyDriverInput = {
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeId: Scalars["String"]["input"];
};

export type RouteDriverVacantAlertCreateManyDriverInputEnvelope = {
  data: Array<RouteDriverVacantAlertCreateManyDriverInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteDriverVacantAlertCreateManyRouteInput = {
  alertId: Scalars["String"]["input"];
  driverId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteDriverVacantAlertCreateManyRouteInputEnvelope = {
  data: Array<RouteDriverVacantAlertCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteDriverVacantAlertCreateNestedManyWithoutDriverInput = {
  connect?: InputMaybe<Array<RouteDriverVacantAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteDriverVacantAlertCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<RouteDriverVacantAlertCreateWithoutDriverInput>>;
  createMany?: InputMaybe<RouteDriverVacantAlertCreateManyDriverInputEnvelope>;
};

export type RouteDriverVacantAlertCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteDriverVacantAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteDriverVacantAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteDriverVacantAlertCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteDriverVacantAlertCreateManyRouteInputEnvelope>;
};

export type RouteDriverVacantAlertCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<RouteDriverVacantAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteDriverVacantAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteDriverVacantAlertCreateWithoutAlertInput>;
};

export type RouteDriverVacantAlertCreateOrConnectWithoutAlertInput = {
  create: RouteDriverVacantAlertCreateWithoutAlertInput;
  where: RouteDriverVacantAlertWhereUniqueInput;
};

export type RouteDriverVacantAlertCreateOrConnectWithoutDriverInput = {
  create: RouteDriverVacantAlertCreateWithoutDriverInput;
  where: RouteDriverVacantAlertWhereUniqueInput;
};

export type RouteDriverVacantAlertCreateOrConnectWithoutRouteInput = {
  create: RouteDriverVacantAlertCreateWithoutRouteInput;
  where: RouteDriverVacantAlertWhereUniqueInput;
};

export type RouteDriverVacantAlertCreateWithoutAlertInput = {
  driver?: InputMaybe<DriverCreateNestedOneWithoutRouteDriverVacantAlertsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route: RouteCreateNestedOneWithoutRouteDriverVacantAlertsInput;
};

export type RouteDriverVacantAlertCreateWithoutDriverInput = {
  alert: AlertCreateNestedOneWithoutRouteDriverVacantAlertInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route: RouteCreateNestedOneWithoutRouteDriverVacantAlertsInput;
};

export type RouteDriverVacantAlertCreateWithoutRouteInput = {
  alert: AlertCreateNestedOneWithoutRouteDriverVacantAlertInput;
  driver?: InputMaybe<DriverCreateNestedOneWithoutRouteDriverVacantAlertsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteDriverVacantAlertListRelationFilter = {
  every?: InputMaybe<RouteDriverVacantAlertWhereInput>;
  none?: InputMaybe<RouteDriverVacantAlertWhereInput>;
  some?: InputMaybe<RouteDriverVacantAlertWhereInput>;
};

export type RouteDriverVacantAlertRelationFilter = {
  is?: InputMaybe<RouteDriverVacantAlertWhereInput>;
  isNot?: InputMaybe<RouteDriverVacantAlertWhereInput>;
};

export type RouteDriverVacantAlertScalarWhereInput = {
  AND?: InputMaybe<Array<RouteDriverVacantAlertScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteDriverVacantAlertScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteDriverVacantAlertScalarWhereInput>>;
  alertId?: InputMaybe<StringFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteDriverVacantAlertUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteDriverVacantAlertUpdateManyWithWhereWithoutDriverInput = {
  data: RouteDriverVacantAlertUpdateManyMutationInput;
  where: RouteDriverVacantAlertScalarWhereInput;
};

export type RouteDriverVacantAlertUpdateManyWithWhereWithoutRouteInput = {
  data: RouteDriverVacantAlertUpdateManyMutationInput;
  where: RouteDriverVacantAlertScalarWhereInput;
};

export type RouteDriverVacantAlertUpdateManyWithoutDriverNestedInput = {
  connect?: InputMaybe<Array<RouteDriverVacantAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteDriverVacantAlertCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<RouteDriverVacantAlertCreateWithoutDriverInput>>;
  createMany?: InputMaybe<RouteDriverVacantAlertCreateManyDriverInputEnvelope>;
  delete?: InputMaybe<Array<RouteDriverVacantAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteDriverVacantAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteDriverVacantAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteDriverVacantAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteDriverVacantAlertUpdateWithWhereUniqueWithoutDriverInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteDriverVacantAlertUpdateManyWithWhereWithoutDriverInput>
  >;
  upsert?: InputMaybe<
    Array<RouteDriverVacantAlertUpsertWithWhereUniqueWithoutDriverInput>
  >;
};

export type RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteDriverVacantAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteDriverVacantAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteDriverVacantAlertCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteDriverVacantAlertCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteDriverVacantAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteDriverVacantAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteDriverVacantAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteDriverVacantAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteDriverVacantAlertUpdateWithWhereUniqueWithoutRouteInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteDriverVacantAlertUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<
    Array<RouteDriverVacantAlertUpsertWithWhereUniqueWithoutRouteInput>
  >;
};

export type RouteDriverVacantAlertUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<RouteDriverVacantAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteDriverVacantAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteDriverVacantAlertCreateWithoutAlertInput>;
  delete?: InputMaybe<RouteDriverVacantAlertWhereInput>;
  disconnect?: InputMaybe<RouteDriverVacantAlertWhereInput>;
  update?: InputMaybe<RouteDriverVacantAlertUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<RouteDriverVacantAlertUpsertWithoutAlertInput>;
};

export type RouteDriverVacantAlertUpdateToOneWithWhereWithoutAlertInput = {
  data: RouteDriverVacantAlertUpdateWithoutAlertInput;
  where?: InputMaybe<RouteDriverVacantAlertWhereInput>;
};

export type RouteDriverVacantAlertUpdateWithWhereUniqueWithoutDriverInput = {
  data: RouteDriverVacantAlertUpdateWithoutDriverInput;
  where: RouteDriverVacantAlertWhereUniqueInput;
};

export type RouteDriverVacantAlertUpdateWithWhereUniqueWithoutRouteInput = {
  data: RouteDriverVacantAlertUpdateWithoutRouteInput;
  where: RouteDriverVacantAlertWhereUniqueInput;
};

export type RouteDriverVacantAlertUpdateWithoutAlertInput = {
  driver?: InputMaybe<DriverUpdateOneWithoutRouteDriverVacantAlertsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteDriverVacantAlertsNestedInput>;
};

export type RouteDriverVacantAlertUpdateWithoutDriverInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutRouteDriverVacantAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteDriverVacantAlertsNestedInput>;
};

export type RouteDriverVacantAlertUpdateWithoutRouteInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutRouteDriverVacantAlertNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutRouteDriverVacantAlertsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteDriverVacantAlertUpsertWithWhereUniqueWithoutDriverInput = {
  create: RouteDriverVacantAlertCreateWithoutDriverInput;
  update: RouteDriverVacantAlertUpdateWithoutDriverInput;
  where: RouteDriverVacantAlertWhereUniqueInput;
};

export type RouteDriverVacantAlertUpsertWithWhereUniqueWithoutRouteInput = {
  create: RouteDriverVacantAlertCreateWithoutRouteInput;
  update: RouteDriverVacantAlertUpdateWithoutRouteInput;
  where: RouteDriverVacantAlertWhereUniqueInput;
};

export type RouteDriverVacantAlertUpsertWithoutAlertInput = {
  create: RouteDriverVacantAlertCreateWithoutAlertInput;
  update: RouteDriverVacantAlertUpdateWithoutAlertInput;
  where?: InputMaybe<RouteDriverVacantAlertWhereInput>;
};

export type RouteDriverVacantAlertWhereInput = {
  AND?: InputMaybe<Array<RouteDriverVacantAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteDriverVacantAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteDriverVacantAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteDriverVacantAlertWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteDriverVacantAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteDriverVacantAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteDriverVacantAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteDriverWhereInput = {
  AND?: InputMaybe<Array<RouteDriverWhereInput>>;
  NOT?: InputMaybe<Array<RouteDriverWhereInput>>;
  OR?: InputMaybe<Array<RouteDriverWhereInput>>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteDriverWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteDriverWhereInput>>;
  NOT?: InputMaybe<Array<RouteDriverWhereInput>>;
  OR?: InputMaybe<Array<RouteDriverWhereInput>>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteForAdhocNotification = {
  __typename?: "RouteForAdhocNotification";
  code?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
};

export type RouteForBusAttendant = {
  __typename?: "RouteForBusAttendant";
  code?: Maybe<Scalars["String"]["output"]>;
  directionality: RouteDirectionality;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  plannedEndTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedSchoolStopDuration: Scalars["Int"]["output"];
  plannedStartTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedStudentStopDuration: Scalars["Int"]["output"];
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status: RouteStatus;
};

export type RouteGroup = {
  __typename?: "RouteGroup";
  _count: RouteGroupCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  displayName?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  endTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isAutoActive: Scalars["Boolean"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  routeOptimizationConfig?: Maybe<Array<RouteOptimizationConfig>>;
  routes?: Maybe<Array<Route>>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  startTime?: Maybe<Scalars["DateTime"]["output"]>;
  status: RouteGroupStatus;
  updatedAt: Scalars["DateTime"]["output"];
  utilizationTarget?: Maybe<Scalars["Int"]["output"]>;
};

export type RouteGroupCount = {
  __typename?: "RouteGroupCount";
  routeOptimizationConfig: Scalars["Int"]["output"];
  routes: Scalars["Int"]["output"];
};

export type RouteGroupCreateInput = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  routeOptimizationConfig?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteGroupInput>;
  routes?: InputMaybe<RouteCreateNestedManyWithoutRouteGroupInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteGroupStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  utilizationTarget?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RouteGroupCreateNestedOneWithoutRouteOptimizationConfigInput = {
  connect?: InputMaybe<RouteGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteGroupCreateOrConnectWithoutRouteOptimizationConfigInput>;
  create?: InputMaybe<RouteGroupCreateWithoutRouteOptimizationConfigInput>;
};

export type RouteGroupCreateNestedOneWithoutRoutesInput = {
  connect?: InputMaybe<RouteGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteGroupCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<RouteGroupCreateWithoutRoutesInput>;
};

export type RouteGroupCreateOrConnectWithoutRouteOptimizationConfigInput = {
  create: RouteGroupCreateWithoutRouteOptimizationConfigInput;
  where: RouteGroupWhereUniqueInput;
};

export type RouteGroupCreateOrConnectWithoutRoutesInput = {
  create: RouteGroupCreateWithoutRoutesInput;
  where: RouteGroupWhereUniqueInput;
};

export type RouteGroupCreateWithoutRouteOptimizationConfigInput = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  routes?: InputMaybe<RouteCreateNestedManyWithoutRouteGroupInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteGroupStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  utilizationTarget?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RouteGroupCreateWithoutRoutesInput = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  routeOptimizationConfig?: InputMaybe<RouteOptimizationConfigCreateNestedManyWithoutRouteGroupInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteGroupStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  utilizationTarget?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RouteGroupRelationFilter = {
  is?: InputMaybe<RouteGroupWhereInput>;
  isNot?: InputMaybe<RouteGroupWhereInput>;
};

export enum RouteGroupStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PLANNING = "PLANNING",
}

export type RouteGroupUpdateInput = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  routeOptimizationConfig?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteGroupNestedInput>;
  routes?: InputMaybe<RouteUpdateManyWithoutRouteGroupNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteGroupStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  utilizationTarget?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RouteGroupUpdateOneWithoutRouteOptimizationConfigNestedInput = {
  connect?: InputMaybe<RouteGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteGroupCreateOrConnectWithoutRouteOptimizationConfigInput>;
  create?: InputMaybe<RouteGroupCreateWithoutRouteOptimizationConfigInput>;
  delete?: InputMaybe<RouteGroupWhereInput>;
  disconnect?: InputMaybe<RouteGroupWhereInput>;
  update?: InputMaybe<RouteGroupUpdateToOneWithWhereWithoutRouteOptimizationConfigInput>;
  upsert?: InputMaybe<RouteGroupUpsertWithoutRouteOptimizationConfigInput>;
};

export type RouteGroupUpdateOneWithoutRoutesNestedInput = {
  connect?: InputMaybe<RouteGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteGroupCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<RouteGroupCreateWithoutRoutesInput>;
  delete?: InputMaybe<RouteGroupWhereInput>;
  disconnect?: InputMaybe<RouteGroupWhereInput>;
  update?: InputMaybe<RouteGroupUpdateToOneWithWhereWithoutRoutesInput>;
  upsert?: InputMaybe<RouteGroupUpsertWithoutRoutesInput>;
};

export type RouteGroupUpdateToOneWithWhereWithoutRouteOptimizationConfigInput =
  {
    data: RouteGroupUpdateWithoutRouteOptimizationConfigInput;
    where?: InputMaybe<RouteGroupWhereInput>;
  };

export type RouteGroupUpdateToOneWithWhereWithoutRoutesInput = {
  data: RouteGroupUpdateWithoutRoutesInput;
  where?: InputMaybe<RouteGroupWhereInput>;
};

export type RouteGroupUpdateWithoutRouteOptimizationConfigInput = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  routes?: InputMaybe<RouteUpdateManyWithoutRouteGroupNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteGroupStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  utilizationTarget?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RouteGroupUpdateWithoutRoutesInput = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  routeOptimizationConfig?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteGroupNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteGroupStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  utilizationTarget?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RouteGroupUpsertInput = {
  create?: InputMaybe<RouteGroupCreateInput>;
  update?: InputMaybe<RouteGroupUpdateInput>;
  where?: InputMaybe<RouteGroupWhereUniqueInput>;
};

export type RouteGroupUpsertWithoutRouteOptimizationConfigInput = {
  create: RouteGroupCreateWithoutRouteOptimizationConfigInput;
  update: RouteGroupUpdateWithoutRouteOptimizationConfigInput;
  where?: InputMaybe<RouteGroupWhereInput>;
};

export type RouteGroupUpsertWithoutRoutesInput = {
  create: RouteGroupCreateWithoutRoutesInput;
  update: RouteGroupUpdateWithoutRoutesInput;
  where?: InputMaybe<RouteGroupWhereInput>;
};

export type RouteGroupWhereInput = {
  AND?: InputMaybe<Array<RouteGroupWhereInput>>;
  NOT?: InputMaybe<Array<RouteGroupWhereInput>>;
  OR?: InputMaybe<Array<RouteGroupWhereInput>>;
  displayName?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isAutoActive?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  routeOptimizationConfig?: InputMaybe<RouteOptimizationConfigListRelationFilter>;
  routes?: InputMaybe<RouteListRelationFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumRouteGroupStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  utilizationTarget?: InputMaybe<IntFilter>;
};

export type RouteGroupWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteGroupWhereInput>>;
  NOT?: InputMaybe<Array<RouteGroupWhereInput>>;
  OR?: InputMaybe<Array<RouteGroupWhereInput>>;
  displayName?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  routeOptimizationConfig?: InputMaybe<RouteOptimizationConfigListRelationFilter>;
  routes?: InputMaybe<RouteListRelationFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumRouteGroupStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  utilizationTarget?: InputMaybe<IntFilter>;
};

export type RouteListRelationFilter = {
  every?: InputMaybe<RouteWhereInput>;
  none?: InputMaybe<RouteWhereInput>;
  some?: InputMaybe<RouteWhereInput>;
};

export type RouteOptimizationConfig = {
  __typename?: "RouteOptimizationConfig";
  _count: RouteOptimizationConfigCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busCapacities?: Maybe<Array<RouteBusCapacity>>;
  createdAt: Scalars["DateTime"]["output"];
  displayName?: Maybe<Scalars["String"]["output"]>;
  driverPenaltyFactor?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["String"]["output"];
  isCurrent: Scalars["Boolean"]["output"];
  maxOptimizerRunDuration?: Maybe<Scalars["Float"]["output"]>;
  maxSchoolDropHaste?: Maybe<Scalars["Int"]["output"]>;
  maxSchoolPickDelay?: Maybe<Scalars["Int"]["output"]>;
  minimumVehicleTime?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  route: Route;
  routeGroup?: Maybe<RouteGroup>;
  routeGroupId?: Maybe<Scalars["String"]["output"]>;
  routeId: Scalars["String"]["output"];
  schoolToHomeStopDuration?: Maybe<Scalars["Int"]["output"]>;
  schoolToSchoolStopDuration?: Maybe<Scalars["Int"]["output"]>;
  studentToHomeStopDuration?: Maybe<Scalars["Int"]["output"]>;
  studentToSchoolStopDuration?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  zones?: Maybe<Array<RouteZoneSla>>;
};

export type RouteOptimizationConfigCount = {
  __typename?: "RouteOptimizationConfigCount";
  busCapacities: Scalars["Int"]["output"];
  zones: Scalars["Int"]["output"];
};

export type RouteOptimizationConfigCreateManyRouteGroupInput = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  driverPenaltyFactor?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxOptimizerRunDuration?: InputMaybe<Scalars["Float"]["input"]>;
  maxSchoolDropHaste?: InputMaybe<Scalars["Int"]["input"]>;
  maxSchoolPickDelay?: InputMaybe<Scalars["Int"]["input"]>;
  minimumVehicleTime?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeId: Scalars["String"]["input"];
  schoolToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  schoolToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteOptimizationConfigCreateManyRouteGroupInputEnvelope = {
  data: Array<RouteOptimizationConfigCreateManyRouteGroupInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteOptimizationConfigCreateManyRouteInput = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  driverPenaltyFactor?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxOptimizerRunDuration?: InputMaybe<Scalars["Float"]["input"]>;
  maxSchoolDropHaste?: InputMaybe<Scalars["Int"]["input"]>;
  maxSchoolPickDelay?: InputMaybe<Scalars["Int"]["input"]>;
  minimumVehicleTime?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeGroupId?: InputMaybe<Scalars["String"]["input"]>;
  schoolToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  schoolToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteOptimizationConfigCreateManyRouteInputEnvelope = {
  data: Array<RouteOptimizationConfigCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteOptimizationConfigCreateNestedManyWithoutRouteGroupInput = {
  connect?: InputMaybe<Array<RouteOptimizationConfigWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteOptimizationConfigCreateOrConnectWithoutRouteGroupInput>
  >;
  create?: InputMaybe<
    Array<RouteOptimizationConfigCreateWithoutRouteGroupInput>
  >;
  createMany?: InputMaybe<RouteOptimizationConfigCreateManyRouteGroupInputEnvelope>;
};

export type RouteOptimizationConfigCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteOptimizationConfigWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteOptimizationConfigCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteOptimizationConfigCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteOptimizationConfigCreateManyRouteInputEnvelope>;
};

export type RouteOptimizationConfigCreateOrConnectWithoutRouteGroupInput = {
  create: RouteOptimizationConfigCreateWithoutRouteGroupInput;
  where: RouteOptimizationConfigWhereUniqueInput;
};

export type RouteOptimizationConfigCreateOrConnectWithoutRouteInput = {
  create: RouteOptimizationConfigCreateWithoutRouteInput;
  where: RouteOptimizationConfigWhereUniqueInput;
};

export type RouteOptimizationConfigCreateWithoutRouteGroupInput = {
  busCapacities?: InputMaybe<RouteBusCapacityCreateNestedManyWithoutRouteOptimizationConfigInput>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  driverPenaltyFactor?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxOptimizerRunDuration?: InputMaybe<Scalars["Float"]["input"]>;
  maxSchoolDropHaste?: InputMaybe<Scalars["Int"]["input"]>;
  maxSchoolPickDelay?: InputMaybe<Scalars["Int"]["input"]>;
  minimumVehicleTime?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  route: RouteCreateNestedOneWithoutRouteOptimizationConfigsInput;
  schoolToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  schoolToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zones?: InputMaybe<RouteZoneSlaCreateNestedManyWithoutRouteOptimizationConfigInput>;
};

export type RouteOptimizationConfigCreateWithoutRouteInput = {
  busCapacities?: InputMaybe<RouteBusCapacityCreateNestedManyWithoutRouteOptimizationConfigInput>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  driverPenaltyFactor?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxOptimizerRunDuration?: InputMaybe<Scalars["Float"]["input"]>;
  maxSchoolDropHaste?: InputMaybe<Scalars["Int"]["input"]>;
  maxSchoolPickDelay?: InputMaybe<Scalars["Int"]["input"]>;
  minimumVehicleTime?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeGroup?: InputMaybe<RouteGroupCreateNestedOneWithoutRouteOptimizationConfigInput>;
  schoolToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  schoolToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zones?: InputMaybe<RouteZoneSlaCreateNestedManyWithoutRouteOptimizationConfigInput>;
};

export type RouteOptimizationConfigInput = {
  busCapacities?: InputMaybe<Array<RouteBusCapacityInput>>;
  driverPenaltyFactor?: InputMaybe<Scalars["Int"]["input"]>;
  maxOptimRunDuration?: InputMaybe<Scalars["Float"]["input"]>;
  maxOptimizerRunDuration?: InputMaybe<Scalars["Float"]["input"]>;
  maxSchoolDropHaste?: InputMaybe<Scalars["Int"]["input"]>;
  maxSchoolPickDelay?: InputMaybe<Scalars["Int"]["input"]>;
  minimumVehicleTime?: InputMaybe<Scalars["Int"]["input"]>;
  schoolToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  schoolToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  zones?: InputMaybe<Array<RouteZoneSlaInput>>;
};

export type RouteOptimizationConfigListRelationFilter = {
  every?: InputMaybe<RouteOptimizationConfigWhereInput>;
  none?: InputMaybe<RouteOptimizationConfigWhereInput>;
  some?: InputMaybe<RouteOptimizationConfigWhereInput>;
};

export type RouteOptimizationConfigRelationFilter = {
  is?: InputMaybe<RouteOptimizationConfigWhereInput>;
  isNot?: InputMaybe<RouteOptimizationConfigWhereInput>;
};

export type RouteOptimizationConfigScalarWhereInput = {
  AND?: InputMaybe<Array<RouteOptimizationConfigScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteOptimizationConfigScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteOptimizationConfigScalarWhereInput>>;
  displayName?: InputMaybe<StringFilter>;
  driverPenaltyFactor?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  isCurrent?: InputMaybe<BoolFilter>;
  maxOptimizerRunDuration?: InputMaybe<FloatFilter>;
  maxSchoolDropHaste?: InputMaybe<IntFilter>;
  maxSchoolPickDelay?: InputMaybe<IntFilter>;
  minimumVehicleTime?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  routeGroupId?: InputMaybe<StringFilter>;
  routeId?: InputMaybe<StringFilter>;
  schoolToHomeStopDuration?: InputMaybe<IntFilter>;
  schoolToSchoolStopDuration?: InputMaybe<IntFilter>;
  studentToHomeStopDuration?: InputMaybe<IntFilter>;
  studentToSchoolStopDuration?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteOptimizationConfigUpdateManyMutationInput = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  driverPenaltyFactor?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxOptimizerRunDuration?: InputMaybe<Scalars["Float"]["input"]>;
  maxSchoolDropHaste?: InputMaybe<Scalars["Int"]["input"]>;
  maxSchoolPickDelay?: InputMaybe<Scalars["Int"]["input"]>;
  minimumVehicleTime?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  schoolToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteOptimizationConfigUpdateManyWithWhereWithoutRouteGroupInput = {
  data: RouteOptimizationConfigUpdateManyMutationInput;
  where: RouteOptimizationConfigScalarWhereInput;
};

export type RouteOptimizationConfigUpdateManyWithWhereWithoutRouteInput = {
  data: RouteOptimizationConfigUpdateManyMutationInput;
  where: RouteOptimizationConfigScalarWhereInput;
};

export type RouteOptimizationConfigUpdateManyWithoutRouteGroupNestedInput = {
  connect?: InputMaybe<Array<RouteOptimizationConfigWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteOptimizationConfigCreateOrConnectWithoutRouteGroupInput>
  >;
  create?: InputMaybe<
    Array<RouteOptimizationConfigCreateWithoutRouteGroupInput>
  >;
  createMany?: InputMaybe<RouteOptimizationConfigCreateManyRouteGroupInputEnvelope>;
  delete?: InputMaybe<Array<RouteOptimizationConfigWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteOptimizationConfigScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteOptimizationConfigWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteOptimizationConfigWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteOptimizationConfigUpdateWithWhereUniqueWithoutRouteGroupInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteOptimizationConfigUpdateManyWithWhereWithoutRouteGroupInput>
  >;
  upsert?: InputMaybe<
    Array<RouteOptimizationConfigUpsertWithWhereUniqueWithoutRouteGroupInput>
  >;
};

export type RouteOptimizationConfigUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteOptimizationConfigWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteOptimizationConfigCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteOptimizationConfigCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteOptimizationConfigCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteOptimizationConfigWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteOptimizationConfigScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteOptimizationConfigWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteOptimizationConfigWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteOptimizationConfigUpdateWithWhereUniqueWithoutRouteInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteOptimizationConfigUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<
    Array<RouteOptimizationConfigUpsertWithWhereUniqueWithoutRouteInput>
  >;
};

export type RouteOptimizationConfigUpdateWithWhereUniqueWithoutRouteGroupInput =
  {
    data: RouteOptimizationConfigUpdateWithoutRouteGroupInput;
    where: RouteOptimizationConfigWhereUniqueInput;
  };

export type RouteOptimizationConfigUpdateWithWhereUniqueWithoutRouteInput = {
  data: RouteOptimizationConfigUpdateWithoutRouteInput;
  where: RouteOptimizationConfigWhereUniqueInput;
};

export type RouteOptimizationConfigUpdateWithoutRouteGroupInput = {
  busCapacities?: InputMaybe<RouteBusCapacityUpdateManyWithoutRouteOptimizationConfigNestedInput>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  driverPenaltyFactor?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxOptimizerRunDuration?: InputMaybe<Scalars["Float"]["input"]>;
  maxSchoolDropHaste?: InputMaybe<Scalars["Int"]["input"]>;
  maxSchoolPickDelay?: InputMaybe<Scalars["Int"]["input"]>;
  minimumVehicleTime?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteOptimizationConfigsNestedInput>;
  schoolToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  schoolToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zones?: InputMaybe<RouteZoneSlaUpdateManyWithoutRouteOptimizationConfigNestedInput>;
};

export type RouteOptimizationConfigUpdateWithoutRouteInput = {
  busCapacities?: InputMaybe<RouteBusCapacityUpdateManyWithoutRouteOptimizationConfigNestedInput>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  driverPenaltyFactor?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxOptimizerRunDuration?: InputMaybe<Scalars["Float"]["input"]>;
  maxSchoolDropHaste?: InputMaybe<Scalars["Int"]["input"]>;
  maxSchoolPickDelay?: InputMaybe<Scalars["Int"]["input"]>;
  minimumVehicleTime?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRouteOptimizationConfigNestedInput>;
  schoolToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  schoolToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToHomeStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  studentToSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zones?: InputMaybe<RouteZoneSlaUpdateManyWithoutRouteOptimizationConfigNestedInput>;
};

export type RouteOptimizationConfigUpsertWithWhereUniqueWithoutRouteGroupInput =
  {
    create: RouteOptimizationConfigCreateWithoutRouteGroupInput;
    update: RouteOptimizationConfigUpdateWithoutRouteGroupInput;
    where: RouteOptimizationConfigWhereUniqueInput;
  };

export type RouteOptimizationConfigUpsertWithWhereUniqueWithoutRouteInput = {
  create: RouteOptimizationConfigCreateWithoutRouteInput;
  update: RouteOptimizationConfigUpdateWithoutRouteInput;
  where: RouteOptimizationConfigWhereUniqueInput;
};

export type RouteOptimizationConfigWhereInput = {
  AND?: InputMaybe<Array<RouteOptimizationConfigWhereInput>>;
  NOT?: InputMaybe<Array<RouteOptimizationConfigWhereInput>>;
  OR?: InputMaybe<Array<RouteOptimizationConfigWhereInput>>;
  busCapacities?: InputMaybe<RouteBusCapacityListRelationFilter>;
  displayName?: InputMaybe<StringFilter>;
  driverPenaltyFactor?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  isCurrent?: InputMaybe<BoolFilter>;
  maxOptimizerRunDuration?: InputMaybe<FloatFilter>;
  maxSchoolDropHaste?: InputMaybe<IntFilter>;
  maxSchoolPickDelay?: InputMaybe<IntFilter>;
  minimumVehicleTime?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeGroup?: InputMaybe<RouteGroupRelationFilter>;
  routeGroupId?: InputMaybe<StringFilter>;
  routeId?: InputMaybe<StringFilter>;
  schoolToHomeStopDuration?: InputMaybe<IntFilter>;
  schoolToSchoolStopDuration?: InputMaybe<IntFilter>;
  studentToHomeStopDuration?: InputMaybe<IntFilter>;
  studentToSchoolStopDuration?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zones?: InputMaybe<RouteZoneSlaListRelationFilter>;
};

export type RouteOptimizationConfigWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteOptimizationConfigWhereInput>>;
  NOT?: InputMaybe<Array<RouteOptimizationConfigWhereInput>>;
  OR?: InputMaybe<Array<RouteOptimizationConfigWhereInput>>;
  busCapacities?: InputMaybe<RouteBusCapacityListRelationFilter>;
  displayName?: InputMaybe<StringFilter>;
  driverPenaltyFactor?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCurrent?: InputMaybe<BoolFilter>;
  maxOptimizerRunDuration?: InputMaybe<FloatFilter>;
  maxSchoolDropHaste?: InputMaybe<IntFilter>;
  maxSchoolPickDelay?: InputMaybe<IntFilter>;
  minimumVehicleTime?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeGroup?: InputMaybe<RouteGroupRelationFilter>;
  routeGroupId?: InputMaybe<StringFilter>;
  routeId?: InputMaybe<StringFilter>;
  schoolToHomeStopDuration?: InputMaybe<IntFilter>;
  schoolToSchoolStopDuration?: InputMaybe<IntFilter>;
  studentToHomeStopDuration?: InputMaybe<IntFilter>;
  studentToSchoolStopDuration?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zones?: InputMaybe<RouteZoneSlaListRelationFilter>;
};

export type RouteRelationFilter = {
  is?: InputMaybe<RouteWhereInput>;
  isNot?: InputMaybe<RouteWhereInput>;
};

export type RouteScalarWhereInput = {
  AND?: InputMaybe<Array<RouteScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteScalarWhereInput>>;
  code?: InputMaybe<StringFilter>;
  directionality?: InputMaybe<EnumRouteDirectionalityFilter>;
  displayName?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  estimatedDistance?: InputMaybe<FloatFilter>;
  estimatedTime?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  isAutoActive?: InputMaybe<BoolFilter>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<IntFilter>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  plannedEndTime?: InputMaybe<DateTimeFilter>;
  plannedSchoolStopDuration?: InputMaybe<IntFilter>;
  plannedStartTime?: InputMaybe<DateTimeFilter>;
  plannedStudentStopDuration?: InputMaybe<IntFilter>;
  reference?: InputMaybe<StringFilter>;
  routeGroupId?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumRouteStatusFilter>;
  suggestedEndTime?: InputMaybe<DateTimeFilter>;
  suggestedStartTime?: InputMaybe<DateTimeFilter>;
  tripEndOffsetMinutes?: InputMaybe<IntFilter>;
  tripStartOffsetMinutes?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  useDriverVehicle?: InputMaybe<BoolFilter>;
  usesCollectionPoints?: InputMaybe<BoolFilter>;
};

export enum RouteStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PILOTING = "PILOTING",
  PLANNING = "PLANNING",
}

export type RouteStop = {
  __typename?: "RouteStop";
  _count: RouteStopCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  collectionPoint?: Maybe<CollectionPoint>;
  collectionPointId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  plannedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedOrder: Scalars["Int"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
  schoolRegistrations?: Maybe<Array<RouteStopSchoolRegistration>>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status: RouteStopStatus;
  stop?: Maybe<Stop>;
  stopId?: Maybe<Scalars["String"]["output"]>;
  suggestedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  suggestedOrder?: Maybe<Scalars["Int"]["output"]>;
  tripStops?: Maybe<Array<TripStop>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type RouteStopCount = {
  __typename?: "RouteStopCount";
  schoolRegistrations: Scalars["Int"]["output"];
  tripStops: Scalars["Int"]["output"];
};

export type RouteStopCreateManyCollectionPointInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedOrder: Scalars["Int"]["input"];
  routeId: Scalars["String"]["input"];
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStopStatus>;
  stopId?: InputMaybe<Scalars["String"]["input"]>;
  suggestedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopCreateManyCollectionPointInputEnvelope = {
  data: Array<RouteStopCreateManyCollectionPointInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteStopCreateManyRouteInput = {
  collectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedOrder: Scalars["Int"]["input"];
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStopStatus>;
  stopId?: InputMaybe<Scalars["String"]["input"]>;
  suggestedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopCreateManyRouteInputEnvelope = {
  data: Array<RouteStopCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteStopCreateNestedManyWithoutCollectionPointInput = {
  connect?: InputMaybe<Array<RouteStopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteStopCreateOrConnectWithoutCollectionPointInput>
  >;
  create?: InputMaybe<Array<RouteStopCreateWithoutCollectionPointInput>>;
  createMany?: InputMaybe<RouteStopCreateManyCollectionPointInputEnvelope>;
};

export type RouteStopCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteStopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteStopCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteStopCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteStopCreateManyRouteInputEnvelope>;
};

export type RouteStopCreateNestedOneWithoutSchoolRegistrationsInput = {
  connect?: InputMaybe<RouteStopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteStopCreateOrConnectWithoutSchoolRegistrationsInput>;
  create?: InputMaybe<RouteStopCreateWithoutSchoolRegistrationsInput>;
};

export type RouteStopCreateNestedOneWithoutTripStopsInput = {
  connect?: InputMaybe<RouteStopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteStopCreateOrConnectWithoutTripStopsInput>;
  create?: InputMaybe<RouteStopCreateWithoutTripStopsInput>;
};

export type RouteStopCreateOrConnectWithoutCollectionPointInput = {
  create: RouteStopCreateWithoutCollectionPointInput;
  where: RouteStopWhereUniqueInput;
};

export type RouteStopCreateOrConnectWithoutRouteInput = {
  create: RouteStopCreateWithoutRouteInput;
  where: RouteStopWhereUniqueInput;
};

export type RouteStopCreateOrConnectWithoutSchoolRegistrationsInput = {
  create: RouteStopCreateWithoutSchoolRegistrationsInput;
  where: RouteStopWhereUniqueInput;
};

export type RouteStopCreateOrConnectWithoutTripStopsInput = {
  create: RouteStopCreateWithoutTripStopsInput;
  where: RouteStopWhereUniqueInput;
};

export type RouteStopCreateWithoutCollectionPointInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedOrder: Scalars["Int"]["input"];
  route: RouteCreateNestedOneWithoutRouteStopsInput;
  schoolRegistrations?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutRouteStopInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStopStatus>;
  stop?: InputMaybe<StopCreateNestedOneWithoutRoutesInput>;
  suggestedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutRouteStopInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopCreateWithoutRouteInput = {
  collectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutRouteStopsInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedOrder: Scalars["Int"]["input"];
  schoolRegistrations?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutRouteStopInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStopStatus>;
  stop?: InputMaybe<StopCreateNestedOneWithoutRoutesInput>;
  suggestedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutRouteStopInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopCreateWithoutSchoolRegistrationsInput = {
  collectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutRouteStopsInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedOrder: Scalars["Int"]["input"];
  route: RouteCreateNestedOneWithoutRouteStopsInput;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStopStatus>;
  stop?: InputMaybe<StopCreateNestedOneWithoutRoutesInput>;
  suggestedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutRouteStopInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopCreateWithoutTripStopsInput = {
  collectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutRouteStopsInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedOrder: Scalars["Int"]["input"];
  route: RouteCreateNestedOneWithoutRouteStopsInput;
  schoolRegistrations?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutRouteStopInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStopStatus>;
  stop?: InputMaybe<StopCreateNestedOneWithoutRoutesInput>;
  suggestedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopDataInput = {
  randomId: Scalars["String"]["input"];
  stopId: Scalars["String"]["input"];
};

export type RouteStopForBusAttendant = {
  __typename?: "RouteStopForBusAttendant";
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  plannedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedOrder: Scalars["Int"]["output"];
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status: RouteStopStatus;
  stop?: Maybe<Stop>;
  stopId?: Maybe<Scalars["String"]["output"]>;
};

export type RouteStopListRelationFilter = {
  every?: InputMaybe<RouteStopWhereInput>;
  none?: InputMaybe<RouteStopWhereInput>;
  some?: InputMaybe<RouteStopWhereInput>;
};

export type RouteStopRelationFilter = {
  is?: InputMaybe<RouteStopWhereInput>;
  isNot?: InputMaybe<RouteStopWhereInput>;
};

export type RouteStopScalarWhereInput = {
  AND?: InputMaybe<Array<RouteStopScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteStopScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteStopScalarWhereInput>>;
  collectionPointId?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  plannedArrivalTime?: InputMaybe<DateTimeFilter>;
  plannedOrder?: InputMaybe<IntFilter>;
  routeId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumRouteStopStatusFilter>;
  stopId?: InputMaybe<StringFilter>;
  suggestedArrivalTime?: InputMaybe<DateTimeFilter>;
  suggestedOrder?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteStopSchoolRegistration = {
  __typename?: "RouteStopSchoolRegistration";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  routeStop: RouteStop;
  routeStopId: Scalars["String"]["output"];
  schoolRegistration: SchoolRegistration;
  schoolRegistrationId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type RouteStopSchoolRegistrationCreateManyRouteStopInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopSchoolRegistrationCreateManyRouteStopInputEnvelope = {
  data: Array<RouteStopSchoolRegistrationCreateManyRouteStopInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteStopSchoolRegistrationCreateManySchoolRegistrationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeStopId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopSchoolRegistrationCreateManySchoolRegistrationInputEnvelope =
  {
    data: Array<RouteStopSchoolRegistrationCreateManySchoolRegistrationInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type RouteStopSchoolRegistrationCreateNestedManyWithoutRouteStopInput = {
  connect?: InputMaybe<Array<RouteStopSchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteStopSchoolRegistrationCreateOrConnectWithoutRouteStopInput>
  >;
  create?: InputMaybe<
    Array<RouteStopSchoolRegistrationCreateWithoutRouteStopInput>
  >;
  createMany?: InputMaybe<RouteStopSchoolRegistrationCreateManyRouteStopInputEnvelope>;
};

export type RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput =
  {
    connect?: InputMaybe<Array<RouteStopSchoolRegistrationWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<RouteStopSchoolRegistrationCreateOrConnectWithoutSchoolRegistrationInput>
    >;
    create?: InputMaybe<
      Array<RouteStopSchoolRegistrationCreateWithoutSchoolRegistrationInput>
    >;
    createMany?: InputMaybe<RouteStopSchoolRegistrationCreateManySchoolRegistrationInputEnvelope>;
  };

export type RouteStopSchoolRegistrationCreateOrConnectWithoutRouteStopInput = {
  create: RouteStopSchoolRegistrationCreateWithoutRouteStopInput;
  where: RouteStopSchoolRegistrationWhereUniqueInput;
};

export type RouteStopSchoolRegistrationCreateOrConnectWithoutSchoolRegistrationInput =
  {
    create: RouteStopSchoolRegistrationCreateWithoutSchoolRegistrationInput;
    where: RouteStopSchoolRegistrationWhereUniqueInput;
  };

export type RouteStopSchoolRegistrationCreateWithoutRouteStopInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutRouteStopsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopSchoolRegistrationCreateWithoutSchoolRegistrationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeStop: RouteStopCreateNestedOneWithoutSchoolRegistrationsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopSchoolRegistrationListRelationFilter = {
  every?: InputMaybe<RouteStopSchoolRegistrationWhereInput>;
  none?: InputMaybe<RouteStopSchoolRegistrationWhereInput>;
  some?: InputMaybe<RouteStopSchoolRegistrationWhereInput>;
};

export type RouteStopSchoolRegistrationScalarWhereInput = {
  AND?: InputMaybe<Array<RouteStopSchoolRegistrationScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteStopSchoolRegistrationScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteStopSchoolRegistrationScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  routeStopId?: InputMaybe<StringFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteStopSchoolRegistrationUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopSchoolRegistrationUpdateManyWithWhereWithoutRouteStopInput =
  {
    data: RouteStopSchoolRegistrationUpdateManyMutationInput;
    where: RouteStopSchoolRegistrationScalarWhereInput;
  };

export type RouteStopSchoolRegistrationUpdateManyWithWhereWithoutSchoolRegistrationInput =
  {
    data: RouteStopSchoolRegistrationUpdateManyMutationInput;
    where: RouteStopSchoolRegistrationScalarWhereInput;
  };

export type RouteStopSchoolRegistrationUpdateManyWithoutRouteStopNestedInput = {
  connect?: InputMaybe<Array<RouteStopSchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteStopSchoolRegistrationCreateOrConnectWithoutRouteStopInput>
  >;
  create?: InputMaybe<
    Array<RouteStopSchoolRegistrationCreateWithoutRouteStopInput>
  >;
  createMany?: InputMaybe<RouteStopSchoolRegistrationCreateManyRouteStopInputEnvelope>;
  delete?: InputMaybe<Array<RouteStopSchoolRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteStopSchoolRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteStopSchoolRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteStopSchoolRegistrationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteStopSchoolRegistrationUpdateWithWhereUniqueWithoutRouteStopInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteStopSchoolRegistrationUpdateManyWithWhereWithoutRouteStopInput>
  >;
  upsert?: InputMaybe<
    Array<RouteStopSchoolRegistrationUpsertWithWhereUniqueWithoutRouteStopInput>
  >;
};

export type RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput =
  {
    connect?: InputMaybe<Array<RouteStopSchoolRegistrationWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<RouteStopSchoolRegistrationCreateOrConnectWithoutSchoolRegistrationInput>
    >;
    create?: InputMaybe<
      Array<RouteStopSchoolRegistrationCreateWithoutSchoolRegistrationInput>
    >;
    createMany?: InputMaybe<RouteStopSchoolRegistrationCreateManySchoolRegistrationInputEnvelope>;
    delete?: InputMaybe<Array<RouteStopSchoolRegistrationWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<RouteStopSchoolRegistrationScalarWhereInput>>;
    disconnect?: InputMaybe<Array<RouteStopSchoolRegistrationWhereUniqueInput>>;
    set?: InputMaybe<Array<RouteStopSchoolRegistrationWhereUniqueInput>>;
    update?: InputMaybe<
      Array<RouteStopSchoolRegistrationUpdateWithWhereUniqueWithoutSchoolRegistrationInput>
    >;
    updateMany?: InputMaybe<
      Array<RouteStopSchoolRegistrationUpdateManyWithWhereWithoutSchoolRegistrationInput>
    >;
    upsert?: InputMaybe<
      Array<RouteStopSchoolRegistrationUpsertWithWhereUniqueWithoutSchoolRegistrationInput>
    >;
  };

export type RouteStopSchoolRegistrationUpdateWithWhereUniqueWithoutRouteStopInput =
  {
    data: RouteStopSchoolRegistrationUpdateWithoutRouteStopInput;
    where: RouteStopSchoolRegistrationWhereUniqueInput;
  };

export type RouteStopSchoolRegistrationUpdateWithWhereUniqueWithoutSchoolRegistrationInput =
  {
    data: RouteStopSchoolRegistrationUpdateWithoutSchoolRegistrationInput;
    where: RouteStopSchoolRegistrationWhereUniqueInput;
  };

export type RouteStopSchoolRegistrationUpdateWithoutRouteStopInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutRouteStopsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopSchoolRegistrationUpdateWithoutSchoolRegistrationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeStop?: InputMaybe<RouteStopUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopSchoolRegistrationUpsertWithWhereUniqueWithoutRouteStopInput =
  {
    create: RouteStopSchoolRegistrationCreateWithoutRouteStopInput;
    update: RouteStopSchoolRegistrationUpdateWithoutRouteStopInput;
    where: RouteStopSchoolRegistrationWhereUniqueInput;
  };

export type RouteStopSchoolRegistrationUpsertWithWhereUniqueWithoutSchoolRegistrationInput =
  {
    create: RouteStopSchoolRegistrationCreateWithoutSchoolRegistrationInput;
    update: RouteStopSchoolRegistrationUpdateWithoutSchoolRegistrationInput;
    where: RouteStopSchoolRegistrationWhereUniqueInput;
  };

export type RouteStopSchoolRegistrationWhereInput = {
  AND?: InputMaybe<Array<RouteStopSchoolRegistrationWhereInput>>;
  NOT?: InputMaybe<Array<RouteStopSchoolRegistrationWhereInput>>;
  OR?: InputMaybe<Array<RouteStopSchoolRegistrationWhereInput>>;
  id?: InputMaybe<StringFilter>;
  routeStop?: InputMaybe<RouteStopRelationFilter>;
  routeStopId?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteStopSchoolRegistrationWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteStopSchoolRegistrationWhereInput>>;
  NOT?: InputMaybe<Array<RouteStopSchoolRegistrationWhereInput>>;
  OR?: InputMaybe<Array<RouteStopSchoolRegistrationWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeStop?: InputMaybe<RouteStopRelationFilter>;
  routeStopId?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum RouteStopStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  STAND_BY = "STAND_BY",
}

export type RouteStopTemp = {
  __typename?: "RouteStopTemp";
  debugVisitTime?: Maybe<Scalars["String"]["output"]>;
  plannedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedOrder: Scalars["Int"]["output"];
  randomId: Scalars["String"]["output"];
  stopId?: Maybe<Scalars["String"]["output"]>;
  stopType: StopType;
  suggestedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
};

export type RouteStopUpdateManyMutationInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStopStatus>;
  suggestedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopUpdateManyWithWhereWithoutCollectionPointInput = {
  data: RouteStopUpdateManyMutationInput;
  where: RouteStopScalarWhereInput;
};

export type RouteStopUpdateManyWithWhereWithoutRouteInput = {
  data: RouteStopUpdateManyMutationInput;
  where: RouteStopScalarWhereInput;
};

export type RouteStopUpdateManyWithoutCollectionPointNestedInput = {
  connect?: InputMaybe<Array<RouteStopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteStopCreateOrConnectWithoutCollectionPointInput>
  >;
  create?: InputMaybe<Array<RouteStopCreateWithoutCollectionPointInput>>;
  createMany?: InputMaybe<RouteStopCreateManyCollectionPointInputEnvelope>;
  delete?: InputMaybe<Array<RouteStopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteStopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteStopWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteStopWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteStopUpdateWithWhereUniqueWithoutCollectionPointInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteStopUpdateManyWithWhereWithoutCollectionPointInput>
  >;
  upsert?: InputMaybe<
    Array<RouteStopUpsertWithWhereUniqueWithoutCollectionPointInput>
  >;
};

export type RouteStopUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteStopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteStopCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteStopCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteStopCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteStopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteStopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteStopWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteStopWhereUniqueInput>>;
  update?: InputMaybe<Array<RouteStopUpdateWithWhereUniqueWithoutRouteInput>>;
  updateMany?: InputMaybe<Array<RouteStopUpdateManyWithWhereWithoutRouteInput>>;
  upsert?: InputMaybe<Array<RouteStopUpsertWithWhereUniqueWithoutRouteInput>>;
};

export type RouteStopUpdateOneRequiredWithoutSchoolRegistrationsNestedInput = {
  connect?: InputMaybe<RouteStopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteStopCreateOrConnectWithoutSchoolRegistrationsInput>;
  create?: InputMaybe<RouteStopCreateWithoutSchoolRegistrationsInput>;
  update?: InputMaybe<RouteStopUpdateToOneWithWhereWithoutSchoolRegistrationsInput>;
  upsert?: InputMaybe<RouteStopUpsertWithoutSchoolRegistrationsInput>;
};

export type RouteStopUpdateOneRequiredWithoutTripStopsNestedInput = {
  connect?: InputMaybe<RouteStopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteStopCreateOrConnectWithoutTripStopsInput>;
  create?: InputMaybe<RouteStopCreateWithoutTripStopsInput>;
  update?: InputMaybe<RouteStopUpdateToOneWithWhereWithoutTripStopsInput>;
  upsert?: InputMaybe<RouteStopUpsertWithoutTripStopsInput>;
};

export type RouteStopUpdateToOneWithWhereWithoutSchoolRegistrationsInput = {
  data: RouteStopUpdateWithoutSchoolRegistrationsInput;
  where?: InputMaybe<RouteStopWhereInput>;
};

export type RouteStopUpdateToOneWithWhereWithoutTripStopsInput = {
  data: RouteStopUpdateWithoutTripStopsInput;
  where?: InputMaybe<RouteStopWhereInput>;
};

export type RouteStopUpdateWithWhereUniqueWithoutCollectionPointInput = {
  data: RouteStopUpdateWithoutCollectionPointInput;
  where: RouteStopWhereUniqueInput;
};

export type RouteStopUpdateWithWhereUniqueWithoutRouteInput = {
  data: RouteStopUpdateWithoutRouteInput;
  where: RouteStopWhereUniqueInput;
};

export type RouteStopUpdateWithoutCollectionPointInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteStopsNestedInput>;
  schoolRegistrations?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutRouteStopNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStopStatus>;
  stop?: InputMaybe<StopUpdateOneWithoutRoutesNestedInput>;
  suggestedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutRouteStopNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopUpdateWithoutRouteInput = {
  collectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutRouteStopsNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  schoolRegistrations?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutRouteStopNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStopStatus>;
  stop?: InputMaybe<StopUpdateOneWithoutRoutesNestedInput>;
  suggestedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutRouteStopNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopUpdateWithoutSchoolRegistrationsInput = {
  collectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutRouteStopsNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteStopsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStopStatus>;
  stop?: InputMaybe<StopUpdateOneWithoutRoutesNestedInput>;
  suggestedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutRouteStopNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopUpdateWithoutTripStopsInput = {
  collectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutRouteStopsNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteStopsNestedInput>;
  schoolRegistrations?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutRouteStopNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStopStatus>;
  stop?: InputMaybe<StopUpdateOneWithoutRoutesNestedInput>;
  suggestedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteStopUpsertWithWhereUniqueWithoutCollectionPointInput = {
  create: RouteStopCreateWithoutCollectionPointInput;
  update: RouteStopUpdateWithoutCollectionPointInput;
  where: RouteStopWhereUniqueInput;
};

export type RouteStopUpsertWithWhereUniqueWithoutRouteInput = {
  create: RouteStopCreateWithoutRouteInput;
  update: RouteStopUpdateWithoutRouteInput;
  where: RouteStopWhereUniqueInput;
};

export type RouteStopUpsertWithoutSchoolRegistrationsInput = {
  create: RouteStopCreateWithoutSchoolRegistrationsInput;
  update: RouteStopUpdateWithoutSchoolRegistrationsInput;
  where?: InputMaybe<RouteStopWhereInput>;
};

export type RouteStopUpsertWithoutTripStopsInput = {
  create: RouteStopCreateWithoutTripStopsInput;
  update: RouteStopUpdateWithoutTripStopsInput;
  where?: InputMaybe<RouteStopWhereInput>;
};

export type RouteStopWhereInput = {
  AND?: InputMaybe<Array<RouteStopWhereInput>>;
  NOT?: InputMaybe<Array<RouteStopWhereInput>>;
  OR?: InputMaybe<Array<RouteStopWhereInput>>;
  collectionPoint?: InputMaybe<CollectionPointRelationFilter>;
  collectionPointId?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  plannedArrivalTime?: InputMaybe<DateTimeFilter>;
  plannedOrder?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  schoolRegistrations?: InputMaybe<RouteStopSchoolRegistrationListRelationFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumRouteStopStatusFilter>;
  stop?: InputMaybe<StopRelationFilter>;
  stopId?: InputMaybe<StringFilter>;
  suggestedArrivalTime?: InputMaybe<DateTimeFilter>;
  suggestedOrder?: InputMaybe<IntFilter>;
  tripStops?: InputMaybe<TripStopListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteStopWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteStopWhereInput>>;
  NOT?: InputMaybe<Array<RouteStopWhereInput>>;
  OR?: InputMaybe<Array<RouteStopWhereInput>>;
  collectionPoint?: InputMaybe<CollectionPointRelationFilter>;
  collectionPointId?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedArrivalTime?: InputMaybe<DateTimeFilter>;
  plannedOrder?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  schoolRegistrations?: InputMaybe<RouteStopSchoolRegistrationListRelationFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumRouteStopStatusFilter>;
  stop?: InputMaybe<StopRelationFilter>;
  stopId?: InputMaybe<StringFilter>;
  suggestedArrivalTime?: InputMaybe<DateTimeFilter>;
  suggestedOrder?: InputMaybe<IntFilter>;
  tripStops?: InputMaybe<TripStopListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteTemp = {
  __typename?: "RouteTemp";
  capacity?: Maybe<Scalars["Int"]["output"]>;
  directionality: RouteDirectionality;
  estimatedDistance: Scalars["Float"]["output"];
  estimatedTime: Scalars["Float"]["output"];
  polyline: Array<LatLon>;
  routeStops: Array<RouteStopTemp>;
  schoolCount: Scalars["Int"]["output"];
  studentCount: Scalars["Int"]["output"];
  utilization?: Maybe<Scalars["Float"]["output"]>;
};

export type RouteTempWithJobId = {
  __typename?: "RouteTempWithJobId";
  error?: Maybe<Scalars["String"]["output"]>;
  finalRoutes?: Maybe<Array<RouteTemp>>;
  jobId: Scalars["String"]["output"];
};

export type RouteTimeLimitExceededAlert = {
  __typename?: "RouteTimeLimitExceededAlert";
  alert: Alert;
  alertId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
};

export type RouteTimeLimitExceededAlertCreateManyRouteInput = {
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteTimeLimitExceededAlertCreateManyRouteInputEnvelope = {
  data: Array<RouteTimeLimitExceededAlertCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteTimeLimitExceededAlertCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteTimeLimitExceededAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteTimeLimitExceededAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<
    Array<RouteTimeLimitExceededAlertCreateWithoutRouteInput>
  >;
  createMany?: InputMaybe<RouteTimeLimitExceededAlertCreateManyRouteInputEnvelope>;
};

export type RouteTimeLimitExceededAlertCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<RouteTimeLimitExceededAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteTimeLimitExceededAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteTimeLimitExceededAlertCreateWithoutAlertInput>;
};

export type RouteTimeLimitExceededAlertCreateOrConnectWithoutAlertInput = {
  create: RouteTimeLimitExceededAlertCreateWithoutAlertInput;
  where: RouteTimeLimitExceededAlertWhereUniqueInput;
};

export type RouteTimeLimitExceededAlertCreateOrConnectWithoutRouteInput = {
  create: RouteTimeLimitExceededAlertCreateWithoutRouteInput;
  where: RouteTimeLimitExceededAlertWhereUniqueInput;
};

export type RouteTimeLimitExceededAlertCreateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  route: RouteCreateNestedOneWithoutRouteTimeLimitExceededAlertsInput;
};

export type RouteTimeLimitExceededAlertCreateWithoutRouteInput = {
  alert: AlertCreateNestedOneWithoutRouteTimeLimitExceededAlertInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteTimeLimitExceededAlertListRelationFilter = {
  every?: InputMaybe<RouteTimeLimitExceededAlertWhereInput>;
  none?: InputMaybe<RouteTimeLimitExceededAlertWhereInput>;
  some?: InputMaybe<RouteTimeLimitExceededAlertWhereInput>;
};

export type RouteTimeLimitExceededAlertRelationFilter = {
  is?: InputMaybe<RouteTimeLimitExceededAlertWhereInput>;
  isNot?: InputMaybe<RouteTimeLimitExceededAlertWhereInput>;
};

export type RouteTimeLimitExceededAlertScalarWhereInput = {
  AND?: InputMaybe<Array<RouteTimeLimitExceededAlertScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteTimeLimitExceededAlertScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteTimeLimitExceededAlertScalarWhereInput>>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteTimeLimitExceededAlertUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteTimeLimitExceededAlertUpdateManyWithWhereWithoutRouteInput = {
  data: RouteTimeLimitExceededAlertUpdateManyMutationInput;
  where: RouteTimeLimitExceededAlertScalarWhereInput;
};

export type RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteTimeLimitExceededAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteTimeLimitExceededAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<
    Array<RouteTimeLimitExceededAlertCreateWithoutRouteInput>
  >;
  createMany?: InputMaybe<RouteTimeLimitExceededAlertCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteTimeLimitExceededAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteTimeLimitExceededAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteTimeLimitExceededAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteTimeLimitExceededAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteTimeLimitExceededAlertUpdateWithWhereUniqueWithoutRouteInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteTimeLimitExceededAlertUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<
    Array<RouteTimeLimitExceededAlertUpsertWithWhereUniqueWithoutRouteInput>
  >;
};

export type RouteTimeLimitExceededAlertUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<RouteTimeLimitExceededAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteTimeLimitExceededAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteTimeLimitExceededAlertCreateWithoutAlertInput>;
  delete?: InputMaybe<RouteTimeLimitExceededAlertWhereInput>;
  disconnect?: InputMaybe<RouteTimeLimitExceededAlertWhereInput>;
  update?: InputMaybe<RouteTimeLimitExceededAlertUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<RouteTimeLimitExceededAlertUpsertWithoutAlertInput>;
};

export type RouteTimeLimitExceededAlertUpdateToOneWithWhereWithoutAlertInput = {
  data: RouteTimeLimitExceededAlertUpdateWithoutAlertInput;
  where?: InputMaybe<RouteTimeLimitExceededAlertWhereInput>;
};

export type RouteTimeLimitExceededAlertUpdateWithWhereUniqueWithoutRouteInput =
  {
    data: RouteTimeLimitExceededAlertUpdateWithoutRouteInput;
    where: RouteTimeLimitExceededAlertWhereUniqueInput;
  };

export type RouteTimeLimitExceededAlertUpdateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteTimeLimitExceededAlertsNestedInput>;
};

export type RouteTimeLimitExceededAlertUpdateWithoutRouteInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutRouteTimeLimitExceededAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteTimeLimitExceededAlertUpsertWithWhereUniqueWithoutRouteInput =
  {
    create: RouteTimeLimitExceededAlertCreateWithoutRouteInput;
    update: RouteTimeLimitExceededAlertUpdateWithoutRouteInput;
    where: RouteTimeLimitExceededAlertWhereUniqueInput;
  };

export type RouteTimeLimitExceededAlertUpsertWithoutAlertInput = {
  create: RouteTimeLimitExceededAlertCreateWithoutAlertInput;
  update: RouteTimeLimitExceededAlertUpdateWithoutAlertInput;
  where?: InputMaybe<RouteTimeLimitExceededAlertWhereInput>;
};

export type RouteTimeLimitExceededAlertWhereInput = {
  AND?: InputMaybe<Array<RouteTimeLimitExceededAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteTimeLimitExceededAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteTimeLimitExceededAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteTimeLimitExceededAlertWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteTimeLimitExceededAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteTimeLimitExceededAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteTimeLimitExceededAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
};

export type RouteUpdateInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateManyMutationInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteUpdateManyWithWhereWithoutRouteGroupInput = {
  data: RouteUpdateManyMutationInput;
  where: RouteScalarWhereInput;
};

export type RouteUpdateManyWithWhereWithoutServiceProviderInput = {
  data: RouteUpdateManyMutationInput;
  where: RouteScalarWhereInput;
};

export type RouteUpdateManyWithoutRouteGroupNestedInput = {
  connect?: InputMaybe<Array<RouteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteCreateOrConnectWithoutRouteGroupInput>
  >;
  create?: InputMaybe<Array<RouteCreateWithoutRouteGroupInput>>;
  createMany?: InputMaybe<RouteCreateManyRouteGroupInputEnvelope>;
  delete?: InputMaybe<Array<RouteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteWhereUniqueInput>>;
  update?: InputMaybe<Array<RouteUpdateWithWhereUniqueWithoutRouteGroupInput>>;
  updateMany?: InputMaybe<
    Array<RouteUpdateManyWithWhereWithoutRouteGroupInput>
  >;
  upsert?: InputMaybe<Array<RouteUpsertWithWhereUniqueWithoutRouteGroupInput>>;
};

export type RouteUpdateManyWithoutServiceProviderNestedInput = {
  connect?: InputMaybe<Array<RouteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<RouteCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<RouteCreateManyServiceProviderInputEnvelope>;
  delete?: InputMaybe<Array<RouteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteUpdateWithWhereUniqueWithoutServiceProviderInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteUpdateManyWithWhereWithoutServiceProviderInput>
  >;
  upsert?: InputMaybe<
    Array<RouteUpsertWithWhereUniqueWithoutServiceProviderInput>
  >;
};

export type RouteUpdateOneRequiredWithoutAgentsNestedInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutAgentsInput>;
  create?: InputMaybe<RouteCreateWithoutAgentsInput>;
  update?: InputMaybe<RouteUpdateToOneWithWhereWithoutAgentsInput>;
  upsert?: InputMaybe<RouteUpsertWithoutAgentsInput>;
};

export type RouteUpdateOneRequiredWithoutBusAttendantsNestedInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutBusAttendantsInput>;
  create?: InputMaybe<RouteCreateWithoutBusAttendantsInput>;
  update?: InputMaybe<RouteUpdateToOneWithWhereWithoutBusAttendantsInput>;
  upsert?: InputMaybe<RouteUpsertWithoutBusAttendantsInput>;
};

export type RouteUpdateOneRequiredWithoutDriversNestedInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutDriversInput>;
  create?: InputMaybe<RouteCreateWithoutDriversInput>;
  update?: InputMaybe<RouteUpdateToOneWithWhereWithoutDriversInput>;
  upsert?: InputMaybe<RouteUpsertWithoutDriversInput>;
};

export type RouteUpdateOneRequiredWithoutLeaveRequestsNestedInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutLeaveRequestsInput>;
  create?: InputMaybe<RouteCreateWithoutLeaveRequestsInput>;
  update?: InputMaybe<RouteUpdateToOneWithWhereWithoutLeaveRequestsInput>;
  upsert?: InputMaybe<RouteUpsertWithoutLeaveRequestsInput>;
};

export type RouteUpdateOneRequiredWithoutRouteBusAttendantVacantAlertsNestedInput =
  {
    connect?: InputMaybe<RouteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteBusAttendantVacantAlertsInput>;
    create?: InputMaybe<RouteCreateWithoutRouteBusAttendantVacantAlertsInput>;
    update?: InputMaybe<RouteUpdateToOneWithWhereWithoutRouteBusAttendantVacantAlertsInput>;
    upsert?: InputMaybe<RouteUpsertWithoutRouteBusAttendantVacantAlertsInput>;
  };

export type RouteUpdateOneRequiredWithoutRouteCapacityExceededAlertsNestedInput =
  {
    connect?: InputMaybe<RouteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteCapacityExceededAlertsInput>;
    create?: InputMaybe<RouteCreateWithoutRouteCapacityExceededAlertsInput>;
    update?: InputMaybe<RouteUpdateToOneWithWhereWithoutRouteCapacityExceededAlertsInput>;
    upsert?: InputMaybe<RouteUpsertWithoutRouteCapacityExceededAlertsInput>;
  };

export type RouteUpdateOneRequiredWithoutRouteDateAlertsNestedInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteDateAlertsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteDateAlertsInput>;
  update?: InputMaybe<RouteUpdateToOneWithWhereWithoutRouteDateAlertsInput>;
  upsert?: InputMaybe<RouteUpsertWithoutRouteDateAlertsInput>;
};

export type RouteUpdateOneRequiredWithoutRouteDriverVacantAlertsNestedInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteDriverVacantAlertsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteDriverVacantAlertsInput>;
  update?: InputMaybe<RouteUpdateToOneWithWhereWithoutRouteDriverVacantAlertsInput>;
  upsert?: InputMaybe<RouteUpsertWithoutRouteDriverVacantAlertsInput>;
};

export type RouteUpdateOneRequiredWithoutRouteOptimizationConfigsNestedInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteOptimizationConfigsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteOptimizationConfigsInput>;
  update?: InputMaybe<RouteUpdateToOneWithWhereWithoutRouteOptimizationConfigsInput>;
  upsert?: InputMaybe<RouteUpsertWithoutRouteOptimizationConfigsInput>;
};

export type RouteUpdateOneRequiredWithoutRouteStopsNestedInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteStopsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteStopsInput>;
  update?: InputMaybe<RouteUpdateToOneWithWhereWithoutRouteStopsInput>;
  upsert?: InputMaybe<RouteUpsertWithoutRouteStopsInput>;
};

export type RouteUpdateOneRequiredWithoutRouteTimeLimitExceededAlertsNestedInput =
  {
    connect?: InputMaybe<RouteWhereUniqueInput>;
    connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteTimeLimitExceededAlertsInput>;
    create?: InputMaybe<RouteCreateWithoutRouteTimeLimitExceededAlertsInput>;
    update?: InputMaybe<RouteUpdateToOneWithWhereWithoutRouteTimeLimitExceededAlertsInput>;
    upsert?: InputMaybe<RouteUpsertWithoutRouteTimeLimitExceededAlertsInput>;
  };

export type RouteUpdateOneRequiredWithoutRouteVehicleVacantAlertsNestedInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutRouteVehicleVacantAlertsInput>;
  create?: InputMaybe<RouteCreateWithoutRouteVehicleVacantAlertsInput>;
  update?: InputMaybe<RouteUpdateToOneWithWhereWithoutRouteVehicleVacantAlertsInput>;
  upsert?: InputMaybe<RouteUpsertWithoutRouteVehicleVacantAlertsInput>;
};

export type RouteUpdateOneRequiredWithoutVehiclesNestedInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<RouteCreateWithoutVehiclesInput>;
  update?: InputMaybe<RouteUpdateToOneWithWhereWithoutVehiclesInput>;
  upsert?: InputMaybe<RouteUpsertWithoutVehiclesInput>;
};

export type RouteUpdateOneWithoutTripsNestedInput = {
  connect?: InputMaybe<RouteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteCreateOrConnectWithoutTripsInput>;
  create?: InputMaybe<RouteCreateWithoutTripsInput>;
  delete?: InputMaybe<RouteWhereInput>;
  disconnect?: InputMaybe<RouteWhereInput>;
  update?: InputMaybe<RouteUpdateToOneWithWhereWithoutTripsInput>;
  upsert?: InputMaybe<RouteUpsertWithoutTripsInput>;
};

export type RouteUpdateToOneWithWhereWithoutAgentsInput = {
  data: RouteUpdateWithoutAgentsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateToOneWithWhereWithoutBusAttendantsInput = {
  data: RouteUpdateWithoutBusAttendantsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateToOneWithWhereWithoutDriversInput = {
  data: RouteUpdateWithoutDriversInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateToOneWithWhereWithoutLeaveRequestsInput = {
  data: RouteUpdateWithoutLeaveRequestsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateToOneWithWhereWithoutRouteBusAttendantVacantAlertsInput =
  {
    data: RouteUpdateWithoutRouteBusAttendantVacantAlertsInput;
    where?: InputMaybe<RouteWhereInput>;
  };

export type RouteUpdateToOneWithWhereWithoutRouteCapacityExceededAlertsInput = {
  data: RouteUpdateWithoutRouteCapacityExceededAlertsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateToOneWithWhereWithoutRouteDateAlertsInput = {
  data: RouteUpdateWithoutRouteDateAlertsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateToOneWithWhereWithoutRouteDriverVacantAlertsInput = {
  data: RouteUpdateWithoutRouteDriverVacantAlertsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateToOneWithWhereWithoutRouteOptimizationConfigsInput = {
  data: RouteUpdateWithoutRouteOptimizationConfigsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateToOneWithWhereWithoutRouteStopsInput = {
  data: RouteUpdateWithoutRouteStopsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateToOneWithWhereWithoutRouteTimeLimitExceededAlertsInput =
  {
    data: RouteUpdateWithoutRouteTimeLimitExceededAlertsInput;
    where?: InputMaybe<RouteWhereInput>;
  };

export type RouteUpdateToOneWithWhereWithoutRouteVehicleVacantAlertsInput = {
  data: RouteUpdateWithoutRouteVehicleVacantAlertsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateToOneWithWhereWithoutTripsInput = {
  data: RouteUpdateWithoutTripsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateToOneWithWhereWithoutVehiclesInput = {
  data: RouteUpdateWithoutVehiclesInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpdateWithWhereUniqueWithoutRouteGroupInput = {
  data: RouteUpdateWithoutRouteGroupInput;
  where: RouteWhereUniqueInput;
};

export type RouteUpdateWithWhereUniqueWithoutServiceProviderInput = {
  data: RouteUpdateWithoutServiceProviderInput;
  where: RouteWhereUniqueInput;
};

export type RouteUpdateWithoutAgentsInput = {
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutBusAttendantsInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutDriversInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutLeaveRequestsInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutRouteBusAttendantVacantAlertsInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutRouteCapacityExceededAlertsInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutRouteDateAlertsInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutRouteDriverVacantAlertsInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutRouteGroupInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutRouteOptimizationConfigsInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutRouteStopsInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutRouteTimeLimitExceededAlertsInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutRouteVehicleVacantAlertsInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutServiceProviderInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutTripsInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<RouteVehicleUpdateManyWithoutRouteNestedInput>;
};

export type RouteUpdateWithoutVehiclesInput = {
  agents?: InputMaybe<RouteAgentUpdateManyWithoutRouteNestedInput>;
  busAttendants?: InputMaybe<RouteBusAttendantUpdateManyWithoutRouteNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  directionality?: InputMaybe<RouteDirectionality>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<RouteDriverUpdateManyWithoutRouteNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedDistance?: InputMaybe<Scalars["Float"]["input"]>;
  estimatedTime?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutRouteNestedInput>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeDateAlerts?: InputMaybe<RouteDateAlertUpdateManyWithoutRouteNestedInput>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertUpdateManyWithoutRouteNestedInput>;
  routeGroup?: InputMaybe<RouteGroupUpdateOneWithoutRoutesNestedInput>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigUpdateManyWithoutRouteNestedInput>;
  routeStops?: InputMaybe<RouteStopUpdateManyWithoutRouteNestedInput>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertUpdateManyWithoutRouteNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<RouteStatus>;
  suggestedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  suggestedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  trips?: InputMaybe<TripUpdateManyWithoutRouteNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  useDriverVehicle?: InputMaybe<Scalars["Boolean"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteUpsertWithWhereUniqueWithoutRouteGroupInput = {
  create: RouteCreateWithoutRouteGroupInput;
  update: RouteUpdateWithoutRouteGroupInput;
  where: RouteWhereUniqueInput;
};

export type RouteUpsertWithWhereUniqueWithoutServiceProviderInput = {
  create: RouteCreateWithoutServiceProviderInput;
  update: RouteUpdateWithoutServiceProviderInput;
  where: RouteWhereUniqueInput;
};

export type RouteUpsertWithoutAgentsInput = {
  create: RouteCreateWithoutAgentsInput;
  update: RouteUpdateWithoutAgentsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutBusAttendantsInput = {
  create: RouteCreateWithoutBusAttendantsInput;
  update: RouteUpdateWithoutBusAttendantsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutDriversInput = {
  create: RouteCreateWithoutDriversInput;
  update: RouteUpdateWithoutDriversInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutLeaveRequestsInput = {
  create: RouteCreateWithoutLeaveRequestsInput;
  update: RouteUpdateWithoutLeaveRequestsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutRouteBusAttendantVacantAlertsInput = {
  create: RouteCreateWithoutRouteBusAttendantVacantAlertsInput;
  update: RouteUpdateWithoutRouteBusAttendantVacantAlertsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutRouteCapacityExceededAlertsInput = {
  create: RouteCreateWithoutRouteCapacityExceededAlertsInput;
  update: RouteUpdateWithoutRouteCapacityExceededAlertsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutRouteDateAlertsInput = {
  create: RouteCreateWithoutRouteDateAlertsInput;
  update: RouteUpdateWithoutRouteDateAlertsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutRouteDriverVacantAlertsInput = {
  create: RouteCreateWithoutRouteDriverVacantAlertsInput;
  update: RouteUpdateWithoutRouteDriverVacantAlertsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutRouteOptimizationConfigsInput = {
  create: RouteCreateWithoutRouteOptimizationConfigsInput;
  update: RouteUpdateWithoutRouteOptimizationConfigsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutRouteStopsInput = {
  create: RouteCreateWithoutRouteStopsInput;
  update: RouteUpdateWithoutRouteStopsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutRouteTimeLimitExceededAlertsInput = {
  create: RouteCreateWithoutRouteTimeLimitExceededAlertsInput;
  update: RouteUpdateWithoutRouteTimeLimitExceededAlertsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutRouteVehicleVacantAlertsInput = {
  create: RouteCreateWithoutRouteVehicleVacantAlertsInput;
  update: RouteUpdateWithoutRouteVehicleVacantAlertsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutTripsInput = {
  create: RouteCreateWithoutTripsInput;
  update: RouteUpdateWithoutTripsInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteUpsertWithoutVehiclesInput = {
  create: RouteCreateWithoutVehiclesInput;
  update: RouteUpdateWithoutVehiclesInput;
  where?: InputMaybe<RouteWhereInput>;
};

export type RouteVehicle = {
  __typename?: "RouteVehicle";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  fromDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  precedence: Scalars["Int"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
  toDate?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  vehicle: Vehicle;
  vehicleId: Scalars["String"]["output"];
};

export type RouteVehicleCreateManyRouteInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleId: Scalars["String"]["input"];
};

export type RouteVehicleCreateManyRouteInputEnvelope = {
  data: Array<RouteVehicleCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteVehicleCreateManyVehicleInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  routeId: Scalars["String"]["input"];
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteVehicleCreateManyVehicleInputEnvelope = {
  data: Array<RouteVehicleCreateManyVehicleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteVehicleCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteVehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteVehicleCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteVehicleCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteVehicleCreateManyRouteInputEnvelope>;
};

export type RouteVehicleCreateNestedManyWithoutVehicleInput = {
  connect?: InputMaybe<Array<RouteVehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteVehicleCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<RouteVehicleCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<RouteVehicleCreateManyVehicleInputEnvelope>;
};

export type RouteVehicleCreateOrConnectWithoutRouteInput = {
  create: RouteVehicleCreateWithoutRouteInput;
  where: RouteVehicleWhereUniqueInput;
};

export type RouteVehicleCreateOrConnectWithoutVehicleInput = {
  create: RouteVehicleCreateWithoutVehicleInput;
  where: RouteVehicleWhereUniqueInput;
};

export type RouteVehicleCreateWithoutRouteInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle: VehicleCreateNestedOneWithoutRoutesInput;
};

export type RouteVehicleCreateWithoutVehicleInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  route: RouteCreateNestedOneWithoutVehiclesInput;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteVehicleListRelationFilter = {
  every?: InputMaybe<RouteVehicleWhereInput>;
  none?: InputMaybe<RouteVehicleWhereInput>;
  some?: InputMaybe<RouteVehicleWhereInput>;
};

export type RouteVehicleScalarWhereInput = {
  AND?: InputMaybe<Array<RouteVehicleScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteVehicleScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteVehicleScalarWhereInput>>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type RouteVehicleUpdateManyMutationInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteVehicleUpdateManyWithWhereWithoutRouteInput = {
  data: RouteVehicleUpdateManyMutationInput;
  where: RouteVehicleScalarWhereInput;
};

export type RouteVehicleUpdateManyWithWhereWithoutVehicleInput = {
  data: RouteVehicleUpdateManyMutationInput;
  where: RouteVehicleScalarWhereInput;
};

export type RouteVehicleUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteVehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteVehicleCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteVehicleCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteVehicleCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteVehicleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteVehicleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteVehicleWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteVehicleWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteVehicleUpdateWithWhereUniqueWithoutRouteInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteVehicleUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<
    Array<RouteVehicleUpsertWithWhereUniqueWithoutRouteInput>
  >;
};

export type RouteVehicleUpdateManyWithoutVehicleNestedInput = {
  connect?: InputMaybe<Array<RouteVehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteVehicleCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<RouteVehicleCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<RouteVehicleCreateManyVehicleInputEnvelope>;
  delete?: InputMaybe<Array<RouteVehicleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteVehicleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteVehicleWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteVehicleWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteVehicleUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteVehicleUpdateManyWithWhereWithoutVehicleInput>
  >;
  upsert?: InputMaybe<
    Array<RouteVehicleUpsertWithWhereUniqueWithoutVehicleInput>
  >;
};

export type RouteVehicleUpdateWithWhereUniqueWithoutRouteInput = {
  data: RouteVehicleUpdateWithoutRouteInput;
  where: RouteVehicleWhereUniqueInput;
};

export type RouteVehicleUpdateWithWhereUniqueWithoutVehicleInput = {
  data: RouteVehicleUpdateWithoutVehicleInput;
  where: RouteVehicleWhereUniqueInput;
};

export type RouteVehicleUpdateWithoutRouteInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneRequiredWithoutRoutesNestedInput>;
};

export type RouteVehicleUpdateWithoutVehicleInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  precedence?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutVehiclesNestedInput>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteVehicleUpsertWithWhereUniqueWithoutRouteInput = {
  create: RouteVehicleCreateWithoutRouteInput;
  update: RouteVehicleUpdateWithoutRouteInput;
  where: RouteVehicleWhereUniqueInput;
};

export type RouteVehicleUpsertWithWhereUniqueWithoutVehicleInput = {
  create: RouteVehicleCreateWithoutVehicleInput;
  update: RouteVehicleUpdateWithoutVehicleInput;
  where: RouteVehicleWhereUniqueInput;
};

export type RouteVehicleVacantAlert = {
  __typename?: "RouteVehicleVacantAlert";
  alert: Alert;
  alertId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars["String"]["output"]>;
};

export type RouteVehicleVacantAlertCreateManyRouteInput = {
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteVehicleVacantAlertCreateManyRouteInputEnvelope = {
  data: Array<RouteVehicleVacantAlertCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteVehicleVacantAlertCreateManyVehicleInput = {
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  routeId: Scalars["String"]["input"];
};

export type RouteVehicleVacantAlertCreateManyVehicleInputEnvelope = {
  data: Array<RouteVehicleVacantAlertCreateManyVehicleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteVehicleVacantAlertCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RouteVehicleVacantAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteVehicleVacantAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteVehicleVacantAlertCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteVehicleVacantAlertCreateManyRouteInputEnvelope>;
};

export type RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput = {
  connect?: InputMaybe<Array<RouteVehicleVacantAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteVehicleVacantAlertCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<RouteVehicleVacantAlertCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<RouteVehicleVacantAlertCreateManyVehicleInputEnvelope>;
};

export type RouteVehicleVacantAlertCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<RouteVehicleVacantAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteVehicleVacantAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteVehicleVacantAlertCreateWithoutAlertInput>;
};

export type RouteVehicleVacantAlertCreateOrConnectWithoutAlertInput = {
  create: RouteVehicleVacantAlertCreateWithoutAlertInput;
  where: RouteVehicleVacantAlertWhereUniqueInput;
};

export type RouteVehicleVacantAlertCreateOrConnectWithoutRouteInput = {
  create: RouteVehicleVacantAlertCreateWithoutRouteInput;
  where: RouteVehicleVacantAlertWhereUniqueInput;
};

export type RouteVehicleVacantAlertCreateOrConnectWithoutVehicleInput = {
  create: RouteVehicleVacantAlertCreateWithoutVehicleInput;
  where: RouteVehicleVacantAlertWhereUniqueInput;
};

export type RouteVehicleVacantAlertCreateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  route: RouteCreateNestedOneWithoutRouteVehicleVacantAlertsInput;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutRouteVehicleVacantAlertsInput>;
};

export type RouteVehicleVacantAlertCreateWithoutRouteInput = {
  alert: AlertCreateNestedOneWithoutRouteVehicleVacantAlertInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutRouteVehicleVacantAlertsInput>;
};

export type RouteVehicleVacantAlertCreateWithoutVehicleInput = {
  alert: AlertCreateNestedOneWithoutRouteVehicleVacantAlertInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route: RouteCreateNestedOneWithoutRouteVehicleVacantAlertsInput;
};

export type RouteVehicleVacantAlertListRelationFilter = {
  every?: InputMaybe<RouteVehicleVacantAlertWhereInput>;
  none?: InputMaybe<RouteVehicleVacantAlertWhereInput>;
  some?: InputMaybe<RouteVehicleVacantAlertWhereInput>;
};

export type RouteVehicleVacantAlertRelationFilter = {
  is?: InputMaybe<RouteVehicleVacantAlertWhereInput>;
  isNot?: InputMaybe<RouteVehicleVacantAlertWhereInput>;
};

export type RouteVehicleVacantAlertScalarWhereInput = {
  AND?: InputMaybe<Array<RouteVehicleVacantAlertScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteVehicleVacantAlertScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteVehicleVacantAlertScalarWhereInput>>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  routeId?: InputMaybe<StringFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type RouteVehicleVacantAlertUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RouteVehicleVacantAlertUpdateManyWithWhereWithoutRouteInput = {
  data: RouteVehicleVacantAlertUpdateManyMutationInput;
  where: RouteVehicleVacantAlertScalarWhereInput;
};

export type RouteVehicleVacantAlertUpdateManyWithWhereWithoutVehicleInput = {
  data: RouteVehicleVacantAlertUpdateManyMutationInput;
  where: RouteVehicleVacantAlertScalarWhereInput;
};

export type RouteVehicleVacantAlertUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<RouteVehicleVacantAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteVehicleVacantAlertCreateOrConnectWithoutRouteInput>
  >;
  create?: InputMaybe<Array<RouteVehicleVacantAlertCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RouteVehicleVacantAlertCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RouteVehicleVacantAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteVehicleVacantAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteVehicleVacantAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteVehicleVacantAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteVehicleVacantAlertUpdateWithWhereUniqueWithoutRouteInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteVehicleVacantAlertUpdateManyWithWhereWithoutRouteInput>
  >;
  upsert?: InputMaybe<
    Array<RouteVehicleVacantAlertUpsertWithWhereUniqueWithoutRouteInput>
  >;
};

export type RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput = {
  connect?: InputMaybe<Array<RouteVehicleVacantAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteVehicleVacantAlertCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<RouteVehicleVacantAlertCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<RouteVehicleVacantAlertCreateManyVehicleInputEnvelope>;
  delete?: InputMaybe<Array<RouteVehicleVacantAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteVehicleVacantAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteVehicleVacantAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteVehicleVacantAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteVehicleVacantAlertUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteVehicleVacantAlertUpdateManyWithWhereWithoutVehicleInput>
  >;
  upsert?: InputMaybe<
    Array<RouteVehicleVacantAlertUpsertWithWhereUniqueWithoutVehicleInput>
  >;
};

export type RouteVehicleVacantAlertUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<RouteVehicleVacantAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RouteVehicleVacantAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<RouteVehicleVacantAlertCreateWithoutAlertInput>;
  delete?: InputMaybe<RouteVehicleVacantAlertWhereInput>;
  disconnect?: InputMaybe<RouteVehicleVacantAlertWhereInput>;
  update?: InputMaybe<RouteVehicleVacantAlertUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<RouteVehicleVacantAlertUpsertWithoutAlertInput>;
};

export type RouteVehicleVacantAlertUpdateToOneWithWhereWithoutAlertInput = {
  data: RouteVehicleVacantAlertUpdateWithoutAlertInput;
  where?: InputMaybe<RouteVehicleVacantAlertWhereInput>;
};

export type RouteVehicleVacantAlertUpdateWithWhereUniqueWithoutRouteInput = {
  data: RouteVehicleVacantAlertUpdateWithoutRouteInput;
  where: RouteVehicleVacantAlertWhereUniqueInput;
};

export type RouteVehicleVacantAlertUpdateWithWhereUniqueWithoutVehicleInput = {
  data: RouteVehicleVacantAlertUpdateWithoutVehicleInput;
  where: RouteVehicleVacantAlertWhereUniqueInput;
};

export type RouteVehicleVacantAlertUpdateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteVehicleVacantAlertsNestedInput>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutRouteVehicleVacantAlertsNestedInput>;
};

export type RouteVehicleVacantAlertUpdateWithoutRouteInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutRouteVehicleVacantAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutRouteVehicleVacantAlertsNestedInput>;
};

export type RouteVehicleVacantAlertUpdateWithoutVehicleInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutRouteVehicleVacantAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteUpdateOneRequiredWithoutRouteVehicleVacantAlertsNestedInput>;
};

export type RouteVehicleVacantAlertUpsertWithWhereUniqueWithoutRouteInput = {
  create: RouteVehicleVacantAlertCreateWithoutRouteInput;
  update: RouteVehicleVacantAlertUpdateWithoutRouteInput;
  where: RouteVehicleVacantAlertWhereUniqueInput;
};

export type RouteVehicleVacantAlertUpsertWithWhereUniqueWithoutVehicleInput = {
  create: RouteVehicleVacantAlertCreateWithoutVehicleInput;
  update: RouteVehicleVacantAlertUpdateWithoutVehicleInput;
  where: RouteVehicleVacantAlertWhereUniqueInput;
};

export type RouteVehicleVacantAlertUpsertWithoutAlertInput = {
  create: RouteVehicleVacantAlertCreateWithoutAlertInput;
  update: RouteVehicleVacantAlertUpdateWithoutAlertInput;
  where?: InputMaybe<RouteVehicleVacantAlertWhereInput>;
};

export type RouteVehicleVacantAlertWhereInput = {
  AND?: InputMaybe<Array<RouteVehicleVacantAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteVehicleVacantAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteVehicleVacantAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type RouteVehicleVacantAlertWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteVehicleVacantAlertWhereInput>>;
  NOT?: InputMaybe<Array<RouteVehicleVacantAlertWhereInput>>;
  OR?: InputMaybe<Array<RouteVehicleVacantAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type RouteVehicleWhereInput = {
  AND?: InputMaybe<Array<RouteVehicleWhereInput>>;
  NOT?: InputMaybe<Array<RouteVehicleWhereInput>>;
  OR?: InputMaybe<Array<RouteVehicleWhereInput>>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type RouteVehicleWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteVehicleWhereInput>>;
  NOT?: InputMaybe<Array<RouteVehicleWhereInput>>;
  OR?: InputMaybe<Array<RouteVehicleWhereInput>>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<BoolFilter>;
  precedence?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type RouteWhereInput = {
  AND?: InputMaybe<Array<RouteWhereInput>>;
  NOT?: InputMaybe<Array<RouteWhereInput>>;
  OR?: InputMaybe<Array<RouteWhereInput>>;
  agents?: InputMaybe<RouteAgentListRelationFilter>;
  busAttendants?: InputMaybe<RouteBusAttendantListRelationFilter>;
  code?: InputMaybe<StringFilter>;
  directionality?: InputMaybe<EnumRouteDirectionalityFilter>;
  displayName?: InputMaybe<StringFilter>;
  drivers?: InputMaybe<RouteDriverListRelationFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  estimatedDistance?: InputMaybe<FloatFilter>;
  estimatedTime?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  isAutoActive?: InputMaybe<BoolFilter>;
  leaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<IntFilter>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  plannedEndTime?: InputMaybe<DateTimeFilter>;
  plannedSchoolStopDuration?: InputMaybe<IntFilter>;
  plannedStartTime?: InputMaybe<DateTimeFilter>;
  plannedStudentStopDuration?: InputMaybe<IntFilter>;
  reference?: InputMaybe<StringFilter>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertListRelationFilter>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertListRelationFilter>;
  routeDateAlerts?: InputMaybe<RouteDateAlertListRelationFilter>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertListRelationFilter>;
  routeGroup?: InputMaybe<RouteGroupRelationFilter>;
  routeGroupId?: InputMaybe<StringFilter>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigListRelationFilter>;
  routeStops?: InputMaybe<RouteStopListRelationFilter>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertListRelationFilter>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertListRelationFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumRouteStatusFilter>;
  suggestedEndTime?: InputMaybe<DateTimeFilter>;
  suggestedStartTime?: InputMaybe<DateTimeFilter>;
  tripEndOffsetMinutes?: InputMaybe<IntFilter>;
  tripStartOffsetMinutes?: InputMaybe<IntFilter>;
  trips?: InputMaybe<TripListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  useDriverVehicle?: InputMaybe<BoolFilter>;
  usesCollectionPoints?: InputMaybe<BoolFilter>;
  vehicles?: InputMaybe<RouteVehicleListRelationFilter>;
};

export type RouteWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteWhereInput>>;
  NOT?: InputMaybe<Array<RouteWhereInput>>;
  OR?: InputMaybe<Array<RouteWhereInput>>;
  agents?: InputMaybe<RouteAgentListRelationFilter>;
  busAttendants?: InputMaybe<RouteBusAttendantListRelationFilter>;
  code?: InputMaybe<StringFilter>;
  directionality?: InputMaybe<EnumRouteDirectionalityFilter>;
  displayName?: InputMaybe<StringFilter>;
  drivers?: InputMaybe<RouteDriverListRelationFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  estimatedDistance?: InputMaybe<FloatFilter>;
  estimatedTime?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAutoActive?: InputMaybe<BoolFilter>;
  leaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<IntFilter>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  plannedEndTime?: InputMaybe<DateTimeFilter>;
  plannedSchoolStopDuration?: InputMaybe<IntFilter>;
  plannedStartTime?: InputMaybe<DateTimeFilter>;
  plannedStudentStopDuration?: InputMaybe<IntFilter>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  routeBusAttendantVacantAlerts?: InputMaybe<RouteBusAttendantVacantAlertListRelationFilter>;
  routeCapacityExceededAlerts?: InputMaybe<RouteCapacityExceededAlertListRelationFilter>;
  routeDateAlerts?: InputMaybe<RouteDateAlertListRelationFilter>;
  routeDriverVacantAlerts?: InputMaybe<RouteDriverVacantAlertListRelationFilter>;
  routeGroup?: InputMaybe<RouteGroupRelationFilter>;
  routeGroupId?: InputMaybe<StringFilter>;
  routeOptimizationConfigs?: InputMaybe<RouteOptimizationConfigListRelationFilter>;
  routeStops?: InputMaybe<RouteStopListRelationFilter>;
  routeTimeLimitExceededAlerts?: InputMaybe<RouteTimeLimitExceededAlertListRelationFilter>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertListRelationFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumRouteStatusFilter>;
  suggestedEndTime?: InputMaybe<DateTimeFilter>;
  suggestedStartTime?: InputMaybe<DateTimeFilter>;
  tripEndOffsetMinutes?: InputMaybe<IntFilter>;
  tripStartOffsetMinutes?: InputMaybe<IntFilter>;
  trips?: InputMaybe<TripListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  useDriverVehicle?: InputMaybe<BoolFilter>;
  usesCollectionPoints?: InputMaybe<BoolFilter>;
  vehicles?: InputMaybe<RouteVehicleListRelationFilter>;
};

export type RouteZoneSla = {
  __typename?: "RouteZoneSla";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  from: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  limit: Scalars["Int"]["output"];
  routeOptimizationConfig?: Maybe<RouteOptimizationConfig>;
  routeOptimizationConfigId?: Maybe<Scalars["String"]["output"]>;
  to: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type RouteZoneSlaCreateManyRouteOptimizationConfigInput = {
  from: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  to: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteZoneSlaCreateManyRouteOptimizationConfigInputEnvelope = {
  data: Array<RouteZoneSlaCreateManyRouteOptimizationConfigInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RouteZoneSlaCreateNestedManyWithoutRouteOptimizationConfigInput = {
  connect?: InputMaybe<Array<RouteZoneSlaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteZoneSlaCreateOrConnectWithoutRouteOptimizationConfigInput>
  >;
  create?: InputMaybe<
    Array<RouteZoneSlaCreateWithoutRouteOptimizationConfigInput>
  >;
  createMany?: InputMaybe<RouteZoneSlaCreateManyRouteOptimizationConfigInputEnvelope>;
};

export type RouteZoneSlaCreateOrConnectWithoutRouteOptimizationConfigInput = {
  create: RouteZoneSlaCreateWithoutRouteOptimizationConfigInput;
  where: RouteZoneSlaWhereUniqueInput;
};

export type RouteZoneSlaCreateWithoutRouteOptimizationConfigInput = {
  from: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  to: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteZoneSlaInput = {
  from: Scalars["Int"]["input"];
  limit: Scalars["Int"]["input"];
  to: Scalars["Int"]["input"];
};

export type RouteZoneSlaListRelationFilter = {
  every?: InputMaybe<RouteZoneSlaWhereInput>;
  none?: InputMaybe<RouteZoneSlaWhereInput>;
  some?: InputMaybe<RouteZoneSlaWhereInput>;
};

export type RouteZoneSlaScalarWhereInput = {
  AND?: InputMaybe<Array<RouteZoneSlaScalarWhereInput>>;
  NOT?: InputMaybe<Array<RouteZoneSlaScalarWhereInput>>;
  OR?: InputMaybe<Array<RouteZoneSlaScalarWhereInput>>;
  from?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  limit?: InputMaybe<IntFilter>;
  routeOptimizationConfigId?: InputMaybe<StringFilter>;
  to?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteZoneSlaUpdateManyMutationInput = {
  from?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  to?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteZoneSlaUpdateManyWithWhereWithoutRouteOptimizationConfigInput =
  {
    data: RouteZoneSlaUpdateManyMutationInput;
    where: RouteZoneSlaScalarWhereInput;
  };

export type RouteZoneSlaUpdateManyWithoutRouteOptimizationConfigNestedInput = {
  connect?: InputMaybe<Array<RouteZoneSlaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<RouteZoneSlaCreateOrConnectWithoutRouteOptimizationConfigInput>
  >;
  create?: InputMaybe<
    Array<RouteZoneSlaCreateWithoutRouteOptimizationConfigInput>
  >;
  createMany?: InputMaybe<RouteZoneSlaCreateManyRouteOptimizationConfigInputEnvelope>;
  delete?: InputMaybe<Array<RouteZoneSlaWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RouteZoneSlaScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RouteZoneSlaWhereUniqueInput>>;
  set?: InputMaybe<Array<RouteZoneSlaWhereUniqueInput>>;
  update?: InputMaybe<
    Array<RouteZoneSlaUpdateWithWhereUniqueWithoutRouteOptimizationConfigInput>
  >;
  updateMany?: InputMaybe<
    Array<RouteZoneSlaUpdateManyWithWhereWithoutRouteOptimizationConfigInput>
  >;
  upsert?: InputMaybe<
    Array<RouteZoneSlaUpsertWithWhereUniqueWithoutRouteOptimizationConfigInput>
  >;
};

export type RouteZoneSlaUpdateWithWhereUniqueWithoutRouteOptimizationConfigInput =
  {
    data: RouteZoneSlaUpdateWithoutRouteOptimizationConfigInput;
    where: RouteZoneSlaWhereUniqueInput;
  };

export type RouteZoneSlaUpdateWithoutRouteOptimizationConfigInput = {
  from?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  to?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RouteZoneSlaUpsertWithWhereUniqueWithoutRouteOptimizationConfigInput =
  {
    create: RouteZoneSlaCreateWithoutRouteOptimizationConfigInput;
    update: RouteZoneSlaUpdateWithoutRouteOptimizationConfigInput;
    where: RouteZoneSlaWhereUniqueInput;
  };

export type RouteZoneSlaWhereInput = {
  AND?: InputMaybe<Array<RouteZoneSlaWhereInput>>;
  NOT?: InputMaybe<Array<RouteZoneSlaWhereInput>>;
  OR?: InputMaybe<Array<RouteZoneSlaWhereInput>>;
  from?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  limit?: InputMaybe<IntFilter>;
  routeOptimizationConfig?: InputMaybe<RouteOptimizationConfigRelationFilter>;
  routeOptimizationConfigId?: InputMaybe<StringFilter>;
  to?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RouteZoneSlaWhereUniqueInput = {
  AND?: InputMaybe<Array<RouteZoneSlaWhereInput>>;
  NOT?: InputMaybe<Array<RouteZoneSlaWhereInput>>;
  OR?: InputMaybe<Array<RouteZoneSlaWhereInput>>;
  from?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<IntFilter>;
  routeOptimizationConfig?: InputMaybe<RouteOptimizationConfigRelationFilter>;
  routeOptimizationConfigId?: InputMaybe<StringFilter>;
  to?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SafetyEventWhereInput = {
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  eventType?: InputMaybe<VehicleSafetyEventCause>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Schedule = {
  __typename?: "Schedule";
  _count: ScheduleCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  clonedFrom?: Maybe<Schedule>;
  clonedTo?: Maybe<Schedule>;
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  daysOfMonth?: Maybe<Array<Scalars["Int"]["output"]>>;
  daysOfWeek?: Maybe<Array<Scalars["String"]["output"]>>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  endDateUTC?: Maybe<Scalars["DateTime"]["output"]>;
  endTime?: Maybe<Scalars["DateTime"]["output"]>;
  filters?: Maybe<Array<ScheduleFilter>>;
  id: Scalars["String"]["output"];
  repeatEvery: Scalars["Int"]["output"];
  repeatMode: ScheduleRepeatMode;
  repetition?: Maybe<ScheduleRepetition>;
  scheduleExclusions?: Maybe<Array<ScheduleExclusion>>;
  scheduleGroupStudent?: Maybe<ScheduleGroupStudent>;
  scheduleGroups?: Maybe<Array<ScheduleGroupSchedule>>;
  scheduleId?: Maybe<Scalars["String"]["output"]>;
  semester?: Maybe<Semester>;
  semesterId?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  startDateUTC?: Maybe<Scalars["DateTime"]["output"]>;
  startTime?: Maybe<Scalars["DateTime"]["output"]>;
  state: ScheduleState;
  title: Scalars["String"]["output"];
  titleAr?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ScheduleCount = {
  __typename?: "ScheduleCount";
  filters: Scalars["Int"]["output"];
  scheduleExclusions: Scalars["Int"]["output"];
  scheduleGroups: Scalars["Int"]["output"];
};

export type ScheduleCreateManyCountryInput = {
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  scheduleId?: InputMaybe<Scalars["String"]["input"]>;
  semesterId?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state: ScheduleState;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleCreateManyCountryInputEnvelope = {
  data: Array<ScheduleCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleCreateManySemesterInput = {
  countryId: Scalars["String"]["input"];
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  scheduleId?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state: ScheduleState;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleCreateManySemesterInputEnvelope = {
  data: Array<ScheduleCreateManySemesterInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<ScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<ScheduleCreateWithoutCountryInput>>;
  createMany?: InputMaybe<ScheduleCreateManyCountryInputEnvelope>;
};

export type ScheduleCreateNestedManyWithoutSemesterInput = {
  connect?: InputMaybe<Array<ScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleCreateOrConnectWithoutSemesterInput>
  >;
  create?: InputMaybe<Array<ScheduleCreateWithoutSemesterInput>>;
  createMany?: InputMaybe<ScheduleCreateManySemesterInputEnvelope>;
};

export type ScheduleCreateNestedOneWithoutClonedFromInput = {
  connect?: InputMaybe<ScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleCreateOrConnectWithoutClonedFromInput>;
  create?: InputMaybe<ScheduleCreateWithoutClonedFromInput>;
};

export type ScheduleCreateNestedOneWithoutClonedToInput = {
  connect?: InputMaybe<ScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleCreateOrConnectWithoutClonedToInput>;
  create?: InputMaybe<ScheduleCreateWithoutClonedToInput>;
};

export type ScheduleCreateNestedOneWithoutScheduleExclusionsInput = {
  connect?: InputMaybe<ScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleCreateOrConnectWithoutScheduleExclusionsInput>;
  create?: InputMaybe<ScheduleCreateWithoutScheduleExclusionsInput>;
};

export type ScheduleCreateNestedOneWithoutScheduleGroupStudentInput = {
  connect?: InputMaybe<ScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleCreateOrConnectWithoutScheduleGroupStudentInput>;
  create?: InputMaybe<ScheduleCreateWithoutScheduleGroupStudentInput>;
};

export type ScheduleCreateNestedOneWithoutScheduleGroupsInput = {
  connect?: InputMaybe<ScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleCreateOrConnectWithoutScheduleGroupsInput>;
  create?: InputMaybe<ScheduleCreateWithoutScheduleGroupsInput>;
};

export type ScheduleCreateOrConnectWithoutClonedFromInput = {
  create: ScheduleCreateWithoutClonedFromInput;
  where: ScheduleWhereUniqueInput;
};

export type ScheduleCreateOrConnectWithoutClonedToInput = {
  create: ScheduleCreateWithoutClonedToInput;
  where: ScheduleWhereUniqueInput;
};

export type ScheduleCreateOrConnectWithoutCountryInput = {
  create: ScheduleCreateWithoutCountryInput;
  where: ScheduleWhereUniqueInput;
};

export type ScheduleCreateOrConnectWithoutScheduleExclusionsInput = {
  create: ScheduleCreateWithoutScheduleExclusionsInput;
  where: ScheduleWhereUniqueInput;
};

export type ScheduleCreateOrConnectWithoutScheduleGroupStudentInput = {
  create: ScheduleCreateWithoutScheduleGroupStudentInput;
  where: ScheduleWhereUniqueInput;
};

export type ScheduleCreateOrConnectWithoutScheduleGroupsInput = {
  create: ScheduleCreateWithoutScheduleGroupsInput;
  where: ScheduleWhereUniqueInput;
};

export type ScheduleCreateOrConnectWithoutSemesterInput = {
  create: ScheduleCreateWithoutSemesterInput;
  where: ScheduleWhereUniqueInput;
};

export type ScheduleCreateWithoutClonedFromInput = {
  clonedTo?: InputMaybe<ScheduleCreateNestedOneWithoutClonedFromInput>;
  country: CountryCreateNestedOneWithoutSchedulesInput;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterCreateNestedManyWithoutScheduleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionCreateNestedOneWithoutScheduleInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentCreateNestedOneWithoutCustomScheduleInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutSchedulesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state: ScheduleState;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleCreateWithoutClonedToInput = {
  clonedFrom?: InputMaybe<ScheduleCreateNestedOneWithoutClonedToInput>;
  country: CountryCreateNestedOneWithoutSchedulesInput;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterCreateNestedManyWithoutScheduleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionCreateNestedOneWithoutScheduleInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentCreateNestedOneWithoutCustomScheduleInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutSchedulesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state: ScheduleState;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleCreateWithoutCountryInput = {
  clonedFrom?: InputMaybe<ScheduleCreateNestedOneWithoutClonedToInput>;
  clonedTo?: InputMaybe<ScheduleCreateNestedOneWithoutClonedFromInput>;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterCreateNestedManyWithoutScheduleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionCreateNestedOneWithoutScheduleInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentCreateNestedOneWithoutCustomScheduleInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutSchedulesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state: ScheduleState;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleCreateWithoutScheduleExclusionsInput = {
  clonedFrom?: InputMaybe<ScheduleCreateNestedOneWithoutClonedToInput>;
  clonedTo?: InputMaybe<ScheduleCreateNestedOneWithoutClonedFromInput>;
  country: CountryCreateNestedOneWithoutSchedulesInput;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterCreateNestedManyWithoutScheduleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionCreateNestedOneWithoutScheduleInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentCreateNestedOneWithoutCustomScheduleInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutSchedulesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state: ScheduleState;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleCreateWithoutScheduleGroupStudentInput = {
  clonedFrom?: InputMaybe<ScheduleCreateNestedOneWithoutClonedToInput>;
  clonedTo?: InputMaybe<ScheduleCreateNestedOneWithoutClonedFromInput>;
  country: CountryCreateNestedOneWithoutSchedulesInput;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterCreateNestedManyWithoutScheduleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionCreateNestedOneWithoutScheduleInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutSchedulesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state: ScheduleState;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleCreateWithoutScheduleGroupsInput = {
  clonedFrom?: InputMaybe<ScheduleCreateNestedOneWithoutClonedToInput>;
  clonedTo?: InputMaybe<ScheduleCreateNestedOneWithoutClonedFromInput>;
  country: CountryCreateNestedOneWithoutSchedulesInput;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterCreateNestedManyWithoutScheduleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionCreateNestedOneWithoutScheduleInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentCreateNestedOneWithoutCustomScheduleInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutSchedulesInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state: ScheduleState;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleCreateWithoutSemesterInput = {
  clonedFrom?: InputMaybe<ScheduleCreateNestedOneWithoutClonedToInput>;
  clonedTo?: InputMaybe<ScheduleCreateNestedOneWithoutClonedFromInput>;
  country: CountryCreateNestedOneWithoutSchedulesInput;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterCreateNestedManyWithoutScheduleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionCreateNestedOneWithoutScheduleInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentCreateNestedOneWithoutCustomScheduleInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state: ScheduleState;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleException = {
  __typename?: "ScheduleException";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  scheduleGroup?: Maybe<ScheduleGroup>;
  scheduleGroupId?: Maybe<Scalars["String"]["output"]>;
  scheduleGroupStudent?: Maybe<ScheduleGroupStudent>;
  scheduleGroupStudentId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ScheduleExceptionCreateManyScheduleGroupInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroupStudentId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExceptionCreateManyScheduleGroupInputEnvelope = {
  data: Array<ScheduleExceptionCreateManyScheduleGroupInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleExceptionCreateManyScheduleGroupStudentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExceptionCreateManyScheduleGroupStudentInputEnvelope = {
  data: Array<ScheduleExceptionCreateManyScheduleGroupStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleExceptionCreateNestedManyWithoutScheduleGroupInput = {
  connect?: InputMaybe<Array<ScheduleExceptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleExceptionCreateOrConnectWithoutScheduleGroupInput>
  >;
  create?: InputMaybe<Array<ScheduleExceptionCreateWithoutScheduleGroupInput>>;
  createMany?: InputMaybe<ScheduleExceptionCreateManyScheduleGroupInputEnvelope>;
};

export type ScheduleExceptionCreateNestedManyWithoutScheduleGroupStudentInput =
  {
    connect?: InputMaybe<Array<ScheduleExceptionWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<ScheduleExceptionCreateOrConnectWithoutScheduleGroupStudentInput>
    >;
    create?: InputMaybe<
      Array<ScheduleExceptionCreateWithoutScheduleGroupStudentInput>
    >;
    createMany?: InputMaybe<ScheduleExceptionCreateManyScheduleGroupStudentInputEnvelope>;
  };

export type ScheduleExceptionCreateOrConnectWithoutScheduleGroupInput = {
  create: ScheduleExceptionCreateWithoutScheduleGroupInput;
  where: ScheduleExceptionWhereUniqueInput;
};

export type ScheduleExceptionCreateOrConnectWithoutScheduleGroupStudentInput = {
  create: ScheduleExceptionCreateWithoutScheduleGroupStudentInput;
  where: ScheduleExceptionWhereUniqueInput;
};

export type ScheduleExceptionCreateWithoutScheduleGroupInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentCreateNestedOneWithoutStudentScheduleExceptionsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExceptionCreateWithoutScheduleGroupStudentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutExceptionsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExceptionListRelationFilter = {
  every?: InputMaybe<ScheduleExceptionWhereInput>;
  none?: InputMaybe<ScheduleExceptionWhereInput>;
  some?: InputMaybe<ScheduleExceptionWhereInput>;
};

export type ScheduleExceptionScalarWhereInput = {
  AND?: InputMaybe<Array<ScheduleExceptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleExceptionScalarWhereInput>>;
  OR?: InputMaybe<Array<ScheduleExceptionScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  scheduleGroupStudentId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleExceptionUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExceptionUpdateManyWithWhereWithoutScheduleGroupInput = {
  data: ScheduleExceptionUpdateManyMutationInput;
  where: ScheduleExceptionScalarWhereInput;
};

export type ScheduleExceptionUpdateManyWithWhereWithoutScheduleGroupStudentInput =
  {
    data: ScheduleExceptionUpdateManyMutationInput;
    where: ScheduleExceptionScalarWhereInput;
  };

export type ScheduleExceptionUpdateManyWithoutScheduleGroupNestedInput = {
  connect?: InputMaybe<Array<ScheduleExceptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleExceptionCreateOrConnectWithoutScheduleGroupInput>
  >;
  create?: InputMaybe<Array<ScheduleExceptionCreateWithoutScheduleGroupInput>>;
  createMany?: InputMaybe<ScheduleExceptionCreateManyScheduleGroupInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleExceptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleExceptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleExceptionWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleExceptionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleExceptionUpdateWithWhereUniqueWithoutScheduleGroupInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleExceptionUpdateManyWithWhereWithoutScheduleGroupInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleExceptionUpsertWithWhereUniqueWithoutScheduleGroupInput>
  >;
};

export type ScheduleExceptionUpdateManyWithoutScheduleGroupStudentNestedInput =
  {
    connect?: InputMaybe<Array<ScheduleExceptionWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<ScheduleExceptionCreateOrConnectWithoutScheduleGroupStudentInput>
    >;
    create?: InputMaybe<
      Array<ScheduleExceptionCreateWithoutScheduleGroupStudentInput>
    >;
    createMany?: InputMaybe<ScheduleExceptionCreateManyScheduleGroupStudentInputEnvelope>;
    delete?: InputMaybe<Array<ScheduleExceptionWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<ScheduleExceptionScalarWhereInput>>;
    disconnect?: InputMaybe<Array<ScheduleExceptionWhereUniqueInput>>;
    set?: InputMaybe<Array<ScheduleExceptionWhereUniqueInput>>;
    update?: InputMaybe<
      Array<ScheduleExceptionUpdateWithWhereUniqueWithoutScheduleGroupStudentInput>
    >;
    updateMany?: InputMaybe<
      Array<ScheduleExceptionUpdateManyWithWhereWithoutScheduleGroupStudentInput>
    >;
    upsert?: InputMaybe<
      Array<ScheduleExceptionUpsertWithWhereUniqueWithoutScheduleGroupStudentInput>
    >;
  };

export type ScheduleExceptionUpdateWithWhereUniqueWithoutScheduleGroupInput = {
  data: ScheduleExceptionUpdateWithoutScheduleGroupInput;
  where: ScheduleExceptionWhereUniqueInput;
};

export type ScheduleExceptionUpdateWithWhereUniqueWithoutScheduleGroupStudentInput =
  {
    data: ScheduleExceptionUpdateWithoutScheduleGroupStudentInput;
    where: ScheduleExceptionWhereUniqueInput;
  };

export type ScheduleExceptionUpdateWithoutScheduleGroupInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentUpdateOneWithoutStudentScheduleExceptionsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExceptionUpdateWithoutScheduleGroupStudentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutExceptionsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExceptionUpsertWithWhereUniqueWithoutScheduleGroupInput = {
  create: ScheduleExceptionCreateWithoutScheduleGroupInput;
  update: ScheduleExceptionUpdateWithoutScheduleGroupInput;
  where: ScheduleExceptionWhereUniqueInput;
};

export type ScheduleExceptionUpsertWithWhereUniqueWithoutScheduleGroupStudentInput =
  {
    create: ScheduleExceptionCreateWithoutScheduleGroupStudentInput;
    update: ScheduleExceptionUpdateWithoutScheduleGroupStudentInput;
    where: ScheduleExceptionWhereUniqueInput;
  };

export type ScheduleExceptionWhereInput = {
  AND?: InputMaybe<Array<ScheduleExceptionWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleExceptionWhereInput>>;
  OR?: InputMaybe<Array<ScheduleExceptionWhereInput>>;
  id?: InputMaybe<StringFilter>;
  scheduleGroup?: InputMaybe<ScheduleGroupRelationFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentRelationFilter>;
  scheduleGroupStudentId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleExceptionWhereUniqueInput = {
  AND?: InputMaybe<Array<ScheduleExceptionWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleExceptionWhereInput>>;
  OR?: InputMaybe<Array<ScheduleExceptionWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup?: InputMaybe<ScheduleGroupRelationFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentRelationFilter>;
  scheduleGroupStudentId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleExclusion = {
  __typename?: "ScheduleExclusion";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  reasonAr?: Maybe<Scalars["String"]["output"]>;
  schedule?: Maybe<Schedule>;
  scheduleGroup?: Maybe<ScheduleGroup>;
  scheduleGroupId?: Maybe<Scalars["String"]["output"]>;
  scheduleId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistration?: Maybe<SchoolRegistration>;
  schoolRegistrationId?: Maybe<Scalars["String"]["output"]>;
  startDate: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ScheduleExclusionCreateManyCountryInput = {
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  scheduleId?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionCreateManyCountryInputEnvelope = {
  data: Array<ScheduleExclusionCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleExclusionCreateManyScheduleGroupInput = {
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  scheduleId?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionCreateManyScheduleGroupInputEnvelope = {
  data: Array<ScheduleExclusionCreateManyScheduleGroupInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleExclusionCreateManyScheduleInput = {
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionCreateManyScheduleInputEnvelope = {
  data: Array<ScheduleExclusionCreateManyScheduleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleExclusionCreateManySchoolRegistrationInput = {
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  scheduleId?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionCreateManySchoolRegistrationInputEnvelope = {
  data: Array<ScheduleExclusionCreateManySchoolRegistrationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleExclusionCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleExclusionCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<ScheduleExclusionCreateWithoutCountryInput>>;
  createMany?: InputMaybe<ScheduleExclusionCreateManyCountryInputEnvelope>;
};

export type ScheduleExclusionCreateNestedManyWithoutScheduleGroupInput = {
  connect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleExclusionCreateOrConnectWithoutScheduleGroupInput>
  >;
  create?: InputMaybe<Array<ScheduleExclusionCreateWithoutScheduleGroupInput>>;
  createMany?: InputMaybe<ScheduleExclusionCreateManyScheduleGroupInputEnvelope>;
};

export type ScheduleExclusionCreateNestedManyWithoutScheduleInput = {
  connect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleExclusionCreateOrConnectWithoutScheduleInput>
  >;
  create?: InputMaybe<Array<ScheduleExclusionCreateWithoutScheduleInput>>;
  createMany?: InputMaybe<ScheduleExclusionCreateManyScheduleInputEnvelope>;
};

export type ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput = {
  connect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleExclusionCreateOrConnectWithoutSchoolRegistrationInput>
  >;
  create?: InputMaybe<
    Array<ScheduleExclusionCreateWithoutSchoolRegistrationInput>
  >;
  createMany?: InputMaybe<ScheduleExclusionCreateManySchoolRegistrationInputEnvelope>;
};

export type ScheduleExclusionCreateOrConnectWithoutCountryInput = {
  create: ScheduleExclusionCreateWithoutCountryInput;
  where: ScheduleExclusionWhereUniqueInput;
};

export type ScheduleExclusionCreateOrConnectWithoutScheduleGroupInput = {
  create: ScheduleExclusionCreateWithoutScheduleGroupInput;
  where: ScheduleExclusionWhereUniqueInput;
};

export type ScheduleExclusionCreateOrConnectWithoutScheduleInput = {
  create: ScheduleExclusionCreateWithoutScheduleInput;
  where: ScheduleExclusionWhereUniqueInput;
};

export type ScheduleExclusionCreateOrConnectWithoutSchoolRegistrationInput = {
  create: ScheduleExclusionCreateWithoutSchoolRegistrationInput;
  where: ScheduleExclusionWhereUniqueInput;
};

export type ScheduleExclusionCreateWithoutCountryInput = {
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  schedule?: InputMaybe<ScheduleCreateNestedOneWithoutScheduleExclusionsInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutScheduleExclusionsInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedOneWithoutScheduleExclusionsInput>;
  startDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionCreateWithoutScheduleGroupInput = {
  country?: InputMaybe<CountryCreateNestedOneWithoutScheduleExclusionsInput>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  schedule?: InputMaybe<ScheduleCreateNestedOneWithoutScheduleExclusionsInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedOneWithoutScheduleExclusionsInput>;
  startDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionCreateWithoutScheduleInput = {
  country?: InputMaybe<CountryCreateNestedOneWithoutScheduleExclusionsInput>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutScheduleExclusionsInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedOneWithoutScheduleExclusionsInput>;
  startDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionCreateWithoutSchoolRegistrationInput = {
  country?: InputMaybe<CountryCreateNestedOneWithoutScheduleExclusionsInput>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  schedule?: InputMaybe<ScheduleCreateNestedOneWithoutScheduleExclusionsInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutScheduleExclusionsInput>;
  startDate: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionListRelationFilter = {
  every?: InputMaybe<ScheduleExclusionWhereInput>;
  none?: InputMaybe<ScheduleExclusionWhereInput>;
  some?: InputMaybe<ScheduleExclusionWhereInput>;
};

export type ScheduleExclusionScalarWhereInput = {
  AND?: InputMaybe<Array<ScheduleExclusionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleExclusionScalarWhereInput>>;
  OR?: InputMaybe<Array<ScheduleExclusionScalarWhereInput>>;
  countryId?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringFilter>;
  reasonAr?: InputMaybe<StringFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleExclusionUpdateManyMutationInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionUpdateManyWithWhereWithoutCountryInput = {
  data: ScheduleExclusionUpdateManyMutationInput;
  where: ScheduleExclusionScalarWhereInput;
};

export type ScheduleExclusionUpdateManyWithWhereWithoutScheduleGroupInput = {
  data: ScheduleExclusionUpdateManyMutationInput;
  where: ScheduleExclusionScalarWhereInput;
};

export type ScheduleExclusionUpdateManyWithWhereWithoutScheduleInput = {
  data: ScheduleExclusionUpdateManyMutationInput;
  where: ScheduleExclusionScalarWhereInput;
};

export type ScheduleExclusionUpdateManyWithWhereWithoutSchoolRegistrationInput =
  {
    data: ScheduleExclusionUpdateManyMutationInput;
    where: ScheduleExclusionScalarWhereInput;
  };

export type ScheduleExclusionUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleExclusionCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<ScheduleExclusionCreateWithoutCountryInput>>;
  createMany?: InputMaybe<ScheduleExclusionCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleExclusionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleExclusionUpdateWithWhereUniqueWithoutCountryInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleExclusionUpdateManyWithWhereWithoutCountryInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleExclusionUpsertWithWhereUniqueWithoutCountryInput>
  >;
};

export type ScheduleExclusionUpdateManyWithoutScheduleGroupNestedInput = {
  connect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleExclusionCreateOrConnectWithoutScheduleGroupInput>
  >;
  create?: InputMaybe<Array<ScheduleExclusionCreateWithoutScheduleGroupInput>>;
  createMany?: InputMaybe<ScheduleExclusionCreateManyScheduleGroupInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleExclusionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleExclusionUpdateWithWhereUniqueWithoutScheduleGroupInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleExclusionUpdateManyWithWhereWithoutScheduleGroupInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleExclusionUpsertWithWhereUniqueWithoutScheduleGroupInput>
  >;
};

export type ScheduleExclusionUpdateManyWithoutScheduleNestedInput = {
  connect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleExclusionCreateOrConnectWithoutScheduleInput>
  >;
  create?: InputMaybe<Array<ScheduleExclusionCreateWithoutScheduleInput>>;
  createMany?: InputMaybe<ScheduleExclusionCreateManyScheduleInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleExclusionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleExclusionUpdateWithWhereUniqueWithoutScheduleInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleExclusionUpdateManyWithWhereWithoutScheduleInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleExclusionUpsertWithWhereUniqueWithoutScheduleInput>
  >;
};

export type ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput = {
  connect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleExclusionCreateOrConnectWithoutSchoolRegistrationInput>
  >;
  create?: InputMaybe<
    Array<ScheduleExclusionCreateWithoutSchoolRegistrationInput>
  >;
  createMany?: InputMaybe<ScheduleExclusionCreateManySchoolRegistrationInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleExclusionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleExclusionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleExclusionUpdateWithWhereUniqueWithoutSchoolRegistrationInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleExclusionUpdateManyWithWhereWithoutSchoolRegistrationInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleExclusionUpsertWithWhereUniqueWithoutSchoolRegistrationInput>
  >;
};

export type ScheduleExclusionUpdateWithWhereUniqueWithoutCountryInput = {
  data: ScheduleExclusionUpdateWithoutCountryInput;
  where: ScheduleExclusionWhereUniqueInput;
};

export type ScheduleExclusionUpdateWithWhereUniqueWithoutScheduleGroupInput = {
  data: ScheduleExclusionUpdateWithoutScheduleGroupInput;
  where: ScheduleExclusionWhereUniqueInput;
};

export type ScheduleExclusionUpdateWithWhereUniqueWithoutScheduleInput = {
  data: ScheduleExclusionUpdateWithoutScheduleInput;
  where: ScheduleExclusionWhereUniqueInput;
};

export type ScheduleExclusionUpdateWithWhereUniqueWithoutSchoolRegistrationInput =
  {
    data: ScheduleExclusionUpdateWithoutSchoolRegistrationInput;
    where: ScheduleExclusionWhereUniqueInput;
  };

export type ScheduleExclusionUpdateWithoutCountryInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  schedule?: InputMaybe<ScheduleUpdateOneWithoutScheduleExclusionsNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutScheduleExclusionsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneWithoutScheduleExclusionsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionUpdateWithoutScheduleGroupInput = {
  country?: InputMaybe<CountryUpdateOneWithoutScheduleExclusionsNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  schedule?: InputMaybe<ScheduleUpdateOneWithoutScheduleExclusionsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneWithoutScheduleExclusionsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionUpdateWithoutScheduleInput = {
  country?: InputMaybe<CountryUpdateOneWithoutScheduleExclusionsNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutScheduleExclusionsNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneWithoutScheduleExclusionsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionUpdateWithoutSchoolRegistrationInput = {
  country?: InputMaybe<CountryUpdateOneWithoutScheduleExclusionsNestedInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  reasonAr?: InputMaybe<Scalars["String"]["input"]>;
  schedule?: InputMaybe<ScheduleUpdateOneWithoutScheduleExclusionsNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutScheduleExclusionsNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleExclusionUpsertWithWhereUniqueWithoutCountryInput = {
  create: ScheduleExclusionCreateWithoutCountryInput;
  update: ScheduleExclusionUpdateWithoutCountryInput;
  where: ScheduleExclusionWhereUniqueInput;
};

export type ScheduleExclusionUpsertWithWhereUniqueWithoutScheduleGroupInput = {
  create: ScheduleExclusionCreateWithoutScheduleGroupInput;
  update: ScheduleExclusionUpdateWithoutScheduleGroupInput;
  where: ScheduleExclusionWhereUniqueInput;
};

export type ScheduleExclusionUpsertWithWhereUniqueWithoutScheduleInput = {
  create: ScheduleExclusionCreateWithoutScheduleInput;
  update: ScheduleExclusionUpdateWithoutScheduleInput;
  where: ScheduleExclusionWhereUniqueInput;
};

export type ScheduleExclusionUpsertWithWhereUniqueWithoutSchoolRegistrationInput =
  {
    create: ScheduleExclusionCreateWithoutSchoolRegistrationInput;
    update: ScheduleExclusionUpdateWithoutSchoolRegistrationInput;
    where: ScheduleExclusionWhereUniqueInput;
  };

export type ScheduleExclusionWhereInput = {
  AND?: InputMaybe<Array<ScheduleExclusionWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleExclusionWhereInput>>;
  OR?: InputMaybe<Array<ScheduleExclusionWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringFilter>;
  reasonAr?: InputMaybe<StringFilter>;
  schedule?: InputMaybe<ScheduleRelationFilter>;
  scheduleGroup?: InputMaybe<ScheduleGroupRelationFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleExclusionWhereUniqueInput = {
  AND?: InputMaybe<Array<ScheduleExclusionWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleExclusionWhereInput>>;
  OR?: InputMaybe<Array<ScheduleExclusionWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<StringFilter>;
  reasonAr?: InputMaybe<StringFilter>;
  schedule?: InputMaybe<ScheduleRelationFilter>;
  scheduleGroup?: InputMaybe<ScheduleGroupRelationFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleFilter = {
  __typename?: "ScheduleFilter";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isRange: Scalars["Boolean"]["output"];
  rangeFrom?: Maybe<Scalars["String"]["output"]>;
  rangeTo?: Maybe<Scalars["String"]["output"]>;
  schedule?: Maybe<Schedule>;
  scheduleId?: Maybe<Scalars["String"]["output"]>;
  type: ScheduleFilterType;
  updatedAt: Scalars["DateTime"]["output"];
  values?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ScheduleFilterCreateManyScheduleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isRange: Scalars["Boolean"]["input"];
  rangeFrom?: InputMaybe<Scalars["String"]["input"]>;
  rangeTo?: InputMaybe<Scalars["String"]["input"]>;
  type: ScheduleFilterType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  values?: InputMaybe<ScheduleFilterCreatevaluesInput>;
};

export type ScheduleFilterCreateManyScheduleInputEnvelope = {
  data: Array<ScheduleFilterCreateManyScheduleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleFilterCreateNestedManyWithoutScheduleInput = {
  connect?: InputMaybe<Array<ScheduleFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleFilterCreateOrConnectWithoutScheduleInput>
  >;
  create?: InputMaybe<Array<ScheduleFilterCreateWithoutScheduleInput>>;
  createMany?: InputMaybe<ScheduleFilterCreateManyScheduleInputEnvelope>;
};

export type ScheduleFilterCreateOrConnectWithoutScheduleInput = {
  create: ScheduleFilterCreateWithoutScheduleInput;
  where: ScheduleFilterWhereUniqueInput;
};

export type ScheduleFilterCreateWithoutScheduleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isRange: Scalars["Boolean"]["input"];
  rangeFrom?: InputMaybe<Scalars["String"]["input"]>;
  rangeTo?: InputMaybe<Scalars["String"]["input"]>;
  type: ScheduleFilterType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  values?: InputMaybe<ScheduleFilterCreatevaluesInput>;
};

export type ScheduleFilterCreatevaluesInput = {
  set: Array<Scalars["String"]["input"]>;
};

export type ScheduleFilterListRelationFilter = {
  every?: InputMaybe<ScheduleFilterWhereInput>;
  none?: InputMaybe<ScheduleFilterWhereInput>;
  some?: InputMaybe<ScheduleFilterWhereInput>;
};

export type ScheduleFilterScalarWhereInput = {
  AND?: InputMaybe<Array<ScheduleFilterScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleFilterScalarWhereInput>>;
  OR?: InputMaybe<Array<ScheduleFilterScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isRange?: InputMaybe<BoolFilter>;
  rangeFrom?: InputMaybe<StringFilter>;
  rangeTo?: InputMaybe<StringFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumScheduleFilterTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  values?: InputMaybe<StringListFilter>;
};

export enum ScheduleFilterType {
  DAYS_OF_WEEK = "DAYS_OF_WEEK",
  DAYS_OF_YEAR = "DAYS_OF_YEAR",
}

export type ScheduleFilterUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isRange?: InputMaybe<Scalars["Boolean"]["input"]>;
  rangeFrom?: InputMaybe<Scalars["String"]["input"]>;
  rangeTo?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<ScheduleFilterType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  values?: InputMaybe<ScheduleFilterUpdatevaluesInput>;
};

export type ScheduleFilterUpdateManyWithWhereWithoutScheduleInput = {
  data: ScheduleFilterUpdateManyMutationInput;
  where: ScheduleFilterScalarWhereInput;
};

export type ScheduleFilterUpdateManyWithoutScheduleNestedInput = {
  connect?: InputMaybe<Array<ScheduleFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleFilterCreateOrConnectWithoutScheduleInput>
  >;
  create?: InputMaybe<Array<ScheduleFilterCreateWithoutScheduleInput>>;
  createMany?: InputMaybe<ScheduleFilterCreateManyScheduleInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleFilterWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleFilterScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleFilterWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleFilterWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleFilterUpdateWithWhereUniqueWithoutScheduleInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleFilterUpdateManyWithWhereWithoutScheduleInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleFilterUpsertWithWhereUniqueWithoutScheduleInput>
  >;
};

export type ScheduleFilterUpdateWithWhereUniqueWithoutScheduleInput = {
  data: ScheduleFilterUpdateWithoutScheduleInput;
  where: ScheduleFilterWhereUniqueInput;
};

export type ScheduleFilterUpdateWithoutScheduleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isRange?: InputMaybe<Scalars["Boolean"]["input"]>;
  rangeFrom?: InputMaybe<Scalars["String"]["input"]>;
  rangeTo?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<ScheduleFilterType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  values?: InputMaybe<ScheduleFilterUpdatevaluesInput>;
};

export type ScheduleFilterUpdatevaluesInput = {
  push?: InputMaybe<Array<Scalars["String"]["input"]>>;
  set?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type ScheduleFilterUpsertWithWhereUniqueWithoutScheduleInput = {
  create: ScheduleFilterCreateWithoutScheduleInput;
  update: ScheduleFilterUpdateWithoutScheduleInput;
  where: ScheduleFilterWhereUniqueInput;
};

export type ScheduleFilterWhereInput = {
  AND?: InputMaybe<Array<ScheduleFilterWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleFilterWhereInput>>;
  OR?: InputMaybe<Array<ScheduleFilterWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isRange?: InputMaybe<BoolFilter>;
  rangeFrom?: InputMaybe<StringFilter>;
  rangeTo?: InputMaybe<StringFilter>;
  schedule?: InputMaybe<ScheduleRelationFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumScheduleFilterTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  values?: InputMaybe<StringListFilter>;
};

export type ScheduleFilterWhereUniqueInput = {
  AND?: InputMaybe<Array<ScheduleFilterWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleFilterWhereInput>>;
  OR?: InputMaybe<Array<ScheduleFilterWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isRange?: InputMaybe<BoolFilter>;
  rangeFrom?: InputMaybe<StringFilter>;
  rangeTo?: InputMaybe<StringFilter>;
  schedule?: InputMaybe<ScheduleRelationFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumScheduleFilterTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  values?: InputMaybe<StringListFilter>;
};

export type ScheduleForBusAttendant = {
  __typename?: "ScheduleForBusAttendant";
  daysOfWeek?: Maybe<Array<Scalars["String"]["output"]>>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  repeatEvery: Scalars["Int"]["output"];
  repeatMode: ScheduleRepeatMode;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  state: ScheduleState;
  title: Scalars["String"]["output"];
  titleAr?: Maybe<Scalars["String"]["output"]>;
};

export type ScheduleForSponsor = {
  __typename?: "ScheduleForSponsor";
  daysOfWeek?: Maybe<Array<Scalars["String"]["output"]>>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  repeatEvery: Scalars["Int"]["output"];
  repeatMode: ScheduleRepeatMode;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  state: ScheduleState;
  title: Scalars["String"]["output"];
  titleAr?: Maybe<Scalars["String"]["output"]>;
};

export type ScheduleGroup = {
  __typename?: "ScheduleGroup";
  _count: ScheduleGroupCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  exceptions?: Maybe<Array<ScheduleException>>;
  excludeDates?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  scheduleExclusions?: Maybe<Array<ScheduleExclusion>>;
  schedules?: Maybe<Array<ScheduleGroupSchedule>>;
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  semester?: Maybe<Semester>;
  semesterId?: Maybe<Scalars["String"]["output"]>;
  students?: Maybe<Array<ScheduleGroupStudent>>;
  title: Scalars["String"]["output"];
  titleAr?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ScheduleGroupCount = {
  __typename?: "ScheduleGroupCount";
  exceptions: Scalars["Int"]["output"];
  scheduleExclusions: Scalars["Int"]["output"];
  schedules: Scalars["Int"]["output"];
  schoolRegistrations: Scalars["Int"]["output"];
  students: Scalars["Int"]["output"];
};

export type ScheduleGroupCreateManySchoolInput = {
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  semesterId?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupCreateManySchoolInputEnvelope = {
  data: Array<ScheduleGroupCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleGroupCreateManySemesterInput = {
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolId?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupCreateManySemesterInputEnvelope = {
  data: Array<ScheduleGroupCreateManySemesterInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleGroupCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<ScheduleGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<ScheduleGroupCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<ScheduleGroupCreateManySchoolInputEnvelope>;
};

export type ScheduleGroupCreateNestedManyWithoutSemesterInput = {
  connect?: InputMaybe<Array<ScheduleGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupCreateOrConnectWithoutSemesterInput>
  >;
  create?: InputMaybe<Array<ScheduleGroupCreateWithoutSemesterInput>>;
  createMany?: InputMaybe<ScheduleGroupCreateManySemesterInputEnvelope>;
};

export type ScheduleGroupCreateNestedOneWithoutExceptionsInput = {
  connect?: InputMaybe<ScheduleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupCreateOrConnectWithoutExceptionsInput>;
  create?: InputMaybe<ScheduleGroupCreateWithoutExceptionsInput>;
};

export type ScheduleGroupCreateNestedOneWithoutScheduleExclusionsInput = {
  connect?: InputMaybe<ScheduleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupCreateOrConnectWithoutScheduleExclusionsInput>;
  create?: InputMaybe<ScheduleGroupCreateWithoutScheduleExclusionsInput>;
};

export type ScheduleGroupCreateNestedOneWithoutSchedulesInput = {
  connect?: InputMaybe<ScheduleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupCreateOrConnectWithoutSchedulesInput>;
  create?: InputMaybe<ScheduleGroupCreateWithoutSchedulesInput>;
};

export type ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput = {
  connect?: InputMaybe<ScheduleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupCreateOrConnectWithoutSchoolRegistrationsInput>;
  create?: InputMaybe<ScheduleGroupCreateWithoutSchoolRegistrationsInput>;
};

export type ScheduleGroupCreateNestedOneWithoutStudentsInput = {
  connect?: InputMaybe<ScheduleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<ScheduleGroupCreateWithoutStudentsInput>;
};

export type ScheduleGroupCreateOrConnectWithoutExceptionsInput = {
  create: ScheduleGroupCreateWithoutExceptionsInput;
  where: ScheduleGroupWhereUniqueInput;
};

export type ScheduleGroupCreateOrConnectWithoutScheduleExclusionsInput = {
  create: ScheduleGroupCreateWithoutScheduleExclusionsInput;
  where: ScheduleGroupWhereUniqueInput;
};

export type ScheduleGroupCreateOrConnectWithoutSchedulesInput = {
  create: ScheduleGroupCreateWithoutSchedulesInput;
  where: ScheduleGroupWhereUniqueInput;
};

export type ScheduleGroupCreateOrConnectWithoutSchoolInput = {
  create: ScheduleGroupCreateWithoutSchoolInput;
  where: ScheduleGroupWhereUniqueInput;
};

export type ScheduleGroupCreateOrConnectWithoutSchoolRegistrationsInput = {
  create: ScheduleGroupCreateWithoutSchoolRegistrationsInput;
  where: ScheduleGroupWhereUniqueInput;
};

export type ScheduleGroupCreateOrConnectWithoutSemesterInput = {
  create: ScheduleGroupCreateWithoutSemesterInput;
  where: ScheduleGroupWhereUniqueInput;
};

export type ScheduleGroupCreateOrConnectWithoutStudentsInput = {
  create: ScheduleGroupCreateWithoutStudentsInput;
  where: ScheduleGroupWhereUniqueInput;
};

export type ScheduleGroupCreateWithoutExceptionsInput = {
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleGroupInput>;
  schedules?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleGroupInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutScheduleGroupsInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutScheduleGroupInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutScheduleGroupsInput>;
  students?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutScheduleGroupInput>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupCreateWithoutScheduleExclusionsInput = {
  exceptions?: InputMaybe<ScheduleExceptionCreateNestedManyWithoutScheduleGroupInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schedules?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleGroupInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutScheduleGroupsInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutScheduleGroupInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutScheduleGroupsInput>;
  students?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutScheduleGroupInput>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupCreateWithoutSchedulesInput = {
  exceptions?: InputMaybe<ScheduleExceptionCreateNestedManyWithoutScheduleGroupInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleGroupInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutScheduleGroupsInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutScheduleGroupInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutScheduleGroupsInput>;
  students?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutScheduleGroupInput>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupCreateWithoutSchoolInput = {
  exceptions?: InputMaybe<ScheduleExceptionCreateNestedManyWithoutScheduleGroupInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleGroupInput>;
  schedules?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleGroupInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutScheduleGroupInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutScheduleGroupsInput>;
  students?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutScheduleGroupInput>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupCreateWithoutSchoolRegistrationsInput = {
  exceptions?: InputMaybe<ScheduleExceptionCreateNestedManyWithoutScheduleGroupInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleGroupInput>;
  schedules?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleGroupInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutScheduleGroupsInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutScheduleGroupsInput>;
  students?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutScheduleGroupInput>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupCreateWithoutSemesterInput = {
  exceptions?: InputMaybe<ScheduleExceptionCreateNestedManyWithoutScheduleGroupInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleGroupInput>;
  schedules?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleGroupInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutScheduleGroupsInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutScheduleGroupInput>;
  students?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutScheduleGroupInput>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupCreateWithoutStudentsInput = {
  exceptions?: InputMaybe<ScheduleExceptionCreateNestedManyWithoutScheduleGroupInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutScheduleGroupInput>;
  schedules?: InputMaybe<ScheduleGroupScheduleCreateNestedManyWithoutScheduleGroupInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutScheduleGroupsInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutScheduleGroupInput>;
  semester?: InputMaybe<SemesterCreateNestedOneWithoutScheduleGroupsInput>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupListRelationFilter = {
  every?: InputMaybe<ScheduleGroupWhereInput>;
  none?: InputMaybe<ScheduleGroupWhereInput>;
  some?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupRelationFilter = {
  is?: InputMaybe<ScheduleGroupWhereInput>;
  isNot?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupScalarWhereInput = {
  AND?: InputMaybe<Array<ScheduleGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<ScheduleGroupScalarWhereInput>>;
  excludeDates?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  schoolId?: InputMaybe<StringFilter>;
  semesterId?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  titleAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleGroupSchedule = {
  __typename?: "ScheduleGroupSchedule";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isMain: Scalars["Boolean"]["output"];
  order: Scalars["Int"]["output"];
  schedule: Schedule;
  scheduleGroup: ScheduleGroup;
  scheduleGroupId: Scalars["String"]["output"];
  scheduleId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ScheduleGroupScheduleCreateManyScheduleGroupInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  order: Scalars["Int"]["input"];
  scheduleId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupScheduleCreateManyScheduleGroupInputEnvelope = {
  data: Array<ScheduleGroupScheduleCreateManyScheduleGroupInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleGroupScheduleCreateManyScheduleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  order: Scalars["Int"]["input"];
  scheduleGroupId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupScheduleCreateManyScheduleInputEnvelope = {
  data: Array<ScheduleGroupScheduleCreateManyScheduleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleGroupScheduleCreateNestedManyWithoutScheduleGroupInput = {
  connect?: InputMaybe<Array<ScheduleGroupScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupScheduleCreateOrConnectWithoutScheduleGroupInput>
  >;
  create?: InputMaybe<
    Array<ScheduleGroupScheduleCreateWithoutScheduleGroupInput>
  >;
  createMany?: InputMaybe<ScheduleGroupScheduleCreateManyScheduleGroupInputEnvelope>;
};

export type ScheduleGroupScheduleCreateNestedManyWithoutScheduleInput = {
  connect?: InputMaybe<Array<ScheduleGroupScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupScheduleCreateOrConnectWithoutScheduleInput>
  >;
  create?: InputMaybe<Array<ScheduleGroupScheduleCreateWithoutScheduleInput>>;
  createMany?: InputMaybe<ScheduleGroupScheduleCreateManyScheduleInputEnvelope>;
};

export type ScheduleGroupScheduleCreateOrConnectWithoutScheduleGroupInput = {
  create: ScheduleGroupScheduleCreateWithoutScheduleGroupInput;
  where: ScheduleGroupScheduleWhereUniqueInput;
};

export type ScheduleGroupScheduleCreateOrConnectWithoutScheduleInput = {
  create: ScheduleGroupScheduleCreateWithoutScheduleInput;
  where: ScheduleGroupScheduleWhereUniqueInput;
};

export type ScheduleGroupScheduleCreateWithoutScheduleGroupInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  order: Scalars["Int"]["input"];
  schedule: ScheduleCreateNestedOneWithoutScheduleGroupsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupScheduleCreateWithoutScheduleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  order: Scalars["Int"]["input"];
  scheduleGroup: ScheduleGroupCreateNestedOneWithoutSchedulesInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupScheduleListRelationFilter = {
  every?: InputMaybe<ScheduleGroupScheduleWhereInput>;
  none?: InputMaybe<ScheduleGroupScheduleWhereInput>;
  some?: InputMaybe<ScheduleGroupScheduleWhereInput>;
};

export type ScheduleGroupScheduleScalarWhereInput = {
  AND?: InputMaybe<Array<ScheduleGroupScheduleScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleGroupScheduleScalarWhereInput>>;
  OR?: InputMaybe<Array<ScheduleGroupScheduleScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isMain?: InputMaybe<BoolFilter>;
  order?: InputMaybe<IntFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleGroupScheduleUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupScheduleUpdateManyWithWhereWithoutScheduleGroupInput =
  {
    data: ScheduleGroupScheduleUpdateManyMutationInput;
    where: ScheduleGroupScheduleScalarWhereInput;
  };

export type ScheduleGroupScheduleUpdateManyWithWhereWithoutScheduleInput = {
  data: ScheduleGroupScheduleUpdateManyMutationInput;
  where: ScheduleGroupScheduleScalarWhereInput;
};

export type ScheduleGroupScheduleUpdateManyWithoutScheduleGroupNestedInput = {
  connect?: InputMaybe<Array<ScheduleGroupScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupScheduleCreateOrConnectWithoutScheduleGroupInput>
  >;
  create?: InputMaybe<
    Array<ScheduleGroupScheduleCreateWithoutScheduleGroupInput>
  >;
  createMany?: InputMaybe<ScheduleGroupScheduleCreateManyScheduleGroupInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleGroupScheduleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleGroupScheduleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleGroupScheduleWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleGroupScheduleWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleGroupScheduleUpdateWithWhereUniqueWithoutScheduleGroupInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleGroupScheduleUpdateManyWithWhereWithoutScheduleGroupInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleGroupScheduleUpsertWithWhereUniqueWithoutScheduleGroupInput>
  >;
};

export type ScheduleGroupScheduleUpdateManyWithoutScheduleNestedInput = {
  connect?: InputMaybe<Array<ScheduleGroupScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupScheduleCreateOrConnectWithoutScheduleInput>
  >;
  create?: InputMaybe<Array<ScheduleGroupScheduleCreateWithoutScheduleInput>>;
  createMany?: InputMaybe<ScheduleGroupScheduleCreateManyScheduleInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleGroupScheduleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleGroupScheduleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleGroupScheduleWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleGroupScheduleWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleGroupScheduleUpdateWithWhereUniqueWithoutScheduleInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleGroupScheduleUpdateManyWithWhereWithoutScheduleInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleGroupScheduleUpsertWithWhereUniqueWithoutScheduleInput>
  >;
};

export type ScheduleGroupScheduleUpdateWithWhereUniqueWithoutScheduleGroupInput =
  {
    data: ScheduleGroupScheduleUpdateWithoutScheduleGroupInput;
    where: ScheduleGroupScheduleWhereUniqueInput;
  };

export type ScheduleGroupScheduleUpdateWithWhereUniqueWithoutScheduleInput = {
  data: ScheduleGroupScheduleUpdateWithoutScheduleInput;
  where: ScheduleGroupScheduleWhereUniqueInput;
};

export type ScheduleGroupScheduleUpdateWithoutScheduleGroupInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  schedule?: InputMaybe<ScheduleUpdateOneRequiredWithoutScheduleGroupsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupScheduleUpdateWithoutScheduleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneRequiredWithoutSchedulesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupScheduleUpsertWithWhereUniqueWithoutScheduleGroupInput =
  {
    create: ScheduleGroupScheduleCreateWithoutScheduleGroupInput;
    update: ScheduleGroupScheduleUpdateWithoutScheduleGroupInput;
    where: ScheduleGroupScheduleWhereUniqueInput;
  };

export type ScheduleGroupScheduleUpsertWithWhereUniqueWithoutScheduleInput = {
  create: ScheduleGroupScheduleCreateWithoutScheduleInput;
  update: ScheduleGroupScheduleUpdateWithoutScheduleInput;
  where: ScheduleGroupScheduleWhereUniqueInput;
};

export type ScheduleGroupScheduleWhereInput = {
  AND?: InputMaybe<Array<ScheduleGroupScheduleWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleGroupScheduleWhereInput>>;
  OR?: InputMaybe<Array<ScheduleGroupScheduleWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isMain?: InputMaybe<BoolFilter>;
  order?: InputMaybe<IntFilter>;
  schedule?: InputMaybe<ScheduleRelationFilter>;
  scheduleGroup?: InputMaybe<ScheduleGroupRelationFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleGroupScheduleWhereUniqueInput = {
  AND?: InputMaybe<Array<ScheduleGroupScheduleWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleGroupScheduleWhereInput>>;
  OR?: InputMaybe<Array<ScheduleGroupScheduleWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<BoolFilter>;
  order?: InputMaybe<IntFilter>;
  schedule?: InputMaybe<ScheduleRelationFilter>;
  scheduleGroup?: InputMaybe<ScheduleGroupRelationFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleGroupStudent = {
  __typename?: "ScheduleGroupStudent";
  _count: ScheduleGroupStudentCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  customSchedule?: Maybe<Schedule>;
  customScheduleId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  scheduleGroup: ScheduleGroup;
  scheduleGroupId: Scalars["String"]["output"];
  student: Student;
  studentId: Scalars["String"]["output"];
  studentScheduleExceptions?: Maybe<Array<ScheduleException>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ScheduleGroupStudentCount = {
  __typename?: "ScheduleGroupStudentCount";
  studentScheduleExceptions: Scalars["Int"]["output"];
};

export type ScheduleGroupStudentCreateManyScheduleGroupInput = {
  customScheduleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  studentId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupStudentCreateManyScheduleGroupInputEnvelope = {
  data: Array<ScheduleGroupStudentCreateManyScheduleGroupInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleGroupStudentCreateManyStudentInput = {
  customScheduleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroupId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupStudentCreateManyStudentInputEnvelope = {
  data: Array<ScheduleGroupStudentCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ScheduleGroupStudentCreateNestedManyWithoutScheduleGroupInput = {
  connect?: InputMaybe<Array<ScheduleGroupStudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupStudentCreateOrConnectWithoutScheduleGroupInput>
  >;
  create?: InputMaybe<
    Array<ScheduleGroupStudentCreateWithoutScheduleGroupInput>
  >;
  createMany?: InputMaybe<ScheduleGroupStudentCreateManyScheduleGroupInputEnvelope>;
};

export type ScheduleGroupStudentCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<ScheduleGroupStudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupStudentCreateOrConnectWithoutStudentInput>
  >;
  create?: InputMaybe<Array<ScheduleGroupStudentCreateWithoutStudentInput>>;
  createMany?: InputMaybe<ScheduleGroupStudentCreateManyStudentInputEnvelope>;
};

export type ScheduleGroupStudentCreateNestedOneWithoutCustomScheduleInput = {
  connect?: InputMaybe<ScheduleGroupStudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupStudentCreateOrConnectWithoutCustomScheduleInput>;
  create?: InputMaybe<ScheduleGroupStudentCreateWithoutCustomScheduleInput>;
};

export type ScheduleGroupStudentCreateNestedOneWithoutStudentScheduleExceptionsInput =
  {
    connect?: InputMaybe<ScheduleGroupStudentWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ScheduleGroupStudentCreateOrConnectWithoutStudentScheduleExceptionsInput>;
    create?: InputMaybe<ScheduleGroupStudentCreateWithoutStudentScheduleExceptionsInput>;
  };

export type ScheduleGroupStudentCreateOrConnectWithoutCustomScheduleInput = {
  create: ScheduleGroupStudentCreateWithoutCustomScheduleInput;
  where: ScheduleGroupStudentWhereUniqueInput;
};

export type ScheduleGroupStudentCreateOrConnectWithoutScheduleGroupInput = {
  create: ScheduleGroupStudentCreateWithoutScheduleGroupInput;
  where: ScheduleGroupStudentWhereUniqueInput;
};

export type ScheduleGroupStudentCreateOrConnectWithoutStudentInput = {
  create: ScheduleGroupStudentCreateWithoutStudentInput;
  where: ScheduleGroupStudentWhereUniqueInput;
};

export type ScheduleGroupStudentCreateOrConnectWithoutStudentScheduleExceptionsInput =
  {
    create: ScheduleGroupStudentCreateWithoutStudentScheduleExceptionsInput;
    where: ScheduleGroupStudentWhereUniqueInput;
  };

export type ScheduleGroupStudentCreateWithoutCustomScheduleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup: ScheduleGroupCreateNestedOneWithoutStudentsInput;
  student: StudentCreateNestedOneWithoutScheduleGroupsInput;
  studentScheduleExceptions?: InputMaybe<ScheduleExceptionCreateNestedManyWithoutScheduleGroupStudentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupStudentCreateWithoutScheduleGroupInput = {
  customSchedule?: InputMaybe<ScheduleCreateNestedOneWithoutScheduleGroupStudentInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  student: StudentCreateNestedOneWithoutScheduleGroupsInput;
  studentScheduleExceptions?: InputMaybe<ScheduleExceptionCreateNestedManyWithoutScheduleGroupStudentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupStudentCreateWithoutStudentInput = {
  customSchedule?: InputMaybe<ScheduleCreateNestedOneWithoutScheduleGroupStudentInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup: ScheduleGroupCreateNestedOneWithoutStudentsInput;
  studentScheduleExceptions?: InputMaybe<ScheduleExceptionCreateNestedManyWithoutScheduleGroupStudentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupStudentCreateWithoutStudentScheduleExceptionsInput = {
  customSchedule?: InputMaybe<ScheduleCreateNestedOneWithoutScheduleGroupStudentInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup: ScheduleGroupCreateNestedOneWithoutStudentsInput;
  student: StudentCreateNestedOneWithoutScheduleGroupsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupStudentListRelationFilter = {
  every?: InputMaybe<ScheduleGroupStudentWhereInput>;
  none?: InputMaybe<ScheduleGroupStudentWhereInput>;
  some?: InputMaybe<ScheduleGroupStudentWhereInput>;
};

export type ScheduleGroupStudentRelationFilter = {
  is?: InputMaybe<ScheduleGroupStudentWhereInput>;
  isNot?: InputMaybe<ScheduleGroupStudentWhereInput>;
};

export type ScheduleGroupStudentScalarWhereInput = {
  AND?: InputMaybe<Array<ScheduleGroupStudentScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleGroupStudentScalarWhereInput>>;
  OR?: InputMaybe<Array<ScheduleGroupStudentScalarWhereInput>>;
  customScheduleId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  studentId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleGroupStudentUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupStudentUpdateManyWithWhereWithoutScheduleGroupInput = {
  data: ScheduleGroupStudentUpdateManyMutationInput;
  where: ScheduleGroupStudentScalarWhereInput;
};

export type ScheduleGroupStudentUpdateManyWithWhereWithoutStudentInput = {
  data: ScheduleGroupStudentUpdateManyMutationInput;
  where: ScheduleGroupStudentScalarWhereInput;
};

export type ScheduleGroupStudentUpdateManyWithoutScheduleGroupNestedInput = {
  connect?: InputMaybe<Array<ScheduleGroupStudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupStudentCreateOrConnectWithoutScheduleGroupInput>
  >;
  create?: InputMaybe<
    Array<ScheduleGroupStudentCreateWithoutScheduleGroupInput>
  >;
  createMany?: InputMaybe<ScheduleGroupStudentCreateManyScheduleGroupInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleGroupStudentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleGroupStudentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleGroupStudentWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleGroupStudentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleGroupStudentUpdateWithWhereUniqueWithoutScheduleGroupInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleGroupStudentUpdateManyWithWhereWithoutScheduleGroupInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleGroupStudentUpsertWithWhereUniqueWithoutScheduleGroupInput>
  >;
};

export type ScheduleGroupStudentUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<ScheduleGroupStudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupStudentCreateOrConnectWithoutStudentInput>
  >;
  create?: InputMaybe<Array<ScheduleGroupStudentCreateWithoutStudentInput>>;
  createMany?: InputMaybe<ScheduleGroupStudentCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleGroupStudentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleGroupStudentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleGroupStudentWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleGroupStudentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleGroupStudentUpdateWithWhereUniqueWithoutStudentInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleGroupStudentUpdateManyWithWhereWithoutStudentInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleGroupStudentUpsertWithWhereUniqueWithoutStudentInput>
  >;
};

export type ScheduleGroupStudentUpdateOneWithoutCustomScheduleNestedInput = {
  connect?: InputMaybe<ScheduleGroupStudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupStudentCreateOrConnectWithoutCustomScheduleInput>;
  create?: InputMaybe<ScheduleGroupStudentCreateWithoutCustomScheduleInput>;
  delete?: InputMaybe<ScheduleGroupStudentWhereInput>;
  disconnect?: InputMaybe<ScheduleGroupStudentWhereInput>;
  update?: InputMaybe<ScheduleGroupStudentUpdateToOneWithWhereWithoutCustomScheduleInput>;
  upsert?: InputMaybe<ScheduleGroupStudentUpsertWithoutCustomScheduleInput>;
};

export type ScheduleGroupStudentUpdateOneWithoutStudentScheduleExceptionsNestedInput =
  {
    connect?: InputMaybe<ScheduleGroupStudentWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ScheduleGroupStudentCreateOrConnectWithoutStudentScheduleExceptionsInput>;
    create?: InputMaybe<ScheduleGroupStudentCreateWithoutStudentScheduleExceptionsInput>;
    delete?: InputMaybe<ScheduleGroupStudentWhereInput>;
    disconnect?: InputMaybe<ScheduleGroupStudentWhereInput>;
    update?: InputMaybe<ScheduleGroupStudentUpdateToOneWithWhereWithoutStudentScheduleExceptionsInput>;
    upsert?: InputMaybe<ScheduleGroupStudentUpsertWithoutStudentScheduleExceptionsInput>;
  };

export type ScheduleGroupStudentUpdateToOneWithWhereWithoutCustomScheduleInput =
  {
    data: ScheduleGroupStudentUpdateWithoutCustomScheduleInput;
    where?: InputMaybe<ScheduleGroupStudentWhereInput>;
  };

export type ScheduleGroupStudentUpdateToOneWithWhereWithoutStudentScheduleExceptionsInput =
  {
    data: ScheduleGroupStudentUpdateWithoutStudentScheduleExceptionsInput;
    where?: InputMaybe<ScheduleGroupStudentWhereInput>;
  };

export type ScheduleGroupStudentUpdateWithWhereUniqueWithoutScheduleGroupInput =
  {
    data: ScheduleGroupStudentUpdateWithoutScheduleGroupInput;
    where: ScheduleGroupStudentWhereUniqueInput;
  };

export type ScheduleGroupStudentUpdateWithWhereUniqueWithoutStudentInput = {
  data: ScheduleGroupStudentUpdateWithoutStudentInput;
  where: ScheduleGroupStudentWhereUniqueInput;
};

export type ScheduleGroupStudentUpdateWithoutCustomScheduleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneRequiredWithoutStudentsNestedInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutScheduleGroupsNestedInput>;
  studentScheduleExceptions?: InputMaybe<ScheduleExceptionUpdateManyWithoutScheduleGroupStudentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupStudentUpdateWithoutScheduleGroupInput = {
  customSchedule?: InputMaybe<ScheduleUpdateOneWithoutScheduleGroupStudentNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutScheduleGroupsNestedInput>;
  studentScheduleExceptions?: InputMaybe<ScheduleExceptionUpdateManyWithoutScheduleGroupStudentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupStudentUpdateWithoutStudentInput = {
  customSchedule?: InputMaybe<ScheduleUpdateOneWithoutScheduleGroupStudentNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneRequiredWithoutStudentsNestedInput>;
  studentScheduleExceptions?: InputMaybe<ScheduleExceptionUpdateManyWithoutScheduleGroupStudentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupStudentUpdateWithoutStudentScheduleExceptionsInput = {
  customSchedule?: InputMaybe<ScheduleUpdateOneWithoutScheduleGroupStudentNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneRequiredWithoutStudentsNestedInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutScheduleGroupsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupStudentUpsertWithWhereUniqueWithoutScheduleGroupInput =
  {
    create: ScheduleGroupStudentCreateWithoutScheduleGroupInput;
    update: ScheduleGroupStudentUpdateWithoutScheduleGroupInput;
    where: ScheduleGroupStudentWhereUniqueInput;
  };

export type ScheduleGroupStudentUpsertWithWhereUniqueWithoutStudentInput = {
  create: ScheduleGroupStudentCreateWithoutStudentInput;
  update: ScheduleGroupStudentUpdateWithoutStudentInput;
  where: ScheduleGroupStudentWhereUniqueInput;
};

export type ScheduleGroupStudentUpsertWithoutCustomScheduleInput = {
  create: ScheduleGroupStudentCreateWithoutCustomScheduleInput;
  update: ScheduleGroupStudentUpdateWithoutCustomScheduleInput;
  where?: InputMaybe<ScheduleGroupStudentWhereInput>;
};

export type ScheduleGroupStudentUpsertWithoutStudentScheduleExceptionsInput = {
  create: ScheduleGroupStudentCreateWithoutStudentScheduleExceptionsInput;
  update: ScheduleGroupStudentUpdateWithoutStudentScheduleExceptionsInput;
  where?: InputMaybe<ScheduleGroupStudentWhereInput>;
};

export type ScheduleGroupStudentWhereInput = {
  AND?: InputMaybe<Array<ScheduleGroupStudentWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleGroupStudentWhereInput>>;
  OR?: InputMaybe<Array<ScheduleGroupStudentWhereInput>>;
  customSchedule?: InputMaybe<ScheduleRelationFilter>;
  customScheduleId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  scheduleGroup?: InputMaybe<ScheduleGroupRelationFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<StringFilter>;
  studentScheduleExceptions?: InputMaybe<ScheduleExceptionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleGroupStudentWhereUniqueInput = {
  AND?: InputMaybe<Array<ScheduleGroupStudentWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleGroupStudentWhereInput>>;
  OR?: InputMaybe<Array<ScheduleGroupStudentWhereInput>>;
  customSchedule?: InputMaybe<ScheduleRelationFilter>;
  customScheduleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroup?: InputMaybe<ScheduleGroupRelationFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<StringFilter>;
  studentScheduleExceptions?: InputMaybe<ScheduleExceptionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleGroupUpdateManyMutationInput = {
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupUpdateManyWithWhereWithoutSchoolInput = {
  data: ScheduleGroupUpdateManyMutationInput;
  where: ScheduleGroupScalarWhereInput;
};

export type ScheduleGroupUpdateManyWithWhereWithoutSemesterInput = {
  data: ScheduleGroupUpdateManyMutationInput;
  where: ScheduleGroupScalarWhereInput;
};

export type ScheduleGroupUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<ScheduleGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<ScheduleGroupCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<ScheduleGroupCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleGroupWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleGroupUpdateWithWhereUniqueWithoutSchoolInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleGroupUpdateManyWithWhereWithoutSchoolInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleGroupUpsertWithWhereUniqueWithoutSchoolInput>
  >;
};

export type ScheduleGroupUpdateManyWithoutSemesterNestedInput = {
  connect?: InputMaybe<Array<ScheduleGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleGroupCreateOrConnectWithoutSemesterInput>
  >;
  create?: InputMaybe<Array<ScheduleGroupCreateWithoutSemesterInput>>;
  createMany?: InputMaybe<ScheduleGroupCreateManySemesterInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleGroupWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ScheduleGroupUpdateWithWhereUniqueWithoutSemesterInput>
  >;
  updateMany?: InputMaybe<
    Array<ScheduleGroupUpdateManyWithWhereWithoutSemesterInput>
  >;
  upsert?: InputMaybe<
    Array<ScheduleGroupUpsertWithWhereUniqueWithoutSemesterInput>
  >;
};

export type ScheduleGroupUpdateOneRequiredWithoutSchedulesNestedInput = {
  connect?: InputMaybe<ScheduleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupCreateOrConnectWithoutSchedulesInput>;
  create?: InputMaybe<ScheduleGroupCreateWithoutSchedulesInput>;
  update?: InputMaybe<ScheduleGroupUpdateToOneWithWhereWithoutSchedulesInput>;
  upsert?: InputMaybe<ScheduleGroupUpsertWithoutSchedulesInput>;
};

export type ScheduleGroupUpdateOneRequiredWithoutStudentsNestedInput = {
  connect?: InputMaybe<ScheduleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<ScheduleGroupCreateWithoutStudentsInput>;
  update?: InputMaybe<ScheduleGroupUpdateToOneWithWhereWithoutStudentsInput>;
  upsert?: InputMaybe<ScheduleGroupUpsertWithoutStudentsInput>;
};

export type ScheduleGroupUpdateOneWithoutExceptionsNestedInput = {
  connect?: InputMaybe<ScheduleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupCreateOrConnectWithoutExceptionsInput>;
  create?: InputMaybe<ScheduleGroupCreateWithoutExceptionsInput>;
  delete?: InputMaybe<ScheduleGroupWhereInput>;
  disconnect?: InputMaybe<ScheduleGroupWhereInput>;
  update?: InputMaybe<ScheduleGroupUpdateToOneWithWhereWithoutExceptionsInput>;
  upsert?: InputMaybe<ScheduleGroupUpsertWithoutExceptionsInput>;
};

export type ScheduleGroupUpdateOneWithoutScheduleExclusionsNestedInput = {
  connect?: InputMaybe<ScheduleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupCreateOrConnectWithoutScheduleExclusionsInput>;
  create?: InputMaybe<ScheduleGroupCreateWithoutScheduleExclusionsInput>;
  delete?: InputMaybe<ScheduleGroupWhereInput>;
  disconnect?: InputMaybe<ScheduleGroupWhereInput>;
  update?: InputMaybe<ScheduleGroupUpdateToOneWithWhereWithoutScheduleExclusionsInput>;
  upsert?: InputMaybe<ScheduleGroupUpsertWithoutScheduleExclusionsInput>;
};

export type ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput = {
  connect?: InputMaybe<ScheduleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleGroupCreateOrConnectWithoutSchoolRegistrationsInput>;
  create?: InputMaybe<ScheduleGroupCreateWithoutSchoolRegistrationsInput>;
  delete?: InputMaybe<ScheduleGroupWhereInput>;
  disconnect?: InputMaybe<ScheduleGroupWhereInput>;
  update?: InputMaybe<ScheduleGroupUpdateToOneWithWhereWithoutSchoolRegistrationsInput>;
  upsert?: InputMaybe<ScheduleGroupUpsertWithoutSchoolRegistrationsInput>;
};

export type ScheduleGroupUpdateToOneWithWhereWithoutExceptionsInput = {
  data: ScheduleGroupUpdateWithoutExceptionsInput;
  where?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupUpdateToOneWithWhereWithoutScheduleExclusionsInput = {
  data: ScheduleGroupUpdateWithoutScheduleExclusionsInput;
  where?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupUpdateToOneWithWhereWithoutSchedulesInput = {
  data: ScheduleGroupUpdateWithoutSchedulesInput;
  where?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupUpdateToOneWithWhereWithoutSchoolRegistrationsInput = {
  data: ScheduleGroupUpdateWithoutSchoolRegistrationsInput;
  where?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupUpdateToOneWithWhereWithoutStudentsInput = {
  data: ScheduleGroupUpdateWithoutStudentsInput;
  where?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupUpdateWithWhereUniqueWithoutSchoolInput = {
  data: ScheduleGroupUpdateWithoutSchoolInput;
  where: ScheduleGroupWhereUniqueInput;
};

export type ScheduleGroupUpdateWithWhereUniqueWithoutSemesterInput = {
  data: ScheduleGroupUpdateWithoutSemesterInput;
  where: ScheduleGroupWhereUniqueInput;
};

export type ScheduleGroupUpdateWithoutExceptionsInput = {
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleGroupNestedInput>;
  schedules?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleGroupNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutScheduleGroupsNestedInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutScheduleGroupNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutScheduleGroupsNestedInput>;
  students?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutScheduleGroupNestedInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupUpdateWithoutScheduleExclusionsInput = {
  exceptions?: InputMaybe<ScheduleExceptionUpdateManyWithoutScheduleGroupNestedInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schedules?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleGroupNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutScheduleGroupsNestedInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutScheduleGroupNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutScheduleGroupsNestedInput>;
  students?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutScheduleGroupNestedInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupUpdateWithoutSchedulesInput = {
  exceptions?: InputMaybe<ScheduleExceptionUpdateManyWithoutScheduleGroupNestedInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleGroupNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutScheduleGroupsNestedInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutScheduleGroupNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutScheduleGroupsNestedInput>;
  students?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutScheduleGroupNestedInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupUpdateWithoutSchoolInput = {
  exceptions?: InputMaybe<ScheduleExceptionUpdateManyWithoutScheduleGroupNestedInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleGroupNestedInput>;
  schedules?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleGroupNestedInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutScheduleGroupNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutScheduleGroupsNestedInput>;
  students?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutScheduleGroupNestedInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupUpdateWithoutSchoolRegistrationsInput = {
  exceptions?: InputMaybe<ScheduleExceptionUpdateManyWithoutScheduleGroupNestedInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleGroupNestedInput>;
  schedules?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleGroupNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutScheduleGroupsNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutScheduleGroupsNestedInput>;
  students?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutScheduleGroupNestedInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupUpdateWithoutSemesterInput = {
  exceptions?: InputMaybe<ScheduleExceptionUpdateManyWithoutScheduleGroupNestedInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleGroupNestedInput>;
  schedules?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleGroupNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutScheduleGroupsNestedInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutScheduleGroupNestedInput>;
  students?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutScheduleGroupNestedInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupUpdateWithoutStudentsInput = {
  exceptions?: InputMaybe<ScheduleExceptionUpdateManyWithoutScheduleGroupNestedInput>;
  excludeDates?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleGroupNestedInput>;
  schedules?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleGroupNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutScheduleGroupsNestedInput>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationUpdateManyWithoutScheduleGroupNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutScheduleGroupsNestedInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleGroupUpsertWithWhereUniqueWithoutSchoolInput = {
  create: ScheduleGroupCreateWithoutSchoolInput;
  update: ScheduleGroupUpdateWithoutSchoolInput;
  where: ScheduleGroupWhereUniqueInput;
};

export type ScheduleGroupUpsertWithWhereUniqueWithoutSemesterInput = {
  create: ScheduleGroupCreateWithoutSemesterInput;
  update: ScheduleGroupUpdateWithoutSemesterInput;
  where: ScheduleGroupWhereUniqueInput;
};

export type ScheduleGroupUpsertWithoutExceptionsInput = {
  create: ScheduleGroupCreateWithoutExceptionsInput;
  update: ScheduleGroupUpdateWithoutExceptionsInput;
  where?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupUpsertWithoutScheduleExclusionsInput = {
  create: ScheduleGroupCreateWithoutScheduleExclusionsInput;
  update: ScheduleGroupUpdateWithoutScheduleExclusionsInput;
  where?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupUpsertWithoutSchedulesInput = {
  create: ScheduleGroupCreateWithoutSchedulesInput;
  update: ScheduleGroupUpdateWithoutSchedulesInput;
  where?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupUpsertWithoutSchoolRegistrationsInput = {
  create: ScheduleGroupCreateWithoutSchoolRegistrationsInput;
  update: ScheduleGroupUpdateWithoutSchoolRegistrationsInput;
  where?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupUpsertWithoutStudentsInput = {
  create: ScheduleGroupCreateWithoutStudentsInput;
  update: ScheduleGroupUpdateWithoutStudentsInput;
  where?: InputMaybe<ScheduleGroupWhereInput>;
};

export type ScheduleGroupWhereInput = {
  AND?: InputMaybe<Array<ScheduleGroupWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleGroupWhereInput>>;
  OR?: InputMaybe<Array<ScheduleGroupWhereInput>>;
  exceptions?: InputMaybe<ScheduleExceptionListRelationFilter>;
  excludeDates?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionListRelationFilter>;
  schedules?: InputMaybe<ScheduleGroupScheduleListRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  semester?: InputMaybe<SemesterRelationFilter>;
  semesterId?: InputMaybe<StringFilter>;
  students?: InputMaybe<ScheduleGroupStudentListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  titleAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleGroupWhereUniqueInput = {
  AND?: InputMaybe<Array<ScheduleGroupWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleGroupWhereInput>>;
  OR?: InputMaybe<Array<ScheduleGroupWhereInput>>;
  exceptions?: InputMaybe<ScheduleExceptionListRelationFilter>;
  excludeDates?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionListRelationFilter>;
  schedules?: InputMaybe<ScheduleGroupScheduleListRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  schoolRegistrations?: InputMaybe<SchoolRegistrationListRelationFilter>;
  semester?: InputMaybe<SemesterRelationFilter>;
  semesterId?: InputMaybe<StringFilter>;
  students?: InputMaybe<ScheduleGroupStudentListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  titleAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleListRelationFilter = {
  every?: InputMaybe<ScheduleWhereInput>;
  none?: InputMaybe<ScheduleWhereInput>;
  some?: InputMaybe<ScheduleWhereInput>;
};

export type ScheduleRelationFilter = {
  is?: InputMaybe<ScheduleWhereInput>;
  isNot?: InputMaybe<ScheduleWhereInput>;
};

export enum ScheduleRepeatMode {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY",
}

export type ScheduleRepetition = {
  __typename?: "ScheduleRepetition";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  schedule: Schedule;
  scheduleId: Scalars["String"]["output"];
  untilUTC?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ScheduleRepetitionCreateNestedOneWithoutScheduleInput = {
  connect?: InputMaybe<ScheduleRepetitionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleRepetitionCreateOrConnectWithoutScheduleInput>;
  create?: InputMaybe<ScheduleRepetitionCreateWithoutScheduleInput>;
};

export type ScheduleRepetitionCreateOrConnectWithoutScheduleInput = {
  create: ScheduleRepetitionCreateWithoutScheduleInput;
  where: ScheduleRepetitionWhereUniqueInput;
};

export type ScheduleRepetitionCreateWithoutScheduleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  untilUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleRepetitionRelationFilter = {
  is?: InputMaybe<ScheduleRepetitionWhereInput>;
  isNot?: InputMaybe<ScheduleRepetitionWhereInput>;
};

export type ScheduleRepetitionUpdateOneWithoutScheduleNestedInput = {
  connect?: InputMaybe<ScheduleRepetitionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleRepetitionCreateOrConnectWithoutScheduleInput>;
  create?: InputMaybe<ScheduleRepetitionCreateWithoutScheduleInput>;
  delete?: InputMaybe<ScheduleRepetitionWhereInput>;
  disconnect?: InputMaybe<ScheduleRepetitionWhereInput>;
  update?: InputMaybe<ScheduleRepetitionUpdateToOneWithWhereWithoutScheduleInput>;
  upsert?: InputMaybe<ScheduleRepetitionUpsertWithoutScheduleInput>;
};

export type ScheduleRepetitionUpdateToOneWithWhereWithoutScheduleInput = {
  data: ScheduleRepetitionUpdateWithoutScheduleInput;
  where?: InputMaybe<ScheduleRepetitionWhereInput>;
};

export type ScheduleRepetitionUpdateWithoutScheduleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  untilUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleRepetitionUpsertWithoutScheduleInput = {
  create: ScheduleRepetitionCreateWithoutScheduleInput;
  update: ScheduleRepetitionUpdateWithoutScheduleInput;
  where?: InputMaybe<ScheduleRepetitionWhereInput>;
};

export type ScheduleRepetitionWhereInput = {
  AND?: InputMaybe<Array<ScheduleRepetitionWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleRepetitionWhereInput>>;
  OR?: InputMaybe<Array<ScheduleRepetitionWhereInput>>;
  id?: InputMaybe<StringFilter>;
  schedule?: InputMaybe<ScheduleRelationFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  untilUTC?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleRepetitionWhereUniqueInput = {
  AND?: InputMaybe<Array<ScheduleRepetitionWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleRepetitionWhereInput>>;
  OR?: InputMaybe<Array<ScheduleRepetitionWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schedule?: InputMaybe<ScheduleRelationFilter>;
  scheduleId?: InputMaybe<Scalars["String"]["input"]>;
  untilUTC?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleScalarWhereInput = {
  AND?: InputMaybe<Array<ScheduleScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleScalarWhereInput>>;
  OR?: InputMaybe<Array<ScheduleScalarWhereInput>>;
  countryId?: InputMaybe<StringFilter>;
  daysOfMonth?: InputMaybe<IntListFilter>;
  daysOfWeek?: InputMaybe<StringListFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  endDateUTC?: InputMaybe<DateTimeFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  repeatEvery?: InputMaybe<IntFilter>;
  repeatMode?: InputMaybe<EnumScheduleRepeatModeFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  semesterId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  startDateUTC?: InputMaybe<DateTimeFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  state?: InputMaybe<EnumScheduleStateFilter>;
  title?: InputMaybe<StringFilter>;
  titleAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ScheduleState {
  ACTIVE = "ACTIVE",
  CLONED = "CLONED",
}

export type ScheduleUpdateManyMutationInput = {
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state?: InputMaybe<ScheduleState>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleUpdateManyWithWhereWithoutCountryInput = {
  data: ScheduleUpdateManyMutationInput;
  where: ScheduleScalarWhereInput;
};

export type ScheduleUpdateManyWithWhereWithoutSemesterInput = {
  data: ScheduleUpdateManyMutationInput;
  where: ScheduleScalarWhereInput;
};

export type ScheduleUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<ScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<ScheduleCreateWithoutCountryInput>>;
  createMany?: InputMaybe<ScheduleCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleWhereUniqueInput>>;
  update?: InputMaybe<Array<ScheduleUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<
    Array<ScheduleUpdateManyWithWhereWithoutCountryInput>
  >;
  upsert?: InputMaybe<Array<ScheduleUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type ScheduleUpdateManyWithoutSemesterNestedInput = {
  connect?: InputMaybe<Array<ScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ScheduleCreateOrConnectWithoutSemesterInput>
  >;
  create?: InputMaybe<Array<ScheduleCreateWithoutSemesterInput>>;
  createMany?: InputMaybe<ScheduleCreateManySemesterInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleWhereUniqueInput>>;
  update?: InputMaybe<Array<ScheduleUpdateWithWhereUniqueWithoutSemesterInput>>;
  updateMany?: InputMaybe<
    Array<ScheduleUpdateManyWithWhereWithoutSemesterInput>
  >;
  upsert?: InputMaybe<Array<ScheduleUpsertWithWhereUniqueWithoutSemesterInput>>;
};

export type ScheduleUpdateOneRequiredWithoutScheduleGroupsNestedInput = {
  connect?: InputMaybe<ScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleCreateOrConnectWithoutScheduleGroupsInput>;
  create?: InputMaybe<ScheduleCreateWithoutScheduleGroupsInput>;
  update?: InputMaybe<ScheduleUpdateToOneWithWhereWithoutScheduleGroupsInput>;
  upsert?: InputMaybe<ScheduleUpsertWithoutScheduleGroupsInput>;
};

export type ScheduleUpdateOneWithoutClonedFromNestedInput = {
  connect?: InputMaybe<ScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleCreateOrConnectWithoutClonedFromInput>;
  create?: InputMaybe<ScheduleCreateWithoutClonedFromInput>;
  delete?: InputMaybe<ScheduleWhereInput>;
  disconnect?: InputMaybe<ScheduleWhereInput>;
  update?: InputMaybe<ScheduleUpdateToOneWithWhereWithoutClonedFromInput>;
  upsert?: InputMaybe<ScheduleUpsertWithoutClonedFromInput>;
};

export type ScheduleUpdateOneWithoutClonedToNestedInput = {
  connect?: InputMaybe<ScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleCreateOrConnectWithoutClonedToInput>;
  create?: InputMaybe<ScheduleCreateWithoutClonedToInput>;
  delete?: InputMaybe<ScheduleWhereInput>;
  disconnect?: InputMaybe<ScheduleWhereInput>;
  update?: InputMaybe<ScheduleUpdateToOneWithWhereWithoutClonedToInput>;
  upsert?: InputMaybe<ScheduleUpsertWithoutClonedToInput>;
};

export type ScheduleUpdateOneWithoutScheduleExclusionsNestedInput = {
  connect?: InputMaybe<ScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleCreateOrConnectWithoutScheduleExclusionsInput>;
  create?: InputMaybe<ScheduleCreateWithoutScheduleExclusionsInput>;
  delete?: InputMaybe<ScheduleWhereInput>;
  disconnect?: InputMaybe<ScheduleWhereInput>;
  update?: InputMaybe<ScheduleUpdateToOneWithWhereWithoutScheduleExclusionsInput>;
  upsert?: InputMaybe<ScheduleUpsertWithoutScheduleExclusionsInput>;
};

export type ScheduleUpdateOneWithoutScheduleGroupStudentNestedInput = {
  connect?: InputMaybe<ScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleCreateOrConnectWithoutScheduleGroupStudentInput>;
  create?: InputMaybe<ScheduleCreateWithoutScheduleGroupStudentInput>;
  delete?: InputMaybe<ScheduleWhereInput>;
  disconnect?: InputMaybe<ScheduleWhereInput>;
  update?: InputMaybe<ScheduleUpdateToOneWithWhereWithoutScheduleGroupStudentInput>;
  upsert?: InputMaybe<ScheduleUpsertWithoutScheduleGroupStudentInput>;
};

export type ScheduleUpdateToOneWithWhereWithoutClonedFromInput = {
  data: ScheduleUpdateWithoutClonedFromInput;
  where?: InputMaybe<ScheduleWhereInput>;
};

export type ScheduleUpdateToOneWithWhereWithoutClonedToInput = {
  data: ScheduleUpdateWithoutClonedToInput;
  where?: InputMaybe<ScheduleWhereInput>;
};

export type ScheduleUpdateToOneWithWhereWithoutScheduleExclusionsInput = {
  data: ScheduleUpdateWithoutScheduleExclusionsInput;
  where?: InputMaybe<ScheduleWhereInput>;
};

export type ScheduleUpdateToOneWithWhereWithoutScheduleGroupStudentInput = {
  data: ScheduleUpdateWithoutScheduleGroupStudentInput;
  where?: InputMaybe<ScheduleWhereInput>;
};

export type ScheduleUpdateToOneWithWhereWithoutScheduleGroupsInput = {
  data: ScheduleUpdateWithoutScheduleGroupsInput;
  where?: InputMaybe<ScheduleWhereInput>;
};

export type ScheduleUpdateWithWhereUniqueWithoutCountryInput = {
  data: ScheduleUpdateWithoutCountryInput;
  where: ScheduleWhereUniqueInput;
};

export type ScheduleUpdateWithWhereUniqueWithoutSemesterInput = {
  data: ScheduleUpdateWithoutSemesterInput;
  where: ScheduleWhereUniqueInput;
};

export type ScheduleUpdateWithoutClonedFromInput = {
  clonedTo?: InputMaybe<ScheduleUpdateOneWithoutClonedFromNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutSchedulesNestedInput>;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterUpdateManyWithoutScheduleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionUpdateOneWithoutScheduleNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleNestedInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentUpdateOneWithoutCustomScheduleNestedInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutSchedulesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state?: InputMaybe<ScheduleState>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleUpdateWithoutClonedToInput = {
  clonedFrom?: InputMaybe<ScheduleUpdateOneWithoutClonedToNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutSchedulesNestedInput>;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterUpdateManyWithoutScheduleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionUpdateOneWithoutScheduleNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleNestedInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentUpdateOneWithoutCustomScheduleNestedInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutSchedulesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state?: InputMaybe<ScheduleState>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleUpdateWithoutCountryInput = {
  clonedFrom?: InputMaybe<ScheduleUpdateOneWithoutClonedToNestedInput>;
  clonedTo?: InputMaybe<ScheduleUpdateOneWithoutClonedFromNestedInput>;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterUpdateManyWithoutScheduleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionUpdateOneWithoutScheduleNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleNestedInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentUpdateOneWithoutCustomScheduleNestedInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutSchedulesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state?: InputMaybe<ScheduleState>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleUpdateWithoutScheduleExclusionsInput = {
  clonedFrom?: InputMaybe<ScheduleUpdateOneWithoutClonedToNestedInput>;
  clonedTo?: InputMaybe<ScheduleUpdateOneWithoutClonedFromNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutSchedulesNestedInput>;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterUpdateManyWithoutScheduleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionUpdateOneWithoutScheduleNestedInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentUpdateOneWithoutCustomScheduleNestedInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutSchedulesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state?: InputMaybe<ScheduleState>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleUpdateWithoutScheduleGroupStudentInput = {
  clonedFrom?: InputMaybe<ScheduleUpdateOneWithoutClonedToNestedInput>;
  clonedTo?: InputMaybe<ScheduleUpdateOneWithoutClonedFromNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutSchedulesNestedInput>;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterUpdateManyWithoutScheduleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionUpdateOneWithoutScheduleNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleNestedInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutSchedulesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state?: InputMaybe<ScheduleState>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleUpdateWithoutScheduleGroupsInput = {
  clonedFrom?: InputMaybe<ScheduleUpdateOneWithoutClonedToNestedInput>;
  clonedTo?: InputMaybe<ScheduleUpdateOneWithoutClonedFromNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutSchedulesNestedInput>;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterUpdateManyWithoutScheduleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionUpdateOneWithoutScheduleNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleNestedInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentUpdateOneWithoutCustomScheduleNestedInput>;
  semester?: InputMaybe<SemesterUpdateOneWithoutSchedulesNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state?: InputMaybe<ScheduleState>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleUpdateWithoutSemesterInput = {
  clonedFrom?: InputMaybe<ScheduleUpdateOneWithoutClonedToNestedInput>;
  clonedTo?: InputMaybe<ScheduleUpdateOneWithoutClonedFromNestedInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutSchedulesNestedInput>;
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  daysOfWeek?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  filters?: InputMaybe<ScheduleFilterUpdateManyWithoutScheduleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<Scalars["Int"]["input"]>;
  repeatMode?: InputMaybe<ScheduleRepeatMode>;
  repetition?: InputMaybe<ScheduleRepetitionUpdateOneWithoutScheduleNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutScheduleNestedInput>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentUpdateOneWithoutCustomScheduleNestedInput>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleUpdateManyWithoutScheduleNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDateUTC?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  state?: InputMaybe<ScheduleState>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduleUpsertWithWhereUniqueWithoutCountryInput = {
  create: ScheduleCreateWithoutCountryInput;
  update: ScheduleUpdateWithoutCountryInput;
  where: ScheduleWhereUniqueInput;
};

export type ScheduleUpsertWithWhereUniqueWithoutSemesterInput = {
  create: ScheduleCreateWithoutSemesterInput;
  update: ScheduleUpdateWithoutSemesterInput;
  where: ScheduleWhereUniqueInput;
};

export type ScheduleUpsertWithoutClonedFromInput = {
  create: ScheduleCreateWithoutClonedFromInput;
  update: ScheduleUpdateWithoutClonedFromInput;
  where?: InputMaybe<ScheduleWhereInput>;
};

export type ScheduleUpsertWithoutClonedToInput = {
  create: ScheduleCreateWithoutClonedToInput;
  update: ScheduleUpdateWithoutClonedToInput;
  where?: InputMaybe<ScheduleWhereInput>;
};

export type ScheduleUpsertWithoutScheduleExclusionsInput = {
  create: ScheduleCreateWithoutScheduleExclusionsInput;
  update: ScheduleUpdateWithoutScheduleExclusionsInput;
  where?: InputMaybe<ScheduleWhereInput>;
};

export type ScheduleUpsertWithoutScheduleGroupStudentInput = {
  create: ScheduleCreateWithoutScheduleGroupStudentInput;
  update: ScheduleUpdateWithoutScheduleGroupStudentInput;
  where?: InputMaybe<ScheduleWhereInput>;
};

export type ScheduleUpsertWithoutScheduleGroupsInput = {
  create: ScheduleCreateWithoutScheduleGroupsInput;
  update: ScheduleUpdateWithoutScheduleGroupsInput;
  where?: InputMaybe<ScheduleWhereInput>;
};

export type ScheduleWhereInput = {
  AND?: InputMaybe<Array<ScheduleWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleWhereInput>>;
  OR?: InputMaybe<Array<ScheduleWhereInput>>;
  clonedFrom?: InputMaybe<ScheduleRelationFilter>;
  clonedTo?: InputMaybe<ScheduleRelationFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  daysOfMonth?: InputMaybe<IntListFilter>;
  daysOfWeek?: InputMaybe<StringListFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  endDateUTC?: InputMaybe<DateTimeFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  filters?: InputMaybe<ScheduleFilterListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  repeatEvery?: InputMaybe<IntFilter>;
  repeatMode?: InputMaybe<EnumScheduleRepeatModeFilter>;
  repetition?: InputMaybe<ScheduleRepetitionRelationFilter>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionListRelationFilter>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentRelationFilter>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleListRelationFilter>;
  scheduleId?: InputMaybe<StringFilter>;
  semester?: InputMaybe<SemesterRelationFilter>;
  semesterId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  startDateUTC?: InputMaybe<DateTimeFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  state?: InputMaybe<EnumScheduleStateFilter>;
  title?: InputMaybe<StringFilter>;
  titleAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScheduleWhereUniqueInput = {
  AND?: InputMaybe<Array<ScheduleWhereInput>>;
  NOT?: InputMaybe<Array<ScheduleWhereInput>>;
  OR?: InputMaybe<Array<ScheduleWhereInput>>;
  clonedFrom?: InputMaybe<ScheduleRelationFilter>;
  clonedTo?: InputMaybe<ScheduleRelationFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  daysOfMonth?: InputMaybe<IntListFilter>;
  daysOfWeek?: InputMaybe<StringListFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  endDateUTC?: InputMaybe<DateTimeFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  filters?: InputMaybe<ScheduleFilterListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  repeatEvery?: InputMaybe<IntFilter>;
  repeatMode?: InputMaybe<EnumScheduleRepeatModeFilter>;
  repetition?: InputMaybe<ScheduleRepetitionRelationFilter>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionListRelationFilter>;
  scheduleGroupStudent?: InputMaybe<ScheduleGroupStudentRelationFilter>;
  scheduleGroups?: InputMaybe<ScheduleGroupScheduleListRelationFilter>;
  scheduleId?: InputMaybe<Scalars["String"]["input"]>;
  semester?: InputMaybe<SemesterRelationFilter>;
  semesterId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  startDateUTC?: InputMaybe<DateTimeFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  state?: InputMaybe<EnumScheduleStateFilter>;
  title?: InputMaybe<StringFilter>;
  titleAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type School = {
  __typename?: "School";
  ServiceZoneSchool?: Maybe<Array<ServiceZoneSchool>>;
  _count: SchoolCount;
  address: Address;
  addressId: Scalars["String"]["output"];
  /** ///////////////////////////// */
  adhocNotificationStudentUsers?: Maybe<Array<AdhocNotificationStudentUser>>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  classes?: Maybe<Array<SchoolClass>>;
  collectionPoints?: Maybe<Array<SchoolCollectionPoint>>;
  comment?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  dropOffPoint?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  idInt: Scalars["Int"]["output"];
  logo?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  pickUpPoint?: Maybe<Scalars["String"]["output"]>;
  radius: Scalars["Int"]["output"];
  scheduleGroups?: Maybe<Array<ScheduleGroup>>;
  schoolCode?: Maybe<Scalars["String"]["output"]>;
  schoolGroup?: Maybe<SchoolGroup>;
  schoolGroupId?: Maybe<Scalars["String"]["output"]>;
  schoolName: Scalars["String"]["output"];
  schoolNameAr: Scalars["String"]["output"];
  schoolRegistrationAttempts?: Maybe<Array<SchoolRegistrationAttempt>>;
  schoolServiceEligibilities?: Maybe<Array<SchoolServiceEligibility>>;
  semesters?: Maybe<Array<Semester>>;
  serviceProviderSchools?: Maybe<Array<ServiceProviderSchool>>;
  stage?: Maybe<SchoolStage>;
  stageId?: Maybe<Scalars["String"]["output"]>;
  stages?: Maybe<Array<SchoolSchoolStage>>;
  status: SchoolStatus;
  stop?: Maybe<Stop>;
  students?: Maybe<Array<SchoolStudent>>;
  submittedAt?: Maybe<Scalars["DateTime"]["output"]>;
  type?: Maybe<SchoolType>;
  typeId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolClass = {
  __typename?: "SchoolClass";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  class: Class;
  classId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  school: School;
  schoolId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolClassCreateManyClassInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolClassCreateManyClassInputEnvelope = {
  data: Array<SchoolClassCreateManyClassInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolClassCreateManySchoolInput = {
  classId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolClassCreateManySchoolInputEnvelope = {
  data: Array<SchoolClassCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolClassCreateNestedManyWithoutClassInput = {
  connect?: InputMaybe<Array<SchoolClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolClassCreateOrConnectWithoutClassInput>
  >;
  create?: InputMaybe<Array<SchoolClassCreateWithoutClassInput>>;
  createMany?: InputMaybe<SchoolClassCreateManyClassInputEnvelope>;
};

export type SchoolClassCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SchoolClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolClassCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolClassCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolClassCreateManySchoolInputEnvelope>;
};

export type SchoolClassCreateOrConnectWithoutClassInput = {
  create: SchoolClassCreateWithoutClassInput;
  where: SchoolClassWhereUniqueInput;
};

export type SchoolClassCreateOrConnectWithoutSchoolInput = {
  create: SchoolClassCreateWithoutSchoolInput;
  where: SchoolClassWhereUniqueInput;
};

export type SchoolClassCreateWithoutClassInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school: SchoolCreateNestedOneWithoutClassesInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolClassCreateWithoutSchoolInput = {
  class: ClassCreateNestedOneWithoutSchoolsInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolClassListRelationFilter = {
  every?: InputMaybe<SchoolClassWhereInput>;
  none?: InputMaybe<SchoolClassWhereInput>;
  some?: InputMaybe<SchoolClassWhereInput>;
};

export type SchoolClassScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolClassScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolClassScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolClassScalarWhereInput>>;
  classId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  schoolId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolClassUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolClassUpdateManyWithWhereWithoutClassInput = {
  data: SchoolClassUpdateManyMutationInput;
  where: SchoolClassScalarWhereInput;
};

export type SchoolClassUpdateManyWithWhereWithoutSchoolInput = {
  data: SchoolClassUpdateManyMutationInput;
  where: SchoolClassScalarWhereInput;
};

export type SchoolClassUpdateManyWithoutClassNestedInput = {
  connect?: InputMaybe<Array<SchoolClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolClassCreateOrConnectWithoutClassInput>
  >;
  create?: InputMaybe<Array<SchoolClassCreateWithoutClassInput>>;
  createMany?: InputMaybe<SchoolClassCreateManyClassInputEnvelope>;
  delete?: InputMaybe<Array<SchoolClassWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolClassScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolClassWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolClassWhereUniqueInput>>;
  update?: InputMaybe<Array<SchoolClassUpdateWithWhereUniqueWithoutClassInput>>;
  updateMany?: InputMaybe<
    Array<SchoolClassUpdateManyWithWhereWithoutClassInput>
  >;
  upsert?: InputMaybe<Array<SchoolClassUpsertWithWhereUniqueWithoutClassInput>>;
};

export type SchoolClassUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SchoolClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolClassCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolClassCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolClassCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SchoolClassWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolClassScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolClassWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolClassWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolClassUpdateWithWhereUniqueWithoutSchoolInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolClassUpdateManyWithWhereWithoutSchoolInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolClassUpsertWithWhereUniqueWithoutSchoolInput>
  >;
};

export type SchoolClassUpdateWithWhereUniqueWithoutClassInput = {
  data: SchoolClassUpdateWithoutClassInput;
  where: SchoolClassWhereUniqueInput;
};

export type SchoolClassUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SchoolClassUpdateWithoutSchoolInput;
  where: SchoolClassWhereUniqueInput;
};

export type SchoolClassUpdateWithoutClassInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutClassesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolClassUpdateWithoutSchoolInput = {
  class?: InputMaybe<ClassUpdateOneRequiredWithoutSchoolsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolClassUpsertWithWhereUniqueWithoutClassInput = {
  create: SchoolClassCreateWithoutClassInput;
  update: SchoolClassUpdateWithoutClassInput;
  where: SchoolClassWhereUniqueInput;
};

export type SchoolClassUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SchoolClassCreateWithoutSchoolInput;
  update: SchoolClassUpdateWithoutSchoolInput;
  where: SchoolClassWhereUniqueInput;
};

export type SchoolClassWhereInput = {
  AND?: InputMaybe<Array<SchoolClassWhereInput>>;
  NOT?: InputMaybe<Array<SchoolClassWhereInput>>;
  OR?: InputMaybe<Array<SchoolClassWhereInput>>;
  class?: InputMaybe<ClassRelationFilter>;
  classId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolClassWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolClassWhereInput>>;
  NOT?: InputMaybe<Array<SchoolClassWhereInput>>;
  OR?: InputMaybe<Array<SchoolClassWhereInput>>;
  class?: InputMaybe<ClassRelationFilter>;
  classId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolCollectionPoint = {
  __typename?: "SchoolCollectionPoint";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  collectionPoint: CollectionPoint;
  collectionPointId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  school: School;
  schoolId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolCollectionPointCreateManyCollectionPointInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCollectionPointCreateManyCollectionPointInputEnvelope = {
  data: Array<SchoolCollectionPointCreateManyCollectionPointInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolCollectionPointCreateManySchoolInput = {
  collectionPointId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCollectionPointCreateManySchoolInputEnvelope = {
  data: Array<SchoolCollectionPointCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolCollectionPointCreateNestedManyWithoutCollectionPointInput = {
  connect?: InputMaybe<Array<SchoolCollectionPointWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolCollectionPointCreateOrConnectWithoutCollectionPointInput>
  >;
  create?: InputMaybe<
    Array<SchoolCollectionPointCreateWithoutCollectionPointInput>
  >;
  createMany?: InputMaybe<SchoolCollectionPointCreateManyCollectionPointInputEnvelope>;
};

export type SchoolCollectionPointCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SchoolCollectionPointWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolCollectionPointCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolCollectionPointCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolCollectionPointCreateManySchoolInputEnvelope>;
};

export type SchoolCollectionPointCreateOrConnectWithoutCollectionPointInput = {
  create: SchoolCollectionPointCreateWithoutCollectionPointInput;
  where: SchoolCollectionPointWhereUniqueInput;
};

export type SchoolCollectionPointCreateOrConnectWithoutSchoolInput = {
  create: SchoolCollectionPointCreateWithoutSchoolInput;
  where: SchoolCollectionPointWhereUniqueInput;
};

export type SchoolCollectionPointCreateWithoutCollectionPointInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school: SchoolCreateNestedOneWithoutCollectionPointsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCollectionPointCreateWithoutSchoolInput = {
  collectionPoint: CollectionPointCreateNestedOneWithoutSchoolsInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCollectionPointListRelationFilter = {
  every?: InputMaybe<SchoolCollectionPointWhereInput>;
  none?: InputMaybe<SchoolCollectionPointWhereInput>;
  some?: InputMaybe<SchoolCollectionPointWhereInput>;
};

export type SchoolCollectionPointScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolCollectionPointScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolCollectionPointScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolCollectionPointScalarWhereInput>>;
  collectionPointId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  schoolId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolCollectionPointUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCollectionPointUpdateManyWithWhereWithoutCollectionPointInput =
  {
    data: SchoolCollectionPointUpdateManyMutationInput;
    where: SchoolCollectionPointScalarWhereInput;
  };

export type SchoolCollectionPointUpdateManyWithWhereWithoutSchoolInput = {
  data: SchoolCollectionPointUpdateManyMutationInput;
  where: SchoolCollectionPointScalarWhereInput;
};

export type SchoolCollectionPointUpdateManyWithoutCollectionPointNestedInput = {
  connect?: InputMaybe<Array<SchoolCollectionPointWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolCollectionPointCreateOrConnectWithoutCollectionPointInput>
  >;
  create?: InputMaybe<
    Array<SchoolCollectionPointCreateWithoutCollectionPointInput>
  >;
  createMany?: InputMaybe<SchoolCollectionPointCreateManyCollectionPointInputEnvelope>;
  delete?: InputMaybe<Array<SchoolCollectionPointWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolCollectionPointScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolCollectionPointWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolCollectionPointWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolCollectionPointUpdateWithWhereUniqueWithoutCollectionPointInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolCollectionPointUpdateManyWithWhereWithoutCollectionPointInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolCollectionPointUpsertWithWhereUniqueWithoutCollectionPointInput>
  >;
};

export type SchoolCollectionPointUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SchoolCollectionPointWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolCollectionPointCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolCollectionPointCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolCollectionPointCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SchoolCollectionPointWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolCollectionPointScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolCollectionPointWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolCollectionPointWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolCollectionPointUpdateWithWhereUniqueWithoutSchoolInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolCollectionPointUpdateManyWithWhereWithoutSchoolInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolCollectionPointUpsertWithWhereUniqueWithoutSchoolInput>
  >;
};

export type SchoolCollectionPointUpdateWithWhereUniqueWithoutCollectionPointInput =
  {
    data: SchoolCollectionPointUpdateWithoutCollectionPointInput;
    where: SchoolCollectionPointWhereUniqueInput;
  };

export type SchoolCollectionPointUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SchoolCollectionPointUpdateWithoutSchoolInput;
  where: SchoolCollectionPointWhereUniqueInput;
};

export type SchoolCollectionPointUpdateWithoutCollectionPointInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutCollectionPointsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCollectionPointUpdateWithoutSchoolInput = {
  collectionPoint?: InputMaybe<CollectionPointUpdateOneRequiredWithoutSchoolsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCollectionPointUpsertWithWhereUniqueWithoutCollectionPointInput =
  {
    create: SchoolCollectionPointCreateWithoutCollectionPointInput;
    update: SchoolCollectionPointUpdateWithoutCollectionPointInput;
    where: SchoolCollectionPointWhereUniqueInput;
  };

export type SchoolCollectionPointUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SchoolCollectionPointCreateWithoutSchoolInput;
  update: SchoolCollectionPointUpdateWithoutSchoolInput;
  where: SchoolCollectionPointWhereUniqueInput;
};

export type SchoolCollectionPointWhereInput = {
  AND?: InputMaybe<Array<SchoolCollectionPointWhereInput>>;
  NOT?: InputMaybe<Array<SchoolCollectionPointWhereInput>>;
  OR?: InputMaybe<Array<SchoolCollectionPointWhereInput>>;
  collectionPoint?: InputMaybe<CollectionPointRelationFilter>;
  collectionPointId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolCollectionPointWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolCollectionPointWhereInput>>;
  NOT?: InputMaybe<Array<SchoolCollectionPointWhereInput>>;
  OR?: InputMaybe<Array<SchoolCollectionPointWhereInput>>;
  collectionPoint?: InputMaybe<CollectionPointRelationFilter>;
  collectionPointId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolConnectNestedManyWithoutServiceZoneSchoolUniqueInput = {
  connect?: InputMaybe<Array<SchoolWhereUniqueInput>>;
};

export type SchoolCount = {
  __typename?: "SchoolCount";
  ServiceZoneSchool: Scalars["Int"]["output"];
  adhocNotificationStudentUsers: Scalars["Int"]["output"];
  classes: Scalars["Int"]["output"];
  collectionPoints: Scalars["Int"]["output"];
  scheduleGroups: Scalars["Int"]["output"];
  schoolRegistrationAttempts: Scalars["Int"]["output"];
  schoolServiceEligibilities: Scalars["Int"]["output"];
  semesters: Scalars["Int"]["output"];
  serviceProviderSchools: Scalars["Int"]["output"];
  stages: Scalars["Int"]["output"];
  students: Scalars["Int"]["output"];
};

export type SchoolCreateInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateManyStageInput = {
  addressId: Scalars["String"]["input"];
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroupId?: InputMaybe<Scalars["String"]["input"]>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  status?: InputMaybe<SchoolStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  typeId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateManyStageInputEnvelope = {
  data: Array<SchoolCreateManyStageInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolCreateNestedManyWithoutStageInput = {
  connect?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolCreateOrConnectWithoutStageInput>>;
  create?: InputMaybe<Array<SchoolCreateWithoutStageInput>>;
  createMany?: InputMaybe<SchoolCreateManyStageInputEnvelope>;
};

export type SchoolCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<SchoolCreateWithoutAddressInput>;
};

export type SchoolCreateNestedOneWithoutAdhocNotificationStudentUsersInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutAdhocNotificationStudentUsersInput>;
  create?: InputMaybe<SchoolCreateWithoutAdhocNotificationStudentUsersInput>;
};

export type SchoolCreateNestedOneWithoutClassesInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutClassesInput>;
  create?: InputMaybe<SchoolCreateWithoutClassesInput>;
};

export type SchoolCreateNestedOneWithoutCollectionPointsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutCollectionPointsInput>;
  create?: InputMaybe<SchoolCreateWithoutCollectionPointsInput>;
};

export type SchoolCreateNestedOneWithoutScheduleGroupsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutScheduleGroupsInput>;
  create?: InputMaybe<SchoolCreateWithoutScheduleGroupsInput>;
};

export type SchoolCreateNestedOneWithoutSchoolRegistrationAttemptsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSchoolRegistrationAttemptsInput>;
  create?: InputMaybe<SchoolCreateWithoutSchoolRegistrationAttemptsInput>;
};

export type SchoolCreateNestedOneWithoutSchoolServiceEligibilitiesInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSchoolServiceEligibilitiesInput>;
  create?: InputMaybe<SchoolCreateWithoutSchoolServiceEligibilitiesInput>;
};

export type SchoolCreateNestedOneWithoutSemestersInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSemestersInput>;
  create?: InputMaybe<SchoolCreateWithoutSemestersInput>;
};

export type SchoolCreateNestedOneWithoutServiceProviderSchoolsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutServiceProviderSchoolsInput>;
  create?: InputMaybe<SchoolCreateWithoutServiceProviderSchoolsInput>;
};

export type SchoolCreateNestedOneWithoutServiceZoneSchoolInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutServiceZoneSchoolInput>;
  create?: InputMaybe<SchoolCreateWithoutServiceZoneSchoolInput>;
};

export type SchoolCreateNestedOneWithoutStagesInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStagesInput>;
  create?: InputMaybe<SchoolCreateWithoutStagesInput>;
};

export type SchoolCreateNestedOneWithoutStopInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStopInput>;
  create?: InputMaybe<SchoolCreateWithoutStopInput>;
};

export type SchoolCreateNestedOneWithoutStudentsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<SchoolCreateWithoutStudentsInput>;
};

export type SchoolCreateOrConnectWithoutAddressInput = {
  create: SchoolCreateWithoutAddressInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutAdhocNotificationStudentUsersInput = {
  create: SchoolCreateWithoutAdhocNotificationStudentUsersInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutClassesInput = {
  create: SchoolCreateWithoutClassesInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutCollectionPointsInput = {
  create: SchoolCreateWithoutCollectionPointsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutScheduleGroupsInput = {
  create: SchoolCreateWithoutScheduleGroupsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutSchoolRegistrationAttemptsInput = {
  create: SchoolCreateWithoutSchoolRegistrationAttemptsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutSchoolServiceEligibilitiesInput = {
  create: SchoolCreateWithoutSchoolServiceEligibilitiesInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutSemestersInput = {
  create: SchoolCreateWithoutSemestersInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutServiceProviderSchoolsInput = {
  create: SchoolCreateWithoutServiceProviderSchoolsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutServiceZoneSchoolInput = {
  create: SchoolCreateWithoutServiceZoneSchoolInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutStageInput = {
  create: SchoolCreateWithoutStageInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutStagesInput = {
  create: SchoolCreateWithoutStagesInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutStopInput = {
  create: SchoolCreateWithoutStopInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutStudentsInput = {
  create: SchoolCreateWithoutStudentsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateWithoutAddressInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutAdhocNotificationStudentUsersInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutClassesInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutCollectionPointsInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutScheduleGroupsInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutSchoolRegistrationAttemptsInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutSchoolServiceEligibilitiesInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutSemestersInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutServiceProviderSchoolsInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutServiceZoneSchoolInput = {
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutStageInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutStagesInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutStopInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentCreateNestedManyWithoutSchoolInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolCreateWithoutStudentsInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutSchoolInput>;
  address: AddressCreateNestedOneWithoutSchoolInput;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutSchoolInput>;
  classes?: InputMaybe<SchoolClassCreateNestedManyWithoutSchoolInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointCreateNestedManyWithoutSchoolInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSchoolInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupCreateNestedOneWithoutSchoolsInput>;
  schoolName: Scalars["String"]["input"];
  schoolNameAr: Scalars["String"]["input"];
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput>;
  semesters?: InputMaybe<SemesterCreateNestedManyWithoutSchoolInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutSchoolInput>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  stages?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutSchoolInput>;
  status?: InputMaybe<SchoolStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeCreateNestedOneWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolForAdhocNotification = {
  __typename?: "SchoolForAdhocNotification";
  id: Scalars["String"]["output"];
  idInt: Scalars["Int"]["output"];
  schoolCode?: Maybe<Scalars["String"]["output"]>;
  schoolName: Scalars["String"]["output"];
  schoolNameAr: Scalars["String"]["output"];
};

export type SchoolForBusAttendant = {
  __typename?: "SchoolForBusAttendant";
  dropOffPoint?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  idInt: Scalars["Int"]["output"];
  pickUpPoint?: Maybe<Scalars["String"]["output"]>;
  schoolCode?: Maybe<Scalars["String"]["output"]>;
  schoolName: Scalars["String"]["output"];
  schoolNameAr: Scalars["String"]["output"];
  status: SchoolStatus;
  stop?: Maybe<Stop>;
  type?: Maybe<SchoolType>;
};

export type SchoolForDriver = {
  __typename?: "SchoolForDriver";
  address: Address;
  dropOffPoint?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  idInt: Scalars["Int"]["output"];
  pickUpPoint?: Maybe<Scalars["String"]["output"]>;
  schoolCode?: Maybe<Scalars["String"]["output"]>;
  schoolName: Scalars["String"]["output"];
  schoolNameAr: Scalars["String"]["output"];
  status: SchoolStatus;
};

export type SchoolForSponsor = {
  __typename?: "SchoolForSponsor";
  dropOffPoint?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  idInt: Scalars["Int"]["output"];
  pickUpPoint?: Maybe<Scalars["String"]["output"]>;
  schoolCode?: Maybe<Scalars["String"]["output"]>;
  schoolName: Scalars["String"]["output"];
  schoolNameAr: Scalars["String"]["output"];
  status: SchoolStatus;
  stop?: Maybe<Stop>;
  type?: Maybe<SchoolType>;
};

export type SchoolForTripStop = {
  __typename?: "SchoolForTripStop";
  id: Scalars["String"]["output"];
  lat: Scalars["Float"]["output"];
  lon: Scalars["Float"]["output"];
  plannedArrivalTime: Scalars["DateTime"]["output"];
  schoolCode?: Maybe<Scalars["String"]["output"]>;
  schoolName: Scalars["String"]["output"];
  schoolNameAr: Scalars["String"]["output"];
};

export type SchoolGroup = {
  __typename?: "SchoolGroup";
  _count: SchoolGroupCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  idInt: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
  schools?: Maybe<Array<School>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolGroupCount = {
  __typename?: "SchoolGroupCount";
  schools: Scalars["Int"]["output"];
};

export type SchoolGroupCreateNestedOneWithoutSchoolsInput = {
  connect?: InputMaybe<SchoolGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolGroupCreateOrConnectWithoutSchoolsInput>;
  create?: InputMaybe<SchoolGroupCreateWithoutSchoolsInput>;
};

export type SchoolGroupCreateOrConnectWithoutSchoolsInput = {
  create: SchoolGroupCreateWithoutSchoolsInput;
  where: SchoolGroupWhereUniqueInput;
};

export type SchoolGroupCreateWithoutSchoolsInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolGroupRelationFilter = {
  is?: InputMaybe<SchoolGroupWhereInput>;
  isNot?: InputMaybe<SchoolGroupWhereInput>;
};

export type SchoolGroupUpdateOneWithoutSchoolsNestedInput = {
  connect?: InputMaybe<SchoolGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolGroupCreateOrConnectWithoutSchoolsInput>;
  create?: InputMaybe<SchoolGroupCreateWithoutSchoolsInput>;
  delete?: InputMaybe<SchoolGroupWhereInput>;
  disconnect?: InputMaybe<SchoolGroupWhereInput>;
  update?: InputMaybe<SchoolGroupUpdateToOneWithWhereWithoutSchoolsInput>;
  upsert?: InputMaybe<SchoolGroupUpsertWithoutSchoolsInput>;
};

export type SchoolGroupUpdateToOneWithWhereWithoutSchoolsInput = {
  data: SchoolGroupUpdateWithoutSchoolsInput;
  where?: InputMaybe<SchoolGroupWhereInput>;
};

export type SchoolGroupUpdateWithoutSchoolsInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolGroupUpsertInput = {
  create?: InputMaybe<SchoolGroupCreateWithoutSchoolsInput>;
  update?: InputMaybe<SchoolGroupUpdateWithoutSchoolsInput>;
  where?: InputMaybe<SchoolGroupWhereUniqueInput>;
};

export type SchoolGroupUpsertWithoutSchoolsInput = {
  create: SchoolGroupCreateWithoutSchoolsInput;
  update: SchoolGroupUpdateWithoutSchoolsInput;
  where?: InputMaybe<SchoolGroupWhereInput>;
};

export type SchoolGroupWhereInput = {
  AND?: InputMaybe<Array<SchoolGroupWhereInput>>;
  NOT?: InputMaybe<Array<SchoolGroupWhereInput>>;
  OR?: InputMaybe<Array<SchoolGroupWhereInput>>;
  code?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  idInt?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  schools?: InputMaybe<SchoolListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolGroupWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolGroupWhereInput>>;
  NOT?: InputMaybe<Array<SchoolGroupWhereInput>>;
  OR?: InputMaybe<Array<SchoolGroupWhereInput>>;
  code?: InputMaybe<StringFilter>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  schools?: InputMaybe<SchoolListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolIdInput = {
  schoolId: Scalars["String"]["input"];
};

export type SchoolListRelationFilter = {
  every?: InputMaybe<SchoolWhereInput>;
  none?: InputMaybe<SchoolWhereInput>;
  some?: InputMaybe<SchoolWhereInput>;
};

export type SchoolRegistration = {
  __typename?: "SchoolRegistration";
  _count: SchoolRegistrationCount;
  agent?: Maybe<Agent>;
  agentId?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  class?: Maybe<Class>;
  classId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  division?: Maybe<Scalars["String"]["output"]>;
  dropoffCollectionPoint?: Maybe<CollectionPoint>;
  dropoffCollectionPointId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  installmentPlan: InstallmentPlan;
  installmentPlanId: Scalars["String"]["output"];
  internalStatus?: Maybe<SchoolRegistrationInternalStatus>;
  isApproved: Scalars["Boolean"]["output"];
  isMultipleKid: Scalars["Boolean"]["output"];
  isPermitRequired: Scalars["Boolean"]["output"];
  isRejectedNotificationSent: Scalars["Boolean"]["output"];
  isToHome: Scalars["Boolean"]["output"];
  isToSchool: Scalars["Boolean"]["output"];
  isWaitlistNotificationSent: Scalars["Boolean"]["output"];
  latestTravelTagDetectionId?: Maybe<Scalars["String"]["output"]>;
  leaveRequests?: Maybe<Array<LeaveRequest>>;
  notes?: Maybe<Scalars["String"]["output"]>;
  paymentLineItems?: Maybe<Array<PaymentLineItem>>;
  paymentPackage?: Maybe<PaymentPackage>;
  paymentStatus?: Maybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: Maybe<CollectionPoint>;
  pickupCollectionPointId?: Maybe<Scalars["String"]["output"]>;
  referenceNumber?: Maybe<Scalars["String"]["output"]>;
  registeredAt?: Maybe<Scalars["DateTime"]["output"]>;
  routeStops?: Maybe<Array<RouteStopSchoolRegistration>>;
  scheduleExclusions?: Maybe<Array<ScheduleExclusion>>;
  scheduleGroup?: Maybe<ScheduleGroup>;
  scheduleGroupId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrationGroup?: Maybe<SchoolRegistrationGroup>;
  schoolRegistrationGroupId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrationStatusChanges?: Maybe<
    Array<SchoolRegistrationStatusChange>
  >;
  sponsor: Sponsor;
  sponsorDisplayStatus?: Maybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["output"];
  statusChangedAt: Scalars["DateTime"]["output"];
  stops?: Maybe<Array<Stop>>;
  student: Student;
  studentId: Scalars["String"]["output"];
  terminatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  terminatedBy?: Maybe<Agent>;
  terminatedByAgentId?: Maybe<Scalars["String"]["output"]>;
  terminationReason?: Maybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: Maybe<Scalars["String"]["output"]>;
  travelTagDetections?: Maybe<Array<TravelTagDetection>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolRegistrationAttempt = {
  __typename?: "SchoolRegistrationAttempt";
  attemptedAt: Scalars["DateTime"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  homeLat: Scalars["Float"]["output"];
  homeLon: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  requestPayload?: Maybe<Scalars["JSON"]["output"]>;
  responsePayload?: Maybe<Scalars["JSON"]["output"]>;
  school: School;
  schoolId: Scalars["String"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type SchoolRegistrationAttemptCreateManySchoolInput = {
  attemptedAt: Scalars["DateTime"]["input"];
  homeLat: Scalars["Float"]["input"];
  homeLon: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  requestPayload?: InputMaybe<Scalars["JSON"]["input"]>;
  responsePayload?: InputMaybe<Scalars["JSON"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type SchoolRegistrationAttemptCreateManySchoolInputEnvelope = {
  data: Array<SchoolRegistrationAttemptCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationAttemptCreateManyUserInput = {
  attemptedAt: Scalars["DateTime"]["input"];
  homeLat: Scalars["Float"]["input"];
  homeLon: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  requestPayload?: InputMaybe<Scalars["JSON"]["input"]>;
  responsePayload?: InputMaybe<Scalars["JSON"]["input"]>;
  schoolId: Scalars["String"]["input"];
};

export type SchoolRegistrationAttemptCreateManyUserInputEnvelope = {
  data: Array<SchoolRegistrationAttemptCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationAttemptCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SchoolRegistrationAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationAttemptCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationAttemptCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolRegistrationAttemptCreateManySchoolInputEnvelope>;
};

export type SchoolRegistrationAttemptCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<SchoolRegistrationAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationAttemptCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationAttemptCreateWithoutUserInput>>;
  createMany?: InputMaybe<SchoolRegistrationAttemptCreateManyUserInputEnvelope>;
};

export type SchoolRegistrationAttemptCreateOrConnectWithoutSchoolInput = {
  create: SchoolRegistrationAttemptCreateWithoutSchoolInput;
  where: SchoolRegistrationAttemptWhereUniqueInput;
};

export type SchoolRegistrationAttemptCreateOrConnectWithoutUserInput = {
  create: SchoolRegistrationAttemptCreateWithoutUserInput;
  where: SchoolRegistrationAttemptWhereUniqueInput;
};

export type SchoolRegistrationAttemptCreateWithoutSchoolInput = {
  attemptedAt: Scalars["DateTime"]["input"];
  homeLat: Scalars["Float"]["input"];
  homeLon: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  requestPayload?: InputMaybe<Scalars["JSON"]["input"]>;
  responsePayload?: InputMaybe<Scalars["JSON"]["input"]>;
  user: UserCreateNestedOneWithoutSchoolRegistrationAttemptsInput;
};

export type SchoolRegistrationAttemptCreateWithoutUserInput = {
  attemptedAt: Scalars["DateTime"]["input"];
  homeLat: Scalars["Float"]["input"];
  homeLon: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  requestPayload?: InputMaybe<Scalars["JSON"]["input"]>;
  responsePayload?: InputMaybe<Scalars["JSON"]["input"]>;
  school: SchoolCreateNestedOneWithoutSchoolRegistrationAttemptsInput;
};

export type SchoolRegistrationAttemptListRelationFilter = {
  every?: InputMaybe<SchoolRegistrationAttemptWhereInput>;
  none?: InputMaybe<SchoolRegistrationAttemptWhereInput>;
  some?: InputMaybe<SchoolRegistrationAttemptWhereInput>;
};

export type SchoolRegistrationAttemptScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolRegistrationAttemptScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolRegistrationAttemptScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolRegistrationAttemptScalarWhereInput>>;
  attemptedAt?: InputMaybe<DateTimeFilter>;
  homeLat?: InputMaybe<FloatFilter>;
  homeLon?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  requestPayload?: InputMaybe<JsonFilter>;
  responsePayload?: InputMaybe<JsonFilter>;
  schoolId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SchoolRegistrationAttemptUpdateManyMutationInput = {
  attemptedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  homeLat?: InputMaybe<Scalars["Float"]["input"]>;
  homeLon?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  requestPayload?: InputMaybe<Scalars["JSON"]["input"]>;
  responsePayload?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type SchoolRegistrationAttemptUpdateManyWithWhereWithoutSchoolInput = {
  data: SchoolRegistrationAttemptUpdateManyMutationInput;
  where: SchoolRegistrationAttemptScalarWhereInput;
};

export type SchoolRegistrationAttemptUpdateManyWithWhereWithoutUserInput = {
  data: SchoolRegistrationAttemptUpdateManyMutationInput;
  where: SchoolRegistrationAttemptScalarWhereInput;
};

export type SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SchoolRegistrationAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationAttemptCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationAttemptCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolRegistrationAttemptCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SchoolRegistrationAttemptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolRegistrationAttemptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolRegistrationAttemptWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolRegistrationAttemptWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolRegistrationAttemptUpdateWithWhereUniqueWithoutSchoolInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolRegistrationAttemptUpdateManyWithWhereWithoutSchoolInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolRegistrationAttemptUpsertWithWhereUniqueWithoutSchoolInput>
  >;
};

export type SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<SchoolRegistrationAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationAttemptCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationAttemptCreateWithoutUserInput>>;
  createMany?: InputMaybe<SchoolRegistrationAttemptCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<SchoolRegistrationAttemptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolRegistrationAttemptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolRegistrationAttemptWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolRegistrationAttemptWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolRegistrationAttemptUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolRegistrationAttemptUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolRegistrationAttemptUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type SchoolRegistrationAttemptUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SchoolRegistrationAttemptUpdateWithoutSchoolInput;
  where: SchoolRegistrationAttemptWhereUniqueInput;
};

export type SchoolRegistrationAttemptUpdateWithWhereUniqueWithoutUserInput = {
  data: SchoolRegistrationAttemptUpdateWithoutUserInput;
  where: SchoolRegistrationAttemptWhereUniqueInput;
};

export type SchoolRegistrationAttemptUpdateWithoutSchoolInput = {
  attemptedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  homeLat?: InputMaybe<Scalars["Float"]["input"]>;
  homeLon?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  requestPayload?: InputMaybe<Scalars["JSON"]["input"]>;
  responsePayload?: InputMaybe<Scalars["JSON"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSchoolRegistrationAttemptsNestedInput>;
};

export type SchoolRegistrationAttemptUpdateWithoutUserInput = {
  attemptedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  homeLat?: InputMaybe<Scalars["Float"]["input"]>;
  homeLon?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  requestPayload?: InputMaybe<Scalars["JSON"]["input"]>;
  responsePayload?: InputMaybe<Scalars["JSON"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSchoolRegistrationAttemptsNestedInput>;
};

export type SchoolRegistrationAttemptUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SchoolRegistrationAttemptCreateWithoutSchoolInput;
  update: SchoolRegistrationAttemptUpdateWithoutSchoolInput;
  where: SchoolRegistrationAttemptWhereUniqueInput;
};

export type SchoolRegistrationAttemptUpsertWithWhereUniqueWithoutUserInput = {
  create: SchoolRegistrationAttemptCreateWithoutUserInput;
  update: SchoolRegistrationAttemptUpdateWithoutUserInput;
  where: SchoolRegistrationAttemptWhereUniqueInput;
};

export type SchoolRegistrationAttemptWhereInput = {
  AND?: InputMaybe<Array<SchoolRegistrationAttemptWhereInput>>;
  NOT?: InputMaybe<Array<SchoolRegistrationAttemptWhereInput>>;
  OR?: InputMaybe<Array<SchoolRegistrationAttemptWhereInput>>;
  attemptedAt?: InputMaybe<DateTimeFilter>;
  homeLat?: InputMaybe<FloatFilter>;
  homeLon?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  requestPayload?: InputMaybe<JsonFilter>;
  responsePayload?: InputMaybe<JsonFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SchoolRegistrationAttemptWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolRegistrationAttemptWhereInput>>;
  NOT?: InputMaybe<Array<SchoolRegistrationAttemptWhereInput>>;
  OR?: InputMaybe<Array<SchoolRegistrationAttemptWhereInput>>;
  attemptedAt?: InputMaybe<DateTimeFilter>;
  homeLat?: InputMaybe<FloatFilter>;
  homeLon?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  requestPayload?: InputMaybe<JsonFilter>;
  responsePayload?: InputMaybe<JsonFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SchoolRegistrationConfiguration = {
  __typename?: "SchoolRegistrationConfiguration";
  contacts: Array<Contact>;
  isRegistrationEnabled: Scalars["Boolean"]["output"];
  /** @deprecated not used anymore */
  multipleKidFactor?: Maybe<Scalars["Float"]["output"]>;
  studentNamesLists?: Maybe<StudentNameLists>;
};

export type SchoolRegistrationCount = {
  __typename?: "SchoolRegistrationCount";
  leaveRequests: Scalars["Int"]["output"];
  paymentLineItems: Scalars["Int"]["output"];
  routeStops: Scalars["Int"]["output"];
  scheduleExclusions: Scalars["Int"]["output"];
  schoolRegistrationStatusChanges: Scalars["Int"]["output"];
  stops: Scalars["Int"]["output"];
  travelTagDetections: Scalars["Int"]["output"];
};

export type SchoolRegistrationCreateManyAgentInput = {
  classId?: InputMaybe<Scalars["String"]["input"]>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlanId: Scalars["String"]["input"];
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["input"];
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  studentId: Scalars["String"]["input"];
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedByAgentId?: InputMaybe<Scalars["String"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateManyAgentInputEnvelope = {
  data: Array<SchoolRegistrationCreateManyAgentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationCreateManyClassInput = {
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlanId: Scalars["String"]["input"];
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["input"];
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  studentId: Scalars["String"]["input"];
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedByAgentId?: InputMaybe<Scalars["String"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateManyClassInputEnvelope = {
  data: Array<SchoolRegistrationCreateManyClassInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationCreateManyDropoffCollectionPointInput = {
  classId?: InputMaybe<Scalars["String"]["input"]>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlanId: Scalars["String"]["input"];
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["input"];
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  studentId: Scalars["String"]["input"];
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedByAgentId?: InputMaybe<Scalars["String"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateManyDropoffCollectionPointInputEnvelope = {
  data: Array<SchoolRegistrationCreateManyDropoffCollectionPointInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationCreateManyInstallmentPlanInput = {
  classId?: InputMaybe<Scalars["String"]["input"]>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["input"];
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  studentId: Scalars["String"]["input"];
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedByAgentId?: InputMaybe<Scalars["String"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateManyInstallmentPlanInputEnvelope = {
  data: Array<SchoolRegistrationCreateManyInstallmentPlanInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationCreateManyPickupCollectionPointInput = {
  classId?: InputMaybe<Scalars["String"]["input"]>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlanId: Scalars["String"]["input"];
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["input"];
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  studentId: Scalars["String"]["input"];
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedByAgentId?: InputMaybe<Scalars["String"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateManyPickupCollectionPointInputEnvelope = {
  data: Array<SchoolRegistrationCreateManyPickupCollectionPointInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationCreateManyScheduleGroupInput = {
  classId?: InputMaybe<Scalars["String"]["input"]>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlanId: Scalars["String"]["input"];
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["input"];
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  studentId: Scalars["String"]["input"];
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedByAgentId?: InputMaybe<Scalars["String"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateManyScheduleGroupInputEnvelope = {
  data: Array<SchoolRegistrationCreateManyScheduleGroupInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationCreateManySponsorInput = {
  classId?: InputMaybe<Scalars["String"]["input"]>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlanId: Scalars["String"]["input"];
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  studentId: Scalars["String"]["input"];
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedByAgentId?: InputMaybe<Scalars["String"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateManySponsorInputEnvelope = {
  data: Array<SchoolRegistrationCreateManySponsorInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationCreateManyStudentInput = {
  classId?: InputMaybe<Scalars["String"]["input"]>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlanId: Scalars["String"]["input"];
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["input"];
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedByAgentId?: InputMaybe<Scalars["String"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateManyStudentInputEnvelope = {
  data: Array<SchoolRegistrationCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationCreateManyTerminatedByInput = {
  classId?: InputMaybe<Scalars["String"]["input"]>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlanId: Scalars["String"]["input"];
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPointId?: InputMaybe<Scalars["String"]["input"]>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["input"];
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  studentId: Scalars["String"]["input"];
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateManyTerminatedByInputEnvelope = {
  data: Array<SchoolRegistrationCreateManyTerminatedByInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationCreateNestedManyWithoutAgentInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutAgentInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutAgentInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManyAgentInputEnvelope>;
};

export type SchoolRegistrationCreateNestedManyWithoutClassInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutClassInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutClassInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManyClassInputEnvelope>;
};

export type SchoolRegistrationCreateNestedManyWithoutDropoffCollectionPointInput =
  {
    connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<SchoolRegistrationCreateOrConnectWithoutDropoffCollectionPointInput>
    >;
    create?: InputMaybe<
      Array<SchoolRegistrationCreateWithoutDropoffCollectionPointInput>
    >;
    createMany?: InputMaybe<SchoolRegistrationCreateManyDropoffCollectionPointInputEnvelope>;
  };

export type SchoolRegistrationCreateNestedManyWithoutInstallmentPlanInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutInstallmentPlanInput>
  >;
  create?: InputMaybe<
    Array<SchoolRegistrationCreateWithoutInstallmentPlanInput>
  >;
  createMany?: InputMaybe<SchoolRegistrationCreateManyInstallmentPlanInputEnvelope>;
};

export type SchoolRegistrationCreateNestedManyWithoutPickupCollectionPointInput =
  {
    connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<SchoolRegistrationCreateOrConnectWithoutPickupCollectionPointInput>
    >;
    create?: InputMaybe<
      Array<SchoolRegistrationCreateWithoutPickupCollectionPointInput>
    >;
    createMany?: InputMaybe<SchoolRegistrationCreateManyPickupCollectionPointInputEnvelope>;
  };

export type SchoolRegistrationCreateNestedManyWithoutScheduleGroupInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutScheduleGroupInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutScheduleGroupInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManyScheduleGroupInputEnvelope>;
};

export type SchoolRegistrationCreateNestedManyWithoutSponsorInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutSponsorInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutSponsorInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManySponsorInputEnvelope>;
};

export type SchoolRegistrationCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutStudentInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutStudentInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManyStudentInputEnvelope>;
};

export type SchoolRegistrationCreateNestedManyWithoutTerminatedByInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutTerminatedByInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutTerminatedByInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManyTerminatedByInputEnvelope>;
};

export type SchoolRegistrationCreateNestedOneWithoutLeaveRequestsInput = {
  connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutLeaveRequestsInput>;
  create?: InputMaybe<SchoolRegistrationCreateWithoutLeaveRequestsInput>;
};

export type SchoolRegistrationCreateNestedOneWithoutPaymentLineItemsInput = {
  connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutPaymentLineItemsInput>;
  create?: InputMaybe<SchoolRegistrationCreateWithoutPaymentLineItemsInput>;
};

export type SchoolRegistrationCreateNestedOneWithoutPaymentPackageInput = {
  connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutPaymentPackageInput>;
  create?: InputMaybe<SchoolRegistrationCreateWithoutPaymentPackageInput>;
};

export type SchoolRegistrationCreateNestedOneWithoutRouteStopsInput = {
  connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutRouteStopsInput>;
  create?: InputMaybe<SchoolRegistrationCreateWithoutRouteStopsInput>;
};

export type SchoolRegistrationCreateNestedOneWithoutScheduleExclusionsInput = {
  connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutScheduleExclusionsInput>;
  create?: InputMaybe<SchoolRegistrationCreateWithoutScheduleExclusionsInput>;
};

export type SchoolRegistrationCreateNestedOneWithoutSchoolRegistrationStatusChangesInput =
  {
    connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutSchoolRegistrationStatusChangesInput>;
    create?: InputMaybe<SchoolRegistrationCreateWithoutSchoolRegistrationStatusChangesInput>;
  };

export type SchoolRegistrationCreateNestedOneWithoutStopsInput = {
  connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutStopsInput>;
  create?: InputMaybe<SchoolRegistrationCreateWithoutStopsInput>;
};

export type SchoolRegistrationCreateNestedOneWithoutTravelTagDetectionsInput = {
  connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutTravelTagDetectionsInput>;
  create?: InputMaybe<SchoolRegistrationCreateWithoutTravelTagDetectionsInput>;
};

export type SchoolRegistrationCreateOrConnectWithoutAgentInput = {
  create: SchoolRegistrationCreateWithoutAgentInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutClassInput = {
  create: SchoolRegistrationCreateWithoutClassInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutDropoffCollectionPointInput =
  {
    create: SchoolRegistrationCreateWithoutDropoffCollectionPointInput;
    where: SchoolRegistrationWhereUniqueInput;
  };

export type SchoolRegistrationCreateOrConnectWithoutInstallmentPlanInput = {
  create: SchoolRegistrationCreateWithoutInstallmentPlanInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutLeaveRequestsInput = {
  create: SchoolRegistrationCreateWithoutLeaveRequestsInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutPaymentLineItemsInput = {
  create: SchoolRegistrationCreateWithoutPaymentLineItemsInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutPaymentPackageInput = {
  create: SchoolRegistrationCreateWithoutPaymentPackageInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutPickupCollectionPointInput =
  {
    create: SchoolRegistrationCreateWithoutPickupCollectionPointInput;
    where: SchoolRegistrationWhereUniqueInput;
  };

export type SchoolRegistrationCreateOrConnectWithoutRouteStopsInput = {
  create: SchoolRegistrationCreateWithoutRouteStopsInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutScheduleExclusionsInput = {
  create: SchoolRegistrationCreateWithoutScheduleExclusionsInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutScheduleGroupInput = {
  create: SchoolRegistrationCreateWithoutScheduleGroupInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutSchoolRegistrationStatusChangesInput =
  {
    create: SchoolRegistrationCreateWithoutSchoolRegistrationStatusChangesInput;
    where: SchoolRegistrationWhereUniqueInput;
  };

export type SchoolRegistrationCreateOrConnectWithoutSponsorInput = {
  create: SchoolRegistrationCreateWithoutSponsorInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutStopsInput = {
  create: SchoolRegistrationCreateWithoutStopsInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutStudentInput = {
  create: SchoolRegistrationCreateWithoutStudentInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutTerminatedByInput = {
  create: SchoolRegistrationCreateWithoutTerminatedByInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateOrConnectWithoutTravelTagDetectionsInput = {
  create: SchoolRegistrationCreateWithoutTravelTagDetectionsInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationCreateWithoutAgentInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutClassInput = {
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutDropoffCollectionPointInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutInstallmentPlanInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutLeaveRequestsInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutPaymentLineItemsInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutPaymentPackageInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutPickupCollectionPointInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutRouteStopsInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutScheduleExclusionsInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutScheduleGroupInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutSchoolRegistrationStatusChangesInput =
  {
    class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
    division?: InputMaybe<Scalars["String"]["input"]>;
    dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
    internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
    isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
    isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
    isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
    isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
    isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
    isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
    latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
    leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
    notes?: InputMaybe<Scalars["String"]["input"]>;
    paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
    paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
    pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
    referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
    registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
    scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
    scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
    sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
    sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
    statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
    terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
    terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
    terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
    travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  };

export type SchoolRegistrationCreateWithoutSponsorInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutStopsInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutStudentInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutTerminatedByInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationCreateWithoutTravelTagDetectionsInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageCreateNestedOneWithoutSchoolRegistrationInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsor: SponsorCreateNestedOneWithoutSchoolRegistrationInput;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationFilterOutput = {
  __typename?: "SchoolRegistrationFilterOutput";
  countryCodes: Array<Scalars["String"]["output"]>;
  routeIds: Array<Scalars["String"]["output"]>;
  schoolIds: Array<Scalars["String"]["output"]>;
  schoolRegistrationsIds: Array<SchoolRegistration>;
};

export type SchoolRegistrationForBusAttendant = {
  __typename?: "SchoolRegistrationForBusAttendant";
  class?: Maybe<Class>;
  division?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isPermitRequired: Scalars["Boolean"]["output"];
  isToHome: Scalars["Boolean"]["output"];
  isToSchool: Scalars["Boolean"]["output"];
  stops?: Maybe<Array<Stop>>;
};

export type SchoolRegistrationForSponsor = {
  __typename?: "SchoolRegistrationForSponsor";
  class?: Maybe<Class>;
  division?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isPermitRequired: Scalars["Boolean"]["output"];
  isToHome: Scalars["Boolean"]["output"];
  isToSchool: Scalars["Boolean"]["output"];
  sponsorDisplayStatus?: Maybe<SchoolRegistrationSponsorDisplayStatus>;
  stops?: Maybe<Array<Stop>>;
};

export type SchoolRegistrationForSponsorCreateInput = {
  administrativeAreaId: Scalars["String"]["input"];
  block?: InputMaybe<Scalars["String"]["input"]>;
  building?: InputMaybe<Scalars["String"]["input"]>;
  buildingType?: InputMaybe<BuildingType>;
  classId: Scalars["String"]["input"];
  dateOfBirth: Scalars["DateTime"]["input"];
  division?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  flat?: InputMaybe<Scalars["String"]["input"]>;
  floor?: InputMaybe<Scalars["String"]["input"]>;
  gender: Gender;
  homeLat: Scalars["Float"]["input"];
  homeLon: Scalars["Float"]["input"];
  installmentPlanId: Scalars["String"]["input"];
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  localityId?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  photo: Scalars["String"]["input"];
  relationship: Relationship;
  scheduleGroupId: Scalars["String"]["input"];
  schoolId?: InputMaybe<Scalars["String"]["input"]>;
  specialNeedsIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  studentId?: InputMaybe<Scalars["String"]["input"]>;
  subLocalityId?: InputMaybe<Scalars["String"]["input"]>;
  way?: InputMaybe<Scalars["String"]["input"]>;
};

export type SchoolRegistrationGroup = {
  __typename?: "SchoolRegistrationGroup";
  _count: SchoolRegistrationGroupCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  referenceNumber?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrations?: Maybe<Array<SchoolRegistration>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolRegistrationGroupCount = {
  __typename?: "SchoolRegistrationGroupCount";
  schoolRegistrations: Scalars["Int"]["output"];
};

export enum SchoolRegistrationInternalStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  COMPLETED_SERVICE = "COMPLETED_SERVICE",
  DEPOSIT_PENDING = "DEPOSIT_PENDING",
  ENDED = "ENDED",
  ONBOARDING = "ONBOARDING",
  REJECTED = "REJECTED",
  ROUTE_PLANNING = "ROUTE_PLANNING",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
  VALIDATION = "VALIDATION",
  WAITLISTED = "WAITLISTED",
}

export type SchoolRegistrationListRelationFilter = {
  every?: InputMaybe<SchoolRegistrationWhereInput>;
  none?: InputMaybe<SchoolRegistrationWhereInput>;
  some?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationNotificationInput = {
  arabicContent: Scalars["String"]["input"];
  arabicTitle: Scalars["String"]["input"];
  countryCodes: Array<Scalars["String"]["input"]>;
  direction?: InputMaybe<Array<Scalars["String"]["input"]>>;
  englishContent: Scalars["String"]["input"];
  englishTitle: Scalars["String"]["input"];
  isPushNotification: Scalars["Boolean"]["input"];
  isSMS: Scalars["Boolean"]["input"];
  paymentStatus?: InputMaybe<Array<Scalars["String"]["input"]>>;
  pushNotificationType?: InputMaybe<PushNotificationType>;
  registrationStatus?: InputMaybe<Array<Scalars["String"]["input"]>>;
  routeIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  schoolIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  schoolRegistrationsIds: Array<Scalars["String"]["input"]>;
};

export enum SchoolRegistrationPaymentStatus {
  DEPOSIT_PENDING = "DEPOSIT_PENDING",
  FULLY_PAID = "FULLY_PAID",
  INSTALLMENT_PAID = "INSTALLMENT_PAID",
  INSTALLMENT_PENDING = "INSTALLMENT_PENDING",
  OVERDUE = "OVERDUE",
}

export type SchoolRegistrationRelationFilter = {
  is?: InputMaybe<SchoolRegistrationWhereInput>;
  isNot?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
  classId?: InputMaybe<StringFilter>;
  division?: InputMaybe<StringFilter>;
  dropoffCollectionPointId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  internalStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  isApproved?: InputMaybe<BoolFilter>;
  isPermitRequired?: InputMaybe<BoolFilter>;
  isRejectedNotificationSent?: InputMaybe<BoolFilter>;
  isToHome?: InputMaybe<BoolFilter>;
  isToSchool?: InputMaybe<BoolFilter>;
  isWaitlistNotificationSent?: InputMaybe<BoolFilter>;
  latestTravelTagDetectionId?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  paymentStatus?: InputMaybe<EnumSchoolRegistrationPaymentStatusFilter>;
  pickupCollectionPointId?: InputMaybe<StringFilter>;
  referenceNumber?: InputMaybe<StringFilter>;
  registeredAt?: InputMaybe<DateTimeFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  sponsorDisplayStatus?: InputMaybe<EnumSchoolRegistrationSponsorDisplayStatusFilter>;
  sponsorId?: InputMaybe<StringFilter>;
  statusChangedAt?: InputMaybe<DateTimeFilter>;
  studentId?: InputMaybe<StringFilter>;
  terminatedAt?: InputMaybe<DateTimeFilter>;
  terminatedByAgentId?: InputMaybe<StringFilter>;
  terminationReason?: InputMaybe<EnumSchoolRegistrationTerminationReasonFilter>;
  terminationReasonOther?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolRegistrationSingleCreateArrayInput = {
  create: Array<SchoolRegistrationSingleCreateInput>;
};

export type SchoolRegistrationSingleCreateInput = {
  class?: InputMaybe<ClassCreateNestedOneWithoutSchoolRegistrationsInput>;
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutDropoffSchoolRegistrationsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan: InstallmentPlanCreateNestedOneWithoutSchoolRegistrationsInput;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFirstPayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPayLater?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSchoolRegistrationInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  offerCode?: InputMaybe<Scalars["String"]["input"]>;
  paymentLineItems?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  paymentRef?: InputMaybe<Scalars["String"]["input"]>;
  paymentSolution?: InputMaybe<PaymentSolution>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  paymentType?: InputMaybe<PaymentType>;
  pickupCollectionPoint?: InputMaybe<CollectionPointCreateNestedOneWithoutPickupSchoolRegistrationsInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionCreateNestedManyWithoutSchoolRegistrationInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupCreateNestedOneWithoutSchoolRegistrationsInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateUpdateNestedOneWithoutSchoolRegistrationInput;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentCreateNestedOneWithoutTerminatedBySchoolRegistrationsInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationSingleUpdateInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentRef?: InputMaybe<Scalars["String"]["input"]>;
  paymentSolution?: InputMaybe<PaymentSolution>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  paymentType?: InputMaybe<PaymentType>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  selectedPaymentLineItemIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum SchoolRegistrationSponsorDisplayStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  COMPLETED_SERVICE = "COMPLETED_SERVICE",
  DEPOSIT_PENDING = "DEPOSIT_PENDING",
  ENDED = "ENDED",
  PROCESSING = "PROCESSING",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
  WAITLISTED = "WAITLISTED",
}

export enum SchoolRegistrationStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  PROCESSING = "PROCESSING",
  REJECTED = "REJECTED",
  ROUTE_PLANNING = "ROUTE_PLANNING",
  SUSPENDED = "SUSPENDED",
}

export type SchoolRegistrationStatusChange = {
  __typename?: "SchoolRegistrationStatusChange";
  agent?: Maybe<Agent>;
  agentId?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  changedAt: Scalars["DateTime"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  fromInternalStatus?: Maybe<SchoolRegistrationInternalStatus>;
  fromSponsorDisplayStatus?: Maybe<SchoolRegistrationSponsorDisplayStatus>;
  fromStatus?: Maybe<SchoolRegistrationStatus>;
  id: Scalars["String"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  schoolRegistration: SchoolRegistration;
  schoolRegistrationId: Scalars["String"]["output"];
  toInternalStatus?: Maybe<SchoolRegistrationInternalStatus>;
  toSponsorDisplayStatus?: Maybe<SchoolRegistrationSponsorDisplayStatus>;
  toStatus?: Maybe<SchoolRegistrationStatus>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolRegistrationStatusChangeCreateManyAgentInput = {
  changedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  fromInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  fromSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  fromStatus?: InputMaybe<SchoolRegistrationStatus>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationId: Scalars["String"]["input"];
  toInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  toSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  toStatus?: InputMaybe<SchoolRegistrationStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationStatusChangeCreateManyAgentInputEnvelope = {
  data: Array<SchoolRegistrationStatusChangeCreateManyAgentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolRegistrationStatusChangeCreateManySchoolRegistrationInput = {
  agentId?: InputMaybe<Scalars["String"]["input"]>;
  changedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  fromInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  fromSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  fromStatus?: InputMaybe<SchoolRegistrationStatus>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  toInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  toSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  toStatus?: InputMaybe<SchoolRegistrationStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationStatusChangeCreateManySchoolRegistrationInputEnvelope =
  {
    data: Array<SchoolRegistrationStatusChangeCreateManySchoolRegistrationInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type SchoolRegistrationStatusChangeCreateNestedManyWithoutAgentInput = {
  connect?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationStatusChangeCreateOrConnectWithoutAgentInput>
  >;
  create?: InputMaybe<
    Array<SchoolRegistrationStatusChangeCreateWithoutAgentInput>
  >;
  createMany?: InputMaybe<SchoolRegistrationStatusChangeCreateManyAgentInputEnvelope>;
};

export type SchoolRegistrationStatusChangeCreateNestedManyWithoutSchoolRegistrationInput =
  {
    connect?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<SchoolRegistrationStatusChangeCreateOrConnectWithoutSchoolRegistrationInput>
    >;
    create?: InputMaybe<
      Array<SchoolRegistrationStatusChangeCreateWithoutSchoolRegistrationInput>
    >;
    createMany?: InputMaybe<SchoolRegistrationStatusChangeCreateManySchoolRegistrationInputEnvelope>;
  };

export type SchoolRegistrationStatusChangeCreateOrConnectWithoutAgentInput = {
  create: SchoolRegistrationStatusChangeCreateWithoutAgentInput;
  where: SchoolRegistrationStatusChangeWhereUniqueInput;
};

export type SchoolRegistrationStatusChangeCreateOrConnectWithoutSchoolRegistrationInput =
  {
    create: SchoolRegistrationStatusChangeCreateWithoutSchoolRegistrationInput;
    where: SchoolRegistrationStatusChangeWhereUniqueInput;
  };

export type SchoolRegistrationStatusChangeCreateWithoutAgentInput = {
  changedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  fromInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  fromSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  fromStatus?: InputMaybe<SchoolRegistrationStatus>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutSchoolRegistrationStatusChangesInput;
  toInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  toSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  toStatus?: InputMaybe<SchoolRegistrationStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationStatusChangeCreateWithoutSchoolRegistrationInput =
  {
    agent?: InputMaybe<AgentCreateNestedOneWithoutSchoolRegistrationStatusChangeInput>;
    changedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    fromInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
    fromSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
    fromStatus?: InputMaybe<SchoolRegistrationStatus>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    notes?: InputMaybe<Scalars["String"]["input"]>;
    toInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
    toSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
    toStatus?: InputMaybe<SchoolRegistrationStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  };

export type SchoolRegistrationStatusChangeListRelationFilter = {
  every?: InputMaybe<SchoolRegistrationStatusChangeWhereInput>;
  none?: InputMaybe<SchoolRegistrationStatusChangeWhereInput>;
  some?: InputMaybe<SchoolRegistrationStatusChangeWhereInput>;
};

export type SchoolRegistrationStatusChangeScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolRegistrationStatusChangeScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolRegistrationStatusChangeScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolRegistrationStatusChangeScalarWhereInput>>;
  agentId?: InputMaybe<StringFilter>;
  changedAt?: InputMaybe<DateTimeFilter>;
  fromInternalStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  fromSponsorDisplayStatus?: InputMaybe<EnumSchoolRegistrationSponsorDisplayStatusFilter>;
  fromStatus?: InputMaybe<EnumSchoolRegistrationStatusFilter>;
  id?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  toInternalStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  toSponsorDisplayStatus?: InputMaybe<EnumSchoolRegistrationSponsorDisplayStatusFilter>;
  toStatus?: InputMaybe<EnumSchoolRegistrationStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolRegistrationStatusChangeUpdateManyMutationInput = {
  changedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  fromInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  fromSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  fromStatus?: InputMaybe<SchoolRegistrationStatus>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  toInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  toSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  toStatus?: InputMaybe<SchoolRegistrationStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationStatusChangeUpdateManyWithWhereWithoutAgentInput =
  {
    data: SchoolRegistrationStatusChangeUpdateManyMutationInput;
    where: SchoolRegistrationStatusChangeScalarWhereInput;
  };

export type SchoolRegistrationStatusChangeUpdateManyWithWhereWithoutSchoolRegistrationInput =
  {
    data: SchoolRegistrationStatusChangeUpdateManyMutationInput;
    where: SchoolRegistrationStatusChangeScalarWhereInput;
  };

export type SchoolRegistrationStatusChangeUpdateManyWithoutAgentNestedInput = {
  connect?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationStatusChangeCreateOrConnectWithoutAgentInput>
  >;
  create?: InputMaybe<
    Array<SchoolRegistrationStatusChangeCreateWithoutAgentInput>
  >;
  createMany?: InputMaybe<SchoolRegistrationStatusChangeCreateManyAgentInputEnvelope>;
  delete?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereUniqueInput>>;
  deleteMany?: InputMaybe<
    Array<SchoolRegistrationStatusChangeScalarWhereInput>
  >;
  disconnect?: InputMaybe<
    Array<SchoolRegistrationStatusChangeWhereUniqueInput>
  >;
  set?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolRegistrationStatusChangeUpdateWithWhereUniqueWithoutAgentInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolRegistrationStatusChangeUpdateManyWithWhereWithoutAgentInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolRegistrationStatusChangeUpsertWithWhereUniqueWithoutAgentInput>
  >;
};

export type SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput =
  {
    connect?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<SchoolRegistrationStatusChangeCreateOrConnectWithoutSchoolRegistrationInput>
    >;
    create?: InputMaybe<
      Array<SchoolRegistrationStatusChangeCreateWithoutSchoolRegistrationInput>
    >;
    createMany?: InputMaybe<SchoolRegistrationStatusChangeCreateManySchoolRegistrationInputEnvelope>;
    delete?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereUniqueInput>>;
    deleteMany?: InputMaybe<
      Array<SchoolRegistrationStatusChangeScalarWhereInput>
    >;
    disconnect?: InputMaybe<
      Array<SchoolRegistrationStatusChangeWhereUniqueInput>
    >;
    set?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereUniqueInput>>;
    update?: InputMaybe<
      Array<SchoolRegistrationStatusChangeUpdateWithWhereUniqueWithoutSchoolRegistrationInput>
    >;
    updateMany?: InputMaybe<
      Array<SchoolRegistrationStatusChangeUpdateManyWithWhereWithoutSchoolRegistrationInput>
    >;
    upsert?: InputMaybe<
      Array<SchoolRegistrationStatusChangeUpsertWithWhereUniqueWithoutSchoolRegistrationInput>
    >;
  };

export type SchoolRegistrationStatusChangeUpdateWithWhereUniqueWithoutAgentInput =
  {
    data: SchoolRegistrationStatusChangeUpdateWithoutAgentInput;
    where: SchoolRegistrationStatusChangeWhereUniqueInput;
  };

export type SchoolRegistrationStatusChangeUpdateWithWhereUniqueWithoutSchoolRegistrationInput =
  {
    data: SchoolRegistrationStatusChangeUpdateWithoutSchoolRegistrationInput;
    where: SchoolRegistrationStatusChangeWhereUniqueInput;
  };

export type SchoolRegistrationStatusChangeUpdateWithoutAgentInput = {
  changedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  fromInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  fromSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  fromStatus?: InputMaybe<SchoolRegistrationStatus>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutSchoolRegistrationStatusChangesNestedInput>;
  toInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  toSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  toStatus?: InputMaybe<SchoolRegistrationStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationStatusChangeUpdateWithoutSchoolRegistrationInput =
  {
    agent?: InputMaybe<AgentUpdateOneWithoutSchoolRegistrationStatusChangeNestedInput>;
    changedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    fromInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
    fromSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
    fromStatus?: InputMaybe<SchoolRegistrationStatus>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    notes?: InputMaybe<Scalars["String"]["input"]>;
    toInternalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
    toSponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
    toStatus?: InputMaybe<SchoolRegistrationStatus>;
    updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  };

export type SchoolRegistrationStatusChangeUpsertWithWhereUniqueWithoutAgentInput =
  {
    create: SchoolRegistrationStatusChangeCreateWithoutAgentInput;
    update: SchoolRegistrationStatusChangeUpdateWithoutAgentInput;
    where: SchoolRegistrationStatusChangeWhereUniqueInput;
  };

export type SchoolRegistrationStatusChangeUpsertWithWhereUniqueWithoutSchoolRegistrationInput =
  {
    create: SchoolRegistrationStatusChangeCreateWithoutSchoolRegistrationInput;
    update: SchoolRegistrationStatusChangeUpdateWithoutSchoolRegistrationInput;
    where: SchoolRegistrationStatusChangeWhereUniqueInput;
  };

export type SchoolRegistrationStatusChangeWhereInput = {
  AND?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereInput>>;
  NOT?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereInput>>;
  OR?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereInput>>;
  agent?: InputMaybe<AgentRelationFilter>;
  agentId?: InputMaybe<StringFilter>;
  changedAt?: InputMaybe<DateTimeFilter>;
  fromInternalStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  fromSponsorDisplayStatus?: InputMaybe<EnumSchoolRegistrationSponsorDisplayStatusFilter>;
  fromStatus?: InputMaybe<EnumSchoolRegistrationStatusFilter>;
  id?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  toInternalStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  toSponsorDisplayStatus?: InputMaybe<EnumSchoolRegistrationSponsorDisplayStatusFilter>;
  toStatus?: InputMaybe<EnumSchoolRegistrationStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolRegistrationStatusChangeWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereInput>>;
  NOT?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereInput>>;
  OR?: InputMaybe<Array<SchoolRegistrationStatusChangeWhereInput>>;
  agent?: InputMaybe<AgentRelationFilter>;
  agentId?: InputMaybe<StringFilter>;
  changedAt?: InputMaybe<DateTimeFilter>;
  fromInternalStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  fromSponsorDisplayStatus?: InputMaybe<EnumSchoolRegistrationSponsorDisplayStatusFilter>;
  fromStatus?: InputMaybe<EnumSchoolRegistrationStatusFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  toInternalStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  toSponsorDisplayStatus?: InputMaybe<EnumSchoolRegistrationSponsorDisplayStatusFilter>;
  toStatus?: InputMaybe<EnumSchoolRegistrationStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum SchoolRegistrationTerminationReason {
  OTHER = "OTHER",
  OVERDUE_PAYMENT = "OVERDUE_PAYMENT",
}

export type SchoolRegistrationUncheckedUpdateManyExtInput = {
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
};

export type SchoolRegistrationUpdateManyMutationInput = {
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateManyMutationWhereInput = {
  data?: InputMaybe<SchoolRegistrationUncheckedUpdateManyExtInput>;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationUpdateManyWithWhereWithoutAgentInput = {
  data: SchoolRegistrationUpdateManyMutationInput;
  where: SchoolRegistrationScalarWhereInput;
};

export type SchoolRegistrationUpdateManyWithWhereWithoutClassInput = {
  data: SchoolRegistrationUpdateManyMutationInput;
  where: SchoolRegistrationScalarWhereInput;
};

export type SchoolRegistrationUpdateManyWithWhereWithoutDropoffCollectionPointInput =
  {
    data: SchoolRegistrationUpdateManyMutationInput;
    where: SchoolRegistrationScalarWhereInput;
  };

export type SchoolRegistrationUpdateManyWithWhereWithoutInstallmentPlanInput = {
  data: SchoolRegistrationUpdateManyMutationInput;
  where: SchoolRegistrationScalarWhereInput;
};

export type SchoolRegistrationUpdateManyWithWhereWithoutPickupCollectionPointInput =
  {
    data: SchoolRegistrationUpdateManyMutationInput;
    where: SchoolRegistrationScalarWhereInput;
  };

export type SchoolRegistrationUpdateManyWithWhereWithoutScheduleGroupInput = {
  data: SchoolRegistrationUpdateManyMutationInput;
  where: SchoolRegistrationScalarWhereInput;
};

export type SchoolRegistrationUpdateManyWithWhereWithoutSponsorInput = {
  data: SchoolRegistrationUpdateManyMutationInput;
  where: SchoolRegistrationScalarWhereInput;
};

export type SchoolRegistrationUpdateManyWithWhereWithoutStudentInput = {
  data: SchoolRegistrationUpdateManyMutationInput;
  where: SchoolRegistrationScalarWhereInput;
};

export type SchoolRegistrationUpdateManyWithWhereWithoutTerminatedByInput = {
  data: SchoolRegistrationUpdateManyMutationInput;
  where: SchoolRegistrationScalarWhereInput;
};

export type SchoolRegistrationUpdateManyWithoutAgentNestedInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutAgentInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutAgentInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManyAgentInputEnvelope>;
  delete?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolRegistrationUpdateWithWhereUniqueWithoutAgentInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolRegistrationUpdateManyWithWhereWithoutAgentInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolRegistrationUpsertWithWhereUniqueWithoutAgentInput>
  >;
};

export type SchoolRegistrationUpdateManyWithoutClassNestedInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutClassInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutClassInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManyClassInputEnvelope>;
  delete?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolRegistrationUpdateWithWhereUniqueWithoutClassInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolRegistrationUpdateManyWithWhereWithoutClassInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolRegistrationUpsertWithWhereUniqueWithoutClassInput>
  >;
};

export type SchoolRegistrationUpdateManyWithoutDropoffCollectionPointNestedInput =
  {
    connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<SchoolRegistrationCreateOrConnectWithoutDropoffCollectionPointInput>
    >;
    create?: InputMaybe<
      Array<SchoolRegistrationCreateWithoutDropoffCollectionPointInput>
    >;
    createMany?: InputMaybe<SchoolRegistrationCreateManyDropoffCollectionPointInputEnvelope>;
    delete?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
    set?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
    update?: InputMaybe<
      Array<SchoolRegistrationUpdateWithWhereUniqueWithoutDropoffCollectionPointInput>
    >;
    updateMany?: InputMaybe<
      Array<SchoolRegistrationUpdateManyWithWhereWithoutDropoffCollectionPointInput>
    >;
    upsert?: InputMaybe<
      Array<SchoolRegistrationUpsertWithWhereUniqueWithoutDropoffCollectionPointInput>
    >;
  };

export type SchoolRegistrationUpdateManyWithoutInstallmentPlanNestedInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutInstallmentPlanInput>
  >;
  create?: InputMaybe<
    Array<SchoolRegistrationCreateWithoutInstallmentPlanInput>
  >;
  createMany?: InputMaybe<SchoolRegistrationCreateManyInstallmentPlanInputEnvelope>;
  delete?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolRegistrationUpdateWithWhereUniqueWithoutInstallmentPlanInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolRegistrationUpdateManyWithWhereWithoutInstallmentPlanInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolRegistrationUpsertWithWhereUniqueWithoutInstallmentPlanInput>
  >;
};

export type SchoolRegistrationUpdateManyWithoutPickupCollectionPointNestedInput =
  {
    connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<SchoolRegistrationCreateOrConnectWithoutPickupCollectionPointInput>
    >;
    create?: InputMaybe<
      Array<SchoolRegistrationCreateWithoutPickupCollectionPointInput>
    >;
    createMany?: InputMaybe<SchoolRegistrationCreateManyPickupCollectionPointInputEnvelope>;
    delete?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
    set?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
    update?: InputMaybe<
      Array<SchoolRegistrationUpdateWithWhereUniqueWithoutPickupCollectionPointInput>
    >;
    updateMany?: InputMaybe<
      Array<SchoolRegistrationUpdateManyWithWhereWithoutPickupCollectionPointInput>
    >;
    upsert?: InputMaybe<
      Array<SchoolRegistrationUpsertWithWhereUniqueWithoutPickupCollectionPointInput>
    >;
  };

export type SchoolRegistrationUpdateManyWithoutScheduleGroupNestedInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutScheduleGroupInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutScheduleGroupInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManyScheduleGroupInputEnvelope>;
  delete?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolRegistrationUpdateWithWhereUniqueWithoutScheduleGroupInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolRegistrationUpdateManyWithWhereWithoutScheduleGroupInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolRegistrationUpsertWithWhereUniqueWithoutScheduleGroupInput>
  >;
};

export type SchoolRegistrationUpdateManyWithoutSponsorNestedInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutSponsorInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutSponsorInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManySponsorInputEnvelope>;
  delete?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolRegistrationUpdateWithWhereUniqueWithoutSponsorInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolRegistrationUpdateManyWithWhereWithoutSponsorInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolRegistrationUpsertWithWhereUniqueWithoutSponsorInput>
  >;
};

export type SchoolRegistrationUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutStudentInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutStudentInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolRegistrationUpdateWithWhereUniqueWithoutStudentInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolRegistrationUpdateManyWithWhereWithoutStudentInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolRegistrationUpsertWithWhereUniqueWithoutStudentInput>
  >;
};

export type SchoolRegistrationUpdateManyWithoutTerminatedByNestedInput = {
  connect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolRegistrationCreateOrConnectWithoutTerminatedByInput>
  >;
  create?: InputMaybe<Array<SchoolRegistrationCreateWithoutTerminatedByInput>>;
  createMany?: InputMaybe<SchoolRegistrationCreateManyTerminatedByInputEnvelope>;
  delete?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolRegistrationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolRegistrationUpdateWithWhereUniqueWithoutTerminatedByInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolRegistrationUpdateManyWithWhereWithoutTerminatedByInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolRegistrationUpsertWithWhereUniqueWithoutTerminatedByInput>
  >;
};

export type SchoolRegistrationUpdateOneRequiredWithoutLeaveRequestsNestedInput =
  {
    connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutLeaveRequestsInput>;
    create?: InputMaybe<SchoolRegistrationCreateWithoutLeaveRequestsInput>;
    update?: InputMaybe<SchoolRegistrationUpdateToOneWithWhereWithoutLeaveRequestsInput>;
    upsert?: InputMaybe<SchoolRegistrationUpsertWithoutLeaveRequestsInput>;
  };

export type SchoolRegistrationUpdateOneRequiredWithoutPaymentLineItemsNestedInput =
  {
    connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutPaymentLineItemsInput>;
    create?: InputMaybe<SchoolRegistrationCreateWithoutPaymentLineItemsInput>;
    update?: InputMaybe<SchoolRegistrationUpdateToOneWithWhereWithoutPaymentLineItemsInput>;
    upsert?: InputMaybe<SchoolRegistrationUpsertWithoutPaymentLineItemsInput>;
  };

export type SchoolRegistrationUpdateOneRequiredWithoutPaymentPackageNestedInput =
  {
    connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutPaymentPackageInput>;
    create?: InputMaybe<SchoolRegistrationCreateWithoutPaymentPackageInput>;
    update?: InputMaybe<SchoolRegistrationUpdateToOneWithWhereWithoutPaymentPackageInput>;
    upsert?: InputMaybe<SchoolRegistrationUpsertWithoutPaymentPackageInput>;
  };

export type SchoolRegistrationUpdateOneRequiredWithoutRouteStopsNestedInput = {
  connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutRouteStopsInput>;
  create?: InputMaybe<SchoolRegistrationCreateWithoutRouteStopsInput>;
  update?: InputMaybe<SchoolRegistrationUpdateToOneWithWhereWithoutRouteStopsInput>;
  upsert?: InputMaybe<SchoolRegistrationUpsertWithoutRouteStopsInput>;
};

export type SchoolRegistrationUpdateOneRequiredWithoutSchoolRegistrationStatusChangesNestedInput =
  {
    connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutSchoolRegistrationStatusChangesInput>;
    create?: InputMaybe<SchoolRegistrationCreateWithoutSchoolRegistrationStatusChangesInput>;
    update?: InputMaybe<SchoolRegistrationUpdateToOneWithWhereWithoutSchoolRegistrationStatusChangesInput>;
    upsert?: InputMaybe<SchoolRegistrationUpsertWithoutSchoolRegistrationStatusChangesInput>;
  };

export type SchoolRegistrationUpdateOneRequiredWithoutTravelTagDetectionsNestedInput =
  {
    connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutTravelTagDetectionsInput>;
    create?: InputMaybe<SchoolRegistrationCreateWithoutTravelTagDetectionsInput>;
    update?: InputMaybe<SchoolRegistrationUpdateToOneWithWhereWithoutTravelTagDetectionsInput>;
    upsert?: InputMaybe<SchoolRegistrationUpsertWithoutTravelTagDetectionsInput>;
  };

export type SchoolRegistrationUpdateOneWithoutScheduleExclusionsNestedInput = {
  connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutScheduleExclusionsInput>;
  create?: InputMaybe<SchoolRegistrationCreateWithoutScheduleExclusionsInput>;
  delete?: InputMaybe<SchoolRegistrationWhereInput>;
  disconnect?: InputMaybe<SchoolRegistrationWhereInput>;
  update?: InputMaybe<SchoolRegistrationUpdateToOneWithWhereWithoutScheduleExclusionsInput>;
  upsert?: InputMaybe<SchoolRegistrationUpsertWithoutScheduleExclusionsInput>;
};

export type SchoolRegistrationUpdateOneWithoutStopsNestedInput = {
  connect?: InputMaybe<SchoolRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolRegistrationCreateOrConnectWithoutStopsInput>;
  create?: InputMaybe<SchoolRegistrationCreateWithoutStopsInput>;
  delete?: InputMaybe<SchoolRegistrationWhereInput>;
  disconnect?: InputMaybe<SchoolRegistrationWhereInput>;
  update?: InputMaybe<SchoolRegistrationUpdateToOneWithWhereWithoutStopsInput>;
  upsert?: InputMaybe<SchoolRegistrationUpsertWithoutStopsInput>;
};

export type SchoolRegistrationUpdateToOneWithWhereWithoutLeaveRequestsInput = {
  data: SchoolRegistrationUpdateWithoutLeaveRequestsInput;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationUpdateToOneWithWhereWithoutPaymentLineItemsInput =
  {
    data: SchoolRegistrationUpdateWithoutPaymentLineItemsInput;
    where?: InputMaybe<SchoolRegistrationWhereInput>;
  };

export type SchoolRegistrationUpdateToOneWithWhereWithoutPaymentPackageInput = {
  data: SchoolRegistrationUpdateWithoutPaymentPackageInput;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationUpdateToOneWithWhereWithoutRouteStopsInput = {
  data: SchoolRegistrationUpdateWithoutRouteStopsInput;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationUpdateToOneWithWhereWithoutScheduleExclusionsInput =
  {
    data: SchoolRegistrationUpdateWithoutScheduleExclusionsInput;
    where?: InputMaybe<SchoolRegistrationWhereInput>;
  };

export type SchoolRegistrationUpdateToOneWithWhereWithoutSchoolRegistrationStatusChangesInput =
  {
    data: SchoolRegistrationUpdateWithoutSchoolRegistrationStatusChangesInput;
    where?: InputMaybe<SchoolRegistrationWhereInput>;
  };

export type SchoolRegistrationUpdateToOneWithWhereWithoutStopsInput = {
  data: SchoolRegistrationUpdateWithoutStopsInput;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationUpdateToOneWithWhereWithoutTravelTagDetectionsInput =
  {
    data: SchoolRegistrationUpdateWithoutTravelTagDetectionsInput;
    where?: InputMaybe<SchoolRegistrationWhereInput>;
  };

export type SchoolRegistrationUpdateWithWhereUniqueWithoutAgentInput = {
  data: SchoolRegistrationUpdateWithoutAgentInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpdateWithWhereUniqueWithoutClassInput = {
  data: SchoolRegistrationUpdateWithoutClassInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpdateWithWhereUniqueWithoutDropoffCollectionPointInput =
  {
    data: SchoolRegistrationUpdateWithoutDropoffCollectionPointInput;
    where: SchoolRegistrationWhereUniqueInput;
  };

export type SchoolRegistrationUpdateWithWhereUniqueWithoutInstallmentPlanInput =
  {
    data: SchoolRegistrationUpdateWithoutInstallmentPlanInput;
    where: SchoolRegistrationWhereUniqueInput;
  };

export type SchoolRegistrationUpdateWithWhereUniqueWithoutPickupCollectionPointInput =
  {
    data: SchoolRegistrationUpdateWithoutPickupCollectionPointInput;
    where: SchoolRegistrationWhereUniqueInput;
  };

export type SchoolRegistrationUpdateWithWhereUniqueWithoutScheduleGroupInput = {
  data: SchoolRegistrationUpdateWithoutScheduleGroupInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpdateWithWhereUniqueWithoutSponsorInput = {
  data: SchoolRegistrationUpdateWithoutSponsorInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpdateWithWhereUniqueWithoutStudentInput = {
  data: SchoolRegistrationUpdateWithoutStudentInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpdateWithWhereUniqueWithoutTerminatedByInput = {
  data: SchoolRegistrationUpdateWithoutTerminatedByInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpdateWithoutAgentInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutClassInput = {
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutDropoffCollectionPointInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutInstallmentPlanInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutLeaveRequestsInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutPaymentLineItemsInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutPaymentPackageInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutPickupCollectionPointInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutRouteStopsInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutScheduleExclusionsInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutScheduleGroupInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutSchoolRegistrationStatusChangesInput =
  {
    class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
    division?: InputMaybe<Scalars["String"]["input"]>;
    dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
    internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
    isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
    isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
    isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
    isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
    isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
    isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
    latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
    leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
    notes?: InputMaybe<Scalars["String"]["input"]>;
    paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
    paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
    pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
    referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
    registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
    scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
    scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
    sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
    sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
    statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
    terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
    terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
    terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
    terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
    travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
    updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  };

export type SchoolRegistrationUpdateWithoutSponsorInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutStopsInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutStudentInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutTerminatedByInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpdateWithoutTravelTagDetectionsInput = {
  class?: InputMaybe<ClassUpdateOneWithoutSchoolRegistrationsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutDropoffSchoolRegistrationsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneRequiredWithoutSchoolRegistrationsNestedInput>;
  internalStatus?: InputMaybe<SchoolRegistrationInternalStatus>;
  isApproved?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPermitRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRejectedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWaitlistNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  latestTravelTagDetectionId?: InputMaybe<Scalars["String"]["input"]>;
  leaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSchoolRegistrationNestedInput>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  paymentPackage?: InputMaybe<PaymentPackageUpdateOneWithoutSchoolRegistrationNestedInput>;
  paymentStatus?: InputMaybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: InputMaybe<CollectionPointUpdateOneWithoutPickupSchoolRegistrationsNestedInput>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionUpdateManyWithoutSchoolRegistrationNestedInput>;
  scheduleGroup?: InputMaybe<ScheduleGroupUpdateOneWithoutSchoolRegistrationsNestedInput>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeUpdateManyWithoutSchoolRegistrationNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  sponsorDisplayStatus?: InputMaybe<SchoolRegistrationSponsorDisplayStatus>;
  statusChangedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminatedBy?: InputMaybe<AgentUpdateOneWithoutTerminatedBySchoolRegistrationsNestedInput>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolRegistrationUpsertWithWhereUniqueWithoutAgentInput = {
  create: SchoolRegistrationCreateWithoutAgentInput;
  update: SchoolRegistrationUpdateWithoutAgentInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpsertWithWhereUniqueWithoutClassInput = {
  create: SchoolRegistrationCreateWithoutClassInput;
  update: SchoolRegistrationUpdateWithoutClassInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpsertWithWhereUniqueWithoutDropoffCollectionPointInput =
  {
    create: SchoolRegistrationCreateWithoutDropoffCollectionPointInput;
    update: SchoolRegistrationUpdateWithoutDropoffCollectionPointInput;
    where: SchoolRegistrationWhereUniqueInput;
  };

export type SchoolRegistrationUpsertWithWhereUniqueWithoutInstallmentPlanInput =
  {
    create: SchoolRegistrationCreateWithoutInstallmentPlanInput;
    update: SchoolRegistrationUpdateWithoutInstallmentPlanInput;
    where: SchoolRegistrationWhereUniqueInput;
  };

export type SchoolRegistrationUpsertWithWhereUniqueWithoutPickupCollectionPointInput =
  {
    create: SchoolRegistrationCreateWithoutPickupCollectionPointInput;
    update: SchoolRegistrationUpdateWithoutPickupCollectionPointInput;
    where: SchoolRegistrationWhereUniqueInput;
  };

export type SchoolRegistrationUpsertWithWhereUniqueWithoutScheduleGroupInput = {
  create: SchoolRegistrationCreateWithoutScheduleGroupInput;
  update: SchoolRegistrationUpdateWithoutScheduleGroupInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpsertWithWhereUniqueWithoutSponsorInput = {
  create: SchoolRegistrationCreateWithoutSponsorInput;
  update: SchoolRegistrationUpdateWithoutSponsorInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpsertWithWhereUniqueWithoutStudentInput = {
  create: SchoolRegistrationCreateWithoutStudentInput;
  update: SchoolRegistrationUpdateWithoutStudentInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpsertWithWhereUniqueWithoutTerminatedByInput = {
  create: SchoolRegistrationCreateWithoutTerminatedByInput;
  update: SchoolRegistrationUpdateWithoutTerminatedByInput;
  where: SchoolRegistrationWhereUniqueInput;
};

export type SchoolRegistrationUpsertWithoutLeaveRequestsInput = {
  create: SchoolRegistrationCreateWithoutLeaveRequestsInput;
  update: SchoolRegistrationUpdateWithoutLeaveRequestsInput;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationUpsertWithoutPaymentLineItemsInput = {
  create: SchoolRegistrationCreateWithoutPaymentLineItemsInput;
  update: SchoolRegistrationUpdateWithoutPaymentLineItemsInput;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationUpsertWithoutPaymentPackageInput = {
  create: SchoolRegistrationCreateWithoutPaymentPackageInput;
  update: SchoolRegistrationUpdateWithoutPaymentPackageInput;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationUpsertWithoutRouteStopsInput = {
  create: SchoolRegistrationCreateWithoutRouteStopsInput;
  update: SchoolRegistrationUpdateWithoutRouteStopsInput;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationUpsertWithoutScheduleExclusionsInput = {
  create: SchoolRegistrationCreateWithoutScheduleExclusionsInput;
  update: SchoolRegistrationUpdateWithoutScheduleExclusionsInput;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationUpsertWithoutSchoolRegistrationStatusChangesInput =
  {
    create: SchoolRegistrationCreateWithoutSchoolRegistrationStatusChangesInput;
    update: SchoolRegistrationUpdateWithoutSchoolRegistrationStatusChangesInput;
    where?: InputMaybe<SchoolRegistrationWhereInput>;
  };

export type SchoolRegistrationUpsertWithoutStopsInput = {
  create: SchoolRegistrationCreateWithoutStopsInput;
  update: SchoolRegistrationUpdateWithoutStopsInput;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationUpsertWithoutTravelTagDetectionsInput = {
  create: SchoolRegistrationCreateWithoutTravelTagDetectionsInput;
  update: SchoolRegistrationUpdateWithoutTravelTagDetectionsInput;
  where?: InputMaybe<SchoolRegistrationWhereInput>;
};

export type SchoolRegistrationWhereInput = {
  AND?: InputMaybe<Array<SchoolRegistrationWhereInput>>;
  NOT?: InputMaybe<Array<SchoolRegistrationWhereInput>>;
  OR?: InputMaybe<Array<SchoolRegistrationWhereInput>>;
  class?: InputMaybe<ClassRelationFilter>;
  classId?: InputMaybe<StringFilter>;
  division?: InputMaybe<StringFilter>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointRelationFilter>;
  dropoffCollectionPointId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  installmentPlan?: InputMaybe<InstallmentPlanRelationFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  internalStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  isApproved?: InputMaybe<BoolFilter>;
  isPermitRequired?: InputMaybe<BoolFilter>;
  isRejectedNotificationSent?: InputMaybe<BoolFilter>;
  isToHome?: InputMaybe<BoolFilter>;
  isToSchool?: InputMaybe<BoolFilter>;
  isWaitlistNotificationSent?: InputMaybe<BoolFilter>;
  latestTravelTagDetectionId?: InputMaybe<StringFilter>;
  leaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  notes?: InputMaybe<StringFilter>;
  paymentPackage?: InputMaybe<PaymentPackageRelationFilter>;
  paymentStatus?: InputMaybe<EnumSchoolRegistrationPaymentStatusFilter>;
  pickupCollectionPoint?: InputMaybe<CollectionPointRelationFilter>;
  pickupCollectionPointId?: InputMaybe<StringFilter>;
  referenceNumber?: InputMaybe<StringFilter>;
  registeredAt?: InputMaybe<DateTimeFilter>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationListRelationFilter>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionListRelationFilter>;
  scheduleGroup?: InputMaybe<ScheduleGroupRelationFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeListRelationFilter>;
  sponsor?: InputMaybe<SponsorRelationFilter>;
  sponsorDisplayStatus?: InputMaybe<EnumSchoolRegistrationSponsorDisplayStatusFilter>;
  sponsorId?: InputMaybe<StringFilter>;
  statusChangedAt?: InputMaybe<DateTimeFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<StringFilter>;
  terminatedAt?: InputMaybe<DateTimeFilter>;
  terminatedBy?: InputMaybe<AgentRelationFilter>;
  terminatedByAgentId?: InputMaybe<StringFilter>;
  terminationReason?: InputMaybe<EnumSchoolRegistrationTerminationReasonFilter>;
  terminationReasonOther?: InputMaybe<StringFilter>;
  travelTagDetections?: InputMaybe<TravelTagDetectionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolRegistrationWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolRegistrationWhereInput>>;
  NOT?: InputMaybe<Array<SchoolRegistrationWhereInput>>;
  OR?: InputMaybe<Array<SchoolRegistrationWhereInput>>;
  class?: InputMaybe<ClassRelationFilter>;
  classId?: InputMaybe<StringFilter>;
  division?: InputMaybe<StringFilter>;
  dropoffCollectionPoint?: InputMaybe<CollectionPointRelationFilter>;
  dropoffCollectionPointId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanRelationFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  internalStatus?: InputMaybe<EnumSchoolRegistrationInternalStatusFilter>;
  isApproved?: InputMaybe<BoolFilter>;
  isPermitRequired?: InputMaybe<BoolFilter>;
  isRejectedNotificationSent?: InputMaybe<BoolFilter>;
  isToHome?: InputMaybe<BoolFilter>;
  isToSchool?: InputMaybe<BoolFilter>;
  isWaitlistNotificationSent?: InputMaybe<BoolFilter>;
  latestTravelTagDetectionId?: InputMaybe<StringFilter>;
  leaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  notes?: InputMaybe<StringFilter>;
  paymentPackage?: InputMaybe<PaymentPackageRelationFilter>;
  paymentStatus?: InputMaybe<EnumSchoolRegistrationPaymentStatusFilter>;
  pickupCollectionPoint?: InputMaybe<CollectionPointRelationFilter>;
  pickupCollectionPointId?: InputMaybe<StringFilter>;
  referenceNumber?: InputMaybe<StringFilter>;
  registeredAt?: InputMaybe<DateTimeFilter>;
  routeStops?: InputMaybe<RouteStopSchoolRegistrationListRelationFilter>;
  scheduleExclusions?: InputMaybe<ScheduleExclusionListRelationFilter>;
  scheduleGroup?: InputMaybe<ScheduleGroupRelationFilter>;
  scheduleGroupId?: InputMaybe<StringFilter>;
  schoolRegistrationStatusChanges?: InputMaybe<SchoolRegistrationStatusChangeListRelationFilter>;
  sponsor?: InputMaybe<SponsorRelationFilter>;
  sponsorDisplayStatus?: InputMaybe<EnumSchoolRegistrationSponsorDisplayStatusFilter>;
  sponsorId?: InputMaybe<StringFilter>;
  statusChangedAt?: InputMaybe<DateTimeFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<StringFilter>;
  terminatedAt?: InputMaybe<DateTimeFilter>;
  terminatedBy?: InputMaybe<AgentRelationFilter>;
  terminatedByAgentId?: InputMaybe<StringFilter>;
  terminationReason?: InputMaybe<EnumSchoolRegistrationTerminationReasonFilter>;
  terminationReasonOther?: InputMaybe<StringFilter>;
  travelTagDetections?: InputMaybe<TravelTagDetectionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolRegistrationWithComputedFields = {
  __typename?: "SchoolRegistrationWithComputedFields";
  _count: SchoolRegistrationCount;
  agent?: Maybe<Agent>;
  agentId?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  class?: Maybe<Class>;
  classId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  division?: Maybe<Scalars["String"]["output"]>;
  dropoffCollectionPoint?: Maybe<CollectionPoint>;
  dropoffCollectionPointId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  installmentPlan: InstallmentPlan;
  installmentPlanId: Scalars["String"]["output"];
  internalStatus?: Maybe<SchoolRegistrationInternalStatus>;
  isApproved: Scalars["Boolean"]["output"];
  isMultipleKid: Scalars["Boolean"]["output"];
  isPermitRequired: Scalars["Boolean"]["output"];
  isRejectedNotificationSent: Scalars["Boolean"]["output"];
  isToHome: Scalars["Boolean"]["output"];
  isToSchool: Scalars["Boolean"]["output"];
  isWaitlistNotificationSent: Scalars["Boolean"]["output"];
  latestTravelTagDetectionId?: Maybe<Scalars["String"]["output"]>;
  leaveRequests?: Maybe<Array<LeaveRequest>>;
  notes?: Maybe<Scalars["String"]["output"]>;
  paymentLineItems?: Maybe<Array<PaymentLineItem>>;
  paymentPackage?: Maybe<PaymentPackage>;
  paymentStatus?: Maybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: Maybe<CollectionPoint>;
  pickupCollectionPointId?: Maybe<Scalars["String"]["output"]>;
  referenceNumber?: Maybe<Scalars["String"]["output"]>;
  registeredAt?: Maybe<Scalars["DateTime"]["output"]>;
  routeStops?: Maybe<Array<RouteStopSchoolRegistration>>;
  routingStatus: RegistrationRoutingStatus;
  scheduleExclusions?: Maybe<Array<ScheduleExclusion>>;
  scheduleGroup?: Maybe<ScheduleGroup>;
  scheduleGroupId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrationGroup?: Maybe<SchoolRegistrationGroup>;
  schoolRegistrationGroupId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrationStatusChanges?: Maybe<
    Array<SchoolRegistrationStatusChange>
  >;
  sponsor: SponsorWithAvailableCreditNoteAmount;
  sponsorDisplayStatus?: Maybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["output"];
  statusChangedAt: Scalars["DateTime"]["output"];
  stops?: Maybe<Array<Stop>>;
  student: Student;
  studentId: Scalars["String"]["output"];
  terminatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  terminatedBy?: Maybe<Agent>;
  terminatedByAgentId?: Maybe<Scalars["String"]["output"]>;
  terminationReason?: Maybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: Maybe<Scalars["String"]["output"]>;
  travelTagDetections?: Maybe<Array<TravelTagDetection>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolRegistrationWithCredit = {
  __typename?: "SchoolRegistrationWithCredit";
  _count: SchoolRegistrationCount;
  agent?: Maybe<Agent>;
  agentId?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  class?: Maybe<Class>;
  classId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  creditInfo?: Maybe<CreditInfo>;
  division?: Maybe<Scalars["String"]["output"]>;
  dropoffCollectionPoint?: Maybe<CollectionPoint>;
  dropoffCollectionPointId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  installmentPlan: InstallmentPlan;
  installmentPlanId: Scalars["String"]["output"];
  internalStatus?: Maybe<SchoolRegistrationInternalStatus>;
  isApproved: Scalars["Boolean"]["output"];
  isMultipleKid: Scalars["Boolean"]["output"];
  isPermitRequired: Scalars["Boolean"]["output"];
  isRejectedNotificationSent: Scalars["Boolean"]["output"];
  isToHome: Scalars["Boolean"]["output"];
  isToSchool: Scalars["Boolean"]["output"];
  isWaitlistNotificationSent: Scalars["Boolean"]["output"];
  latestTravelTagDetectionId?: Maybe<Scalars["String"]["output"]>;
  leaveRequests?: Maybe<Array<LeaveRequest>>;
  notes?: Maybe<Scalars["String"]["output"]>;
  paymentLineItems?: Maybe<Array<PaymentLineItem>>;
  paymentPackage?: Maybe<PaymentPackage>;
  paymentStatus?: Maybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: Maybe<CollectionPoint>;
  pickupCollectionPointId?: Maybe<Scalars["String"]["output"]>;
  referenceNumber?: Maybe<Scalars["String"]["output"]>;
  registeredAt?: Maybe<Scalars["DateTime"]["output"]>;
  routeStops?: Maybe<Array<RouteStopSchoolRegistration>>;
  scheduleExclusions?: Maybe<Array<ScheduleExclusion>>;
  scheduleGroup?: Maybe<ScheduleGroup>;
  scheduleGroupId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrationGroup?: Maybe<SchoolRegistrationGroup>;
  schoolRegistrationGroupId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrationStatusChanges?: Maybe<
    Array<SchoolRegistrationStatusChange>
  >;
  sponsor: Sponsor;
  sponsorDisplayStatus?: Maybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["output"];
  statusChangedAt: Scalars["DateTime"]["output"];
  stops?: Maybe<Array<Stop>>;
  student: Student;
  studentId: Scalars["String"]["output"];
  terminatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  terminatedBy?: Maybe<Agent>;
  terminatedByAgentId?: Maybe<Scalars["String"]["output"]>;
  terminationReason?: Maybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: Maybe<Scalars["String"]["output"]>;
  travelTagDetections?: Maybe<Array<TravelTagDetection>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolRegistrationWithPaymentLineItemStatus = {
  __typename?: "SchoolRegistrationWithPaymentLineItemStatus";
  _count: SchoolRegistrationCount;
  agent?: Maybe<Agent>;
  agentId?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  class?: Maybe<Class>;
  classId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  division?: Maybe<Scalars["String"]["output"]>;
  dropoffCollectionPoint?: Maybe<CollectionPoint>;
  dropoffCollectionPointId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  installmentPlan: InstallmentPlan;
  installmentPlanId: Scalars["String"]["output"];
  internalStatus?: Maybe<SchoolRegistrationInternalStatus>;
  isApproved: Scalars["Boolean"]["output"];
  isMultipleKid: Scalars["Boolean"]["output"];
  isPermitRequired: Scalars["Boolean"]["output"];
  isRejectedNotificationSent: Scalars["Boolean"]["output"];
  isToHome: Scalars["Boolean"]["output"];
  isToSchool: Scalars["Boolean"]["output"];
  isWaitlistNotificationSent: Scalars["Boolean"]["output"];
  latestTravelTagDetectionId?: Maybe<Scalars["String"]["output"]>;
  leaveRequests?: Maybe<Array<LeaveRequest>>;
  notes?: Maybe<Scalars["String"]["output"]>;
  paymentLineItems?: Maybe<Array<PaymentLineItem>>;
  paymentLineItemsWithStatus?: Maybe<Array<PaymentLineItemWithStatus>>;
  paymentPackage?: Maybe<PaymentPackage>;
  paymentStatus?: Maybe<SchoolRegistrationPaymentStatus>;
  pickupCollectionPoint?: Maybe<CollectionPoint>;
  pickupCollectionPointId?: Maybe<Scalars["String"]["output"]>;
  referenceNumber?: Maybe<Scalars["String"]["output"]>;
  registeredAt?: Maybe<Scalars["DateTime"]["output"]>;
  routeStops?: Maybe<Array<RouteStopSchoolRegistration>>;
  scheduleExclusions?: Maybe<Array<ScheduleExclusion>>;
  scheduleGroup?: Maybe<ScheduleGroup>;
  scheduleGroupId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrationGroup?: Maybe<SchoolRegistrationGroup>;
  schoolRegistrationGroupId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrationStatusChanges?: Maybe<
    Array<SchoolRegistrationStatusChange>
  >;
  sponsor: Sponsor;
  sponsorDisplayStatus?: Maybe<SchoolRegistrationSponsorDisplayStatus>;
  sponsorId: Scalars["String"]["output"];
  statusChangedAt: Scalars["DateTime"]["output"];
  stops?: Maybe<Array<Stop>>;
  student: Student;
  studentId: Scalars["String"]["output"];
  terminatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  terminatedBy?: Maybe<Agent>;
  terminatedByAgentId?: Maybe<Scalars["String"]["output"]>;
  terminationReason?: Maybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: Maybe<Scalars["String"]["output"]>;
  travelTagDetections?: Maybe<Array<TravelTagDetection>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolRegistrationWithPaymentStatusAndUpcomingTripAndTimePeriodsAndVat =
  {
    __typename?: "SchoolRegistrationWithPaymentStatusAndUpcomingTripAndTimePeriodsAndVat";
    _count: SchoolRegistrationCount;
    agent?: Maybe<Agent>;
    agentId?: Maybe<Scalars["String"]["output"]>;
    archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
    class?: Maybe<Class>;
    classId?: Maybe<Scalars["String"]["output"]>;
    createdAt: Scalars["DateTime"]["output"];
    currentSubscriptionTimePeriod?: Maybe<TimePeriodWithSchoolRegistrationExtended>;
    depositCollectionType: DepositCollectionType;
    division?: Maybe<Scalars["String"]["output"]>;
    dropoffCollectionPoint?: Maybe<CollectionPoint>;
    dropoffCollectionPointId?: Maybe<Scalars["String"]["output"]>;
    futureSubscriptionTimePeriod?: Maybe<TimePeriodWithSchoolRegistrationExtended>;
    hasHomeLocation: Scalars["Boolean"]["output"];
    hasVat: Scalars["Boolean"]["output"];
    id: Scalars["String"]["output"];
    installmentPlan: InstallmentPlan;
    installmentPlanId: Scalars["String"]["output"];
    internalStatus?: Maybe<SchoolRegistrationInternalStatus>;
    isApproved: Scalars["Boolean"]["output"];
    isMultipleKid: Scalars["Boolean"]["output"];
    isPermitRequired: Scalars["Boolean"]["output"];
    isRejectedNotificationSent: Scalars["Boolean"]["output"];
    isServedByEmushrif: Scalars["Boolean"]["output"];
    isToHome: Scalars["Boolean"]["output"];
    isToSchool: Scalars["Boolean"]["output"];
    isVatInclusive: Scalars["Boolean"]["output"];
    isWaitlistNotificationSent: Scalars["Boolean"]["output"];
    latestTravelTagDetectionId?: Maybe<Scalars["String"]["output"]>;
    leaveRequests?: Maybe<Array<LeaveRequest>>;
    notes?: Maybe<Scalars["String"]["output"]>;
    offerDiscountAmount?: Maybe<Scalars["Float"]["output"]>;
    onewayDiscount?: Maybe<Scalars["Float"]["output"]>;
    pastSubscriptionTimePeriod?: Maybe<TimePeriodWithSchoolRegistrationExtended>;
    paymentLineItems?: Maybe<Array<PaymentLineItemWithStatus>>;
    paymentPackage?: Maybe<PaymentPackage>;
    paymentStatus?: Maybe<SchoolRegistrationPaymentStatus>;
    pickupCollectionPoint?: Maybe<CollectionPoint>;
    pickupCollectionPointId?: Maybe<Scalars["String"]["output"]>;
    planDiscountAmount?: Maybe<Scalars["Float"]["output"]>;
    referenceNumber?: Maybe<Scalars["String"]["output"]>;
    registeredAt?: Maybe<Scalars["DateTime"]["output"]>;
    routeStops?: Maybe<Array<RouteStopSchoolRegistration>>;
    scheduleExclusions?: Maybe<Array<ScheduleExclusion>>;
    scheduleGroup?: Maybe<ScheduleGroup>;
    scheduleGroupId?: Maybe<Scalars["String"]["output"]>;
    schoolRegistrationGroup?: Maybe<SchoolRegistrationGroup>;
    schoolRegistrationGroupId?: Maybe<Scalars["String"]["output"]>;
    schoolRegistrationStatusChanges?: Maybe<
      Array<SchoolRegistrationStatusChange>
    >;
    serviceAcademicYear?: Maybe<Scalars["Float"]["output"]>;
    serviceProviderContact?: Maybe<Array<Contact>>;
    serviceProviderEmail?: Maybe<Scalars["String"]["output"]>;
    serviceProviderName: Scalars["String"]["output"];
    serviceProviderNameAr: Scalars["String"]["output"];
    siblingDiscount?: Maybe<Scalars["Float"]["output"]>;
    sponsor: Sponsor;
    sponsorDisplayStatus?: Maybe<SchoolRegistrationSponsorDisplayStatus>;
    sponsorId: Scalars["String"]["output"];
    statusChangedAt: Scalars["DateTime"]["output"];
    stops?: Maybe<Array<Stop>>;
    student: Student;
    studentId: Scalars["String"]["output"];
    terminatedAt?: Maybe<Scalars["DateTime"]["output"]>;
    terminatedBy?: Maybe<Agent>;
    terminatedByAgentId?: Maybe<Scalars["String"]["output"]>;
    terminationReason?: Maybe<SchoolRegistrationTerminationReason>;
    terminationReasonOther?: Maybe<Scalars["String"]["output"]>;
    todayTrips?: Maybe<Array<TodayTripForSchoolRegistration>>;
    travelTagDetections?: Maybe<Array<TravelTagDetection>>;
    upcomingTrip?: Maybe<UpcomingTripForSchoolRegistration>;
    updatedAt: Scalars["DateTime"]["output"];
    vatAmount?: Maybe<Scalars["Float"]["output"]>;
    vatFactor: Scalars["Float"]["output"];
  };

export type SchoolRegistrationsCreateInput = {
  creditNoteAmount?: InputMaybe<Scalars["Float"]["input"]>;
  isFirstPayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPayLater?: InputMaybe<Scalars["Boolean"]["input"]>;
  offerCode?: InputMaybe<Scalars["String"]["input"]>;
  paymentRef?: InputMaybe<Scalars["String"]["input"]>;
  paymentSolution?: InputMaybe<PaymentSolution>;
  paymentType?: InputMaybe<PaymentType>;
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrations: SchoolRegistrationSingleCreateArrayInput;
};

export type SchoolRegistrationsFilterInput = {
  countryCodes: Array<Scalars["String"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  isToHome?: InputMaybe<Scalars["Boolean"]["input"]>;
  isToSchool?: InputMaybe<Scalars["Boolean"]["input"]>;
  paymentStatuses?: InputMaybe<Array<Scalars["String"]["input"]>>;
  registrationStatuses?: InputMaybe<Array<Scalars["String"]["input"]>>;
  routeIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  schoolIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startDate: Scalars["DateTime"]["input"];
};

export type SchoolRegistrationsForSponsorCreateInput = {
  offerCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrations: Array<SchoolRegistrationForSponsorCreateInput>;
};

export type SchoolRelationFilter = {
  is?: InputMaybe<SchoolWhereInput>;
  isNot?: InputMaybe<SchoolWhereInput>;
};

export type SchoolResponsePayload = {
  __typename?: "SchoolResponsePayload";
  address: AddressResponsePayload;
  availableAcademicYears: Array<Scalars["Float"]["output"]>;
  comment?: Maybe<Scalars["String"]["output"]>;
  dropOffPoint?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  logoKey?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  pickUpPoint?: Maybe<Scalars["String"]["output"]>;
  radius: Scalars["Float"]["output"];
  scheduleGroups: Array<SchoolScheduleGroupResponse>;
  schoolCode?: Maybe<Scalars["String"]["output"]>;
  schoolName: Scalars["String"]["output"];
  schoolNameAr: Scalars["String"]["output"];
  schoolServiceSpName?: Maybe<Scalars["String"]["output"]>;
  schoolServiceSpNameAr?: Maybe<Scalars["String"]["output"]>;
  stage: SchoolStageResponsePayload;
  status: SchoolStatus;
  students?: Maybe<Array<SchoolStudentPayload>>;
  type: SchoolTypeResponsePayload;
};

export type SchoolScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolScalarWhereInput>>;
  addressId?: InputMaybe<StringFilter>;
  comment?: InputMaybe<StringFilter>;
  dropOffPoint?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  idInt?: InputMaybe<IntFilter>;
  logo?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  pickUpPoint?: InputMaybe<StringFilter>;
  radius?: InputMaybe<IntFilter>;
  schoolCode?: InputMaybe<StringFilter>;
  schoolGroupId?: InputMaybe<StringFilter>;
  schoolName?: InputMaybe<StringFilter>;
  schoolNameAr?: InputMaybe<StringFilter>;
  stageId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumSchoolStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  typeId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolScheduleGroupResponse = {
  __typename?: "SchoolScheduleGroupResponse";
  id: Scalars["String"]["output"];
  schedules: Array<SchoolScheduleResponse>;
  title: Scalars["String"]["output"];
  titleAr?: Maybe<Scalars["String"]["output"]>;
};

export type SchoolScheduleResponse = {
  __typename?: "SchoolScheduleResponse";
  endDateUTC: Scalars["DateTime"]["output"];
  endTime: Scalars["DateTime"]["output"];
  isMain: Scalars["Boolean"]["output"];
  semester: SemesterResponsePayload;
  startDateUTC: Scalars["DateTime"]["output"];
  startTime: Scalars["DateTime"]["output"];
  state: ScheduleState;
  title: Scalars["String"]["output"];
  titleAr?: Maybe<Scalars["String"]["output"]>;
};

export type SchoolSchoolStage = {
  __typename?: "SchoolSchoolStage";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars["String"]["output"]>;
  stage?: Maybe<SchoolStage>;
  stageId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolSchoolStageCreateManySchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  stageId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolSchoolStageCreateManySchoolInputEnvelope = {
  data: Array<SchoolSchoolStageCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolSchoolStageCreateManyStageInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolSchoolStageCreateManyStageInputEnvelope = {
  data: Array<SchoolSchoolStageCreateManyStageInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolSchoolStageCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SchoolSchoolStageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolSchoolStageCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolSchoolStageCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolSchoolStageCreateManySchoolInputEnvelope>;
};

export type SchoolSchoolStageCreateNestedManyWithoutStageInput = {
  connect?: InputMaybe<Array<SchoolSchoolStageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolSchoolStageCreateOrConnectWithoutStageInput>
  >;
  create?: InputMaybe<Array<SchoolSchoolStageCreateWithoutStageInput>>;
  createMany?: InputMaybe<SchoolSchoolStageCreateManyStageInputEnvelope>;
};

export type SchoolSchoolStageCreateOrConnectWithoutSchoolInput = {
  create: SchoolSchoolStageCreateWithoutSchoolInput;
  where: SchoolSchoolStageWhereUniqueInput;
};

export type SchoolSchoolStageCreateOrConnectWithoutStageInput = {
  create: SchoolSchoolStageCreateWithoutStageInput;
  where: SchoolSchoolStageWhereUniqueInput;
};

export type SchoolSchoolStageCreateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  stage?: InputMaybe<SchoolStageCreateNestedOneWithoutSchoolsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolSchoolStageCreateWithoutStageInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutStagesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolSchoolStageListRelationFilter = {
  every?: InputMaybe<SchoolSchoolStageWhereInput>;
  none?: InputMaybe<SchoolSchoolStageWhereInput>;
  some?: InputMaybe<SchoolSchoolStageWhereInput>;
};

export type SchoolSchoolStageScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolSchoolStageScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolSchoolStageScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolSchoolStageScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  schoolId?: InputMaybe<StringFilter>;
  stageId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolSchoolStageUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolSchoolStageUpdateManyWithWhereWithoutSchoolInput = {
  data: SchoolSchoolStageUpdateManyMutationInput;
  where: SchoolSchoolStageScalarWhereInput;
};

export type SchoolSchoolStageUpdateManyWithWhereWithoutStageInput = {
  data: SchoolSchoolStageUpdateManyMutationInput;
  where: SchoolSchoolStageScalarWhereInput;
};

export type SchoolSchoolStageUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SchoolSchoolStageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolSchoolStageCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolSchoolStageCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolSchoolStageCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SchoolSchoolStageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolSchoolStageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolSchoolStageWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolSchoolStageWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolSchoolStageUpdateWithWhereUniqueWithoutSchoolInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolSchoolStageUpdateManyWithWhereWithoutSchoolInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolSchoolStageUpsertWithWhereUniqueWithoutSchoolInput>
  >;
};

export type SchoolSchoolStageUpdateManyWithoutStageNestedInput = {
  connect?: InputMaybe<Array<SchoolSchoolStageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolSchoolStageCreateOrConnectWithoutStageInput>
  >;
  create?: InputMaybe<Array<SchoolSchoolStageCreateWithoutStageInput>>;
  createMany?: InputMaybe<SchoolSchoolStageCreateManyStageInputEnvelope>;
  delete?: InputMaybe<Array<SchoolSchoolStageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolSchoolStageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolSchoolStageWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolSchoolStageWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolSchoolStageUpdateWithWhereUniqueWithoutStageInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolSchoolStageUpdateManyWithWhereWithoutStageInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolSchoolStageUpsertWithWhereUniqueWithoutStageInput>
  >;
};

export type SchoolSchoolStageUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SchoolSchoolStageUpdateWithoutSchoolInput;
  where: SchoolSchoolStageWhereUniqueInput;
};

export type SchoolSchoolStageUpdateWithWhereUniqueWithoutStageInput = {
  data: SchoolSchoolStageUpdateWithoutStageInput;
  where: SchoolSchoolStageWhereUniqueInput;
};

export type SchoolSchoolStageUpdateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolSchoolStageUpdateWithoutStageInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneWithoutStagesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolSchoolStageUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SchoolSchoolStageCreateWithoutSchoolInput;
  update: SchoolSchoolStageUpdateWithoutSchoolInput;
  where: SchoolSchoolStageWhereUniqueInput;
};

export type SchoolSchoolStageUpsertWithWhereUniqueWithoutStageInput = {
  create: SchoolSchoolStageCreateWithoutStageInput;
  update: SchoolSchoolStageUpdateWithoutStageInput;
  where: SchoolSchoolStageWhereUniqueInput;
};

export type SchoolSchoolStageWhereInput = {
  AND?: InputMaybe<Array<SchoolSchoolStageWhereInput>>;
  NOT?: InputMaybe<Array<SchoolSchoolStageWhereInput>>;
  OR?: InputMaybe<Array<SchoolSchoolStageWhereInput>>;
  id?: InputMaybe<StringFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  stage?: InputMaybe<SchoolStageRelationFilter>;
  stageId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolSchoolStageWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolSchoolStageWhereInput>>;
  NOT?: InputMaybe<Array<SchoolSchoolStageWhereInput>>;
  OR?: InputMaybe<Array<SchoolSchoolStageWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  stage?: InputMaybe<SchoolStageRelationFilter>;
  stageId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolServiceEligibility = {
  __typename?: "SchoolServiceEligibility";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  school: School;
  schoolId: Scalars["String"]["output"];
  serviceEligibility: ServiceEligibility;
  serviceEligibilityId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolServiceEligibilityCreateManySchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceEligibilityId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolServiceEligibilityCreateManySchoolInputEnvelope = {
  data: Array<SchoolServiceEligibilityCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolServiceEligibilityCreateManyServiceEligibilityInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolServiceEligibilityCreateManyServiceEligibilityInputEnvelope =
  {
    data: Array<SchoolServiceEligibilityCreateManyServiceEligibilityInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type SchoolServiceEligibilityCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SchoolServiceEligibilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolServiceEligibilityCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolServiceEligibilityCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolServiceEligibilityCreateManySchoolInputEnvelope>;
};

export type SchoolServiceEligibilityCreateNestedManyWithoutServiceEligibilityInput =
  {
    connect?: InputMaybe<Array<SchoolServiceEligibilityWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<SchoolServiceEligibilityCreateOrConnectWithoutServiceEligibilityInput>
    >;
    create?: InputMaybe<
      Array<SchoolServiceEligibilityCreateWithoutServiceEligibilityInput>
    >;
    createMany?: InputMaybe<SchoolServiceEligibilityCreateManyServiceEligibilityInputEnvelope>;
  };

export type SchoolServiceEligibilityCreateOrConnectWithoutSchoolInput = {
  create: SchoolServiceEligibilityCreateWithoutSchoolInput;
  where: SchoolServiceEligibilityWhereUniqueInput;
};

export type SchoolServiceEligibilityCreateOrConnectWithoutServiceEligibilityInput =
  {
    create: SchoolServiceEligibilityCreateWithoutServiceEligibilityInput;
    where: SchoolServiceEligibilityWhereUniqueInput;
  };

export type SchoolServiceEligibilityCreateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceEligibility: ServiceEligibilityCreateNestedOneWithoutSchoolServiceEligibilitiesInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolServiceEligibilityCreateWithoutServiceEligibilityInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school: SchoolCreateNestedOneWithoutSchoolServiceEligibilitiesInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolServiceEligibilityListRelationFilter = {
  every?: InputMaybe<SchoolServiceEligibilityWhereInput>;
  none?: InputMaybe<SchoolServiceEligibilityWhereInput>;
  some?: InputMaybe<SchoolServiceEligibilityWhereInput>;
};

export type SchoolServiceEligibilityScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolServiceEligibilityScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolServiceEligibilityScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolServiceEligibilityScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  schoolId?: InputMaybe<StringFilter>;
  serviceEligibilityId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolServiceEligibilityServiceEligibilityIdSchoolIdCompoundUniqueInput =
  {
    schoolId: Scalars["String"]["input"];
    serviceEligibilityId: Scalars["String"]["input"];
  };

export type SchoolServiceEligibilityUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolServiceEligibilityUpdateManyWithWhereWithoutSchoolInput = {
  data: SchoolServiceEligibilityUpdateManyMutationInput;
  where: SchoolServiceEligibilityScalarWhereInput;
};

export type SchoolServiceEligibilityUpdateManyWithWhereWithoutServiceEligibilityInput =
  {
    data: SchoolServiceEligibilityUpdateManyMutationInput;
    where: SchoolServiceEligibilityScalarWhereInput;
  };

export type SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SchoolServiceEligibilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolServiceEligibilityCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolServiceEligibilityCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolServiceEligibilityCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SchoolServiceEligibilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolServiceEligibilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolServiceEligibilityWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolServiceEligibilityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolServiceEligibilityUpdateWithWhereUniqueWithoutSchoolInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolServiceEligibilityUpdateManyWithWhereWithoutSchoolInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolServiceEligibilityUpsertWithWhereUniqueWithoutSchoolInput>
  >;
};

export type SchoolServiceEligibilityUpdateManyWithoutServiceEligibilityNestedInput =
  {
    connect?: InputMaybe<Array<SchoolServiceEligibilityWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<SchoolServiceEligibilityCreateOrConnectWithoutServiceEligibilityInput>
    >;
    create?: InputMaybe<
      Array<SchoolServiceEligibilityCreateWithoutServiceEligibilityInput>
    >;
    createMany?: InputMaybe<SchoolServiceEligibilityCreateManyServiceEligibilityInputEnvelope>;
    delete?: InputMaybe<Array<SchoolServiceEligibilityWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SchoolServiceEligibilityScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SchoolServiceEligibilityWhereUniqueInput>>;
    set?: InputMaybe<Array<SchoolServiceEligibilityWhereUniqueInput>>;
    update?: InputMaybe<
      Array<SchoolServiceEligibilityUpdateWithWhereUniqueWithoutServiceEligibilityInput>
    >;
    updateMany?: InputMaybe<
      Array<SchoolServiceEligibilityUpdateManyWithWhereWithoutServiceEligibilityInput>
    >;
    upsert?: InputMaybe<
      Array<SchoolServiceEligibilityUpsertWithWhereUniqueWithoutServiceEligibilityInput>
    >;
  };

export type SchoolServiceEligibilityUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SchoolServiceEligibilityUpdateWithoutSchoolInput;
  where: SchoolServiceEligibilityWhereUniqueInput;
};

export type SchoolServiceEligibilityUpdateWithWhereUniqueWithoutServiceEligibilityInput =
  {
    data: SchoolServiceEligibilityUpdateWithoutServiceEligibilityInput;
    where: SchoolServiceEligibilityWhereUniqueInput;
  };

export type SchoolServiceEligibilityUpdateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceEligibility?: InputMaybe<ServiceEligibilityUpdateOneRequiredWithoutSchoolServiceEligibilitiesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolServiceEligibilityUpdateWithoutServiceEligibilityInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSchoolServiceEligibilitiesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolServiceEligibilityUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SchoolServiceEligibilityCreateWithoutSchoolInput;
  update: SchoolServiceEligibilityUpdateWithoutSchoolInput;
  where: SchoolServiceEligibilityWhereUniqueInput;
};

export type SchoolServiceEligibilityUpsertWithWhereUniqueWithoutServiceEligibilityInput =
  {
    create: SchoolServiceEligibilityCreateWithoutServiceEligibilityInput;
    update: SchoolServiceEligibilityUpdateWithoutServiceEligibilityInput;
    where: SchoolServiceEligibilityWhereUniqueInput;
  };

export type SchoolServiceEligibilityWhereInput = {
  AND?: InputMaybe<Array<SchoolServiceEligibilityWhereInput>>;
  NOT?: InputMaybe<Array<SchoolServiceEligibilityWhereInput>>;
  OR?: InputMaybe<Array<SchoolServiceEligibilityWhereInput>>;
  id?: InputMaybe<StringFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  serviceEligibility?: InputMaybe<ServiceEligibilityRelationFilter>;
  serviceEligibilityId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolServiceEligibilityWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolServiceEligibilityWhereInput>>;
  NOT?: InputMaybe<Array<SchoolServiceEligibilityWhereInput>>;
  OR?: InputMaybe<Array<SchoolServiceEligibilityWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  serviceEligibility?: InputMaybe<ServiceEligibilityRelationFilter>;
  serviceEligibilityId?: InputMaybe<StringFilter>;
  serviceEligibilityId_schoolId?: InputMaybe<SchoolServiceEligibilityServiceEligibilityIdSchoolIdCompoundUniqueInput>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolStage = {
  __typename?: "SchoolStage";
  Schools?: Maybe<Array<School>>;
  _count: SchoolStageCount;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  schools?: Maybe<Array<SchoolSchoolStage>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolStageCount = {
  __typename?: "SchoolStageCount";
  Schools: Scalars["Int"]["output"];
  schools: Scalars["Int"]["output"];
};

export type SchoolStageCreateInput = {
  Schools?: InputMaybe<SchoolCreateNestedManyWithoutStageInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  schools?: InputMaybe<SchoolSchoolStageCreateNestedManyWithoutStageInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStageCreateNestedOneWithoutSchoolsInput = {
  connect?: InputMaybe<SchoolStageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolStageCreateOrConnectWithoutSchoolsInput>;
  create?: InputMaybe<SchoolStageCreateWithoutSchoolsInput>;
};

export type SchoolStageCreateOrConnectWithoutSchoolsInput = {
  create: SchoolStageCreateWithoutSchoolsInput;
  where: SchoolStageWhereUniqueInput;
};

export type SchoolStageCreateWithoutSchoolsInput = {
  Schools?: InputMaybe<SchoolCreateNestedManyWithoutStageInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStageRelationFilter = {
  is?: InputMaybe<SchoolStageWhereInput>;
  isNot?: InputMaybe<SchoolStageWhereInput>;
};

export type SchoolStageResponsePayload = {
  __typename?: "SchoolStageResponsePayload";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
};

export type SchoolStageUpdateInput = {
  Schools?: InputMaybe<SchoolUpdateManyWithoutStageNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  schools?: InputMaybe<SchoolSchoolStageUpdateManyWithoutStageNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStageUpdateOneWithoutSchoolsNestedInput = {
  connect?: InputMaybe<SchoolStageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolStageCreateOrConnectWithoutSchoolsInput>;
  create?: InputMaybe<SchoolStageCreateWithoutSchoolsInput>;
  delete?: InputMaybe<SchoolStageWhereInput>;
  disconnect?: InputMaybe<SchoolStageWhereInput>;
  update?: InputMaybe<SchoolStageUpdateToOneWithWhereWithoutSchoolsInput>;
  upsert?: InputMaybe<SchoolStageUpsertWithoutSchoolsInput>;
};

export type SchoolStageUpdateToOneWithWhereWithoutSchoolsInput = {
  data: SchoolStageUpdateWithoutSchoolsInput;
  where?: InputMaybe<SchoolStageWhereInput>;
};

export type SchoolStageUpdateWithoutSchoolsInput = {
  Schools?: InputMaybe<SchoolUpdateManyWithoutStageNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStageUpsertInput = {
  create?: InputMaybe<SchoolStageCreateInput>;
  update?: InputMaybe<SchoolStageUpdateInput>;
  where?: InputMaybe<SchoolStageWhereUniqueInput>;
};

export type SchoolStageUpsertWithoutSchoolsInput = {
  create: SchoolStageCreateWithoutSchoolsInput;
  update: SchoolStageUpdateWithoutSchoolsInput;
  where?: InputMaybe<SchoolStageWhereInput>;
};

export type SchoolStageWhereInput = {
  AND?: InputMaybe<Array<SchoolStageWhereInput>>;
  NOT?: InputMaybe<Array<SchoolStageWhereInput>>;
  OR?: InputMaybe<Array<SchoolStageWhereInput>>;
  Schools?: InputMaybe<SchoolListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  schools?: InputMaybe<SchoolSchoolStageListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolStageWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolStageWhereInput>>;
  NOT?: InputMaybe<Array<SchoolStageWhereInput>>;
  OR?: InputMaybe<Array<SchoolStageWhereInput>>;
  Schools?: InputMaybe<SchoolListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  schools?: InputMaybe<SchoolSchoolStageListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum SchoolStatus {
  ARCHIVED = "ARCHIVED",
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export type SchoolStudent = {
  __typename?: "SchoolStudent";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  class: Class;
  classId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  division?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isPrimary: Scalars["Boolean"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  school: School;
  schoolId: Scalars["String"]["output"];
  student: Student;
  studentId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolStudentCreateManyClassInput = {
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
  studentId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStudentCreateManyClassInputEnvelope = {
  data: Array<SchoolStudentCreateManyClassInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolStudentCreateManySchoolInput = {
  classId: Scalars["String"]["input"];
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  studentId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStudentCreateManySchoolInputEnvelope = {
  data: Array<SchoolStudentCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolStudentCreateManyStudentInput = {
  classId: Scalars["String"]["input"];
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStudentCreateManyStudentInputEnvelope = {
  data: Array<SchoolStudentCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolStudentCreateNestedManyWithoutClassInput = {
  connect?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolStudentCreateOrConnectWithoutClassInput>
  >;
  create?: InputMaybe<Array<SchoolStudentCreateWithoutClassInput>>;
  createMany?: InputMaybe<SchoolStudentCreateManyClassInputEnvelope>;
};

export type SchoolStudentCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolStudentCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolStudentCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolStudentCreateManySchoolInputEnvelope>;
};

export type SchoolStudentCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolStudentCreateOrConnectWithoutStudentInput>
  >;
  create?: InputMaybe<Array<SchoolStudentCreateWithoutStudentInput>>;
  createMany?: InputMaybe<SchoolStudentCreateManyStudentInputEnvelope>;
};

export type SchoolStudentCreateOrConnectWithoutClassInput = {
  create: SchoolStudentCreateWithoutClassInput;
  where: SchoolStudentWhereUniqueInput;
};

export type SchoolStudentCreateOrConnectWithoutSchoolInput = {
  create: SchoolStudentCreateWithoutSchoolInput;
  where: SchoolStudentWhereUniqueInput;
};

export type SchoolStudentCreateOrConnectWithoutStudentInput = {
  create: SchoolStudentCreateWithoutStudentInput;
  where: SchoolStudentWhereUniqueInput;
};

export type SchoolStudentCreateWithoutClassInput = {
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  student: StudentCreateNestedOneWithoutSchoolsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStudentCreateWithoutSchoolInput = {
  class: ClassCreateNestedOneWithoutStudentsInput;
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  student: StudentCreateNestedOneWithoutSchoolsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStudentCreateWithoutStudentInput = {
  class: ClassCreateNestedOneWithoutStudentsInput;
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStudentListRelationFilter = {
  every?: InputMaybe<SchoolStudentWhereInput>;
  none?: InputMaybe<SchoolStudentWhereInput>;
  some?: InputMaybe<SchoolStudentWhereInput>;
};

export type SchoolStudentPayload = {
  __typename?: "SchoolStudentPayload";
  class: ClassPayload;
  code: Scalars["Float"]["output"];
  dateOfBirth: Scalars["DateTime"]["output"];
  division?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  gender: Gender;
  id: Scalars["String"]["output"];
  isPrimary: Scalars["Boolean"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  middleNameAr?: Maybe<Scalars["String"]["output"]>;
  passengerId: Scalars["String"]["output"];
  photoURL?: Maybe<Scalars["String"]["output"]>;
};

export type SchoolStudentScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolStudentScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolStudentScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolStudentScalarWhereInput>>;
  classId?: InputMaybe<StringFilter>;
  division?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPrimary?: InputMaybe<BoolFilter>;
  notes?: InputMaybe<StringFilter>;
  schoolId?: InputMaybe<StringFilter>;
  studentId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolStudentSchoolIdStudentIdArchivedAtCompoundUniqueInput = {
  schoolId: Scalars["String"]["input"];
  studentId: Scalars["String"]["input"];
};

export type SchoolStudentUpdateManyMutationInput = {
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStudentUpdateManyWithWhereWithoutClassInput = {
  data: SchoolStudentUpdateManyMutationInput;
  where: SchoolStudentScalarWhereInput;
};

export type SchoolStudentUpdateManyWithWhereWithoutSchoolInput = {
  data: SchoolStudentUpdateManyMutationInput;
  where: SchoolStudentScalarWhereInput;
};

export type SchoolStudentUpdateManyWithWhereWithoutStudentInput = {
  data: SchoolStudentUpdateManyMutationInput;
  where: SchoolStudentScalarWhereInput;
};

export type SchoolStudentUpdateManyWithoutClassNestedInput = {
  connect?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolStudentCreateOrConnectWithoutClassInput>
  >;
  create?: InputMaybe<Array<SchoolStudentCreateWithoutClassInput>>;
  createMany?: InputMaybe<SchoolStudentCreateManyClassInputEnvelope>;
  delete?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolStudentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolStudentUpdateWithWhereUniqueWithoutClassInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolStudentUpdateManyWithWhereWithoutClassInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolStudentUpsertWithWhereUniqueWithoutClassInput>
  >;
};

export type SchoolStudentUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolStudentCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SchoolStudentCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolStudentCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolStudentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolStudentUpdateWithWhereUniqueWithoutSchoolInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolStudentUpdateManyWithWhereWithoutSchoolInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolStudentUpsertWithWhereUniqueWithoutSchoolInput>
  >;
};

export type SchoolStudentUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SchoolStudentCreateOrConnectWithoutStudentInput>
  >;
  create?: InputMaybe<Array<SchoolStudentCreateWithoutStudentInput>>;
  createMany?: InputMaybe<SchoolStudentCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolStudentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolStudentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolStudentUpdateWithWhereUniqueWithoutStudentInput>
  >;
  updateMany?: InputMaybe<
    Array<SchoolStudentUpdateManyWithWhereWithoutStudentInput>
  >;
  upsert?: InputMaybe<
    Array<SchoolStudentUpsertWithWhereUniqueWithoutStudentInput>
  >;
};

export type SchoolStudentUpdateWithWhereUniqueWithoutClassInput = {
  data: SchoolStudentUpdateWithoutClassInput;
  where: SchoolStudentWhereUniqueInput;
};

export type SchoolStudentUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SchoolStudentUpdateWithoutSchoolInput;
  where: SchoolStudentWhereUniqueInput;
};

export type SchoolStudentUpdateWithWhereUniqueWithoutStudentInput = {
  data: SchoolStudentUpdateWithoutStudentInput;
  where: SchoolStudentWhereUniqueInput;
};

export type SchoolStudentUpdateWithoutClassInput = {
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStudentUpdateWithoutSchoolInput = {
  class?: InputMaybe<ClassUpdateOneRequiredWithoutStudentsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutSchoolsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStudentUpdateWithoutStudentInput = {
  class?: InputMaybe<ClassUpdateOneRequiredWithoutStudentsNestedInput>;
  division?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolStudentUpsertWithWhereUniqueWithoutClassInput = {
  create: SchoolStudentCreateWithoutClassInput;
  update: SchoolStudentUpdateWithoutClassInput;
  where: SchoolStudentWhereUniqueInput;
};

export type SchoolStudentUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SchoolStudentCreateWithoutSchoolInput;
  update: SchoolStudentUpdateWithoutSchoolInput;
  where: SchoolStudentWhereUniqueInput;
};

export type SchoolStudentUpsertWithWhereUniqueWithoutStudentInput = {
  create: SchoolStudentCreateWithoutStudentInput;
  update: SchoolStudentUpdateWithoutStudentInput;
  where: SchoolStudentWhereUniqueInput;
};

export type SchoolStudentWhereInput = {
  AND?: InputMaybe<Array<SchoolStudentWhereInput>>;
  NOT?: InputMaybe<Array<SchoolStudentWhereInput>>;
  OR?: InputMaybe<Array<SchoolStudentWhereInput>>;
  class?: InputMaybe<ClassRelationFilter>;
  classId?: InputMaybe<StringFilter>;
  division?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPrimary?: InputMaybe<BoolFilter>;
  notes?: InputMaybe<StringFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolStudentWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolStudentWhereInput>>;
  NOT?: InputMaybe<Array<SchoolStudentWhereInput>>;
  OR?: InputMaybe<Array<SchoolStudentWhereInput>>;
  class?: InputMaybe<ClassRelationFilter>;
  classId?: InputMaybe<StringFilter>;
  division?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<BoolFilter>;
  notes?: InputMaybe<StringFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  schoolId_studentId_archivedAt?: InputMaybe<SchoolStudentSchoolIdStudentIdArchivedAtCompoundUniqueInput>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolStudentsStats = {
  __typename?: "SchoolStudentsStats";
  activeStudentsCount: Scalars["Int"]["output"];
  studentsWithFullFilledRoutesCount: Scalars["Int"]["output"];
};

export enum SchoolTripStopStatus {
  COMPLETED = "COMPLETED",
  INVALID = "INVALID",
  IN_TRANSIT = "IN_TRANSIT",
  MISSED = "MISSED",
  NEARBY = "NEARBY",
  PENDING = "PENDING",
  VISITED = "VISITED",
}

export type SchoolType = {
  __typename?: "SchoolType";
  School?: Maybe<Array<School>>;
  _count: SchoolTypeCount;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SchoolTypeCount = {
  __typename?: "SchoolTypeCount";
  School: Scalars["Int"]["output"];
};

export type SchoolTypeCreateNestedOneWithoutSchoolInput = {
  connect?: InputMaybe<SchoolTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolTypeCreateOrConnectWithoutSchoolInput>;
  create?: InputMaybe<SchoolTypeCreateWithoutSchoolInput>;
};

export type SchoolTypeCreateOrConnectWithoutSchoolInput = {
  create: SchoolTypeCreateWithoutSchoolInput;
  where: SchoolTypeWhereUniqueInput;
};

export type SchoolTypeCreateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolTypeRelationFilter = {
  is?: InputMaybe<SchoolTypeWhereInput>;
  isNot?: InputMaybe<SchoolTypeWhereInput>;
};

export type SchoolTypeResponsePayload = {
  __typename?: "SchoolTypeResponsePayload";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
};

export type SchoolTypeUpdateOneWithoutSchoolNestedInput = {
  connect?: InputMaybe<SchoolTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolTypeCreateOrConnectWithoutSchoolInput>;
  create?: InputMaybe<SchoolTypeCreateWithoutSchoolInput>;
  delete?: InputMaybe<SchoolTypeWhereInput>;
  disconnect?: InputMaybe<SchoolTypeWhereInput>;
  update?: InputMaybe<SchoolTypeUpdateToOneWithWhereWithoutSchoolInput>;
  upsert?: InputMaybe<SchoolTypeUpsertWithoutSchoolInput>;
};

export type SchoolTypeUpdateToOneWithWhereWithoutSchoolInput = {
  data: SchoolTypeUpdateWithoutSchoolInput;
  where?: InputMaybe<SchoolTypeWhereInput>;
};

export type SchoolTypeUpdateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolTypeUpsertInput = {
  create?: InputMaybe<SchoolTypeCreateWithoutSchoolInput>;
  update?: InputMaybe<SchoolTypeUpdateWithoutSchoolInput>;
  where?: InputMaybe<SchoolTypeWhereUniqueInput>;
};

export type SchoolTypeUpsertWithoutSchoolInput = {
  create: SchoolTypeCreateWithoutSchoolInput;
  update: SchoolTypeUpdateWithoutSchoolInput;
  where?: InputMaybe<SchoolTypeWhereInput>;
};

export type SchoolTypeWhereInput = {
  AND?: InputMaybe<Array<SchoolTypeWhereInput>>;
  NOT?: InputMaybe<Array<SchoolTypeWhereInput>>;
  OR?: InputMaybe<Array<SchoolTypeWhereInput>>;
  School?: InputMaybe<SchoolListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolTypeWhereInput>>;
  NOT?: InputMaybe<Array<SchoolTypeWhereInput>>;
  OR?: InputMaybe<Array<SchoolTypeWhereInput>>;
  School?: InputMaybe<SchoolListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolUpdateInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateManyMutationInput = {
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<SchoolStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateManyWithWhereWithoutStageInput = {
  data: SchoolUpdateManyMutationInput;
  where: SchoolScalarWhereInput;
};

export type SchoolUpdateManyWithoutStageNestedInput = {
  connect?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolCreateOrConnectWithoutStageInput>>;
  create?: InputMaybe<Array<SchoolCreateWithoutStageInput>>;
  createMany?: InputMaybe<SchoolCreateManyStageInputEnvelope>;
  delete?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  update?: InputMaybe<Array<SchoolUpdateWithWhereUniqueWithoutStageInput>>;
  updateMany?: InputMaybe<Array<SchoolUpdateManyWithWhereWithoutStageInput>>;
  upsert?: InputMaybe<Array<SchoolUpsertWithWhereUniqueWithoutStageInput>>;
};

export type SchoolUpdateOneRequiredWithoutAdhocNotificationStudentUsersNestedInput =
  {
    connect?: InputMaybe<SchoolWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutAdhocNotificationStudentUsersInput>;
    create?: InputMaybe<SchoolCreateWithoutAdhocNotificationStudentUsersInput>;
    update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutAdhocNotificationStudentUsersInput>;
    upsert?: InputMaybe<SchoolUpsertWithoutAdhocNotificationStudentUsersInput>;
  };

export type SchoolUpdateOneRequiredWithoutClassesNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutClassesInput>;
  create?: InputMaybe<SchoolCreateWithoutClassesInput>;
  update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutClassesInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutClassesInput>;
};

export type SchoolUpdateOneRequiredWithoutCollectionPointsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutCollectionPointsInput>;
  create?: InputMaybe<SchoolCreateWithoutCollectionPointsInput>;
  update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutCollectionPointsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutCollectionPointsInput>;
};

export type SchoolUpdateOneRequiredWithoutSchoolRegistrationAttemptsNestedInput =
  {
    connect?: InputMaybe<SchoolWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSchoolRegistrationAttemptsInput>;
    create?: InputMaybe<SchoolCreateWithoutSchoolRegistrationAttemptsInput>;
    update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutSchoolRegistrationAttemptsInput>;
    upsert?: InputMaybe<SchoolUpsertWithoutSchoolRegistrationAttemptsInput>;
  };

export type SchoolUpdateOneRequiredWithoutSchoolServiceEligibilitiesNestedInput =
  {
    connect?: InputMaybe<SchoolWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSchoolServiceEligibilitiesInput>;
    create?: InputMaybe<SchoolCreateWithoutSchoolServiceEligibilitiesInput>;
    update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutSchoolServiceEligibilitiesInput>;
    upsert?: InputMaybe<SchoolUpsertWithoutSchoolServiceEligibilitiesInput>;
  };

export type SchoolUpdateOneRequiredWithoutServiceProviderSchoolsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutServiceProviderSchoolsInput>;
  create?: InputMaybe<SchoolCreateWithoutServiceProviderSchoolsInput>;
  update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutServiceProviderSchoolsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutServiceProviderSchoolsInput>;
};

export type SchoolUpdateOneRequiredWithoutServiceZoneSchoolNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutServiceZoneSchoolInput>;
  create?: InputMaybe<SchoolCreateWithoutServiceZoneSchoolInput>;
  update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutServiceZoneSchoolInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutServiceZoneSchoolInput>;
};

export type SchoolUpdateOneRequiredWithoutStudentsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<SchoolCreateWithoutStudentsInput>;
  update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutStudentsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutStudentsInput>;
};

export type SchoolUpdateOneWithoutAddressNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<SchoolCreateWithoutAddressInput>;
  delete?: InputMaybe<SchoolWhereInput>;
  disconnect?: InputMaybe<SchoolWhereInput>;
  update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutAddressInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutAddressInput>;
};

export type SchoolUpdateOneWithoutScheduleGroupsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutScheduleGroupsInput>;
  create?: InputMaybe<SchoolCreateWithoutScheduleGroupsInput>;
  delete?: InputMaybe<SchoolWhereInput>;
  disconnect?: InputMaybe<SchoolWhereInput>;
  update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutScheduleGroupsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutScheduleGroupsInput>;
};

export type SchoolUpdateOneWithoutSemestersNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSemestersInput>;
  create?: InputMaybe<SchoolCreateWithoutSemestersInput>;
  delete?: InputMaybe<SchoolWhereInput>;
  disconnect?: InputMaybe<SchoolWhereInput>;
  update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutSemestersInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutSemestersInput>;
};

export type SchoolUpdateOneWithoutStagesNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStagesInput>;
  create?: InputMaybe<SchoolCreateWithoutStagesInput>;
  delete?: InputMaybe<SchoolWhereInput>;
  disconnect?: InputMaybe<SchoolWhereInput>;
  update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutStagesInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutStagesInput>;
};

export type SchoolUpdateOneWithoutStopNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStopInput>;
  create?: InputMaybe<SchoolCreateWithoutStopInput>;
  delete?: InputMaybe<SchoolWhereInput>;
  disconnect?: InputMaybe<SchoolWhereInput>;
  update?: InputMaybe<SchoolUpdateToOneWithWhereWithoutStopInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutStopInput>;
};

export type SchoolUpdateToOneWithWhereWithoutAddressInput = {
  data: SchoolUpdateWithoutAddressInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateToOneWithWhereWithoutAdhocNotificationStudentUsersInput =
  {
    data: SchoolUpdateWithoutAdhocNotificationStudentUsersInput;
    where?: InputMaybe<SchoolWhereInput>;
  };

export type SchoolUpdateToOneWithWhereWithoutClassesInput = {
  data: SchoolUpdateWithoutClassesInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateToOneWithWhereWithoutCollectionPointsInput = {
  data: SchoolUpdateWithoutCollectionPointsInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateToOneWithWhereWithoutScheduleGroupsInput = {
  data: SchoolUpdateWithoutScheduleGroupsInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateToOneWithWhereWithoutSchoolRegistrationAttemptsInput = {
  data: SchoolUpdateWithoutSchoolRegistrationAttemptsInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateToOneWithWhereWithoutSchoolServiceEligibilitiesInput = {
  data: SchoolUpdateWithoutSchoolServiceEligibilitiesInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateToOneWithWhereWithoutSemestersInput = {
  data: SchoolUpdateWithoutSemestersInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateToOneWithWhereWithoutServiceProviderSchoolsInput = {
  data: SchoolUpdateWithoutServiceProviderSchoolsInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateToOneWithWhereWithoutServiceZoneSchoolInput = {
  data: SchoolUpdateWithoutServiceZoneSchoolInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateToOneWithWhereWithoutStagesInput = {
  data: SchoolUpdateWithoutStagesInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateToOneWithWhereWithoutStopInput = {
  data: SchoolUpdateWithoutStopInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateToOneWithWhereWithoutStudentsInput = {
  data: SchoolUpdateWithoutStudentsInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpdateWithWhereUniqueWithoutStageInput = {
  data: SchoolUpdateWithoutStageInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolUpdateWithoutAddressInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutAdhocNotificationStudentUsersInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutClassesInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutCollectionPointsInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutScheduleGroupsInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutSchoolRegistrationAttemptsInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutSchoolServiceEligibilitiesInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutSemestersInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutServiceProviderSchoolsInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutServiceZoneSchoolInput = {
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutStageInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutStagesInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutStopInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  students?: InputMaybe<SchoolStudentUpdateManyWithoutSchoolNestedInput>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateWithoutStudentsInput = {
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutSchoolNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutSchoolNestedInput>;
  classes?: InputMaybe<SchoolClassUpdateManyWithoutSchoolNestedInput>;
  collectionPoints?: InputMaybe<SchoolCollectionPointUpdateManyWithoutSchoolNestedInput>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dropOffPoint?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSchoolNestedInput>;
  schoolCode?: InputMaybe<Scalars["String"]["input"]>;
  schoolGroup?: InputMaybe<SchoolGroupUpdateOneWithoutSchoolsNestedInput>;
  schoolName?: InputMaybe<Scalars["String"]["input"]>;
  schoolNameAr?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutSchoolNestedInput>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutSchoolNestedInput>;
  semesters?: InputMaybe<SemesterUpdateManyWithoutSchoolNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput>;
  stage?: InputMaybe<SchoolStageUpdateOneWithoutSchoolsNestedInput>;
  stages?: InputMaybe<SchoolSchoolStageUpdateManyWithoutSchoolNestedInput>;
  status?: InputMaybe<SchoolStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<SchoolTypeUpdateOneWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpsertWithWhereUniqueWithoutStageInput = {
  create: SchoolCreateWithoutStageInput;
  update: SchoolUpdateWithoutStageInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolUpsertWithoutAddressInput = {
  create: SchoolCreateWithoutAddressInput;
  update: SchoolUpdateWithoutAddressInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutAdhocNotificationStudentUsersInput = {
  create: SchoolCreateWithoutAdhocNotificationStudentUsersInput;
  update: SchoolUpdateWithoutAdhocNotificationStudentUsersInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutClassesInput = {
  create: SchoolCreateWithoutClassesInput;
  update: SchoolUpdateWithoutClassesInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutCollectionPointsInput = {
  create: SchoolCreateWithoutCollectionPointsInput;
  update: SchoolUpdateWithoutCollectionPointsInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutScheduleGroupsInput = {
  create: SchoolCreateWithoutScheduleGroupsInput;
  update: SchoolUpdateWithoutScheduleGroupsInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutSchoolRegistrationAttemptsInput = {
  create: SchoolCreateWithoutSchoolRegistrationAttemptsInput;
  update: SchoolUpdateWithoutSchoolRegistrationAttemptsInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutSchoolServiceEligibilitiesInput = {
  create: SchoolCreateWithoutSchoolServiceEligibilitiesInput;
  update: SchoolUpdateWithoutSchoolServiceEligibilitiesInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutSemestersInput = {
  create: SchoolCreateWithoutSemestersInput;
  update: SchoolUpdateWithoutSemestersInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutServiceProviderSchoolsInput = {
  create: SchoolCreateWithoutServiceProviderSchoolsInput;
  update: SchoolUpdateWithoutServiceProviderSchoolsInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutServiceZoneSchoolInput = {
  create: SchoolCreateWithoutServiceZoneSchoolInput;
  update: SchoolUpdateWithoutServiceZoneSchoolInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutStagesInput = {
  create: SchoolCreateWithoutStagesInput;
  update: SchoolUpdateWithoutStagesInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutStopInput = {
  create: SchoolCreateWithoutStopInput;
  update: SchoolUpdateWithoutStopInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolUpsertWithoutStudentsInput = {
  create: SchoolCreateWithoutStudentsInput;
  update: SchoolUpdateWithoutStudentsInput;
  where?: InputMaybe<SchoolWhereInput>;
};

export type SchoolWhereInput = {
  AND?: InputMaybe<Array<SchoolWhereInput>>;
  NOT?: InputMaybe<Array<SchoolWhereInput>>;
  OR?: InputMaybe<Array<SchoolWhereInput>>;
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolListRelationFilter>;
  address?: InputMaybe<AddressRelationFilter>;
  addressId?: InputMaybe<StringFilter>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserListRelationFilter>;
  classes?: InputMaybe<SchoolClassListRelationFilter>;
  collectionPoints?: InputMaybe<SchoolCollectionPointListRelationFilter>;
  comment?: InputMaybe<StringFilter>;
  dropOffPoint?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  idInt?: InputMaybe<IntFilter>;
  logo?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  pickUpPoint?: InputMaybe<StringFilter>;
  radius?: InputMaybe<IntFilter>;
  scheduleGroups?: InputMaybe<ScheduleGroupListRelationFilter>;
  schoolCode?: InputMaybe<StringFilter>;
  schoolGroup?: InputMaybe<SchoolGroupRelationFilter>;
  schoolGroupId?: InputMaybe<StringFilter>;
  schoolName?: InputMaybe<StringFilter>;
  schoolNameAr?: InputMaybe<StringFilter>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptListRelationFilter>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityListRelationFilter>;
  semesters?: InputMaybe<SemesterListRelationFilter>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolListRelationFilter>;
  stage?: InputMaybe<SchoolStageRelationFilter>;
  stageId?: InputMaybe<StringFilter>;
  stages?: InputMaybe<SchoolSchoolStageListRelationFilter>;
  status?: InputMaybe<EnumSchoolStatusFilter>;
  students?: InputMaybe<SchoolStudentListRelationFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  type?: InputMaybe<SchoolTypeRelationFilter>;
  typeId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolWhereUniqueInput = {
  AND?: InputMaybe<Array<SchoolWhereInput>>;
  NOT?: InputMaybe<Array<SchoolWhereInput>>;
  OR?: InputMaybe<Array<SchoolWhereInput>>;
  ServiceZoneSchool?: InputMaybe<ServiceZoneSchoolListRelationFilter>;
  address?: InputMaybe<AddressRelationFilter>;
  addressId?: InputMaybe<Scalars["String"]["input"]>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserListRelationFilter>;
  classes?: InputMaybe<SchoolClassListRelationFilter>;
  collectionPoints?: InputMaybe<SchoolCollectionPointListRelationFilter>;
  comment?: InputMaybe<StringFilter>;
  dropOffPoint?: InputMaybe<StringFilter>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  idInt?: InputMaybe<Scalars["Int"]["input"]>;
  logo?: InputMaybe<StringFilter>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pickUpPoint?: InputMaybe<StringFilter>;
  radius?: InputMaybe<IntFilter>;
  scheduleGroups?: InputMaybe<ScheduleGroupListRelationFilter>;
  schoolCode?: InputMaybe<StringFilter>;
  schoolGroup?: InputMaybe<SchoolGroupRelationFilter>;
  schoolGroupId?: InputMaybe<StringFilter>;
  schoolName?: InputMaybe<StringFilter>;
  schoolNameAr?: InputMaybe<StringFilter>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptListRelationFilter>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityListRelationFilter>;
  semesters?: InputMaybe<SemesterListRelationFilter>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolListRelationFilter>;
  stage?: InputMaybe<SchoolStageRelationFilter>;
  stageId?: InputMaybe<StringFilter>;
  stages?: InputMaybe<SchoolSchoolStageListRelationFilter>;
  status?: InputMaybe<EnumSchoolStatusFilter>;
  students?: InputMaybe<SchoolStudentListRelationFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  type?: InputMaybe<SchoolTypeRelationFilter>;
  typeId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolWithServiceDefinitions = {
  __typename?: "SchoolWithServiceDefinitions";
  schoolId: Scalars["String"]["output"];
  serviceDefinitions: Array<Service>;
};

export type SchoolsForWeb = {
  __typename?: "SchoolsForWeb";
  ServiceZoneSchool?: Maybe<Array<ServiceZoneSchool>>;
  _count: SchoolCount;
  address: Address;
  addressId: Scalars["String"]["output"];
  /** ///////////////////////////// */
  adhocNotificationStudentUsers?: Maybe<Array<AdhocNotificationStudentUser>>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  classes?: Maybe<Array<SchoolClass>>;
  collectionPointsCount?: Maybe<Scalars["Float"]["output"]>;
  comment?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  dropOffPoint?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  idInt: Scalars["Int"]["output"];
  logo?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  pickUpPoint?: Maybe<Scalars["String"]["output"]>;
  primaryGuardiansCount?: Maybe<Scalars["Float"]["output"]>;
  radius: Scalars["Int"]["output"];
  scheduleGroups?: Maybe<Array<ScheduleGroup>>;
  schoolCode?: Maybe<Scalars["String"]["output"]>;
  schoolGroup?: Maybe<SchoolGroup>;
  schoolGroupId?: Maybe<Scalars["String"]["output"]>;
  schoolName: Scalars["String"]["output"];
  schoolNameAr: Scalars["String"]["output"];
  schoolRegistrationAttempts?: Maybe<Array<SchoolRegistrationAttempt>>;
  schoolServiceEligibilities?: Maybe<Array<SchoolServiceEligibility>>;
  semesters?: Maybe<Array<Semester>>;
  serviceProviderSchools?: Maybe<Array<ServiceProviderSchool>>;
  stage?: Maybe<SchoolStage>;
  stageId?: Maybe<Scalars["String"]["output"]>;
  stages?: Maybe<Array<SchoolSchoolStage>>;
  status: SchoolStatus;
  stop?: Maybe<Stop>;
  students?: Maybe<Array<SchoolStudent>>;
  studentsStats?: Maybe<SchoolStudentsStats>;
  submittedAt?: Maybe<Scalars["DateTime"]["output"]>;
  type?: Maybe<SchoolType>;
  typeId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SearchCollectionPointsFilterInput = {
  administrativeAreaIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  countryIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  localityIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  schoolIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  statuses?: InputMaybe<Array<CollectionPointStatus>>;
  subLocalityIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type SearchRoutesFilterInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  routesIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  statuses?: InputMaybe<Array<RouteStatus>>;
};

export type SearchSchoolRegistrationClause = {
  isParentName?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPhoneNumber?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStudentName?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SearchSchoolRegistrationsFilter = {
  collectionPointIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  countryCodes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  directionality?: InputMaybe<RouteDirectionality>;
  schoolIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type SearchServiceProvidersFilterInput = {
  schoolIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Semester = {
  __typename?: "Semester";
  _count: SemesterCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  scheduleGroups?: Maybe<Array<ScheduleGroup>>;
  schedules?: Maybe<Array<Schedule>>;
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  titleAr?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SemesterCount = {
  __typename?: "SemesterCount";
  scheduleGroups: Scalars["Int"]["output"];
  schedules: Scalars["Int"]["output"];
};

export type SemesterCreateManySchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SemesterCreateManySchoolInputEnvelope = {
  data: Array<SemesterCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SemesterCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SemesterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SemesterCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SemesterCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SemesterCreateManySchoolInputEnvelope>;
};

export type SemesterCreateNestedOneWithoutScheduleGroupsInput = {
  connect?: InputMaybe<SemesterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SemesterCreateOrConnectWithoutScheduleGroupsInput>;
  create?: InputMaybe<SemesterCreateWithoutScheduleGroupsInput>;
};

export type SemesterCreateNestedOneWithoutSchedulesInput = {
  connect?: InputMaybe<SemesterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SemesterCreateOrConnectWithoutSchedulesInput>;
  create?: InputMaybe<SemesterCreateWithoutSchedulesInput>;
};

export type SemesterCreateOrConnectWithoutScheduleGroupsInput = {
  create: SemesterCreateWithoutScheduleGroupsInput;
  where: SemesterWhereUniqueInput;
};

export type SemesterCreateOrConnectWithoutSchedulesInput = {
  create: SemesterCreateWithoutSchedulesInput;
  where: SemesterWhereUniqueInput;
};

export type SemesterCreateOrConnectWithoutSchoolInput = {
  create: SemesterCreateWithoutSchoolInput;
  where: SemesterWhereUniqueInput;
};

export type SemesterCreateWithoutScheduleGroupsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schedules?: InputMaybe<ScheduleCreateNestedManyWithoutSemesterInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSemestersInput>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SemesterCreateWithoutSchedulesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSemesterInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSemestersInput>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SemesterCreateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupCreateNestedManyWithoutSemesterInput>;
  schedules?: InputMaybe<ScheduleCreateNestedManyWithoutSemesterInput>;
  title: Scalars["String"]["input"];
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SemesterListRelationFilter = {
  every?: InputMaybe<SemesterWhereInput>;
  none?: InputMaybe<SemesterWhereInput>;
  some?: InputMaybe<SemesterWhereInput>;
};

export type SemesterRelationFilter = {
  is?: InputMaybe<SemesterWhereInput>;
  isNot?: InputMaybe<SemesterWhereInput>;
};

export type SemesterResponsePayload = {
  __typename?: "SemesterResponsePayload";
  id: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  titleAr?: Maybe<Scalars["String"]["output"]>;
};

export type SemesterScalarWhereInput = {
  AND?: InputMaybe<Array<SemesterScalarWhereInput>>;
  NOT?: InputMaybe<Array<SemesterScalarWhereInput>>;
  OR?: InputMaybe<Array<SemesterScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  schoolId?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  titleAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SemesterUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SemesterUpdateManyWithWhereWithoutSchoolInput = {
  data: SemesterUpdateManyMutationInput;
  where: SemesterScalarWhereInput;
};

export type SemesterUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SemesterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SemesterCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<SemesterCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SemesterCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SemesterWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SemesterScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SemesterWhereUniqueInput>>;
  set?: InputMaybe<Array<SemesterWhereUniqueInput>>;
  update?: InputMaybe<Array<SemesterUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<SemesterUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<SemesterUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type SemesterUpdateOneWithoutScheduleGroupsNestedInput = {
  connect?: InputMaybe<SemesterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SemesterCreateOrConnectWithoutScheduleGroupsInput>;
  create?: InputMaybe<SemesterCreateWithoutScheduleGroupsInput>;
  delete?: InputMaybe<SemesterWhereInput>;
  disconnect?: InputMaybe<SemesterWhereInput>;
  update?: InputMaybe<SemesterUpdateToOneWithWhereWithoutScheduleGroupsInput>;
  upsert?: InputMaybe<SemesterUpsertWithoutScheduleGroupsInput>;
};

export type SemesterUpdateOneWithoutSchedulesNestedInput = {
  connect?: InputMaybe<SemesterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SemesterCreateOrConnectWithoutSchedulesInput>;
  create?: InputMaybe<SemesterCreateWithoutSchedulesInput>;
  delete?: InputMaybe<SemesterWhereInput>;
  disconnect?: InputMaybe<SemesterWhereInput>;
  update?: InputMaybe<SemesterUpdateToOneWithWhereWithoutSchedulesInput>;
  upsert?: InputMaybe<SemesterUpsertWithoutSchedulesInput>;
};

export type SemesterUpdateToOneWithWhereWithoutScheduleGroupsInput = {
  data: SemesterUpdateWithoutScheduleGroupsInput;
  where?: InputMaybe<SemesterWhereInput>;
};

export type SemesterUpdateToOneWithWhereWithoutSchedulesInput = {
  data: SemesterUpdateWithoutSchedulesInput;
  where?: InputMaybe<SemesterWhereInput>;
};

export type SemesterUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SemesterUpdateWithoutSchoolInput;
  where: SemesterWhereUniqueInput;
};

export type SemesterUpdateWithoutScheduleGroupsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schedules?: InputMaybe<ScheduleUpdateManyWithoutSemesterNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSemestersNestedInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SemesterUpdateWithoutSchedulesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSemesterNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSemestersNestedInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SemesterUpdateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupUpdateManyWithoutSemesterNestedInput>;
  schedules?: InputMaybe<ScheduleUpdateManyWithoutSemesterNestedInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SemesterUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SemesterCreateWithoutSchoolInput;
  update: SemesterUpdateWithoutSchoolInput;
  where: SemesterWhereUniqueInput;
};

export type SemesterUpsertWithoutScheduleGroupsInput = {
  create: SemesterCreateWithoutScheduleGroupsInput;
  update: SemesterUpdateWithoutScheduleGroupsInput;
  where?: InputMaybe<SemesterWhereInput>;
};

export type SemesterUpsertWithoutSchedulesInput = {
  create: SemesterCreateWithoutSchedulesInput;
  update: SemesterUpdateWithoutSchedulesInput;
  where?: InputMaybe<SemesterWhereInput>;
};

export type SemesterWhereInput = {
  AND?: InputMaybe<Array<SemesterWhereInput>>;
  NOT?: InputMaybe<Array<SemesterWhereInput>>;
  OR?: InputMaybe<Array<SemesterWhereInput>>;
  id?: InputMaybe<StringFilter>;
  scheduleGroups?: InputMaybe<ScheduleGroupListRelationFilter>;
  schedules?: InputMaybe<ScheduleListRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  titleAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SemesterWhereUniqueInput = {
  AND?: InputMaybe<Array<SemesterWhereInput>>;
  NOT?: InputMaybe<Array<SemesterWhereInput>>;
  OR?: InputMaybe<Array<SemesterWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupListRelationFilter>;
  schedules?: InputMaybe<ScheduleListRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  titleAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Service = {
  __typename?: "Service";
  _count: ServiceCount;
  allowOnlinePayment: Scalars["Boolean"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  category?: Maybe<ServiceCategory>;
  consumptionDurationInMonths?: Maybe<Scalars["Int"]["output"]>;
  consumptionStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  durationInMonths?: Maybe<Scalars["Int"]["output"]>;
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  installmentPlansOrderingStrategy: InstallmentPlansOrderingStrategy;
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  paymentPlansPeriods?: Maybe<Array<PaymentPlanPeriod>>;
  photo?: Maybe<Scalars["String"]["output"]>;
  pricePreview: ServicePricePreview;
  promotionalInfo?: Maybe<Scalars["JSON"]["output"]>;
  registrationEndDate?: Maybe<Scalars["DateTime"]["output"]>;
  registrationStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  serviceAcademicYear: Scalars["Int"]["output"];
  serviceCategoryId?: Maybe<Scalars["String"]["output"]>;
  serviceEligibilities?: Maybe<Array<ServiceEligibility>>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderId?: Maybe<Scalars["String"]["output"]>;
  serviceType: ServiceType;
  serviceZone?: Maybe<ServiceZone>;
  serviceZoneId?: Maybe<Scalars["String"]["output"]>;
  serviceZoneSchools?: Maybe<Array<ServiceZoneSchool>>;
  startDate: Scalars["DateTime"]["output"];
  status: Status;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ServiceCategory = {
  __typename?: "ServiceCategory";
  _count: ServiceCategoryCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  descriptionAr: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  services?: Maybe<Array<Service>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ServiceCategoryCount = {
  __typename?: "ServiceCategoryCount";
  services: Scalars["Int"]["output"];
};

export type ServiceCategoryCreateNestedOneWithoutServicesInput = {
  connect?: InputMaybe<ServiceCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCategoryCreateOrConnectWithoutServicesInput>;
  create?: InputMaybe<ServiceCategoryCreateWithoutServicesInput>;
};

export type ServiceCategoryCreateOrConnectWithoutServicesInput = {
  create: ServiceCategoryCreateWithoutServicesInput;
  where: ServiceCategoryWhereUniqueInput;
};

export type ServiceCategoryCreateWithoutServicesInput = {
  description: Scalars["String"]["input"];
  descriptionAr: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceCategoryRelationFilter = {
  is?: InputMaybe<ServiceCategoryWhereInput>;
  isNot?: InputMaybe<ServiceCategoryWhereInput>;
};

export type ServiceCategoryResponsePayload = {
  __typename?: "ServiceCategoryResponsePayload";
  /** The creation time */
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  descriptionAr: Scalars["String"]["output"];
  /** A UUID */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  /** The last update time */
  updatedAt: Scalars["DateTime"]["output"];
};

export type ServiceCategoryUpdateOneWithoutServicesNestedInput = {
  connect?: InputMaybe<ServiceCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCategoryCreateOrConnectWithoutServicesInput>;
  create?: InputMaybe<ServiceCategoryCreateWithoutServicesInput>;
  delete?: InputMaybe<ServiceCategoryWhereInput>;
  disconnect?: InputMaybe<ServiceCategoryWhereInput>;
  update?: InputMaybe<ServiceCategoryUpdateToOneWithWhereWithoutServicesInput>;
  upsert?: InputMaybe<ServiceCategoryUpsertWithoutServicesInput>;
};

export type ServiceCategoryUpdateToOneWithWhereWithoutServicesInput = {
  data: ServiceCategoryUpdateWithoutServicesInput;
  where?: InputMaybe<ServiceCategoryWhereInput>;
};

export type ServiceCategoryUpdateWithoutServicesInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  descriptionAr?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceCategoryUpsertWithoutServicesInput = {
  create: ServiceCategoryCreateWithoutServicesInput;
  update: ServiceCategoryUpdateWithoutServicesInput;
  where?: InputMaybe<ServiceCategoryWhereInput>;
};

export type ServiceCategoryWhereInput = {
  AND?: InputMaybe<Array<ServiceCategoryWhereInput>>;
  NOT?: InputMaybe<Array<ServiceCategoryWhereInput>>;
  OR?: InputMaybe<Array<ServiceCategoryWhereInput>>;
  description?: InputMaybe<StringFilter>;
  descriptionAr?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  services?: InputMaybe<ServiceListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceCategoryWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceCategoryWhereInput>>;
  NOT?: InputMaybe<Array<ServiceCategoryWhereInput>>;
  OR?: InputMaybe<Array<ServiceCategoryWhereInput>>;
  description?: InputMaybe<StringFilter>;
  descriptionAr?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  services?: InputMaybe<ServiceListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceCount = {
  __typename?: "ServiceCount";
  paymentPlansPeriods: Scalars["Int"]["output"];
  serviceEligibilities: Scalars["Int"]["output"];
  serviceZoneSchools: Scalars["Int"]["output"];
};

export type ServiceCreateExtendedInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryCreateNestedOneWithoutServicesInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country: CountryCreateNestedOneWithoutServicesInput;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodCreateNestedManyWithoutServiceInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityCreateNestedManyWithoutServiceInput>;
  serviceProvider?: InputMaybe<ServiceProviderConnectNestedOneWithoutServicesInput>;
  serviceType?: ServiceType;
  serviceZone?: InputMaybe<ServiceZoneCreateExtendedNestedOneWithoutServiceInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceCreateManyCountryInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceCategoryId?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceCreateManyCountryInputEnvelope = {
  data: Array<ServiceCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceCreateManyServiceProviderInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryId: Scalars["String"]["input"];
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceCategoryId?: InputMaybe<Scalars["String"]["input"]>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceCreateManyServiceProviderInputEnvelope = {
  data: Array<ServiceCreateManyServiceProviderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<ServiceCreateWithoutCountryInput>>;
  createMany?: InputMaybe<ServiceCreateManyCountryInputEnvelope>;
};

export type ServiceCreateNestedManyWithoutServiceProviderInput = {
  connect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<ServiceCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<ServiceCreateManyServiceProviderInputEnvelope>;
};

export type ServiceCreateNestedOneWithoutPaymentPlansPeriodsInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutPaymentPlansPeriodsInput>;
  create?: InputMaybe<ServiceCreateWithoutPaymentPlansPeriodsInput>;
};

export type ServiceCreateNestedOneWithoutServiceEligibilitiesInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutServiceEligibilitiesInput>;
  create?: InputMaybe<ServiceCreateWithoutServiceEligibilitiesInput>;
};

export type ServiceCreateNestedOneWithoutServiceZoneInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutServiceZoneInput>;
  create?: InputMaybe<ServiceCreateWithoutServiceZoneInput>;
};

export type ServiceCreateNestedOneWithoutServiceZoneSchoolsInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutServiceZoneSchoolsInput>;
  create?: InputMaybe<ServiceCreateWithoutServiceZoneSchoolsInput>;
};

export type ServiceCreateOrConnectWithoutCountryInput = {
  create: ServiceCreateWithoutCountryInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutPaymentPlansPeriodsInput = {
  create: ServiceCreateWithoutPaymentPlansPeriodsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutServiceEligibilitiesInput = {
  create: ServiceCreateWithoutServiceEligibilitiesInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutServiceProviderInput = {
  create: ServiceCreateWithoutServiceProviderInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutServiceZoneInput = {
  create: ServiceCreateWithoutServiceZoneInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutServiceZoneSchoolsInput = {
  create: ServiceCreateWithoutServiceZoneSchoolsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateWithoutCountryInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryCreateNestedOneWithoutServicesInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodCreateNestedManyWithoutServiceInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityCreateNestedManyWithoutServiceInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutServicesInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZone?: InputMaybe<ServiceZoneCreateNestedOneWithoutServiceInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutServiceInput>;
  startDate: Scalars["DateTime"]["input"];
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceCreateWithoutPaymentPlansPeriodsInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryCreateNestedOneWithoutServicesInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country: CountryCreateNestedOneWithoutServicesInput;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityCreateNestedManyWithoutServiceInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutServicesInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZone?: InputMaybe<ServiceZoneCreateNestedOneWithoutServiceInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutServiceInput>;
  startDate: Scalars["DateTime"]["input"];
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceCreateWithoutServiceEligibilitiesInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryCreateNestedOneWithoutServicesInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country: CountryCreateNestedOneWithoutServicesInput;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodCreateNestedManyWithoutServiceInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutServicesInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZone?: InputMaybe<ServiceZoneCreateNestedOneWithoutServiceInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutServiceInput>;
  startDate: Scalars["DateTime"]["input"];
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceCreateWithoutServiceProviderInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryCreateNestedOneWithoutServicesInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country: CountryCreateNestedOneWithoutServicesInput;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodCreateNestedManyWithoutServiceInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityCreateNestedManyWithoutServiceInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZone?: InputMaybe<ServiceZoneCreateNestedOneWithoutServiceInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutServiceInput>;
  startDate: Scalars["DateTime"]["input"];
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceCreateWithoutServiceZoneInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryCreateNestedOneWithoutServicesInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country: CountryCreateNestedOneWithoutServicesInput;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodCreateNestedManyWithoutServiceInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityCreateNestedManyWithoutServiceInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutServicesInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolCreateNestedManyWithoutServiceInput>;
  startDate: Scalars["DateTime"]["input"];
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceCreateWithoutServiceZoneSchoolsInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryCreateNestedOneWithoutServicesInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country: CountryCreateNestedOneWithoutServicesInput;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodCreateNestedManyWithoutServiceInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityCreateNestedManyWithoutServiceInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutServicesInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZone?: InputMaybe<ServiceZoneCreateNestedOneWithoutServiceInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  status: Status;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceDepositResponsePayload = {
  __typename?: "ServiceDepositResponsePayload";
  /** The creation time */
  createdAt: Scalars["DateTime"]["output"];
  depositActiveFromDays: Scalars["Int"]["output"];
  depositActiveToDays: Scalars["Int"]["output"];
  depositPercentage: Scalars["Float"]["output"];
  depositRefundCutoffDays: Scalars["Int"]["output"];
  depositRefundMaxPenalty?: Maybe<Scalars["Int"]["output"]>;
  /** A UUID */
  id: Scalars["ID"]["output"];
  /** The last update time */
  updatedAt: Scalars["DateTime"]["output"];
};

export type ServiceDiscount = {
  __typename?: "ServiceDiscount";
  oneWayFactor: Scalars["Float"]["output"];
  onewayDiscount: Scalars["Float"]["output"];
  siblingDiscount: Scalars["Float"]["output"];
  siblingFactor: Scalars["Float"]["output"];
};

export type ServiceDuplicateInput = {
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
};

export type ServiceEligibility = {
  __typename?: "ServiceEligibility";
  _count: ServiceEligibilityCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isMain: Scalars["Boolean"]["output"];
  maxDistance?: Maybe<Scalars["Int"]["output"]>;
  maxTime?: Maybe<Scalars["Int"]["output"]>;
  schoolServiceEligibilities?: Maybe<Array<SchoolServiceEligibility>>;
  service: Service;
  serviceId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ServiceEligibilityCount = {
  __typename?: "ServiceEligibilityCount";
  schoolServiceEligibilities: Scalars["Int"]["output"];
};

export type ServiceEligibilityCreateManyServiceInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxDistance?: InputMaybe<Scalars["Int"]["input"]>;
  maxTime?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceEligibilityCreateManyServiceInputEnvelope = {
  data: Array<ServiceEligibilityCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceEligibilityCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<ServiceEligibilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceEligibilityCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<ServiceEligibilityCreateWithoutServiceInput>>;
  createMany?: InputMaybe<ServiceEligibilityCreateManyServiceInputEnvelope>;
};

export type ServiceEligibilityCreateNestedOneWithoutSchoolServiceEligibilitiesInput =
  {
    connect?: InputMaybe<ServiceEligibilityWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ServiceEligibilityCreateOrConnectWithoutSchoolServiceEligibilitiesInput>;
    create?: InputMaybe<ServiceEligibilityCreateWithoutSchoolServiceEligibilitiesInput>;
  };

export type ServiceEligibilityCreateOrConnectWithoutSchoolServiceEligibilitiesInput =
  {
    create: ServiceEligibilityCreateWithoutSchoolServiceEligibilitiesInput;
    where: ServiceEligibilityWhereUniqueInput;
  };

export type ServiceEligibilityCreateOrConnectWithoutServiceInput = {
  create: ServiceEligibilityCreateWithoutServiceInput;
  where: ServiceEligibilityWhereUniqueInput;
};

export type ServiceEligibilityCreateWithoutSchoolServiceEligibilitiesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxDistance?: InputMaybe<Scalars["Int"]["input"]>;
  maxTime?: InputMaybe<Scalars["Int"]["input"]>;
  service: ServiceCreateNestedOneWithoutServiceEligibilitiesInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceEligibilityCreateWithoutServiceInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxDistance?: InputMaybe<Scalars["Int"]["input"]>;
  maxTime?: InputMaybe<Scalars["Int"]["input"]>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityCreateNestedManyWithoutServiceEligibilityInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceEligibilityListRelationFilter = {
  every?: InputMaybe<ServiceEligibilityWhereInput>;
  none?: InputMaybe<ServiceEligibilityWhereInput>;
  some?: InputMaybe<ServiceEligibilityWhereInput>;
};

export type ServiceEligibilityRelationFilter = {
  is?: InputMaybe<ServiceEligibilityWhereInput>;
  isNot?: InputMaybe<ServiceEligibilityWhereInput>;
};

export type ServiceEligibilityScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceEligibilityScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceEligibilityScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceEligibilityScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isMain?: InputMaybe<BoolFilter>;
  maxDistance?: InputMaybe<IntFilter>;
  maxTime?: InputMaybe<IntFilter>;
  serviceId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceEligibilityUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxDistance?: InputMaybe<Scalars["Int"]["input"]>;
  maxTime?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceEligibilityUpdateManyWithWhereWithoutServiceInput = {
  data: ServiceEligibilityUpdateManyMutationInput;
  where: ServiceEligibilityScalarWhereInput;
};

export type ServiceEligibilityUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<ServiceEligibilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceEligibilityCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<ServiceEligibilityCreateWithoutServiceInput>>;
  createMany?: InputMaybe<ServiceEligibilityCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<ServiceEligibilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceEligibilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceEligibilityWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceEligibilityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceEligibilityUpdateWithWhereUniqueWithoutServiceInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceEligibilityUpdateManyWithWhereWithoutServiceInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceEligibilityUpsertWithWhereUniqueWithoutServiceInput>
  >;
};

export type ServiceEligibilityUpdateOneRequiredWithoutSchoolServiceEligibilitiesNestedInput =
  {
    connect?: InputMaybe<ServiceEligibilityWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ServiceEligibilityCreateOrConnectWithoutSchoolServiceEligibilitiesInput>;
    create?: InputMaybe<ServiceEligibilityCreateWithoutSchoolServiceEligibilitiesInput>;
    update?: InputMaybe<ServiceEligibilityUpdateToOneWithWhereWithoutSchoolServiceEligibilitiesInput>;
    upsert?: InputMaybe<ServiceEligibilityUpsertWithoutSchoolServiceEligibilitiesInput>;
  };

export type ServiceEligibilityUpdateToOneWithWhereWithoutSchoolServiceEligibilitiesInput =
  {
    data: ServiceEligibilityUpdateWithoutSchoolServiceEligibilitiesInput;
    where?: InputMaybe<ServiceEligibilityWhereInput>;
  };

export type ServiceEligibilityUpdateWithWhereUniqueWithoutServiceInput = {
  data: ServiceEligibilityUpdateWithoutServiceInput;
  where: ServiceEligibilityWhereUniqueInput;
};

export type ServiceEligibilityUpdateWithoutSchoolServiceEligibilitiesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxDistance?: InputMaybe<Scalars["Int"]["input"]>;
  maxTime?: InputMaybe<Scalars["Int"]["input"]>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutServiceEligibilitiesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceEligibilityUpdateWithoutServiceInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxDistance?: InputMaybe<Scalars["Int"]["input"]>;
  maxTime?: InputMaybe<Scalars["Int"]["input"]>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityUpdateManyWithoutServiceEligibilityNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceEligibilityUpsertWithWhereUniqueWithoutServiceInput = {
  create: ServiceEligibilityCreateWithoutServiceInput;
  update: ServiceEligibilityUpdateWithoutServiceInput;
  where: ServiceEligibilityWhereUniqueInput;
};

export type ServiceEligibilityUpsertWithoutSchoolServiceEligibilitiesInput = {
  create: ServiceEligibilityCreateWithoutSchoolServiceEligibilitiesInput;
  update: ServiceEligibilityUpdateWithoutSchoolServiceEligibilitiesInput;
  where?: InputMaybe<ServiceEligibilityWhereInput>;
};

export type ServiceEligibilityWhereInput = {
  AND?: InputMaybe<Array<ServiceEligibilityWhereInput>>;
  NOT?: InputMaybe<Array<ServiceEligibilityWhereInput>>;
  OR?: InputMaybe<Array<ServiceEligibilityWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isMain?: InputMaybe<BoolFilter>;
  maxDistance?: InputMaybe<IntFilter>;
  maxTime?: InputMaybe<IntFilter>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityListRelationFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceEligibilityWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceEligibilityWhereInput>>;
  NOT?: InputMaybe<Array<ServiceEligibilityWhereInput>>;
  OR?: InputMaybe<Array<ServiceEligibilityWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMain?: InputMaybe<BoolFilter>;
  maxDistance?: InputMaybe<IntFilter>;
  maxTime?: InputMaybe<IntFilter>;
  schoolServiceEligibilities?: InputMaybe<SchoolServiceEligibilityListRelationFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceForSponsor = {
  __typename?: "ServiceForSponsor";
  depositPercentage: Scalars["Float"]["output"];
  durationInMonths?: Maybe<Scalars["Int"]["output"]>;
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  installmentPlans: Array<InstallmentPlanForSponsor>;
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  promotionalInfo?: Maybe<Scalars["JSON"]["output"]>;
  startDate: Scalars["DateTime"]["output"];
  status: Status;
};

export type ServiceListRelationFilter = {
  every?: InputMaybe<ServiceWhereInput>;
  none?: InputMaybe<ServiceWhereInput>;
  some?: InputMaybe<ServiceWhereInput>;
};

export type ServicePeriod = {
  __typename?: "ServicePeriod";
  endDate: Scalars["DateTime"]["output"];
  startDate: Scalars["DateTime"]["output"];
};

export type ServicePriceAndRefundModifiers = {
  cancelDate: Scalars["DateTime"]["input"];
  homeLocation: SimpleLatLon;
  isMultipleKid?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOneWay?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripType?: InputMaybe<TripType>;
};

export type ServicePriceModifiers = {
  homeLocation: SimpleLatLon;
  isMultipleKid?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOneWay?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripType?: InputMaybe<TripType>;
};

export enum ServicePricePreview {
  PER_INSTALLMENT = "PER_INSTALLMENT",
  PER_YEAR = "PER_YEAR",
}

export type ServicePricing = {
  __typename?: "ServicePricing";
  _count: ServicePricingCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  currency?: Maybe<CurrencyCode>;
  deposit?: Maybe<Deposit>;
  depositId?: Maybe<Scalars["String"]["output"]>;
  flatRateRoundTrip?: Maybe<Scalars["Float"]["output"]>;
  flatRateToHome?: Maybe<Scalars["Float"]["output"]>;
  flatRateToSchool?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["String"]["output"];
  installmentPlan?: Maybe<Array<InstallmentPlan>>;
  isVatInclusive: Scalars["Boolean"]["output"];
  journeyBaseDistanceKM?: Maybe<Scalars["Float"]["output"]>;
  journeyBasePrice?: Maybe<Scalars["Float"]["output"]>;
  journeyIncrementalDistanceKM?: Maybe<Scalars["Float"]["output"]>;
  journeyIncrementalDistancePrice?: Maybe<Scalars["Float"]["output"]>;
  multipleKidFactor?: Maybe<Scalars["Float"]["output"]>;
  oneWayFactor?: Maybe<Scalars["Float"]["output"]>;
  pricePremium?: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  vat?: Maybe<Scalars["Float"]["output"]>;
};

export type ServicePricingCount = {
  __typename?: "ServicePricingCount";
  installmentPlan: Scalars["Int"]["output"];
};

export type ServicePricingCreateManyDepositInput = {
  currency?: InputMaybe<CurrencyCode>;
  flatRateRoundTrip?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToHome?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToSchool?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isVatInclusive?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyBaseDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyBasePrice?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistancePrice?: InputMaybe<Scalars["Float"]["input"]>;
  multipleKidFactor?: InputMaybe<Scalars["Float"]["input"]>;
  oneWayFactor?: InputMaybe<Scalars["Float"]["input"]>;
  pricePremium?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vat?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ServicePricingCreateManyDepositInputEnvelope = {
  data: Array<ServicePricingCreateManyDepositInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServicePricingCreateNestedManyWithoutDepositInput = {
  connect?: InputMaybe<Array<ServicePricingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServicePricingCreateOrConnectWithoutDepositInput>
  >;
  create?: InputMaybe<Array<ServicePricingCreateWithoutDepositInput>>;
  createMany?: InputMaybe<ServicePricingCreateManyDepositInputEnvelope>;
};

export type ServicePricingCreateNestedOneWithoutInstallmentPlanInput = {
  connect?: InputMaybe<ServicePricingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServicePricingCreateOrConnectWithoutInstallmentPlanInput>;
  create?: InputMaybe<ServicePricingCreateWithoutInstallmentPlanInput>;
};

export type ServicePricingCreateOrConnectWithoutDepositInput = {
  create: ServicePricingCreateWithoutDepositInput;
  where: ServicePricingWhereUniqueInput;
};

export type ServicePricingCreateOrConnectWithoutInstallmentPlanInput = {
  create: ServicePricingCreateWithoutInstallmentPlanInput;
  where: ServicePricingWhereUniqueInput;
};

export type ServicePricingCreateWithoutDepositInput = {
  currency?: InputMaybe<CurrencyCode>;
  flatRateRoundTrip?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToHome?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToSchool?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanCreateNestedManyWithoutServicePricingInput>;
  isVatInclusive?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyBaseDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyBasePrice?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistancePrice?: InputMaybe<Scalars["Float"]["input"]>;
  multipleKidFactor?: InputMaybe<Scalars["Float"]["input"]>;
  oneWayFactor?: InputMaybe<Scalars["Float"]["input"]>;
  pricePremium?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vat?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ServicePricingCreateWithoutInstallmentPlanInput = {
  currency?: InputMaybe<CurrencyCode>;
  deposit?: InputMaybe<DepositCreateNestedOneWithoutServicePricingInput>;
  flatRateRoundTrip?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToHome?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToSchool?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isVatInclusive?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyBaseDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyBasePrice?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistancePrice?: InputMaybe<Scalars["Float"]["input"]>;
  multipleKidFactor?: InputMaybe<Scalars["Float"]["input"]>;
  oneWayFactor?: InputMaybe<Scalars["Float"]["input"]>;
  pricePremium?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vat?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ServicePricingListRelationFilter = {
  every?: InputMaybe<ServicePricingWhereInput>;
  none?: InputMaybe<ServicePricingWhereInput>;
  some?: InputMaybe<ServicePricingWhereInput>;
};

export type ServicePricingRelationFilter = {
  is?: InputMaybe<ServicePricingWhereInput>;
  isNot?: InputMaybe<ServicePricingWhereInput>;
};

export type ServicePricingScalarWhereInput = {
  AND?: InputMaybe<Array<ServicePricingScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServicePricingScalarWhereInput>>;
  OR?: InputMaybe<Array<ServicePricingScalarWhereInput>>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  depositId?: InputMaybe<StringFilter>;
  flatRateRoundTrip?: InputMaybe<FloatFilter>;
  flatRateToHome?: InputMaybe<FloatFilter>;
  flatRateToSchool?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  isVatInclusive?: InputMaybe<BoolFilter>;
  journeyBaseDistanceKM?: InputMaybe<FloatFilter>;
  journeyBasePrice?: InputMaybe<FloatFilter>;
  journeyIncrementalDistanceKM?: InputMaybe<FloatFilter>;
  journeyIncrementalDistancePrice?: InputMaybe<FloatFilter>;
  multipleKidFactor?: InputMaybe<FloatFilter>;
  oneWayFactor?: InputMaybe<FloatFilter>;
  pricePremium?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vat?: InputMaybe<FloatFilter>;
};

export type ServicePricingUpdateManyMutationInput = {
  currency?: InputMaybe<CurrencyCode>;
  flatRateRoundTrip?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToHome?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToSchool?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isVatInclusive?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyBaseDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyBasePrice?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistancePrice?: InputMaybe<Scalars["Float"]["input"]>;
  multipleKidFactor?: InputMaybe<Scalars["Float"]["input"]>;
  oneWayFactor?: InputMaybe<Scalars["Float"]["input"]>;
  pricePremium?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vat?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ServicePricingUpdateManyWithWhereWithoutDepositInput = {
  data: ServicePricingUpdateManyMutationInput;
  where: ServicePricingScalarWhereInput;
};

export type ServicePricingUpdateManyWithoutDepositNestedInput = {
  connect?: InputMaybe<Array<ServicePricingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServicePricingCreateOrConnectWithoutDepositInput>
  >;
  create?: InputMaybe<Array<ServicePricingCreateWithoutDepositInput>>;
  createMany?: InputMaybe<ServicePricingCreateManyDepositInputEnvelope>;
  delete?: InputMaybe<Array<ServicePricingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServicePricingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServicePricingWhereUniqueInput>>;
  set?: InputMaybe<Array<ServicePricingWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServicePricingUpdateWithWhereUniqueWithoutDepositInput>
  >;
  updateMany?: InputMaybe<
    Array<ServicePricingUpdateManyWithWhereWithoutDepositInput>
  >;
  upsert?: InputMaybe<
    Array<ServicePricingUpsertWithWhereUniqueWithoutDepositInput>
  >;
};

export type ServicePricingUpdateOneWithoutInstallmentPlanNestedInput = {
  connect?: InputMaybe<ServicePricingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServicePricingCreateOrConnectWithoutInstallmentPlanInput>;
  create?: InputMaybe<ServicePricingCreateWithoutInstallmentPlanInput>;
  delete?: InputMaybe<ServicePricingWhereInput>;
  disconnect?: InputMaybe<ServicePricingWhereInput>;
  update?: InputMaybe<ServicePricingUpdateToOneWithWhereWithoutInstallmentPlanInput>;
  upsert?: InputMaybe<ServicePricingUpsertWithoutInstallmentPlanInput>;
};

export type ServicePricingUpdateToOneWithWhereWithoutInstallmentPlanInput = {
  data: ServicePricingUpdateWithoutInstallmentPlanInput;
  where?: InputMaybe<ServicePricingWhereInput>;
};

export type ServicePricingUpdateWithWhereUniqueWithoutDepositInput = {
  data: ServicePricingUpdateWithoutDepositInput;
  where: ServicePricingWhereUniqueInput;
};

export type ServicePricingUpdateWithoutDepositInput = {
  currency?: InputMaybe<CurrencyCode>;
  flatRateRoundTrip?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToHome?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToSchool?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateManyWithoutServicePricingNestedInput>;
  isVatInclusive?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyBaseDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyBasePrice?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistancePrice?: InputMaybe<Scalars["Float"]["input"]>;
  multipleKidFactor?: InputMaybe<Scalars["Float"]["input"]>;
  oneWayFactor?: InputMaybe<Scalars["Float"]["input"]>;
  pricePremium?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vat?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ServicePricingUpdateWithoutInstallmentPlanInput = {
  currency?: InputMaybe<CurrencyCode>;
  deposit?: InputMaybe<DepositUpdateOneWithoutServicePricingNestedInput>;
  flatRateRoundTrip?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToHome?: InputMaybe<Scalars["Float"]["input"]>;
  flatRateToSchool?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isVatInclusive?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyBaseDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyBasePrice?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistanceKM?: InputMaybe<Scalars["Float"]["input"]>;
  journeyIncrementalDistancePrice?: InputMaybe<Scalars["Float"]["input"]>;
  multipleKidFactor?: InputMaybe<Scalars["Float"]["input"]>;
  oneWayFactor?: InputMaybe<Scalars["Float"]["input"]>;
  pricePremium?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vat?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ServicePricingUpsertWithWhereUniqueWithoutDepositInput = {
  create: ServicePricingCreateWithoutDepositInput;
  update: ServicePricingUpdateWithoutDepositInput;
  where: ServicePricingWhereUniqueInput;
};

export type ServicePricingUpsertWithoutInstallmentPlanInput = {
  create: ServicePricingCreateWithoutInstallmentPlanInput;
  update: ServicePricingUpdateWithoutInstallmentPlanInput;
  where?: InputMaybe<ServicePricingWhereInput>;
};

export type ServicePricingWhereInput = {
  AND?: InputMaybe<Array<ServicePricingWhereInput>>;
  NOT?: InputMaybe<Array<ServicePricingWhereInput>>;
  OR?: InputMaybe<Array<ServicePricingWhereInput>>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  deposit?: InputMaybe<DepositRelationFilter>;
  depositId?: InputMaybe<StringFilter>;
  flatRateRoundTrip?: InputMaybe<FloatFilter>;
  flatRateToHome?: InputMaybe<FloatFilter>;
  flatRateToSchool?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  installmentPlan?: InputMaybe<InstallmentPlanListRelationFilter>;
  isVatInclusive?: InputMaybe<BoolFilter>;
  journeyBaseDistanceKM?: InputMaybe<FloatFilter>;
  journeyBasePrice?: InputMaybe<FloatFilter>;
  journeyIncrementalDistanceKM?: InputMaybe<FloatFilter>;
  journeyIncrementalDistancePrice?: InputMaybe<FloatFilter>;
  multipleKidFactor?: InputMaybe<FloatFilter>;
  oneWayFactor?: InputMaybe<FloatFilter>;
  pricePremium?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vat?: InputMaybe<FloatFilter>;
};

export type ServicePricingWhereUniqueInput = {
  AND?: InputMaybe<Array<ServicePricingWhereInput>>;
  NOT?: InputMaybe<Array<ServicePricingWhereInput>>;
  OR?: InputMaybe<Array<ServicePricingWhereInput>>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  deposit?: InputMaybe<DepositRelationFilter>;
  depositId?: InputMaybe<StringFilter>;
  flatRateRoundTrip?: InputMaybe<FloatFilter>;
  flatRateToHome?: InputMaybe<FloatFilter>;
  flatRateToSchool?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanListRelationFilter>;
  isVatInclusive?: InputMaybe<BoolFilter>;
  journeyBaseDistanceKM?: InputMaybe<FloatFilter>;
  journeyBasePrice?: InputMaybe<FloatFilter>;
  journeyIncrementalDistanceKM?: InputMaybe<FloatFilter>;
  journeyIncrementalDistancePrice?: InputMaybe<FloatFilter>;
  multipleKidFactor?: InputMaybe<FloatFilter>;
  oneWayFactor?: InputMaybe<FloatFilter>;
  pricePremium?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vat?: InputMaybe<FloatFilter>;
};

export type ServiceProvider = {
  __typename?: "ServiceProvider";
  _count: ServiceProviderCount;
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  banksDetails?: Maybe<Array<BankDetails>>;
  branches?: Maybe<Array<Branch>>;
  collectionPoints?: Maybe<Array<CollectionPoint>>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr?: Maybe<Scalars["String"]["output"]>;
  ownerUser: User;
  ownerUserId: Scalars["String"]["output"];
  routes?: Maybe<Array<Route>>;
  serviceProviderGroup?: Maybe<ServiceProviderGroup>;
  serviceProviderGroupId?: Maybe<Scalars["String"]["output"]>;
  serviceProviderSchools?: Maybe<Array<ServiceProviderSchool>>;
  serviceProviderSubscriptionPlans?: Maybe<
    Array<ServiceProviderSubscriptionPlan>
  >;
  services?: Maybe<Array<Service>>;
  status: ServiceProviderStatus;
  type: ServiceProviderType;
  updatedAt: Scalars["DateTime"]["output"];
  users?: Maybe<Array<ServiceProviderUser>>;
  usesCollectionPoints: Scalars["Boolean"]["output"];
  vehicleGroups?: Maybe<Array<VehicleGroup>>;
  vehicles?: Maybe<Array<Vehicle>>;
};

export type ServiceProviderConnectNestedOneWithoutServicesInput = {
  connect: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCount = {
  __typename?: "ServiceProviderCount";
  banksDetails: Scalars["Int"]["output"];
  branches: Scalars["Int"]["output"];
  collectionPoints: Scalars["Int"]["output"];
  routes: Scalars["Int"]["output"];
  serviceProviderSchools: Scalars["Int"]["output"];
  serviceProviderSubscriptionPlans: Scalars["Int"]["output"];
  services: Scalars["Int"]["output"];
  users: Scalars["Int"]["output"];
  vehicleGroups: Scalars["Int"]["output"];
  vehicles: Scalars["Int"]["output"];
};

export type ServiceProviderCreateManyServiceProviderGroupInput = {
  addressId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUserId: Scalars["String"]["input"];
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceProviderCreateManyServiceProviderGroupInputEnvelope = {
  data: Array<ServiceProviderCreateManyServiceProviderGroupInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceProviderCreateNestedManyWithoutServiceProviderGroupInput = {
  connect?: InputMaybe<Array<ServiceProviderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceProviderCreateOrConnectWithoutServiceProviderGroupInput>
  >;
  create?: InputMaybe<
    Array<ServiceProviderCreateWithoutServiceProviderGroupInput>
  >;
  createMany?: InputMaybe<ServiceProviderCreateManyServiceProviderGroupInputEnvelope>;
};

export type ServiceProviderCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutAddressInput>;
};

export type ServiceProviderCreateNestedOneWithoutBanksDetailsInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutBanksDetailsInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutBanksDetailsInput>;
};

export type ServiceProviderCreateNestedOneWithoutBranchesInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutBranchesInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutBranchesInput>;
};

export type ServiceProviderCreateNestedOneWithoutCollectionPointsInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutCollectionPointsInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutCollectionPointsInput>;
};

export type ServiceProviderCreateNestedOneWithoutOwnerUserInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutOwnerUserInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutOwnerUserInput>;
};

export type ServiceProviderCreateNestedOneWithoutRoutesInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutRoutesInput>;
};

export type ServiceProviderCreateNestedOneWithoutServiceProviderSchoolsInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutServiceProviderSchoolsInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutServiceProviderSchoolsInput>;
};

export type ServiceProviderCreateNestedOneWithoutServicesInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutServicesInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutServicesInput>;
};

export type ServiceProviderCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutUsersInput>;
};

export type ServiceProviderCreateNestedOneWithoutVehicleGroupsInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutVehicleGroupsInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutVehicleGroupsInput>;
};

export type ServiceProviderCreateNestedOneWithoutVehiclesInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutVehiclesInput>;
};

export type ServiceProviderCreateOrConnectWithoutAddressInput = {
  create: ServiceProviderCreateWithoutAddressInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutBanksDetailsInput = {
  create: ServiceProviderCreateWithoutBanksDetailsInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutBranchesInput = {
  create: ServiceProviderCreateWithoutBranchesInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutCollectionPointsInput = {
  create: ServiceProviderCreateWithoutCollectionPointsInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutOwnerUserInput = {
  create: ServiceProviderCreateWithoutOwnerUserInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutRoutesInput = {
  create: ServiceProviderCreateWithoutRoutesInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutServiceProviderGroupInput = {
  create: ServiceProviderCreateWithoutServiceProviderGroupInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutServiceProviderSchoolsInput = {
  create: ServiceProviderCreateWithoutServiceProviderSchoolsInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutServicesInput = {
  create: ServiceProviderCreateWithoutServicesInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutUsersInput = {
  create: ServiceProviderCreateWithoutUsersInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutVehicleGroupsInput = {
  create: ServiceProviderCreateWithoutVehicleGroupsInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutVehiclesInput = {
  create: ServiceProviderCreateWithoutVehiclesInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateWithoutAddressInput = {
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutServiceProviderInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutServiceProviderInput>;
  collectionPoints?: InputMaybe<CollectionPointCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: UserCreateNestedOneWithoutOwnedServiceProviderInput;
  routes?: InputMaybe<RouteCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutServiceProviderInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupCreateNestedManyWithoutServiceProviderInput>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateWithoutBanksDetailsInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutServiceProviderInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutServiceProviderInput>;
  collectionPoints?: InputMaybe<CollectionPointCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: UserCreateNestedOneWithoutOwnedServiceProviderInput;
  routes?: InputMaybe<RouteCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutServiceProviderInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupCreateNestedManyWithoutServiceProviderInput>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateWithoutBranchesInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutServiceProviderInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutServiceProviderInput>;
  collectionPoints?: InputMaybe<CollectionPointCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: UserCreateNestedOneWithoutOwnedServiceProviderInput;
  routes?: InputMaybe<RouteCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutServiceProviderInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupCreateNestedManyWithoutServiceProviderInput>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateWithoutCollectionPointsInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutServiceProviderInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutServiceProviderInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: UserCreateNestedOneWithoutOwnedServiceProviderInput;
  routes?: InputMaybe<RouteCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutServiceProviderInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupCreateNestedManyWithoutServiceProviderInput>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateWithoutOwnerUserInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutServiceProviderInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutServiceProviderInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutServiceProviderInput>;
  collectionPoints?: InputMaybe<CollectionPointCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routes?: InputMaybe<RouteCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutServiceProviderInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupCreateNestedManyWithoutServiceProviderInput>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateWithoutRoutesInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutServiceProviderInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutServiceProviderInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutServiceProviderInput>;
  collectionPoints?: InputMaybe<CollectionPointCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: UserCreateNestedOneWithoutOwnedServiceProviderInput;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutServiceProviderInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupCreateNestedManyWithoutServiceProviderInput>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateWithoutServiceProviderGroupInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutServiceProviderInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutServiceProviderInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutServiceProviderInput>;
  collectionPoints?: InputMaybe<CollectionPointCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: UserCreateNestedOneWithoutOwnedServiceProviderInput;
  routes?: InputMaybe<RouteCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutServiceProviderInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupCreateNestedManyWithoutServiceProviderInput>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateWithoutServiceProviderSchoolsInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutServiceProviderInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutServiceProviderInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutServiceProviderInput>;
  collectionPoints?: InputMaybe<CollectionPointCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: UserCreateNestedOneWithoutOwnedServiceProviderInput;
  routes?: InputMaybe<RouteCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutServiceProviderInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupCreateNestedManyWithoutServiceProviderInput>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateWithoutServicesInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutServiceProviderInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutServiceProviderInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutServiceProviderInput>;
  collectionPoints?: InputMaybe<CollectionPointCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: UserCreateNestedOneWithoutOwnedServiceProviderInput;
  routes?: InputMaybe<RouteCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutServiceProviderInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupCreateNestedManyWithoutServiceProviderInput>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateWithoutUsersInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutServiceProviderInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutServiceProviderInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutServiceProviderInput>;
  collectionPoints?: InputMaybe<CollectionPointCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: UserCreateNestedOneWithoutOwnedServiceProviderInput;
  routes?: InputMaybe<RouteCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupCreateNestedManyWithoutServiceProviderInput>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateWithoutVehicleGroupsInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutServiceProviderInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutServiceProviderInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutServiceProviderInput>;
  collectionPoints?: InputMaybe<CollectionPointCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: UserCreateNestedOneWithoutOwnedServiceProviderInput;
  routes?: InputMaybe<RouteCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutServiceProviderInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderCreateWithoutVehiclesInput = {
  address?: InputMaybe<AddressCreateNestedOneWithoutServiceProviderInput>;
  banksDetails?: InputMaybe<BankDetailsCreateNestedManyWithoutServiceProviderInput>;
  branches?: InputMaybe<BranchCreateNestedManyWithoutServiceProviderInput>;
  collectionPoints?: InputMaybe<CollectionPointCreateNestedManyWithoutServiceProviderInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser: UserCreateNestedOneWithoutOwnedServiceProviderInput;
  routes?: InputMaybe<RouteCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutServiceProviderInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutServiceProviderInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupCreateNestedManyWithoutServiceProviderInput>;
};

export type ServiceProviderGroup = {
  __typename?: "ServiceProviderGroup";
  _count: ServiceProviderGroupCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  roles?: Maybe<Array<Role>>;
  serviceProviders?: Maybe<Array<ServiceProvider>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ServiceProviderGroupCount = {
  __typename?: "ServiceProviderGroupCount";
  roles: Scalars["Int"]["output"];
  serviceProviders: Scalars["Int"]["output"];
};

export type ServiceProviderGroupCreateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutServiceProviderGroupInput>;
  serviceProviders?: InputMaybe<ServiceProviderCreateNestedManyWithoutServiceProviderGroupInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderGroupCreateNestedOneWithoutRolesInput = {
  connect?: InputMaybe<ServiceProviderGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderGroupCreateOrConnectWithoutRolesInput>;
  create?: InputMaybe<ServiceProviderGroupCreateWithoutRolesInput>;
};

export type ServiceProviderGroupCreateNestedOneWithoutServiceProvidersInput = {
  connect?: InputMaybe<ServiceProviderGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderGroupCreateOrConnectWithoutServiceProvidersInput>;
  create?: InputMaybe<ServiceProviderGroupCreateWithoutServiceProvidersInput>;
};

export type ServiceProviderGroupCreateOrConnectWithoutRolesInput = {
  create: ServiceProviderGroupCreateWithoutRolesInput;
  where: ServiceProviderGroupWhereUniqueInput;
};

export type ServiceProviderGroupCreateOrConnectWithoutServiceProvidersInput = {
  create: ServiceProviderGroupCreateWithoutServiceProvidersInput;
  where: ServiceProviderGroupWhereUniqueInput;
};

export type ServiceProviderGroupCreateWithoutRolesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviders?: InputMaybe<ServiceProviderCreateNestedManyWithoutServiceProviderGroupInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderGroupCreateWithoutServiceProvidersInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutServiceProviderGroupInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderGroupRelationFilter = {
  is?: InputMaybe<ServiceProviderGroupWhereInput>;
  isNot?: InputMaybe<ServiceProviderGroupWhereInput>;
};

export type ServiceProviderGroupUpdateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<RoleUpdateManyWithoutServiceProviderGroupNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUpdateManyWithoutServiceProviderGroupNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderGroupUpdateOneWithoutRolesNestedInput = {
  connect?: InputMaybe<ServiceProviderGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderGroupCreateOrConnectWithoutRolesInput>;
  create?: InputMaybe<ServiceProviderGroupCreateWithoutRolesInput>;
  delete?: InputMaybe<ServiceProviderGroupWhereInput>;
  disconnect?: InputMaybe<ServiceProviderGroupWhereInput>;
  update?: InputMaybe<ServiceProviderGroupUpdateToOneWithWhereWithoutRolesInput>;
  upsert?: InputMaybe<ServiceProviderGroupUpsertWithoutRolesInput>;
};

export type ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput = {
  connect?: InputMaybe<ServiceProviderGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderGroupCreateOrConnectWithoutServiceProvidersInput>;
  create?: InputMaybe<ServiceProviderGroupCreateWithoutServiceProvidersInput>;
  delete?: InputMaybe<ServiceProviderGroupWhereInput>;
  disconnect?: InputMaybe<ServiceProviderGroupWhereInput>;
  update?: InputMaybe<ServiceProviderGroupUpdateToOneWithWhereWithoutServiceProvidersInput>;
  upsert?: InputMaybe<ServiceProviderGroupUpsertWithoutServiceProvidersInput>;
};

export type ServiceProviderGroupUpdateToOneWithWhereWithoutRolesInput = {
  data: ServiceProviderGroupUpdateWithoutRolesInput;
  where?: InputMaybe<ServiceProviderGroupWhereInput>;
};

export type ServiceProviderGroupUpdateToOneWithWhereWithoutServiceProvidersInput =
  {
    data: ServiceProviderGroupUpdateWithoutServiceProvidersInput;
    where?: InputMaybe<ServiceProviderGroupWhereInput>;
  };

export type ServiceProviderGroupUpdateWithoutRolesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviders?: InputMaybe<ServiceProviderUpdateManyWithoutServiceProviderGroupNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderGroupUpdateWithoutServiceProvidersInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<RoleUpdateManyWithoutServiceProviderGroupNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderGroupUpsertInput = {
  create?: InputMaybe<ServiceProviderGroupCreateInput>;
  update?: InputMaybe<ServiceProviderGroupUpdateInput>;
  where?: InputMaybe<ServiceProviderGroupWhereUniqueInput>;
};

export type ServiceProviderGroupUpsertWithoutRolesInput = {
  create: ServiceProviderGroupCreateWithoutRolesInput;
  update: ServiceProviderGroupUpdateWithoutRolesInput;
  where?: InputMaybe<ServiceProviderGroupWhereInput>;
};

export type ServiceProviderGroupUpsertWithoutServiceProvidersInput = {
  create: ServiceProviderGroupCreateWithoutServiceProvidersInput;
  update: ServiceProviderGroupUpdateWithoutServiceProvidersInput;
  where?: InputMaybe<ServiceProviderGroupWhereInput>;
};

export type ServiceProviderGroupWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderGroupWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderGroupWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderGroupWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  roles?: InputMaybe<RoleListRelationFilter>;
  serviceProviders?: InputMaybe<ServiceProviderListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceProviderGroupWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceProviderGroupWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderGroupWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderGroupWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  roles?: InputMaybe<RoleListRelationFilter>;
  serviceProviders?: InputMaybe<ServiceProviderListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceProviderListRelationFilter = {
  every?: InputMaybe<ServiceProviderWhereInput>;
  none?: InputMaybe<ServiceProviderWhereInput>;
  some?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderOwnerUser = {
  addresses: CreateUserAddress;
  countryCode?: InputMaybe<CountryCode>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  phone: Scalars["String"]["input"];
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
};

export enum ServiceProviderPlanStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type ServiceProviderRelationFilter = {
  is?: InputMaybe<ServiceProviderWhereInput>;
  isNot?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderScalarWhereInput>>;
  addressId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  ownerUserId?: InputMaybe<StringFilter>;
  serviceProviderGroupId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumServiceProviderStatusFilter>;
  type?: InputMaybe<EnumServiceProviderTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  usesCollectionPoints?: InputMaybe<BoolFilter>;
};

export type ServiceProviderSchool = {
  __typename?: "ServiceProviderSchool";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  school: School;
  schoolId: Scalars["String"]["output"];
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ServiceProviderSchoolCreateManySchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderSchoolCreateManySchoolInputEnvelope = {
  data: Array<ServiceProviderSchoolCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceProviderSchoolCreateManyServiceProviderInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderSchoolCreateManyServiceProviderInputEnvelope = {
  data: Array<ServiceProviderSchoolCreateManyServiceProviderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceProviderSchoolCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<ServiceProviderSchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceProviderSchoolCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<ServiceProviderSchoolCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<ServiceProviderSchoolCreateManySchoolInputEnvelope>;
};

export type ServiceProviderSchoolCreateNestedManyWithoutServiceProviderInput = {
  connect?: InputMaybe<Array<ServiceProviderSchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceProviderSchoolCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<
    Array<ServiceProviderSchoolCreateWithoutServiceProviderInput>
  >;
  createMany?: InputMaybe<ServiceProviderSchoolCreateManyServiceProviderInputEnvelope>;
};

export type ServiceProviderSchoolCreateOrConnectWithoutSchoolInput = {
  create: ServiceProviderSchoolCreateWithoutSchoolInput;
  where: ServiceProviderSchoolWhereUniqueInput;
};

export type ServiceProviderSchoolCreateOrConnectWithoutServiceProviderInput = {
  create: ServiceProviderSchoolCreateWithoutServiceProviderInput;
  where: ServiceProviderSchoolWhereUniqueInput;
};

export type ServiceProviderSchoolCreateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider: ServiceProviderCreateNestedOneWithoutServiceProviderSchoolsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderSchoolCreateWithoutServiceProviderInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school: SchoolCreateNestedOneWithoutServiceProviderSchoolsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderSchoolListRelationFilter = {
  every?: InputMaybe<ServiceProviderSchoolWhereInput>;
  none?: InputMaybe<ServiceProviderSchoolWhereInput>;
  some?: InputMaybe<ServiceProviderSchoolWhereInput>;
};

export type ServiceProviderSchoolScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderSchoolScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderSchoolScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderSchoolScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  schoolId?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceProviderSchoolUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderSchoolUpdateManyWithWhereWithoutSchoolInput = {
  data: ServiceProviderSchoolUpdateManyMutationInput;
  where: ServiceProviderSchoolScalarWhereInput;
};

export type ServiceProviderSchoolUpdateManyWithWhereWithoutServiceProviderInput =
  {
    data: ServiceProviderSchoolUpdateManyMutationInput;
    where: ServiceProviderSchoolScalarWhereInput;
  };

export type ServiceProviderSchoolUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<ServiceProviderSchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceProviderSchoolCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<ServiceProviderSchoolCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<ServiceProviderSchoolCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<ServiceProviderSchoolWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceProviderSchoolScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceProviderSchoolWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceProviderSchoolWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceProviderSchoolUpdateWithWhereUniqueWithoutSchoolInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceProviderSchoolUpdateManyWithWhereWithoutSchoolInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceProviderSchoolUpsertWithWhereUniqueWithoutSchoolInput>
  >;
};

export type ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput = {
  connect?: InputMaybe<Array<ServiceProviderSchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceProviderSchoolCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<
    Array<ServiceProviderSchoolCreateWithoutServiceProviderInput>
  >;
  createMany?: InputMaybe<ServiceProviderSchoolCreateManyServiceProviderInputEnvelope>;
  delete?: InputMaybe<Array<ServiceProviderSchoolWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceProviderSchoolScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceProviderSchoolWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceProviderSchoolWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceProviderSchoolUpdateWithWhereUniqueWithoutServiceProviderInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceProviderSchoolUpdateManyWithWhereWithoutServiceProviderInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceProviderSchoolUpsertWithWhereUniqueWithoutServiceProviderInput>
  >;
};

export type ServiceProviderSchoolUpdateWithWhereUniqueWithoutSchoolInput = {
  data: ServiceProviderSchoolUpdateWithoutSchoolInput;
  where: ServiceProviderSchoolWhereUniqueInput;
};

export type ServiceProviderSchoolUpdateWithWhereUniqueWithoutServiceProviderInput =
  {
    data: ServiceProviderSchoolUpdateWithoutServiceProviderInput;
    where: ServiceProviderSchoolWhereUniqueInput;
  };

export type ServiceProviderSchoolUpdateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutServiceProviderSchoolsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderSchoolUpdateWithoutServiceProviderInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutServiceProviderSchoolsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderSchoolUpsertWithWhereUniqueWithoutSchoolInput = {
  create: ServiceProviderSchoolCreateWithoutSchoolInput;
  update: ServiceProviderSchoolUpdateWithoutSchoolInput;
  where: ServiceProviderSchoolWhereUniqueInput;
};

export type ServiceProviderSchoolUpsertWithWhereUniqueWithoutServiceProviderInput =
  {
    create: ServiceProviderSchoolCreateWithoutServiceProviderInput;
    update: ServiceProviderSchoolUpdateWithoutServiceProviderInput;
    where: ServiceProviderSchoolWhereUniqueInput;
  };

export type ServiceProviderSchoolWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderSchoolWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderSchoolWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderSchoolWhereInput>>;
  id?: InputMaybe<StringFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceProviderSchoolWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceProviderSchoolWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderSchoolWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderSchoolWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ServiceProviderStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type ServiceProviderSubscriptionPlan = {
  __typename?: "ServiceProviderSubscriptionPlan";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderId?: Maybe<Scalars["String"]["output"]>;
  startDate: Scalars["DateTime"]["output"];
  status: ServiceProviderPlanStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ServiceProviderSubscriptionPlanCreateManyServiceProviderInput = {
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<ServiceProviderPlanStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderSubscriptionPlanCreateManyServiceProviderInputEnvelope =
  {
    data: Array<ServiceProviderSubscriptionPlanCreateManyServiceProviderInput>;
    skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type ServiceProviderSubscriptionPlanCreateNestedManyWithoutServiceProviderInput =
  {
    connect?: InputMaybe<
      Array<ServiceProviderSubscriptionPlanWhereUniqueInput>
    >;
    connectOrCreate?: InputMaybe<
      Array<ServiceProviderSubscriptionPlanCreateOrConnectWithoutServiceProviderInput>
    >;
    create?: InputMaybe<
      Array<ServiceProviderSubscriptionPlanCreateWithoutServiceProviderInput>
    >;
    createMany?: InputMaybe<ServiceProviderSubscriptionPlanCreateManyServiceProviderInputEnvelope>;
  };

export type ServiceProviderSubscriptionPlanCreateOrConnectWithoutServiceProviderInput =
  {
    create: ServiceProviderSubscriptionPlanCreateWithoutServiceProviderInput;
    where: ServiceProviderSubscriptionPlanWhereUniqueInput;
  };

export type ServiceProviderSubscriptionPlanCreateWithoutServiceProviderInput = {
  endDate: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  status?: InputMaybe<ServiceProviderPlanStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderSubscriptionPlanListRelationFilter = {
  every?: InputMaybe<ServiceProviderSubscriptionPlanWhereInput>;
  none?: InputMaybe<ServiceProviderSubscriptionPlanWhereInput>;
  some?: InputMaybe<ServiceProviderSubscriptionPlanWhereInput>;
};

export type ServiceProviderSubscriptionPlanScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderSubscriptionPlanScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderSubscriptionPlanScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderSubscriptionPlanScalarWhereInput>>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumServiceProviderPlanStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceProviderSubscriptionPlanUpdateManyMutationInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<ServiceProviderPlanStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderSubscriptionPlanUpdateManyWithWhereWithoutServiceProviderInput =
  {
    data: ServiceProviderSubscriptionPlanUpdateManyMutationInput;
    where: ServiceProviderSubscriptionPlanScalarWhereInput;
  };

export type ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput =
  {
    connect?: InputMaybe<
      Array<ServiceProviderSubscriptionPlanWhereUniqueInput>
    >;
    connectOrCreate?: InputMaybe<
      Array<ServiceProviderSubscriptionPlanCreateOrConnectWithoutServiceProviderInput>
    >;
    create?: InputMaybe<
      Array<ServiceProviderSubscriptionPlanCreateWithoutServiceProviderInput>
    >;
    createMany?: InputMaybe<ServiceProviderSubscriptionPlanCreateManyServiceProviderInputEnvelope>;
    delete?: InputMaybe<Array<ServiceProviderSubscriptionPlanWhereUniqueInput>>;
    deleteMany?: InputMaybe<
      Array<ServiceProviderSubscriptionPlanScalarWhereInput>
    >;
    disconnect?: InputMaybe<
      Array<ServiceProviderSubscriptionPlanWhereUniqueInput>
    >;
    set?: InputMaybe<Array<ServiceProviderSubscriptionPlanWhereUniqueInput>>;
    update?: InputMaybe<
      Array<ServiceProviderSubscriptionPlanUpdateWithWhereUniqueWithoutServiceProviderInput>
    >;
    updateMany?: InputMaybe<
      Array<ServiceProviderSubscriptionPlanUpdateManyWithWhereWithoutServiceProviderInput>
    >;
    upsert?: InputMaybe<
      Array<ServiceProviderSubscriptionPlanUpsertWithWhereUniqueWithoutServiceProviderInput>
    >;
  };

export type ServiceProviderSubscriptionPlanUpdateWithWhereUniqueWithoutServiceProviderInput =
  {
    data: ServiceProviderSubscriptionPlanUpdateWithoutServiceProviderInput;
    where: ServiceProviderSubscriptionPlanWhereUniqueInput;
  };

export type ServiceProviderSubscriptionPlanUpdateWithoutServiceProviderInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<ServiceProviderPlanStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderSubscriptionPlanUpsertWithWhereUniqueWithoutServiceProviderInput =
  {
    create: ServiceProviderSubscriptionPlanCreateWithoutServiceProviderInput;
    update: ServiceProviderSubscriptionPlanUpdateWithoutServiceProviderInput;
    where: ServiceProviderSubscriptionPlanWhereUniqueInput;
  };

export type ServiceProviderSubscriptionPlanWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderSubscriptionPlanWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderSubscriptionPlanWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderSubscriptionPlanWhereInput>>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumServiceProviderPlanStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceProviderSubscriptionPlanWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceProviderSubscriptionPlanWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderSubscriptionPlanWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderSubscriptionPlanWhereInput>>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumServiceProviderPlanStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ServiceProviderType {
  SCHOOL = "SCHOOL",
  TRANSPORT_OPERATOR = "TRANSPORT_OPERATOR",
}

export type ServiceProviderUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceProviderUpdateManyWithWhereWithoutServiceProviderGroupInput =
  {
    data: ServiceProviderUpdateManyMutationInput;
    where: ServiceProviderScalarWhereInput;
  };

export type ServiceProviderUpdateManyWithoutServiceProviderGroupNestedInput = {
  connect?: InputMaybe<Array<ServiceProviderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceProviderCreateOrConnectWithoutServiceProviderGroupInput>
  >;
  create?: InputMaybe<
    Array<ServiceProviderCreateWithoutServiceProviderGroupInput>
  >;
  createMany?: InputMaybe<ServiceProviderCreateManyServiceProviderGroupInputEnvelope>;
  delete?: InputMaybe<Array<ServiceProviderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceProviderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceProviderWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceProviderWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceProviderUpdateWithWhereUniqueWithoutServiceProviderGroupInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceProviderUpdateManyWithWhereWithoutServiceProviderGroupInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceProviderUpsertWithWhereUniqueWithoutServiceProviderGroupInput>
  >;
};

export type ServiceProviderUpdateOneRequiredWithoutCollectionPointsNestedInput =
  {
    connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutCollectionPointsInput>;
    create?: InputMaybe<ServiceProviderCreateWithoutCollectionPointsInput>;
    update?: InputMaybe<ServiceProviderUpdateToOneWithWhereWithoutCollectionPointsInput>;
    upsert?: InputMaybe<ServiceProviderUpsertWithoutCollectionPointsInput>;
  };

export type ServiceProviderUpdateOneRequiredWithoutRoutesNestedInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutRoutesInput>;
  update?: InputMaybe<ServiceProviderUpdateToOneWithWhereWithoutRoutesInput>;
  upsert?: InputMaybe<ServiceProviderUpsertWithoutRoutesInput>;
};

export type ServiceProviderUpdateOneRequiredWithoutServiceProviderSchoolsNestedInput =
  {
    connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
    connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutServiceProviderSchoolsInput>;
    create?: InputMaybe<ServiceProviderCreateWithoutServiceProviderSchoolsInput>;
    update?: InputMaybe<ServiceProviderUpdateToOneWithWhereWithoutServiceProviderSchoolsInput>;
    upsert?: InputMaybe<ServiceProviderUpsertWithoutServiceProviderSchoolsInput>;
  };

export type ServiceProviderUpdateOneRequiredWithoutUsersNestedInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutUsersInput>;
  update?: InputMaybe<ServiceProviderUpdateToOneWithWhereWithoutUsersInput>;
  upsert?: InputMaybe<ServiceProviderUpsertWithoutUsersInput>;
};

export type ServiceProviderUpdateOneRequiredWithoutVehicleGroupsNestedInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutVehicleGroupsInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutVehicleGroupsInput>;
  update?: InputMaybe<ServiceProviderUpdateToOneWithWhereWithoutVehicleGroupsInput>;
  upsert?: InputMaybe<ServiceProviderUpsertWithoutVehicleGroupsInput>;
};

export type ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutVehiclesInput>;
  update?: InputMaybe<ServiceProviderUpdateToOneWithWhereWithoutVehiclesInput>;
  upsert?: InputMaybe<ServiceProviderUpsertWithoutVehiclesInput>;
};

export type ServiceProviderUpdateOneWithoutAddressNestedInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutAddressInput>;
  delete?: InputMaybe<ServiceProviderWhereInput>;
  disconnect?: InputMaybe<ServiceProviderWhereInput>;
  update?: InputMaybe<ServiceProviderUpdateToOneWithWhereWithoutAddressInput>;
  upsert?: InputMaybe<ServiceProviderUpsertWithoutAddressInput>;
};

export type ServiceProviderUpdateOneWithoutBanksDetailsNestedInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutBanksDetailsInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutBanksDetailsInput>;
  delete?: InputMaybe<ServiceProviderWhereInput>;
  disconnect?: InputMaybe<ServiceProviderWhereInput>;
  update?: InputMaybe<ServiceProviderUpdateToOneWithWhereWithoutBanksDetailsInput>;
  upsert?: InputMaybe<ServiceProviderUpsertWithoutBanksDetailsInput>;
};

export type ServiceProviderUpdateOneWithoutBranchesNestedInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutBranchesInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutBranchesInput>;
  delete?: InputMaybe<ServiceProviderWhereInput>;
  disconnect?: InputMaybe<ServiceProviderWhereInput>;
  update?: InputMaybe<ServiceProviderUpdateToOneWithWhereWithoutBranchesInput>;
  upsert?: InputMaybe<ServiceProviderUpsertWithoutBranchesInput>;
};

export type ServiceProviderUpdateOneWithoutOwnerUserNestedInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutOwnerUserInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutOwnerUserInput>;
  delete?: InputMaybe<ServiceProviderWhereInput>;
  disconnect?: InputMaybe<ServiceProviderWhereInput>;
  update?: InputMaybe<ServiceProviderUpdateToOneWithWhereWithoutOwnerUserInput>;
  upsert?: InputMaybe<ServiceProviderUpsertWithoutOwnerUserInput>;
};

export type ServiceProviderUpdateOneWithoutServicesNestedInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutServicesInput>;
  create?: InputMaybe<ServiceProviderCreateWithoutServicesInput>;
  delete?: InputMaybe<ServiceProviderWhereInput>;
  disconnect?: InputMaybe<ServiceProviderWhereInput>;
  update?: InputMaybe<ServiceProviderUpdateToOneWithWhereWithoutServicesInput>;
  upsert?: InputMaybe<ServiceProviderUpsertWithoutServicesInput>;
};

export type ServiceProviderUpdateToOneWithWhereWithoutAddressInput = {
  data: ServiceProviderUpdateWithoutAddressInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpdateToOneWithWhereWithoutBanksDetailsInput = {
  data: ServiceProviderUpdateWithoutBanksDetailsInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpdateToOneWithWhereWithoutBranchesInput = {
  data: ServiceProviderUpdateWithoutBranchesInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpdateToOneWithWhereWithoutCollectionPointsInput = {
  data: ServiceProviderUpdateWithoutCollectionPointsInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpdateToOneWithWhereWithoutOwnerUserInput = {
  data: ServiceProviderUpdateWithoutOwnerUserInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpdateToOneWithWhereWithoutRoutesInput = {
  data: ServiceProviderUpdateWithoutRoutesInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpdateToOneWithWhereWithoutServiceProviderSchoolsInput =
  {
    data: ServiceProviderUpdateWithoutServiceProviderSchoolsInput;
    where?: InputMaybe<ServiceProviderWhereInput>;
  };

export type ServiceProviderUpdateToOneWithWhereWithoutServicesInput = {
  data: ServiceProviderUpdateWithoutServicesInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpdateToOneWithWhereWithoutUsersInput = {
  data: ServiceProviderUpdateWithoutUsersInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpdateToOneWithWhereWithoutVehicleGroupsInput = {
  data: ServiceProviderUpdateWithoutVehicleGroupsInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpdateToOneWithWhereWithoutVehiclesInput = {
  data: ServiceProviderUpdateWithoutVehiclesInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpdateWithWhereUniqueWithoutServiceProviderGroupInput =
  {
    data: ServiceProviderUpdateWithoutServiceProviderGroupInput;
    where: ServiceProviderWhereUniqueInput;
  };

export type ServiceProviderUpdateWithoutAddressInput = {
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutServiceProviderNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser?: InputMaybe<UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpdateWithoutBanksDetailsInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser?: InputMaybe<UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpdateWithoutBranchesInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser?: InputMaybe<UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpdateWithoutCollectionPointsInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutServiceProviderNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser?: InputMaybe<UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpdateWithoutOwnerUserInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutServiceProviderNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpdateWithoutRoutesInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutServiceProviderNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser?: InputMaybe<UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpdateWithoutServiceProviderGroupInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutServiceProviderNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser?: InputMaybe<UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpdateWithoutServiceProviderSchoolsInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutServiceProviderNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser?: InputMaybe<UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpdateWithoutServicesInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutServiceProviderNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser?: InputMaybe<UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpdateWithoutUsersInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutServiceProviderNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser?: InputMaybe<UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpdateWithoutVehicleGroupsInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutServiceProviderNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser?: InputMaybe<UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpdateWithoutVehiclesInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  banksDetails?: InputMaybe<BankDetailsUpdateManyWithoutServiceProviderNestedInput>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUser?: InputMaybe<UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupUpdateOneWithoutServiceProvidersNestedInput>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanUpdateManyWithoutServiceProviderNestedInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  type?: InputMaybe<ServiceProviderType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
};

export type ServiceProviderUpsertWithWhereUniqueWithoutServiceProviderGroupInput =
  {
    create: ServiceProviderCreateWithoutServiceProviderGroupInput;
    update: ServiceProviderUpdateWithoutServiceProviderGroupInput;
    where: ServiceProviderWhereUniqueInput;
  };

export type ServiceProviderUpsertWithoutAddressInput = {
  create: ServiceProviderCreateWithoutAddressInput;
  update: ServiceProviderUpdateWithoutAddressInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpsertWithoutBanksDetailsInput = {
  create: ServiceProviderCreateWithoutBanksDetailsInput;
  update: ServiceProviderUpdateWithoutBanksDetailsInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpsertWithoutBranchesInput = {
  create: ServiceProviderCreateWithoutBranchesInput;
  update: ServiceProviderUpdateWithoutBranchesInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpsertWithoutCollectionPointsInput = {
  create: ServiceProviderCreateWithoutCollectionPointsInput;
  update: ServiceProviderUpdateWithoutCollectionPointsInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpsertWithoutOwnerUserInput = {
  create: ServiceProviderCreateWithoutOwnerUserInput;
  update: ServiceProviderUpdateWithoutOwnerUserInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpsertWithoutRoutesInput = {
  create: ServiceProviderCreateWithoutRoutesInput;
  update: ServiceProviderUpdateWithoutRoutesInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpsertWithoutServiceProviderSchoolsInput = {
  create: ServiceProviderCreateWithoutServiceProviderSchoolsInput;
  update: ServiceProviderUpdateWithoutServiceProviderSchoolsInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpsertWithoutServicesInput = {
  create: ServiceProviderCreateWithoutServicesInput;
  update: ServiceProviderUpdateWithoutServicesInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpsertWithoutUsersInput = {
  create: ServiceProviderCreateWithoutUsersInput;
  update: ServiceProviderUpdateWithoutUsersInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpsertWithoutVehicleGroupsInput = {
  create: ServiceProviderCreateWithoutVehicleGroupsInput;
  update: ServiceProviderUpdateWithoutVehicleGroupsInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUpsertWithoutVehiclesInput = {
  create: ServiceProviderCreateWithoutVehiclesInput;
  update: ServiceProviderUpdateWithoutVehiclesInput;
  where?: InputMaybe<ServiceProviderWhereInput>;
};

export type ServiceProviderUser = {
  __typename?: "ServiceProviderUser";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type ServiceProviderUserCreateManyServiceProviderInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type ServiceProviderUserCreateManyServiceProviderInputEnvelope = {
  data: Array<ServiceProviderUserCreateManyServiceProviderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceProviderUserCreateManyUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderUserCreateManyUserInputEnvelope = {
  data: Array<ServiceProviderUserCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceProviderUserCreateNestedManyWithoutServiceProviderInput = {
  connect?: InputMaybe<Array<ServiceProviderUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceProviderUserCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<
    Array<ServiceProviderUserCreateWithoutServiceProviderInput>
  >;
  createMany?: InputMaybe<ServiceProviderUserCreateManyServiceProviderInputEnvelope>;
};

export type ServiceProviderUserCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ServiceProviderUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceProviderUserCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<ServiceProviderUserCreateWithoutUserInput>>;
  createMany?: InputMaybe<ServiceProviderUserCreateManyUserInputEnvelope>;
};

export type ServiceProviderUserCreateOrConnectWithoutServiceProviderInput = {
  create: ServiceProviderUserCreateWithoutServiceProviderInput;
  where: ServiceProviderUserWhereUniqueInput;
};

export type ServiceProviderUserCreateOrConnectWithoutUserInput = {
  create: ServiceProviderUserCreateWithoutUserInput;
  where: ServiceProviderUserWhereUniqueInput;
};

export type ServiceProviderUserCreateWithoutServiceProviderInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutServiceProvidersInput;
};

export type ServiceProviderUserCreateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider: ServiceProviderCreateNestedOneWithoutUsersInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderUserListRelationFilter = {
  every?: InputMaybe<ServiceProviderUserWhereInput>;
  none?: InputMaybe<ServiceProviderUserWhereInput>;
  some?: InputMaybe<ServiceProviderUserWhereInput>;
};

export type ServiceProviderUserScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderUserScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderUserScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderUserScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ServiceProviderUserUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderUserUpdateManyWithWhereWithoutServiceProviderInput =
  {
    data: ServiceProviderUserUpdateManyMutationInput;
    where: ServiceProviderUserScalarWhereInput;
  };

export type ServiceProviderUserUpdateManyWithWhereWithoutUserInput = {
  data: ServiceProviderUserUpdateManyMutationInput;
  where: ServiceProviderUserScalarWhereInput;
};

export type ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput = {
  connect?: InputMaybe<Array<ServiceProviderUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceProviderUserCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<
    Array<ServiceProviderUserCreateWithoutServiceProviderInput>
  >;
  createMany?: InputMaybe<ServiceProviderUserCreateManyServiceProviderInputEnvelope>;
  delete?: InputMaybe<Array<ServiceProviderUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceProviderUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceProviderUserWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceProviderUserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceProviderUserUpdateWithWhereUniqueWithoutServiceProviderInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceProviderUserUpdateManyWithWhereWithoutServiceProviderInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceProviderUserUpsertWithWhereUniqueWithoutServiceProviderInput>
  >;
};

export type ServiceProviderUserUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ServiceProviderUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceProviderUserCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<ServiceProviderUserCreateWithoutUserInput>>;
  createMany?: InputMaybe<ServiceProviderUserCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ServiceProviderUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceProviderUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceProviderUserWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceProviderUserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceProviderUserUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceProviderUserUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceProviderUserUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type ServiceProviderUserUpdateWithWhereUniqueWithoutServiceProviderInput =
  {
    data: ServiceProviderUserUpdateWithoutServiceProviderInput;
    where: ServiceProviderUserWhereUniqueInput;
  };

export type ServiceProviderUserUpdateWithWhereUniqueWithoutUserInput = {
  data: ServiceProviderUserUpdateWithoutUserInput;
  where: ServiceProviderUserWhereUniqueInput;
};

export type ServiceProviderUserUpdateWithoutServiceProviderInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutServiceProvidersNestedInput>;
};

export type ServiceProviderUserUpdateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceProviderUserUpsertWithWhereUniqueWithoutServiceProviderInput =
  {
    create: ServiceProviderUserCreateWithoutServiceProviderInput;
    update: ServiceProviderUserUpdateWithoutServiceProviderInput;
    where: ServiceProviderUserWhereUniqueInput;
  };

export type ServiceProviderUserUpsertWithWhereUniqueWithoutUserInput = {
  create: ServiceProviderUserCreateWithoutUserInput;
  update: ServiceProviderUserUpdateWithoutUserInput;
  where: ServiceProviderUserWhereUniqueInput;
};

export type ServiceProviderUserWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderUserWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderUserWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderUserWhereInput>>;
  id?: InputMaybe<StringFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ServiceProviderUserWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceProviderUserWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderUserWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderUserWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ServiceProviderWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderWhereInput>>;
  address?: InputMaybe<AddressRelationFilter>;
  addressId?: InputMaybe<StringFilter>;
  banksDetails?: InputMaybe<BankDetailsListRelationFilter>;
  branches?: InputMaybe<BranchListRelationFilter>;
  collectionPoints?: InputMaybe<CollectionPointListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  ownerUser?: InputMaybe<UserRelationFilter>;
  ownerUserId?: InputMaybe<StringFilter>;
  routes?: InputMaybe<RouteListRelationFilter>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupRelationFilter>;
  serviceProviderGroupId?: InputMaybe<StringFilter>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolListRelationFilter>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanListRelationFilter>;
  services?: InputMaybe<ServiceListRelationFilter>;
  status?: InputMaybe<EnumServiceProviderStatusFilter>;
  type?: InputMaybe<EnumServiceProviderTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<ServiceProviderUserListRelationFilter>;
  usesCollectionPoints?: InputMaybe<BoolFilter>;
  vehicleGroups?: InputMaybe<VehicleGroupListRelationFilter>;
  vehicles?: InputMaybe<VehicleListRelationFilter>;
};

export type ServiceProviderWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceProviderWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderWhereInput>>;
  address?: InputMaybe<AddressRelationFilter>;
  addressId?: InputMaybe<Scalars["String"]["input"]>;
  banksDetails?: InputMaybe<BankDetailsListRelationFilter>;
  branches?: InputMaybe<BranchListRelationFilter>;
  collectionPoints?: InputMaybe<CollectionPointListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  ownerUser?: InputMaybe<UserRelationFilter>;
  ownerUserId?: InputMaybe<Scalars["String"]["input"]>;
  routes?: InputMaybe<RouteListRelationFilter>;
  serviceProviderGroup?: InputMaybe<ServiceProviderGroupRelationFilter>;
  serviceProviderGroupId?: InputMaybe<StringFilter>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolListRelationFilter>;
  serviceProviderSubscriptionPlans?: InputMaybe<ServiceProviderSubscriptionPlanListRelationFilter>;
  services?: InputMaybe<ServiceListRelationFilter>;
  status?: InputMaybe<EnumServiceProviderStatusFilter>;
  type?: InputMaybe<EnumServiceProviderTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<ServiceProviderUserListRelationFilter>;
  usesCollectionPoints?: InputMaybe<BoolFilter>;
  vehicleGroups?: InputMaybe<VehicleGroupListRelationFilter>;
  vehicles?: InputMaybe<VehicleListRelationFilter>;
};

export type ServiceRelationFilter = {
  is?: InputMaybe<ServiceWhereInput>;
  isNot?: InputMaybe<ServiceWhereInput>;
};

export type ServiceResponsePayload = {
  __typename?: "ServiceResponsePayload";
  category: ServiceCategoryResponsePayload;
  country: CountryResponsePayload;
  /** The creation time */
  createdAt: Scalars["DateTime"]["output"];
  durationInMonths: Scalars["Int"]["output"];
  /** A UUID */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  paymentPlanPeriods: Array<PaymentPlanPeriod>;
  photo: Scalars["String"]["output"];
  serviceAcademicYear: Scalars["Int"]["output"];
  /** The last update time */
  updatedAt: Scalars["DateTime"]["output"];
};

export type ServiceScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceScalarWhereInput>>;
  allowOnlinePayment?: InputMaybe<BoolFilter>;
  consumptionDurationInMonths?: InputMaybe<IntFilter>;
  consumptionStartDate?: InputMaybe<DateTimeFilter>;
  countryId?: InputMaybe<StringFilter>;
  durationInMonths?: InputMaybe<IntFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  installmentPlansOrderingStrategy?: InputMaybe<EnumInstallmentPlansOrderingStrategyFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  photo?: InputMaybe<StringFilter>;
  pricePreview?: InputMaybe<EnumServicePricePreviewFilter>;
  promotionalInfo?: InputMaybe<JsonFilter>;
  registrationEndDate?: InputMaybe<DateTimeFilter>;
  registrationStartDate?: InputMaybe<DateTimeFilter>;
  serviceAcademicYear?: InputMaybe<IntFilter>;
  serviceCategoryId?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  serviceType?: InputMaybe<EnumServiceTypeFilter>;
  serviceZoneId?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ServiceType {
  DISTANCE_BASED = "DISTANCE_BASED",
  FLAT_RATE = "FLAT_RATE",
  ZONE_BASED = "ZONE_BASED",
}

export type ServiceUpdateInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryUpdateOneWithoutServicesNestedInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutServicesNestedInput>;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodUpdateManyWithoutServiceNestedInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityUpdateManyWithoutServiceNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutServicesNestedInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZone?: InputMaybe<ServiceZoneUpdateOneWithoutServiceNestedInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutServiceNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceUpdateManyMutationInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceUpdateManyWithWhereWithoutCountryInput = {
  data: ServiceUpdateManyMutationInput;
  where: ServiceScalarWhereInput;
};

export type ServiceUpdateManyWithWhereWithoutServiceProviderInput = {
  data: ServiceUpdateManyMutationInput;
  where: ServiceScalarWhereInput;
};

export type ServiceUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<ServiceCreateWithoutCountryInput>>;
  createMany?: InputMaybe<ServiceCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  update?: InputMaybe<Array<ServiceUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<Array<ServiceUpdateManyWithWhereWithoutCountryInput>>;
  upsert?: InputMaybe<Array<ServiceUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type ServiceUpdateManyWithoutServiceProviderNestedInput = {
  connect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<ServiceCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<ServiceCreateManyServiceProviderInputEnvelope>;
  delete?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceUpdateWithWhereUniqueWithoutServiceProviderInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceUpdateManyWithWhereWithoutServiceProviderInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceUpsertWithWhereUniqueWithoutServiceProviderInput>
  >;
};

export type ServiceUpdateOneRequiredWithoutServiceEligibilitiesNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutServiceEligibilitiesInput>;
  create?: InputMaybe<ServiceCreateWithoutServiceEligibilitiesInput>;
  update?: InputMaybe<ServiceUpdateToOneWithWhereWithoutServiceEligibilitiesInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutServiceEligibilitiesInput>;
};

export type ServiceUpdateOneRequiredWithoutServiceZoneSchoolsNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutServiceZoneSchoolsInput>;
  create?: InputMaybe<ServiceCreateWithoutServiceZoneSchoolsInput>;
  update?: InputMaybe<ServiceUpdateToOneWithWhereWithoutServiceZoneSchoolsInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutServiceZoneSchoolsInput>;
};

export type ServiceUpdateOneWithoutPaymentPlansPeriodsNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutPaymentPlansPeriodsInput>;
  create?: InputMaybe<ServiceCreateWithoutPaymentPlansPeriodsInput>;
  delete?: InputMaybe<ServiceWhereInput>;
  disconnect?: InputMaybe<ServiceWhereInput>;
  update?: InputMaybe<ServiceUpdateToOneWithWhereWithoutPaymentPlansPeriodsInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutPaymentPlansPeriodsInput>;
};

export type ServiceUpdateOneWithoutServiceZoneNestedInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutServiceZoneInput>;
  create?: InputMaybe<ServiceCreateWithoutServiceZoneInput>;
  delete?: InputMaybe<ServiceWhereInput>;
  disconnect?: InputMaybe<ServiceWhereInput>;
  update?: InputMaybe<ServiceUpdateToOneWithWhereWithoutServiceZoneInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutServiceZoneInput>;
};

export type ServiceUpdateToOneWithWhereWithoutPaymentPlansPeriodsInput = {
  data: ServiceUpdateWithoutPaymentPlansPeriodsInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpdateToOneWithWhereWithoutServiceEligibilitiesInput = {
  data: ServiceUpdateWithoutServiceEligibilitiesInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpdateToOneWithWhereWithoutServiceZoneInput = {
  data: ServiceUpdateWithoutServiceZoneInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpdateToOneWithWhereWithoutServiceZoneSchoolsInput = {
  data: ServiceUpdateWithoutServiceZoneSchoolsInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpdateWithWhereUniqueWithoutCountryInput = {
  data: ServiceUpdateWithoutCountryInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpdateWithWhereUniqueWithoutServiceProviderInput = {
  data: ServiceUpdateWithoutServiceProviderInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpdateWithoutCountryInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryUpdateOneWithoutServicesNestedInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodUpdateManyWithoutServiceNestedInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityUpdateManyWithoutServiceNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutServicesNestedInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZone?: InputMaybe<ServiceZoneUpdateOneWithoutServiceNestedInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutServiceNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceUpdateWithoutPaymentPlansPeriodsInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryUpdateOneWithoutServicesNestedInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutServicesNestedInput>;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityUpdateManyWithoutServiceNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutServicesNestedInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZone?: InputMaybe<ServiceZoneUpdateOneWithoutServiceNestedInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutServiceNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceUpdateWithoutServiceEligibilitiesInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryUpdateOneWithoutServicesNestedInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutServicesNestedInput>;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodUpdateManyWithoutServiceNestedInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutServicesNestedInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZone?: InputMaybe<ServiceZoneUpdateOneWithoutServiceNestedInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutServiceNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceUpdateWithoutServiceProviderInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryUpdateOneWithoutServicesNestedInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutServicesNestedInput>;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodUpdateManyWithoutServiceNestedInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityUpdateManyWithoutServiceNestedInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZone?: InputMaybe<ServiceZoneUpdateOneWithoutServiceNestedInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutServiceNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceUpdateWithoutServiceZoneInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryUpdateOneWithoutServicesNestedInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutServicesNestedInput>;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodUpdateManyWithoutServiceNestedInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityUpdateManyWithoutServiceNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutServicesNestedInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutServiceNestedInput>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceUpdateWithoutServiceZoneSchoolsInput = {
  allowOnlinePayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<ServiceCategoryUpdateOneWithoutServicesNestedInput>;
  consumptionDurationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  consumptionStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutServicesNestedInput>;
  durationInMonths?: InputMaybe<Scalars["Int"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<InstallmentPlansOrderingStrategy>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodUpdateManyWithoutServiceNestedInput>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  pricePreview?: InputMaybe<ServicePricePreview>;
  promotionalInfo?: InputMaybe<Scalars["JSON"]["input"]>;
  registrationEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  registrationStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceAcademicYear?: InputMaybe<Scalars["Int"]["input"]>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityUpdateManyWithoutServiceNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutServicesNestedInput>;
  serviceType?: InputMaybe<ServiceType>;
  serviceZone?: InputMaybe<ServiceZoneUpdateOneWithoutServiceNestedInput>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Status>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceUpsertWithWhereUniqueWithoutCountryInput = {
  create: ServiceCreateWithoutCountryInput;
  update: ServiceUpdateWithoutCountryInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertWithWhereUniqueWithoutServiceProviderInput = {
  create: ServiceCreateWithoutServiceProviderInput;
  update: ServiceUpdateWithoutServiceProviderInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertWithoutPaymentPlansPeriodsInput = {
  create: ServiceCreateWithoutPaymentPlansPeriodsInput;
  update: ServiceUpdateWithoutPaymentPlansPeriodsInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpsertWithoutServiceEligibilitiesInput = {
  create: ServiceCreateWithoutServiceEligibilitiesInput;
  update: ServiceUpdateWithoutServiceEligibilitiesInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpsertWithoutServiceZoneInput = {
  create: ServiceCreateWithoutServiceZoneInput;
  update: ServiceUpdateWithoutServiceZoneInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceUpsertWithoutServiceZoneSchoolsInput = {
  create: ServiceCreateWithoutServiceZoneSchoolsInput;
  update: ServiceUpdateWithoutServiceZoneSchoolsInput;
  where?: InputMaybe<ServiceWhereInput>;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  allowOnlinePayment?: InputMaybe<BoolFilter>;
  category?: InputMaybe<ServiceCategoryRelationFilter>;
  consumptionDurationInMonths?: InputMaybe<IntFilter>;
  consumptionStartDate?: InputMaybe<DateTimeFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  durationInMonths?: InputMaybe<IntFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  installmentPlansOrderingStrategy?: InputMaybe<EnumInstallmentPlansOrderingStrategyFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodListRelationFilter>;
  photo?: InputMaybe<StringFilter>;
  pricePreview?: InputMaybe<EnumServicePricePreviewFilter>;
  promotionalInfo?: InputMaybe<JsonFilter>;
  registrationEndDate?: InputMaybe<DateTimeFilter>;
  registrationStartDate?: InputMaybe<DateTimeFilter>;
  serviceAcademicYear?: InputMaybe<IntFilter>;
  serviceCategoryId?: InputMaybe<StringFilter>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityListRelationFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  serviceType?: InputMaybe<EnumServiceTypeFilter>;
  serviceZone?: InputMaybe<ServiceZoneRelationFilter>;
  serviceZoneId?: InputMaybe<StringFilter>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolListRelationFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  allowOnlinePayment?: InputMaybe<BoolFilter>;
  category?: InputMaybe<ServiceCategoryRelationFilter>;
  consumptionDurationInMonths?: InputMaybe<IntFilter>;
  consumptionStartDate?: InputMaybe<DateTimeFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  durationInMonths?: InputMaybe<IntFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlansOrderingStrategy?: InputMaybe<EnumInstallmentPlansOrderingStrategyFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  paymentPlansPeriods?: InputMaybe<PaymentPlanPeriodListRelationFilter>;
  photo?: InputMaybe<StringFilter>;
  pricePreview?: InputMaybe<EnumServicePricePreviewFilter>;
  promotionalInfo?: InputMaybe<JsonFilter>;
  registrationEndDate?: InputMaybe<DateTimeFilter>;
  registrationStartDate?: InputMaybe<DateTimeFilter>;
  serviceAcademicYear?: InputMaybe<IntFilter>;
  serviceCategoryId?: InputMaybe<StringFilter>;
  serviceEligibilities?: InputMaybe<ServiceEligibilityListRelationFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  serviceType?: InputMaybe<EnumServiceTypeFilter>;
  serviceZone?: InputMaybe<ServiceZoneRelationFilter>;
  serviceZoneId?: InputMaybe<Scalars["String"]["input"]>;
  serviceZoneSchools?: InputMaybe<ServiceZoneSchoolListRelationFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceWithPrice = {
  __typename?: "ServiceWithPrice";
  _count: ServiceCount;
  allowOnlinePayment: Scalars["Boolean"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  basePrice: Scalars["Float"]["output"];
  basePriceWithVat: Scalars["Float"]["output"];
  basePriceWithVatAndModifiers: Scalars["Float"]["output"];
  basePriceWithVatAndModifiersAndOffer: Scalars["Float"]["output"];
  category?: Maybe<ServiceCategory>;
  consumptionDurationInMonths?: Maybe<Scalars["Int"]["output"]>;
  consumptionStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  durationInMonths?: Maybe<Scalars["Int"]["output"]>;
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  installmentPlans: Array<InstallmentPlanWithPrice>;
  installmentPlansOrderingStrategy: InstallmentPlansOrderingStrategy;
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  paymentPlansPeriods?: Maybe<Array<PaymentPlanPeriod>>;
  photo?: Maybe<Scalars["String"]["output"]>;
  pricePreview: ServicePricePreview;
  promotionalInfo?: Maybe<Scalars["JSON"]["output"]>;
  registrationEndDate?: Maybe<Scalars["DateTime"]["output"]>;
  registrationStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  serviceAcademicYear: Scalars["Int"]["output"];
  serviceCategoryId?: Maybe<Scalars["String"]["output"]>;
  serviceEligibilities?: Maybe<Array<ServiceEligibility>>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderId?: Maybe<Scalars["String"]["output"]>;
  serviceType: ServiceType;
  serviceZone?: Maybe<ServiceZone>;
  serviceZoneId?: Maybe<Scalars["String"]["output"]>;
  serviceZoneSchools?: Maybe<Array<ServiceZoneSchool>>;
  startDate: Scalars["DateTime"]["output"];
  status: Status;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ServiceWithPriceAndRefund = {
  __typename?: "ServiceWithPriceAndRefund";
  _count: ServiceCount;
  allowOnlinePayment: Scalars["Boolean"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  basePrice: Scalars["Float"]["output"];
  basePriceWithVat: Scalars["Float"]["output"];
  basePriceWithVatAndModifiers: Scalars["Float"]["output"];
  basePriceWithVatAndModifiersAndOffer: Scalars["Float"]["output"];
  category?: Maybe<ServiceCategory>;
  consumptionDurationInMonths?: Maybe<Scalars["Int"]["output"]>;
  consumptionStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  durationInMonths?: Maybe<Scalars["Int"]["output"]>;
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  installmentPlans: Array<InstallmentPlanWithPriceAndRefund>;
  installmentPlansOrderingStrategy: InstallmentPlansOrderingStrategy;
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  paymentPlansPeriods?: Maybe<Array<PaymentPlanPeriod>>;
  photo?: Maybe<Scalars["String"]["output"]>;
  pricePreview: ServicePricePreview;
  promotionalInfo?: Maybe<Scalars["JSON"]["output"]>;
  registrationEndDate?: Maybe<Scalars["DateTime"]["output"]>;
  registrationStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  serviceAcademicYear: Scalars["Int"]["output"];
  serviceCategoryId?: Maybe<Scalars["String"]["output"]>;
  serviceEligibilities?: Maybe<Array<ServiceEligibility>>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderId?: Maybe<Scalars["String"]["output"]>;
  serviceType: ServiceType;
  serviceZone?: Maybe<ServiceZone>;
  serviceZoneId?: Maybe<Scalars["String"]["output"]>;
  serviceZoneSchools?: Maybe<Array<ServiceZoneSchool>>;
  startDate: Scalars["DateTime"]["output"];
  status: Status;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ServiceZone = {
  __typename?: "ServiceZone";
  _count: ServiceZoneCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  zones?: Maybe<Array<Zone>>;
};

export type ServiceZoneCount = {
  __typename?: "ServiceZoneCount";
  zones: Scalars["Int"]["output"];
};

export type ServiceZoneCreateExtendedNestedOneWithoutServiceInput = {
  connect?: InputMaybe<ServiceZoneWhereUniqueInput>;
  create?: InputMaybe<ServiceZoneCreateExtendedWithoutServiceInput>;
};

export type ServiceZoneCreateExtendedWithoutServiceInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  selectedSchools?: InputMaybe<SchoolConnectNestedManyWithoutServiceZoneSchoolUniqueInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zones?: InputMaybe<ZoneCreateExtendedNestedManyWithoutServiceZoneInput>;
};

export type ServiceZoneCreateNestedOneWithoutServiceInput = {
  connect?: InputMaybe<ServiceZoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceZoneCreateOrConnectWithoutServiceInput>;
  create?: InputMaybe<ServiceZoneCreateWithoutServiceInput>;
};

export type ServiceZoneCreateOrConnectWithoutServiceInput = {
  create: ServiceZoneCreateWithoutServiceInput;
  where: ServiceZoneWhereUniqueInput;
};

export type ServiceZoneCreateOrConnectWithoutZonesInput = {
  create: ServiceZoneCreateWithoutZonesInput;
  where: ServiceZoneWhereUniqueInput;
};

export type ServiceZoneCreateWithoutServiceInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zones?: InputMaybe<ZoneCreateNestedManyWithoutServiceZoneInput>;
};

export type ServiceZoneCreateWithoutZonesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutServiceZoneInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceZoneRelationFilter = {
  is?: InputMaybe<ServiceZoneWhereInput>;
  isNot?: InputMaybe<ServiceZoneWhereInput>;
};

export type ServiceZoneSchool = {
  __typename?: "ServiceZoneSchool";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  school: School;
  schoolId: Scalars["String"]["output"];
  service: Service;
  serviceId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  zone: Zone;
  zoneId: Scalars["String"]["output"];
};

export type ServiceZoneSchoolCreateManySchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  serviceId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneId: Scalars["String"]["input"];
};

export type ServiceZoneSchoolCreateManySchoolInputEnvelope = {
  data: Array<ServiceZoneSchoolCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceZoneSchoolCreateManyServiceInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneId: Scalars["String"]["input"];
};

export type ServiceZoneSchoolCreateManyServiceInputEnvelope = {
  data: Array<ServiceZoneSchoolCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceZoneSchoolCreateManyZoneInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["String"]["input"];
  serviceId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceZoneSchoolCreateManyZoneInputEnvelope = {
  data: Array<ServiceZoneSchoolCreateManyZoneInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceZoneSchoolCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceZoneSchoolCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<ServiceZoneSchoolCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<ServiceZoneSchoolCreateManySchoolInputEnvelope>;
};

export type ServiceZoneSchoolCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceZoneSchoolCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<ServiceZoneSchoolCreateWithoutServiceInput>>;
  createMany?: InputMaybe<ServiceZoneSchoolCreateManyServiceInputEnvelope>;
};

export type ServiceZoneSchoolCreateOrConnectWithoutSchoolInput = {
  create: ServiceZoneSchoolCreateWithoutSchoolInput;
  where: ServiceZoneSchoolWhereUniqueInput;
};

export type ServiceZoneSchoolCreateOrConnectWithoutServiceInput = {
  create: ServiceZoneSchoolCreateWithoutServiceInput;
  where: ServiceZoneSchoolWhereUniqueInput;
};

export type ServiceZoneSchoolCreateOrConnectWithoutZoneInput = {
  create: ServiceZoneSchoolCreateWithoutZoneInput;
  where: ServiceZoneSchoolWhereUniqueInput;
};

export type ServiceZoneSchoolCreateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  service: ServiceCreateNestedOneWithoutServiceZoneSchoolsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zone: ZoneCreateNestedOneWithoutServiceZoneSchoolInput;
};

export type ServiceZoneSchoolCreateWithoutServiceInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school: SchoolCreateNestedOneWithoutServiceZoneSchoolInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zone: ZoneCreateNestedOneWithoutServiceZoneSchoolInput;
};

export type ServiceZoneSchoolCreateWithoutZoneInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school: SchoolCreateNestedOneWithoutServiceZoneSchoolInput;
  service: ServiceCreateNestedOneWithoutServiceZoneSchoolsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceZoneSchoolListRelationFilter = {
  every?: InputMaybe<ServiceZoneSchoolWhereInput>;
  none?: InputMaybe<ServiceZoneSchoolWhereInput>;
  some?: InputMaybe<ServiceZoneSchoolWhereInput>;
};

export type ServiceZoneSchoolScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceZoneSchoolScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceZoneSchoolScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceZoneSchoolScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  schoolId?: InputMaybe<StringFilter>;
  serviceId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zoneId?: InputMaybe<StringFilter>;
};

export type ServiceZoneSchoolUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceZoneSchoolUpdateManyWithWhereWithoutSchoolInput = {
  data: ServiceZoneSchoolUpdateManyMutationInput;
  where: ServiceZoneSchoolScalarWhereInput;
};

export type ServiceZoneSchoolUpdateManyWithWhereWithoutServiceInput = {
  data: ServiceZoneSchoolUpdateManyMutationInput;
  where: ServiceZoneSchoolScalarWhereInput;
};

export type ServiceZoneSchoolUpdateManyWithWhereWithoutZoneInput = {
  data: ServiceZoneSchoolUpdateManyMutationInput;
  where: ServiceZoneSchoolScalarWhereInput;
};

export type ServiceZoneSchoolUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceZoneSchoolCreateOrConnectWithoutSchoolInput>
  >;
  create?: InputMaybe<Array<ServiceZoneSchoolCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<ServiceZoneSchoolCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceZoneSchoolScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceZoneSchoolUpdateWithWhereUniqueWithoutSchoolInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceZoneSchoolUpdateManyWithWhereWithoutSchoolInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceZoneSchoolUpsertWithWhereUniqueWithoutSchoolInput>
  >;
};

export type ServiceZoneSchoolUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceZoneSchoolCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<ServiceZoneSchoolCreateWithoutServiceInput>>;
  createMany?: InputMaybe<ServiceZoneSchoolCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceZoneSchoolScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceZoneSchoolUpdateWithWhereUniqueWithoutServiceInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceZoneSchoolUpdateManyWithWhereWithoutServiceInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceZoneSchoolUpsertWithWhereUniqueWithoutServiceInput>
  >;
};

export type ServiceZoneSchoolUpdateManyWithoutZoneNestedInput = {
  connect?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceZoneSchoolCreateOrConnectWithoutZoneInput>
  >;
  create?: InputMaybe<Array<ServiceZoneSchoolCreateWithoutZoneInput>>;
  createMany?: InputMaybe<ServiceZoneSchoolCreateManyZoneInputEnvelope>;
  delete?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceZoneSchoolScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceZoneSchoolWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ServiceZoneSchoolUpdateWithWhereUniqueWithoutZoneInput>
  >;
  updateMany?: InputMaybe<
    Array<ServiceZoneSchoolUpdateManyWithWhereWithoutZoneInput>
  >;
  upsert?: InputMaybe<
    Array<ServiceZoneSchoolUpsertWithWhereUniqueWithoutZoneInput>
  >;
};

export type ServiceZoneSchoolUpdateWithWhereUniqueWithoutSchoolInput = {
  data: ServiceZoneSchoolUpdateWithoutSchoolInput;
  where: ServiceZoneSchoolWhereUniqueInput;
};

export type ServiceZoneSchoolUpdateWithWhereUniqueWithoutServiceInput = {
  data: ServiceZoneSchoolUpdateWithoutServiceInput;
  where: ServiceZoneSchoolWhereUniqueInput;
};

export type ServiceZoneSchoolUpdateWithWhereUniqueWithoutZoneInput = {
  data: ServiceZoneSchoolUpdateWithoutZoneInput;
  where: ServiceZoneSchoolWhereUniqueInput;
};

export type ServiceZoneSchoolUpdateWithoutSchoolInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutServiceZoneSchoolsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zone?: InputMaybe<ZoneUpdateOneRequiredWithoutServiceZoneSchoolNestedInput>;
};

export type ServiceZoneSchoolUpdateWithoutServiceInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutServiceZoneSchoolNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zone?: InputMaybe<ZoneUpdateOneRequiredWithoutServiceZoneSchoolNestedInput>;
};

export type ServiceZoneSchoolUpdateWithoutZoneInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutServiceZoneSchoolNestedInput>;
  service?: InputMaybe<ServiceUpdateOneRequiredWithoutServiceZoneSchoolsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceZoneSchoolUpsertWithWhereUniqueWithoutSchoolInput = {
  create: ServiceZoneSchoolCreateWithoutSchoolInput;
  update: ServiceZoneSchoolUpdateWithoutSchoolInput;
  where: ServiceZoneSchoolWhereUniqueInput;
};

export type ServiceZoneSchoolUpsertWithWhereUniqueWithoutServiceInput = {
  create: ServiceZoneSchoolCreateWithoutServiceInput;
  update: ServiceZoneSchoolUpdateWithoutServiceInput;
  where: ServiceZoneSchoolWhereUniqueInput;
};

export type ServiceZoneSchoolUpsertWithWhereUniqueWithoutZoneInput = {
  create: ServiceZoneSchoolCreateWithoutZoneInput;
  update: ServiceZoneSchoolUpdateWithoutZoneInput;
  where: ServiceZoneSchoolWhereUniqueInput;
};

export type ServiceZoneSchoolWhereInput = {
  AND?: InputMaybe<Array<ServiceZoneSchoolWhereInput>>;
  NOT?: InputMaybe<Array<ServiceZoneSchoolWhereInput>>;
  OR?: InputMaybe<Array<ServiceZoneSchoolWhereInput>>;
  id?: InputMaybe<StringFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zone?: InputMaybe<ZoneRelationFilter>;
  zoneId?: InputMaybe<StringFilter>;
};

export type ServiceZoneSchoolWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceZoneSchoolWhereInput>>;
  NOT?: InputMaybe<Array<ServiceZoneSchoolWhereInput>>;
  OR?: InputMaybe<Array<ServiceZoneSchoolWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zone?: InputMaybe<ZoneRelationFilter>;
  zoneId?: InputMaybe<StringFilter>;
};

export type ServiceZoneUpdateOneRequiredWithoutZonesNestedInput = {
  connect?: InputMaybe<ServiceZoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceZoneCreateOrConnectWithoutZonesInput>;
  create?: InputMaybe<ServiceZoneCreateWithoutZonesInput>;
  update?: InputMaybe<ServiceZoneUpdateToOneWithWhereWithoutZonesInput>;
  upsert?: InputMaybe<ServiceZoneUpsertWithoutZonesInput>;
};

export type ServiceZoneUpdateOneWithoutServiceNestedInput = {
  connect?: InputMaybe<ServiceZoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceZoneCreateOrConnectWithoutServiceInput>;
  create?: InputMaybe<ServiceZoneCreateWithoutServiceInput>;
  delete?: InputMaybe<ServiceZoneWhereInput>;
  disconnect?: InputMaybe<ServiceZoneWhereInput>;
  update?: InputMaybe<ServiceZoneUpdateToOneWithWhereWithoutServiceInput>;
  upsert?: InputMaybe<ServiceZoneUpsertWithoutServiceInput>;
};

export type ServiceZoneUpdateToOneWithWhereWithoutServiceInput = {
  data: ServiceZoneUpdateWithoutServiceInput;
  where?: InputMaybe<ServiceZoneWhereInput>;
};

export type ServiceZoneUpdateToOneWithWhereWithoutZonesInput = {
  data: ServiceZoneUpdateWithoutZonesInput;
  where?: InputMaybe<ServiceZoneWhereInput>;
};

export type ServiceZoneUpdateWithoutServiceInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zones?: InputMaybe<ZoneUpdateManyWithoutServiceZoneNestedInput>;
};

export type ServiceZoneUpdateWithoutZonesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  service?: InputMaybe<ServiceUpdateOneWithoutServiceZoneNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ServiceZoneUpsertWithoutServiceInput = {
  create: ServiceZoneCreateWithoutServiceInput;
  update: ServiceZoneUpdateWithoutServiceInput;
  where?: InputMaybe<ServiceZoneWhereInput>;
};

export type ServiceZoneUpsertWithoutZonesInput = {
  create: ServiceZoneCreateWithoutZonesInput;
  update: ServiceZoneUpdateWithoutZonesInput;
  where?: InputMaybe<ServiceZoneWhereInput>;
};

export type ServiceZoneWhereInput = {
  AND?: InputMaybe<Array<ServiceZoneWhereInput>>;
  NOT?: InputMaybe<Array<ServiceZoneWhereInput>>;
  OR?: InputMaybe<Array<ServiceZoneWhereInput>>;
  id?: InputMaybe<StringFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zones?: InputMaybe<ZoneListRelationFilter>;
};

export type ServiceZoneWhereUniqueInput = {
  AND?: InputMaybe<Array<ServiceZoneWhereInput>>;
  NOT?: InputMaybe<Array<ServiceZoneWhereInput>>;
  OR?: InputMaybe<Array<ServiceZoneWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zones?: InputMaybe<ZoneListRelationFilter>;
};

export type SignUpInput = {
  countryConnect: CountryWhereUniqueInput;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  otpId: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type SignUpOutput = {
  __typename?: "SignUpOutput";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  country: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  middleNameAr?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  refreshToken?: Maybe<Scalars["String"]["output"]>;
  sponsorId: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

export type SimpleLatLon = {
  lat: Scalars["Float"]["input"];
  lon: Scalars["Float"]["input"];
};

export type SimulationBoardingEvent = {
  cardSignalPower?: InputMaybe<Scalars["Float"]["input"]>;
  cardStatus?: InputMaybe<CardStatus>;
  imei: Scalars["String"]["input"];
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  tagId: Scalars["String"]["input"];
  time?: InputMaybe<Scalars["String"]["input"]>;
};

export type SimulationVehicleEvent = {
  heading?: InputMaybe<Scalars["Float"]["input"]>;
  imei: Scalars["String"]["input"];
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  speed?: InputMaybe<Scalars["Float"]["input"]>;
  time?: InputMaybe<Scalars["String"]["input"]>;
};

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type SpContactWithGroupedStudents = {
  __typename?: "SpContactWithGroupedStudents";
  serviceProviderContact?: Maybe<Array<Contact>>;
  serviceProviderEmail?: Maybe<Scalars["String"]["output"]>;
  serviceProviderName: Scalars["String"]["output"];
  serviceProviderNameAr: Scalars["String"]["output"];
  studentNames: Array<Scalars["String"]["output"]>;
  studentNamesAr: Array<Scalars["String"]["output"]>;
};

export type SpecialNeed = {
  __typename?: "SpecialNeed";
  _count: SpecialNeedCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  country: Country;
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isDefinedByUser: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  specialNeedPassengers?: Maybe<Array<SpecialNeedPassenger>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SpecialNeedCount = {
  __typename?: "SpecialNeedCount";
  specialNeedPassengers: Scalars["Int"]["output"];
};

export type SpecialNeedCreateManyCountryInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isDefinedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedCreateManyCountryInputEnvelope = {
  data: Array<SpecialNeedCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SpecialNeedCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<SpecialNeedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SpecialNeedCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<SpecialNeedCreateWithoutCountryInput>>;
  createMany?: InputMaybe<SpecialNeedCreateManyCountryInputEnvelope>;
};

export type SpecialNeedCreateNestedOneWithoutSpecialNeedPassengersInput = {
  connect?: InputMaybe<SpecialNeedWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SpecialNeedCreateOrConnectWithoutSpecialNeedPassengersInput>;
  create?: InputMaybe<SpecialNeedCreateWithoutSpecialNeedPassengersInput>;
};

export type SpecialNeedCreateOrConnectWithoutCountryInput = {
  create: SpecialNeedCreateWithoutCountryInput;
  where: SpecialNeedWhereUniqueInput;
};

export type SpecialNeedCreateOrConnectWithoutSpecialNeedPassengersInput = {
  create: SpecialNeedCreateWithoutSpecialNeedPassengersInput;
  where: SpecialNeedWhereUniqueInput;
};

export type SpecialNeedCreateWithoutCountryInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isDefinedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  specialNeedPassengers?: InputMaybe<SpecialNeedPassengerCreateNestedManyWithoutSpecialNeedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedCreateWithoutSpecialNeedPassengersInput = {
  country?: InputMaybe<CountryCreateNestedOneWithoutSpecialNeedsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isDefinedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedListRelationFilter = {
  every?: InputMaybe<SpecialNeedWhereInput>;
  none?: InputMaybe<SpecialNeedWhereInput>;
  some?: InputMaybe<SpecialNeedWhereInput>;
};

export type SpecialNeedPassenger = {
  __typename?: "SpecialNeedPassenger";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  passenger: Passenger;
  passengerId: Scalars["String"]["output"];
  specialNeed: SpecialNeed;
  specialNeedId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SpecialNeedPassengerCreateManyPassengerInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  specialNeedId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedPassengerCreateManyPassengerInputEnvelope = {
  data: Array<SpecialNeedPassengerCreateManyPassengerInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SpecialNeedPassengerCreateManySpecialNeedInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengerId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedPassengerCreateManySpecialNeedInputEnvelope = {
  data: Array<SpecialNeedPassengerCreateManySpecialNeedInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SpecialNeedPassengerCreateNestedManyWithoutPassengerInput = {
  connect?: InputMaybe<Array<SpecialNeedPassengerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SpecialNeedPassengerCreateOrConnectWithoutPassengerInput>
  >;
  create?: InputMaybe<Array<SpecialNeedPassengerCreateWithoutPassengerInput>>;
  createMany?: InputMaybe<SpecialNeedPassengerCreateManyPassengerInputEnvelope>;
};

export type SpecialNeedPassengerCreateNestedManyWithoutSpecialNeedInput = {
  connect?: InputMaybe<Array<SpecialNeedPassengerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SpecialNeedPassengerCreateOrConnectWithoutSpecialNeedInput>
  >;
  create?: InputMaybe<Array<SpecialNeedPassengerCreateWithoutSpecialNeedInput>>;
  createMany?: InputMaybe<SpecialNeedPassengerCreateManySpecialNeedInputEnvelope>;
};

export type SpecialNeedPassengerCreateOrConnectWithoutPassengerInput = {
  create: SpecialNeedPassengerCreateWithoutPassengerInput;
  where: SpecialNeedPassengerWhereUniqueInput;
};

export type SpecialNeedPassengerCreateOrConnectWithoutSpecialNeedInput = {
  create: SpecialNeedPassengerCreateWithoutSpecialNeedInput;
  where: SpecialNeedPassengerWhereUniqueInput;
};

export type SpecialNeedPassengerCreateWithoutPassengerInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  specialNeed: SpecialNeedCreateNestedOneWithoutSpecialNeedPassengersInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedPassengerCreateWithoutSpecialNeedInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passenger: PassengerCreateNestedOneWithoutPassengerSpecialNeedsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedPassengerListRelationFilter = {
  every?: InputMaybe<SpecialNeedPassengerWhereInput>;
  none?: InputMaybe<SpecialNeedPassengerWhereInput>;
  some?: InputMaybe<SpecialNeedPassengerWhereInput>;
};

export type SpecialNeedPassengerScalarWhereInput = {
  AND?: InputMaybe<Array<SpecialNeedPassengerScalarWhereInput>>;
  NOT?: InputMaybe<Array<SpecialNeedPassengerScalarWhereInput>>;
  OR?: InputMaybe<Array<SpecialNeedPassengerScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  passengerId?: InputMaybe<StringFilter>;
  specialNeedId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SpecialNeedPassengerSpecialNeedIdPassengerIdArchivedAtCompoundUniqueInput =
  {
    passengerId: Scalars["String"]["input"];
    specialNeedId: Scalars["String"]["input"];
  };

export type SpecialNeedPassengerUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedPassengerUpdateManyWithWhereWithoutPassengerInput = {
  data: SpecialNeedPassengerUpdateManyMutationInput;
  where: SpecialNeedPassengerScalarWhereInput;
};

export type SpecialNeedPassengerUpdateManyWithWhereWithoutSpecialNeedInput = {
  data: SpecialNeedPassengerUpdateManyMutationInput;
  where: SpecialNeedPassengerScalarWhereInput;
};

export type SpecialNeedPassengerUpdateManyWithoutPassengerNestedInput = {
  connect?: InputMaybe<Array<SpecialNeedPassengerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SpecialNeedPassengerCreateOrConnectWithoutPassengerInput>
  >;
  create?: InputMaybe<Array<SpecialNeedPassengerCreateWithoutPassengerInput>>;
  createMany?: InputMaybe<SpecialNeedPassengerCreateManyPassengerInputEnvelope>;
  delete?: InputMaybe<Array<SpecialNeedPassengerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpecialNeedPassengerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpecialNeedPassengerWhereUniqueInput>>;
  set?: InputMaybe<Array<SpecialNeedPassengerWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SpecialNeedPassengerUpdateWithWhereUniqueWithoutPassengerInput>
  >;
  updateMany?: InputMaybe<
    Array<SpecialNeedPassengerUpdateManyWithWhereWithoutPassengerInput>
  >;
  upsert?: InputMaybe<
    Array<SpecialNeedPassengerUpsertWithWhereUniqueWithoutPassengerInput>
  >;
};

export type SpecialNeedPassengerUpdateManyWithoutSpecialNeedNestedInput = {
  connect?: InputMaybe<Array<SpecialNeedPassengerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SpecialNeedPassengerCreateOrConnectWithoutSpecialNeedInput>
  >;
  create?: InputMaybe<Array<SpecialNeedPassengerCreateWithoutSpecialNeedInput>>;
  createMany?: InputMaybe<SpecialNeedPassengerCreateManySpecialNeedInputEnvelope>;
  delete?: InputMaybe<Array<SpecialNeedPassengerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpecialNeedPassengerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpecialNeedPassengerWhereUniqueInput>>;
  set?: InputMaybe<Array<SpecialNeedPassengerWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SpecialNeedPassengerUpdateWithWhereUniqueWithoutSpecialNeedInput>
  >;
  updateMany?: InputMaybe<
    Array<SpecialNeedPassengerUpdateManyWithWhereWithoutSpecialNeedInput>
  >;
  upsert?: InputMaybe<
    Array<SpecialNeedPassengerUpsertWithWhereUniqueWithoutSpecialNeedInput>
  >;
};

export type SpecialNeedPassengerUpdateWithWhereUniqueWithoutPassengerInput = {
  data: SpecialNeedPassengerUpdateWithoutPassengerInput;
  where: SpecialNeedPassengerWhereUniqueInput;
};

export type SpecialNeedPassengerUpdateWithWhereUniqueWithoutSpecialNeedInput = {
  data: SpecialNeedPassengerUpdateWithoutSpecialNeedInput;
  where: SpecialNeedPassengerWhereUniqueInput;
};

export type SpecialNeedPassengerUpdateWithoutPassengerInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  specialNeed?: InputMaybe<SpecialNeedUpdateOneRequiredWithoutSpecialNeedPassengersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedPassengerUpdateWithoutSpecialNeedInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passenger?: InputMaybe<PassengerUpdateOneRequiredWithoutPassengerSpecialNeedsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedPassengerUpsertWithWhereUniqueWithoutPassengerInput = {
  create: SpecialNeedPassengerCreateWithoutPassengerInput;
  update: SpecialNeedPassengerUpdateWithoutPassengerInput;
  where: SpecialNeedPassengerWhereUniqueInput;
};

export type SpecialNeedPassengerUpsertWithWhereUniqueWithoutSpecialNeedInput = {
  create: SpecialNeedPassengerCreateWithoutSpecialNeedInput;
  update: SpecialNeedPassengerUpdateWithoutSpecialNeedInput;
  where: SpecialNeedPassengerWhereUniqueInput;
};

export type SpecialNeedPassengerWhereInput = {
  AND?: InputMaybe<Array<SpecialNeedPassengerWhereInput>>;
  NOT?: InputMaybe<Array<SpecialNeedPassengerWhereInput>>;
  OR?: InputMaybe<Array<SpecialNeedPassengerWhereInput>>;
  id?: InputMaybe<StringFilter>;
  passenger?: InputMaybe<PassengerRelationFilter>;
  passengerId?: InputMaybe<StringFilter>;
  specialNeed?: InputMaybe<SpecialNeedRelationFilter>;
  specialNeedId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SpecialNeedPassengerWhereUniqueInput = {
  AND?: InputMaybe<Array<SpecialNeedPassengerWhereInput>>;
  NOT?: InputMaybe<Array<SpecialNeedPassengerWhereInput>>;
  OR?: InputMaybe<Array<SpecialNeedPassengerWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  passenger?: InputMaybe<PassengerRelationFilter>;
  passengerId?: InputMaybe<StringFilter>;
  specialNeed?: InputMaybe<SpecialNeedRelationFilter>;
  specialNeedId?: InputMaybe<StringFilter>;
  specialNeedId_passengerId_archivedAt?: InputMaybe<SpecialNeedPassengerSpecialNeedIdPassengerIdArchivedAtCompoundUniqueInput>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SpecialNeedRelationFilter = {
  is?: InputMaybe<SpecialNeedWhereInput>;
  isNot?: InputMaybe<SpecialNeedWhereInput>;
};

export type SpecialNeedResponsePayload = {
  __typename?: "SpecialNeedResponsePayload";
  id: Scalars["String"]["output"];
  isDefinedByUser: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
};

export type SpecialNeedScalarWhereInput = {
  AND?: InputMaybe<Array<SpecialNeedScalarWhereInput>>;
  NOT?: InputMaybe<Array<SpecialNeedScalarWhereInput>>;
  OR?: InputMaybe<Array<SpecialNeedScalarWhereInput>>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isDefinedByUser?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SpecialNeedUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isDefinedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedUpdateManyWithWhereWithoutCountryInput = {
  data: SpecialNeedUpdateManyMutationInput;
  where: SpecialNeedScalarWhereInput;
};

export type SpecialNeedUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<SpecialNeedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SpecialNeedCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<SpecialNeedCreateWithoutCountryInput>>;
  createMany?: InputMaybe<SpecialNeedCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<SpecialNeedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpecialNeedScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpecialNeedWhereUniqueInput>>;
  set?: InputMaybe<Array<SpecialNeedWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SpecialNeedUpdateWithWhereUniqueWithoutCountryInput>
  >;
  updateMany?: InputMaybe<
    Array<SpecialNeedUpdateManyWithWhereWithoutCountryInput>
  >;
  upsert?: InputMaybe<
    Array<SpecialNeedUpsertWithWhereUniqueWithoutCountryInput>
  >;
};

export type SpecialNeedUpdateOneRequiredWithoutSpecialNeedPassengersNestedInput =
  {
    connect?: InputMaybe<SpecialNeedWhereUniqueInput>;
    connectOrCreate?: InputMaybe<SpecialNeedCreateOrConnectWithoutSpecialNeedPassengersInput>;
    create?: InputMaybe<SpecialNeedCreateWithoutSpecialNeedPassengersInput>;
    update?: InputMaybe<SpecialNeedUpdateToOneWithWhereWithoutSpecialNeedPassengersInput>;
    upsert?: InputMaybe<SpecialNeedUpsertWithoutSpecialNeedPassengersInput>;
  };

export type SpecialNeedUpdateToOneWithWhereWithoutSpecialNeedPassengersInput = {
  data: SpecialNeedUpdateWithoutSpecialNeedPassengersInput;
  where?: InputMaybe<SpecialNeedWhereInput>;
};

export type SpecialNeedUpdateWithWhereUniqueWithoutCountryInput = {
  data: SpecialNeedUpdateWithoutCountryInput;
  where: SpecialNeedWhereUniqueInput;
};

export type SpecialNeedUpdateWithoutCountryInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isDefinedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  specialNeedPassengers?: InputMaybe<SpecialNeedPassengerUpdateManyWithoutSpecialNeedNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedUpdateWithoutSpecialNeedPassengersInput = {
  country?: InputMaybe<CountryUpdateOneRequiredWithoutSpecialNeedsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isDefinedByUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SpecialNeedUpsertWithWhereUniqueWithoutCountryInput = {
  create: SpecialNeedCreateWithoutCountryInput;
  update: SpecialNeedUpdateWithoutCountryInput;
  where: SpecialNeedWhereUniqueInput;
};

export type SpecialNeedUpsertWithoutSpecialNeedPassengersInput = {
  create: SpecialNeedCreateWithoutSpecialNeedPassengersInput;
  update: SpecialNeedUpdateWithoutSpecialNeedPassengersInput;
  where?: InputMaybe<SpecialNeedWhereInput>;
};

export type SpecialNeedWhereInput = {
  AND?: InputMaybe<Array<SpecialNeedWhereInput>>;
  NOT?: InputMaybe<Array<SpecialNeedWhereInput>>;
  OR?: InputMaybe<Array<SpecialNeedWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isDefinedByUser?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  specialNeedPassengers?: InputMaybe<SpecialNeedPassengerListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SpecialNeedWhereUniqueInput = {
  AND?: InputMaybe<Array<SpecialNeedWhereInput>>;
  NOT?: InputMaybe<Array<SpecialNeedWhereInput>>;
  OR?: InputMaybe<Array<SpecialNeedWhereInput>>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isDefinedByUser?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  specialNeedPassengers?: InputMaybe<SpecialNeedPassengerListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Sponsor = {
  __typename?: "Sponsor";
  _count: SponsorCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  cashOutRequests?: Maybe<Array<CashOutRequest>>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  passengers?: Maybe<Array<PassengerSponsor>>;
  schoolRegistration?: Maybe<Array<SchoolRegistration>>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type SponsorCount = {
  __typename?: "SponsorCount";
  cashOutRequests: Scalars["Int"]["output"];
  passengers: Scalars["Int"]["output"];
  schoolRegistration: Scalars["Int"]["output"];
};

export type SponsorCreateInput = {
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutSponsorInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengers?: InputMaybe<PassengerSponsorCreateNestedManyWithoutSponsorInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutSponsorInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutSponsorInput;
};

export type SponsorCreateNestedOneWithoutCashOutRequestsInput = {
  connect?: InputMaybe<SponsorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SponsorCreateOrConnectWithoutCashOutRequestsInput>;
  create?: InputMaybe<SponsorCreateWithoutCashOutRequestsInput>;
};

export type SponsorCreateNestedOneWithoutCashOutRequestsInputAndWithoutCreate =
  {
    connect: SponsorWhereUniqueInput;
  };

export type SponsorCreateNestedOneWithoutPassengersInput = {
  connect?: InputMaybe<SponsorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SponsorCreateOrConnectWithoutPassengersInput>;
  create?: InputMaybe<SponsorCreateWithoutPassengersInput>;
};

export type SponsorCreateNestedOneWithoutSchoolRegistrationInput = {
  connect?: InputMaybe<SponsorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SponsorCreateOrConnectWithoutSchoolRegistrationInput>;
  create?: InputMaybe<SponsorCreateWithoutSchoolRegistrationInput>;
};

export type SponsorCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<SponsorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SponsorCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<SponsorCreateWithoutUserInput>;
};

export type SponsorCreateOrConnectWithoutCashOutRequestsInput = {
  create: SponsorCreateWithoutCashOutRequestsInput;
  where: SponsorWhereUniqueInput;
};

export type SponsorCreateOrConnectWithoutPassengersInput = {
  create: SponsorCreateWithoutPassengersInput;
  where: SponsorWhereUniqueInput;
};

export type SponsorCreateOrConnectWithoutSchoolRegistrationInput = {
  create: SponsorCreateWithoutSchoolRegistrationInput;
  where: SponsorWhereUniqueInput;
};

export type SponsorCreateOrConnectWithoutUserInput = {
  create: SponsorCreateWithoutUserInput;
  where: SponsorWhereUniqueInput;
};

export type SponsorCreateWithoutCashOutRequestsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengers?: InputMaybe<PassengerSponsorCreateNestedManyWithoutSponsorInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutSponsorInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutSponsorInput;
};

export type SponsorCreateWithoutPassengersInput = {
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutSponsorInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutSponsorInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutSponsorInput;
};

export type SponsorCreateWithoutSchoolRegistrationInput = {
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutSponsorInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengers?: InputMaybe<PassengerSponsorCreateNestedManyWithoutSponsorInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutSponsorInput;
};

export type SponsorCreateWithoutUserInput = {
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutSponsorInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengers?: InputMaybe<PassengerSponsorCreateNestedManyWithoutSponsorInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutSponsorInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SponsorForBusAttendant = {
  __typename?: "SponsorForBusAttendant";
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type SponsorForDriver = {
  __typename?: "SponsorForDriver";
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type SponsorInfoExtraInfo = {
  __typename?: "SponsorInfoExtraInfo";
  sponsorName?: Maybe<Scalars["String"]["output"]>;
  sponsorNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type SponsorProfile = {
  __typename?: "SponsorProfile";
  altId: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  countryId: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  primaryLanguage?: Maybe<Language>;
};

export type SponsorRelationFilter = {
  is?: InputMaybe<SponsorWhereInput>;
  isNot?: InputMaybe<SponsorWhereInput>;
};

export type SponsorUpdateInput = {
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutSponsorNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengers?: InputMaybe<PassengerSponsorUpdateManyWithoutSponsorNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutSponsorNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSponsorNestedInput>;
};

export type SponsorUpdateOneRequiredWithoutCashOutRequestsNestedInput = {
  connect?: InputMaybe<SponsorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SponsorCreateOrConnectWithoutCashOutRequestsInput>;
  create?: InputMaybe<SponsorCreateWithoutCashOutRequestsInput>;
  update?: InputMaybe<SponsorUpdateToOneWithWhereWithoutCashOutRequestsInput>;
  upsert?: InputMaybe<SponsorUpsertWithoutCashOutRequestsInput>;
};

export type SponsorUpdateOneRequiredWithoutPassengersNestedInput = {
  connect?: InputMaybe<SponsorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SponsorCreateOrConnectWithoutPassengersInput>;
  create?: InputMaybe<SponsorCreateWithoutPassengersInput>;
  update?: InputMaybe<SponsorUpdateToOneWithWhereWithoutPassengersInput>;
  upsert?: InputMaybe<SponsorUpsertWithoutPassengersInput>;
};

export type SponsorUpdateOneRequiredWithoutSchoolRegistrationNestedInput = {
  connect?: InputMaybe<SponsorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SponsorCreateOrConnectWithoutSchoolRegistrationInput>;
  create?: InputMaybe<SponsorCreateWithoutSchoolRegistrationInput>;
  update?: InputMaybe<SponsorUpdateToOneWithWhereWithoutSchoolRegistrationInput>;
  upsert?: InputMaybe<SponsorUpsertWithoutSchoolRegistrationInput>;
};

export type SponsorUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<SponsorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SponsorCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<SponsorCreateWithoutUserInput>;
  delete?: InputMaybe<SponsorWhereInput>;
  disconnect?: InputMaybe<SponsorWhereInput>;
  update?: InputMaybe<SponsorUpdateToOneWithWhereWithoutUserInput>;
  upsert?: InputMaybe<SponsorUpsertWithoutUserInput>;
};

export type SponsorUpdateToOneWithWhereWithoutCashOutRequestsInput = {
  data: SponsorUpdateWithoutCashOutRequestsInput;
  where?: InputMaybe<SponsorWhereInput>;
};

export type SponsorUpdateToOneWithWhereWithoutPassengersInput = {
  data: SponsorUpdateWithoutPassengersInput;
  where?: InputMaybe<SponsorWhereInput>;
};

export type SponsorUpdateToOneWithWhereWithoutSchoolRegistrationInput = {
  data: SponsorUpdateWithoutSchoolRegistrationInput;
  where?: InputMaybe<SponsorWhereInput>;
};

export type SponsorUpdateToOneWithWhereWithoutUserInput = {
  data: SponsorUpdateWithoutUserInput;
  where?: InputMaybe<SponsorWhereInput>;
};

export type SponsorUpdateWithoutCashOutRequestsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengers?: InputMaybe<PassengerSponsorUpdateManyWithoutSponsorNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutSponsorNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSponsorNestedInput>;
};

export type SponsorUpdateWithoutPassengersInput = {
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutSponsorNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutSponsorNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSponsorNestedInput>;
};

export type SponsorUpdateWithoutSchoolRegistrationInput = {
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutSponsorNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengers?: InputMaybe<PassengerSponsorUpdateManyWithoutSponsorNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSponsorNestedInput>;
};

export type SponsorUpdateWithoutUserInput = {
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutSponsorNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengers?: InputMaybe<PassengerSponsorUpdateManyWithoutSponsorNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutSponsorNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SponsorUpsertInput = {
  create?: InputMaybe<SponsorCreateInput>;
  update?: InputMaybe<SponsorUpdateInput>;
  where?: InputMaybe<SponsorWhereUniqueInput>;
};

export type SponsorUpsertWithoutCashOutRequestsInput = {
  create: SponsorCreateWithoutCashOutRequestsInput;
  update: SponsorUpdateWithoutCashOutRequestsInput;
  where?: InputMaybe<SponsorWhereInput>;
};

export type SponsorUpsertWithoutPassengersInput = {
  create: SponsorCreateWithoutPassengersInput;
  update: SponsorUpdateWithoutPassengersInput;
  where?: InputMaybe<SponsorWhereInput>;
};

export type SponsorUpsertWithoutSchoolRegistrationInput = {
  create: SponsorCreateWithoutSchoolRegistrationInput;
  update: SponsorUpdateWithoutSchoolRegistrationInput;
  where?: InputMaybe<SponsorWhereInput>;
};

export type SponsorUpsertWithoutUserInput = {
  create: SponsorCreateWithoutUserInput;
  update: SponsorUpdateWithoutUserInput;
  where?: InputMaybe<SponsorWhereInput>;
};

export type SponsorWhereInput = {
  AND?: InputMaybe<Array<SponsorWhereInput>>;
  NOT?: InputMaybe<Array<SponsorWhereInput>>;
  OR?: InputMaybe<Array<SponsorWhereInput>>;
  cashOutRequests?: InputMaybe<CashOutRequestListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  passengers?: InputMaybe<PassengerSponsorListRelationFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SponsorWhereUniqueInput = {
  AND?: InputMaybe<Array<SponsorWhereInput>>;
  NOT?: InputMaybe<Array<SponsorWhereInput>>;
  OR?: InputMaybe<Array<SponsorWhereInput>>;
  cashOutRequests?: InputMaybe<CashOutRequestListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  passengers?: InputMaybe<PassengerSponsorListRelationFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type SponsorWithAvailableCreditNoteAmount = {
  __typename?: "SponsorWithAvailableCreditNoteAmount";
  _count: SponsorCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  cashOutRequests?: Maybe<Array<CashOutRequest>>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  passengers?: Maybe<Array<PassengerSponsor>>;
  schoolRegistration?: Maybe<Array<SchoolRegistration>>;
  updatedAt: Scalars["DateTime"]["output"];
  user: UserGetSchoolRegistrationWithAvailableCreditNote;
  userId: Scalars["String"]["output"];
};

export type SponsorWithWithReferenceNumberAndStudents = {
  __typename?: "SponsorWithWithReferenceNumberAndStudents";
  _count: SponsorCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  cashOutRequests?: Maybe<Array<CashOutRequest>>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  passengers?: Maybe<Array<PassengerSponsor>>;
  schoolRegistration?: Maybe<Array<SchoolRegistration>>;
  updatedAt: Scalars["DateTime"]["output"];
  user: UserWithWithReferenceNumberAndStudents;
  userId: Scalars["String"]["output"];
};

export enum Status {
  ARCHIVED = "ARCHIVED",
  DRAFT = "DRAFT",
  EXPIRED = "EXPIRED",
  PUBLISHED = "PUBLISHED",
  SCHEDULED = "SCHEDULED",
  UNPUBLISHED = "UNPUBLISHED",
}

export type Stop = {
  __typename?: "Stop";
  _count: StopCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isPrimary: Scalars["Boolean"]["output"];
  lat: Scalars["Float"]["output"];
  locationStatus: StopLocationStatus;
  lon: Scalars["Float"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  registrationId?: Maybe<Scalars["String"]["output"]>;
  routes?: Maybe<Array<RouteStop>>;
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars["String"]["output"]>;
  schoolRegistration?: Maybe<SchoolRegistration>;
  status: StopStatus;
  stopLocationSource: StopLocationSource;
  updatedAt: Scalars["DateTime"]["output"];
};

export type StopCount = {
  __typename?: "StopCount";
  routes: Scalars["Int"]["output"];
};

export type StopCreateNestedOneWithoutRoutesInput = {
  connect?: InputMaybe<StopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StopCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<StopCreateWithoutRoutesInput>;
};

export type StopCreateOrConnectWithoutRoutesInput = {
  create: StopCreateWithoutRoutesInput;
  where: StopWhereUniqueInput;
};

export type StopCreateWithoutRoutesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat: Scalars["Float"]["input"];
  locationStatus?: InputMaybe<StopLocationStatus>;
  lon: Scalars["Float"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutStopInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedOneWithoutStopsInput>;
  status?: InputMaybe<StopStatus>;
  stopLocationSource?: InputMaybe<StopLocationSource>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StopForBaBd = {
  __typename?: "StopForBaBd";
  arrivalTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  lat: Scalars["Float"]["output"];
  lon: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  plannedLat?: Maybe<Scalars["Float"]["output"]>;
  plannedLon?: Maybe<Scalars["Float"]["output"]>;
  students?: Maybe<Array<StudentForBaBd>>;
  type: TripManifestStopType;
};

export enum StopLocationSource {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum StopLocationStatus {
  KNOWN = "KNOWN",
  UNKNOWN = "UNKNOWN",
}

export type StopRelationFilter = {
  is?: InputMaybe<StopWhereInput>;
  isNot?: InputMaybe<StopWhereInput>;
};

export enum StopStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum StopType {
  HOME = "HOME",
  SCHOOL = "SCHOOL",
}

export type StopUpdateOneWithoutRoutesNestedInput = {
  connect?: InputMaybe<StopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StopCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<StopCreateWithoutRoutesInput>;
  delete?: InputMaybe<StopWhereInput>;
  disconnect?: InputMaybe<StopWhereInput>;
  update?: InputMaybe<StopUpdateToOneWithWhereWithoutRoutesInput>;
  upsert?: InputMaybe<StopUpsertWithoutRoutesInput>;
};

export type StopUpdateToOneWithWhereWithoutRoutesInput = {
  data: StopUpdateWithoutRoutesInput;
  where?: InputMaybe<StopWhereInput>;
};

export type StopUpdateWithoutRoutesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  locationStatus?: InputMaybe<StopLocationStatus>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<SchoolUpdateOneWithoutStopNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneWithoutStopsNestedInput>;
  status?: InputMaybe<StopStatus>;
  stopLocationSource?: InputMaybe<StopLocationSource>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StopUpsertWithoutRoutesInput = {
  create: StopCreateWithoutRoutesInput;
  update: StopUpdateWithoutRoutesInput;
  where?: InputMaybe<StopWhereInput>;
};

export type StopWhereInput = {
  AND?: InputMaybe<Array<StopWhereInput>>;
  NOT?: InputMaybe<Array<StopWhereInput>>;
  OR?: InputMaybe<Array<StopWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isPrimary?: InputMaybe<BoolFilter>;
  lat?: InputMaybe<FloatFilter>;
  locationStatus?: InputMaybe<EnumStopLocationStatusFilter>;
  lon?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  registrationId?: InputMaybe<StringFilter>;
  routes?: InputMaybe<RouteStopListRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<StringFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  status?: InputMaybe<EnumStopStatusFilter>;
  stopLocationSource?: InputMaybe<EnumStopLocationSourceFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StopWhereUniqueInput = {
  AND?: InputMaybe<Array<StopWhereInput>>;
  NOT?: InputMaybe<Array<StopWhereInput>>;
  OR?: InputMaybe<Array<StopWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<BoolFilter>;
  lat?: InputMaybe<FloatFilter>;
  locationStatus?: InputMaybe<EnumStopLocationStatusFilter>;
  lon?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  registrationId?: InputMaybe<StringFilter>;
  routes?: InputMaybe<RouteStopListRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  status?: InputMaybe<EnumStopStatusFilter>;
  stopLocationSource?: InputMaybe<EnumStopLocationSourceFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<StringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringListFilter = {
  equals?: InputMaybe<Array<Scalars["String"]["input"]>>;
  has?: InputMaybe<Scalars["String"]["input"]>;
  hasEvery?: InputMaybe<Array<Scalars["String"]["input"]>>;
  hasSome?: InputMaybe<Array<Scalars["String"]["input"]>>;
  isEmpty?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Student = {
  __typename?: "Student";
  _count: StudentCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  code: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  noPickUpAlerts?: Maybe<Array<NoPickUpAlert>>;
  passenger?: Maybe<Passenger>;
  passengerId: Scalars["String"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  scheduleGroups?: Maybe<Array<ScheduleGroupStudent>>;
  schoolRegistration?: Maybe<Array<SchoolRegistration>>;
  schools?: Maybe<Array<SchoolStudent>>;
  updatedAt: Scalars["DateTime"]["output"];
  /** //////////////////////////// */
  wrongDropOffLocations?: Maybe<Array<WrongDropOffLocation>>;
};

export type StudentCount = {
  __typename?: "StudentCount";
  noPickUpAlerts: Scalars["Int"]["output"];
  scheduleGroups: Scalars["Int"]["output"];
  schoolRegistration: Scalars["Int"]["output"];
  schools: Scalars["Int"]["output"];
  wrongDropOffLocations: Scalars["Int"]["output"];
};

export type StudentCreateInput = {
  code?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutStudentInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutStudentInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutStudentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutStudentInput>;
  schools?: InputMaybe<SchoolStudentCreateNestedManyWithoutStudentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateNestedOneWithoutNoPickUpAlertsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutNoPickUpAlertsInput>;
  create?: InputMaybe<StudentCreateWithoutNoPickUpAlertsInput>;
};

export type StudentCreateNestedOneWithoutPassengerInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutPassengerInput>;
  create?: InputMaybe<StudentCreateWithoutPassengerInput>;
};

export type StudentCreateNestedOneWithoutScheduleGroupsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutScheduleGroupsInput>;
  create?: InputMaybe<StudentCreateWithoutScheduleGroupsInput>;
};

export type StudentCreateNestedOneWithoutSchoolRegistrationInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutSchoolRegistrationInput>;
  create?: InputMaybe<StudentCreateWithoutSchoolRegistrationInput>;
};

export type StudentCreateNestedOneWithoutSchoolsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutSchoolsInput>;
  create?: InputMaybe<StudentCreateWithoutSchoolsInput>;
};

export type StudentCreateNestedOneWithoutWrongDropOffLocationsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutWrongDropOffLocationsInput>;
  create?: InputMaybe<StudentCreateWithoutWrongDropOffLocationsInput>;
};

export type StudentCreateOrConnectWithoutNoPickUpAlertsInput = {
  create: StudentCreateWithoutNoPickUpAlertsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutPassengerInput = {
  create: StudentCreateWithoutPassengerInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutScheduleGroupsInput = {
  create: StudentCreateWithoutScheduleGroupsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutSchoolRegistrationInput = {
  create: StudentCreateWithoutSchoolRegistrationInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutSchoolsInput = {
  create: StudentCreateWithoutSchoolsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutWrongDropOffLocationsInput = {
  create: StudentCreateWithoutWrongDropOffLocationsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateUpdateNestedOneWithoutSchoolRegistrationInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutSchoolRegistrationInput>;
  create?: InputMaybe<StudentCreateWithoutSchoolRegistrationInput>;
  update?: InputMaybe<StudentUpdateToOneWithoutSchoolRegistrationInput>;
};

export type StudentCreateWithoutNoPickUpAlertsInput = {
  code?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutStudentInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutStudentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutStudentInput>;
  schools?: InputMaybe<SchoolStudentCreateNestedManyWithoutStudentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutPassengerInput = {
  code?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutStudentInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutStudentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutStudentInput>;
  schools?: InputMaybe<SchoolStudentCreateNestedManyWithoutStudentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutScheduleGroupsInput = {
  code?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutStudentInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutStudentInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutStudentInput>;
  schools?: InputMaybe<SchoolStudentCreateNestedManyWithoutStudentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutSchoolRegistrationInput = {
  code?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutStudentInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutStudentInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutStudentInput>;
  schools?: InputMaybe<SchoolStudentCreateNestedManyWithoutStudentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutSchoolsInput = {
  code?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutStudentInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutStudentInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutStudentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutStudentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutWrongDropOffLocationsInput = {
  code?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutStudentInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutStudentInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentCreateNestedManyWithoutStudentInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationCreateNestedManyWithoutStudentInput>;
  schools?: InputMaybe<SchoolStudentCreateNestedManyWithoutStudentInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StudentForBaBd = {
  __typename?: "StudentForBaBd";
  bleStatus?: Maybe<BleStatus>;
  cardStatus: StudentTripStopStatus;
  className?: Maybe<Scalars["String"]["output"]>;
  confirmedAttendanceStatus: AttendanceStatus;
  dropOffTime?: Maybe<Scalars["DateTime"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  homeLocation?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isOnLeave?: Maybe<Scalars["Boolean"]["output"]>;
  isStopLocationUnknown?: Maybe<Scalars["Boolean"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  lat?: Maybe<Scalars["Float"]["output"]>;
  lon?: Maybe<Scalars["Float"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  middleNameAr?: Maybe<Scalars["String"]["output"]>;
  parents?: Maybe<Array<StudentParentForBaBd>>;
  phone?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  pickUpTime?: Maybe<Scalars["DateTime"]["output"]>;
  schoolDetails?: Maybe<StudentSchoolForBaBd>;
  schoolTripStopId: Scalars["ID"]["output"];
  tripStopId: Scalars["ID"]["output"];
};

export type StudentForBusAttendant = {
  __typename?: "StudentForBusAttendant";
  addresses?: Maybe<Array<Address>>;
  className: Scalars["String"]["output"];
  classNameAr: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type StudentForDriver = {
  __typename?: "StudentForDriver";
  addresses?: Maybe<Array<Address>>;
  className: Scalars["String"]["output"];
  classNameAr: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type StudentForSponsor = {
  __typename?: "StudentForSponsor";
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  lat: Scalars["Float"]["output"];
  lon: Scalars["Float"]["output"];
  photo?: Maybe<Scalars["String"]["output"]>;
};

export type StudentLocation = {
  __typename?: "StudentLocation";
  homeLocation: LatLon;
  tagId: Scalars["String"]["output"];
};

export type StudentNameLists = {
  __typename?: "StudentNameLists";
  studentNamesAr: Array<Scalars["String"]["output"]>;
  studentNamesEn: Array<Scalars["String"]["output"]>;
};

export type StudentParentForBaBd = {
  __typename?: "StudentParentForBaBd";
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isPrimary: Scalars["Boolean"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type StudentRelationFilter = {
  is?: InputMaybe<StudentWhereInput>;
  isNot?: InputMaybe<StudentWhereInput>;
};

export type StudentSchoolForBaBd = {
  __typename?: "StudentSchoolForBaBd";
  id: Scalars["ID"]["output"];
  schoolName?: Maybe<Scalars["String"]["output"]>;
  schoolNameAr?: Maybe<Scalars["String"]["output"]>;
};

export type StudentStopLog = {
  __typename?: "StudentStopLog";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  lat?: Maybe<Scalars["Float"]["output"]>;
  lon?: Maybe<Scalars["Float"]["output"]>;
  newActualArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  newConfirmedAttendanceStatus?: Maybe<AttendanceStatus>;
  newConfirmedInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  newConfirmedNoShowTime?: Maybe<Scalars["DateTime"]["output"]>;
  newDetectedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  newTripStopStatus?: Maybe<StudentTripStopStatus>;
  newUnknownDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  oldActualArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  oldConfirmedAttendanceStatus?: Maybe<AttendanceStatus>;
  oldConfirmedInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  oldConfirmedNoShowTime?: Maybe<Scalars["DateTime"]["output"]>;
  oldDetectedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  oldTripStopStatus?: Maybe<StudentTripStopStatus>;
  oldUnknownDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  source: TripStopUpdateSource;
  tripStop: TripStop;
  tripStopId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type StudentStopLogCreateManyTripStopInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  newActualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newConfirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  newConfirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newConfirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDetectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  newUnknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldActualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldConfirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  oldConfirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldConfirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldDetectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  oldUnknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  source?: InputMaybe<TripStopUpdateSource>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StudentStopLogCreateManyTripStopInputEnvelope = {
  data: Array<StudentStopLogCreateManyTripStopInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentStopLogCreateNestedManyWithoutTripStopInput = {
  connect?: InputMaybe<Array<StudentStopLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<StudentStopLogCreateOrConnectWithoutTripStopInput>
  >;
  create?: InputMaybe<Array<StudentStopLogCreateWithoutTripStopInput>>;
  createMany?: InputMaybe<StudentStopLogCreateManyTripStopInputEnvelope>;
};

export type StudentStopLogCreateOrConnectWithoutTripStopInput = {
  create: StudentStopLogCreateWithoutTripStopInput;
  where: StudentStopLogWhereUniqueInput;
};

export type StudentStopLogCreateWithoutTripStopInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  newActualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newConfirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  newConfirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newConfirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDetectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  newUnknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldActualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldConfirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  oldConfirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldConfirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldDetectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  oldUnknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  source?: InputMaybe<TripStopUpdateSource>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StudentStopLogListRelationFilter = {
  every?: InputMaybe<StudentStopLogWhereInput>;
  none?: InputMaybe<StudentStopLogWhereInput>;
  some?: InputMaybe<StudentStopLogWhereInput>;
};

export type StudentStopLogScalarWhereInput = {
  AND?: InputMaybe<Array<StudentStopLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<StudentStopLogScalarWhereInput>>;
  OR?: InputMaybe<Array<StudentStopLogScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  lat?: InputMaybe<FloatFilter>;
  lon?: InputMaybe<FloatFilter>;
  newActualArrivalTime?: InputMaybe<DateTimeFilter>;
  newConfirmedAttendanceStatus?: InputMaybe<EnumAttendanceStatusFilter>;
  newConfirmedInTransitTime?: InputMaybe<DateTimeFilter>;
  newConfirmedNoShowTime?: InputMaybe<DateTimeFilter>;
  newDetectedArrivalTime?: InputMaybe<DateTimeFilter>;
  newTripStopStatus?: InputMaybe<EnumStudentTripStopStatusFilter>;
  newUnknownDropoffTime?: InputMaybe<DateTimeFilter>;
  oldActualArrivalTime?: InputMaybe<DateTimeFilter>;
  oldConfirmedAttendanceStatus?: InputMaybe<EnumAttendanceStatusFilter>;
  oldConfirmedInTransitTime?: InputMaybe<DateTimeFilter>;
  oldConfirmedNoShowTime?: InputMaybe<DateTimeFilter>;
  oldDetectedArrivalTime?: InputMaybe<DateTimeFilter>;
  oldTripStopStatus?: InputMaybe<EnumStudentTripStopStatusFilter>;
  oldUnknownDropoffTime?: InputMaybe<DateTimeFilter>;
  source?: InputMaybe<EnumTripStopUpdateSourceFilter>;
  tripStopId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StudentStopLogUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  newActualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newConfirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  newConfirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newConfirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDetectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  newUnknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldActualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldConfirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  oldConfirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldConfirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldDetectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  oldUnknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  source?: InputMaybe<TripStopUpdateSource>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StudentStopLogUpdateManyWithWhereWithoutTripStopInput = {
  data: StudentStopLogUpdateManyMutationInput;
  where: StudentStopLogScalarWhereInput;
};

export type StudentStopLogUpdateManyWithoutTripStopNestedInput = {
  connect?: InputMaybe<Array<StudentStopLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<StudentStopLogCreateOrConnectWithoutTripStopInput>
  >;
  create?: InputMaybe<Array<StudentStopLogCreateWithoutTripStopInput>>;
  createMany?: InputMaybe<StudentStopLogCreateManyTripStopInputEnvelope>;
  delete?: InputMaybe<Array<StudentStopLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentStopLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentStopLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentStopLogWhereUniqueInput>>;
  update?: InputMaybe<
    Array<StudentStopLogUpdateWithWhereUniqueWithoutTripStopInput>
  >;
  updateMany?: InputMaybe<
    Array<StudentStopLogUpdateManyWithWhereWithoutTripStopInput>
  >;
  upsert?: InputMaybe<
    Array<StudentStopLogUpsertWithWhereUniqueWithoutTripStopInput>
  >;
};

export type StudentStopLogUpdateWithWhereUniqueWithoutTripStopInput = {
  data: StudentStopLogUpdateWithoutTripStopInput;
  where: StudentStopLogWhereUniqueInput;
};

export type StudentStopLogUpdateWithoutTripStopInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  newActualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newConfirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  newConfirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newConfirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newDetectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  newTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  newUnknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldActualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldConfirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  oldConfirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldConfirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldDetectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  oldTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  oldUnknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  source?: InputMaybe<TripStopUpdateSource>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StudentStopLogUpsertWithWhereUniqueWithoutTripStopInput = {
  create: StudentStopLogCreateWithoutTripStopInput;
  update: StudentStopLogUpdateWithoutTripStopInput;
  where: StudentStopLogWhereUniqueInput;
};

export type StudentStopLogWhereInput = {
  AND?: InputMaybe<Array<StudentStopLogWhereInput>>;
  NOT?: InputMaybe<Array<StudentStopLogWhereInput>>;
  OR?: InputMaybe<Array<StudentStopLogWhereInput>>;
  id?: InputMaybe<StringFilter>;
  lat?: InputMaybe<FloatFilter>;
  lon?: InputMaybe<FloatFilter>;
  newActualArrivalTime?: InputMaybe<DateTimeFilter>;
  newConfirmedAttendanceStatus?: InputMaybe<EnumAttendanceStatusFilter>;
  newConfirmedInTransitTime?: InputMaybe<DateTimeFilter>;
  newConfirmedNoShowTime?: InputMaybe<DateTimeFilter>;
  newDetectedArrivalTime?: InputMaybe<DateTimeFilter>;
  newTripStopStatus?: InputMaybe<EnumStudentTripStopStatusFilter>;
  newUnknownDropoffTime?: InputMaybe<DateTimeFilter>;
  oldActualArrivalTime?: InputMaybe<DateTimeFilter>;
  oldConfirmedAttendanceStatus?: InputMaybe<EnumAttendanceStatusFilter>;
  oldConfirmedInTransitTime?: InputMaybe<DateTimeFilter>;
  oldConfirmedNoShowTime?: InputMaybe<DateTimeFilter>;
  oldDetectedArrivalTime?: InputMaybe<DateTimeFilter>;
  oldTripStopStatus?: InputMaybe<EnumStudentTripStopStatusFilter>;
  oldUnknownDropoffTime?: InputMaybe<DateTimeFilter>;
  source?: InputMaybe<EnumTripStopUpdateSourceFilter>;
  tripStop?: InputMaybe<TripStopRelationFilter>;
  tripStopId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StudentStopLogWhereUniqueInput = {
  AND?: InputMaybe<Array<StudentStopLogWhereInput>>;
  NOT?: InputMaybe<Array<StudentStopLogWhereInput>>;
  OR?: InputMaybe<Array<StudentStopLogWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<FloatFilter>;
  lon?: InputMaybe<FloatFilter>;
  newActualArrivalTime?: InputMaybe<DateTimeFilter>;
  newConfirmedAttendanceStatus?: InputMaybe<EnumAttendanceStatusFilter>;
  newConfirmedInTransitTime?: InputMaybe<DateTimeFilter>;
  newConfirmedNoShowTime?: InputMaybe<DateTimeFilter>;
  newDetectedArrivalTime?: InputMaybe<DateTimeFilter>;
  newTripStopStatus?: InputMaybe<EnumStudentTripStopStatusFilter>;
  newUnknownDropoffTime?: InputMaybe<DateTimeFilter>;
  oldActualArrivalTime?: InputMaybe<DateTimeFilter>;
  oldConfirmedAttendanceStatus?: InputMaybe<EnumAttendanceStatusFilter>;
  oldConfirmedInTransitTime?: InputMaybe<DateTimeFilter>;
  oldConfirmedNoShowTime?: InputMaybe<DateTimeFilter>;
  oldDetectedArrivalTime?: InputMaybe<DateTimeFilter>;
  oldTripStopStatus?: InputMaybe<EnumStudentTripStopStatusFilter>;
  oldUnknownDropoffTime?: InputMaybe<DateTimeFilter>;
  source?: InputMaybe<EnumTripStopUpdateSourceFilter>;
  tripStop?: InputMaybe<TripStopRelationFilter>;
  tripStopId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StudentTag = {
  studentId: Scalars["String"]["input"];
  tagId: Scalars["String"]["input"];
};

export enum StudentTripStopStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  INVALID = "INVALID",
  IN_TRANSIT = "IN_TRANSIT",
  MISSED = "MISSED",
  NEARBY_DROPOFF = "NEARBY_DROPOFF",
  NEARBY_PICKUP = "NEARBY_PICKUP",
  NO_PICKUP = "NO_PICKUP",
  PARTIAL_COMPLETED = "PARTIAL_COMPLETED",
  PENDING = "PENDING",
  STAND_BY = "STAND_BY",
  VISITED = "VISITED",
}

export type StudentUpdateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutStudentNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutStudentNestedInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutStudentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutStudentNestedInput>;
  schools?: InputMaybe<SchoolStudentUpdateManyWithoutStudentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateOneRequiredWithoutNoPickUpAlertsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutNoPickUpAlertsInput>;
  create?: InputMaybe<StudentCreateWithoutNoPickUpAlertsInput>;
  update?: InputMaybe<StudentUpdateToOneWithWhereWithoutNoPickUpAlertsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutNoPickUpAlertsInput>;
};

export type StudentUpdateOneRequiredWithoutScheduleGroupsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutScheduleGroupsInput>;
  create?: InputMaybe<StudentCreateWithoutScheduleGroupsInput>;
  update?: InputMaybe<StudentUpdateToOneWithWhereWithoutScheduleGroupsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutScheduleGroupsInput>;
};

export type StudentUpdateOneRequiredWithoutSchoolRegistrationNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutSchoolRegistrationInput>;
  create?: InputMaybe<StudentCreateWithoutSchoolRegistrationInput>;
  update?: InputMaybe<StudentUpdateToOneWithWhereWithoutSchoolRegistrationInput>;
  upsert?: InputMaybe<StudentUpsertWithoutSchoolRegistrationInput>;
};

export type StudentUpdateOneRequiredWithoutSchoolsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutSchoolsInput>;
  create?: InputMaybe<StudentCreateWithoutSchoolsInput>;
  update?: InputMaybe<StudentUpdateToOneWithWhereWithoutSchoolsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutSchoolsInput>;
};

export type StudentUpdateOneRequiredWithoutWrongDropOffLocationsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutWrongDropOffLocationsInput>;
  create?: InputMaybe<StudentCreateWithoutWrongDropOffLocationsInput>;
  update?: InputMaybe<StudentUpdateToOneWithWhereWithoutWrongDropOffLocationsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutWrongDropOffLocationsInput>;
};

export type StudentUpdateOneWithoutPassengerNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutPassengerInput>;
  create?: InputMaybe<StudentCreateWithoutPassengerInput>;
  delete?: InputMaybe<StudentWhereInput>;
  disconnect?: InputMaybe<StudentWhereInput>;
  update?: InputMaybe<StudentUpdateToOneWithWhereWithoutPassengerInput>;
  upsert?: InputMaybe<StudentUpsertWithoutPassengerInput>;
};

export type StudentUpdateToOneWithWhereWithoutNoPickUpAlertsInput = {
  data: StudentUpdateWithoutNoPickUpAlertsInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpdateToOneWithWhereWithoutPassengerInput = {
  data: StudentUpdateWithoutPassengerInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpdateToOneWithWhereWithoutScheduleGroupsInput = {
  data: StudentUpdateWithoutScheduleGroupsInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpdateToOneWithWhereWithoutSchoolRegistrationInput = {
  data: StudentUpdateWithoutSchoolRegistrationInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpdateToOneWithWhereWithoutSchoolsInput = {
  data: StudentUpdateWithoutSchoolsInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpdateToOneWithWhereWithoutWrongDropOffLocationsInput = {
  data: StudentUpdateWithoutWrongDropOffLocationsInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpdateToOneWithoutSchoolRegistrationInput = {
  data: StudentUpdateWithoutSchoolRegistrationWithIdInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpdateWithoutNoPickUpAlertsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutStudentNestedInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutStudentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutStudentNestedInput>;
  schools?: InputMaybe<SchoolStudentUpdateManyWithoutStudentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutPassengerInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutStudentNestedInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutStudentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutStudentNestedInput>;
  schools?: InputMaybe<SchoolStudentUpdateManyWithoutStudentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutScheduleGroupsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutStudentNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutStudentNestedInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutStudentNestedInput>;
  schools?: InputMaybe<SchoolStudentUpdateManyWithoutStudentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutSchoolRegistrationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutStudentNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutStudentNestedInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutStudentNestedInput>;
  schools?: InputMaybe<SchoolStudentUpdateManyWithoutStudentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutSchoolRegistrationWithIdInput = {
  id: Scalars["String"]["input"];
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutStudentNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutStudentNestedInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutStudentNestedInput>;
  schools?: InputMaybe<SchoolStudentUpdateManyWithoutStudentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutSchoolsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutStudentNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutStudentNestedInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutStudentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutStudentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutWrongDropOffLocationsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutStudentNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutStudentNestedInput>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentUpdateManyWithoutStudentNestedInput>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateManyWithoutStudentNestedInput>;
  schools?: InputMaybe<SchoolStudentUpdateManyWithoutStudentNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StudentUpsertWithoutNoPickUpAlertsInput = {
  create: StudentCreateWithoutNoPickUpAlertsInput;
  update: StudentUpdateWithoutNoPickUpAlertsInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpsertWithoutPassengerInput = {
  create: StudentCreateWithoutPassengerInput;
  update: StudentUpdateWithoutPassengerInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpsertWithoutScheduleGroupsInput = {
  create: StudentCreateWithoutScheduleGroupsInput;
  update: StudentUpdateWithoutScheduleGroupsInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpsertWithoutSchoolRegistrationInput = {
  create: StudentCreateWithoutSchoolRegistrationInput;
  update: StudentUpdateWithoutSchoolRegistrationInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpsertWithoutSchoolsInput = {
  create: StudentCreateWithoutSchoolsInput;
  update: StudentUpdateWithoutSchoolsInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentUpsertWithoutWrongDropOffLocationsInput = {
  create: StudentCreateWithoutWrongDropOffLocationsInput;
  update: StudentUpdateWithoutWrongDropOffLocationsInput;
  where?: InputMaybe<StudentWhereInput>;
};

export type StudentWhereInput = {
  AND?: InputMaybe<Array<StudentWhereInput>>;
  NOT?: InputMaybe<Array<StudentWhereInput>>;
  OR?: InputMaybe<Array<StudentWhereInput>>;
  code?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertListRelationFilter>;
  passenger?: InputMaybe<PassengerRelationFilter>;
  passengerId?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringFilter>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentListRelationFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationListRelationFilter>;
  schools?: InputMaybe<SchoolStudentListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationListRelationFilter>;
};

export type StudentWhereUniqueInput = {
  AND?: InputMaybe<Array<StudentWhereInput>>;
  NOT?: InputMaybe<Array<StudentWhereInput>>;
  OR?: InputMaybe<Array<StudentWhereInput>>;
  code?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertListRelationFilter>;
  passenger?: InputMaybe<PassengerRelationFilter>;
  passengerId?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<StringFilter>;
  scheduleGroups?: InputMaybe<ScheduleGroupStudentListRelationFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationListRelationFilter>;
  schools?: InputMaybe<SchoolStudentListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationListRelationFilter>;
};

export type SubLocalitiesFilterInput = {
  administrativeAreaId?: InputMaybe<Scalars["String"]["input"]>;
  localityId?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Float"]["input"]>;
  take?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SubLocality = {
  __typename?: "SubLocality";
  _count: SubLocalityCount;
  address?: Maybe<Array<Address>>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  locality: Locality;
  localityId: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SubLocalityCount = {
  __typename?: "SubLocalityCount";
  address: Scalars["Int"]["output"];
};

export type SubLocalityCreateManyLocalityInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SubLocalityCreateManyLocalityInputEnvelope = {
  data: Array<SubLocalityCreateManyLocalityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SubLocalityCreateNestedManyWithoutLocalityInput = {
  connect?: InputMaybe<Array<SubLocalityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SubLocalityCreateOrConnectWithoutLocalityInput>
  >;
  create?: InputMaybe<Array<SubLocalityCreateWithoutLocalityInput>>;
  createMany?: InputMaybe<SubLocalityCreateManyLocalityInputEnvelope>;
};

export type SubLocalityCreateNestedOneWithoutAddressInput = {
  connect?: InputMaybe<SubLocalityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubLocalityCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<SubLocalityCreateWithoutAddressInput>;
};

export type SubLocalityCreateOrConnectWithoutAddressInput = {
  create: SubLocalityCreateWithoutAddressInput;
  where: SubLocalityWhereUniqueInput;
};

export type SubLocalityCreateOrConnectWithoutLocalityInput = {
  create: SubLocalityCreateWithoutLocalityInput;
  where: SubLocalityWhereUniqueInput;
};

export type SubLocalityCreateWithoutAddressInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  locality: LocalityCreateNestedOneWithoutSubLocalitiesInput;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SubLocalityCreateWithoutLocalityInput = {
  address?: InputMaybe<AddressCreateNestedManyWithoutSubLocalityInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SubLocalityListRelationFilter = {
  every?: InputMaybe<SubLocalityWhereInput>;
  none?: InputMaybe<SubLocalityWhereInput>;
  some?: InputMaybe<SubLocalityWhereInput>;
};

export type SubLocalityRelationFilter = {
  is?: InputMaybe<SubLocalityWhereInput>;
  isNot?: InputMaybe<SubLocalityWhereInput>;
};

export type SubLocalityResponsePayload = {
  __typename?: "SubLocalityResponsePayload";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
};

export type SubLocalityScalarWhereInput = {
  AND?: InputMaybe<Array<SubLocalityScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubLocalityScalarWhereInput>>;
  OR?: InputMaybe<Array<SubLocalityScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  localityId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubLocalityUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SubLocalityUpdateManyWithWhereWithoutLocalityInput = {
  data: SubLocalityUpdateManyMutationInput;
  where: SubLocalityScalarWhereInput;
};

export type SubLocalityUpdateManyWithoutLocalityNestedInput = {
  connect?: InputMaybe<Array<SubLocalityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SubLocalityCreateOrConnectWithoutLocalityInput>
  >;
  create?: InputMaybe<Array<SubLocalityCreateWithoutLocalityInput>>;
  createMany?: InputMaybe<SubLocalityCreateManyLocalityInputEnvelope>;
  delete?: InputMaybe<Array<SubLocalityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubLocalityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubLocalityWhereUniqueInput>>;
  set?: InputMaybe<Array<SubLocalityWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SubLocalityUpdateWithWhereUniqueWithoutLocalityInput>
  >;
  updateMany?: InputMaybe<
    Array<SubLocalityUpdateManyWithWhereWithoutLocalityInput>
  >;
  upsert?: InputMaybe<
    Array<SubLocalityUpsertWithWhereUniqueWithoutLocalityInput>
  >;
};

export type SubLocalityUpdateOneWithoutAddressNestedInput = {
  connect?: InputMaybe<SubLocalityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubLocalityCreateOrConnectWithoutAddressInput>;
  create?: InputMaybe<SubLocalityCreateWithoutAddressInput>;
  delete?: InputMaybe<SubLocalityWhereInput>;
  disconnect?: InputMaybe<SubLocalityWhereInput>;
  update?: InputMaybe<SubLocalityUpdateToOneWithWhereWithoutAddressInput>;
  upsert?: InputMaybe<SubLocalityUpsertWithoutAddressInput>;
};

export type SubLocalityUpdateToOneWithWhereWithoutAddressInput = {
  data: SubLocalityUpdateWithoutAddressInput;
  where?: InputMaybe<SubLocalityWhereInput>;
};

export type SubLocalityUpdateWithWhereUniqueWithoutLocalityInput = {
  data: SubLocalityUpdateWithoutLocalityInput;
  where: SubLocalityWhereUniqueInput;
};

export type SubLocalityUpdateWithoutAddressInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  locality?: InputMaybe<LocalityUpdateOneRequiredWithoutSubLocalitiesNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SubLocalityUpdateWithoutLocalityInput = {
  address?: InputMaybe<AddressUpdateManyWithoutSubLocalityNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SubLocalityUpsertWithWhereUniqueWithoutLocalityInput = {
  create: SubLocalityCreateWithoutLocalityInput;
  update: SubLocalityUpdateWithoutLocalityInput;
  where: SubLocalityWhereUniqueInput;
};

export type SubLocalityUpsertWithoutAddressInput = {
  create: SubLocalityCreateWithoutAddressInput;
  update: SubLocalityUpdateWithoutAddressInput;
  where?: InputMaybe<SubLocalityWhereInput>;
};

export type SubLocalityWhereInput = {
  AND?: InputMaybe<Array<SubLocalityWhereInput>>;
  NOT?: InputMaybe<Array<SubLocalityWhereInput>>;
  OR?: InputMaybe<Array<SubLocalityWhereInput>>;
  address?: InputMaybe<AddressListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  locality?: InputMaybe<LocalityRelationFilter>;
  localityId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubLocalityWhereUniqueInput = {
  AND?: InputMaybe<Array<SubLocalityWhereInput>>;
  NOT?: InputMaybe<Array<SubLocalityWhereInput>>;
  OR?: InputMaybe<Array<SubLocalityWhereInput>>;
  address?: InputMaybe<AddressListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locality?: InputMaybe<LocalityRelationFilter>;
  localityId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriberNotificationTopic = {
  __typename?: "SubscriberNotificationTopic";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  notificationTopic: NotificationTopic;
  notificationTopicId: Scalars["String"]["output"];
  subscriber: FcmSubscriber;
  subscriberId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SubscriberNotificationTopicCreateManySubscriberInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notificationTopicId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SubscriberNotificationTopicCreateManySubscriberInputEnvelope = {
  data: Array<SubscriberNotificationTopicCreateManySubscriberInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SubscriberNotificationTopicCreateNestedManyWithoutSubscriberInput =
  {
    connect?: InputMaybe<Array<SubscriberNotificationTopicWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<SubscriberNotificationTopicCreateOrConnectWithoutSubscriberInput>
    >;
    create?: InputMaybe<
      Array<SubscriberNotificationTopicCreateWithoutSubscriberInput>
    >;
    createMany?: InputMaybe<SubscriberNotificationTopicCreateManySubscriberInputEnvelope>;
  };

export type SubscriberNotificationTopicCreateOrConnectWithoutSubscriberInput = {
  create: SubscriberNotificationTopicCreateWithoutSubscriberInput;
  where: SubscriberNotificationTopicWhereUniqueInput;
};

export type SubscriberNotificationTopicCreateWithoutSubscriberInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notificationTopic: NotificationTopicCreateNestedOneWithoutSubscribersInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SubscriberNotificationTopicListRelationFilter = {
  every?: InputMaybe<SubscriberNotificationTopicWhereInput>;
  none?: InputMaybe<SubscriberNotificationTopicWhereInput>;
  some?: InputMaybe<SubscriberNotificationTopicWhereInput>;
};

export type SubscriberNotificationTopicScalarWhereInput = {
  AND?: InputMaybe<Array<SubscriberNotificationTopicScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubscriberNotificationTopicScalarWhereInput>>;
  OR?: InputMaybe<Array<SubscriberNotificationTopicScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  notificationTopicId?: InputMaybe<StringFilter>;
  subscriberId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriberNotificationTopicUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SubscriberNotificationTopicUpdateManyWithWhereWithoutSubscriberInput =
  {
    data: SubscriberNotificationTopicUpdateManyMutationInput;
    where: SubscriberNotificationTopicScalarWhereInput;
  };

export type SubscriberNotificationTopicUpdateManyWithoutSubscriberNestedInput =
  {
    connect?: InputMaybe<Array<SubscriberNotificationTopicWhereUniqueInput>>;
    connectOrCreate?: InputMaybe<
      Array<SubscriberNotificationTopicCreateOrConnectWithoutSubscriberInput>
    >;
    create?: InputMaybe<
      Array<SubscriberNotificationTopicCreateWithoutSubscriberInput>
    >;
    createMany?: InputMaybe<SubscriberNotificationTopicCreateManySubscriberInputEnvelope>;
    delete?: InputMaybe<Array<SubscriberNotificationTopicWhereUniqueInput>>;
    deleteMany?: InputMaybe<Array<SubscriberNotificationTopicScalarWhereInput>>;
    disconnect?: InputMaybe<Array<SubscriberNotificationTopicWhereUniqueInput>>;
    set?: InputMaybe<Array<SubscriberNotificationTopicWhereUniqueInput>>;
    update?: InputMaybe<
      Array<SubscriberNotificationTopicUpdateWithWhereUniqueWithoutSubscriberInput>
    >;
    updateMany?: InputMaybe<
      Array<SubscriberNotificationTopicUpdateManyWithWhereWithoutSubscriberInput>
    >;
    upsert?: InputMaybe<
      Array<SubscriberNotificationTopicUpsertWithWhereUniqueWithoutSubscriberInput>
    >;
  };

export type SubscriberNotificationTopicUpdateWithWhereUniqueWithoutSubscriberInput =
  {
    data: SubscriberNotificationTopicUpdateWithoutSubscriberInput;
    where: SubscriberNotificationTopicWhereUniqueInput;
  };

export type SubscriberNotificationTopicUpdateWithoutSubscriberInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  notificationTopic?: InputMaybe<NotificationTopicUpdateOneRequiredWithoutSubscribersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SubscriberNotificationTopicUpsertWithWhereUniqueWithoutSubscriberInput =
  {
    create: SubscriberNotificationTopicCreateWithoutSubscriberInput;
    update: SubscriberNotificationTopicUpdateWithoutSubscriberInput;
    where: SubscriberNotificationTopicWhereUniqueInput;
  };

export type SubscriberNotificationTopicWhereInput = {
  AND?: InputMaybe<Array<SubscriberNotificationTopicWhereInput>>;
  NOT?: InputMaybe<Array<SubscriberNotificationTopicWhereInput>>;
  OR?: InputMaybe<Array<SubscriberNotificationTopicWhereInput>>;
  id?: InputMaybe<StringFilter>;
  notificationTopic?: InputMaybe<NotificationTopicRelationFilter>;
  notificationTopicId?: InputMaybe<StringFilter>;
  subscriber?: InputMaybe<FcmSubscriberRelationFilter>;
  subscriberId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriberNotificationTopicWhereUniqueInput = {
  AND?: InputMaybe<Array<SubscriberNotificationTopicWhereInput>>;
  NOT?: InputMaybe<Array<SubscriberNotificationTopicWhereInput>>;
  OR?: InputMaybe<Array<SubscriberNotificationTopicWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  notificationTopic?: InputMaybe<NotificationTopicRelationFilter>;
  notificationTopicId?: InputMaybe<StringFilter>;
  subscriber?: InputMaybe<FcmSubscriberRelationFilter>;
  subscriberId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Subscription = {
  __typename?: "Subscription";
  adhocNotificationAdded: AdhocNotificationWithoutJsonTypes;
  agentAdded: Agent;
  agentArchived: Agent;
  agentUpdated: Agent;
  alertAdded: Alert;
  busAttendantAdded: BusAttendant;
  busAttendantArchived: BusAttendant;
  busAttendantHoursWorkedAdded: Array<BusAttendantHoursWorked>;
  busAttendantHoursWorkedUpdated: BusAttendantHoursWorked;
  busAttendantStatusHistoryAdded: Array<BusAttendantStatusHistory>;
  busAttendantStatusHistoryUpdated: BusAttendantStatusHistory;
  busAttendantUpdated: BusAttendant;
  busAttendantViolationComplianceAdded: Array<BusAttendantViolationCompliance>;
  busAttendantViolationComplianceUpdated: BusAttendantViolationCompliance;
  collectionPointAdded: CollectionPointResponse;
  collectionPointUpdated: CollectionPointResponse;
  customerAdded: GetCustomerManyOutput;
  customerUpdated: GetCustomerManyOutput;
  driverAdded: Driver;
  driverArchived: Driver;
  driverHoursWorkedAdded: Array<DriverHoursWorked>;
  driverHoursWorkedUpdated: DriverHoursWorked;
  driverStatusHistoryAdded: Array<DriverStatusHistory>;
  driverStatusHistoryUpdated: DriverStatusHistory;
  driverUpdated: Driver;
  driverViolationComplianceAdded: Array<DriverViolationCompliance>;
  driverViolationComplianceUpdated: DriverViolationCompliance;
  leaveRequestCreated: LeaveRequest;
  leaveRequestUpdated: LeaveRequest;
  offerAdded: Offer;
  offerArchived: Offer;
  offerUpdated: Offer;
  paymentCashOutRequestAdded: CashOutRequest;
  paymentCashOutRequestUpdated: CashOutRequest;
  routeAdded: Route;
  routeArchived: Route;
  routeOptimizationResponseReceived: RouteTempWithJobId;
  routeUpdated: Route;
  routesUpdated: Array<Route>;
  schoolAdded: SchoolsForWeb;
  schoolArchived: School;
  schoolRegistrationAdded: SchoolRegistration;
  schoolRegistrationArchived: SchoolRegistration;
  schoolRegistrationUpdated: SchoolRegistration;
  schoolUpdated: SchoolsForWeb;
  serviceAdded: Service;
  serviceArchived: Service;
  serviceProviderCreated: ServiceProvider;
  serviceProviderUpdated: ServiceProvider;
  serviceUpdated: Service;
  sponsorAdded: Sponsor;
  sponsorArchived: Sponsor;
  sponsorUpdated: Sponsor;
  studentAdded: Student;
  studentArchived: Student;
  studentUpdated: Student;
  tripArchived: Trip;
  tripConnectivityAndGPSUpdate: TripConnectivityAndGpsUpdate;
  tripStopStudentUpdatedForBaBd: StudentForBaBd;
  tripStopUpdated: TripStopForWeb;
  tripStopUpdatedForSponsor: TripStopSubPayloadForSponsor;
  tripUpdated: TripForWeb;
  tripUpdatedForBaBd: TripForBaBd;
  tripsUpdatedForSponsor: Array<Trip>;
  vehicleAdded: Vehicle;
  vehicleArchived: Vehicle;
  vehicleSafetyEventCreated: VehicleSafetyEvent;
  vehicleUpdated: Vehicle;
};

export type SubscriptionAlertAddedArgs = {
  alertFilterInput: AlertAddedFilterInput;
};

export type SubscriptionRouteOptimizationResponseReceivedArgs = {
  jobId: Scalars["String"]["input"];
};

export type SubscriptionTripConnectivityAndGpsUpdateArgs = {
  packageNumbers: Array<Scalars["Int"]["input"]>;
};

export type SubscriptionTripStopStudentUpdatedForBaBdArgs = {
  filters: TripStopStudentUpdatedForBaBdInput;
};

export type SubscriptionTripStopUpdatedArgs = {
  tripIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tripStopIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type SubscriptionTripStopUpdatedForSponsorArgs = {
  sponsorId: Scalars["String"]["input"];
  tripIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tripStopIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type SubscriptionTripUpdatedArgs = {
  tripIds: Array<Scalars["String"]["input"]>;
};

export type SubscriptionTripUpdatedForBaBdArgs = {
  filters: TripUpdatedForBaBdInput;
};

export type SubscriptionTripsUpdatedForSponsorArgs = {
  sponsorId: Scalars["String"]["input"];
};

export type SubscriptionVehicleSafetyEventCreatedArgs = {
  causes?: InputMaybe<Array<VehicleSafetyEventCause>>;
};

export enum TagType {
  BLE = "BLE",
  RFID = "RFID",
}

export type TerminateManySchoolRegistrationWithCreditAmountInput = {
  creditAmount?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["String"]["input"];
  isCashOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  terminatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  terminationReason?: InputMaybe<SchoolRegistrationTerminationReason>;
  terminationReasonOther?: InputMaybe<Scalars["String"]["input"]>;
};

export type TimePeriod = {
  __typename?: "TimePeriod";
  _count: TimePeriodCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  from: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  installmentPlan?: Maybe<InstallmentPlan>;
  installmentPlanId?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  paymentDueDays?: Maybe<Scalars["Int"]["output"]>;
  paymentLineItems?: Maybe<Array<PaymentLineItem>>;
  paymentReminderFrequency?: Maybe<Scalars["Int"]["output"]>;
  paymentReminderStartDays?: Maybe<Scalars["Int"]["output"]>;
  priority: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type TimePeriodCount = {
  __typename?: "TimePeriodCount";
  paymentLineItems: Scalars["Int"]["output"];
};

export type TimePeriodCreateManyInstallmentPlanInput = {
  from: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  paymentDueDays?: InputMaybe<Scalars["Int"]["input"]>;
  paymentReminderFrequency?: InputMaybe<Scalars["Int"]["input"]>;
  paymentReminderStartDays?: InputMaybe<Scalars["Int"]["input"]>;
  priority: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TimePeriodCreateManyInstallmentPlanInputEnvelope = {
  data: Array<TimePeriodCreateManyInstallmentPlanInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TimePeriodCreateNestedManyWithoutInstallmentPlanInput = {
  connect?: InputMaybe<Array<TimePeriodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TimePeriodCreateOrConnectWithoutInstallmentPlanInput>
  >;
  create?: InputMaybe<Array<TimePeriodCreateWithoutInstallmentPlanInput>>;
  createMany?: InputMaybe<TimePeriodCreateManyInstallmentPlanInputEnvelope>;
};

export type TimePeriodCreateNestedOneWithoutPaymentLineItemsInput = {
  connect?: InputMaybe<TimePeriodWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TimePeriodCreateOrConnectWithoutPaymentLineItemsInput>;
  create?: InputMaybe<TimePeriodCreateWithoutPaymentLineItemsInput>;
};

export type TimePeriodCreateOrConnectWithoutInstallmentPlanInput = {
  create: TimePeriodCreateWithoutInstallmentPlanInput;
  where: TimePeriodWhereUniqueInput;
};

export type TimePeriodCreateOrConnectWithoutPaymentLineItemsInput = {
  create: TimePeriodCreateWithoutPaymentLineItemsInput;
  where: TimePeriodWhereUniqueInput;
};

export type TimePeriodCreateWithoutInstallmentPlanInput = {
  from: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  paymentDueDays?: InputMaybe<Scalars["Int"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemCreateNestedManyWithoutTimePeriodInput>;
  paymentReminderFrequency?: InputMaybe<Scalars["Int"]["input"]>;
  paymentReminderStartDays?: InputMaybe<Scalars["Int"]["input"]>;
  priority: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TimePeriodCreateWithoutPaymentLineItemsInput = {
  from: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanCreateNestedOneWithoutCollectionTimePeriodsInput>;
  name: Scalars["String"]["input"];
  nameAr: Scalars["String"]["input"];
  paymentDueDays?: InputMaybe<Scalars["Int"]["input"]>;
  paymentReminderFrequency?: InputMaybe<Scalars["Int"]["input"]>;
  paymentReminderStartDays?: InputMaybe<Scalars["Int"]["input"]>;
  priority: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TimePeriodForSponsor = {
  __typename?: "TimePeriodForSponsor";
  from: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  paymentDueDays?: Maybe<Scalars["Int"]["output"]>;
  paymentReminderStartDays?: Maybe<Scalars["Int"]["output"]>;
  priority: Scalars["Int"]["output"];
};

export type TimePeriodListRelationFilter = {
  every?: InputMaybe<TimePeriodWhereInput>;
  none?: InputMaybe<TimePeriodWhereInput>;
  some?: InputMaybe<TimePeriodWhereInput>;
};

export type TimePeriodRelationFilter = {
  is?: InputMaybe<TimePeriodWhereInput>;
  isNot?: InputMaybe<TimePeriodWhereInput>;
};

export type TimePeriodResponsePayload = {
  __typename?: "TimePeriodResponsePayload";
  /** The creation time */
  createdAt: Scalars["DateTime"]["output"];
  from: Scalars["DateTime"]["output"];
  /** A UUID */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  paymentDueDays?: Maybe<Scalars["Int"]["output"]>;
  paymentReminderFrequency?: Maybe<Scalars["Int"]["output"]>;
  paymentReminderStartDays?: Maybe<Scalars["Int"]["output"]>;
  priority: Scalars["Int"]["output"];
  /** The last update time */
  updatedAt: Scalars["DateTime"]["output"];
};

export type TimePeriodScalarWhereInput = {
  AND?: InputMaybe<Array<TimePeriodScalarWhereInput>>;
  NOT?: InputMaybe<Array<TimePeriodScalarWhereInput>>;
  OR?: InputMaybe<Array<TimePeriodScalarWhereInput>>;
  from?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  paymentDueDays?: InputMaybe<IntFilter>;
  paymentReminderFrequency?: InputMaybe<IntFilter>;
  paymentReminderStartDays?: InputMaybe<IntFilter>;
  priority?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TimePeriodUpdateManyMutationInput = {
  from?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentDueDays?: InputMaybe<Scalars["Int"]["input"]>;
  paymentReminderFrequency?: InputMaybe<Scalars["Int"]["input"]>;
  paymentReminderStartDays?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TimePeriodUpdateManyWithWhereWithoutInstallmentPlanInput = {
  data: TimePeriodUpdateManyMutationInput;
  where: TimePeriodScalarWhereInput;
};

export type TimePeriodUpdateManyWithoutInstallmentPlanNestedInput = {
  connect?: InputMaybe<Array<TimePeriodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TimePeriodCreateOrConnectWithoutInstallmentPlanInput>
  >;
  create?: InputMaybe<Array<TimePeriodCreateWithoutInstallmentPlanInput>>;
  createMany?: InputMaybe<TimePeriodCreateManyInstallmentPlanInputEnvelope>;
  delete?: InputMaybe<Array<TimePeriodWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TimePeriodScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TimePeriodWhereUniqueInput>>;
  set?: InputMaybe<Array<TimePeriodWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TimePeriodUpdateWithWhereUniqueWithoutInstallmentPlanInput>
  >;
  updateMany?: InputMaybe<
    Array<TimePeriodUpdateManyWithWhereWithoutInstallmentPlanInput>
  >;
  upsert?: InputMaybe<
    Array<TimePeriodUpsertWithWhereUniqueWithoutInstallmentPlanInput>
  >;
};

export type TimePeriodUpdateOneWithoutPaymentLineItemsNestedInput = {
  connect?: InputMaybe<TimePeriodWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TimePeriodCreateOrConnectWithoutPaymentLineItemsInput>;
  create?: InputMaybe<TimePeriodCreateWithoutPaymentLineItemsInput>;
  delete?: InputMaybe<TimePeriodWhereInput>;
  disconnect?: InputMaybe<TimePeriodWhereInput>;
  update?: InputMaybe<TimePeriodUpdateToOneWithWhereWithoutPaymentLineItemsInput>;
  upsert?: InputMaybe<TimePeriodUpsertWithoutPaymentLineItemsInput>;
};

export type TimePeriodUpdateToOneWithWhereWithoutPaymentLineItemsInput = {
  data: TimePeriodUpdateWithoutPaymentLineItemsInput;
  where?: InputMaybe<TimePeriodWhereInput>;
};

export type TimePeriodUpdateWithWhereUniqueWithoutInstallmentPlanInput = {
  data: TimePeriodUpdateWithoutInstallmentPlanInput;
  where: TimePeriodWhereUniqueInput;
};

export type TimePeriodUpdateWithoutInstallmentPlanInput = {
  from?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentDueDays?: InputMaybe<Scalars["Int"]["input"]>;
  paymentLineItems?: InputMaybe<PaymentLineItemUpdateManyWithoutTimePeriodNestedInput>;
  paymentReminderFrequency?: InputMaybe<Scalars["Int"]["input"]>;
  paymentReminderStartDays?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TimePeriodUpdateWithoutPaymentLineItemsInput = {
  from?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanUpdateOneWithoutCollectionTimePeriodsNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  paymentDueDays?: InputMaybe<Scalars["Int"]["input"]>;
  paymentReminderFrequency?: InputMaybe<Scalars["Int"]["input"]>;
  paymentReminderStartDays?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TimePeriodUpsertWithWhereUniqueWithoutInstallmentPlanInput = {
  create: TimePeriodCreateWithoutInstallmentPlanInput;
  update: TimePeriodUpdateWithoutInstallmentPlanInput;
  where: TimePeriodWhereUniqueInput;
};

export type TimePeriodUpsertWithoutPaymentLineItemsInput = {
  create: TimePeriodCreateWithoutPaymentLineItemsInput;
  update: TimePeriodUpdateWithoutPaymentLineItemsInput;
  where?: InputMaybe<TimePeriodWhereInput>;
};

export type TimePeriodWhereInput = {
  AND?: InputMaybe<Array<TimePeriodWhereInput>>;
  NOT?: InputMaybe<Array<TimePeriodWhereInput>>;
  OR?: InputMaybe<Array<TimePeriodWhereInput>>;
  from?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  installmentPlan?: InputMaybe<InstallmentPlanRelationFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  paymentDueDays?: InputMaybe<IntFilter>;
  paymentLineItems?: InputMaybe<PaymentLineItemListRelationFilter>;
  paymentReminderFrequency?: InputMaybe<IntFilter>;
  paymentReminderStartDays?: InputMaybe<IntFilter>;
  priority?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TimePeriodWhereUniqueInput = {
  AND?: InputMaybe<Array<TimePeriodWhereInput>>;
  NOT?: InputMaybe<Array<TimePeriodWhereInput>>;
  OR?: InputMaybe<Array<TimePeriodWhereInput>>;
  from?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  installmentPlan?: InputMaybe<InstallmentPlanRelationFilter>;
  installmentPlanId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  paymentDueDays?: InputMaybe<IntFilter>;
  paymentLineItems?: InputMaybe<PaymentLineItemListRelationFilter>;
  paymentReminderFrequency?: InputMaybe<IntFilter>;
  paymentReminderStartDays?: InputMaybe<IntFilter>;
  priority?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TimePeriodWithSchoolRegistrationExtended = {
  __typename?: "TimePeriodWithSchoolRegistrationExtended";
  _count: TimePeriodCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  from: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  installmentPlan?: Maybe<InstallmentPlan>;
  installmentPlanId?: Maybe<Scalars["String"]["output"]>;
  isCurrent: Scalars["Boolean"]["output"];
  isCurrentSubscription: Scalars["Boolean"]["output"];
  isFuture: Scalars["Boolean"]["output"];
  isPast: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  nameAr: Scalars["String"]["output"];
  paymentDueDate: Scalars["DateTime"]["output"];
  paymentDueDays?: Maybe<Scalars["Int"]["output"]>;
  paymentLineItems?: Maybe<Array<PaymentLineItem>>;
  paymentReminderFrequency?: Maybe<Scalars["Int"]["output"]>;
  paymentReminderStartDays?: Maybe<Scalars["Int"]["output"]>;
  priority: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export enum TimestampSource {
  BACKEND = "BACKEND",
  HARDWARE = "HARDWARE",
}

export type TodayTripForSchoolRegistration = {
  __typename?: "TodayTripForSchoolRegistration";
  busAttendant?: Maybe<BusAttendant>;
  date: Scalars["DateTime"]["output"];
  directionality: RouteDirectionality;
  driver?: Maybe<Driver>;
  id: Scalars["String"]["output"];
  status: TodayTripStatus;
  trackingPollRate: Scalars["Float"]["output"];
  tripHasUnknownLocationStop: Scalars["Boolean"]["output"];
  tripStops: Array<TripStopForSponsor>;
  vehicle?: Maybe<Vehicle>;
};

export enum TodayTripStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  UPCOMING = "UPCOMING",
}

export type TravelTag = {
  __typename?: "TravelTag";
  _count: TravelTagCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  tagId: Scalars["String"]["output"];
  tagType: TagType;
  updatedAt: Scalars["DateTime"]["output"];
  userTravelTags?: Maybe<Array<UserTravelTag>>;
};

export type TravelTagCount = {
  __typename?: "TravelTagCount";
  userTravelTags: Scalars["Int"]["output"];
};

export type TravelTagCreateNestedOneWithoutUserTravelTagsInput = {
  connect?: InputMaybe<TravelTagWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TravelTagCreateOrConnectWithoutUserTravelTagsInput>;
  create?: InputMaybe<TravelTagCreateWithoutUserTravelTagsInput>;
};

export type TravelTagCreateOrConnectWithoutUserTravelTagsInput = {
  create: TravelTagCreateWithoutUserTravelTagsInput;
  where: TravelTagWhereUniqueInput;
};

export type TravelTagCreateWithoutUserTravelTagsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  tagId: Scalars["String"]["input"];
  tagType: TagType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TravelTagDetection = {
  __typename?: "TravelTagDetection";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  lat: Scalars["Float"]["output"];
  lon: Scalars["Float"]["output"];
  schoolRegistration: SchoolRegistration;
  schoolRegistrationId: Scalars["String"]["output"];
  tagId: Scalars["String"]["output"];
  timestamp: Scalars["DateTime"]["output"];
  timestampSource: TimestampSource;
  travelTagStatus: TravelTagStatus;
  updatedAt: Scalars["DateTime"]["output"];
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars["String"]["output"]>;
};

export type TravelTagDetectionCreateManySchoolRegistrationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat: Scalars["Float"]["input"];
  lon: Scalars["Float"]["input"];
  tagId: Scalars["String"]["input"];
  timestamp: Scalars["DateTime"]["input"];
  timestampSource: TimestampSource;
  travelTagStatus: TravelTagStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TravelTagDetectionCreateManySchoolRegistrationInputEnvelope = {
  data: Array<TravelTagDetectionCreateManySchoolRegistrationInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TravelTagDetectionCreateManyVehicleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat: Scalars["Float"]["input"];
  lon: Scalars["Float"]["input"];
  schoolRegistrationId: Scalars["String"]["input"];
  tagId: Scalars["String"]["input"];
  timestamp: Scalars["DateTime"]["input"];
  timestampSource: TimestampSource;
  travelTagStatus: TravelTagStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TravelTagDetectionCreateManyVehicleInputEnvelope = {
  data: Array<TravelTagDetectionCreateManyVehicleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TravelTagDetectionCreateNestedManyWithoutSchoolRegistrationInput = {
  connect?: InputMaybe<Array<TravelTagDetectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TravelTagDetectionCreateOrConnectWithoutSchoolRegistrationInput>
  >;
  create?: InputMaybe<
    Array<TravelTagDetectionCreateWithoutSchoolRegistrationInput>
  >;
  createMany?: InputMaybe<TravelTagDetectionCreateManySchoolRegistrationInputEnvelope>;
};

export type TravelTagDetectionCreateNestedManyWithoutVehicleInput = {
  connect?: InputMaybe<Array<TravelTagDetectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TravelTagDetectionCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<TravelTagDetectionCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<TravelTagDetectionCreateManyVehicleInputEnvelope>;
};

export type TravelTagDetectionCreateOrConnectWithoutSchoolRegistrationInput = {
  create: TravelTagDetectionCreateWithoutSchoolRegistrationInput;
  where: TravelTagDetectionWhereUniqueInput;
};

export type TravelTagDetectionCreateOrConnectWithoutVehicleInput = {
  create: TravelTagDetectionCreateWithoutVehicleInput;
  where: TravelTagDetectionWhereUniqueInput;
};

export type TravelTagDetectionCreateWithoutSchoolRegistrationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat: Scalars["Float"]["input"];
  lon: Scalars["Float"]["input"];
  tagId: Scalars["String"]["input"];
  timestamp: Scalars["DateTime"]["input"];
  timestampSource: TimestampSource;
  travelTagStatus: TravelTagStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTravelTagDetectionsInput>;
};

export type TravelTagDetectionCreateWithoutVehicleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat: Scalars["Float"]["input"];
  lon: Scalars["Float"]["input"];
  schoolRegistration: SchoolRegistrationCreateNestedOneWithoutTravelTagDetectionsInput;
  tagId: Scalars["String"]["input"];
  timestamp: Scalars["DateTime"]["input"];
  timestampSource: TimestampSource;
  travelTagStatus: TravelTagStatus;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TravelTagDetectionListRelationFilter = {
  every?: InputMaybe<TravelTagDetectionWhereInput>;
  none?: InputMaybe<TravelTagDetectionWhereInput>;
  some?: InputMaybe<TravelTagDetectionWhereInput>;
};

export type TravelTagDetectionScalarWhereInput = {
  AND?: InputMaybe<Array<TravelTagDetectionScalarWhereInput>>;
  NOT?: InputMaybe<Array<TravelTagDetectionScalarWhereInput>>;
  OR?: InputMaybe<Array<TravelTagDetectionScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  lat?: InputMaybe<FloatFilter>;
  lon?: InputMaybe<FloatFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  tagId?: InputMaybe<StringFilter>;
  timestamp?: InputMaybe<DateTimeFilter>;
  timestampSource?: InputMaybe<EnumTimestampSourceFilter>;
  travelTagStatus?: InputMaybe<EnumTravelTagStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type TravelTagDetectionUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  tagId?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  timestampSource?: InputMaybe<TimestampSource>;
  travelTagStatus?: InputMaybe<TravelTagStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TravelTagDetectionUpdateManyWithWhereWithoutSchoolRegistrationInput =
  {
    data: TravelTagDetectionUpdateManyMutationInput;
    where: TravelTagDetectionScalarWhereInput;
  };

export type TravelTagDetectionUpdateManyWithWhereWithoutVehicleInput = {
  data: TravelTagDetectionUpdateManyMutationInput;
  where: TravelTagDetectionScalarWhereInput;
};

export type TravelTagDetectionUpdateManyWithoutSchoolRegistrationNestedInput = {
  connect?: InputMaybe<Array<TravelTagDetectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TravelTagDetectionCreateOrConnectWithoutSchoolRegistrationInput>
  >;
  create?: InputMaybe<
    Array<TravelTagDetectionCreateWithoutSchoolRegistrationInput>
  >;
  createMany?: InputMaybe<TravelTagDetectionCreateManySchoolRegistrationInputEnvelope>;
  delete?: InputMaybe<Array<TravelTagDetectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TravelTagDetectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TravelTagDetectionWhereUniqueInput>>;
  set?: InputMaybe<Array<TravelTagDetectionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TravelTagDetectionUpdateWithWhereUniqueWithoutSchoolRegistrationInput>
  >;
  updateMany?: InputMaybe<
    Array<TravelTagDetectionUpdateManyWithWhereWithoutSchoolRegistrationInput>
  >;
  upsert?: InputMaybe<
    Array<TravelTagDetectionUpsertWithWhereUniqueWithoutSchoolRegistrationInput>
  >;
};

export type TravelTagDetectionUpdateManyWithoutVehicleNestedInput = {
  connect?: InputMaybe<Array<TravelTagDetectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TravelTagDetectionCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<TravelTagDetectionCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<TravelTagDetectionCreateManyVehicleInputEnvelope>;
  delete?: InputMaybe<Array<TravelTagDetectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TravelTagDetectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TravelTagDetectionWhereUniqueInput>>;
  set?: InputMaybe<Array<TravelTagDetectionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TravelTagDetectionUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: InputMaybe<
    Array<TravelTagDetectionUpdateManyWithWhereWithoutVehicleInput>
  >;
  upsert?: InputMaybe<
    Array<TravelTagDetectionUpsertWithWhereUniqueWithoutVehicleInput>
  >;
};

export type TravelTagDetectionUpdateWithWhereUniqueWithoutSchoolRegistrationInput =
  {
    data: TravelTagDetectionUpdateWithoutSchoolRegistrationInput;
    where: TravelTagDetectionWhereUniqueInput;
  };

export type TravelTagDetectionUpdateWithWhereUniqueWithoutVehicleInput = {
  data: TravelTagDetectionUpdateWithoutVehicleInput;
  where: TravelTagDetectionWhereUniqueInput;
};

export type TravelTagDetectionUpdateWithoutSchoolRegistrationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  tagId?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  timestampSource?: InputMaybe<TimestampSource>;
  travelTagStatus?: InputMaybe<TravelTagStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTravelTagDetectionsNestedInput>;
};

export type TravelTagDetectionUpdateWithoutVehicleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  schoolRegistration?: InputMaybe<SchoolRegistrationUpdateOneRequiredWithoutTravelTagDetectionsNestedInput>;
  tagId?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  timestampSource?: InputMaybe<TimestampSource>;
  travelTagStatus?: InputMaybe<TravelTagStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TravelTagDetectionUpsertWithWhereUniqueWithoutSchoolRegistrationInput =
  {
    create: TravelTagDetectionCreateWithoutSchoolRegistrationInput;
    update: TravelTagDetectionUpdateWithoutSchoolRegistrationInput;
    where: TravelTagDetectionWhereUniqueInput;
  };

export type TravelTagDetectionUpsertWithWhereUniqueWithoutVehicleInput = {
  create: TravelTagDetectionCreateWithoutVehicleInput;
  update: TravelTagDetectionUpdateWithoutVehicleInput;
  where: TravelTagDetectionWhereUniqueInput;
};

export type TravelTagDetectionWhereInput = {
  AND?: InputMaybe<Array<TravelTagDetectionWhereInput>>;
  NOT?: InputMaybe<Array<TravelTagDetectionWhereInput>>;
  OR?: InputMaybe<Array<TravelTagDetectionWhereInput>>;
  id?: InputMaybe<StringFilter>;
  lat?: InputMaybe<FloatFilter>;
  lon?: InputMaybe<FloatFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  tagId?: InputMaybe<StringFilter>;
  timestamp?: InputMaybe<DateTimeFilter>;
  timestampSource?: InputMaybe<EnumTimestampSourceFilter>;
  travelTagStatus?: InputMaybe<EnumTravelTagStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type TravelTagDetectionWhereUniqueInput = {
  AND?: InputMaybe<Array<TravelTagDetectionWhereInput>>;
  NOT?: InputMaybe<Array<TravelTagDetectionWhereInput>>;
  OR?: InputMaybe<Array<TravelTagDetectionWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<FloatFilter>;
  lon?: InputMaybe<FloatFilter>;
  schoolRegistration?: InputMaybe<SchoolRegistrationRelationFilter>;
  schoolRegistrationId?: InputMaybe<StringFilter>;
  tagId?: InputMaybe<StringFilter>;
  timestamp?: InputMaybe<DateTimeFilter>;
  timestampSource?: InputMaybe<EnumTimestampSourceFilter>;
  travelTagStatus?: InputMaybe<EnumTravelTagStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type TravelTagRelationFilter = {
  is?: InputMaybe<TravelTagWhereInput>;
  isNot?: InputMaybe<TravelTagWhereInput>;
};

export enum TravelTagStatus {
  DROPPED_OFF = "DROPPED_OFF",
  LOST = "LOST",
  ONBOARD = "ONBOARD",
}

export type TravelTagTagIdTagTypeCompoundUniqueInput = {
  tagId: Scalars["String"]["input"];
  tagType: TagType;
};

export type TravelTagUpdateOneRequiredWithoutUserTravelTagsNestedInput = {
  connect?: InputMaybe<TravelTagWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TravelTagCreateOrConnectWithoutUserTravelTagsInput>;
  create?: InputMaybe<TravelTagCreateWithoutUserTravelTagsInput>;
  update?: InputMaybe<TravelTagUpdateToOneWithWhereWithoutUserTravelTagsInput>;
  upsert?: InputMaybe<TravelTagUpsertWithoutUserTravelTagsInput>;
};

export type TravelTagUpdateToOneWithWhereWithoutUserTravelTagsInput = {
  data: TravelTagUpdateWithoutUserTravelTagsInput;
  where?: InputMaybe<TravelTagWhereInput>;
};

export type TravelTagUpdateWithoutUserTravelTagsInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  tagId?: InputMaybe<Scalars["String"]["input"]>;
  tagType?: InputMaybe<TagType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TravelTagUpsertWithoutUserTravelTagsInput = {
  create: TravelTagCreateWithoutUserTravelTagsInput;
  update: TravelTagUpdateWithoutUserTravelTagsInput;
  where?: InputMaybe<TravelTagWhereInput>;
};

export type TravelTagWhereInput = {
  AND?: InputMaybe<Array<TravelTagWhereInput>>;
  NOT?: InputMaybe<Array<TravelTagWhereInput>>;
  OR?: InputMaybe<Array<TravelTagWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  tagId?: InputMaybe<StringFilter>;
  tagType?: InputMaybe<EnumTagTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userTravelTags?: InputMaybe<UserTravelTagListRelationFilter>;
};

export type TravelTagWhereUniqueInput = {
  AND?: InputMaybe<Array<TravelTagWhereInput>>;
  NOT?: InputMaybe<Array<TravelTagWhereInput>>;
  OR?: InputMaybe<Array<TravelTagWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<BoolFilter>;
  tagId?: InputMaybe<StringFilter>;
  tagId_tagType?: InputMaybe<TravelTagTagIdTagTypeCompoundUniqueInput>;
  tagType?: InputMaybe<EnumTagTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userTravelTags?: InputMaybe<UserTravelTagListRelationFilter>;
};

export type Trip = {
  __typename?: "Trip";
  _count: TripCount;
  actualEndTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualSchoolStopDuration?: Maybe<Scalars["Int"]["output"]>;
  actualStartTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualStudentStopDuration?: Maybe<Scalars["Int"]["output"]>;
  agent?: Maybe<Agent>;
  agentId?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendant?: Maybe<BusAttendant>;
  busAttendantId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  date: Scalars["DateTime"]["output"];
  delayTripStopAlerts?: Maybe<Array<DelayTripStopAlert>>;
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isForceEnded: Scalars["Boolean"]["output"];
  isForceStarted: Scalars["Boolean"]["output"];
  manualAttendanceEndOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  manualAttendanceStartOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  noPickUpAlerts?: Maybe<Array<NoPickUpAlert>>;
  plannedEndTime: Scalars["DateTime"]["output"];
  plannedSchoolStopDuration: Scalars["Int"]["output"];
  plannedStartTime: Scalars["DateTime"]["output"];
  plannedStudentStopDuration: Scalars["Int"]["output"];
  route?: Maybe<Route>;
  routeId?: Maybe<Scalars["String"]["output"]>;
  status: TripStatus;
  tripBusOverLoadedAlerts?: Maybe<Array<TripBusOverLoadedAlert>>;
  tripDelayAlerts?: Maybe<Array<TripDelayAlert>>;
  tripEndOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  tripFeedback?: Maybe<TripFeedback>;
  tripLogs?: Maybe<Array<TripLog>>;
  tripStartOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  tripStops?: Maybe<Array<TripStop>>;
  updatedAt: Scalars["DateTime"]["output"];
  vehicle?: Maybe<Vehicle>;
  vehicleAssignmentType?: Maybe<TripVehicleAssignmentType>;
  vehicleId?: Maybe<Scalars["String"]["output"]>;
  vehicleSafetyEvents?: Maybe<Array<VehicleSafetyEvent>>;
  wrongDropOffLocations?: Maybe<Array<WrongDropOffLocation>>;
};

export type TripBusOverLoadedAlert = {
  __typename?: "TripBusOverLoadedAlert";
  alert?: Maybe<Alert>;
  alertId: Scalars["String"]["output"];
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  numberOfExtraStudents: Scalars["Int"]["output"];
  trip: Trip;
  tripId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars["String"]["output"]>;
};

export type TripBusOverLoadedAlertCreateManyTripInput = {
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  numberOfExtraStudents: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripBusOverLoadedAlertCreateManyTripInputEnvelope = {
  data: Array<TripBusOverLoadedAlertCreateManyTripInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripBusOverLoadedAlertCreateManyVehicleInput = {
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  numberOfExtraStudents: Scalars["Int"]["input"];
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripBusOverLoadedAlertCreateManyVehicleInputEnvelope = {
  data: Array<TripBusOverLoadedAlertCreateManyVehicleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripBusOverLoadedAlertCreateNestedManyWithoutTripInput = {
  connect?: InputMaybe<Array<TripBusOverLoadedAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripBusOverLoadedAlertCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<TripBusOverLoadedAlertCreateWithoutTripInput>>;
  createMany?: InputMaybe<TripBusOverLoadedAlertCreateManyTripInputEnvelope>;
};

export type TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput = {
  connect?: InputMaybe<Array<TripBusOverLoadedAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripBusOverLoadedAlertCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<TripBusOverLoadedAlertCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<TripBusOverLoadedAlertCreateManyVehicleInputEnvelope>;
};

export type TripBusOverLoadedAlertCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<TripBusOverLoadedAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripBusOverLoadedAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<TripBusOverLoadedAlertCreateWithoutAlertInput>;
};

export type TripBusOverLoadedAlertCreateOrConnectWithoutAlertInput = {
  create: TripBusOverLoadedAlertCreateWithoutAlertInput;
  where: TripBusOverLoadedAlertWhereUniqueInput;
};

export type TripBusOverLoadedAlertCreateOrConnectWithoutTripInput = {
  create: TripBusOverLoadedAlertCreateWithoutTripInput;
  where: TripBusOverLoadedAlertWhereUniqueInput;
};

export type TripBusOverLoadedAlertCreateOrConnectWithoutVehicleInput = {
  create: TripBusOverLoadedAlertCreateWithoutVehicleInput;
  where: TripBusOverLoadedAlertWhereUniqueInput;
};

export type TripBusOverLoadedAlertCreateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  numberOfExtraStudents: Scalars["Int"]["input"];
  trip: TripCreateNestedOneWithoutTripBusOverLoadedAlertsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripBusOverLoadedAlertsInput>;
};

export type TripBusOverLoadedAlertCreateWithoutTripInput = {
  alert?: InputMaybe<AlertCreateNestedOneWithoutTripBusOverLoadedAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  numberOfExtraStudents: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripBusOverLoadedAlertsInput>;
};

export type TripBusOverLoadedAlertCreateWithoutVehicleInput = {
  alert?: InputMaybe<AlertCreateNestedOneWithoutTripBusOverLoadedAlertInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  numberOfExtraStudents: Scalars["Int"]["input"];
  trip: TripCreateNestedOneWithoutTripBusOverLoadedAlertsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripBusOverLoadedAlertListRelationFilter = {
  every?: InputMaybe<TripBusOverLoadedAlertWhereInput>;
  none?: InputMaybe<TripBusOverLoadedAlertWhereInput>;
  some?: InputMaybe<TripBusOverLoadedAlertWhereInput>;
};

export type TripBusOverLoadedAlertRelationFilter = {
  is?: InputMaybe<TripBusOverLoadedAlertWhereInput>;
  isNot?: InputMaybe<TripBusOverLoadedAlertWhereInput>;
};

export type TripBusOverLoadedAlertScalarWhereInput = {
  AND?: InputMaybe<Array<TripBusOverLoadedAlertScalarWhereInput>>;
  NOT?: InputMaybe<Array<TripBusOverLoadedAlertScalarWhereInput>>;
  OR?: InputMaybe<Array<TripBusOverLoadedAlertScalarWhereInput>>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  numberOfExtraStudents?: InputMaybe<IntFilter>;
  tripId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type TripBusOverLoadedAlertUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  numberOfExtraStudents?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripBusOverLoadedAlertUpdateManyWithWhereWithoutTripInput = {
  data: TripBusOverLoadedAlertUpdateManyMutationInput;
  where: TripBusOverLoadedAlertScalarWhereInput;
};

export type TripBusOverLoadedAlertUpdateManyWithWhereWithoutVehicleInput = {
  data: TripBusOverLoadedAlertUpdateManyMutationInput;
  where: TripBusOverLoadedAlertScalarWhereInput;
};

export type TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput = {
  connect?: InputMaybe<Array<TripBusOverLoadedAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripBusOverLoadedAlertCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<TripBusOverLoadedAlertCreateWithoutTripInput>>;
  createMany?: InputMaybe<TripBusOverLoadedAlertCreateManyTripInputEnvelope>;
  delete?: InputMaybe<Array<TripBusOverLoadedAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripBusOverLoadedAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripBusOverLoadedAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<TripBusOverLoadedAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TripBusOverLoadedAlertUpdateWithWhereUniqueWithoutTripInput>
  >;
  updateMany?: InputMaybe<
    Array<TripBusOverLoadedAlertUpdateManyWithWhereWithoutTripInput>
  >;
  upsert?: InputMaybe<
    Array<TripBusOverLoadedAlertUpsertWithWhereUniqueWithoutTripInput>
  >;
};

export type TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput = {
  connect?: InputMaybe<Array<TripBusOverLoadedAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripBusOverLoadedAlertCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<TripBusOverLoadedAlertCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<TripBusOverLoadedAlertCreateManyVehicleInputEnvelope>;
  delete?: InputMaybe<Array<TripBusOverLoadedAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripBusOverLoadedAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripBusOverLoadedAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<TripBusOverLoadedAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TripBusOverLoadedAlertUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: InputMaybe<
    Array<TripBusOverLoadedAlertUpdateManyWithWhereWithoutVehicleInput>
  >;
  upsert?: InputMaybe<
    Array<TripBusOverLoadedAlertUpsertWithWhereUniqueWithoutVehicleInput>
  >;
};

export type TripBusOverLoadedAlertUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<TripBusOverLoadedAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripBusOverLoadedAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<TripBusOverLoadedAlertCreateWithoutAlertInput>;
  delete?: InputMaybe<TripBusOverLoadedAlertWhereInput>;
  disconnect?: InputMaybe<TripBusOverLoadedAlertWhereInput>;
  update?: InputMaybe<TripBusOverLoadedAlertUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<TripBusOverLoadedAlertUpsertWithoutAlertInput>;
};

export type TripBusOverLoadedAlertUpdateToOneWithWhereWithoutAlertInput = {
  data: TripBusOverLoadedAlertUpdateWithoutAlertInput;
  where?: InputMaybe<TripBusOverLoadedAlertWhereInput>;
};

export type TripBusOverLoadedAlertUpdateWithWhereUniqueWithoutTripInput = {
  data: TripBusOverLoadedAlertUpdateWithoutTripInput;
  where: TripBusOverLoadedAlertWhereUniqueInput;
};

export type TripBusOverLoadedAlertUpdateWithWhereUniqueWithoutVehicleInput = {
  data: TripBusOverLoadedAlertUpdateWithoutVehicleInput;
  where: TripBusOverLoadedAlertWhereUniqueInput;
};

export type TripBusOverLoadedAlertUpdateWithoutAlertInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  numberOfExtraStudents?: InputMaybe<Scalars["Int"]["input"]>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripBusOverLoadedAlertsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripBusOverLoadedAlertsNestedInput>;
};

export type TripBusOverLoadedAlertUpdateWithoutTripInput = {
  alert?: InputMaybe<AlertUpdateOneWithoutTripBusOverLoadedAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  numberOfExtraStudents?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripBusOverLoadedAlertsNestedInput>;
};

export type TripBusOverLoadedAlertUpdateWithoutVehicleInput = {
  alert?: InputMaybe<AlertUpdateOneWithoutTripBusOverLoadedAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  numberOfExtraStudents?: InputMaybe<Scalars["Int"]["input"]>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripBusOverLoadedAlertsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripBusOverLoadedAlertUpsertWithWhereUniqueWithoutTripInput = {
  create: TripBusOverLoadedAlertCreateWithoutTripInput;
  update: TripBusOverLoadedAlertUpdateWithoutTripInput;
  where: TripBusOverLoadedAlertWhereUniqueInput;
};

export type TripBusOverLoadedAlertUpsertWithWhereUniqueWithoutVehicleInput = {
  create: TripBusOverLoadedAlertCreateWithoutVehicleInput;
  update: TripBusOverLoadedAlertUpdateWithoutVehicleInput;
  where: TripBusOverLoadedAlertWhereUniqueInput;
};

export type TripBusOverLoadedAlertUpsertWithoutAlertInput = {
  create: TripBusOverLoadedAlertCreateWithoutAlertInput;
  update: TripBusOverLoadedAlertUpdateWithoutAlertInput;
  where?: InputMaybe<TripBusOverLoadedAlertWhereInput>;
};

export type TripBusOverLoadedAlertWhereInput = {
  AND?: InputMaybe<Array<TripBusOverLoadedAlertWhereInput>>;
  NOT?: InputMaybe<Array<TripBusOverLoadedAlertWhereInput>>;
  OR?: InputMaybe<Array<TripBusOverLoadedAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  numberOfExtraStudents?: InputMaybe<IntFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type TripBusOverLoadedAlertWhereUniqueInput = {
  AND?: InputMaybe<Array<TripBusOverLoadedAlertWhereInput>>;
  NOT?: InputMaybe<Array<TripBusOverLoadedAlertWhereInput>>;
  OR?: InputMaybe<Array<TripBusOverLoadedAlertWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  numberOfExtraStudents?: InputMaybe<IntFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type TripConnectivityAndGpsUpdate = {
  __typename?: "TripConnectivityAndGPSUpdate";
  connected?: Maybe<Scalars["Boolean"]["output"]>;
  gpsHealthStatus?: Maybe<Scalars["Boolean"]["output"]>;
  gpsLockRate?: Maybe<Scalars["Float"]["output"]>;
  packageNumber?: Maybe<Scalars["Float"]["output"]>;
  timestamp?: Maybe<Scalars["DateTime"]["output"]>;
};

export type TripCount = {
  __typename?: "TripCount";
  delayTripStopAlerts: Scalars["Int"]["output"];
  noPickUpAlerts: Scalars["Int"]["output"];
  tripBusOverLoadedAlerts: Scalars["Int"]["output"];
  tripDelayAlerts: Scalars["Int"]["output"];
  tripLogs: Scalars["Int"]["output"];
  tripStops: Scalars["Int"]["output"];
  vehicleSafetyEvents: Scalars["Int"]["output"];
  wrongDropOffLocations: Scalars["Int"]["output"];
};

export type TripCreateManyAgentInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  busAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  routeId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TripStatus>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripCreateManyAgentInputEnvelope = {
  data: Array<TripCreateManyAgentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripCreateManyBusAttendantInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agentId?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  routeId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TripStatus>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripCreateManyBusAttendantInputEnvelope = {
  data: Array<TripCreateManyBusAttendantInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripCreateManyDriverInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agentId?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  routeId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TripStatus>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripCreateManyDriverInputEnvelope = {
  data: Array<TripCreateManyDriverInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripCreateManyRouteInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agentId?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<TripStatus>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripCreateManyRouteInputEnvelope = {
  data: Array<TripCreateManyRouteInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripCreateManyVehicleInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agentId?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  routeId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<TripStatus>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
};

export type TripCreateManyVehicleInputEnvelope = {
  data: Array<TripCreateManyVehicleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripCreateNestedManyWithoutAgentInput = {
  connect?: InputMaybe<Array<TripWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<TripCreateWithoutAgentInput>>;
  createMany?: InputMaybe<TripCreateManyAgentInputEnvelope>;
};

export type TripCreateNestedManyWithoutBusAttendantInput = {
  connect?: InputMaybe<Array<TripWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripCreateOrConnectWithoutBusAttendantInput>
  >;
  create?: InputMaybe<Array<TripCreateWithoutBusAttendantInput>>;
  createMany?: InputMaybe<TripCreateManyBusAttendantInputEnvelope>;
};

export type TripCreateNestedManyWithoutDriverInput = {
  connect?: InputMaybe<Array<TripWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripCreateOrConnectWithoutDriverInput>>;
  create?: InputMaybe<Array<TripCreateWithoutDriverInput>>;
  createMany?: InputMaybe<TripCreateManyDriverInputEnvelope>;
};

export type TripCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<TripWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripCreateOrConnectWithoutRouteInput>>;
  create?: InputMaybe<Array<TripCreateWithoutRouteInput>>;
  createMany?: InputMaybe<TripCreateManyRouteInputEnvelope>;
};

export type TripCreateNestedManyWithoutVehicleInput = {
  connect?: InputMaybe<Array<TripWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripCreateOrConnectWithoutVehicleInput>>;
  create?: InputMaybe<Array<TripCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<TripCreateManyVehicleInputEnvelope>;
};

export type TripCreateNestedOneWithoutDelayTripStopAlertsInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutDelayTripStopAlertsInput>;
  create?: InputMaybe<TripCreateWithoutDelayTripStopAlertsInput>;
};

export type TripCreateNestedOneWithoutNoPickUpAlertsInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutNoPickUpAlertsInput>;
  create?: InputMaybe<TripCreateWithoutNoPickUpAlertsInput>;
};

export type TripCreateNestedOneWithoutTripBusOverLoadedAlertsInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutTripBusOverLoadedAlertsInput>;
  create?: InputMaybe<TripCreateWithoutTripBusOverLoadedAlertsInput>;
};

export type TripCreateNestedOneWithoutTripDelayAlertsInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutTripDelayAlertsInput>;
  create?: InputMaybe<TripCreateWithoutTripDelayAlertsInput>;
};

export type TripCreateNestedOneWithoutTripFeedbackInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutTripFeedbackInput>;
  create?: InputMaybe<TripCreateWithoutTripFeedbackInput>;
};

export type TripCreateNestedOneWithoutTripLogsInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutTripLogsInput>;
  create?: InputMaybe<TripCreateWithoutTripLogsInput>;
};

export type TripCreateNestedOneWithoutTripStopsInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutTripStopsInput>;
  create?: InputMaybe<TripCreateWithoutTripStopsInput>;
};

export type TripCreateNestedOneWithoutVehicleSafetyEventsInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutVehicleSafetyEventsInput>;
  create?: InputMaybe<TripCreateWithoutVehicleSafetyEventsInput>;
};

export type TripCreateNestedOneWithoutWrongDropOffLocationsInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutWrongDropOffLocationsInput>;
  create?: InputMaybe<TripCreateWithoutWrongDropOffLocationsInput>;
};

export type TripCreateOrConnectWithoutAgentInput = {
  create: TripCreateWithoutAgentInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutBusAttendantInput = {
  create: TripCreateWithoutBusAttendantInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutDelayTripStopAlertsInput = {
  create: TripCreateWithoutDelayTripStopAlertsInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutDriverInput = {
  create: TripCreateWithoutDriverInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutNoPickUpAlertsInput = {
  create: TripCreateWithoutNoPickUpAlertsInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutRouteInput = {
  create: TripCreateWithoutRouteInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutTripBusOverLoadedAlertsInput = {
  create: TripCreateWithoutTripBusOverLoadedAlertsInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutTripDelayAlertsInput = {
  create: TripCreateWithoutTripDelayAlertsInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutTripFeedbackInput = {
  create: TripCreateWithoutTripFeedbackInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutTripLogsInput = {
  create: TripCreateWithoutTripLogsInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutTripStopsInput = {
  create: TripCreateWithoutTripStopsInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutVehicleInput = {
  create: TripCreateWithoutVehicleInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutVehicleSafetyEventsInput = {
  create: TripCreateWithoutVehicleSafetyEventsInput;
  where: TripWhereUniqueInput;
};

export type TripCreateOrConnectWithoutWrongDropOffLocationsInput = {
  create: TripCreateWithoutWrongDropOffLocationsInput;
  where: TripWhereUniqueInput;
};

export type TripCreateWithoutAgentInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutBusAttendantInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutDelayTripStopAlertsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutDriverInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutNoPickUpAlertsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutRouteInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutTripBusOverLoadedAlertsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutTripDelayAlertsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutTripFeedbackInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutTripLogsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutTripStopsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutVehicleInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutVehicleSafetyEventsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationCreateNestedManyWithoutTripInput>;
};

export type TripCreateWithoutWrongDropOffLocationsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutTripsInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutTripsInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripInput>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutTripsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertCreateNestedManyWithoutTripInput>;
  plannedEndTime: Scalars["DateTime"]["input"];
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime: Scalars["DateTime"]["input"];
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteCreateNestedOneWithoutTripsInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutTripInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertCreateNestedManyWithoutTripInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackCreateNestedOneWithoutTripInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutTripInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopCreateNestedManyWithoutTripInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutTripsInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutTripInput>;
};

export type TripDelayAlert = {
  __typename?: "TripDelayAlert";
  actualTime: Scalars["DateTime"]["output"];
  alert: Alert;
  alertId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  plannedTime: Scalars["DateTime"]["output"];
  trip: Trip;
  tripId: Scalars["String"]["output"];
};

export type TripDelayAlertCreateManyTripInput = {
  actualTime: Scalars["DateTime"]["input"];
  alertId: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedTime: Scalars["DateTime"]["input"];
};

export type TripDelayAlertCreateManyTripInputEnvelope = {
  data: Array<TripDelayAlertCreateManyTripInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripDelayAlertCreateNestedManyWithoutTripInput = {
  connect?: InputMaybe<Array<TripDelayAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripDelayAlertCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<TripDelayAlertCreateWithoutTripInput>>;
  createMany?: InputMaybe<TripDelayAlertCreateManyTripInputEnvelope>;
};

export type TripDelayAlertCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<TripDelayAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripDelayAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<TripDelayAlertCreateWithoutAlertInput>;
};

export type TripDelayAlertCreateOrConnectWithoutAlertInput = {
  create: TripDelayAlertCreateWithoutAlertInput;
  where: TripDelayAlertWhereUniqueInput;
};

export type TripDelayAlertCreateOrConnectWithoutTripInput = {
  create: TripDelayAlertCreateWithoutTripInput;
  where: TripDelayAlertWhereUniqueInput;
};

export type TripDelayAlertCreateWithoutAlertInput = {
  actualTime: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedTime: Scalars["DateTime"]["input"];
  trip: TripCreateNestedOneWithoutTripDelayAlertsInput;
};

export type TripDelayAlertCreateWithoutTripInput = {
  actualTime: Scalars["DateTime"]["input"];
  alert: AlertCreateNestedOneWithoutTripDelayAlertInput;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedTime: Scalars["DateTime"]["input"];
};

export type TripDelayAlertListRelationFilter = {
  every?: InputMaybe<TripDelayAlertWhereInput>;
  none?: InputMaybe<TripDelayAlertWhereInput>;
  some?: InputMaybe<TripDelayAlertWhereInput>;
};

export type TripDelayAlertRelationFilter = {
  is?: InputMaybe<TripDelayAlertWhereInput>;
  isNot?: InputMaybe<TripDelayAlertWhereInput>;
};

export type TripDelayAlertScalarWhereInput = {
  AND?: InputMaybe<Array<TripDelayAlertScalarWhereInput>>;
  NOT?: InputMaybe<Array<TripDelayAlertScalarWhereInput>>;
  OR?: InputMaybe<Array<TripDelayAlertScalarWhereInput>>;
  actualTime?: InputMaybe<DateTimeFilter>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  plannedTime?: InputMaybe<DateTimeFilter>;
  tripId?: InputMaybe<StringFilter>;
};

export type TripDelayAlertUpdateManyMutationInput = {
  actualTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripDelayAlertUpdateManyWithWhereWithoutTripInput = {
  data: TripDelayAlertUpdateManyMutationInput;
  where: TripDelayAlertScalarWhereInput;
};

export type TripDelayAlertUpdateManyWithoutTripNestedInput = {
  connect?: InputMaybe<Array<TripDelayAlertWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripDelayAlertCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<TripDelayAlertCreateWithoutTripInput>>;
  createMany?: InputMaybe<TripDelayAlertCreateManyTripInputEnvelope>;
  delete?: InputMaybe<Array<TripDelayAlertWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripDelayAlertScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripDelayAlertWhereUniqueInput>>;
  set?: InputMaybe<Array<TripDelayAlertWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TripDelayAlertUpdateWithWhereUniqueWithoutTripInput>
  >;
  updateMany?: InputMaybe<
    Array<TripDelayAlertUpdateManyWithWhereWithoutTripInput>
  >;
  upsert?: InputMaybe<
    Array<TripDelayAlertUpsertWithWhereUniqueWithoutTripInput>
  >;
};

export type TripDelayAlertUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<TripDelayAlertWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripDelayAlertCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<TripDelayAlertCreateWithoutAlertInput>;
  delete?: InputMaybe<TripDelayAlertWhereInput>;
  disconnect?: InputMaybe<TripDelayAlertWhereInput>;
  update?: InputMaybe<TripDelayAlertUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<TripDelayAlertUpsertWithoutAlertInput>;
};

export type TripDelayAlertUpdateToOneWithWhereWithoutAlertInput = {
  data: TripDelayAlertUpdateWithoutAlertInput;
  where?: InputMaybe<TripDelayAlertWhereInput>;
};

export type TripDelayAlertUpdateWithWhereUniqueWithoutTripInput = {
  data: TripDelayAlertUpdateWithoutTripInput;
  where: TripDelayAlertWhereUniqueInput;
};

export type TripDelayAlertUpdateWithoutAlertInput = {
  actualTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripDelayAlertsNestedInput>;
};

export type TripDelayAlertUpdateWithoutTripInput = {
  actualTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutTripDelayAlertNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripDelayAlertUpsertWithWhereUniqueWithoutTripInput = {
  create: TripDelayAlertCreateWithoutTripInput;
  update: TripDelayAlertUpdateWithoutTripInput;
  where: TripDelayAlertWhereUniqueInput;
};

export type TripDelayAlertUpsertWithoutAlertInput = {
  create: TripDelayAlertCreateWithoutAlertInput;
  update: TripDelayAlertUpdateWithoutAlertInput;
  where?: InputMaybe<TripDelayAlertWhereInput>;
};

export type TripDelayAlertWhereInput = {
  AND?: InputMaybe<Array<TripDelayAlertWhereInput>>;
  NOT?: InputMaybe<Array<TripDelayAlertWhereInput>>;
  OR?: InputMaybe<Array<TripDelayAlertWhereInput>>;
  actualTime?: InputMaybe<DateTimeFilter>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  plannedTime?: InputMaybe<DateTimeFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
};

export type TripDelayAlertWhereUniqueInput = {
  AND?: InputMaybe<Array<TripDelayAlertWhereInput>>;
  NOT?: InputMaybe<Array<TripDelayAlertWhereInput>>;
  OR?: InputMaybe<Array<TripDelayAlertWhereInput>>;
  actualTime?: InputMaybe<DateTimeFilter>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedTime?: InputMaybe<DateTimeFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
};

export type TripDetailsForSponsor = {
  __typename?: "TripDetailsForSponsor";
  actualDropOffTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualPickUpTime?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendant?: Maybe<BusAttendantForSponsor>;
  directionality: RouteDirectionality;
  driver?: Maybe<DriverForSponsor>;
  dropOffTime?: Maybe<Scalars["DateTime"]["output"]>;
  hasHomeLocation?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  isOnLeave?: Maybe<Scalars["Boolean"]["output"]>;
  leaveRequestSubmittedDate?: Maybe<Scalars["DateTime"]["output"]>;
  passengerTripStop: LatLon;
  pickUpTime?: Maybe<Scalars["DateTime"]["output"]>;
  schoolName?: Maybe<Scalars["String"]["output"]>;
  schoolNameAr?: Maybe<Scalars["String"]["output"]>;
  schoolTripStop?: Maybe<LatLon>;
  shape?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<TodayTripStatus>;
  studentName?: Maybe<Scalars["String"]["output"]>;
  studentNameAr?: Maybe<Scalars["String"]["output"]>;
  tripHasUnknownLocationStop: Scalars["Boolean"]["output"];
  vehicle?: Maybe<VehicleForSponsor>;
};

export type TripFeedback = {
  __typename?: "TripFeedback";
  accidentReportNote?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendantNote?: Maybe<Scalars["String"]["output"]>;
  busAttendantRating: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  driverNote?: Maybe<Scalars["String"]["output"]>;
  driverRating: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  incidentReportNote?: Maybe<Scalars["String"]["output"]>;
  incidentReportType?: Maybe<IncidentReportType>;
  isAccidentReported: Scalars["Boolean"]["output"];
  isEmergencyVehicleDispatched: Scalars["Boolean"]["output"];
  isIncidentReported: Scalars["Boolean"]["output"];
  trip: Trip;
  tripId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
  vehicleNote?: Maybe<Scalars["String"]["output"]>;
  vehicleRating: Scalars["Int"]["output"];
};

export type TripFeedbackCreateInput = {
  accidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantRating: Scalars["Int"]["input"];
  driverNote?: InputMaybe<Scalars["String"]["input"]>;
  driverRating: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportType?: InputMaybe<IncidentReportType>;
  isAccidentReported: Scalars["Boolean"]["input"];
  isEmergencyVehicleDispatched: Scalars["Boolean"]["input"];
  isIncidentReported: Scalars["Boolean"]["input"];
  trip: TripCreateNestedOneWithoutTripFeedbackInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutTripFeedbackInput;
  vehicleNote?: InputMaybe<Scalars["String"]["input"]>;
  vehicleRating: Scalars["Int"]["input"];
};

export type TripFeedbackCreateManyUserInput = {
  accidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantRating: Scalars["Int"]["input"];
  driverNote?: InputMaybe<Scalars["String"]["input"]>;
  driverRating: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportType?: InputMaybe<IncidentReportType>;
  isAccidentReported: Scalars["Boolean"]["input"];
  isEmergencyVehicleDispatched: Scalars["Boolean"]["input"];
  isIncidentReported: Scalars["Boolean"]["input"];
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleNote?: InputMaybe<Scalars["String"]["input"]>;
  vehicleRating: Scalars["Int"]["input"];
};

export type TripFeedbackCreateManyUserInputEnvelope = {
  data: Array<TripFeedbackCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripFeedbackCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<TripFeedbackWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripFeedbackCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<TripFeedbackCreateWithoutUserInput>>;
  createMany?: InputMaybe<TripFeedbackCreateManyUserInputEnvelope>;
};

export type TripFeedbackCreateNestedOneWithoutTripInput = {
  connect?: InputMaybe<TripFeedbackWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripFeedbackCreateOrConnectWithoutTripInput>;
  create?: InputMaybe<TripFeedbackCreateWithoutTripInput>;
};

export type TripFeedbackCreateOrConnectWithoutTripInput = {
  create: TripFeedbackCreateWithoutTripInput;
  where: TripFeedbackWhereUniqueInput;
};

export type TripFeedbackCreateOrConnectWithoutUserInput = {
  create: TripFeedbackCreateWithoutUserInput;
  where: TripFeedbackWhereUniqueInput;
};

export type TripFeedbackCreateWithoutTripInput = {
  accidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantRating: Scalars["Int"]["input"];
  driverNote?: InputMaybe<Scalars["String"]["input"]>;
  driverRating: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportType?: InputMaybe<IncidentReportType>;
  isAccidentReported: Scalars["Boolean"]["input"];
  isEmergencyVehicleDispatched: Scalars["Boolean"]["input"];
  isIncidentReported: Scalars["Boolean"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutTripFeedbackInput;
  vehicleNote?: InputMaybe<Scalars["String"]["input"]>;
  vehicleRating: Scalars["Int"]["input"];
};

export type TripFeedbackCreateWithoutUserInput = {
  accidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantRating: Scalars["Int"]["input"];
  driverNote?: InputMaybe<Scalars["String"]["input"]>;
  driverRating: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportType?: InputMaybe<IncidentReportType>;
  isAccidentReported: Scalars["Boolean"]["input"];
  isEmergencyVehicleDispatched: Scalars["Boolean"]["input"];
  isIncidentReported: Scalars["Boolean"]["input"];
  trip: TripCreateNestedOneWithoutTripFeedbackInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleNote?: InputMaybe<Scalars["String"]["input"]>;
  vehicleRating: Scalars["Int"]["input"];
};

export type TripFeedbackListRelationFilter = {
  every?: InputMaybe<TripFeedbackWhereInput>;
  none?: InputMaybe<TripFeedbackWhereInput>;
  some?: InputMaybe<TripFeedbackWhereInput>;
};

export type TripFeedbackRelationFilter = {
  is?: InputMaybe<TripFeedbackWhereInput>;
  isNot?: InputMaybe<TripFeedbackWhereInput>;
};

export type TripFeedbackScalarWhereInput = {
  AND?: InputMaybe<Array<TripFeedbackScalarWhereInput>>;
  NOT?: InputMaybe<Array<TripFeedbackScalarWhereInput>>;
  OR?: InputMaybe<Array<TripFeedbackScalarWhereInput>>;
  accidentReportNote?: InputMaybe<StringFilter>;
  busAttendantNote?: InputMaybe<StringFilter>;
  busAttendantRating?: InputMaybe<IntFilter>;
  driverNote?: InputMaybe<StringFilter>;
  driverRating?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  incidentReportNote?: InputMaybe<StringFilter>;
  incidentReportType?: InputMaybe<EnumIncidentReportTypeFilter>;
  isAccidentReported?: InputMaybe<BoolFilter>;
  isEmergencyVehicleDispatched?: InputMaybe<BoolFilter>;
  isIncidentReported?: InputMaybe<BoolFilter>;
  tripId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  vehicleNote?: InputMaybe<StringFilter>;
  vehicleRating?: InputMaybe<IntFilter>;
};

export type TripFeedbackUpdateManyMutationInput = {
  accidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantRating?: InputMaybe<Scalars["Int"]["input"]>;
  driverNote?: InputMaybe<Scalars["String"]["input"]>;
  driverRating?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportType?: InputMaybe<IncidentReportType>;
  isAccidentReported?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmergencyVehicleDispatched?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIncidentReported?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleNote?: InputMaybe<Scalars["String"]["input"]>;
  vehicleRating?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TripFeedbackUpdateManyWithWhereWithoutUserInput = {
  data: TripFeedbackUpdateManyMutationInput;
  where: TripFeedbackScalarWhereInput;
};

export type TripFeedbackUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<TripFeedbackWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripFeedbackCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<TripFeedbackCreateWithoutUserInput>>;
  createMany?: InputMaybe<TripFeedbackCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<TripFeedbackWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripFeedbackScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripFeedbackWhereUniqueInput>>;
  set?: InputMaybe<Array<TripFeedbackWhereUniqueInput>>;
  update?: InputMaybe<Array<TripFeedbackUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<
    Array<TripFeedbackUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<Array<TripFeedbackUpsertWithWhereUniqueWithoutUserInput>>;
};

export type TripFeedbackUpdateOneWithoutTripNestedInput = {
  connect?: InputMaybe<TripFeedbackWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripFeedbackCreateOrConnectWithoutTripInput>;
  create?: InputMaybe<TripFeedbackCreateWithoutTripInput>;
  delete?: InputMaybe<TripFeedbackWhereInput>;
  disconnect?: InputMaybe<TripFeedbackWhereInput>;
  update?: InputMaybe<TripFeedbackUpdateToOneWithWhereWithoutTripInput>;
  upsert?: InputMaybe<TripFeedbackUpsertWithoutTripInput>;
};

export type TripFeedbackUpdateToOneWithWhereWithoutTripInput = {
  data: TripFeedbackUpdateWithoutTripInput;
  where?: InputMaybe<TripFeedbackWhereInput>;
};

export type TripFeedbackUpdateWithWhereUniqueWithoutUserInput = {
  data: TripFeedbackUpdateWithoutUserInput;
  where: TripFeedbackWhereUniqueInput;
};

export type TripFeedbackUpdateWithoutTripInput = {
  accidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantRating?: InputMaybe<Scalars["Int"]["input"]>;
  driverNote?: InputMaybe<Scalars["String"]["input"]>;
  driverRating?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportType?: InputMaybe<IncidentReportType>;
  isAccidentReported?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmergencyVehicleDispatched?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIncidentReported?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTripFeedbackNestedInput>;
  vehicleNote?: InputMaybe<Scalars["String"]["input"]>;
  vehicleRating?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TripFeedbackUpdateWithoutUserInput = {
  accidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantNote?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantRating?: InputMaybe<Scalars["Int"]["input"]>;
  driverNote?: InputMaybe<Scalars["String"]["input"]>;
  driverRating?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportNote?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportType?: InputMaybe<IncidentReportType>;
  isAccidentReported?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmergencyVehicleDispatched?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIncidentReported?: InputMaybe<Scalars["Boolean"]["input"]>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripFeedbackNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleNote?: InputMaybe<Scalars["String"]["input"]>;
  vehicleRating?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TripFeedbackUpsertWithWhereUniqueWithoutUserInput = {
  create: TripFeedbackCreateWithoutUserInput;
  update: TripFeedbackUpdateWithoutUserInput;
  where: TripFeedbackWhereUniqueInput;
};

export type TripFeedbackUpsertWithoutTripInput = {
  create: TripFeedbackCreateWithoutTripInput;
  update: TripFeedbackUpdateWithoutTripInput;
  where?: InputMaybe<TripFeedbackWhereInput>;
};

export type TripFeedbackWhereInput = {
  AND?: InputMaybe<Array<TripFeedbackWhereInput>>;
  NOT?: InputMaybe<Array<TripFeedbackWhereInput>>;
  OR?: InputMaybe<Array<TripFeedbackWhereInput>>;
  accidentReportNote?: InputMaybe<StringFilter>;
  busAttendantNote?: InputMaybe<StringFilter>;
  busAttendantRating?: InputMaybe<IntFilter>;
  driverNote?: InputMaybe<StringFilter>;
  driverRating?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  incidentReportNote?: InputMaybe<StringFilter>;
  incidentReportType?: InputMaybe<EnumIncidentReportTypeFilter>;
  isAccidentReported?: InputMaybe<BoolFilter>;
  isEmergencyVehicleDispatched?: InputMaybe<BoolFilter>;
  isIncidentReported?: InputMaybe<BoolFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  vehicleNote?: InputMaybe<StringFilter>;
  vehicleRating?: InputMaybe<IntFilter>;
};

export type TripFeedbackWhereUniqueInput = {
  AND?: InputMaybe<Array<TripFeedbackWhereInput>>;
  NOT?: InputMaybe<Array<TripFeedbackWhereInput>>;
  OR?: InputMaybe<Array<TripFeedbackWhereInput>>;
  accidentReportNote?: InputMaybe<StringFilter>;
  busAttendantNote?: InputMaybe<StringFilter>;
  busAttendantRating?: InputMaybe<IntFilter>;
  driverNote?: InputMaybe<StringFilter>;
  driverRating?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  incidentReportNote?: InputMaybe<StringFilter>;
  incidentReportType?: InputMaybe<EnumIncidentReportTypeFilter>;
  isAccidentReported?: InputMaybe<BoolFilter>;
  isEmergencyVehicleDispatched?: InputMaybe<BoolFilter>;
  isIncidentReported?: InputMaybe<BoolFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  vehicleNote?: InputMaybe<StringFilter>;
  vehicleRating?: InputMaybe<IntFilter>;
};

export type TripForBaBd = {
  __typename?: "TripForBaBd";
  busAttendant?: Maybe<BusAttendantForBaBd>;
  directionality: RouteDirectionality;
  driver?: Maybe<DriverForBaBd>;
  id: Scalars["ID"]["output"];
  isForceEnded: Scalars["Boolean"]["output"];
  isForceStarted: Scalars["Boolean"]["output"];
  manualAttendanceEndOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  manualAttendanceStartOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  plannedEndTime: Scalars["DateTime"]["output"];
  plannedStartTime: Scalars["DateTime"]["output"];
  routeId: Scalars["ID"]["output"];
  status: TripStatus;
  stops?: Maybe<Array<StopForBaBd>>;
  stopsCount: Scalars["Int"]["output"];
  studentsCount: Scalars["Int"]["output"];
  studentsOnLeaveCount?: Maybe<Scalars["Int"]["output"]>;
  tripEndOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  tripStartOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  vehicle?: Maybe<VehicleForBaBd>;
};

export type TripForDriver = {
  __typename?: "TripForDriver";
  code?: Maybe<Scalars["String"]["output"]>;
  directionality: RouteDirectionality;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  plannedEndTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedSchoolStopDuration: Scalars["Int"]["output"];
  plannedStartTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedStudentStopDuration: Scalars["Int"]["output"];
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status: RouteStatus;
};

export type TripForSponsor = {
  __typename?: "TripForSponsor";
  actualDropOffTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualPickUpTime?: Maybe<Scalars["DateTime"]["output"]>;
  directionality: RouteDirectionality;
  dropOffTime?: Maybe<Scalars["DateTime"]["output"]>;
  hasHomeLocation?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  isOnLeave?: Maybe<Scalars["Boolean"]["output"]>;
  leaveRequestSubmittedDate?: Maybe<Scalars["DateTime"]["output"]>;
  pickUpTime?: Maybe<Scalars["DateTime"]["output"]>;
  schoolName?: Maybe<Scalars["String"]["output"]>;
  schoolNameAr?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<TodayTripStatus>;
  studentName?: Maybe<Scalars["String"]["output"]>;
  studentNameAr?: Maybe<Scalars["String"]["output"]>;
  tripHasUnknownLocationStop: Scalars["Boolean"]["output"];
};

export type TripForWeb = {
  __typename?: "TripForWeb";
  _count: TripCount;
  absentStudentCount?: Maybe<Scalars["Float"]["output"]>;
  actualEndTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualSchoolStopDuration?: Maybe<Scalars["Int"]["output"]>;
  actualStartTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualStudentStopDuration?: Maybe<Scalars["Int"]["output"]>;
  agent?: Maybe<Agent>;
  agentId?: Maybe<Scalars["String"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendant?: Maybe<BusAttendant>;
  busAttendantId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  date: Scalars["DateTime"]["output"];
  delayCategory?: Maybe<AlertLevel>;
  delayInMinutes?: Maybe<Scalars["Float"]["output"]>;
  delayTripStopAlerts?: Maybe<Array<DelayTripStopAlert>>;
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars["String"]["output"]>;
  expectedStudentCount?: Maybe<Scalars["Float"]["output"]>;
  gpsHealthStatus?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  isForceEnded: Scalars["Boolean"]["output"];
  isForceStarted: Scalars["Boolean"]["output"];
  isVehicleConnected?: Maybe<Scalars["Boolean"]["output"]>;
  latestVehicleEvent?: Maybe<LatestVehicleEventObjectType>;
  manualAttendanceEndOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  manualAttendanceStartOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  noPickUpAlerts?: Maybe<Array<NoPickUpAlert>>;
  onboardStudentCount?: Maybe<Scalars["Float"]["output"]>;
  plannedEndTime: Scalars["DateTime"]["output"];
  plannedSchoolStopDuration: Scalars["Int"]["output"];
  plannedStartTime: Scalars["DateTime"]["output"];
  plannedStudentStopDuration: Scalars["Int"]["output"];
  route?: Maybe<Route>;
  routeId?: Maybe<Scalars["String"]["output"]>;
  status: TripStatus;
  tripBusOverLoadedAlerts?: Maybe<Array<TripBusOverLoadedAlert>>;
  tripDelayAlerts?: Maybe<Array<TripDelayAlert>>;
  tripEndOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  tripFeedback?: Maybe<TripFeedback>;
  tripLogs?: Maybe<Array<TripLog>>;
  tripStartOffsetMinutes?: Maybe<Scalars["Int"]["output"]>;
  tripStops: Array<TripStopForWeb>;
  updatedAt: Scalars["DateTime"]["output"];
  vehicle?: Maybe<Vehicle>;
  vehicleAssignmentType?: Maybe<TripVehicleAssignmentType>;
  vehicleId?: Maybe<Scalars["String"]["output"]>;
  vehicleSafetyEvents?: Maybe<Array<VehicleSafetyEvent>>;
  wrongDropOffLocations?: Maybe<Array<WrongDropOffLocation>>;
};

export type TripListRelationFilter = {
  every?: InputMaybe<TripWhereInput>;
  none?: InputMaybe<TripWhereInput>;
  some?: InputMaybe<TripWhereInput>;
};

export type TripLog = {
  __typename?: "TripLog";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  newAgent?: Maybe<Agent>;
  newAgentId?: Maybe<Scalars["String"]["output"]>;
  newBusAttendant?: Maybe<BusAttendant>;
  newBusAttendantId?: Maybe<Scalars["String"]["output"]>;
  newDriver?: Maybe<Driver>;
  newDriverId?: Maybe<Scalars["String"]["output"]>;
  newTripStatus?: Maybe<TripStatus>;
  newVehicle?: Maybe<Vehicle>;
  newVehicleAssignmentType?: Maybe<TripVehicleAssignmentType>;
  newVehicleId?: Maybe<Scalars["String"]["output"]>;
  oldAgent?: Maybe<Agent>;
  oldAgentId?: Maybe<Scalars["String"]["output"]>;
  oldBusAttendant?: Maybe<BusAttendant>;
  oldBusAttendantId?: Maybe<Scalars["String"]["output"]>;
  oldDriver?: Maybe<Driver>;
  oldDriverId?: Maybe<Scalars["String"]["output"]>;
  oldTripStatus?: Maybe<TripStatus>;
  oldVehicle?: Maybe<Vehicle>;
  oldVehicleAssignmentType?: Maybe<TripVehicleAssignmentType>;
  oldVehicleId?: Maybe<Scalars["String"]["output"]>;
  trip: Trip;
  tripId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type TripLogCreateManyNewAgentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  newDriverId?: InputMaybe<Scalars["String"]["input"]>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  newVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  oldAgentId?: InputMaybe<Scalars["String"]["input"]>;
  oldBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  oldDriverId?: InputMaybe<Scalars["String"]["input"]>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripLogCreateManyNewAgentInputEnvelope = {
  data: Array<TripLogCreateManyNewAgentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripLogCreateManyNewBusAttendantInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentId?: InputMaybe<Scalars["String"]["input"]>;
  newDriverId?: InputMaybe<Scalars["String"]["input"]>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  newVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  oldAgentId?: InputMaybe<Scalars["String"]["input"]>;
  oldBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  oldDriverId?: InputMaybe<Scalars["String"]["input"]>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripLogCreateManyNewBusAttendantInputEnvelope = {
  data: Array<TripLogCreateManyNewBusAttendantInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripLogCreateManyNewDriverInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentId?: InputMaybe<Scalars["String"]["input"]>;
  newBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  newVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  oldAgentId?: InputMaybe<Scalars["String"]["input"]>;
  oldBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  oldDriverId?: InputMaybe<Scalars["String"]["input"]>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripLogCreateManyNewDriverInputEnvelope = {
  data: Array<TripLogCreateManyNewDriverInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripLogCreateManyNewVehicleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentId?: InputMaybe<Scalars["String"]["input"]>;
  newBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  newDriverId?: InputMaybe<Scalars["String"]["input"]>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgentId?: InputMaybe<Scalars["String"]["input"]>;
  oldBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  oldDriverId?: InputMaybe<Scalars["String"]["input"]>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripLogCreateManyNewVehicleInputEnvelope = {
  data: Array<TripLogCreateManyNewVehicleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripLogCreateManyOldAgentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentId?: InputMaybe<Scalars["String"]["input"]>;
  newBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  newDriverId?: InputMaybe<Scalars["String"]["input"]>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  newVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  oldBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  oldDriverId?: InputMaybe<Scalars["String"]["input"]>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripLogCreateManyOldAgentInputEnvelope = {
  data: Array<TripLogCreateManyOldAgentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripLogCreateManyOldBusAttendantInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentId?: InputMaybe<Scalars["String"]["input"]>;
  newBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  newDriverId?: InputMaybe<Scalars["String"]["input"]>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  newVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  oldAgentId?: InputMaybe<Scalars["String"]["input"]>;
  oldDriverId?: InputMaybe<Scalars["String"]["input"]>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripLogCreateManyOldBusAttendantInputEnvelope = {
  data: Array<TripLogCreateManyOldBusAttendantInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripLogCreateManyOldDriverInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentId?: InputMaybe<Scalars["String"]["input"]>;
  newBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  newDriverId?: InputMaybe<Scalars["String"]["input"]>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  newVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  oldAgentId?: InputMaybe<Scalars["String"]["input"]>;
  oldBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripLogCreateManyOldDriverInputEnvelope = {
  data: Array<TripLogCreateManyOldDriverInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripLogCreateManyOldVehicleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentId?: InputMaybe<Scalars["String"]["input"]>;
  newBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  newDriverId?: InputMaybe<Scalars["String"]["input"]>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  newVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  oldAgentId?: InputMaybe<Scalars["String"]["input"]>;
  oldBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  oldDriverId?: InputMaybe<Scalars["String"]["input"]>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripLogCreateManyOldVehicleInputEnvelope = {
  data: Array<TripLogCreateManyOldVehicleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripLogCreateManyTripInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentId?: InputMaybe<Scalars["String"]["input"]>;
  newBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  newDriverId?: InputMaybe<Scalars["String"]["input"]>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  newVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  oldAgentId?: InputMaybe<Scalars["String"]["input"]>;
  oldBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  oldDriverId?: InputMaybe<Scalars["String"]["input"]>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripLogCreateManyTripInputEnvelope = {
  data: Array<TripLogCreateManyTripInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripLogCreateManyUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgentId?: InputMaybe<Scalars["String"]["input"]>;
  newBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  newDriverId?: InputMaybe<Scalars["String"]["input"]>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  newVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  oldAgentId?: InputMaybe<Scalars["String"]["input"]>;
  oldBusAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  oldDriverId?: InputMaybe<Scalars["String"]["input"]>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldVehicleId?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripLogCreateManyUserInputEnvelope = {
  data: Array<TripLogCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripLogCreateNestedManyWithoutNewAgentInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutNewAgentInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutNewAgentInput>>;
  createMany?: InputMaybe<TripLogCreateManyNewAgentInputEnvelope>;
};

export type TripLogCreateNestedManyWithoutNewBusAttendantInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutNewBusAttendantInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutNewBusAttendantInput>>;
  createMany?: InputMaybe<TripLogCreateManyNewBusAttendantInputEnvelope>;
};

export type TripLogCreateNestedManyWithoutNewDriverInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutNewDriverInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutNewDriverInput>>;
  createMany?: InputMaybe<TripLogCreateManyNewDriverInputEnvelope>;
};

export type TripLogCreateNestedManyWithoutNewVehicleInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutNewVehicleInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutNewVehicleInput>>;
  createMany?: InputMaybe<TripLogCreateManyNewVehicleInputEnvelope>;
};

export type TripLogCreateNestedManyWithoutOldAgentInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutOldAgentInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutOldAgentInput>>;
  createMany?: InputMaybe<TripLogCreateManyOldAgentInputEnvelope>;
};

export type TripLogCreateNestedManyWithoutOldBusAttendantInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutOldBusAttendantInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutOldBusAttendantInput>>;
  createMany?: InputMaybe<TripLogCreateManyOldBusAttendantInputEnvelope>;
};

export type TripLogCreateNestedManyWithoutOldDriverInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutOldDriverInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutOldDriverInput>>;
  createMany?: InputMaybe<TripLogCreateManyOldDriverInputEnvelope>;
};

export type TripLogCreateNestedManyWithoutOldVehicleInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutOldVehicleInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutOldVehicleInput>>;
  createMany?: InputMaybe<TripLogCreateManyOldVehicleInputEnvelope>;
};

export type TripLogCreateNestedManyWithoutTripInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripLogCreateOrConnectWithoutTripInput>>;
  create?: InputMaybe<Array<TripLogCreateWithoutTripInput>>;
  createMany?: InputMaybe<TripLogCreateManyTripInputEnvelope>;
};

export type TripLogCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TripLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<TripLogCreateManyUserInputEnvelope>;
};

export type TripLogCreateOrConnectWithoutNewAgentInput = {
  create: TripLogCreateWithoutNewAgentInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogCreateOrConnectWithoutNewBusAttendantInput = {
  create: TripLogCreateWithoutNewBusAttendantInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogCreateOrConnectWithoutNewDriverInput = {
  create: TripLogCreateWithoutNewDriverInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogCreateOrConnectWithoutNewVehicleInput = {
  create: TripLogCreateWithoutNewVehicleInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogCreateOrConnectWithoutOldAgentInput = {
  create: TripLogCreateWithoutOldAgentInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogCreateOrConnectWithoutOldBusAttendantInput = {
  create: TripLogCreateWithoutOldBusAttendantInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogCreateOrConnectWithoutOldDriverInput = {
  create: TripLogCreateWithoutOldDriverInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogCreateOrConnectWithoutOldVehicleInput = {
  create: TripLogCreateWithoutOldVehicleInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogCreateOrConnectWithoutTripInput = {
  create: TripLogCreateWithoutTripInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogCreateOrConnectWithoutUserInput = {
  create: TripLogCreateWithoutUserInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogCreateWithoutNewAgentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutNewBusAttendantTripLogsInput>;
  newDriver?: InputMaybe<DriverCreateNestedOneWithoutNewDriverTripLogsInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleCreateNestedOneWithoutNewVehicleTripLogsInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentCreateNestedOneWithoutOldAgentTripLogsInput>;
  oldBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutOldBusAttendantTripLogsInput>;
  oldDriver?: InputMaybe<DriverCreateNestedOneWithoutOldDriverTripLogsInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleCreateNestedOneWithoutOldVehicleTripLogsInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip: TripCreateNestedOneWithoutTripLogsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutTripLogsInput>;
};

export type TripLogCreateWithoutNewBusAttendantInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentCreateNestedOneWithoutNewAgentTripLogsInput>;
  newDriver?: InputMaybe<DriverCreateNestedOneWithoutNewDriverTripLogsInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleCreateNestedOneWithoutNewVehicleTripLogsInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentCreateNestedOneWithoutOldAgentTripLogsInput>;
  oldBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutOldBusAttendantTripLogsInput>;
  oldDriver?: InputMaybe<DriverCreateNestedOneWithoutOldDriverTripLogsInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleCreateNestedOneWithoutOldVehicleTripLogsInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip: TripCreateNestedOneWithoutTripLogsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutTripLogsInput>;
};

export type TripLogCreateWithoutNewDriverInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentCreateNestedOneWithoutNewAgentTripLogsInput>;
  newBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutNewBusAttendantTripLogsInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleCreateNestedOneWithoutNewVehicleTripLogsInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentCreateNestedOneWithoutOldAgentTripLogsInput>;
  oldBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutOldBusAttendantTripLogsInput>;
  oldDriver?: InputMaybe<DriverCreateNestedOneWithoutOldDriverTripLogsInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleCreateNestedOneWithoutOldVehicleTripLogsInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip: TripCreateNestedOneWithoutTripLogsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutTripLogsInput>;
};

export type TripLogCreateWithoutNewVehicleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentCreateNestedOneWithoutNewAgentTripLogsInput>;
  newBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutNewBusAttendantTripLogsInput>;
  newDriver?: InputMaybe<DriverCreateNestedOneWithoutNewDriverTripLogsInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentCreateNestedOneWithoutOldAgentTripLogsInput>;
  oldBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutOldBusAttendantTripLogsInput>;
  oldDriver?: InputMaybe<DriverCreateNestedOneWithoutOldDriverTripLogsInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleCreateNestedOneWithoutOldVehicleTripLogsInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip: TripCreateNestedOneWithoutTripLogsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutTripLogsInput>;
};

export type TripLogCreateWithoutOldAgentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentCreateNestedOneWithoutNewAgentTripLogsInput>;
  newBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutNewBusAttendantTripLogsInput>;
  newDriver?: InputMaybe<DriverCreateNestedOneWithoutNewDriverTripLogsInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleCreateNestedOneWithoutNewVehicleTripLogsInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutOldBusAttendantTripLogsInput>;
  oldDriver?: InputMaybe<DriverCreateNestedOneWithoutOldDriverTripLogsInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleCreateNestedOneWithoutOldVehicleTripLogsInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip: TripCreateNestedOneWithoutTripLogsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutTripLogsInput>;
};

export type TripLogCreateWithoutOldBusAttendantInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentCreateNestedOneWithoutNewAgentTripLogsInput>;
  newBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutNewBusAttendantTripLogsInput>;
  newDriver?: InputMaybe<DriverCreateNestedOneWithoutNewDriverTripLogsInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleCreateNestedOneWithoutNewVehicleTripLogsInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentCreateNestedOneWithoutOldAgentTripLogsInput>;
  oldDriver?: InputMaybe<DriverCreateNestedOneWithoutOldDriverTripLogsInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleCreateNestedOneWithoutOldVehicleTripLogsInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip: TripCreateNestedOneWithoutTripLogsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutTripLogsInput>;
};

export type TripLogCreateWithoutOldDriverInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentCreateNestedOneWithoutNewAgentTripLogsInput>;
  newBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutNewBusAttendantTripLogsInput>;
  newDriver?: InputMaybe<DriverCreateNestedOneWithoutNewDriverTripLogsInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleCreateNestedOneWithoutNewVehicleTripLogsInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentCreateNestedOneWithoutOldAgentTripLogsInput>;
  oldBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutOldBusAttendantTripLogsInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleCreateNestedOneWithoutOldVehicleTripLogsInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip: TripCreateNestedOneWithoutTripLogsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutTripLogsInput>;
};

export type TripLogCreateWithoutOldVehicleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentCreateNestedOneWithoutNewAgentTripLogsInput>;
  newBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutNewBusAttendantTripLogsInput>;
  newDriver?: InputMaybe<DriverCreateNestedOneWithoutNewDriverTripLogsInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleCreateNestedOneWithoutNewVehicleTripLogsInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentCreateNestedOneWithoutOldAgentTripLogsInput>;
  oldBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutOldBusAttendantTripLogsInput>;
  oldDriver?: InputMaybe<DriverCreateNestedOneWithoutOldDriverTripLogsInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip: TripCreateNestedOneWithoutTripLogsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutTripLogsInput>;
};

export type TripLogCreateWithoutTripInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentCreateNestedOneWithoutNewAgentTripLogsInput>;
  newBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutNewBusAttendantTripLogsInput>;
  newDriver?: InputMaybe<DriverCreateNestedOneWithoutNewDriverTripLogsInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleCreateNestedOneWithoutNewVehicleTripLogsInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentCreateNestedOneWithoutOldAgentTripLogsInput>;
  oldBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutOldBusAttendantTripLogsInput>;
  oldDriver?: InputMaybe<DriverCreateNestedOneWithoutOldDriverTripLogsInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleCreateNestedOneWithoutOldVehicleTripLogsInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserCreateNestedOneWithoutTripLogsInput>;
};

export type TripLogCreateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentCreateNestedOneWithoutNewAgentTripLogsInput>;
  newBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutNewBusAttendantTripLogsInput>;
  newDriver?: InputMaybe<DriverCreateNestedOneWithoutNewDriverTripLogsInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleCreateNestedOneWithoutNewVehicleTripLogsInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentCreateNestedOneWithoutOldAgentTripLogsInput>;
  oldBusAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutOldBusAttendantTripLogsInput>;
  oldDriver?: InputMaybe<DriverCreateNestedOneWithoutOldDriverTripLogsInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleCreateNestedOneWithoutOldVehicleTripLogsInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip: TripCreateNestedOneWithoutTripLogsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripLogListRelationFilter = {
  every?: InputMaybe<TripLogWhereInput>;
  none?: InputMaybe<TripLogWhereInput>;
  some?: InputMaybe<TripLogWhereInput>;
};

export type TripLogScalarWhereInput = {
  AND?: InputMaybe<Array<TripLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<TripLogScalarWhereInput>>;
  OR?: InputMaybe<Array<TripLogScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  newAgentId?: InputMaybe<StringFilter>;
  newBusAttendantId?: InputMaybe<StringFilter>;
  newDriverId?: InputMaybe<StringFilter>;
  newTripStatus?: InputMaybe<EnumTripStatusFilter>;
  newVehicleAssignmentType?: InputMaybe<EnumTripVehicleAssignmentTypeFilter>;
  newVehicleId?: InputMaybe<StringFilter>;
  oldAgentId?: InputMaybe<StringFilter>;
  oldBusAttendantId?: InputMaybe<StringFilter>;
  oldDriverId?: InputMaybe<StringFilter>;
  oldTripStatus?: InputMaybe<EnumTripStatusFilter>;
  oldVehicleAssignmentType?: InputMaybe<EnumTripVehicleAssignmentTypeFilter>;
  oldVehicleId?: InputMaybe<StringFilter>;
  tripId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TripLogUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripLogUpdateManyWithWhereWithoutNewAgentInput = {
  data: TripLogUpdateManyMutationInput;
  where: TripLogScalarWhereInput;
};

export type TripLogUpdateManyWithWhereWithoutNewBusAttendantInput = {
  data: TripLogUpdateManyMutationInput;
  where: TripLogScalarWhereInput;
};

export type TripLogUpdateManyWithWhereWithoutNewDriverInput = {
  data: TripLogUpdateManyMutationInput;
  where: TripLogScalarWhereInput;
};

export type TripLogUpdateManyWithWhereWithoutNewVehicleInput = {
  data: TripLogUpdateManyMutationInput;
  where: TripLogScalarWhereInput;
};

export type TripLogUpdateManyWithWhereWithoutOldAgentInput = {
  data: TripLogUpdateManyMutationInput;
  where: TripLogScalarWhereInput;
};

export type TripLogUpdateManyWithWhereWithoutOldBusAttendantInput = {
  data: TripLogUpdateManyMutationInput;
  where: TripLogScalarWhereInput;
};

export type TripLogUpdateManyWithWhereWithoutOldDriverInput = {
  data: TripLogUpdateManyMutationInput;
  where: TripLogScalarWhereInput;
};

export type TripLogUpdateManyWithWhereWithoutOldVehicleInput = {
  data: TripLogUpdateManyMutationInput;
  where: TripLogScalarWhereInput;
};

export type TripLogUpdateManyWithWhereWithoutTripInput = {
  data: TripLogUpdateManyMutationInput;
  where: TripLogScalarWhereInput;
};

export type TripLogUpdateManyWithWhereWithoutUserInput = {
  data: TripLogUpdateManyMutationInput;
  where: TripLogScalarWhereInput;
};

export type TripLogUpdateManyWithoutNewAgentNestedInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutNewAgentInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutNewAgentInput>>;
  createMany?: InputMaybe<TripLogCreateManyNewAgentInputEnvelope>;
  delete?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  set?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  update?: InputMaybe<Array<TripLogUpdateWithWhereUniqueWithoutNewAgentInput>>;
  updateMany?: InputMaybe<
    Array<TripLogUpdateManyWithWhereWithoutNewAgentInput>
  >;
  upsert?: InputMaybe<Array<TripLogUpsertWithWhereUniqueWithoutNewAgentInput>>;
};

export type TripLogUpdateManyWithoutNewBusAttendantNestedInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutNewBusAttendantInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutNewBusAttendantInput>>;
  createMany?: InputMaybe<TripLogCreateManyNewBusAttendantInputEnvelope>;
  delete?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  set?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TripLogUpdateWithWhereUniqueWithoutNewBusAttendantInput>
  >;
  updateMany?: InputMaybe<
    Array<TripLogUpdateManyWithWhereWithoutNewBusAttendantInput>
  >;
  upsert?: InputMaybe<
    Array<TripLogUpsertWithWhereUniqueWithoutNewBusAttendantInput>
  >;
};

export type TripLogUpdateManyWithoutNewDriverNestedInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutNewDriverInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutNewDriverInput>>;
  createMany?: InputMaybe<TripLogCreateManyNewDriverInputEnvelope>;
  delete?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  set?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  update?: InputMaybe<Array<TripLogUpdateWithWhereUniqueWithoutNewDriverInput>>;
  updateMany?: InputMaybe<
    Array<TripLogUpdateManyWithWhereWithoutNewDriverInput>
  >;
  upsert?: InputMaybe<Array<TripLogUpsertWithWhereUniqueWithoutNewDriverInput>>;
};

export type TripLogUpdateManyWithoutNewVehicleNestedInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutNewVehicleInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutNewVehicleInput>>;
  createMany?: InputMaybe<TripLogCreateManyNewVehicleInputEnvelope>;
  delete?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  set?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TripLogUpdateWithWhereUniqueWithoutNewVehicleInput>
  >;
  updateMany?: InputMaybe<
    Array<TripLogUpdateManyWithWhereWithoutNewVehicleInput>
  >;
  upsert?: InputMaybe<
    Array<TripLogUpsertWithWhereUniqueWithoutNewVehicleInput>
  >;
};

export type TripLogUpdateManyWithoutOldAgentNestedInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutOldAgentInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutOldAgentInput>>;
  createMany?: InputMaybe<TripLogCreateManyOldAgentInputEnvelope>;
  delete?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  set?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  update?: InputMaybe<Array<TripLogUpdateWithWhereUniqueWithoutOldAgentInput>>;
  updateMany?: InputMaybe<
    Array<TripLogUpdateManyWithWhereWithoutOldAgentInput>
  >;
  upsert?: InputMaybe<Array<TripLogUpsertWithWhereUniqueWithoutOldAgentInput>>;
};

export type TripLogUpdateManyWithoutOldBusAttendantNestedInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutOldBusAttendantInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutOldBusAttendantInput>>;
  createMany?: InputMaybe<TripLogCreateManyOldBusAttendantInputEnvelope>;
  delete?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  set?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TripLogUpdateWithWhereUniqueWithoutOldBusAttendantInput>
  >;
  updateMany?: InputMaybe<
    Array<TripLogUpdateManyWithWhereWithoutOldBusAttendantInput>
  >;
  upsert?: InputMaybe<
    Array<TripLogUpsertWithWhereUniqueWithoutOldBusAttendantInput>
  >;
};

export type TripLogUpdateManyWithoutOldDriverNestedInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutOldDriverInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutOldDriverInput>>;
  createMany?: InputMaybe<TripLogCreateManyOldDriverInputEnvelope>;
  delete?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  set?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  update?: InputMaybe<Array<TripLogUpdateWithWhereUniqueWithoutOldDriverInput>>;
  updateMany?: InputMaybe<
    Array<TripLogUpdateManyWithWhereWithoutOldDriverInput>
  >;
  upsert?: InputMaybe<Array<TripLogUpsertWithWhereUniqueWithoutOldDriverInput>>;
};

export type TripLogUpdateManyWithoutOldVehicleNestedInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripLogCreateOrConnectWithoutOldVehicleInput>
  >;
  create?: InputMaybe<Array<TripLogCreateWithoutOldVehicleInput>>;
  createMany?: InputMaybe<TripLogCreateManyOldVehicleInputEnvelope>;
  delete?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  set?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TripLogUpdateWithWhereUniqueWithoutOldVehicleInput>
  >;
  updateMany?: InputMaybe<
    Array<TripLogUpdateManyWithWhereWithoutOldVehicleInput>
  >;
  upsert?: InputMaybe<
    Array<TripLogUpsertWithWhereUniqueWithoutOldVehicleInput>
  >;
};

export type TripLogUpdateManyWithoutTripNestedInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripLogCreateOrConnectWithoutTripInput>>;
  create?: InputMaybe<Array<TripLogCreateWithoutTripInput>>;
  createMany?: InputMaybe<TripLogCreateManyTripInputEnvelope>;
  delete?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  set?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  update?: InputMaybe<Array<TripLogUpdateWithWhereUniqueWithoutTripInput>>;
  updateMany?: InputMaybe<Array<TripLogUpdateManyWithWhereWithoutTripInput>>;
  upsert?: InputMaybe<Array<TripLogUpsertWithWhereUniqueWithoutTripInput>>;
};

export type TripLogUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TripLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<TripLogCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  set?: InputMaybe<Array<TripLogWhereUniqueInput>>;
  update?: InputMaybe<Array<TripLogUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<TripLogUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<TripLogUpsertWithWhereUniqueWithoutUserInput>>;
};

export type TripLogUpdateWithWhereUniqueWithoutNewAgentInput = {
  data: TripLogUpdateWithoutNewAgentInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpdateWithWhereUniqueWithoutNewBusAttendantInput = {
  data: TripLogUpdateWithoutNewBusAttendantInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpdateWithWhereUniqueWithoutNewDriverInput = {
  data: TripLogUpdateWithoutNewDriverInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpdateWithWhereUniqueWithoutNewVehicleInput = {
  data: TripLogUpdateWithoutNewVehicleInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpdateWithWhereUniqueWithoutOldAgentInput = {
  data: TripLogUpdateWithoutOldAgentInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpdateWithWhereUniqueWithoutOldBusAttendantInput = {
  data: TripLogUpdateWithoutOldBusAttendantInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpdateWithWhereUniqueWithoutOldDriverInput = {
  data: TripLogUpdateWithoutOldDriverInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpdateWithWhereUniqueWithoutOldVehicleInput = {
  data: TripLogUpdateWithoutOldVehicleInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpdateWithWhereUniqueWithoutTripInput = {
  data: TripLogUpdateWithoutTripInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpdateWithWhereUniqueWithoutUserInput = {
  data: TripLogUpdateWithoutUserInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpdateWithoutNewAgentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutNewBusAttendantTripLogsNestedInput>;
  newDriver?: InputMaybe<DriverUpdateOneWithoutNewDriverTripLogsNestedInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleUpdateOneWithoutNewVehicleTripLogsNestedInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentUpdateOneWithoutOldAgentTripLogsNestedInput>;
  oldBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutOldBusAttendantTripLogsNestedInput>;
  oldDriver?: InputMaybe<DriverUpdateOneWithoutOldDriverTripLogsNestedInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleUpdateOneWithoutOldVehicleTripLogsNestedInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripLogsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutTripLogsNestedInput>;
};

export type TripLogUpdateWithoutNewBusAttendantInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentUpdateOneWithoutNewAgentTripLogsNestedInput>;
  newDriver?: InputMaybe<DriverUpdateOneWithoutNewDriverTripLogsNestedInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleUpdateOneWithoutNewVehicleTripLogsNestedInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentUpdateOneWithoutOldAgentTripLogsNestedInput>;
  oldBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutOldBusAttendantTripLogsNestedInput>;
  oldDriver?: InputMaybe<DriverUpdateOneWithoutOldDriverTripLogsNestedInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleUpdateOneWithoutOldVehicleTripLogsNestedInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripLogsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutTripLogsNestedInput>;
};

export type TripLogUpdateWithoutNewDriverInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentUpdateOneWithoutNewAgentTripLogsNestedInput>;
  newBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutNewBusAttendantTripLogsNestedInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleUpdateOneWithoutNewVehicleTripLogsNestedInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentUpdateOneWithoutOldAgentTripLogsNestedInput>;
  oldBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutOldBusAttendantTripLogsNestedInput>;
  oldDriver?: InputMaybe<DriverUpdateOneWithoutOldDriverTripLogsNestedInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleUpdateOneWithoutOldVehicleTripLogsNestedInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripLogsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutTripLogsNestedInput>;
};

export type TripLogUpdateWithoutNewVehicleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentUpdateOneWithoutNewAgentTripLogsNestedInput>;
  newBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutNewBusAttendantTripLogsNestedInput>;
  newDriver?: InputMaybe<DriverUpdateOneWithoutNewDriverTripLogsNestedInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentUpdateOneWithoutOldAgentTripLogsNestedInput>;
  oldBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutOldBusAttendantTripLogsNestedInput>;
  oldDriver?: InputMaybe<DriverUpdateOneWithoutOldDriverTripLogsNestedInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleUpdateOneWithoutOldVehicleTripLogsNestedInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripLogsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutTripLogsNestedInput>;
};

export type TripLogUpdateWithoutOldAgentInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentUpdateOneWithoutNewAgentTripLogsNestedInput>;
  newBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutNewBusAttendantTripLogsNestedInput>;
  newDriver?: InputMaybe<DriverUpdateOneWithoutNewDriverTripLogsNestedInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleUpdateOneWithoutNewVehicleTripLogsNestedInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutOldBusAttendantTripLogsNestedInput>;
  oldDriver?: InputMaybe<DriverUpdateOneWithoutOldDriverTripLogsNestedInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleUpdateOneWithoutOldVehicleTripLogsNestedInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripLogsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutTripLogsNestedInput>;
};

export type TripLogUpdateWithoutOldBusAttendantInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentUpdateOneWithoutNewAgentTripLogsNestedInput>;
  newBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutNewBusAttendantTripLogsNestedInput>;
  newDriver?: InputMaybe<DriverUpdateOneWithoutNewDriverTripLogsNestedInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleUpdateOneWithoutNewVehicleTripLogsNestedInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentUpdateOneWithoutOldAgentTripLogsNestedInput>;
  oldDriver?: InputMaybe<DriverUpdateOneWithoutOldDriverTripLogsNestedInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleUpdateOneWithoutOldVehicleTripLogsNestedInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripLogsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutTripLogsNestedInput>;
};

export type TripLogUpdateWithoutOldDriverInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentUpdateOneWithoutNewAgentTripLogsNestedInput>;
  newBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutNewBusAttendantTripLogsNestedInput>;
  newDriver?: InputMaybe<DriverUpdateOneWithoutNewDriverTripLogsNestedInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleUpdateOneWithoutNewVehicleTripLogsNestedInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentUpdateOneWithoutOldAgentTripLogsNestedInput>;
  oldBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutOldBusAttendantTripLogsNestedInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleUpdateOneWithoutOldVehicleTripLogsNestedInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripLogsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutTripLogsNestedInput>;
};

export type TripLogUpdateWithoutOldVehicleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentUpdateOneWithoutNewAgentTripLogsNestedInput>;
  newBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutNewBusAttendantTripLogsNestedInput>;
  newDriver?: InputMaybe<DriverUpdateOneWithoutNewDriverTripLogsNestedInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleUpdateOneWithoutNewVehicleTripLogsNestedInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentUpdateOneWithoutOldAgentTripLogsNestedInput>;
  oldBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutOldBusAttendantTripLogsNestedInput>;
  oldDriver?: InputMaybe<DriverUpdateOneWithoutOldDriverTripLogsNestedInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripLogsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutTripLogsNestedInput>;
};

export type TripLogUpdateWithoutTripInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentUpdateOneWithoutNewAgentTripLogsNestedInput>;
  newBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutNewBusAttendantTripLogsNestedInput>;
  newDriver?: InputMaybe<DriverUpdateOneWithoutNewDriverTripLogsNestedInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleUpdateOneWithoutNewVehicleTripLogsNestedInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentUpdateOneWithoutOldAgentTripLogsNestedInput>;
  oldBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutOldBusAttendantTripLogsNestedInput>;
  oldDriver?: InputMaybe<DriverUpdateOneWithoutOldDriverTripLogsNestedInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleUpdateOneWithoutOldVehicleTripLogsNestedInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneWithoutTripLogsNestedInput>;
};

export type TripLogUpdateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentUpdateOneWithoutNewAgentTripLogsNestedInput>;
  newBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutNewBusAttendantTripLogsNestedInput>;
  newDriver?: InputMaybe<DriverUpdateOneWithoutNewDriverTripLogsNestedInput>;
  newTripStatus?: InputMaybe<TripStatus>;
  newVehicle?: InputMaybe<VehicleUpdateOneWithoutNewVehicleTripLogsNestedInput>;
  newVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  oldAgent?: InputMaybe<AgentUpdateOneWithoutOldAgentTripLogsNestedInput>;
  oldBusAttendant?: InputMaybe<BusAttendantUpdateOneWithoutOldBusAttendantTripLogsNestedInput>;
  oldDriver?: InputMaybe<DriverUpdateOneWithoutOldDriverTripLogsNestedInput>;
  oldTripStatus?: InputMaybe<TripStatus>;
  oldVehicle?: InputMaybe<VehicleUpdateOneWithoutOldVehicleTripLogsNestedInput>;
  oldVehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripLogsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripLogUpsertWithWhereUniqueWithoutNewAgentInput = {
  create: TripLogCreateWithoutNewAgentInput;
  update: TripLogUpdateWithoutNewAgentInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpsertWithWhereUniqueWithoutNewBusAttendantInput = {
  create: TripLogCreateWithoutNewBusAttendantInput;
  update: TripLogUpdateWithoutNewBusAttendantInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpsertWithWhereUniqueWithoutNewDriverInput = {
  create: TripLogCreateWithoutNewDriverInput;
  update: TripLogUpdateWithoutNewDriverInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpsertWithWhereUniqueWithoutNewVehicleInput = {
  create: TripLogCreateWithoutNewVehicleInput;
  update: TripLogUpdateWithoutNewVehicleInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpsertWithWhereUniqueWithoutOldAgentInput = {
  create: TripLogCreateWithoutOldAgentInput;
  update: TripLogUpdateWithoutOldAgentInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpsertWithWhereUniqueWithoutOldBusAttendantInput = {
  create: TripLogCreateWithoutOldBusAttendantInput;
  update: TripLogUpdateWithoutOldBusAttendantInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpsertWithWhereUniqueWithoutOldDriverInput = {
  create: TripLogCreateWithoutOldDriverInput;
  update: TripLogUpdateWithoutOldDriverInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpsertWithWhereUniqueWithoutOldVehicleInput = {
  create: TripLogCreateWithoutOldVehicleInput;
  update: TripLogUpdateWithoutOldVehicleInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpsertWithWhereUniqueWithoutTripInput = {
  create: TripLogCreateWithoutTripInput;
  update: TripLogUpdateWithoutTripInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogUpsertWithWhereUniqueWithoutUserInput = {
  create: TripLogCreateWithoutUserInput;
  update: TripLogUpdateWithoutUserInput;
  where: TripLogWhereUniqueInput;
};

export type TripLogWhereInput = {
  AND?: InputMaybe<Array<TripLogWhereInput>>;
  NOT?: InputMaybe<Array<TripLogWhereInput>>;
  OR?: InputMaybe<Array<TripLogWhereInput>>;
  id?: InputMaybe<StringFilter>;
  newAgent?: InputMaybe<AgentRelationFilter>;
  newAgentId?: InputMaybe<StringFilter>;
  newBusAttendant?: InputMaybe<BusAttendantRelationFilter>;
  newBusAttendantId?: InputMaybe<StringFilter>;
  newDriver?: InputMaybe<DriverRelationFilter>;
  newDriverId?: InputMaybe<StringFilter>;
  newTripStatus?: InputMaybe<EnumTripStatusFilter>;
  newVehicle?: InputMaybe<VehicleRelationFilter>;
  newVehicleAssignmentType?: InputMaybe<EnumTripVehicleAssignmentTypeFilter>;
  newVehicleId?: InputMaybe<StringFilter>;
  oldAgent?: InputMaybe<AgentRelationFilter>;
  oldAgentId?: InputMaybe<StringFilter>;
  oldBusAttendant?: InputMaybe<BusAttendantRelationFilter>;
  oldBusAttendantId?: InputMaybe<StringFilter>;
  oldDriver?: InputMaybe<DriverRelationFilter>;
  oldDriverId?: InputMaybe<StringFilter>;
  oldTripStatus?: InputMaybe<EnumTripStatusFilter>;
  oldVehicle?: InputMaybe<VehicleRelationFilter>;
  oldVehicleAssignmentType?: InputMaybe<EnumTripVehicleAssignmentTypeFilter>;
  oldVehicleId?: InputMaybe<StringFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TripLogWhereUniqueInput = {
  AND?: InputMaybe<Array<TripLogWhereInput>>;
  NOT?: InputMaybe<Array<TripLogWhereInput>>;
  OR?: InputMaybe<Array<TripLogWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  newAgent?: InputMaybe<AgentRelationFilter>;
  newAgentId?: InputMaybe<StringFilter>;
  newBusAttendant?: InputMaybe<BusAttendantRelationFilter>;
  newBusAttendantId?: InputMaybe<StringFilter>;
  newDriver?: InputMaybe<DriverRelationFilter>;
  newDriverId?: InputMaybe<StringFilter>;
  newTripStatus?: InputMaybe<EnumTripStatusFilter>;
  newVehicle?: InputMaybe<VehicleRelationFilter>;
  newVehicleAssignmentType?: InputMaybe<EnumTripVehicleAssignmentTypeFilter>;
  newVehicleId?: InputMaybe<StringFilter>;
  oldAgent?: InputMaybe<AgentRelationFilter>;
  oldAgentId?: InputMaybe<StringFilter>;
  oldBusAttendant?: InputMaybe<BusAttendantRelationFilter>;
  oldBusAttendantId?: InputMaybe<StringFilter>;
  oldDriver?: InputMaybe<DriverRelationFilter>;
  oldDriverId?: InputMaybe<StringFilter>;
  oldTripStatus?: InputMaybe<EnumTripStatusFilter>;
  oldVehicle?: InputMaybe<VehicleRelationFilter>;
  oldVehicleAssignmentType?: InputMaybe<EnumTripVehicleAssignmentTypeFilter>;
  oldVehicleId?: InputMaybe<StringFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TripManifest = {
  __typename?: "TripManifest";
  busAttendant?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  direction: RouteDirectionality;
  driver?: Maybe<Scalars["String"]["output"]>;
  routeName: Scalars["String"]["output"];
  stops: Array<TripManifestStop>;
  vehicleLicensePlate?: Maybe<Scalars["String"]["output"]>;
};

export type TripManifestLatLon = {
  __typename?: "TripManifestLatLon";
  lat: Scalars["Float"]["output"];
  lon: Scalars["Float"]["output"];
};

export type TripManifestStop = {
  __typename?: "TripManifestStop";
  arrivalTime: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  location: TripManifestLatLon;
  phone?: Maybe<Scalars["String"]["output"]>;
  stopName: Scalars["String"]["output"];
  stopType: TripManifestStopType;
  students: Array<TripManifestStopStudent>;
};

export type TripManifestStopStudent = {
  __typename?: "TripManifestStopStudent";
  class: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  schoolRegistrationInternalStatus?: Maybe<SchoolRegistrationInternalStatus>;
};

export enum TripManifestStopType {
  HOME = "HOME",
  SCHOOL = "SCHOOL",
}

export type TripOrderByForWeb = {
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  route?: InputMaybe<TripOrderByRouteForWeb>;
  status?: InputMaybe<SortOrder>;
};

export type TripOrderByRouteForWeb = {
  directionality?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  routeGroup?: InputMaybe<TripOrderByRouteGroupForWeb>;
};

export type TripOrderByRouteGroupForWeb = {
  name?: InputMaybe<SortOrderInput>;
};

export type TripPlannedForSponsor = {
  __typename?: "TripPlannedForSponsor";
  code?: Maybe<Scalars["String"]["output"]>;
  directionality: RouteDirectionality;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  plannedEndTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedSchoolStopDuration: Scalars["Int"]["output"];
  plannedStartTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedStudentStopDuration: Scalars["Int"]["output"];
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status: RouteStatus;
};

export type TripRelationFilter = {
  is?: InputMaybe<TripWhereInput>;
  isNot?: InputMaybe<TripWhereInput>;
};

export type TripScalarWhereInput = {
  AND?: InputMaybe<Array<TripScalarWhereInput>>;
  NOT?: InputMaybe<Array<TripScalarWhereInput>>;
  OR?: InputMaybe<Array<TripScalarWhereInput>>;
  actualEndTime?: InputMaybe<DateTimeFilter>;
  actualSchoolStopDuration?: InputMaybe<IntFilter>;
  actualStartTime?: InputMaybe<DateTimeFilter>;
  actualStudentStopDuration?: InputMaybe<IntFilter>;
  agentId?: InputMaybe<StringFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isForceEnded?: InputMaybe<BoolFilter>;
  isForceStarted?: InputMaybe<BoolFilter>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<IntFilter>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<IntFilter>;
  plannedEndTime?: InputMaybe<DateTimeFilter>;
  plannedSchoolStopDuration?: InputMaybe<IntFilter>;
  plannedStartTime?: InputMaybe<DateTimeFilter>;
  plannedStudentStopDuration?: InputMaybe<IntFilter>;
  routeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumTripStatusFilter>;
  tripEndOffsetMinutes?: InputMaybe<IntFilter>;
  tripStartOffsetMinutes?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleAssignmentType?: InputMaybe<EnumTripVehicleAssignmentTypeFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export enum TripStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  PARTIAL_COMPLETED = "PARTIAL_COMPLETED",
  PENDING = "PENDING",
}

export type TripStop = {
  __typename?: "TripStop";
  _count: TripStopCount;
  actualArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualCompletedTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualLat?: Maybe<Scalars["Float"]["output"]>;
  actualLon?: Maybe<Scalars["Float"]["output"]>;
  actualOrder?: Maybe<Scalars["Int"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedAttendanceStatus: AttendanceStatus;
  confirmedDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedNoShowTime?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  delayInMinutes?: Maybe<Scalars["Int"]["output"]>;
  delayTripStopAlerts?: Maybe<Array<DelayTripStopAlert>>;
  detectedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  estimatedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isCompletedNotificationSent: Scalars["Boolean"]["output"];
  isInTransit?: Maybe<Scalars["Boolean"]["output"]>;
  isInTransitNotificationSent: Scalars["Boolean"]["output"];
  isInaccurateLocation: Scalars["Boolean"]["output"];
  isNearbyDropoffNotificationSent: Scalars["Boolean"]["output"];
  isNearbyPickupNotificationSent: Scalars["Boolean"]["output"];
  isOnLeave: Scalars["Boolean"]["output"];
  isVisitedNotificationSent: Scalars["Boolean"]["output"];
  plannedArrivalTime: Scalars["DateTime"]["output"];
  plannedLat?: Maybe<Scalars["Float"]["output"]>;
  plannedLon?: Maybe<Scalars["Float"]["output"]>;
  plannedOrder?: Maybe<Scalars["Int"]["output"]>;
  routeStop: RouteStop;
  routeStopId: Scalars["String"]["output"];
  schoolTripStop?: Maybe<TripStop>;
  schoolTripStopId?: Maybe<Scalars["String"]["output"]>;
  schoolTripStopStatus: SchoolTripStopStatus;
  studentStopLogs?: Maybe<Array<StudentStopLog>>;
  studentTripStopStatus: StudentTripStopStatus;
  studentsTripStops?: Maybe<Array<TripStop>>;
  trip: Trip;
  tripId: Scalars["String"]["output"];
  unknownDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type TripStopCount = {
  __typename?: "TripStopCount";
  delayTripStopAlerts: Scalars["Int"]["output"];
  studentStopLogs: Scalars["Int"]["output"];
  studentsTripStops: Scalars["Int"]["output"];
};

export type TripStopCreateManyRouteStopInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime: Scalars["DateTime"]["input"];
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  schoolTripStopId?: InputMaybe<Scalars["String"]["input"]>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  tripId: Scalars["String"]["input"];
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopCreateManyRouteStopInputEnvelope = {
  data: Array<TripStopCreateManyRouteStopInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripStopCreateManySchoolTripStopInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime: Scalars["DateTime"]["input"];
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  routeStopId: Scalars["String"]["input"];
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  tripId: Scalars["String"]["input"];
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopCreateManySchoolTripStopInputEnvelope = {
  data: Array<TripStopCreateManySchoolTripStopInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripStopCreateManyTripInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime: Scalars["DateTime"]["input"];
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  routeStopId: Scalars["String"]["input"];
  schoolTripStopId?: InputMaybe<Scalars["String"]["input"]>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopCreateManyTripInputEnvelope = {
  data: Array<TripStopCreateManyTripInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TripStopCreateNestedManyWithoutRouteStopInput = {
  connect?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripStopCreateOrConnectWithoutRouteStopInput>
  >;
  create?: InputMaybe<Array<TripStopCreateWithoutRouteStopInput>>;
  createMany?: InputMaybe<TripStopCreateManyRouteStopInputEnvelope>;
};

export type TripStopCreateNestedManyWithoutSchoolTripStopInput = {
  connect?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripStopCreateOrConnectWithoutSchoolTripStopInput>
  >;
  create?: InputMaybe<Array<TripStopCreateWithoutSchoolTripStopInput>>;
  createMany?: InputMaybe<TripStopCreateManySchoolTripStopInputEnvelope>;
};

export type TripStopCreateNestedManyWithoutTripInput = {
  connect?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripStopCreateOrConnectWithoutTripInput>>;
  create?: InputMaybe<Array<TripStopCreateWithoutTripInput>>;
  createMany?: InputMaybe<TripStopCreateManyTripInputEnvelope>;
};

export type TripStopCreateNestedOneWithoutDelayTripStopAlertsInput = {
  connect?: InputMaybe<TripStopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripStopCreateOrConnectWithoutDelayTripStopAlertsInput>;
  create?: InputMaybe<TripStopCreateWithoutDelayTripStopAlertsInput>;
};

export type TripStopCreateNestedOneWithoutStudentsTripStopsInput = {
  connect?: InputMaybe<TripStopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripStopCreateOrConnectWithoutStudentsTripStopsInput>;
  create?: InputMaybe<TripStopCreateWithoutStudentsTripStopsInput>;
};

export type TripStopCreateOrConnectWithoutDelayTripStopAlertsInput = {
  create: TripStopCreateWithoutDelayTripStopAlertsInput;
  where: TripStopWhereUniqueInput;
};

export type TripStopCreateOrConnectWithoutRouteStopInput = {
  create: TripStopCreateWithoutRouteStopInput;
  where: TripStopWhereUniqueInput;
};

export type TripStopCreateOrConnectWithoutSchoolTripStopInput = {
  create: TripStopCreateWithoutSchoolTripStopInput;
  where: TripStopWhereUniqueInput;
};

export type TripStopCreateOrConnectWithoutStudentsTripStopsInput = {
  create: TripStopCreateWithoutStudentsTripStopsInput;
  where: TripStopWhereUniqueInput;
};

export type TripStopCreateOrConnectWithoutTripInput = {
  create: TripStopCreateWithoutTripInput;
  where: TripStopWhereUniqueInput;
};

export type TripStopCreateWithoutDelayTripStopAlertsInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime: Scalars["DateTime"]["input"];
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  routeStop: RouteStopCreateNestedOneWithoutTripStopsInput;
  schoolTripStop?: InputMaybe<TripStopCreateNestedOneWithoutStudentsTripStopsInput>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentStopLogs?: InputMaybe<StudentStopLogCreateNestedManyWithoutTripStopInput>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  studentsTripStops?: InputMaybe<TripStopCreateNestedManyWithoutSchoolTripStopInput>;
  trip: TripCreateNestedOneWithoutTripStopsInput;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopCreateWithoutRouteStopInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripStopInput>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime: Scalars["DateTime"]["input"];
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  schoolTripStop?: InputMaybe<TripStopCreateNestedOneWithoutStudentsTripStopsInput>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentStopLogs?: InputMaybe<StudentStopLogCreateNestedManyWithoutTripStopInput>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  studentsTripStops?: InputMaybe<TripStopCreateNestedManyWithoutSchoolTripStopInput>;
  trip: TripCreateNestedOneWithoutTripStopsInput;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopCreateWithoutSchoolTripStopInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripStopInput>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime: Scalars["DateTime"]["input"];
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  routeStop: RouteStopCreateNestedOneWithoutTripStopsInput;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentStopLogs?: InputMaybe<StudentStopLogCreateNestedManyWithoutTripStopInput>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  studentsTripStops?: InputMaybe<TripStopCreateNestedManyWithoutSchoolTripStopInput>;
  trip: TripCreateNestedOneWithoutTripStopsInput;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopCreateWithoutStudentsTripStopsInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripStopInput>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime: Scalars["DateTime"]["input"];
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  routeStop: RouteStopCreateNestedOneWithoutTripStopsInput;
  schoolTripStop?: InputMaybe<TripStopCreateNestedOneWithoutStudentsTripStopsInput>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentStopLogs?: InputMaybe<StudentStopLogCreateNestedManyWithoutTripStopInput>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  trip: TripCreateNestedOneWithoutTripStopsInput;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopCreateWithoutTripInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertCreateNestedManyWithoutTripStopInput>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime: Scalars["DateTime"]["input"];
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  routeStop: RouteStopCreateNestedOneWithoutTripStopsInput;
  schoolTripStop?: InputMaybe<TripStopCreateNestedOneWithoutStudentsTripStopsInput>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentStopLogs?: InputMaybe<StudentStopLogCreateNestedManyWithoutTripStopInput>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  studentsTripStops?: InputMaybe<TripStopCreateNestedManyWithoutSchoolTripStopInput>;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum TripStopEventSource {
  BUS_ATTENDANT = "BUS_ATTENDANT",
  HARDWARE = "HARDWARE",
}

export type TripStopForBusAttendant = {
  __typename?: "TripStopForBusAttendant";
  confirmedAttendanceStatus: AttendanceStatus;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  locationGroupId?: Maybe<Scalars["String"]["output"]>;
  plannedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedOrder: Scalars["Int"]["output"];
  school?: Maybe<SchoolForBusAttendant>;
  schoolTripStopId?: Maybe<Scalars["String"]["output"]>;
  sponsor?: Maybe<SponsorForBusAttendant>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status: RouteStopStatus;
  stop?: Maybe<Stop>;
  stopId?: Maybe<Scalars["String"]["output"]>;
  student?: Maybe<StudentForBusAttendant>;
};

export type TripStopForDriver = {
  __typename?: "TripStopForDriver";
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  locationGroupId?: Maybe<Scalars["String"]["output"]>;
  plannedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedOrder: Scalars["Int"]["output"];
  school?: Maybe<SchoolForDriver>;
  schoolTripStopId?: Maybe<Scalars["String"]["output"]>;
  sponsor?: Maybe<SponsorForDriver>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status: RouteStopStatus;
  stop?: Maybe<Stop>;
  stopId?: Maybe<Scalars["String"]["output"]>;
  student?: Maybe<StudentForDriver>;
};

export type TripStopForSponsor = {
  __typename?: "TripStopForSponsor";
  _count: TripStopCount;
  actualArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualCompletedTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualLat?: Maybe<Scalars["Float"]["output"]>;
  actualLon?: Maybe<Scalars["Float"]["output"]>;
  actualOrder?: Maybe<Scalars["Int"]["output"]>;
  actualPickupTime?: Maybe<Scalars["DateTime"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedAttendanceStatus: AttendanceStatus;
  confirmedDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedNoShowTime?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  delayInMinutes?: Maybe<Scalars["Int"]["output"]>;
  delayTripStopAlerts?: Maybe<Array<DelayTripStopAlert>>;
  detectedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  estimatedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  hasHomeLocation?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  isCompletedNotificationSent: Scalars["Boolean"]["output"];
  isInTransit?: Maybe<Scalars["Boolean"]["output"]>;
  isInTransitNotificationSent: Scalars["Boolean"]["output"];
  isInaccurateLocation: Scalars["Boolean"]["output"];
  isNearbyDropoffNotificationSent: Scalars["Boolean"]["output"];
  isNearbyPickupNotificationSent: Scalars["Boolean"]["output"];
  isOnLeave: Scalars["Boolean"]["output"];
  isVisitedNotificationSent: Scalars["Boolean"]["output"];
  plannedArrivalTime: Scalars["DateTime"]["output"];
  plannedDropoffTime: Scalars["DateTime"]["output"];
  plannedLat?: Maybe<Scalars["Float"]["output"]>;
  plannedLon?: Maybe<Scalars["Float"]["output"]>;
  plannedOrder?: Maybe<Scalars["Int"]["output"]>;
  plannedPickupTime: Scalars["DateTime"]["output"];
  routeStop: RouteStop;
  routeStopId: Scalars["String"]["output"];
  school: SchoolForTripStop;
  schoolTripStopId?: Maybe<Scalars["String"]["output"]>;
  schoolTripStopStatus: SchoolTripStopStatus;
  shape?: Maybe<Scalars["String"]["output"]>;
  student: StudentForSponsor;
  studentTripStopStatus: StudentTripStopStatus;
  tripId: Scalars["String"]["output"];
  unknownDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type TripStopForWeb = {
  __typename?: "TripStopForWeb";
  _count: TripStopCount;
  actualArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualCompletedTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualLat?: Maybe<Scalars["Float"]["output"]>;
  actualLon?: Maybe<Scalars["Float"]["output"]>;
  actualOrder?: Maybe<Scalars["Int"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  bleStatus?: Maybe<BleStatus>;
  confirmedAttendanceStatus: AttendanceStatus;
  confirmedDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedNoShowTime?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  delayCategory?: Maybe<AlertLevel>;
  delayInMinutes?: Maybe<Scalars["Int"]["output"]>;
  delayTripStopAlerts?: Maybe<Array<DelayTripStopAlert>>;
  detectedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  estimatedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isCompletedNotificationSent: Scalars["Boolean"]["output"];
  isInTransit?: Maybe<Scalars["Boolean"]["output"]>;
  isInTransitNotificationSent: Scalars["Boolean"]["output"];
  isInaccurateLocation: Scalars["Boolean"]["output"];
  /** @deprecated in favor of locationStatus */
  isLocationAccurate: Scalars["Boolean"]["output"];
  isNearbyDropoffNotificationSent: Scalars["Boolean"]["output"];
  isNearbyPickupNotificationSent: Scalars["Boolean"]["output"];
  isOnLeave: Scalars["Boolean"]["output"];
  isVisitedNotificationSent: Scalars["Boolean"]["output"];
  locationStatus?: Maybe<StopLocationStatus>;
  plannedArrivalTime: Scalars["DateTime"]["output"];
  plannedLat?: Maybe<Scalars["Float"]["output"]>;
  plannedLon?: Maybe<Scalars["Float"]["output"]>;
  plannedOrder?: Maybe<Scalars["Int"]["output"]>;
  polyline?: Maybe<Array<LatLon>>;
  routeStop: RouteStop;
  routeStopId: Scalars["String"]["output"];
  schoolTripStop?: Maybe<TripStop>;
  schoolTripStopId?: Maybe<Scalars["String"]["output"]>;
  schoolTripStopStatus: SchoolTripStopStatus;
  shape?: Maybe<Scalars["String"]["output"]>;
  studentStopLogs?: Maybe<Array<StudentStopLog>>;
  studentTripStopStatus: StudentTripStopStatus;
  studentsTripStops?: Maybe<Array<TripStop>>;
  trip: Trip;
  tripId: Scalars["String"]["output"];
  unknownDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type TripStopListRelationFilter = {
  every?: InputMaybe<TripStopWhereInput>;
  none?: InputMaybe<TripStopWhereInput>;
  some?: InputMaybe<TripStopWhereInput>;
};

export type TripStopPlanned = {
  __typename?: "TripStopPlanned";
  _count: RouteStopCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  collectionPoint?: Maybe<CollectionPoint>;
  collectionPointId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isOnLeave: Scalars["Boolean"]["output"];
  plannedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedOrder: Scalars["Int"]["output"];
  route: Route;
  routeId: Scalars["String"]["output"];
  schoolRegistrations?: Maybe<Array<RouteStopSchoolRegistration>>;
  schoolTripStopId: Scalars["String"]["output"];
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status: RouteStopStatus;
  stop?: Maybe<Stop>;
  stopId?: Maybe<Scalars["String"]["output"]>;
  suggestedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  suggestedOrder?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type TripStopPlannedForSponsor = {
  __typename?: "TripStopPlannedForSponsor";
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  plannedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedOrder: Scalars["Int"]["output"];
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status: RouteStopStatus;
  stop?: Maybe<Stop>;
  stopId?: Maybe<Scalars["String"]["output"]>;
};

export type TripStopRelationFilter = {
  is?: InputMaybe<TripStopWhereInput>;
  isNot?: InputMaybe<TripStopWhereInput>;
};

export type TripStopScalarWhereInput = {
  AND?: InputMaybe<Array<TripStopScalarWhereInput>>;
  NOT?: InputMaybe<Array<TripStopScalarWhereInput>>;
  OR?: InputMaybe<Array<TripStopScalarWhereInput>>;
  actualArrivalTime?: InputMaybe<DateTimeFilter>;
  actualCompletedTime?: InputMaybe<DateTimeFilter>;
  actualInTransitTime?: InputMaybe<DateTimeFilter>;
  actualLat?: InputMaybe<FloatFilter>;
  actualLon?: InputMaybe<FloatFilter>;
  actualOrder?: InputMaybe<IntFilter>;
  confirmedAttendanceStatus?: InputMaybe<EnumAttendanceStatusFilter>;
  confirmedDropoffTime?: InputMaybe<DateTimeFilter>;
  confirmedInTransitTime?: InputMaybe<DateTimeFilter>;
  confirmedNoShowTime?: InputMaybe<DateTimeFilter>;
  delayInMinutes?: InputMaybe<IntFilter>;
  detectedArrivalTime?: InputMaybe<DateTimeFilter>;
  estimatedArrivalTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isCompletedNotificationSent?: InputMaybe<BoolFilter>;
  isInTransit?: InputMaybe<BoolFilter>;
  isInTransitNotificationSent?: InputMaybe<BoolFilter>;
  isInaccurateLocation?: InputMaybe<BoolFilter>;
  isNearbyDropoffNotificationSent?: InputMaybe<BoolFilter>;
  isNearbyPickupNotificationSent?: InputMaybe<BoolFilter>;
  isOnLeave?: InputMaybe<BoolFilter>;
  isVisitedNotificationSent?: InputMaybe<BoolFilter>;
  plannedArrivalTime?: InputMaybe<DateTimeFilter>;
  plannedLat?: InputMaybe<FloatFilter>;
  plannedLon?: InputMaybe<FloatFilter>;
  plannedOrder?: InputMaybe<IntFilter>;
  routeStopId?: InputMaybe<StringFilter>;
  schoolTripStopId?: InputMaybe<StringFilter>;
  schoolTripStopStatus?: InputMaybe<EnumSchoolTripStopStatusFilter>;
  studentTripStopStatus?: InputMaybe<EnumStudentTripStopStatusFilter>;
  tripId?: InputMaybe<StringFilter>;
  unknownDropoffTime?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TripStopStudentUpdatedForBaBdInput = {
  tripId?: InputMaybe<Scalars["String"]["input"]>;
  tripStopIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type TripStopSubPayloadForSponsor = {
  __typename?: "TripStopSubPayloadForSponsor";
  _count: TripStopCount;
  actualArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualCompletedTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  actualLat?: Maybe<Scalars["Float"]["output"]>;
  actualLon?: Maybe<Scalars["Float"]["output"]>;
  actualOrder?: Maybe<Scalars["Int"]["output"]>;
  actualPickupTime?: Maybe<Scalars["DateTime"]["output"]>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedAttendanceStatus: AttendanceStatus;
  confirmedDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedInTransitTime?: Maybe<Scalars["DateTime"]["output"]>;
  confirmedNoShowTime?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  delayInMinutes?: Maybe<Scalars["Int"]["output"]>;
  delayTripStopAlerts?: Maybe<Array<DelayTripStopAlert>>;
  detectedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  estimatedArrivalTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isCompletedNotificationSent: Scalars["Boolean"]["output"];
  isInTransit?: Maybe<Scalars["Boolean"]["output"]>;
  isInTransitNotificationSent: Scalars["Boolean"]["output"];
  isInaccurateLocation: Scalars["Boolean"]["output"];
  isNearbyDropoffNotificationSent: Scalars["Boolean"]["output"];
  isNearbyPickupNotificationSent: Scalars["Boolean"]["output"];
  isOnLeave: Scalars["Boolean"]["output"];
  isVisitedNotificationSent: Scalars["Boolean"]["output"];
  plannedArrivalTime: Scalars["DateTime"]["output"];
  plannedDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  plannedLat?: Maybe<Scalars["Float"]["output"]>;
  plannedLon?: Maybe<Scalars["Float"]["output"]>;
  plannedOrder?: Maybe<Scalars["Int"]["output"]>;
  plannedPickupTime?: Maybe<Scalars["DateTime"]["output"]>;
  routeStop: RouteStop;
  routeStopId: Scalars["String"]["output"];
  schoolTripStop?: Maybe<TripStop>;
  schoolTripStopId?: Maybe<Scalars["String"]["output"]>;
  schoolTripStopStatus: SchoolTripStopStatus;
  studentStopLogs?: Maybe<Array<StudentStopLog>>;
  studentTripStopStatus: StudentTripStopStatus;
  studentsTripStops?: Maybe<Array<TripStop>>;
  trip: Trip;
  tripId: Scalars["String"]["output"];
  unknownDropoffTime?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type TripStopUpdateManyMutationInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopUpdateManyWithWhereWithoutRouteStopInput = {
  data: TripStopUpdateManyMutationInput;
  where: TripStopScalarWhereInput;
};

export type TripStopUpdateManyWithWhereWithoutSchoolTripStopInput = {
  data: TripStopUpdateManyMutationInput;
  where: TripStopScalarWhereInput;
};

export type TripStopUpdateManyWithWhereWithoutTripInput = {
  data: TripStopUpdateManyMutationInput;
  where: TripStopScalarWhereInput;
};

export type TripStopUpdateManyWithoutRouteStopNestedInput = {
  connect?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripStopCreateOrConnectWithoutRouteStopInput>
  >;
  create?: InputMaybe<Array<TripStopCreateWithoutRouteStopInput>>;
  createMany?: InputMaybe<TripStopCreateManyRouteStopInputEnvelope>;
  delete?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripStopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  set?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TripStopUpdateWithWhereUniqueWithoutRouteStopInput>
  >;
  updateMany?: InputMaybe<
    Array<TripStopUpdateManyWithWhereWithoutRouteStopInput>
  >;
  upsert?: InputMaybe<
    Array<TripStopUpsertWithWhereUniqueWithoutRouteStopInput>
  >;
};

export type TripStopUpdateManyWithoutSchoolTripStopNestedInput = {
  connect?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripStopCreateOrConnectWithoutSchoolTripStopInput>
  >;
  create?: InputMaybe<Array<TripStopCreateWithoutSchoolTripStopInput>>;
  createMany?: InputMaybe<TripStopCreateManySchoolTripStopInputEnvelope>;
  delete?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripStopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  set?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TripStopUpdateWithWhereUniqueWithoutSchoolTripStopInput>
  >;
  updateMany?: InputMaybe<
    Array<TripStopUpdateManyWithWhereWithoutSchoolTripStopInput>
  >;
  upsert?: InputMaybe<
    Array<TripStopUpsertWithWhereUniqueWithoutSchoolTripStopInput>
  >;
};

export type TripStopUpdateManyWithoutTripNestedInput = {
  connect?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripStopCreateOrConnectWithoutTripInput>>;
  create?: InputMaybe<Array<TripStopCreateWithoutTripInput>>;
  createMany?: InputMaybe<TripStopCreateManyTripInputEnvelope>;
  delete?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripStopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  set?: InputMaybe<Array<TripStopWhereUniqueInput>>;
  update?: InputMaybe<Array<TripStopUpdateWithWhereUniqueWithoutTripInput>>;
  updateMany?: InputMaybe<Array<TripStopUpdateManyWithWhereWithoutTripInput>>;
  upsert?: InputMaybe<Array<TripStopUpsertWithWhereUniqueWithoutTripInput>>;
};

export type TripStopUpdateOneRequiredWithoutDelayTripStopAlertsNestedInput = {
  connect?: InputMaybe<TripStopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripStopCreateOrConnectWithoutDelayTripStopAlertsInput>;
  create?: InputMaybe<TripStopCreateWithoutDelayTripStopAlertsInput>;
  update?: InputMaybe<TripStopUpdateToOneWithWhereWithoutDelayTripStopAlertsInput>;
  upsert?: InputMaybe<TripStopUpsertWithoutDelayTripStopAlertsInput>;
};

export type TripStopUpdateOneWithoutStudentsTripStopsNestedInput = {
  connect?: InputMaybe<TripStopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripStopCreateOrConnectWithoutStudentsTripStopsInput>;
  create?: InputMaybe<TripStopCreateWithoutStudentsTripStopsInput>;
  delete?: InputMaybe<TripStopWhereInput>;
  disconnect?: InputMaybe<TripStopWhereInput>;
  update?: InputMaybe<TripStopUpdateToOneWithWhereWithoutStudentsTripStopsInput>;
  upsert?: InputMaybe<TripStopUpsertWithoutStudentsTripStopsInput>;
};

export enum TripStopUpdateSource {
  MANUAL = "MANUAL",
  STREAMER = "STREAMER",
}

export type TripStopUpdateToOneWithWhereWithoutDelayTripStopAlertsInput = {
  data: TripStopUpdateWithoutDelayTripStopAlertsInput;
  where?: InputMaybe<TripStopWhereInput>;
};

export type TripStopUpdateToOneWithWhereWithoutStudentsTripStopsInput = {
  data: TripStopUpdateWithoutStudentsTripStopsInput;
  where?: InputMaybe<TripStopWhereInput>;
};

export type TripStopUpdateWithWhereUniqueWithoutRouteStopInput = {
  data: TripStopUpdateWithoutRouteStopInput;
  where: TripStopWhereUniqueInput;
};

export type TripStopUpdateWithWhereUniqueWithoutSchoolTripStopInput = {
  data: TripStopUpdateWithoutSchoolTripStopInput;
  where: TripStopWhereUniqueInput;
};

export type TripStopUpdateWithWhereUniqueWithoutTripInput = {
  data: TripStopUpdateWithoutTripInput;
  where: TripStopWhereUniqueInput;
};

export type TripStopUpdateWithoutDelayTripStopAlertsInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  routeStop?: InputMaybe<RouteStopUpdateOneRequiredWithoutTripStopsNestedInput>;
  schoolTripStop?: InputMaybe<TripStopUpdateOneWithoutStudentsTripStopsNestedInput>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentStopLogs?: InputMaybe<StudentStopLogUpdateManyWithoutTripStopNestedInput>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  studentsTripStops?: InputMaybe<TripStopUpdateManyWithoutSchoolTripStopNestedInput>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripStopsNestedInput>;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopUpdateWithoutRouteStopInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripStopNestedInput>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  schoolTripStop?: InputMaybe<TripStopUpdateOneWithoutStudentsTripStopsNestedInput>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentStopLogs?: InputMaybe<StudentStopLogUpdateManyWithoutTripStopNestedInput>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  studentsTripStops?: InputMaybe<TripStopUpdateManyWithoutSchoolTripStopNestedInput>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripStopsNestedInput>;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopUpdateWithoutSchoolTripStopInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripStopNestedInput>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  routeStop?: InputMaybe<RouteStopUpdateOneRequiredWithoutTripStopsNestedInput>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentStopLogs?: InputMaybe<StudentStopLogUpdateManyWithoutTripStopNestedInput>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  studentsTripStops?: InputMaybe<TripStopUpdateManyWithoutSchoolTripStopNestedInput>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripStopsNestedInput>;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopUpdateWithoutStudentsTripStopsInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripStopNestedInput>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  routeStop?: InputMaybe<RouteStopUpdateOneRequiredWithoutTripStopsNestedInput>;
  schoolTripStop?: InputMaybe<TripStopUpdateOneWithoutStudentsTripStopsNestedInput>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentStopLogs?: InputMaybe<StudentStopLogUpdateManyWithoutTripStopNestedInput>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutTripStopsNestedInput>;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopUpdateWithoutTripInput = {
  actualArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualCompletedTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualLat?: InputMaybe<Scalars["Float"]["input"]>;
  actualLon?: InputMaybe<Scalars["Float"]["input"]>;
  actualOrder?: InputMaybe<Scalars["Int"]["input"]>;
  confirmedAttendanceStatus?: InputMaybe<AttendanceStatus>;
  confirmedDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedInTransitTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  confirmedNoShowTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripStopNestedInput>;
  detectedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  estimatedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransit?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInTransitNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isInaccurateLocation?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyDropoffNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNearbyPickupNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnLeave?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisitedNotificationSent?: InputMaybe<Scalars["Boolean"]["input"]>;
  plannedArrivalTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  plannedOrder?: InputMaybe<Scalars["Int"]["input"]>;
  routeStop?: InputMaybe<RouteStopUpdateOneRequiredWithoutTripStopsNestedInput>;
  schoolTripStop?: InputMaybe<TripStopUpdateOneWithoutStudentsTripStopsNestedInput>;
  schoolTripStopStatus?: InputMaybe<SchoolTripStopStatus>;
  studentStopLogs?: InputMaybe<StudentStopLogUpdateManyWithoutTripStopNestedInput>;
  studentTripStopStatus?: InputMaybe<StudentTripStopStatus>;
  studentsTripStops?: InputMaybe<TripStopUpdateManyWithoutSchoolTripStopNestedInput>;
  unknownDropoffTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TripStopUpsertWithWhereUniqueWithoutRouteStopInput = {
  create: TripStopCreateWithoutRouteStopInput;
  update: TripStopUpdateWithoutRouteStopInput;
  where: TripStopWhereUniqueInput;
};

export type TripStopUpsertWithWhereUniqueWithoutSchoolTripStopInput = {
  create: TripStopCreateWithoutSchoolTripStopInput;
  update: TripStopUpdateWithoutSchoolTripStopInput;
  where: TripStopWhereUniqueInput;
};

export type TripStopUpsertWithWhereUniqueWithoutTripInput = {
  create: TripStopCreateWithoutTripInput;
  update: TripStopUpdateWithoutTripInput;
  where: TripStopWhereUniqueInput;
};

export type TripStopUpsertWithoutDelayTripStopAlertsInput = {
  create: TripStopCreateWithoutDelayTripStopAlertsInput;
  update: TripStopUpdateWithoutDelayTripStopAlertsInput;
  where?: InputMaybe<TripStopWhereInput>;
};

export type TripStopUpsertWithoutStudentsTripStopsInput = {
  create: TripStopCreateWithoutStudentsTripStopsInput;
  update: TripStopUpdateWithoutStudentsTripStopsInput;
  where?: InputMaybe<TripStopWhereInput>;
};

export type TripStopWhereInput = {
  AND?: InputMaybe<Array<TripStopWhereInput>>;
  NOT?: InputMaybe<Array<TripStopWhereInput>>;
  OR?: InputMaybe<Array<TripStopWhereInput>>;
  actualArrivalTime?: InputMaybe<DateTimeFilter>;
  actualCompletedTime?: InputMaybe<DateTimeFilter>;
  actualInTransitTime?: InputMaybe<DateTimeFilter>;
  actualLat?: InputMaybe<FloatFilter>;
  actualLon?: InputMaybe<FloatFilter>;
  actualOrder?: InputMaybe<IntFilter>;
  confirmedAttendanceStatus?: InputMaybe<EnumAttendanceStatusFilter>;
  confirmedDropoffTime?: InputMaybe<DateTimeFilter>;
  confirmedInTransitTime?: InputMaybe<DateTimeFilter>;
  confirmedNoShowTime?: InputMaybe<DateTimeFilter>;
  delayInMinutes?: InputMaybe<IntFilter>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertListRelationFilter>;
  detectedArrivalTime?: InputMaybe<DateTimeFilter>;
  estimatedArrivalTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isCompletedNotificationSent?: InputMaybe<BoolFilter>;
  isInTransit?: InputMaybe<BoolFilter>;
  isInTransitNotificationSent?: InputMaybe<BoolFilter>;
  isInaccurateLocation?: InputMaybe<BoolFilter>;
  isNearbyDropoffNotificationSent?: InputMaybe<BoolFilter>;
  isNearbyPickupNotificationSent?: InputMaybe<BoolFilter>;
  isOnLeave?: InputMaybe<BoolFilter>;
  isVisitedNotificationSent?: InputMaybe<BoolFilter>;
  plannedArrivalTime?: InputMaybe<DateTimeFilter>;
  plannedLat?: InputMaybe<FloatFilter>;
  plannedLon?: InputMaybe<FloatFilter>;
  plannedOrder?: InputMaybe<IntFilter>;
  routeStop?: InputMaybe<RouteStopRelationFilter>;
  routeStopId?: InputMaybe<StringFilter>;
  schoolTripStop?: InputMaybe<TripStopRelationFilter>;
  schoolTripStopId?: InputMaybe<StringFilter>;
  schoolTripStopStatus?: InputMaybe<EnumSchoolTripStopStatusFilter>;
  studentStopLogs?: InputMaybe<StudentStopLogListRelationFilter>;
  studentTripStopStatus?: InputMaybe<EnumStudentTripStopStatusFilter>;
  studentsTripStops?: InputMaybe<TripStopListRelationFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
  unknownDropoffTime?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TripStopWhereUniqueInput = {
  AND?: InputMaybe<Array<TripStopWhereInput>>;
  NOT?: InputMaybe<Array<TripStopWhereInput>>;
  OR?: InputMaybe<Array<TripStopWhereInput>>;
  actualArrivalTime?: InputMaybe<DateTimeFilter>;
  actualCompletedTime?: InputMaybe<DateTimeFilter>;
  actualInTransitTime?: InputMaybe<DateTimeFilter>;
  actualLat?: InputMaybe<FloatFilter>;
  actualLon?: InputMaybe<FloatFilter>;
  actualOrder?: InputMaybe<IntFilter>;
  confirmedAttendanceStatus?: InputMaybe<EnumAttendanceStatusFilter>;
  confirmedDropoffTime?: InputMaybe<DateTimeFilter>;
  confirmedInTransitTime?: InputMaybe<DateTimeFilter>;
  confirmedNoShowTime?: InputMaybe<DateTimeFilter>;
  delayInMinutes?: InputMaybe<IntFilter>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertListRelationFilter>;
  detectedArrivalTime?: InputMaybe<DateTimeFilter>;
  estimatedArrivalTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompletedNotificationSent?: InputMaybe<BoolFilter>;
  isInTransit?: InputMaybe<BoolFilter>;
  isInTransitNotificationSent?: InputMaybe<BoolFilter>;
  isInaccurateLocation?: InputMaybe<BoolFilter>;
  isNearbyDropoffNotificationSent?: InputMaybe<BoolFilter>;
  isNearbyPickupNotificationSent?: InputMaybe<BoolFilter>;
  isOnLeave?: InputMaybe<BoolFilter>;
  isVisitedNotificationSent?: InputMaybe<BoolFilter>;
  plannedArrivalTime?: InputMaybe<DateTimeFilter>;
  plannedLat?: InputMaybe<FloatFilter>;
  plannedLon?: InputMaybe<FloatFilter>;
  plannedOrder?: InputMaybe<IntFilter>;
  routeStop?: InputMaybe<RouteStopRelationFilter>;
  routeStopId?: InputMaybe<StringFilter>;
  schoolTripStop?: InputMaybe<TripStopRelationFilter>;
  schoolTripStopId?: InputMaybe<StringFilter>;
  schoolTripStopStatus?: InputMaybe<EnumSchoolTripStopStatusFilter>;
  studentStopLogs?: InputMaybe<StudentStopLogListRelationFilter>;
  studentTripStopStatus?: InputMaybe<EnumStudentTripStopStatusFilter>;
  studentsTripStops?: InputMaybe<TripStopListRelationFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
  unknownDropoffTime?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum TripType {
  HOME_TO_SCHOOL = "HOME_TO_SCHOOL",
  ROUND_TRIP = "ROUND_TRIP",
  SCHOOL_TO_HOME = "SCHOOL_TO_HOME",
}

export type TripUpdateInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateManyInputForWeb = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agentId?: InputMaybe<Scalars["String"]["input"]>;
  busAttendantId?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  driverId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<TripStatus>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripUpdateManyMutationInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<TripStatus>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
};

export type TripUpdateManyWithWhereWithoutAgentInput = {
  data: TripUpdateManyMutationInput;
  where: TripScalarWhereInput;
};

export type TripUpdateManyWithWhereWithoutBusAttendantInput = {
  data: TripUpdateManyMutationInput;
  where: TripScalarWhereInput;
};

export type TripUpdateManyWithWhereWithoutDriverInput = {
  data: TripUpdateManyMutationInput;
  where: TripScalarWhereInput;
};

export type TripUpdateManyWithWhereWithoutRouteInput = {
  data: TripUpdateManyMutationInput;
  where: TripScalarWhereInput;
};

export type TripUpdateManyWithWhereWithoutVehicleInput = {
  data: TripUpdateManyMutationInput;
  where: TripScalarWhereInput;
};

export type TripUpdateManyWithoutAgentNestedInput = {
  connect?: InputMaybe<Array<TripWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<TripCreateWithoutAgentInput>>;
  createMany?: InputMaybe<TripCreateManyAgentInputEnvelope>;
  delete?: InputMaybe<Array<TripWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripWhereUniqueInput>>;
  set?: InputMaybe<Array<TripWhereUniqueInput>>;
  update?: InputMaybe<Array<TripUpdateWithWhereUniqueWithoutAgentInput>>;
  updateMany?: InputMaybe<Array<TripUpdateManyWithWhereWithoutAgentInput>>;
  upsert?: InputMaybe<Array<TripUpsertWithWhereUniqueWithoutAgentInput>>;
};

export type TripUpdateManyWithoutBusAttendantNestedInput = {
  connect?: InputMaybe<Array<TripWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TripCreateOrConnectWithoutBusAttendantInput>
  >;
  create?: InputMaybe<Array<TripCreateWithoutBusAttendantInput>>;
  createMany?: InputMaybe<TripCreateManyBusAttendantInputEnvelope>;
  delete?: InputMaybe<Array<TripWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripWhereUniqueInput>>;
  set?: InputMaybe<Array<TripWhereUniqueInput>>;
  update?: InputMaybe<Array<TripUpdateWithWhereUniqueWithoutBusAttendantInput>>;
  updateMany?: InputMaybe<
    Array<TripUpdateManyWithWhereWithoutBusAttendantInput>
  >;
  upsert?: InputMaybe<Array<TripUpsertWithWhereUniqueWithoutBusAttendantInput>>;
};

export type TripUpdateManyWithoutDriverNestedInput = {
  connect?: InputMaybe<Array<TripWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripCreateOrConnectWithoutDriverInput>>;
  create?: InputMaybe<Array<TripCreateWithoutDriverInput>>;
  createMany?: InputMaybe<TripCreateManyDriverInputEnvelope>;
  delete?: InputMaybe<Array<TripWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripWhereUniqueInput>>;
  set?: InputMaybe<Array<TripWhereUniqueInput>>;
  update?: InputMaybe<Array<TripUpdateWithWhereUniqueWithoutDriverInput>>;
  updateMany?: InputMaybe<Array<TripUpdateManyWithWhereWithoutDriverInput>>;
  upsert?: InputMaybe<Array<TripUpsertWithWhereUniqueWithoutDriverInput>>;
};

export type TripUpdateManyWithoutRouteNestedInput = {
  connect?: InputMaybe<Array<TripWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripCreateOrConnectWithoutRouteInput>>;
  create?: InputMaybe<Array<TripCreateWithoutRouteInput>>;
  createMany?: InputMaybe<TripCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<TripWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripWhereUniqueInput>>;
  set?: InputMaybe<Array<TripWhereUniqueInput>>;
  update?: InputMaybe<Array<TripUpdateWithWhereUniqueWithoutRouteInput>>;
  updateMany?: InputMaybe<Array<TripUpdateManyWithWhereWithoutRouteInput>>;
  upsert?: InputMaybe<Array<TripUpsertWithWhereUniqueWithoutRouteInput>>;
};

export type TripUpdateManyWithoutVehicleNestedInput = {
  connect?: InputMaybe<Array<TripWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TripCreateOrConnectWithoutVehicleInput>>;
  create?: InputMaybe<Array<TripCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<TripCreateManyVehicleInputEnvelope>;
  delete?: InputMaybe<Array<TripWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TripScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TripWhereUniqueInput>>;
  set?: InputMaybe<Array<TripWhereUniqueInput>>;
  update?: InputMaybe<Array<TripUpdateWithWhereUniqueWithoutVehicleInput>>;
  updateMany?: InputMaybe<Array<TripUpdateManyWithWhereWithoutVehicleInput>>;
  upsert?: InputMaybe<Array<TripUpsertWithWhereUniqueWithoutVehicleInput>>;
};

export type TripUpdateOneRequiredWithoutDelayTripStopAlertsNestedInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutDelayTripStopAlertsInput>;
  create?: InputMaybe<TripCreateWithoutDelayTripStopAlertsInput>;
  update?: InputMaybe<TripUpdateToOneWithWhereWithoutDelayTripStopAlertsInput>;
  upsert?: InputMaybe<TripUpsertWithoutDelayTripStopAlertsInput>;
};

export type TripUpdateOneRequiredWithoutNoPickUpAlertsNestedInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutNoPickUpAlertsInput>;
  create?: InputMaybe<TripCreateWithoutNoPickUpAlertsInput>;
  update?: InputMaybe<TripUpdateToOneWithWhereWithoutNoPickUpAlertsInput>;
  upsert?: InputMaybe<TripUpsertWithoutNoPickUpAlertsInput>;
};

export type TripUpdateOneRequiredWithoutTripBusOverLoadedAlertsNestedInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutTripBusOverLoadedAlertsInput>;
  create?: InputMaybe<TripCreateWithoutTripBusOverLoadedAlertsInput>;
  update?: InputMaybe<TripUpdateToOneWithWhereWithoutTripBusOverLoadedAlertsInput>;
  upsert?: InputMaybe<TripUpsertWithoutTripBusOverLoadedAlertsInput>;
};

export type TripUpdateOneRequiredWithoutTripDelayAlertsNestedInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutTripDelayAlertsInput>;
  create?: InputMaybe<TripCreateWithoutTripDelayAlertsInput>;
  update?: InputMaybe<TripUpdateToOneWithWhereWithoutTripDelayAlertsInput>;
  upsert?: InputMaybe<TripUpsertWithoutTripDelayAlertsInput>;
};

export type TripUpdateOneRequiredWithoutTripFeedbackNestedInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutTripFeedbackInput>;
  create?: InputMaybe<TripCreateWithoutTripFeedbackInput>;
  update?: InputMaybe<TripUpdateToOneWithWhereWithoutTripFeedbackInput>;
  upsert?: InputMaybe<TripUpsertWithoutTripFeedbackInput>;
};

export type TripUpdateOneRequiredWithoutTripLogsNestedInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutTripLogsInput>;
  create?: InputMaybe<TripCreateWithoutTripLogsInput>;
  update?: InputMaybe<TripUpdateToOneWithWhereWithoutTripLogsInput>;
  upsert?: InputMaybe<TripUpsertWithoutTripLogsInput>;
};

export type TripUpdateOneRequiredWithoutTripStopsNestedInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutTripStopsInput>;
  create?: InputMaybe<TripCreateWithoutTripStopsInput>;
  update?: InputMaybe<TripUpdateToOneWithWhereWithoutTripStopsInput>;
  upsert?: InputMaybe<TripUpsertWithoutTripStopsInput>;
};

export type TripUpdateOneRequiredWithoutWrongDropOffLocationsNestedInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutWrongDropOffLocationsInput>;
  create?: InputMaybe<TripCreateWithoutWrongDropOffLocationsInput>;
  update?: InputMaybe<TripUpdateToOneWithWhereWithoutWrongDropOffLocationsInput>;
  upsert?: InputMaybe<TripUpsertWithoutWrongDropOffLocationsInput>;
};

export type TripUpdateOneWithoutVehicleSafetyEventsNestedInput = {
  connect?: InputMaybe<TripWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TripCreateOrConnectWithoutVehicleSafetyEventsInput>;
  create?: InputMaybe<TripCreateWithoutVehicleSafetyEventsInput>;
  delete?: InputMaybe<TripWhereInput>;
  disconnect?: InputMaybe<TripWhereInput>;
  update?: InputMaybe<TripUpdateToOneWithWhereWithoutVehicleSafetyEventsInput>;
  upsert?: InputMaybe<TripUpsertWithoutVehicleSafetyEventsInput>;
};

export type TripUpdateToOneWithWhereWithoutDelayTripStopAlertsInput = {
  data: TripUpdateWithoutDelayTripStopAlertsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpdateToOneWithWhereWithoutNoPickUpAlertsInput = {
  data: TripUpdateWithoutNoPickUpAlertsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpdateToOneWithWhereWithoutTripBusOverLoadedAlertsInput = {
  data: TripUpdateWithoutTripBusOverLoadedAlertsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpdateToOneWithWhereWithoutTripDelayAlertsInput = {
  data: TripUpdateWithoutTripDelayAlertsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpdateToOneWithWhereWithoutTripFeedbackInput = {
  data: TripUpdateWithoutTripFeedbackInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpdateToOneWithWhereWithoutTripLogsInput = {
  data: TripUpdateWithoutTripLogsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpdateToOneWithWhereWithoutTripStopsInput = {
  data: TripUpdateWithoutTripStopsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpdateToOneWithWhereWithoutVehicleSafetyEventsInput = {
  data: TripUpdateWithoutVehicleSafetyEventsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpdateToOneWithWhereWithoutWrongDropOffLocationsInput = {
  data: TripUpdateWithoutWrongDropOffLocationsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpdateWithWhereUniqueWithoutAgentInput = {
  data: TripUpdateWithoutAgentInput;
  where: TripWhereUniqueInput;
};

export type TripUpdateWithWhereUniqueWithoutBusAttendantInput = {
  data: TripUpdateWithoutBusAttendantInput;
  where: TripWhereUniqueInput;
};

export type TripUpdateWithWhereUniqueWithoutDriverInput = {
  data: TripUpdateWithoutDriverInput;
  where: TripWhereUniqueInput;
};

export type TripUpdateWithWhereUniqueWithoutRouteInput = {
  data: TripUpdateWithoutRouteInput;
  where: TripWhereUniqueInput;
};

export type TripUpdateWithWhereUniqueWithoutVehicleInput = {
  data: TripUpdateWithoutVehicleInput;
  where: TripWhereUniqueInput;
};

export type TripUpdateWithoutAgentInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutBusAttendantInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutDelayTripStopAlertsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutDriverInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutNoPickUpAlertsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutRouteInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutTripBusOverLoadedAlertsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutTripDelayAlertsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutTripFeedbackInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutTripLogsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutTripStopsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutVehicleInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutVehicleSafetyEventsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationUpdateManyWithoutTripNestedInput>;
};

export type TripUpdateWithoutWrongDropOffLocationsInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  agent?: InputMaybe<AgentUpdateOneWithoutTripsNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutTripsNestedInput>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertUpdateManyWithoutTripNestedInput>;
  driver?: InputMaybe<DriverUpdateOneWithoutTripsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isForceStarted?: InputMaybe<Scalars["Boolean"]["input"]>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertUpdateManyWithoutTripNestedInput>;
  plannedEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedSchoolStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  plannedStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  plannedStudentStopDuration?: InputMaybe<Scalars["Int"]["input"]>;
  route?: InputMaybe<RouteUpdateOneWithoutTripsNestedInput>;
  status?: InputMaybe<TripStatus>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutTripNestedInput>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertUpdateManyWithoutTripNestedInput>;
  tripEndOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripFeedback?: InputMaybe<TripFeedbackUpdateOneWithoutTripNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutTripNestedInput>;
  tripStartOffsetMinutes?: InputMaybe<Scalars["Int"]["input"]>;
  tripStops?: InputMaybe<TripStopUpdateManyWithoutTripNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutTripsNestedInput>;
  vehicleAssignmentType?: InputMaybe<TripVehicleAssignmentType>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutTripNestedInput>;
};

export type TripUpdatedForBaBdInput = {
  tripIds: Array<Scalars["String"]["input"]>;
};

export type TripUpsertWithWhereUniqueWithoutAgentInput = {
  create: TripCreateWithoutAgentInput;
  update: TripUpdateWithoutAgentInput;
  where: TripWhereUniqueInput;
};

export type TripUpsertWithWhereUniqueWithoutBusAttendantInput = {
  create: TripCreateWithoutBusAttendantInput;
  update: TripUpdateWithoutBusAttendantInput;
  where: TripWhereUniqueInput;
};

export type TripUpsertWithWhereUniqueWithoutDriverInput = {
  create: TripCreateWithoutDriverInput;
  update: TripUpdateWithoutDriverInput;
  where: TripWhereUniqueInput;
};

export type TripUpsertWithWhereUniqueWithoutRouteInput = {
  create: TripCreateWithoutRouteInput;
  update: TripUpdateWithoutRouteInput;
  where: TripWhereUniqueInput;
};

export type TripUpsertWithWhereUniqueWithoutVehicleInput = {
  create: TripCreateWithoutVehicleInput;
  update: TripUpdateWithoutVehicleInput;
  where: TripWhereUniqueInput;
};

export type TripUpsertWithoutDelayTripStopAlertsInput = {
  create: TripCreateWithoutDelayTripStopAlertsInput;
  update: TripUpdateWithoutDelayTripStopAlertsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpsertWithoutNoPickUpAlertsInput = {
  create: TripCreateWithoutNoPickUpAlertsInput;
  update: TripUpdateWithoutNoPickUpAlertsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpsertWithoutTripBusOverLoadedAlertsInput = {
  create: TripCreateWithoutTripBusOverLoadedAlertsInput;
  update: TripUpdateWithoutTripBusOverLoadedAlertsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpsertWithoutTripDelayAlertsInput = {
  create: TripCreateWithoutTripDelayAlertsInput;
  update: TripUpdateWithoutTripDelayAlertsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpsertWithoutTripFeedbackInput = {
  create: TripCreateWithoutTripFeedbackInput;
  update: TripUpdateWithoutTripFeedbackInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpsertWithoutTripLogsInput = {
  create: TripCreateWithoutTripLogsInput;
  update: TripUpdateWithoutTripLogsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpsertWithoutTripStopsInput = {
  create: TripCreateWithoutTripStopsInput;
  update: TripUpdateWithoutTripStopsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpsertWithoutVehicleSafetyEventsInput = {
  create: TripCreateWithoutVehicleSafetyEventsInput;
  update: TripUpdateWithoutVehicleSafetyEventsInput;
  where?: InputMaybe<TripWhereInput>;
};

export type TripUpsertWithoutWrongDropOffLocationsInput = {
  create: TripCreateWithoutWrongDropOffLocationsInput;
  update: TripUpdateWithoutWrongDropOffLocationsInput;
  where?: InputMaybe<TripWhereInput>;
};

export enum TripVehicleAssignmentType {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
  NONE = "NONE",
}

export type TripWhereInput = {
  AND?: InputMaybe<Array<TripWhereInput>>;
  NOT?: InputMaybe<Array<TripWhereInput>>;
  OR?: InputMaybe<Array<TripWhereInput>>;
  actualEndTime?: InputMaybe<DateTimeFilter>;
  actualSchoolStopDuration?: InputMaybe<IntFilter>;
  actualStartTime?: InputMaybe<DateTimeFilter>;
  actualStudentStopDuration?: InputMaybe<IntFilter>;
  agent?: InputMaybe<AgentRelationFilter>;
  agentId?: InputMaybe<StringFilter>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertListRelationFilter>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isForceEnded?: InputMaybe<BoolFilter>;
  isForceStarted?: InputMaybe<BoolFilter>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<IntFilter>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<IntFilter>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertListRelationFilter>;
  plannedEndTime?: InputMaybe<DateTimeFilter>;
  plannedSchoolStopDuration?: InputMaybe<IntFilter>;
  plannedStartTime?: InputMaybe<DateTimeFilter>;
  plannedStudentStopDuration?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumTripStatusFilter>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertListRelationFilter>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertListRelationFilter>;
  tripEndOffsetMinutes?: InputMaybe<IntFilter>;
  tripFeedback?: InputMaybe<TripFeedbackRelationFilter>;
  tripLogs?: InputMaybe<TripLogListRelationFilter>;
  tripStartOffsetMinutes?: InputMaybe<IntFilter>;
  tripStops?: InputMaybe<TripStopListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleAssignmentType?: InputMaybe<EnumTripVehicleAssignmentTypeFilter>;
  vehicleId?: InputMaybe<StringFilter>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventListRelationFilter>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationListRelationFilter>;
};

export type TripWhereInputForWeb = {
  agentIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  date: Scalars["DateTime"]["input"];
  directionality?: InputMaybe<RouteDirectionality>;
  include?: InputMaybe<TripWhereInputIncludeForWeb>;
  routeGroupIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  routeIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  schoolIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  schoolRegistrationIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  serviceProviderIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  statuses?: InputMaybe<Array<TripStatus>>;
};

export type TripWhereInputIncludeForWeb = {
  shapes: Scalars["Boolean"]["input"];
};

export type TripWhereUniqueInput = {
  AND?: InputMaybe<Array<TripWhereInput>>;
  NOT?: InputMaybe<Array<TripWhereInput>>;
  OR?: InputMaybe<Array<TripWhereInput>>;
  actualEndTime?: InputMaybe<DateTimeFilter>;
  actualSchoolStopDuration?: InputMaybe<IntFilter>;
  actualStartTime?: InputMaybe<DateTimeFilter>;
  actualStudentStopDuration?: InputMaybe<IntFilter>;
  agent?: InputMaybe<AgentRelationFilter>;
  agentId?: InputMaybe<StringFilter>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  busAttendantId?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  delayTripStopAlerts?: InputMaybe<DelayTripStopAlertListRelationFilter>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isForceEnded?: InputMaybe<BoolFilter>;
  isForceStarted?: InputMaybe<BoolFilter>;
  manualAttendanceEndOffsetMinutes?: InputMaybe<IntFilter>;
  manualAttendanceStartOffsetMinutes?: InputMaybe<IntFilter>;
  noPickUpAlerts?: InputMaybe<NoPickUpAlertListRelationFilter>;
  plannedEndTime?: InputMaybe<DateTimeFilter>;
  plannedSchoolStopDuration?: InputMaybe<IntFilter>;
  plannedStartTime?: InputMaybe<DateTimeFilter>;
  plannedStudentStopDuration?: InputMaybe<IntFilter>;
  route?: InputMaybe<RouteRelationFilter>;
  routeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumTripStatusFilter>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertListRelationFilter>;
  tripDelayAlerts?: InputMaybe<TripDelayAlertListRelationFilter>;
  tripEndOffsetMinutes?: InputMaybe<IntFilter>;
  tripFeedback?: InputMaybe<TripFeedbackRelationFilter>;
  tripLogs?: InputMaybe<TripLogListRelationFilter>;
  tripStartOffsetMinutes?: InputMaybe<IntFilter>;
  tripStops?: InputMaybe<TripStopListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleAssignmentType?: InputMaybe<EnumTripVehicleAssignmentTypeFilter>;
  vehicleId?: InputMaybe<StringFilter>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventListRelationFilter>;
  wrongDropOffLocations?: InputMaybe<WrongDropOffLocationListRelationFilter>;
};

export type TripsForDriver = {
  __typename?: "TripsForDriver";
  busAttendant?: Maybe<BusAttendantForDriver>;
  driver?: Maybe<DriverForDriver>;
  trip: TripForDriver;
  tripStops: Array<TripStopForDriver>;
  vehicle?: Maybe<VehicleForDriver>;
};

export type UpcomingTripForSchoolRegistration = {
  __typename?: "UpcomingTripForSchoolRegistration";
  busAttendant?: Maybe<BusAttendantForSponsor>;
  date: Scalars["DateTime"]["output"];
  driver?: Maybe<DriverForSponsor>;
  passengerTripStop: TripStopPlannedForSponsor;
  schedule: ScheduleForSponsor;
  school: SchoolForSponsor;
  schoolRegistration: SchoolRegistrationForSponsor;
  schoolTripStop?: Maybe<TripStopPlannedForSponsor>;
  shape?: Maybe<Scalars["String"]["output"]>;
  trip: TripPlannedForSponsor;
  tripHasUnknownLocationStop: Scalars["Boolean"]["output"];
  vehicle?: Maybe<VehicleForSponsor>;
};

export type UpcomingTripForSponsor = {
  __typename?: "UpcomingTripForSponsor";
  busAttendant?: Maybe<BusAttendantForSponsor>;
  driver?: Maybe<DriverForSponsor>;
  passengerTripStop: TripStopPlannedForSponsor;
  schedule: ScheduleForSponsor;
  school: SchoolForSponsor;
  schoolRegistration: SchoolRegistrationForSponsor;
  schoolTripStop?: Maybe<TripStopPlannedForSponsor>;
  shape?: Maybe<Scalars["String"]["output"]>;
  trip: TripPlannedForSponsor;
  tripHasUnknownLocationStop: Scalars["Boolean"]["output"];
  vehicle?: Maybe<VehicleForSponsor>;
};

export type UpdateBankDetails = {
  data: PartialCreateServiceProviderBankDetails;
  where: BankDetailsWhereUniqueInput;
};

export type UpdateCollectionPointInput = {
  address?: InputMaybe<AddressUpdateOneRequiredWithoutCollectionPointNestedInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  schools?: InputMaybe<UpdateCollectionPointSchoolInput>;
  status?: InputMaybe<CollectionPointStatus>;
};

export type UpdateCollectionPointSchoolInput = {
  create?: InputMaybe<
    Array<SchoolCollectionPointCreateWithoutCollectionPointInput>
  >;
  delete?: InputMaybe<Array<SchoolCollectionPointWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SchoolCollectionPointUpdateWithWhereUniqueWithoutCollectionPointInput>
  >;
};

export type UpdateGlobalConfigurationInput = {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type UpdateLeaveRequestInput = {
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  otherRequestChannel?: InputMaybe<Scalars["String"]["input"]>;
  requestChannel?: InputMaybe<LeaveRequestChannel>;
  sponsorUserId: Scalars["String"]["input"];
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UpdateManyAttendanceStatusInput = {
  students: Array<UpdateStudentAttendanceStatusInput>;
};

export type UpdateProfileForSponsorInput = {
  primaryLanguage?: InputMaybe<Language>;
};

export type UpdateSchoolRegistrationAddressInput = {
  addressId: Scalars["String"]["input"];
  lat: Scalars["Float"]["input"];
  lon: Scalars["Float"]["input"];
  schoolRegistrationId: Scalars["String"]["input"];
};

export type UpdateSchoolRegistrationsAddressesForMobileInput = {
  schoolRegistrationAddresses: Array<UpdateSchoolRegistrationAddressInput>;
};

export type UpdateServiceProviderBankDetails = {
  create?: InputMaybe<Array<CreateBankDetails>>;
  delete?: InputMaybe<Array<BankDetailsWhereUniqueInput>>;
  update?: InputMaybe<Array<UpdateBankDetails>>;
};

export type UpdateServiceProviderGroupInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateServiceProviderInput = {
  address?: InputMaybe<AddressUpdateOneWithoutServiceProviderNestedInput>;
  banksDetails?: InputMaybe<UpdateServiceProviderBankDetails>;
  branches?: InputMaybe<BranchUpdateManyWithoutServiceProviderNestedInput>;
  collectionPoints?: InputMaybe<CollectionPointUpdateManyWithoutServiceProviderNestedInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownerUserId?: InputMaybe<Scalars["String"]["input"]>;
  routes?: InputMaybe<RouteUpdateManyWithoutServiceProviderNestedInput>;
  serviceProviderGroupId?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderSchools?: InputMaybe<ServiceProviderSchoolUpdateManyWithoutServiceProviderNestedInput>;
  status?: InputMaybe<ServiceProviderStatus>;
  subscriptionPlans?: InputMaybe<UpdateServiceProviderSubscriptionPlan>;
  type?: InputMaybe<ServiceProviderType>;
  users?: InputMaybe<ServiceProviderUserUpdateManyWithoutServiceProviderNestedInput>;
  usesCollectionPoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  vehicleGroups?: InputMaybe<VehicleGroupUpdateManyWithoutServiceProviderNestedInput>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutServiceProviderNestedInput>;
};

export type UpdateServiceProviderSubscriptionPlan = {
  create?: InputMaybe<Array<CreateServiceProviderSubscriptionPlan>>;
  delete?: InputMaybe<Array<ServiceProviderSubscriptionPlanWhereUniqueInput>>;
  update?: InputMaybe<Array<UpdateSubscriptionPlan>>;
};

export type UpdateStudentAttendanceStatusInput = {
  status: AttendanceStatus;
  studentTripStopId: Scalars["String"]["input"];
};

export type UpdateStudentsLocationsResponse = {
  __typename?: "UpdateStudentsLocationsResponse";
  tagIdsWithNewLocations: Array<StudentLocation>;
  totalTagsCount: Scalars["Float"]["output"];
  updatedTagsCount: Scalars["Float"]["output"];
};

export type UpdateSubscriptionPlan = {
  data: PartialCreateServiceProviderSubscriptionPlan;
  where: ServiceProviderSubscriptionPlanWhereUniqueInput;
};

export type UploadSchoolRegistrationsError = {
  __typename?: "UploadSchoolRegistrationsError";
  message: Scalars["String"]["output"];
  record: Scalars["Float"]["output"];
};

export type UploadSchoolRegistrationsOutput = {
  __typename?: "UploadSchoolRegistrationsOutput";
  errors: Array<UploadSchoolRegistrationsError>;
};

export type UploadedFileResponse = {
  __typename?: "UploadedFileResponse";
  eTag: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type User = {
  __typename?: "User";
  Otp?: Maybe<Array<Otp>>;
  TripFeedback?: Maybe<Array<TripFeedback>>;
  _count: UserCount;
  addresses?: Maybe<Array<Address>>;
  adhocNotificationStudentUsers?: Maybe<Array<AdhocNotificationStudentUser>>;
  adhocNotifications?: Maybe<Array<AdhocNotificationSponsorUser>>;
  agent?: Maybe<Agent>;
  agentLeaveRequests?: Maybe<Array<LeaveRequest>>;
  agentModifiedLeaveRequests?: Maybe<Array<LeaveRequest>>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendant?: Maybe<BusAttendant>;
  cashOutRequests?: Maybe<Array<CashOutRequest>>;
  civilId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  creditNotes?: Maybe<Array<CreditNote>>;
  dateOfBirth?: Maybe<Scalars["DateTime"]["output"]>;
  driver?: Maybe<Driver>;
  email?: Maybe<Scalars["String"]["output"]>;
  fcmSubscribers?: Maybe<Array<FcmSubscriber>>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  googleId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  middleNameAr?: Maybe<Scalars["String"]["output"]>;
  ownedServiceProvider?: Maybe<ServiceProvider>;
  passenger?: Maybe<Passenger>;
  paymentReminderNotificationLogs?: Maybe<
    Array<PaymentReminderNotificationLog>
  >;
  phone?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  primaryLanguage?: Maybe<Language>;
  roles?: Maybe<Array<UserRole>>;
  schoolRegistrationAttempts?: Maybe<Array<SchoolRegistrationAttempt>>;
  serviceProviders?: Maybe<Array<ServiceProviderUser>>;
  sponsor?: Maybe<Sponsor>;
  sponsorLeaveRequests?: Maybe<Array<LeaveRequest>>;
  sponsorModifiedLeaveRequests?: Maybe<Array<LeaveRequest>>;
  travelTags?: Maybe<Array<UserTravelTag>>;
  tripLogs?: Maybe<Array<TripLog>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type UserCount = {
  __typename?: "UserCount";
  Otp: Scalars["Int"]["output"];
  TripFeedback: Scalars["Int"]["output"];
  addresses: Scalars["Int"]["output"];
  adhocNotificationStudentUsers: Scalars["Int"]["output"];
  adhocNotifications: Scalars["Int"]["output"];
  agentLeaveRequests: Scalars["Int"]["output"];
  agentModifiedLeaveRequests: Scalars["Int"]["output"];
  cashOutRequests: Scalars["Int"]["output"];
  creditNotes: Scalars["Int"]["output"];
  fcmSubscribers: Scalars["Int"]["output"];
  paymentReminderNotificationLogs: Scalars["Int"]["output"];
  roles: Scalars["Int"]["output"];
  schoolRegistrationAttempts: Scalars["Int"]["output"];
  serviceProviders: Scalars["Int"]["output"];
  sponsorLeaveRequests: Scalars["Int"]["output"];
  sponsorModifiedLeaveRequests: Scalars["Int"]["output"];
  travelTags: Scalars["Int"]["output"];
  tripLogs: Scalars["Int"]["output"];
};

export type UserCreateNestedOneWithoutAddressesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<UserCreateWithoutAddressesInput>;
};

export type UserCreateNestedOneWithoutAdhocNotificationStudentUsersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAdhocNotificationStudentUsersInput>;
  create?: InputMaybe<UserCreateWithoutAdhocNotificationStudentUsersInput>;
};

export type UserCreateNestedOneWithoutAdhocNotificationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAdhocNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutAdhocNotificationsInput>;
};

export type UserCreateNestedOneWithoutAgentInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAgentInput>;
  create?: InputMaybe<UserCreateWithoutAgentInput>;
};

export type UserCreateNestedOneWithoutAgentLeaveRequestsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAgentLeaveRequestsInput>;
  create?: InputMaybe<UserCreateWithoutAgentLeaveRequestsInput>;
};

export type UserCreateNestedOneWithoutAgentModifiedLeaveRequestsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAgentModifiedLeaveRequestsInput>;
  create?: InputMaybe<UserCreateWithoutAgentModifiedLeaveRequestsInput>;
};

export type UserCreateNestedOneWithoutBusAttendantInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBusAttendantInput>;
  create?: InputMaybe<UserCreateWithoutBusAttendantInput>;
};

export type UserCreateNestedOneWithoutCashOutRequestsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCashOutRequestsInput>;
  create?: InputMaybe<UserCreateWithoutCashOutRequestsInput>;
};

export type UserCreateNestedOneWithoutCreditNotesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreditNotesInput>;
  create?: InputMaybe<UserCreateWithoutCreditNotesInput>;
};

export type UserCreateNestedOneWithoutCreditNotesInputWithoutCreate = {
  connect: UserWhereUniqueInput;
};

export type UserCreateNestedOneWithoutDriverInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDriverInput>;
  create?: InputMaybe<UserCreateWithoutDriverInput>;
};

export type UserCreateNestedOneWithoutOwnedServiceProviderInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOwnedServiceProviderInput>;
  create?: InputMaybe<UserCreateWithoutOwnedServiceProviderInput>;
};

export type UserCreateNestedOneWithoutPassengerInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPassengerInput>;
  create?: InputMaybe<UserCreateWithoutPassengerInput>;
};

export type UserCreateNestedOneWithoutRolesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRolesInput>;
  create?: InputMaybe<UserCreateWithoutRolesInput>;
};

export type UserCreateNestedOneWithoutSchoolRegistrationAttemptsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSchoolRegistrationAttemptsInput>;
  create?: InputMaybe<UserCreateWithoutSchoolRegistrationAttemptsInput>;
};

export type UserCreateNestedOneWithoutServiceProvidersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutServiceProvidersInput>;
  create?: InputMaybe<UserCreateWithoutServiceProvidersInput>;
};

export type UserCreateNestedOneWithoutSponsorInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSponsorInput>;
  create?: InputMaybe<UserCreateWithoutSponsorInput>;
};

export type UserCreateNestedOneWithoutSponsorLeaveRequestsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSponsorLeaveRequestsInput>;
  create?: InputMaybe<UserCreateWithoutSponsorLeaveRequestsInput>;
};

export type UserCreateNestedOneWithoutSponsorModifiedLeaveRequestsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSponsorModifiedLeaveRequestsInput>;
  create?: InputMaybe<UserCreateWithoutSponsorModifiedLeaveRequestsInput>;
};

export type UserCreateNestedOneWithoutTripFeedbackInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTripFeedbackInput>;
  create?: InputMaybe<UserCreateWithoutTripFeedbackInput>;
};

export type UserCreateNestedOneWithoutTripLogsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTripLogsInput>;
  create?: InputMaybe<UserCreateWithoutTripLogsInput>;
};

export type UserCreateOrConnectWithoutAddressesInput = {
  create: UserCreateWithoutAddressesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAdhocNotificationStudentUsersInput = {
  create: UserCreateWithoutAdhocNotificationStudentUsersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAdhocNotificationsInput = {
  create: UserCreateWithoutAdhocNotificationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAgentInput = {
  create: UserCreateWithoutAgentInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAgentLeaveRequestsInput = {
  create: UserCreateWithoutAgentLeaveRequestsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAgentModifiedLeaveRequestsInput = {
  create: UserCreateWithoutAgentModifiedLeaveRequestsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBusAttendantInput = {
  create: UserCreateWithoutBusAttendantInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCashOutRequestsInput = {
  create: UserCreateWithoutCashOutRequestsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreditNotesInput = {
  create: UserCreateWithoutCreditNotesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDriverInput = {
  create: UserCreateWithoutDriverInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOwnedServiceProviderInput = {
  create: UserCreateWithoutOwnedServiceProviderInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPassengerInput = {
  create: UserCreateWithoutPassengerInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRolesInput = {
  create: UserCreateWithoutRolesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSchoolRegistrationAttemptsInput = {
  create: UserCreateWithoutSchoolRegistrationAttemptsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutServiceProvidersInput = {
  create: UserCreateWithoutServiceProvidersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSponsorInput = {
  create: UserCreateWithoutSponsorInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSponsorLeaveRequestsInput = {
  create: UserCreateWithoutSponsorLeaveRequestsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSponsorModifiedLeaveRequestsInput = {
  create: UserCreateWithoutSponsorModifiedLeaveRequestsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTripFeedbackInput = {
  create: UserCreateWithoutTripFeedbackInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTripLogsInput = {
  create: UserCreateWithoutTripLogsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutAddressesInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutAdhocNotificationStudentUsersInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutAdhocNotificationsInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutAgentInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutAgentLeaveRequestsInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutAgentModifiedLeaveRequestsInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutBusAttendantInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutCashOutRequestsInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutCreditNotesInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutDriverInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutOwnedServiceProviderInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutPassengerInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutRolesInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutSchoolRegistrationAttemptsInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutServiceProvidersInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutSponsorInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutSponsorLeaveRequestsInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutSponsorModifiedLeaveRequestsInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutTripFeedbackInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  tripLogs?: InputMaybe<TripLogCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreateWithoutTripLogsInput = {
  Otp?: InputMaybe<OtpCreateNestedManyWithoutUserInput>;
  TripFeedback?: InputMaybe<TripFeedbackCreateNestedManyWithoutUserInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutUserInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserCreateNestedManyWithoutStudentUserInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserCreateNestedManyWithoutSponsorUserInput>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutUserInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutAgentInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateAgentUserInput>;
  busAttendant?: InputMaybe<BusAttendantCreateNestedOneWithoutUserInput>;
  cashOutRequests?: InputMaybe<CashOutRequestCreateNestedManyWithoutRequestedByInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteCreateNestedManyWithoutUserInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverCreateNestedOneWithoutUserInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberCreateNestedManyWithoutUserInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutOwnerUserInput>;
  passenger?: InputMaybe<PassengerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogCreateNestedManyWithoutUserInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleCreateNestedManyWithoutUserInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptCreateNestedManyWithoutUserInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<SponsorCreateNestedOneWithoutUserInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutSponsorInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestCreateNestedManyWithoutLastUpdateSponsorUserInput>;
  travelTags?: InputMaybe<UserTravelTagCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserCreditNote = {
  __typename?: "UserCreditNote";
  amount: Scalars["Float"]["output"];
  country: Country;
  creditInfo?: Maybe<CreditInfo>;
  currency: CurrencyCode;
  userId: Scalars["String"]["output"];
};

export type UserGetSchoolRegistrationWithAvailableCreditNote = {
  __typename?: "UserGetSchoolRegistrationWithAvailableCreditNote";
  Otp?: Maybe<Array<Otp>>;
  TripFeedback?: Maybe<Array<TripFeedback>>;
  _count: UserCount;
  addresses?: Maybe<Array<Address>>;
  adhocNotificationStudentUsers?: Maybe<Array<AdhocNotificationStudentUser>>;
  adhocNotifications?: Maybe<Array<AdhocNotificationSponsorUser>>;
  agent?: Maybe<Agent>;
  agentLeaveRequests?: Maybe<Array<LeaveRequest>>;
  agentModifiedLeaveRequests?: Maybe<Array<LeaveRequest>>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  availableCreditNoteAmount?: Maybe<Scalars["Float"]["output"]>;
  busAttendant?: Maybe<BusAttendant>;
  cashOutRequests?: Maybe<Array<CashOutRequest>>;
  civilId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  creditNotes?: Maybe<Array<CreditNote>>;
  dateOfBirth?: Maybe<Scalars["DateTime"]["output"]>;
  driver?: Maybe<Driver>;
  email?: Maybe<Scalars["String"]["output"]>;
  fcmSubscribers?: Maybe<Array<FcmSubscriber>>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  googleId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  middleNameAr?: Maybe<Scalars["String"]["output"]>;
  ownedServiceProvider?: Maybe<ServiceProvider>;
  passenger?: Maybe<Passenger>;
  paymentReminderNotificationLogs?: Maybe<
    Array<PaymentReminderNotificationLog>
  >;
  phone?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  primaryLanguage?: Maybe<Language>;
  roles?: Maybe<Array<UserRole>>;
  schoolRegistrationAttempts?: Maybe<Array<SchoolRegistrationAttempt>>;
  serviceProviders?: Maybe<Array<ServiceProviderUser>>;
  sponsor?: Maybe<Sponsor>;
  sponsorLeaveRequests?: Maybe<Array<LeaveRequest>>;
  sponsorModifiedLeaveRequests?: Maybe<Array<LeaveRequest>>;
  travelTags?: Maybe<Array<UserTravelTag>>;
  tripLogs?: Maybe<Array<TripLog>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type UserIdInput = {
  userId: Scalars["String"]["input"];
};

export type UserIdentifier = {
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserResponsePayload = {
  __typename?: "UserResponsePayload";
  dateOfBirth?: Maybe<Scalars["DateTime"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  middleNameAr?: Maybe<Scalars["String"]["output"]>;
  phone: Scalars["String"]["output"];
  primaryLanguage?: Maybe<Language>;
  roles?: Maybe<Array<RoleResponsePayload>>;
};

export type UserRole = {
  __typename?: "UserRole";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  role: Role;
  roleId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type UserRoleCreateManyRoleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type UserRoleCreateManyRoleInputEnvelope = {
  data: Array<UserRoleCreateManyRoleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserRoleCreateManyUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  roleId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserRoleCreateManyUserInputEnvelope = {
  data: Array<UserRoleCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserRoleCreateNestedManyWithoutRoleInput = {
  connect?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserRoleCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<UserRoleCreateWithoutRoleInput>>;
  createMany?: InputMaybe<UserRoleCreateManyRoleInputEnvelope>;
};

export type UserRoleCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserRoleCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserRoleCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserRoleCreateManyUserInputEnvelope>;
};

export type UserRoleCreateOrConnectWithoutRoleInput = {
  create: UserRoleCreateWithoutRoleInput;
  where: UserRoleWhereUniqueInput;
};

export type UserRoleCreateOrConnectWithoutUserInput = {
  create: UserRoleCreateWithoutUserInput;
  where: UserRoleWhereUniqueInput;
};

export type UserRoleCreateWithoutRoleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserCreateNestedOneWithoutRolesInput;
};

export type UserRoleCreateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  role: RoleCreateNestedOneWithoutUsersInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserRoleListRelationFilter = {
  every?: InputMaybe<UserRoleWhereInput>;
  none?: InputMaybe<UserRoleWhereInput>;
  some?: InputMaybe<UserRoleWhereInput>;
};

export type UserRoleScalarWhereInput = {
  AND?: InputMaybe<Array<UserRoleScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserRoleScalarWhereInput>>;
  OR?: InputMaybe<Array<UserRoleScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  roleId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserRoleUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserRoleUpdateManyWithWhereWithoutRoleInput = {
  data: UserRoleUpdateManyMutationInput;
  where: UserRoleScalarWhereInput;
};

export type UserRoleUpdateManyWithWhereWithoutUserInput = {
  data: UserRoleUpdateManyMutationInput;
  where: UserRoleScalarWhereInput;
};

export type UserRoleUpdateManyWithoutRoleNestedInput = {
  connect?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserRoleCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<UserRoleCreateWithoutRoleInput>>;
  createMany?: InputMaybe<UserRoleCreateManyRoleInputEnvelope>;
  delete?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserRoleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  set?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  update?: InputMaybe<Array<UserRoleUpdateWithWhereUniqueWithoutRoleInput>>;
  updateMany?: InputMaybe<Array<UserRoleUpdateManyWithWhereWithoutRoleInput>>;
  upsert?: InputMaybe<Array<UserRoleUpsertWithWhereUniqueWithoutRoleInput>>;
};

export type UserRoleUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserRoleCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserRoleCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserRoleCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserRoleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  set?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  update?: InputMaybe<Array<UserRoleUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserRoleUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserRoleUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserRoleUpdateWithWhereUniqueWithoutRoleInput = {
  data: UserRoleUpdateWithoutRoleInput;
  where: UserRoleWhereUniqueInput;
};

export type UserRoleUpdateWithWhereUniqueWithoutUserInput = {
  data: UserRoleUpdateWithoutUserInput;
  where: UserRoleWhereUniqueInput;
};

export type UserRoleUpdateWithoutRoleInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutRolesNestedInput>;
};

export type UserRoleUpdateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserRoleUpsertWithWhereUniqueWithoutRoleInput = {
  create: UserRoleCreateWithoutRoleInput;
  update: UserRoleUpdateWithoutRoleInput;
  where: UserRoleWhereUniqueInput;
};

export type UserRoleUpsertWithWhereUniqueWithoutUserInput = {
  create: UserRoleCreateWithoutUserInput;
  update: UserRoleUpdateWithoutUserInput;
  where: UserRoleWhereUniqueInput;
};

export type UserRoleWhereInput = {
  AND?: InputMaybe<Array<UserRoleWhereInput>>;
  NOT?: InputMaybe<Array<UserRoleWhereInput>>;
  OR?: InputMaybe<Array<UserRoleWhereInput>>;
  id?: InputMaybe<StringFilter>;
  role?: InputMaybe<RoleRelationFilter>;
  roleId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserRoleWhereUniqueInput = {
  AND?: InputMaybe<Array<UserRoleWhereInput>>;
  NOT?: InputMaybe<Array<UserRoleWhereInput>>;
  OR?: InputMaybe<Array<UserRoleWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<RoleRelationFilter>;
  roleId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTravelTag = {
  __typename?: "UserTravelTag";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isDelivered: Scalars["Boolean"]["output"];
  travelTag: TravelTag;
  travelTagId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["String"]["output"];
};

export type UserTravelTagCreateManyUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDelivered?: InputMaybe<Scalars["Boolean"]["input"]>;
  travelTagId: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserTravelTagCreateManyUserInputEnvelope = {
  data: Array<UserTravelTagCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserTravelTagCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserTravelTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTravelTagCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserTravelTagCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserTravelTagCreateManyUserInputEnvelope>;
};

export type UserTravelTagCreateOrConnectWithoutUserInput = {
  create: UserTravelTagCreateWithoutUserInput;
  where: UserTravelTagWhereUniqueInput;
};

export type UserTravelTagCreateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDelivered?: InputMaybe<Scalars["Boolean"]["input"]>;
  travelTag: TravelTagCreateNestedOneWithoutUserTravelTagsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserTravelTagListRelationFilter = {
  every?: InputMaybe<UserTravelTagWhereInput>;
  none?: InputMaybe<UserTravelTagWhereInput>;
  some?: InputMaybe<UserTravelTagWhereInput>;
};

export type UserTravelTagScalarWhereInput = {
  AND?: InputMaybe<Array<UserTravelTagScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserTravelTagScalarWhereInput>>;
  OR?: InputMaybe<Array<UserTravelTagScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isDelivered?: InputMaybe<BoolFilter>;
  travelTagId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTravelTagUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDelivered?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserTravelTagUpdateManyWithWhereWithoutUserInput = {
  data: UserTravelTagUpdateManyMutationInput;
  where: UserTravelTagScalarWhereInput;
};

export type UserTravelTagUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserTravelTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserTravelTagCreateOrConnectWithoutUserInput>
  >;
  create?: InputMaybe<Array<UserTravelTagCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserTravelTagCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserTravelTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserTravelTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserTravelTagWhereUniqueInput>>;
  set?: InputMaybe<Array<UserTravelTagWhereUniqueInput>>;
  update?: InputMaybe<
    Array<UserTravelTagUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: InputMaybe<
    Array<UserTravelTagUpdateManyWithWhereWithoutUserInput>
  >;
  upsert?: InputMaybe<
    Array<UserTravelTagUpsertWithWhereUniqueWithoutUserInput>
  >;
};

export type UserTravelTagUpdateWithWhereUniqueWithoutUserInput = {
  data: UserTravelTagUpdateWithoutUserInput;
  where: UserTravelTagWhereUniqueInput;
};

export type UserTravelTagUpdateWithoutUserInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDelivered?: InputMaybe<Scalars["Boolean"]["input"]>;
  travelTag?: InputMaybe<TravelTagUpdateOneRequiredWithoutUserTravelTagsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserTravelTagUpsertWithWhereUniqueWithoutUserInput = {
  create: UserTravelTagCreateWithoutUserInput;
  update: UserTravelTagUpdateWithoutUserInput;
  where: UserTravelTagWhereUniqueInput;
};

export type UserTravelTagWhereInput = {
  AND?: InputMaybe<Array<UserTravelTagWhereInput>>;
  NOT?: InputMaybe<Array<UserTravelTagWhereInput>>;
  OR?: InputMaybe<Array<UserTravelTagWhereInput>>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isDelivered?: InputMaybe<BoolFilter>;
  travelTag?: InputMaybe<TravelTagRelationFilter>;
  travelTagId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserTravelTagWhereUniqueInput = {
  AND?: InputMaybe<Array<UserTravelTagWhereInput>>;
  NOT?: InputMaybe<Array<UserTravelTagWhereInput>>;
  OR?: InputMaybe<Array<UserTravelTagWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<BoolFilter>;
  isDelivered?: InputMaybe<BoolFilter>;
  travelTag?: InputMaybe<TravelTagRelationFilter>;
  travelTagId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserUpdateOneRequiredWithoutAdhocNotificationStudentUsersNestedInput =
  {
    connect?: InputMaybe<UserWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAdhocNotificationStudentUsersInput>;
    create?: InputMaybe<UserCreateWithoutAdhocNotificationStudentUsersInput>;
    update?: InputMaybe<UserUpdateToOneWithWhereWithoutAdhocNotificationStudentUsersInput>;
    upsert?: InputMaybe<UserUpsertWithoutAdhocNotificationStudentUsersInput>;
  };

export type UserUpdateOneRequiredWithoutAdhocNotificationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAdhocNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutAdhocNotificationsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutAdhocNotificationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutAdhocNotificationsInput>;
};

export type UserUpdateOneRequiredWithoutAgentNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAgentInput>;
  create?: InputMaybe<UserCreateWithoutAgentInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutAgentInput>;
  upsert?: InputMaybe<UserUpsertWithoutAgentInput>;
};

export type UserUpdateOneRequiredWithoutBusAttendantNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBusAttendantInput>;
  create?: InputMaybe<UserCreateWithoutBusAttendantInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutBusAttendantInput>;
  upsert?: InputMaybe<UserUpsertWithoutBusAttendantInput>;
};

export type UserUpdateOneRequiredWithoutCreditNotesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreditNotesInput>;
  create?: InputMaybe<UserCreateWithoutCreditNotesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutCreditNotesInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreditNotesInput>;
};

export type UserUpdateOneRequiredWithoutDriverNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDriverInput>;
  create?: InputMaybe<UserCreateWithoutDriverInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutDriverInput>;
  upsert?: InputMaybe<UserUpsertWithoutDriverInput>;
};

export type UserUpdateOneRequiredWithoutOwnedServiceProviderNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOwnedServiceProviderInput>;
  create?: InputMaybe<UserCreateWithoutOwnedServiceProviderInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutOwnedServiceProviderInput>;
  upsert?: InputMaybe<UserUpsertWithoutOwnedServiceProviderInput>;
};

export type UserUpdateOneRequiredWithoutPassengerNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPassengerInput>;
  create?: InputMaybe<UserCreateWithoutPassengerInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutPassengerInput>;
  upsert?: InputMaybe<UserUpsertWithoutPassengerInput>;
};

export type UserUpdateOneRequiredWithoutRolesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRolesInput>;
  create?: InputMaybe<UserCreateWithoutRolesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutRolesInput>;
  upsert?: InputMaybe<UserUpsertWithoutRolesInput>;
};

export type UserUpdateOneRequiredWithoutSchoolRegistrationAttemptsNestedInput =
  {
    connect?: InputMaybe<UserWhereUniqueInput>;
    connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSchoolRegistrationAttemptsInput>;
    create?: InputMaybe<UserCreateWithoutSchoolRegistrationAttemptsInput>;
    update?: InputMaybe<UserUpdateToOneWithWhereWithoutSchoolRegistrationAttemptsInput>;
    upsert?: InputMaybe<UserUpsertWithoutSchoolRegistrationAttemptsInput>;
  };

export type UserUpdateOneRequiredWithoutServiceProvidersNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutServiceProvidersInput>;
  create?: InputMaybe<UserCreateWithoutServiceProvidersInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutServiceProvidersInput>;
  upsert?: InputMaybe<UserUpsertWithoutServiceProvidersInput>;
};

export type UserUpdateOneRequiredWithoutSponsorLeaveRequestsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSponsorLeaveRequestsInput>;
  create?: InputMaybe<UserCreateWithoutSponsorLeaveRequestsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutSponsorLeaveRequestsInput>;
  upsert?: InputMaybe<UserUpsertWithoutSponsorLeaveRequestsInput>;
};

export type UserUpdateOneRequiredWithoutSponsorNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSponsorInput>;
  create?: InputMaybe<UserCreateWithoutSponsorInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutSponsorInput>;
  upsert?: InputMaybe<UserUpsertWithoutSponsorInput>;
};

export type UserUpdateOneRequiredWithoutTripFeedbackNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTripFeedbackInput>;
  create?: InputMaybe<UserCreateWithoutTripFeedbackInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutTripFeedbackInput>;
  upsert?: InputMaybe<UserUpsertWithoutTripFeedbackInput>;
};

export type UserUpdateOneWithoutAddressesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<UserCreateWithoutAddressesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutAddressesInput>;
  upsert?: InputMaybe<UserUpsertWithoutAddressesInput>;
};

export type UserUpdateOneWithoutAgentLeaveRequestsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAgentLeaveRequestsInput>;
  create?: InputMaybe<UserCreateWithoutAgentLeaveRequestsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutAgentLeaveRequestsInput>;
  upsert?: InputMaybe<UserUpsertWithoutAgentLeaveRequestsInput>;
};

export type UserUpdateOneWithoutAgentModifiedLeaveRequestsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAgentModifiedLeaveRequestsInput>;
  create?: InputMaybe<UserCreateWithoutAgentModifiedLeaveRequestsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutAgentModifiedLeaveRequestsInput>;
  upsert?: InputMaybe<UserUpsertWithoutAgentModifiedLeaveRequestsInput>;
};

export type UserUpdateOneWithoutCashOutRequestsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCashOutRequestsInput>;
  create?: InputMaybe<UserCreateWithoutCashOutRequestsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutCashOutRequestsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCashOutRequestsInput>;
};

export type UserUpdateOneWithoutSponsorModifiedLeaveRequestsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSponsorModifiedLeaveRequestsInput>;
  create?: InputMaybe<UserCreateWithoutSponsorModifiedLeaveRequestsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutSponsorModifiedLeaveRequestsInput>;
  upsert?: InputMaybe<UserUpsertWithoutSponsorModifiedLeaveRequestsInput>;
};

export type UserUpdateOneWithoutTripLogsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTripLogsInput>;
  create?: InputMaybe<UserCreateWithoutTripLogsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutTripLogsInput>;
  upsert?: InputMaybe<UserUpsertWithoutTripLogsInput>;
};

export type UserUpdateToOneWithWhereWithoutAddressesInput = {
  data: UserUpdateWithoutAddressesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutAdhocNotificationStudentUsersInput =
  {
    data: UserUpdateWithoutAdhocNotificationStudentUsersInput;
    where?: InputMaybe<UserWhereInput>;
  };

export type UserUpdateToOneWithWhereWithoutAdhocNotificationsInput = {
  data: UserUpdateWithoutAdhocNotificationsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutAgentInput = {
  data: UserUpdateWithoutAgentInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutAgentLeaveRequestsInput = {
  data: UserUpdateWithoutAgentLeaveRequestsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutAgentModifiedLeaveRequestsInput = {
  data: UserUpdateWithoutAgentModifiedLeaveRequestsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutBusAttendantInput = {
  data: UserUpdateWithoutBusAttendantInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutCashOutRequestsInput = {
  data: UserUpdateWithoutCashOutRequestsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutCreditNotesInput = {
  data: UserUpdateWithoutCreditNotesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutDriverInput = {
  data: UserUpdateWithoutDriverInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutOwnedServiceProviderInput = {
  data: UserUpdateWithoutOwnedServiceProviderInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutPassengerInput = {
  data: UserUpdateWithoutPassengerInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutRolesInput = {
  data: UserUpdateWithoutRolesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutSchoolRegistrationAttemptsInput = {
  data: UserUpdateWithoutSchoolRegistrationAttemptsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutServiceProvidersInput = {
  data: UserUpdateWithoutServiceProvidersInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutSponsorInput = {
  data: UserUpdateWithoutSponsorInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutSponsorLeaveRequestsInput = {
  data: UserUpdateWithoutSponsorLeaveRequestsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutSponsorModifiedLeaveRequestsInput = {
  data: UserUpdateWithoutSponsorModifiedLeaveRequestsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutTripFeedbackInput = {
  data: UserUpdateWithoutTripFeedbackInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutTripLogsInput = {
  data: UserUpdateWithoutTripLogsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateWithoutAddressesInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutAdhocNotificationStudentUsersInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutAdhocNotificationsInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutAgentInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutAgentLeaveRequestsInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutAgentModifiedLeaveRequestsInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutBusAttendantInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutCashOutRequestsInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutCreditNotesInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutDriverInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutOwnedServiceProviderInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutPassengerInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutRolesInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutSchoolRegistrationAttemptsInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutServiceProvidersInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutSponsorInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutSponsorLeaveRequestsInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutSponsorModifiedLeaveRequestsInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutTripFeedbackInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  tripLogs?: InputMaybe<TripLogUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpdateWithoutTripLogsInput = {
  Otp?: InputMaybe<OtpUpdateManyWithoutUserNestedInput>;
  TripFeedback?: InputMaybe<TripFeedbackUpdateManyWithoutUserNestedInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutUserNestedInput>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserUpdateManyWithoutStudentUserNestedInput>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserUpdateManyWithoutSponsorUserNestedInput>;
  agent?: InputMaybe<AgentUpdateOneWithoutUserNestedInput>;
  agentLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutAgentNestedInput>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateAgentUserNestedInput>;
  busAttendant?: InputMaybe<BusAttendantUpdateOneWithoutUserNestedInput>;
  cashOutRequests?: InputMaybe<CashOutRequestUpdateManyWithoutRequestedByNestedInput>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteUpdateManyWithoutUserNestedInput>;
  dateOfBirth?: InputMaybe<Scalars["DateTime"]["input"]>;
  driver?: InputMaybe<DriverUpdateOneWithoutUserNestedInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberUpdateManyWithoutUserNestedInput>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  firstNameAr?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  lastNameAr?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
  middleNameAr?: InputMaybe<Scalars["String"]["input"]>;
  ownedServiceProvider?: InputMaybe<ServiceProviderUpdateOneWithoutOwnerUserNestedInput>;
  passenger?: InputMaybe<PassengerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogUpdateManyWithoutUserNestedInput>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<Scalars["String"]["input"]>;
  primaryLanguage?: InputMaybe<Language>;
  roles?: InputMaybe<UserRoleUpdateManyWithoutUserNestedInput>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptUpdateManyWithoutUserNestedInput>;
  serviceProviders?: InputMaybe<ServiceProviderUserUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<SponsorUpdateOneWithoutUserNestedInput>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutSponsorNestedInput>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestUpdateManyWithoutLastUpdateSponsorUserNestedInput>;
  travelTags?: InputMaybe<UserTravelTagUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserUpsertWithoutAddressesInput = {
  create: UserCreateWithoutAddressesInput;
  update: UserUpdateWithoutAddressesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutAdhocNotificationStudentUsersInput = {
  create: UserCreateWithoutAdhocNotificationStudentUsersInput;
  update: UserUpdateWithoutAdhocNotificationStudentUsersInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutAdhocNotificationsInput = {
  create: UserCreateWithoutAdhocNotificationsInput;
  update: UserUpdateWithoutAdhocNotificationsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutAgentInput = {
  create: UserCreateWithoutAgentInput;
  update: UserUpdateWithoutAgentInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutAgentLeaveRequestsInput = {
  create: UserCreateWithoutAgentLeaveRequestsInput;
  update: UserUpdateWithoutAgentLeaveRequestsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutAgentModifiedLeaveRequestsInput = {
  create: UserCreateWithoutAgentModifiedLeaveRequestsInput;
  update: UserUpdateWithoutAgentModifiedLeaveRequestsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutBusAttendantInput = {
  create: UserCreateWithoutBusAttendantInput;
  update: UserUpdateWithoutBusAttendantInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutCashOutRequestsInput = {
  create: UserCreateWithoutCashOutRequestsInput;
  update: UserUpdateWithoutCashOutRequestsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutCreditNotesInput = {
  create: UserCreateWithoutCreditNotesInput;
  update: UserUpdateWithoutCreditNotesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutDriverInput = {
  create: UserCreateWithoutDriverInput;
  update: UserUpdateWithoutDriverInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutOwnedServiceProviderInput = {
  create: UserCreateWithoutOwnedServiceProviderInput;
  update: UserUpdateWithoutOwnedServiceProviderInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutPassengerInput = {
  create: UserCreateWithoutPassengerInput;
  update: UserUpdateWithoutPassengerInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutRolesInput = {
  create: UserCreateWithoutRolesInput;
  update: UserUpdateWithoutRolesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutSchoolRegistrationAttemptsInput = {
  create: UserCreateWithoutSchoolRegistrationAttemptsInput;
  update: UserUpdateWithoutSchoolRegistrationAttemptsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutServiceProvidersInput = {
  create: UserCreateWithoutServiceProvidersInput;
  update: UserUpdateWithoutServiceProvidersInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutSponsorInput = {
  create: UserCreateWithoutSponsorInput;
  update: UserUpdateWithoutSponsorInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutSponsorLeaveRequestsInput = {
  create: UserCreateWithoutSponsorLeaveRequestsInput;
  update: UserUpdateWithoutSponsorLeaveRequestsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutSponsorModifiedLeaveRequestsInput = {
  create: UserCreateWithoutSponsorModifiedLeaveRequestsInput;
  update: UserUpdateWithoutSponsorModifiedLeaveRequestsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutTripFeedbackInput = {
  create: UserCreateWithoutTripFeedbackInput;
  update: UserUpdateWithoutTripFeedbackInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutTripLogsInput = {
  create: UserCreateWithoutTripLogsInput;
  update: UserUpdateWithoutTripLogsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  Otp?: InputMaybe<OtpListRelationFilter>;
  TripFeedback?: InputMaybe<TripFeedbackListRelationFilter>;
  addresses?: InputMaybe<AddressListRelationFilter>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserListRelationFilter>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserListRelationFilter>;
  agent?: InputMaybe<AgentRelationFilter>;
  agentLeaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  cashOutRequests?: InputMaybe<CashOutRequestListRelationFilter>;
  civilId?: InputMaybe<StringFilter>;
  creditNotes?: InputMaybe<CreditNoteListRelationFilter>;
  dateOfBirth?: InputMaybe<DateTimeFilter>;
  driver?: InputMaybe<DriverRelationFilter>;
  email?: InputMaybe<StringFilter>;
  fcmSubscribers?: InputMaybe<FcmSubscriberListRelationFilter>;
  firstName?: InputMaybe<StringFilter>;
  firstNameAr?: InputMaybe<StringFilter>;
  gender?: InputMaybe<EnumGenderFilter>;
  googleId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
  lastNameAr?: InputMaybe<StringFilter>;
  middleName?: InputMaybe<StringFilter>;
  middleNameAr?: InputMaybe<StringFilter>;
  ownedServiceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  passenger?: InputMaybe<PassengerRelationFilter>;
  password?: InputMaybe<StringFilter>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogListRelationFilter>;
  phone?: InputMaybe<StringFilter>;
  photo?: InputMaybe<StringFilter>;
  primaryLanguage?: InputMaybe<EnumLanguageFilter>;
  roles?: InputMaybe<UserRoleListRelationFilter>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptListRelationFilter>;
  serviceProviders?: InputMaybe<ServiceProviderUserListRelationFilter>;
  sponsor?: InputMaybe<SponsorRelationFilter>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  travelTags?: InputMaybe<UserTravelTagListRelationFilter>;
  tripLogs?: InputMaybe<TripLogListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  Otp?: InputMaybe<OtpListRelationFilter>;
  TripFeedback?: InputMaybe<TripFeedbackListRelationFilter>;
  addresses?: InputMaybe<AddressListRelationFilter>;
  adhocNotificationStudentUsers?: InputMaybe<AdhocNotificationStudentUserListRelationFilter>;
  adhocNotifications?: InputMaybe<AdhocNotificationSponsorUserListRelationFilter>;
  agent?: InputMaybe<AgentRelationFilter>;
  agentLeaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  agentModifiedLeaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  busAttendant?: InputMaybe<BusAttendantRelationFilter>;
  cashOutRequests?: InputMaybe<CashOutRequestListRelationFilter>;
  civilId?: InputMaybe<Scalars["String"]["input"]>;
  creditNotes?: InputMaybe<CreditNoteListRelationFilter>;
  dateOfBirth?: InputMaybe<DateTimeFilter>;
  driver?: InputMaybe<DriverRelationFilter>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fcmSubscribers?: InputMaybe<FcmSubscriberListRelationFilter>;
  firstName?: InputMaybe<StringFilter>;
  firstNameAr?: InputMaybe<StringFilter>;
  gender?: InputMaybe<EnumGenderFilter>;
  googleId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<StringFilter>;
  lastNameAr?: InputMaybe<StringFilter>;
  middleName?: InputMaybe<StringFilter>;
  middleNameAr?: InputMaybe<StringFilter>;
  ownedServiceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  passenger?: InputMaybe<PassengerRelationFilter>;
  password?: InputMaybe<StringFilter>;
  paymentReminderNotificationLogs?: InputMaybe<PaymentReminderNotificationLogListRelationFilter>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  photo?: InputMaybe<StringFilter>;
  primaryLanguage?: InputMaybe<EnumLanguageFilter>;
  roles?: InputMaybe<UserRoleListRelationFilter>;
  schoolRegistrationAttempts?: InputMaybe<SchoolRegistrationAttemptListRelationFilter>;
  serviceProviders?: InputMaybe<ServiceProviderUserListRelationFilter>;
  sponsor?: InputMaybe<SponsorRelationFilter>;
  sponsorLeaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  sponsorModifiedLeaveRequests?: InputMaybe<LeaveRequestListRelationFilter>;
  travelTags?: InputMaybe<UserTravelTagListRelationFilter>;
  tripLogs?: InputMaybe<TripLogListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserWithAccessibleCountries = {
  __typename?: "UserWithAccessibleCountries";
  Otp?: Maybe<Array<Otp>>;
  TripFeedback?: Maybe<Array<TripFeedback>>;
  _count: UserCount;
  /** @deprecated in favor of spCountryCode */
  accessibleCountryCodes: Array<CountryCode>;
  addresses?: Maybe<Array<Address>>;
  adhocNotificationStudentUsers?: Maybe<Array<AdhocNotificationStudentUser>>;
  adhocNotifications?: Maybe<Array<AdhocNotificationSponsorUser>>;
  agent?: Maybe<Agent>;
  agentLeaveRequests?: Maybe<Array<LeaveRequest>>;
  agentModifiedLeaveRequests?: Maybe<Array<LeaveRequest>>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendant?: Maybe<BusAttendant>;
  cashOutRequests?: Maybe<Array<CashOutRequest>>;
  civilId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  creditNotes?: Maybe<Array<CreditNote>>;
  dateOfBirth?: Maybe<Scalars["DateTime"]["output"]>;
  driver?: Maybe<Driver>;
  email?: Maybe<Scalars["String"]["output"]>;
  fcmSubscribers?: Maybe<Array<FcmSubscriber>>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  googleId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isEmushrifMember: Scalars["Boolean"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  middleNameAr?: Maybe<Scalars["String"]["output"]>;
  ownedServiceProvider?: Maybe<ServiceProvider>;
  passenger?: Maybe<Passenger>;
  paymentReminderNotificationLogs?: Maybe<
    Array<PaymentReminderNotificationLog>
  >;
  phone?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  primaryLanguage?: Maybe<Language>;
  roles?: Maybe<Array<UserRole>>;
  schoolRegistrationAttempts?: Maybe<Array<SchoolRegistrationAttempt>>;
  serviceProviders?: Maybe<Array<ServiceProviderUser>>;
  spCountryCode?: Maybe<CountryCode>;
  sponsor?: Maybe<Sponsor>;
  sponsorLeaveRequests?: Maybe<Array<LeaveRequest>>;
  sponsorModifiedLeaveRequests?: Maybe<Array<LeaveRequest>>;
  travelTags?: Maybe<Array<UserTravelTag>>;
  tripLogs?: Maybe<Array<TripLog>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type UserWithWithReferenceNumberAndStudents = {
  __typename?: "UserWithWithReferenceNumberAndStudents";
  Otp?: Maybe<Array<Otp>>;
  TripFeedback?: Maybe<Array<TripFeedback>>;
  _count: UserCount;
  addresses?: Maybe<Array<Address>>;
  adhocNotificationStudentUsers?: Maybe<Array<AdhocNotificationStudentUser>>;
  adhocNotifications?: Maybe<Array<AdhocNotificationSponsorUser>>;
  agent?: Maybe<Agent>;
  agentLeaveRequests?: Maybe<Array<LeaveRequest>>;
  agentModifiedLeaveRequests?: Maybe<Array<LeaveRequest>>;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  busAttendant?: Maybe<BusAttendant>;
  cashOutRequests?: Maybe<Array<CashOutRequest>>;
  civilId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  creditNotes?: Maybe<Array<CreditNoteWithReferenceNumberAndStudents>>;
  dateOfBirth?: Maybe<Scalars["DateTime"]["output"]>;
  driver?: Maybe<Driver>;
  email?: Maybe<Scalars["String"]["output"]>;
  fcmSubscribers?: Maybe<Array<FcmSubscriber>>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  firstNameAr?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  googleId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  lastNameAr?: Maybe<Scalars["String"]["output"]>;
  middleName?: Maybe<Scalars["String"]["output"]>;
  middleNameAr?: Maybe<Scalars["String"]["output"]>;
  ownedServiceProvider?: Maybe<ServiceProvider>;
  passenger?: Maybe<Passenger>;
  paymentReminderNotificationLogs?: Maybe<
    Array<PaymentReminderNotificationLog>
  >;
  phone?: Maybe<Scalars["String"]["output"]>;
  photo?: Maybe<Scalars["String"]["output"]>;
  primaryLanguage?: Maybe<Language>;
  roles?: Maybe<Array<UserRole>>;
  schoolRegistrationAttempts?: Maybe<Array<SchoolRegistrationAttempt>>;
  serviceProviders?: Maybe<Array<ServiceProviderUser>>;
  sponsor?: Maybe<Sponsor>;
  sponsorLeaveRequests?: Maybe<Array<LeaveRequest>>;
  sponsorModifiedLeaveRequests?: Maybe<Array<LeaveRequest>>;
  travelTags?: Maybe<Array<UserTravelTag>>;
  tripLogs?: Maybe<Array<TripLog>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ValhallaLeg = {
  __typename?: "ValhallaLeg";
  polyline: Array<LatLon>;
  shape: Scalars["String"]["output"];
  summary: ValhallaSummary;
};

export type ValhallaLocation = {
  __typename?: "ValhallaLocation";
  lat: Scalars["Float"]["output"];
  lon: Scalars["Float"]["output"];
  original_index?: Maybe<Scalars["Int"]["output"]>;
  side_of_street?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type ValhallaRouteResponse = {
  __typename?: "ValhallaRouteResponse";
  legs: Array<ValhallaLeg>;
  locations: Array<ValhallaLocation>;
  polyline: Array<LatLon>;
  summary: ValhallaSummary;
  units: ValhallaUnits;
};

export type ValhallaSummary = {
  __typename?: "ValhallaSummary";
  cost: Scalars["Float"]["output"];
  length: Scalars["Float"]["output"];
  time: Scalars["Float"]["output"];
};

/** Valhalla units */
export enum ValhallaUnits {
  KILOMETERS = "kilometers",
  MILES = "miles",
}

export type ValidationResponse = {
  __typename?: "ValidationResponse";
  errCode?: Maybe<Scalars["String"]["output"]>;
  field?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  statusCode?: Maybe<Scalars["Float"]["output"]>;
};

export type Vehicle = {
  __typename?: "Vehicle";
  _count: VehicleCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  capacity: Scalars["Int"]["output"];
  chassisNumber?: Maybe<Scalars["String"]["output"]>;
  code: Scalars["String"]["output"];
  color?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  drivers?: Maybe<Array<VehicleDriver>>;
  id: Scalars["String"]["output"];
  initOdometer?: Maybe<Scalars["Int"]["output"]>;
  licensePlate?: Maybe<Scalars["String"]["output"]>;
  make?: Maybe<Scalars["String"]["output"]>;
  maxSpeed?: Maybe<Scalars["Int"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  newVehicleTripLogs?: Maybe<Array<TripLog>>;
  oldVehicleTripLogs?: Maybe<Array<TripLog>>;
  packageNumber?: Maybe<Scalars["Int"]["output"]>;
  routeVehicleVacantAlerts?: Maybe<Array<RouteVehicleVacantAlert>>;
  routes?: Maybe<Array<RouteVehicle>>;
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars["String"]["output"];
  status: VehicleStatus;
  submittedAt?: Maybe<Scalars["DateTime"]["output"]>;
  travelTagDetections?: Maybe<Array<TravelTagDetection>>;
  tripBusOverLoadedAlerts?: Maybe<Array<TripBusOverLoadedAlert>>;
  trips?: Maybe<Array<Trip>>;
  updatedAt: Scalars["DateTime"]["output"];
  vehicleEvents?: Maybe<Array<VehicleEvent>>;
  vehicleGroup?: Maybe<VehicleGroup>;
  vehicleGroupId?: Maybe<Scalars["String"]["output"]>;
  vehicleMake?: Maybe<VehicleMake>;
  vehicleMakeId?: Maybe<Scalars["String"]["output"]>;
  vehicleModel?: Maybe<VehicleModel>;
  vehicleModelId?: Maybe<Scalars["String"]["output"]>;
  vehicleSafetyEvents?: Maybe<Array<VehicleSafetyEvent>>;
  vehicleType?: Maybe<VehicleType>;
  vehicleTypeId?: Maybe<Scalars["String"]["output"]>;
  /** ////////////////////// */
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type VehicleCount = {
  __typename?: "VehicleCount";
  drivers: Scalars["Int"]["output"];
  newVehicleTripLogs: Scalars["Int"]["output"];
  oldVehicleTripLogs: Scalars["Int"]["output"];
  routeVehicleVacantAlerts: Scalars["Int"]["output"];
  routes: Scalars["Int"]["output"];
  travelTagDetections: Scalars["Int"]["output"];
  tripBusOverLoadedAlerts: Scalars["Int"]["output"];
  trips: Scalars["Int"]["output"];
  vehicleEvents: Scalars["Int"]["output"];
  vehicleSafetyEvents: Scalars["Int"]["output"];
};

export type VehicleCreateInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateManyCountryInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleMakeId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleModelId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleTypeId?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateManyCountryInputEnvelope = {
  data: Array<VehicleCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleCreateManyServiceProviderInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleMakeId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleModelId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleTypeId?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateManyServiceProviderInputEnvelope = {
  data: Array<VehicleCreateManyServiceProviderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleCreateManyVehicleGroupInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleMakeId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleModelId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleTypeId?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateManyVehicleGroupInputEnvelope = {
  data: Array<VehicleCreateManyVehicleGroupInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleCreateManyVehicleMakeInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleModelId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleTypeId?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateManyVehicleMakeInputEnvelope = {
  data: Array<VehicleCreateManyVehicleMakeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleCreateManyVehicleModelInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleMakeId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleTypeId?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateManyVehicleModelInputEnvelope = {
  data: Array<VehicleCreateManyVehicleModelInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleCreateManyVehicleTypeInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  countryId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  serviceProviderId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleGroupId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleMakeId?: InputMaybe<Scalars["String"]["input"]>;
  vehicleModelId?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateManyVehicleTypeInputEnvelope = {
  data: Array<VehicleCreateManyVehicleTypeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutCountryInput>>;
  createMany?: InputMaybe<VehicleCreateManyCountryInputEnvelope>;
};

export type VehicleCreateNestedManyWithoutServiceProviderInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<VehicleCreateManyServiceProviderInputEnvelope>;
};

export type VehicleCreateNestedManyWithoutVehicleGroupInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutVehicleGroupInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutVehicleGroupInput>>;
  createMany?: InputMaybe<VehicleCreateManyVehicleGroupInputEnvelope>;
};

export type VehicleCreateNestedManyWithoutVehicleMakeInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutVehicleMakeInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutVehicleMakeInput>>;
  createMany?: InputMaybe<VehicleCreateManyVehicleMakeInputEnvelope>;
};

export type VehicleCreateNestedManyWithoutVehicleModelInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutVehicleModelInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutVehicleModelInput>>;
  createMany?: InputMaybe<VehicleCreateManyVehicleModelInputEnvelope>;
};

export type VehicleCreateNestedManyWithoutVehicleTypeInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutVehicleTypeInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutVehicleTypeInput>>;
  createMany?: InputMaybe<VehicleCreateManyVehicleTypeInputEnvelope>;
};

export type VehicleCreateNestedOneWithoutDriversInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutDriversInput>;
  create?: InputMaybe<VehicleCreateWithoutDriversInput>;
};

export type VehicleCreateNestedOneWithoutNewVehicleTripLogsInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutNewVehicleTripLogsInput>;
  create?: InputMaybe<VehicleCreateWithoutNewVehicleTripLogsInput>;
};

export type VehicleCreateNestedOneWithoutOldVehicleTripLogsInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutOldVehicleTripLogsInput>;
  create?: InputMaybe<VehicleCreateWithoutOldVehicleTripLogsInput>;
};

export type VehicleCreateNestedOneWithoutRouteVehicleVacantAlertsInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutRouteVehicleVacantAlertsInput>;
  create?: InputMaybe<VehicleCreateWithoutRouteVehicleVacantAlertsInput>;
};

export type VehicleCreateNestedOneWithoutRoutesInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<VehicleCreateWithoutRoutesInput>;
};

export type VehicleCreateNestedOneWithoutTravelTagDetectionsInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutTravelTagDetectionsInput>;
  create?: InputMaybe<VehicleCreateWithoutTravelTagDetectionsInput>;
};

export type VehicleCreateNestedOneWithoutTripBusOverLoadedAlertsInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutTripBusOverLoadedAlertsInput>;
  create?: InputMaybe<VehicleCreateWithoutTripBusOverLoadedAlertsInput>;
};

export type VehicleCreateNestedOneWithoutTripsInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutTripsInput>;
  create?: InputMaybe<VehicleCreateWithoutTripsInput>;
};

export type VehicleCreateNestedOneWithoutVehicleSafetyEventsInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutVehicleSafetyEventsInput>;
  create?: InputMaybe<VehicleCreateWithoutVehicleSafetyEventsInput>;
};

export type VehicleCreateOrConnectWithoutCountryInput = {
  create: VehicleCreateWithoutCountryInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutDriversInput = {
  create: VehicleCreateWithoutDriversInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutNewVehicleTripLogsInput = {
  create: VehicleCreateWithoutNewVehicleTripLogsInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutOldVehicleTripLogsInput = {
  create: VehicleCreateWithoutOldVehicleTripLogsInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutRouteVehicleVacantAlertsInput = {
  create: VehicleCreateWithoutRouteVehicleVacantAlertsInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutRoutesInput = {
  create: VehicleCreateWithoutRoutesInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutServiceProviderInput = {
  create: VehicleCreateWithoutServiceProviderInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutTravelTagDetectionsInput = {
  create: VehicleCreateWithoutTravelTagDetectionsInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutTripBusOverLoadedAlertsInput = {
  create: VehicleCreateWithoutTripBusOverLoadedAlertsInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutTripsInput = {
  create: VehicleCreateWithoutTripsInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutVehicleGroupInput = {
  create: VehicleCreateWithoutVehicleGroupInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutVehicleMakeInput = {
  create: VehicleCreateWithoutVehicleMakeInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutVehicleModelInput = {
  create: VehicleCreateWithoutVehicleModelInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutVehicleSafetyEventsInput = {
  create: VehicleCreateWithoutVehicleSafetyEventsInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateOrConnectWithoutVehicleTypeInput = {
  create: VehicleCreateWithoutVehicleTypeInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleCreateWithoutCountryInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutDriversInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutNewVehicleTripLogsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutOldVehicleTripLogsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutRouteVehicleVacantAlertsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutRoutesInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutServiceProviderInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutTravelTagDetectionsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutTripBusOverLoadedAlertsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutTripsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutVehicleGroupInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutVehicleMakeInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutVehicleModelInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutVehicleSafetyEventsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateNestedOneWithoutVehiclesInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleCreateWithoutVehicleTypeInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryCreateNestedOneWithoutVehicleInput>;
  drivers?: InputMaybe<VehicleDriverCreateNestedManyWithoutVehicleInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutNewVehicleInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogCreateNestedManyWithoutOldVehicleInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertCreateNestedManyWithoutVehicleInput>;
  routes?: InputMaybe<RouteVehicleCreateNestedManyWithoutVehicleInput>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehiclesInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionCreateNestedManyWithoutVehicleInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertCreateNestedManyWithoutVehicleInput>;
  trips?: InputMaybe<TripCreateNestedManyWithoutVehicleInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventCreateNestedManyWithoutVehicleInput>;
  vehicleGroup?: InputMaybe<VehicleGroupCreateNestedOneWithoutVehiclesInput>;
  vehicleMake?: InputMaybe<VehicleMakeCreateNestedOneWithoutVehiclesInput>;
  vehicleModel?: InputMaybe<VehicleModelCreateNestedOneWithoutVehiclesInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventCreateNestedManyWithoutVehicleInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleDriver = {
  __typename?: "VehicleDriver";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  driver: Driver;
  driverId: Scalars["String"]["output"];
  fromDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isActive: Scalars["Boolean"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  toDate?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  vehicle: Vehicle;
  vehicleId: Scalars["String"]["output"];
};

export type VehicleDriverCreateManyDriverInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleId: Scalars["String"]["input"];
};

export type VehicleDriverCreateManyDriverInputEnvelope = {
  data: Array<VehicleDriverCreateManyDriverInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleDriverCreateManyVehicleInput = {
  driverId: Scalars["String"]["input"];
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleDriverCreateManyVehicleInputEnvelope = {
  data: Array<VehicleDriverCreateManyVehicleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleDriverCreateNestedManyWithoutDriverInput = {
  connect?: InputMaybe<Array<VehicleDriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleDriverCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<VehicleDriverCreateWithoutDriverInput>>;
  createMany?: InputMaybe<VehicleDriverCreateManyDriverInputEnvelope>;
};

export type VehicleDriverCreateNestedManyWithoutVehicleInput = {
  connect?: InputMaybe<Array<VehicleDriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleDriverCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<VehicleDriverCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<VehicleDriverCreateManyVehicleInputEnvelope>;
};

export type VehicleDriverCreateOrConnectWithoutDriverInput = {
  create: VehicleDriverCreateWithoutDriverInput;
  where: VehicleDriverWhereUniqueInput;
};

export type VehicleDriverCreateOrConnectWithoutVehicleInput = {
  create: VehicleDriverCreateWithoutVehicleInput;
  where: VehicleDriverWhereUniqueInput;
};

export type VehicleDriverCreateWithoutDriverInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle: VehicleCreateNestedOneWithoutDriversInput;
};

export type VehicleDriverCreateWithoutVehicleInput = {
  driver: DriverCreateNestedOneWithoutVehiclesInput;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleDriverListRelationFilter = {
  every?: InputMaybe<VehicleDriverWhereInput>;
  none?: InputMaybe<VehicleDriverWhereInput>;
  some?: InputMaybe<VehicleDriverWhereInput>;
};

export type VehicleDriverScalarWhereInput = {
  AND?: InputMaybe<Array<VehicleDriverScalarWhereInput>>;
  NOT?: InputMaybe<Array<VehicleDriverScalarWhereInput>>;
  OR?: InputMaybe<Array<VehicleDriverScalarWhereInput>>;
  driverId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  notes?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type VehicleDriverUpdateManyMutationInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleDriverUpdateManyWithWhereWithoutDriverInput = {
  data: VehicleDriverUpdateManyMutationInput;
  where: VehicleDriverScalarWhereInput;
};

export type VehicleDriverUpdateManyWithWhereWithoutVehicleInput = {
  data: VehicleDriverUpdateManyMutationInput;
  where: VehicleDriverScalarWhereInput;
};

export type VehicleDriverUpdateManyWithoutDriverNestedInput = {
  connect?: InputMaybe<Array<VehicleDriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleDriverCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<VehicleDriverCreateWithoutDriverInput>>;
  createMany?: InputMaybe<VehicleDriverCreateManyDriverInputEnvelope>;
  delete?: InputMaybe<Array<VehicleDriverWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleDriverScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleDriverWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleDriverWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleDriverUpdateWithWhereUniqueWithoutDriverInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleDriverUpdateManyWithWhereWithoutDriverInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleDriverUpsertWithWhereUniqueWithoutDriverInput>
  >;
};

export type VehicleDriverUpdateManyWithoutVehicleNestedInput = {
  connect?: InputMaybe<Array<VehicleDriverWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleDriverCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<VehicleDriverCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<VehicleDriverCreateManyVehicleInputEnvelope>;
  delete?: InputMaybe<Array<VehicleDriverWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleDriverScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleDriverWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleDriverWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleDriverUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleDriverUpdateManyWithWhereWithoutVehicleInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleDriverUpsertWithWhereUniqueWithoutVehicleInput>
  >;
};

export type VehicleDriverUpdateWithWhereUniqueWithoutDriverInput = {
  data: VehicleDriverUpdateWithoutDriverInput;
  where: VehicleDriverWhereUniqueInput;
};

export type VehicleDriverUpdateWithWhereUniqueWithoutVehicleInput = {
  data: VehicleDriverUpdateWithoutVehicleInput;
  where: VehicleDriverWhereUniqueInput;
};

export type VehicleDriverUpdateWithoutDriverInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicle?: InputMaybe<VehicleUpdateOneRequiredWithoutDriversNestedInput>;
};

export type VehicleDriverUpdateWithoutVehicleInput = {
  driver?: InputMaybe<DriverUpdateOneRequiredWithoutVehiclesNestedInput>;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleDriverUpsertWithWhereUniqueWithoutDriverInput = {
  create: VehicleDriverCreateWithoutDriverInput;
  update: VehicleDriverUpdateWithoutDriverInput;
  where: VehicleDriverWhereUniqueInput;
};

export type VehicleDriverUpsertWithWhereUniqueWithoutVehicleInput = {
  create: VehicleDriverCreateWithoutVehicleInput;
  update: VehicleDriverUpdateWithoutVehicleInput;
  where: VehicleDriverWhereUniqueInput;
};

export type VehicleDriverWhereInput = {
  AND?: InputMaybe<Array<VehicleDriverWhereInput>>;
  NOT?: InputMaybe<Array<VehicleDriverWhereInput>>;
  OR?: InputMaybe<Array<VehicleDriverWhereInput>>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  notes?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type VehicleDriverWhereUniqueInput = {
  AND?: InputMaybe<Array<VehicleDriverWhereInput>>;
  NOT?: InputMaybe<Array<VehicleDriverWhereInput>>;
  OR?: InputMaybe<Array<VehicleDriverWhereInput>>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  fromDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<BoolFilter>;
  notes?: InputMaybe<StringFilter>;
  toDate?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type VehicleEvent = {
  __typename?: "VehicleEvent";
  createdAt: Scalars["DateTime"]["output"];
  data?: Maybe<Scalars["JSON"]["output"]>;
  eventAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  type: VehicleEventType;
  updatedAt: Scalars["DateTime"]["output"];
  vehicle: Vehicle;
  vehicleId: Scalars["String"]["output"];
};

export type VehicleEventCreateManyVehicleInput = {
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  eventAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<VehicleEventType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleEventCreateManyVehicleInputEnvelope = {
  data: Array<VehicleEventCreateManyVehicleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleEventCreateNestedManyWithoutVehicleInput = {
  connect?: InputMaybe<Array<VehicleEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleEventCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<VehicleEventCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<VehicleEventCreateManyVehicleInputEnvelope>;
};

export type VehicleEventCreateOrConnectWithoutVehicleInput = {
  create: VehicleEventCreateWithoutVehicleInput;
  where: VehicleEventWhereUniqueInput;
};

export type VehicleEventCreateWithoutVehicleInput = {
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  eventAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<VehicleEventType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleEventListRelationFilter = {
  every?: InputMaybe<VehicleEventWhereInput>;
  none?: InputMaybe<VehicleEventWhereInput>;
  some?: InputMaybe<VehicleEventWhereInput>;
};

export type VehicleEventScalarWhereInput = {
  AND?: InputMaybe<Array<VehicleEventScalarWhereInput>>;
  NOT?: InputMaybe<Array<VehicleEventScalarWhereInput>>;
  OR?: InputMaybe<Array<VehicleEventScalarWhereInput>>;
  data?: InputMaybe<JsonFilter>;
  eventAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumVehicleEventTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export enum VehicleEventType {
  ACCIDENT = "ACCIDENT",
  ALARM = "ALARM",
  BATTERY = "BATTERY",
  BREAKDOWN = "BREAKDOWN",
  OTHER = "OTHER",
  OVERSPEED = "OVERSPEED",
}

export type VehicleEventUpdateManyMutationInput = {
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  eventAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<VehicleEventType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleEventUpdateManyWithWhereWithoutVehicleInput = {
  data: VehicleEventUpdateManyMutationInput;
  where: VehicleEventScalarWhereInput;
};

export type VehicleEventUpdateManyWithoutVehicleNestedInput = {
  connect?: InputMaybe<Array<VehicleEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleEventCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<VehicleEventCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<VehicleEventCreateManyVehicleInputEnvelope>;
  delete?: InputMaybe<Array<VehicleEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleEventWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleEventWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleEventUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleEventUpdateManyWithWhereWithoutVehicleInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleEventUpsertWithWhereUniqueWithoutVehicleInput>
  >;
};

export type VehicleEventUpdateWithWhereUniqueWithoutVehicleInput = {
  data: VehicleEventUpdateWithoutVehicleInput;
  where: VehicleEventWhereUniqueInput;
};

export type VehicleEventUpdateWithoutVehicleInput = {
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  eventAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<VehicleEventType>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleEventUpsertWithWhereUniqueWithoutVehicleInput = {
  create: VehicleEventCreateWithoutVehicleInput;
  update: VehicleEventUpdateWithoutVehicleInput;
  where: VehicleEventWhereUniqueInput;
};

export type VehicleEventWhereInput = {
  AND?: InputMaybe<Array<VehicleEventWhereInput>>;
  NOT?: InputMaybe<Array<VehicleEventWhereInput>>;
  OR?: InputMaybe<Array<VehicleEventWhereInput>>;
  data?: InputMaybe<JsonFilter>;
  eventAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumVehicleEventTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type VehicleEventWhereUniqueInput = {
  AND?: InputMaybe<Array<VehicleEventWhereInput>>;
  NOT?: InputMaybe<Array<VehicleEventWhereInput>>;
  OR?: InputMaybe<Array<VehicleEventWhereInput>>;
  data?: InputMaybe<JsonFilter>;
  eventAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumVehicleEventTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type VehicleForBaBd = {
  __typename?: "VehicleForBaBd";
  capacity: Scalars["Int"]["output"];
  code: Scalars["String"]["output"];
  color?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  licensePlate?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  vehicleMake?: Maybe<VehicleMake>;
  vehicleModel?: Maybe<VehicleModel>;
  vehicleType?: Maybe<VehicleType>;
  /** ////////////////////// */
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type VehicleForBusAttendant = {
  __typename?: "VehicleForBusAttendant";
  code: Scalars["String"]["output"];
  color?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  licensePlate?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  packageNumber?: Maybe<Scalars["Int"]["output"]>;
  status: VehicleStatus;
  vehicleGroup?: Maybe<VehicleGroup>;
  vehicleMake?: Maybe<VehicleMake>;
  vehicleModel?: Maybe<VehicleModel>;
  vehicleType?: Maybe<VehicleType>;
  /** ////////////////////// */
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type VehicleForDriver = {
  __typename?: "VehicleForDriver";
  code: Scalars["String"]["output"];
  color?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  licensePlate?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  packageNumber?: Maybe<Scalars["Int"]["output"]>;
  status: VehicleStatus;
  vehicleGroup?: Maybe<VehicleGroup>;
  vehicleMake?: Maybe<VehicleMake>;
  vehicleModel?: Maybe<VehicleModel>;
  vehicleType?: Maybe<VehicleType>;
  /** ////////////////////// */
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type VehicleForSponsor = {
  __typename?: "VehicleForSponsor";
  code: Scalars["String"]["output"];
  color?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  licensePlate?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  packageNumber?: Maybe<Scalars["Int"]["output"]>;
  status: VehicleStatus;
  vehicleGroup?: Maybe<VehicleGroup>;
  vehicleMake?: Maybe<VehicleMake>;
  vehicleModel?: Maybe<VehicleModel>;
  vehicleType?: Maybe<VehicleType>;
  /** ////////////////////// */
  year?: Maybe<Scalars["Int"]["output"]>;
};

export enum VehicleGpsStatus {
  OFF = "OFF",
  ON = "ON",
  UNKNOWN = "UNKNOWN",
}

export type VehicleGroup = {
  __typename?: "VehicleGroup";
  _count: VehicleGroupCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr?: Maybe<Scalars["String"]["output"]>;
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  vehicles?: Maybe<Array<Vehicle>>;
};

export type VehicleGroupCount = {
  __typename?: "VehicleGroupCount";
  vehicles: Scalars["Int"]["output"];
};

export type VehicleGroupCreateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehicleGroupsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutVehicleGroupInput>;
};

export type VehicleGroupCreateManyServiceProviderInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleGroupCreateManyServiceProviderInputEnvelope = {
  data: Array<VehicleGroupCreateManyServiceProviderInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleGroupCreateNestedManyWithoutServiceProviderInput = {
  connect?: InputMaybe<Array<VehicleGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleGroupCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<VehicleGroupCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<VehicleGroupCreateManyServiceProviderInputEnvelope>;
};

export type VehicleGroupCreateNestedOneWithoutVehiclesInput = {
  connect?: InputMaybe<VehicleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleGroupCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<VehicleGroupCreateWithoutVehiclesInput>;
};

export type VehicleGroupCreateOrConnectWithoutServiceProviderInput = {
  create: VehicleGroupCreateWithoutServiceProviderInput;
  where: VehicleGroupWhereUniqueInput;
};

export type VehicleGroupCreateOrConnectWithoutVehiclesInput = {
  create: VehicleGroupCreateWithoutVehiclesInput;
  where: VehicleGroupWhereUniqueInput;
};

export type VehicleGroupCreateWithoutServiceProviderInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutVehicleGroupInput>;
};

export type VehicleGroupCreateWithoutVehiclesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderCreateNestedOneWithoutVehicleGroupsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleGroupListRelationFilter = {
  every?: InputMaybe<VehicleGroupWhereInput>;
  none?: InputMaybe<VehicleGroupWhereInput>;
  some?: InputMaybe<VehicleGroupWhereInput>;
};

export type VehicleGroupNameServiceProviderIdCompoundUniqueInput = {
  name: Scalars["String"]["input"];
  serviceProviderId: Scalars["String"]["input"];
};

export type VehicleGroupRelationFilter = {
  is?: InputMaybe<VehicleGroupWhereInput>;
  isNot?: InputMaybe<VehicleGroupWhereInput>;
};

export type VehicleGroupScalarWhereInput = {
  AND?: InputMaybe<Array<VehicleGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<VehicleGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<VehicleGroupScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type VehicleGroupUpdateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehicleGroupsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutVehicleGroupNestedInput>;
};

export type VehicleGroupUpdateManyMutationInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleGroupUpdateManyWithWhereWithoutServiceProviderInput = {
  data: VehicleGroupUpdateManyMutationInput;
  where: VehicleGroupScalarWhereInput;
};

export type VehicleGroupUpdateManyWithoutServiceProviderNestedInput = {
  connect?: InputMaybe<Array<VehicleGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleGroupCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<VehicleGroupCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<VehicleGroupCreateManyServiceProviderInputEnvelope>;
  delete?: InputMaybe<Array<VehicleGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleGroupWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleGroupUpdateWithWhereUniqueWithoutServiceProviderInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleGroupUpdateManyWithWhereWithoutServiceProviderInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleGroupUpsertWithWhereUniqueWithoutServiceProviderInput>
  >;
};

export type VehicleGroupUpdateOneWithoutVehiclesNestedInput = {
  connect?: InputMaybe<VehicleGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleGroupCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<VehicleGroupCreateWithoutVehiclesInput>;
  delete?: InputMaybe<VehicleGroupWhereInput>;
  disconnect?: InputMaybe<VehicleGroupWhereInput>;
  update?: InputMaybe<VehicleGroupUpdateToOneWithWhereWithoutVehiclesInput>;
  upsert?: InputMaybe<VehicleGroupUpsertWithoutVehiclesInput>;
};

export type VehicleGroupUpdateToOneWithWhereWithoutVehiclesInput = {
  data: VehicleGroupUpdateWithoutVehiclesInput;
  where?: InputMaybe<VehicleGroupWhereInput>;
};

export type VehicleGroupUpdateWithWhereUniqueWithoutServiceProviderInput = {
  data: VehicleGroupUpdateWithoutServiceProviderInput;
  where: VehicleGroupWhereUniqueInput;
};

export type VehicleGroupUpdateWithoutServiceProviderInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutVehicleGroupNestedInput>;
};

export type VehicleGroupUpdateWithoutVehiclesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehicleGroupsNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleGroupUpsertInput = {
  create?: InputMaybe<VehicleGroupCreateInput>;
  update?: InputMaybe<VehicleGroupUpdateInput>;
  where?: InputMaybe<VehicleGroupWhereUniqueInput>;
};

export type VehicleGroupUpsertWithWhereUniqueWithoutServiceProviderInput = {
  create: VehicleGroupCreateWithoutServiceProviderInput;
  update: VehicleGroupUpdateWithoutServiceProviderInput;
  where: VehicleGroupWhereUniqueInput;
};

export type VehicleGroupUpsertWithoutVehiclesInput = {
  create: VehicleGroupCreateWithoutVehiclesInput;
  update: VehicleGroupUpdateWithoutVehiclesInput;
  where?: InputMaybe<VehicleGroupWhereInput>;
};

export type VehicleGroupWhereInput = {
  AND?: InputMaybe<Array<VehicleGroupWhereInput>>;
  NOT?: InputMaybe<Array<VehicleGroupWhereInput>>;
  OR?: InputMaybe<Array<VehicleGroupWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicles?: InputMaybe<VehicleListRelationFilter>;
};

export type VehicleGroupWhereUniqueInput = {
  AND?: InputMaybe<Array<VehicleGroupWhereInput>>;
  NOT?: InputMaybe<Array<VehicleGroupWhereInput>>;
  OR?: InputMaybe<Array<VehicleGroupWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  name_serviceProviderId?: InputMaybe<VehicleGroupNameServiceProviderIdCompoundUniqueInput>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicles?: InputMaybe<VehicleListRelationFilter>;
};

export enum VehicleIgnitionStatus {
  OFF = "OFF",
  ON = "ON",
  UNKNOWN = "UNKNOWN",
}

export type VehicleListRelationFilter = {
  every?: InputMaybe<VehicleWhereInput>;
  none?: InputMaybe<VehicleWhereInput>;
  some?: InputMaybe<VehicleWhereInput>;
};

export type VehicleMake = {
  __typename?: "VehicleMake";
  _count: VehicleMakeCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  vehicles?: Maybe<Array<Vehicle>>;
};

export type VehicleMakeCount = {
  __typename?: "VehicleMakeCount";
  vehicles: Scalars["Int"]["output"];
};

export type VehicleMakeCreateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutVehicleMakeInput>;
};

export type VehicleMakeCreateNestedOneWithoutVehiclesInput = {
  connect?: InputMaybe<VehicleMakeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleMakeCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<VehicleMakeCreateWithoutVehiclesInput>;
};

export type VehicleMakeCreateOrConnectWithoutVehiclesInput = {
  create: VehicleMakeCreateWithoutVehiclesInput;
  where: VehicleMakeWhereUniqueInput;
};

export type VehicleMakeCreateWithoutVehiclesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleMakeRelationFilter = {
  is?: InputMaybe<VehicleMakeWhereInput>;
  isNot?: InputMaybe<VehicleMakeWhereInput>;
};

export type VehicleMakeUpdateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutVehicleMakeNestedInput>;
};

export type VehicleMakeUpdateOneWithoutVehiclesNestedInput = {
  connect?: InputMaybe<VehicleMakeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleMakeCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<VehicleMakeCreateWithoutVehiclesInput>;
  delete?: InputMaybe<VehicleMakeWhereInput>;
  disconnect?: InputMaybe<VehicleMakeWhereInput>;
  update?: InputMaybe<VehicleMakeUpdateToOneWithWhereWithoutVehiclesInput>;
  upsert?: InputMaybe<VehicleMakeUpsertWithoutVehiclesInput>;
};

export type VehicleMakeUpdateToOneWithWhereWithoutVehiclesInput = {
  data: VehicleMakeUpdateWithoutVehiclesInput;
  where?: InputMaybe<VehicleMakeWhereInput>;
};

export type VehicleMakeUpdateWithoutVehiclesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleMakeUpsertInput = {
  create?: InputMaybe<VehicleMakeCreateInput>;
  update?: InputMaybe<VehicleMakeUpdateInput>;
  where?: InputMaybe<VehicleMakeWhereUniqueInput>;
};

export type VehicleMakeUpsertWithoutVehiclesInput = {
  create: VehicleMakeCreateWithoutVehiclesInput;
  update: VehicleMakeUpdateWithoutVehiclesInput;
  where?: InputMaybe<VehicleMakeWhereInput>;
};

export type VehicleMakeWhereInput = {
  AND?: InputMaybe<Array<VehicleMakeWhereInput>>;
  NOT?: InputMaybe<Array<VehicleMakeWhereInput>>;
  OR?: InputMaybe<Array<VehicleMakeWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicles?: InputMaybe<VehicleListRelationFilter>;
};

export type VehicleMakeWhereUniqueInput = {
  AND?: InputMaybe<Array<VehicleMakeWhereInput>>;
  NOT?: InputMaybe<Array<VehicleMakeWhereInput>>;
  OR?: InputMaybe<Array<VehicleMakeWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicles?: InputMaybe<VehicleListRelationFilter>;
};

export type VehicleModel = {
  __typename?: "VehicleModel";
  _count: VehicleModelCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  vehicles?: Maybe<Array<Vehicle>>;
};

export type VehicleModelCount = {
  __typename?: "VehicleModelCount";
  vehicles: Scalars["Int"]["output"];
};

export type VehicleModelCreateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutVehicleModelInput>;
};

export type VehicleModelCreateNestedOneWithoutVehiclesInput = {
  connect?: InputMaybe<VehicleModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleModelCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<VehicleModelCreateWithoutVehiclesInput>;
};

export type VehicleModelCreateOrConnectWithoutVehiclesInput = {
  create: VehicleModelCreateWithoutVehiclesInput;
  where: VehicleModelWhereUniqueInput;
};

export type VehicleModelCreateWithoutVehiclesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleModelRelationFilter = {
  is?: InputMaybe<VehicleModelWhereInput>;
  isNot?: InputMaybe<VehicleModelWhereInput>;
};

export type VehicleModelUpdateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutVehicleModelNestedInput>;
};

export type VehicleModelUpdateOneWithoutVehiclesNestedInput = {
  connect?: InputMaybe<VehicleModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleModelCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<VehicleModelCreateWithoutVehiclesInput>;
  delete?: InputMaybe<VehicleModelWhereInput>;
  disconnect?: InputMaybe<VehicleModelWhereInput>;
  update?: InputMaybe<VehicleModelUpdateToOneWithWhereWithoutVehiclesInput>;
  upsert?: InputMaybe<VehicleModelUpsertWithoutVehiclesInput>;
};

export type VehicleModelUpdateToOneWithWhereWithoutVehiclesInput = {
  data: VehicleModelUpdateWithoutVehiclesInput;
  where?: InputMaybe<VehicleModelWhereInput>;
};

export type VehicleModelUpdateWithoutVehiclesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleModelUpsertInput = {
  create?: InputMaybe<VehicleModelCreateInput>;
  update?: InputMaybe<VehicleModelUpdateInput>;
  where?: InputMaybe<VehicleModelWhereUniqueInput>;
};

export type VehicleModelUpsertWithoutVehiclesInput = {
  create: VehicleModelCreateWithoutVehiclesInput;
  update: VehicleModelUpdateWithoutVehiclesInput;
  where?: InputMaybe<VehicleModelWhereInput>;
};

export type VehicleModelWhereInput = {
  AND?: InputMaybe<Array<VehicleModelWhereInput>>;
  NOT?: InputMaybe<Array<VehicleModelWhereInput>>;
  OR?: InputMaybe<Array<VehicleModelWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicles?: InputMaybe<VehicleListRelationFilter>;
};

export type VehicleModelWhereUniqueInput = {
  AND?: InputMaybe<Array<VehicleModelWhereInput>>;
  NOT?: InputMaybe<Array<VehicleModelWhereInput>>;
  OR?: InputMaybe<Array<VehicleModelWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicles?: InputMaybe<VehicleListRelationFilter>;
};

export enum VehicleOperationalStatus {
  INACTIVE = "INACTIVE",
  OPERATIONAL = "OPERATIONAL",
  PENDING_DRIVER_ASSIGNMENT = "PENDING_DRIVER_ASSIGNMENT",
  PENDING_ROUTE_ASSIGNMENT = "PENDING_ROUTE_ASSIGNMENT",
  POOL = "POOL",
}

export type VehicleRelationFilter = {
  is?: InputMaybe<VehicleWhereInput>;
  isNot?: InputMaybe<VehicleWhereInput>;
};

export type VehicleSafetyEvent = {
  __typename?: "VehicleSafetyEvent";
  agentComment?: Maybe<Scalars["String"]["output"]>;
  alert?: Maybe<Alert>;
  alertId?: Maybe<Scalars["String"]["output"]>;
  cause: VehicleSafetyEventCause;
  createdAt: Scalars["DateTime"]["output"];
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isFlagged: Scalars["Boolean"]["output"];
  lat?: Maybe<Scalars["Float"]["output"]>;
  level: VehicleSafetyEventLevel;
  locationPrecision?: Maybe<Scalars["Float"]["output"]>;
  lon?: Maybe<Scalars["Float"]["output"]>;
  packageNumber?: Maybe<Scalars["Int"]["output"]>;
  priority: VehicleSafetyEventPriority;
  timestamp?: Maybe<Scalars["DateTime"]["output"]>;
  trip?: Maybe<Trip>;
  tripId?: Maybe<Scalars["String"]["output"]>;
  type: VehicleSafetyEventType;
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars["String"]["output"]>;
};

export enum VehicleSafetyEventCause {
  BAD_GPS = "BAD_GPS",
  CARD = "CARD",
  CH_OFFLINE = "CH_OFFLINE",
  COLLISION = "COLLISION",
  COMMAND = "COMMAND",
  DEVICE_OFFLINE = "DEVICE_OFFLINE",
  HIGH_HUMIDITY = "HIGH_HUMIDITY",
  HIGH_TEMPERATURE = "HIGH_TEMPERATURE",
  IGNITION = "IGNITION",
  INVALID = "INVALID",
  LOW_SPEED = "LOW_SPEED",
  LOW_VOLTAGE = "LOW_VOLTAGE",
  MOTION_MAJOR = "MOTION_MAJOR",
  MOTION_MINOR = "MOTION_MINOR",
  NO_CHECK = "NO_CHECK",
  NO_DISK = "NO_DISK",
  OVER_SPEED = "OVER_SPEED",
  PANICBUTTON = "PANICBUTTON",
  PUSHBUTTON = "PUSHBUTTON",
  RAPID_SPEEDDOWN = "RAPID_SPEEDDOWN",
  RAPID_SPEEDUP = "RAPID_SPEEDUP",
  RAPID_TURN = "RAPID_TURN",
  SMOKE = "SMOKE",
  TIMEOUT = "TIMEOUT",
  TURNOVER = "TURNOVER",
  VIDEO_LOSS = "VIDEO_LOSS",
  VIDEO_TAMPERING = "VIDEO_TAMPERING",
}

export type VehicleSafetyEventCreateInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  alert?: InputMaybe<AlertCreateNestedOneWithoutVehicleSafetyEventInput>;
  cause: VehicleSafetyEventCause;
  driver?: InputMaybe<DriverCreateNestedOneWithoutVehicleSafetyEventsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level: VehicleSafetyEventLevel;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  trip?: InputMaybe<TripCreateNestedOneWithoutVehicleSafetyEventsInput>;
  type: VehicleSafetyEventType;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutVehicleSafetyEventsInput>;
};

export type VehicleSafetyEventCreateManyDriverInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  cause: VehicleSafetyEventCause;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level: VehicleSafetyEventLevel;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
  type: VehicleSafetyEventType;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type VehicleSafetyEventCreateManyDriverInputEnvelope = {
  data: Array<VehicleSafetyEventCreateManyDriverInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleSafetyEventCreateManyTripInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  cause: VehicleSafetyEventCause;
  driverId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level: VehicleSafetyEventLevel;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  type: VehicleSafetyEventType;
  vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type VehicleSafetyEventCreateManyTripInputEnvelope = {
  data: Array<VehicleSafetyEventCreateManyTripInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleSafetyEventCreateManyVehicleInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  cause: VehicleSafetyEventCause;
  driverId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level: VehicleSafetyEventLevel;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
  type: VehicleSafetyEventType;
};

export type VehicleSafetyEventCreateManyVehicleInputEnvelope = {
  data: Array<VehicleSafetyEventCreateManyVehicleInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VehicleSafetyEventCreateNestedManyWithoutDriverInput = {
  connect?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleSafetyEventCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<VehicleSafetyEventCreateWithoutDriverInput>>;
  createMany?: InputMaybe<VehicleSafetyEventCreateManyDriverInputEnvelope>;
};

export type VehicleSafetyEventCreateNestedManyWithoutTripInput = {
  connect?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleSafetyEventCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<VehicleSafetyEventCreateWithoutTripInput>>;
  createMany?: InputMaybe<VehicleSafetyEventCreateManyTripInputEnvelope>;
};

export type VehicleSafetyEventCreateNestedManyWithoutVehicleInput = {
  connect?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleSafetyEventCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<VehicleSafetyEventCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<VehicleSafetyEventCreateManyVehicleInputEnvelope>;
};

export type VehicleSafetyEventCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<VehicleSafetyEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleSafetyEventCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<VehicleSafetyEventCreateWithoutAlertInput>;
};

export type VehicleSafetyEventCreateOrConnectWithoutAlertInput = {
  create: VehicleSafetyEventCreateWithoutAlertInput;
  where: VehicleSafetyEventWhereUniqueInput;
};

export type VehicleSafetyEventCreateOrConnectWithoutDriverInput = {
  create: VehicleSafetyEventCreateWithoutDriverInput;
  where: VehicleSafetyEventWhereUniqueInput;
};

export type VehicleSafetyEventCreateOrConnectWithoutTripInput = {
  create: VehicleSafetyEventCreateWithoutTripInput;
  where: VehicleSafetyEventWhereUniqueInput;
};

export type VehicleSafetyEventCreateOrConnectWithoutVehicleInput = {
  create: VehicleSafetyEventCreateWithoutVehicleInput;
  where: VehicleSafetyEventWhereUniqueInput;
};

export type VehicleSafetyEventCreateWithoutAlertInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  cause: VehicleSafetyEventCause;
  driver?: InputMaybe<DriverCreateNestedOneWithoutVehicleSafetyEventsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level: VehicleSafetyEventLevel;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  trip?: InputMaybe<TripCreateNestedOneWithoutVehicleSafetyEventsInput>;
  type: VehicleSafetyEventType;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutVehicleSafetyEventsInput>;
};

export type VehicleSafetyEventCreateWithoutDriverInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  alert?: InputMaybe<AlertCreateNestedOneWithoutVehicleSafetyEventInput>;
  cause: VehicleSafetyEventCause;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level: VehicleSafetyEventLevel;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  trip?: InputMaybe<TripCreateNestedOneWithoutVehicleSafetyEventsInput>;
  type: VehicleSafetyEventType;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutVehicleSafetyEventsInput>;
};

export type VehicleSafetyEventCreateWithoutTripInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  alert?: InputMaybe<AlertCreateNestedOneWithoutVehicleSafetyEventInput>;
  cause: VehicleSafetyEventCause;
  driver?: InputMaybe<DriverCreateNestedOneWithoutVehicleSafetyEventsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level: VehicleSafetyEventLevel;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  type: VehicleSafetyEventType;
  vehicle?: InputMaybe<VehicleCreateNestedOneWithoutVehicleSafetyEventsInput>;
};

export type VehicleSafetyEventCreateWithoutVehicleInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  alert?: InputMaybe<AlertCreateNestedOneWithoutVehicleSafetyEventInput>;
  cause: VehicleSafetyEventCause;
  driver?: InputMaybe<DriverCreateNestedOneWithoutVehicleSafetyEventsInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level: VehicleSafetyEventLevel;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  trip?: InputMaybe<TripCreateNestedOneWithoutVehicleSafetyEventsInput>;
  type: VehicleSafetyEventType;
};

export enum VehicleSafetyEventLevel {
  INVALID = "INVALID",
  MAJOR = "MAJOR",
  MINOR = "MINOR",
  NONE = "NONE",
}

export type VehicleSafetyEventListRelationFilter = {
  every?: InputMaybe<VehicleSafetyEventWhereInput>;
  none?: InputMaybe<VehicleSafetyEventWhereInput>;
  some?: InputMaybe<VehicleSafetyEventWhereInput>;
};

export enum VehicleSafetyEventPriority {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  UNASSIGNED = "UNASSIGNED",
}

export type VehicleSafetyEventRelationFilter = {
  is?: InputMaybe<VehicleSafetyEventWhereInput>;
  isNot?: InputMaybe<VehicleSafetyEventWhereInput>;
};

export type VehicleSafetyEventScalarWhereInput = {
  AND?: InputMaybe<Array<VehicleSafetyEventScalarWhereInput>>;
  NOT?: InputMaybe<Array<VehicleSafetyEventScalarWhereInput>>;
  OR?: InputMaybe<Array<VehicleSafetyEventScalarWhereInput>>;
  agentComment?: InputMaybe<StringFilter>;
  alertId?: InputMaybe<StringFilter>;
  cause?: InputMaybe<EnumVehicleSafetyEventCauseFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isFlagged?: InputMaybe<BoolFilter>;
  lat?: InputMaybe<FloatFilter>;
  level?: InputMaybe<EnumVehicleSafetyEventLevelFilter>;
  locationPrecision?: InputMaybe<FloatFilter>;
  lon?: InputMaybe<FloatFilter>;
  packageNumber?: InputMaybe<IntFilter>;
  priority?: InputMaybe<EnumVehicleSafetyEventPriorityFilter>;
  timestamp?: InputMaybe<DateTimeFilter>;
  tripId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumVehicleSafetyEventTypeFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export enum VehicleSafetyEventType {
  ALERT = "ALERT",
  CHILL = "CHILL",
  INVALID = "INVALID",
}

export type VehicleSafetyEventUpdateInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  alert?: InputMaybe<AlertUpdateOneWithoutVehicleSafetyEventNestedInput>;
  cause?: InputMaybe<VehicleSafetyEventCause>;
  driver?: InputMaybe<DriverUpdateOneWithoutVehicleSafetyEventsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level?: InputMaybe<VehicleSafetyEventLevel>;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  trip?: InputMaybe<TripUpdateOneWithoutVehicleSafetyEventsNestedInput>;
  type?: InputMaybe<VehicleSafetyEventType>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutVehicleSafetyEventsNestedInput>;
};

export type VehicleSafetyEventUpdateManyMutationInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  cause?: InputMaybe<VehicleSafetyEventCause>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level?: InputMaybe<VehicleSafetyEventLevel>;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<VehicleSafetyEventType>;
};

export type VehicleSafetyEventUpdateManyWithWhereWithoutDriverInput = {
  data: VehicleSafetyEventUpdateManyMutationInput;
  where: VehicleSafetyEventScalarWhereInput;
};

export type VehicleSafetyEventUpdateManyWithWhereWithoutTripInput = {
  data: VehicleSafetyEventUpdateManyMutationInput;
  where: VehicleSafetyEventScalarWhereInput;
};

export type VehicleSafetyEventUpdateManyWithWhereWithoutVehicleInput = {
  data: VehicleSafetyEventUpdateManyMutationInput;
  where: VehicleSafetyEventScalarWhereInput;
};

export type VehicleSafetyEventUpdateManyWithoutDriverNestedInput = {
  connect?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleSafetyEventCreateOrConnectWithoutDriverInput>
  >;
  create?: InputMaybe<Array<VehicleSafetyEventCreateWithoutDriverInput>>;
  createMany?: InputMaybe<VehicleSafetyEventCreateManyDriverInputEnvelope>;
  delete?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleSafetyEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleSafetyEventUpdateWithWhereUniqueWithoutDriverInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleSafetyEventUpdateManyWithWhereWithoutDriverInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleSafetyEventUpsertWithWhereUniqueWithoutDriverInput>
  >;
};

export type VehicleSafetyEventUpdateManyWithoutTripNestedInput = {
  connect?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleSafetyEventCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<VehicleSafetyEventCreateWithoutTripInput>>;
  createMany?: InputMaybe<VehicleSafetyEventCreateManyTripInputEnvelope>;
  delete?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleSafetyEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleSafetyEventUpdateWithWhereUniqueWithoutTripInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleSafetyEventUpdateManyWithWhereWithoutTripInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleSafetyEventUpsertWithWhereUniqueWithoutTripInput>
  >;
};

export type VehicleSafetyEventUpdateManyWithoutVehicleNestedInput = {
  connect?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleSafetyEventCreateOrConnectWithoutVehicleInput>
  >;
  create?: InputMaybe<Array<VehicleSafetyEventCreateWithoutVehicleInput>>;
  createMany?: InputMaybe<VehicleSafetyEventCreateManyVehicleInputEnvelope>;
  delete?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleSafetyEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleSafetyEventWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleSafetyEventUpdateWithWhereUniqueWithoutVehicleInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleSafetyEventUpdateManyWithWhereWithoutVehicleInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleSafetyEventUpsertWithWhereUniqueWithoutVehicleInput>
  >;
};

export type VehicleSafetyEventUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<VehicleSafetyEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleSafetyEventCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<VehicleSafetyEventCreateWithoutAlertInput>;
  delete?: InputMaybe<VehicleSafetyEventWhereInput>;
  disconnect?: InputMaybe<VehicleSafetyEventWhereInput>;
  update?: InputMaybe<VehicleSafetyEventUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<VehicleSafetyEventUpsertWithoutAlertInput>;
};

export type VehicleSafetyEventUpdateToOneWithWhereWithoutAlertInput = {
  data: VehicleSafetyEventUpdateWithoutAlertInput;
  where?: InputMaybe<VehicleSafetyEventWhereInput>;
};

export type VehicleSafetyEventUpdateWithWhereUniqueWithoutDriverInput = {
  data: VehicleSafetyEventUpdateWithoutDriverInput;
  where: VehicleSafetyEventWhereUniqueInput;
};

export type VehicleSafetyEventUpdateWithWhereUniqueWithoutTripInput = {
  data: VehicleSafetyEventUpdateWithoutTripInput;
  where: VehicleSafetyEventWhereUniqueInput;
};

export type VehicleSafetyEventUpdateWithWhereUniqueWithoutVehicleInput = {
  data: VehicleSafetyEventUpdateWithoutVehicleInput;
  where: VehicleSafetyEventWhereUniqueInput;
};

export type VehicleSafetyEventUpdateWithoutAlertInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  cause?: InputMaybe<VehicleSafetyEventCause>;
  driver?: InputMaybe<DriverUpdateOneWithoutVehicleSafetyEventsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level?: InputMaybe<VehicleSafetyEventLevel>;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  trip?: InputMaybe<TripUpdateOneWithoutVehicleSafetyEventsNestedInput>;
  type?: InputMaybe<VehicleSafetyEventType>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutVehicleSafetyEventsNestedInput>;
};

export type VehicleSafetyEventUpdateWithoutDriverInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  alert?: InputMaybe<AlertUpdateOneWithoutVehicleSafetyEventNestedInput>;
  cause?: InputMaybe<VehicleSafetyEventCause>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level?: InputMaybe<VehicleSafetyEventLevel>;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  trip?: InputMaybe<TripUpdateOneWithoutVehicleSafetyEventsNestedInput>;
  type?: InputMaybe<VehicleSafetyEventType>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutVehicleSafetyEventsNestedInput>;
};

export type VehicleSafetyEventUpdateWithoutTripInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  alert?: InputMaybe<AlertUpdateOneWithoutVehicleSafetyEventNestedInput>;
  cause?: InputMaybe<VehicleSafetyEventCause>;
  driver?: InputMaybe<DriverUpdateOneWithoutVehicleSafetyEventsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level?: InputMaybe<VehicleSafetyEventLevel>;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<VehicleSafetyEventType>;
  vehicle?: InputMaybe<VehicleUpdateOneWithoutVehicleSafetyEventsNestedInput>;
};

export type VehicleSafetyEventUpdateWithoutVehicleInput = {
  agentComment?: InputMaybe<Scalars["String"]["input"]>;
  alert?: InputMaybe<AlertUpdateOneWithoutVehicleSafetyEventNestedInput>;
  cause?: InputMaybe<VehicleSafetyEventCause>;
  driver?: InputMaybe<DriverUpdateOneWithoutVehicleSafetyEventsNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  level?: InputMaybe<VehicleSafetyEventLevel>;
  locationPrecision?: InputMaybe<Scalars["Float"]["input"]>;
  lon?: InputMaybe<Scalars["Float"]["input"]>;
  packageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<VehicleSafetyEventPriority>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  trip?: InputMaybe<TripUpdateOneWithoutVehicleSafetyEventsNestedInput>;
  type?: InputMaybe<VehicleSafetyEventType>;
};

export type VehicleSafetyEventUpsertWithWhereUniqueWithoutDriverInput = {
  create: VehicleSafetyEventCreateWithoutDriverInput;
  update: VehicleSafetyEventUpdateWithoutDriverInput;
  where: VehicleSafetyEventWhereUniqueInput;
};

export type VehicleSafetyEventUpsertWithWhereUniqueWithoutTripInput = {
  create: VehicleSafetyEventCreateWithoutTripInput;
  update: VehicleSafetyEventUpdateWithoutTripInput;
  where: VehicleSafetyEventWhereUniqueInput;
};

export type VehicleSafetyEventUpsertWithWhereUniqueWithoutVehicleInput = {
  create: VehicleSafetyEventCreateWithoutVehicleInput;
  update: VehicleSafetyEventUpdateWithoutVehicleInput;
  where: VehicleSafetyEventWhereUniqueInput;
};

export type VehicleSafetyEventUpsertWithoutAlertInput = {
  create: VehicleSafetyEventCreateWithoutAlertInput;
  update: VehicleSafetyEventUpdateWithoutAlertInput;
  where?: InputMaybe<VehicleSafetyEventWhereInput>;
};

export type VehicleSafetyEventWhereInput = {
  AND?: InputMaybe<Array<VehicleSafetyEventWhereInput>>;
  NOT?: InputMaybe<Array<VehicleSafetyEventWhereInput>>;
  OR?: InputMaybe<Array<VehicleSafetyEventWhereInput>>;
  agentComment?: InputMaybe<StringFilter>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  cause?: InputMaybe<EnumVehicleSafetyEventCauseFilter>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isFlagged?: InputMaybe<BoolFilter>;
  lat?: InputMaybe<FloatFilter>;
  level?: InputMaybe<EnumVehicleSafetyEventLevelFilter>;
  locationPrecision?: InputMaybe<FloatFilter>;
  lon?: InputMaybe<FloatFilter>;
  packageNumber?: InputMaybe<IntFilter>;
  priority?: InputMaybe<EnumVehicleSafetyEventPriorityFilter>;
  timestamp?: InputMaybe<DateTimeFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumVehicleSafetyEventTypeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type VehicleSafetyEventWhereUniqueInput = {
  AND?: InputMaybe<Array<VehicleSafetyEventWhereInput>>;
  NOT?: InputMaybe<Array<VehicleSafetyEventWhereInput>>;
  OR?: InputMaybe<Array<VehicleSafetyEventWhereInput>>;
  agentComment?: InputMaybe<StringFilter>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  cause?: InputMaybe<EnumVehicleSafetyEventCauseFilter>;
  driver?: InputMaybe<DriverRelationFilter>;
  driverId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isFlagged?: InputMaybe<BoolFilter>;
  lat?: InputMaybe<FloatFilter>;
  level?: InputMaybe<EnumVehicleSafetyEventLevelFilter>;
  locationPrecision?: InputMaybe<FloatFilter>;
  lon?: InputMaybe<FloatFilter>;
  packageNumber?: InputMaybe<IntFilter>;
  priority?: InputMaybe<EnumVehicleSafetyEventPriorityFilter>;
  timestamp?: InputMaybe<DateTimeFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumVehicleSafetyEventTypeFilter>;
  vehicle?: InputMaybe<VehicleRelationFilter>;
  vehicleId?: InputMaybe<StringFilter>;
};

export type VehicleScalarWhereInput = {
  AND?: InputMaybe<Array<VehicleScalarWhereInput>>;
  NOT?: InputMaybe<Array<VehicleScalarWhereInput>>;
  OR?: InputMaybe<Array<VehicleScalarWhereInput>>;
  capacity?: InputMaybe<IntFilter>;
  chassisNumber?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  color?: InputMaybe<StringFilter>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  initOdometer?: InputMaybe<IntFilter>;
  licensePlate?: InputMaybe<StringFilter>;
  make?: InputMaybe<StringFilter>;
  maxSpeed?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumVehicleStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleGroupId?: InputMaybe<StringFilter>;
  vehicleMakeId?: InputMaybe<StringFilter>;
  vehicleModelId?: InputMaybe<StringFilter>;
  vehicleTypeId?: InputMaybe<StringFilter>;
  year?: InputMaybe<IntFilter>;
};

export enum VehicleStatus {
  ACTIVE = "ACTIVE",
  EMERGENCY = "EMERGENCY",
  INACTIVE = "INACTIVE",
  MAINTENANCE = "MAINTENANCE",
  POOL = "POOL",
  PROVISIONING = "PROVISIONING",
  RETIRED = "RETIRED",
}

export type VehicleType = {
  __typename?: "VehicleType";
  _count: VehicleTypeCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  vehicles?: Maybe<Array<Vehicle>>;
};

export type VehicleTypeCount = {
  __typename?: "VehicleTypeCount";
  vehicles: Scalars["Int"]["output"];
};

export type VehicleTypeCreateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicles?: InputMaybe<VehicleCreateNestedManyWithoutVehicleTypeInput>;
};

export type VehicleTypeCreateNestedOneWithoutVehiclesInput = {
  connect?: InputMaybe<VehicleTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleTypeCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<VehicleTypeCreateWithoutVehiclesInput>;
};

export type VehicleTypeCreateOrConnectWithoutVehiclesInput = {
  create: VehicleTypeCreateWithoutVehiclesInput;
  where: VehicleTypeWhereUniqueInput;
};

export type VehicleTypeCreateWithoutVehiclesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleTypeRelationFilter = {
  is?: InputMaybe<VehicleTypeWhereInput>;
  isNot?: InputMaybe<VehicleTypeWhereInput>;
};

export type VehicleTypeUpdateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicles?: InputMaybe<VehicleUpdateManyWithoutVehicleTypeNestedInput>;
};

export type VehicleTypeUpdateOneWithoutVehiclesNestedInput = {
  connect?: InputMaybe<VehicleTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleTypeCreateOrConnectWithoutVehiclesInput>;
  create?: InputMaybe<VehicleTypeCreateWithoutVehiclesInput>;
  delete?: InputMaybe<VehicleTypeWhereInput>;
  disconnect?: InputMaybe<VehicleTypeWhereInput>;
  update?: InputMaybe<VehicleTypeUpdateToOneWithWhereWithoutVehiclesInput>;
  upsert?: InputMaybe<VehicleTypeUpsertWithoutVehiclesInput>;
};

export type VehicleTypeUpdateToOneWithWhereWithoutVehiclesInput = {
  data: VehicleTypeUpdateWithoutVehiclesInput;
  where?: InputMaybe<VehicleTypeWhereInput>;
};

export type VehicleTypeUpdateWithoutVehiclesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VehicleTypeUpsertInput = {
  create?: InputMaybe<VehicleTypeCreateInput>;
  update?: InputMaybe<VehicleTypeUpdateInput>;
  where?: InputMaybe<VehicleTypeWhereUniqueInput>;
};

export type VehicleTypeUpsertWithoutVehiclesInput = {
  create: VehicleTypeCreateWithoutVehiclesInput;
  update: VehicleTypeUpdateWithoutVehiclesInput;
  where?: InputMaybe<VehicleTypeWhereInput>;
};

export type VehicleTypeWhereInput = {
  AND?: InputMaybe<Array<VehicleTypeWhereInput>>;
  NOT?: InputMaybe<Array<VehicleTypeWhereInput>>;
  OR?: InputMaybe<Array<VehicleTypeWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicles?: InputMaybe<VehicleListRelationFilter>;
};

export type VehicleTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<VehicleTypeWhereInput>>;
  NOT?: InputMaybe<Array<VehicleTypeWhereInput>>;
  OR?: InputMaybe<Array<VehicleTypeWhereInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicles?: InputMaybe<VehicleListRelationFilter>;
};

export type VehicleUpdateInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateManyMutationInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateManyWithWhereWithoutCountryInput = {
  data: VehicleUpdateManyMutationInput;
  where: VehicleScalarWhereInput;
};

export type VehicleUpdateManyWithWhereWithoutServiceProviderInput = {
  data: VehicleUpdateManyMutationInput;
  where: VehicleScalarWhereInput;
};

export type VehicleUpdateManyWithWhereWithoutVehicleGroupInput = {
  data: VehicleUpdateManyMutationInput;
  where: VehicleScalarWhereInput;
};

export type VehicleUpdateManyWithWhereWithoutVehicleMakeInput = {
  data: VehicleUpdateManyMutationInput;
  where: VehicleScalarWhereInput;
};

export type VehicleUpdateManyWithWhereWithoutVehicleModelInput = {
  data: VehicleUpdateManyMutationInput;
  where: VehicleScalarWhereInput;
};

export type VehicleUpdateManyWithWhereWithoutVehicleTypeInput = {
  data: VehicleUpdateManyMutationInput;
  where: VehicleScalarWhereInput;
};

export type VehicleUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutCountryInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutCountryInput>>;
  createMany?: InputMaybe<VehicleCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  update?: InputMaybe<Array<VehicleUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<Array<VehicleUpdateManyWithWhereWithoutCountryInput>>;
  upsert?: InputMaybe<Array<VehicleUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type VehicleUpdateManyWithoutServiceProviderNestedInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutServiceProviderInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutServiceProviderInput>>;
  createMany?: InputMaybe<VehicleCreateManyServiceProviderInputEnvelope>;
  delete?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleUpdateWithWhereUniqueWithoutServiceProviderInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleUpdateManyWithWhereWithoutServiceProviderInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleUpsertWithWhereUniqueWithoutServiceProviderInput>
  >;
};

export type VehicleUpdateManyWithoutVehicleGroupNestedInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutVehicleGroupInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutVehicleGroupInput>>;
  createMany?: InputMaybe<VehicleCreateManyVehicleGroupInputEnvelope>;
  delete?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleUpdateWithWhereUniqueWithoutVehicleGroupInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleUpdateManyWithWhereWithoutVehicleGroupInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleUpsertWithWhereUniqueWithoutVehicleGroupInput>
  >;
};

export type VehicleUpdateManyWithoutVehicleMakeNestedInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutVehicleMakeInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutVehicleMakeInput>>;
  createMany?: InputMaybe<VehicleCreateManyVehicleMakeInputEnvelope>;
  delete?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleUpdateWithWhereUniqueWithoutVehicleMakeInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleUpdateManyWithWhereWithoutVehicleMakeInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleUpsertWithWhereUniqueWithoutVehicleMakeInput>
  >;
};

export type VehicleUpdateManyWithoutVehicleModelNestedInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutVehicleModelInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutVehicleModelInput>>;
  createMany?: InputMaybe<VehicleCreateManyVehicleModelInputEnvelope>;
  delete?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleUpdateWithWhereUniqueWithoutVehicleModelInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleUpdateManyWithWhereWithoutVehicleModelInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleUpsertWithWhereUniqueWithoutVehicleModelInput>
  >;
};

export type VehicleUpdateManyWithoutVehicleTypeNestedInput = {
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<VehicleCreateOrConnectWithoutVehicleTypeInput>
  >;
  create?: InputMaybe<Array<VehicleCreateWithoutVehicleTypeInput>>;
  createMany?: InputMaybe<VehicleCreateManyVehicleTypeInputEnvelope>;
  delete?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<VehicleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  set?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  update?: InputMaybe<
    Array<VehicleUpdateWithWhereUniqueWithoutVehicleTypeInput>
  >;
  updateMany?: InputMaybe<
    Array<VehicleUpdateManyWithWhereWithoutVehicleTypeInput>
  >;
  upsert?: InputMaybe<
    Array<VehicleUpsertWithWhereUniqueWithoutVehicleTypeInput>
  >;
};

export type VehicleUpdateOneRequiredWithoutDriversNestedInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutDriversInput>;
  create?: InputMaybe<VehicleCreateWithoutDriversInput>;
  update?: InputMaybe<VehicleUpdateToOneWithWhereWithoutDriversInput>;
  upsert?: InputMaybe<VehicleUpsertWithoutDriversInput>;
};

export type VehicleUpdateOneRequiredWithoutRoutesNestedInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutRoutesInput>;
  create?: InputMaybe<VehicleCreateWithoutRoutesInput>;
  update?: InputMaybe<VehicleUpdateToOneWithWhereWithoutRoutesInput>;
  upsert?: InputMaybe<VehicleUpsertWithoutRoutesInput>;
};

export type VehicleUpdateOneWithoutNewVehicleTripLogsNestedInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutNewVehicleTripLogsInput>;
  create?: InputMaybe<VehicleCreateWithoutNewVehicleTripLogsInput>;
  delete?: InputMaybe<VehicleWhereInput>;
  disconnect?: InputMaybe<VehicleWhereInput>;
  update?: InputMaybe<VehicleUpdateToOneWithWhereWithoutNewVehicleTripLogsInput>;
  upsert?: InputMaybe<VehicleUpsertWithoutNewVehicleTripLogsInput>;
};

export type VehicleUpdateOneWithoutOldVehicleTripLogsNestedInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutOldVehicleTripLogsInput>;
  create?: InputMaybe<VehicleCreateWithoutOldVehicleTripLogsInput>;
  delete?: InputMaybe<VehicleWhereInput>;
  disconnect?: InputMaybe<VehicleWhereInput>;
  update?: InputMaybe<VehicleUpdateToOneWithWhereWithoutOldVehicleTripLogsInput>;
  upsert?: InputMaybe<VehicleUpsertWithoutOldVehicleTripLogsInput>;
};

export type VehicleUpdateOneWithoutRouteVehicleVacantAlertsNestedInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutRouteVehicleVacantAlertsInput>;
  create?: InputMaybe<VehicleCreateWithoutRouteVehicleVacantAlertsInput>;
  delete?: InputMaybe<VehicleWhereInput>;
  disconnect?: InputMaybe<VehicleWhereInput>;
  update?: InputMaybe<VehicleUpdateToOneWithWhereWithoutRouteVehicleVacantAlertsInput>;
  upsert?: InputMaybe<VehicleUpsertWithoutRouteVehicleVacantAlertsInput>;
};

export type VehicleUpdateOneWithoutTravelTagDetectionsNestedInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutTravelTagDetectionsInput>;
  create?: InputMaybe<VehicleCreateWithoutTravelTagDetectionsInput>;
  delete?: InputMaybe<VehicleWhereInput>;
  disconnect?: InputMaybe<VehicleWhereInput>;
  update?: InputMaybe<VehicleUpdateToOneWithWhereWithoutTravelTagDetectionsInput>;
  upsert?: InputMaybe<VehicleUpsertWithoutTravelTagDetectionsInput>;
};

export type VehicleUpdateOneWithoutTripBusOverLoadedAlertsNestedInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutTripBusOverLoadedAlertsInput>;
  create?: InputMaybe<VehicleCreateWithoutTripBusOverLoadedAlertsInput>;
  delete?: InputMaybe<VehicleWhereInput>;
  disconnect?: InputMaybe<VehicleWhereInput>;
  update?: InputMaybe<VehicleUpdateToOneWithWhereWithoutTripBusOverLoadedAlertsInput>;
  upsert?: InputMaybe<VehicleUpsertWithoutTripBusOverLoadedAlertsInput>;
};

export type VehicleUpdateOneWithoutTripsNestedInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutTripsInput>;
  create?: InputMaybe<VehicleCreateWithoutTripsInput>;
  delete?: InputMaybe<VehicleWhereInput>;
  disconnect?: InputMaybe<VehicleWhereInput>;
  update?: InputMaybe<VehicleUpdateToOneWithWhereWithoutTripsInput>;
  upsert?: InputMaybe<VehicleUpsertWithoutTripsInput>;
};

export type VehicleUpdateOneWithoutVehicleSafetyEventsNestedInput = {
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VehicleCreateOrConnectWithoutVehicleSafetyEventsInput>;
  create?: InputMaybe<VehicleCreateWithoutVehicleSafetyEventsInput>;
  delete?: InputMaybe<VehicleWhereInput>;
  disconnect?: InputMaybe<VehicleWhereInput>;
  update?: InputMaybe<VehicleUpdateToOneWithWhereWithoutVehicleSafetyEventsInput>;
  upsert?: InputMaybe<VehicleUpsertWithoutVehicleSafetyEventsInput>;
};

export type VehicleUpdateToOneWithWhereWithoutDriversInput = {
  data: VehicleUpdateWithoutDriversInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpdateToOneWithWhereWithoutNewVehicleTripLogsInput = {
  data: VehicleUpdateWithoutNewVehicleTripLogsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpdateToOneWithWhereWithoutOldVehicleTripLogsInput = {
  data: VehicleUpdateWithoutOldVehicleTripLogsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpdateToOneWithWhereWithoutRouteVehicleVacantAlertsInput = {
  data: VehicleUpdateWithoutRouteVehicleVacantAlertsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpdateToOneWithWhereWithoutRoutesInput = {
  data: VehicleUpdateWithoutRoutesInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpdateToOneWithWhereWithoutTravelTagDetectionsInput = {
  data: VehicleUpdateWithoutTravelTagDetectionsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpdateToOneWithWhereWithoutTripBusOverLoadedAlertsInput = {
  data: VehicleUpdateWithoutTripBusOverLoadedAlertsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpdateToOneWithWhereWithoutTripsInput = {
  data: VehicleUpdateWithoutTripsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpdateToOneWithWhereWithoutVehicleSafetyEventsInput = {
  data: VehicleUpdateWithoutVehicleSafetyEventsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpdateWithWhereUniqueWithoutCountryInput = {
  data: VehicleUpdateWithoutCountryInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpdateWithWhereUniqueWithoutServiceProviderInput = {
  data: VehicleUpdateWithoutServiceProviderInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpdateWithWhereUniqueWithoutVehicleGroupInput = {
  data: VehicleUpdateWithoutVehicleGroupInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpdateWithWhereUniqueWithoutVehicleMakeInput = {
  data: VehicleUpdateWithoutVehicleMakeInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpdateWithWhereUniqueWithoutVehicleModelInput = {
  data: VehicleUpdateWithoutVehicleModelInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpdateWithWhereUniqueWithoutVehicleTypeInput = {
  data: VehicleUpdateWithoutVehicleTypeInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpdateWithoutCountryInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutDriversInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutNewVehicleTripLogsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutOldVehicleTripLogsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutRouteVehicleVacantAlertsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutRoutesInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutServiceProviderInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutTravelTagDetectionsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutTripBusOverLoadedAlertsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutTripsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutVehicleGroupInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutVehicleMakeInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutVehicleModelInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutVehicleSafetyEventsInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneWithoutVehiclesNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpdateWithoutVehicleTypeInput = {
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  chassisNumber?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<CountryUpdateOneWithoutVehicleNestedInput>;
  drivers?: InputMaybe<VehicleDriverUpdateManyWithoutVehicleNestedInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<Scalars["Int"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxSpeed?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  newVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutNewVehicleNestedInput>;
  oldVehicleTripLogs?: InputMaybe<TripLogUpdateManyWithoutOldVehicleNestedInput>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertUpdateManyWithoutVehicleNestedInput>;
  routes?: InputMaybe<RouteVehicleUpdateManyWithoutVehicleNestedInput>;
  serviceProvider?: InputMaybe<ServiceProviderUpdateOneRequiredWithoutVehiclesNestedInput>;
  status?: InputMaybe<VehicleStatus>;
  submittedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  travelTagDetections?: InputMaybe<TravelTagDetectionUpdateManyWithoutVehicleNestedInput>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertUpdateManyWithoutVehicleNestedInput>;
  trips?: InputMaybe<TripUpdateManyWithoutVehicleNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  vehicleEvents?: InputMaybe<VehicleEventUpdateManyWithoutVehicleNestedInput>;
  vehicleGroup?: InputMaybe<VehicleGroupUpdateOneWithoutVehiclesNestedInput>;
  vehicleMake?: InputMaybe<VehicleMakeUpdateOneWithoutVehiclesNestedInput>;
  vehicleModel?: InputMaybe<VehicleModelUpdateOneWithoutVehiclesNestedInput>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventUpdateManyWithoutVehicleNestedInput>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VehicleUpsertWithWhereUniqueWithoutCountryInput = {
  create: VehicleCreateWithoutCountryInput;
  update: VehicleUpdateWithoutCountryInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpsertWithWhereUniqueWithoutServiceProviderInput = {
  create: VehicleCreateWithoutServiceProviderInput;
  update: VehicleUpdateWithoutServiceProviderInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpsertWithWhereUniqueWithoutVehicleGroupInput = {
  create: VehicleCreateWithoutVehicleGroupInput;
  update: VehicleUpdateWithoutVehicleGroupInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpsertWithWhereUniqueWithoutVehicleMakeInput = {
  create: VehicleCreateWithoutVehicleMakeInput;
  update: VehicleUpdateWithoutVehicleMakeInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpsertWithWhereUniqueWithoutVehicleModelInput = {
  create: VehicleCreateWithoutVehicleModelInput;
  update: VehicleUpdateWithoutVehicleModelInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpsertWithWhereUniqueWithoutVehicleTypeInput = {
  create: VehicleCreateWithoutVehicleTypeInput;
  update: VehicleUpdateWithoutVehicleTypeInput;
  where: VehicleWhereUniqueInput;
};

export type VehicleUpsertWithoutDriversInput = {
  create: VehicleCreateWithoutDriversInput;
  update: VehicleUpdateWithoutDriversInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpsertWithoutNewVehicleTripLogsInput = {
  create: VehicleCreateWithoutNewVehicleTripLogsInput;
  update: VehicleUpdateWithoutNewVehicleTripLogsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpsertWithoutOldVehicleTripLogsInput = {
  create: VehicleCreateWithoutOldVehicleTripLogsInput;
  update: VehicleUpdateWithoutOldVehicleTripLogsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpsertWithoutRouteVehicleVacantAlertsInput = {
  create: VehicleCreateWithoutRouteVehicleVacantAlertsInput;
  update: VehicleUpdateWithoutRouteVehicleVacantAlertsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpsertWithoutRoutesInput = {
  create: VehicleCreateWithoutRoutesInput;
  update: VehicleUpdateWithoutRoutesInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpsertWithoutTravelTagDetectionsInput = {
  create: VehicleCreateWithoutTravelTagDetectionsInput;
  update: VehicleUpdateWithoutTravelTagDetectionsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpsertWithoutTripBusOverLoadedAlertsInput = {
  create: VehicleCreateWithoutTripBusOverLoadedAlertsInput;
  update: VehicleUpdateWithoutTripBusOverLoadedAlertsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpsertWithoutTripsInput = {
  create: VehicleCreateWithoutTripsInput;
  update: VehicleUpdateWithoutTripsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleUpsertWithoutVehicleSafetyEventsInput = {
  create: VehicleCreateWithoutVehicleSafetyEventsInput;
  update: VehicleUpdateWithoutVehicleSafetyEventsInput;
  where?: InputMaybe<VehicleWhereInput>;
};

export type VehicleWhereInput = {
  AND?: InputMaybe<Array<VehicleWhereInput>>;
  NOT?: InputMaybe<Array<VehicleWhereInput>>;
  OR?: InputMaybe<Array<VehicleWhereInput>>;
  capacity?: InputMaybe<IntFilter>;
  chassisNumber?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  color?: InputMaybe<StringFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  drivers?: InputMaybe<VehicleDriverListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  initOdometer?: InputMaybe<IntFilter>;
  licensePlate?: InputMaybe<StringFilter>;
  make?: InputMaybe<StringFilter>;
  maxSpeed?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  newVehicleTripLogs?: InputMaybe<TripLogListRelationFilter>;
  oldVehicleTripLogs?: InputMaybe<TripLogListRelationFilter>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertListRelationFilter>;
  routes?: InputMaybe<RouteVehicleListRelationFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumVehicleStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  travelTagDetections?: InputMaybe<TravelTagDetectionListRelationFilter>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertListRelationFilter>;
  trips?: InputMaybe<TripListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleEvents?: InputMaybe<VehicleEventListRelationFilter>;
  vehicleGroup?: InputMaybe<VehicleGroupRelationFilter>;
  vehicleGroupId?: InputMaybe<StringFilter>;
  vehicleMake?: InputMaybe<VehicleMakeRelationFilter>;
  vehicleMakeId?: InputMaybe<StringFilter>;
  vehicleModel?: InputMaybe<VehicleModelRelationFilter>;
  vehicleModelId?: InputMaybe<StringFilter>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventListRelationFilter>;
  vehicleType?: InputMaybe<VehicleTypeRelationFilter>;
  vehicleTypeId?: InputMaybe<StringFilter>;
  year?: InputMaybe<IntFilter>;
};

export type VehicleWhereUniqueInput = {
  AND?: InputMaybe<Array<VehicleWhereInput>>;
  NOT?: InputMaybe<Array<VehicleWhereInput>>;
  OR?: InputMaybe<Array<VehicleWhereInput>>;
  capacity?: InputMaybe<IntFilter>;
  chassisNumber?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  color?: InputMaybe<StringFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  drivers?: InputMaybe<VehicleDriverListRelationFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  initOdometer?: InputMaybe<IntFilter>;
  licensePlate?: InputMaybe<StringFilter>;
  make?: InputMaybe<StringFilter>;
  maxSpeed?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  newVehicleTripLogs?: InputMaybe<TripLogListRelationFilter>;
  oldVehicleTripLogs?: InputMaybe<TripLogListRelationFilter>;
  routeVehicleVacantAlerts?: InputMaybe<RouteVehicleVacantAlertListRelationFilter>;
  routes?: InputMaybe<RouteVehicleListRelationFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumVehicleStatusFilter>;
  submittedAt?: InputMaybe<DateTimeFilter>;
  travelTagDetections?: InputMaybe<TravelTagDetectionListRelationFilter>;
  tripBusOverLoadedAlerts?: InputMaybe<TripBusOverLoadedAlertListRelationFilter>;
  trips?: InputMaybe<TripListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vehicleEvents?: InputMaybe<VehicleEventListRelationFilter>;
  vehicleGroup?: InputMaybe<VehicleGroupRelationFilter>;
  vehicleGroupId?: InputMaybe<StringFilter>;
  vehicleMake?: InputMaybe<VehicleMakeRelationFilter>;
  vehicleMakeId?: InputMaybe<StringFilter>;
  vehicleModel?: InputMaybe<VehicleModelRelationFilter>;
  vehicleModelId?: InputMaybe<StringFilter>;
  vehicleSafetyEvents?: InputMaybe<VehicleSafetyEventListRelationFilter>;
  vehicleType?: InputMaybe<VehicleTypeRelationFilter>;
  vehicleTypeId?: InputMaybe<StringFilter>;
  year?: InputMaybe<IntFilter>;
};

export type VehicleWithLastLocation = {
  __typename?: "VehicleWithLastLocation";
  _count: VehicleCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  capacity: Scalars["Int"]["output"];
  chassisNumber?: Maybe<Scalars["String"]["output"]>;
  code: Scalars["String"]["output"];
  color?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  drivers?: Maybe<Array<VehicleDriver>>;
  id: Scalars["String"]["output"];
  initOdometer?: Maybe<Scalars["Int"]["output"]>;
  lastLocation?: Maybe<LatLon>;
  lastSeen?: Maybe<Scalars["DateTime"]["output"]>;
  licensePlate?: Maybe<Scalars["String"]["output"]>;
  make?: Maybe<Scalars["String"]["output"]>;
  maxSpeed?: Maybe<Scalars["Int"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  nameAr?: Maybe<Scalars["String"]["output"]>;
  newVehicleTripLogs?: Maybe<Array<TripLog>>;
  oldVehicleTripLogs?: Maybe<Array<TripLog>>;
  operationalStatus?: Maybe<VehicleOperationalStatus>;
  packageNumber?: Maybe<Scalars["Int"]["output"]>;
  routeVehicleVacantAlerts?: Maybe<Array<RouteVehicleVacantAlert>>;
  routes?: Maybe<Array<RouteVehicle>>;
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars["String"]["output"];
  status: VehicleStatus;
  submittedAt?: Maybe<Scalars["DateTime"]["output"]>;
  travelTagDetections?: Maybe<Array<TravelTagDetection>>;
  tripBusOverLoadedAlerts?: Maybe<Array<TripBusOverLoadedAlert>>;
  trips?: Maybe<Array<Trip>>;
  updatedAt: Scalars["DateTime"]["output"];
  vehicleEvents?: Maybe<Array<VehicleEvent>>;
  vehicleGroup?: Maybe<VehicleGroup>;
  vehicleGroupId?: Maybe<Scalars["String"]["output"]>;
  vehicleMake?: Maybe<VehicleMake>;
  vehicleMakeId?: Maybe<Scalars["String"]["output"]>;
  vehicleModel?: Maybe<VehicleModel>;
  vehicleModelId?: Maybe<Scalars["String"]["output"]>;
  vehicleSafetyEvents?: Maybe<Array<VehicleSafetyEvent>>;
  vehicleType?: Maybe<VehicleType>;
  vehicleTypeId?: Maybe<Scalars["String"]["output"]>;
  /** ////////////////////// */
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type WrongDropOffLocation = {
  __typename?: "WrongDropOffLocation";
  alert: Alert;
  alertId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  dropOffLat: Scalars["Float"]["output"];
  dropOffLon: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  plannedLat: Scalars["Float"]["output"];
  plannedLon: Scalars["Float"]["output"];
  source: TripStopEventSource;
  student: Student;
  studentId: Scalars["String"]["output"];
  trip: Trip;
  tripId: Scalars["String"]["output"];
};

export type WrongDropOffLocationCreateManyStudentInput = {
  alertId: Scalars["String"]["input"];
  dropOffLat: Scalars["Float"]["input"];
  dropOffLon: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedLat: Scalars["Float"]["input"];
  plannedLon: Scalars["Float"]["input"];
  source: TripStopEventSource;
  tripId: Scalars["String"]["input"];
};

export type WrongDropOffLocationCreateManyStudentInputEnvelope = {
  data: Array<WrongDropOffLocationCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WrongDropOffLocationCreateManyTripInput = {
  alertId: Scalars["String"]["input"];
  dropOffLat: Scalars["Float"]["input"];
  dropOffLon: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedLat: Scalars["Float"]["input"];
  plannedLon: Scalars["Float"]["input"];
  source: TripStopEventSource;
  studentId: Scalars["String"]["input"];
};

export type WrongDropOffLocationCreateManyTripInputEnvelope = {
  data: Array<WrongDropOffLocationCreateManyTripInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WrongDropOffLocationCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<WrongDropOffLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<WrongDropOffLocationCreateOrConnectWithoutStudentInput>
  >;
  create?: InputMaybe<Array<WrongDropOffLocationCreateWithoutStudentInput>>;
  createMany?: InputMaybe<WrongDropOffLocationCreateManyStudentInputEnvelope>;
};

export type WrongDropOffLocationCreateNestedManyWithoutTripInput = {
  connect?: InputMaybe<Array<WrongDropOffLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<WrongDropOffLocationCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<WrongDropOffLocationCreateWithoutTripInput>>;
  createMany?: InputMaybe<WrongDropOffLocationCreateManyTripInputEnvelope>;
};

export type WrongDropOffLocationCreateNestedOneWithoutAlertInput = {
  connect?: InputMaybe<WrongDropOffLocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WrongDropOffLocationCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<WrongDropOffLocationCreateWithoutAlertInput>;
};

export type WrongDropOffLocationCreateOrConnectWithoutAlertInput = {
  create: WrongDropOffLocationCreateWithoutAlertInput;
  where: WrongDropOffLocationWhereUniqueInput;
};

export type WrongDropOffLocationCreateOrConnectWithoutStudentInput = {
  create: WrongDropOffLocationCreateWithoutStudentInput;
  where: WrongDropOffLocationWhereUniqueInput;
};

export type WrongDropOffLocationCreateOrConnectWithoutTripInput = {
  create: WrongDropOffLocationCreateWithoutTripInput;
  where: WrongDropOffLocationWhereUniqueInput;
};

export type WrongDropOffLocationCreateWithoutAlertInput = {
  dropOffLat: Scalars["Float"]["input"];
  dropOffLon: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedLat: Scalars["Float"]["input"];
  plannedLon: Scalars["Float"]["input"];
  source: TripStopEventSource;
  student: StudentCreateNestedOneWithoutWrongDropOffLocationsInput;
  trip: TripCreateNestedOneWithoutWrongDropOffLocationsInput;
};

export type WrongDropOffLocationCreateWithoutStudentInput = {
  alert: AlertCreateNestedOneWithoutWrongDropOffLocationInput;
  dropOffLat: Scalars["Float"]["input"];
  dropOffLon: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedLat: Scalars["Float"]["input"];
  plannedLon: Scalars["Float"]["input"];
  source: TripStopEventSource;
  trip: TripCreateNestedOneWithoutWrongDropOffLocationsInput;
};

export type WrongDropOffLocationCreateWithoutTripInput = {
  alert: AlertCreateNestedOneWithoutWrongDropOffLocationInput;
  dropOffLat: Scalars["Float"]["input"];
  dropOffLon: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedLat: Scalars["Float"]["input"];
  plannedLon: Scalars["Float"]["input"];
  source: TripStopEventSource;
  student: StudentCreateNestedOneWithoutWrongDropOffLocationsInput;
};

export type WrongDropOffLocationListRelationFilter = {
  every?: InputMaybe<WrongDropOffLocationWhereInput>;
  none?: InputMaybe<WrongDropOffLocationWhereInput>;
  some?: InputMaybe<WrongDropOffLocationWhereInput>;
};

export type WrongDropOffLocationRelationFilter = {
  is?: InputMaybe<WrongDropOffLocationWhereInput>;
  isNot?: InputMaybe<WrongDropOffLocationWhereInput>;
};

export type WrongDropOffLocationScalarWhereInput = {
  AND?: InputMaybe<Array<WrongDropOffLocationScalarWhereInput>>;
  NOT?: InputMaybe<Array<WrongDropOffLocationScalarWhereInput>>;
  OR?: InputMaybe<Array<WrongDropOffLocationScalarWhereInput>>;
  alertId?: InputMaybe<StringFilter>;
  dropOffLat?: InputMaybe<FloatFilter>;
  dropOffLon?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  plannedLat?: InputMaybe<FloatFilter>;
  plannedLon?: InputMaybe<FloatFilter>;
  source?: InputMaybe<EnumTripStopEventSourceFilter>;
  studentId?: InputMaybe<StringFilter>;
  tripId?: InputMaybe<StringFilter>;
};

export type WrongDropOffLocationUpdateManyMutationInput = {
  dropOffLat?: InputMaybe<Scalars["Float"]["input"]>;
  dropOffLon?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  source?: InputMaybe<TripStopEventSource>;
};

export type WrongDropOffLocationUpdateManyWithWhereWithoutStudentInput = {
  data: WrongDropOffLocationUpdateManyMutationInput;
  where: WrongDropOffLocationScalarWhereInput;
};

export type WrongDropOffLocationUpdateManyWithWhereWithoutTripInput = {
  data: WrongDropOffLocationUpdateManyMutationInput;
  where: WrongDropOffLocationScalarWhereInput;
};

export type WrongDropOffLocationUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<WrongDropOffLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<WrongDropOffLocationCreateOrConnectWithoutStudentInput>
  >;
  create?: InputMaybe<Array<WrongDropOffLocationCreateWithoutStudentInput>>;
  createMany?: InputMaybe<WrongDropOffLocationCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<WrongDropOffLocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WrongDropOffLocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WrongDropOffLocationWhereUniqueInput>>;
  set?: InputMaybe<Array<WrongDropOffLocationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<WrongDropOffLocationUpdateWithWhereUniqueWithoutStudentInput>
  >;
  updateMany?: InputMaybe<
    Array<WrongDropOffLocationUpdateManyWithWhereWithoutStudentInput>
  >;
  upsert?: InputMaybe<
    Array<WrongDropOffLocationUpsertWithWhereUniqueWithoutStudentInput>
  >;
};

export type WrongDropOffLocationUpdateManyWithoutTripNestedInput = {
  connect?: InputMaybe<Array<WrongDropOffLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<WrongDropOffLocationCreateOrConnectWithoutTripInput>
  >;
  create?: InputMaybe<Array<WrongDropOffLocationCreateWithoutTripInput>>;
  createMany?: InputMaybe<WrongDropOffLocationCreateManyTripInputEnvelope>;
  delete?: InputMaybe<Array<WrongDropOffLocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WrongDropOffLocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WrongDropOffLocationWhereUniqueInput>>;
  set?: InputMaybe<Array<WrongDropOffLocationWhereUniqueInput>>;
  update?: InputMaybe<
    Array<WrongDropOffLocationUpdateWithWhereUniqueWithoutTripInput>
  >;
  updateMany?: InputMaybe<
    Array<WrongDropOffLocationUpdateManyWithWhereWithoutTripInput>
  >;
  upsert?: InputMaybe<
    Array<WrongDropOffLocationUpsertWithWhereUniqueWithoutTripInput>
  >;
};

export type WrongDropOffLocationUpdateOneWithoutAlertNestedInput = {
  connect?: InputMaybe<WrongDropOffLocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WrongDropOffLocationCreateOrConnectWithoutAlertInput>;
  create?: InputMaybe<WrongDropOffLocationCreateWithoutAlertInput>;
  delete?: InputMaybe<WrongDropOffLocationWhereInput>;
  disconnect?: InputMaybe<WrongDropOffLocationWhereInput>;
  update?: InputMaybe<WrongDropOffLocationUpdateToOneWithWhereWithoutAlertInput>;
  upsert?: InputMaybe<WrongDropOffLocationUpsertWithoutAlertInput>;
};

export type WrongDropOffLocationUpdateToOneWithWhereWithoutAlertInput = {
  data: WrongDropOffLocationUpdateWithoutAlertInput;
  where?: InputMaybe<WrongDropOffLocationWhereInput>;
};

export type WrongDropOffLocationUpdateWithWhereUniqueWithoutStudentInput = {
  data: WrongDropOffLocationUpdateWithoutStudentInput;
  where: WrongDropOffLocationWhereUniqueInput;
};

export type WrongDropOffLocationUpdateWithWhereUniqueWithoutTripInput = {
  data: WrongDropOffLocationUpdateWithoutTripInput;
  where: WrongDropOffLocationWhereUniqueInput;
};

export type WrongDropOffLocationUpdateWithoutAlertInput = {
  dropOffLat?: InputMaybe<Scalars["Float"]["input"]>;
  dropOffLon?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  source?: InputMaybe<TripStopEventSource>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutWrongDropOffLocationsNestedInput>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutWrongDropOffLocationsNestedInput>;
};

export type WrongDropOffLocationUpdateWithoutStudentInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutWrongDropOffLocationNestedInput>;
  dropOffLat?: InputMaybe<Scalars["Float"]["input"]>;
  dropOffLon?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  source?: InputMaybe<TripStopEventSource>;
  trip?: InputMaybe<TripUpdateOneRequiredWithoutWrongDropOffLocationsNestedInput>;
};

export type WrongDropOffLocationUpdateWithoutTripInput = {
  alert?: InputMaybe<AlertUpdateOneRequiredWithoutWrongDropOffLocationNestedInput>;
  dropOffLat?: InputMaybe<Scalars["Float"]["input"]>;
  dropOffLon?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedLat?: InputMaybe<Scalars["Float"]["input"]>;
  plannedLon?: InputMaybe<Scalars["Float"]["input"]>;
  source?: InputMaybe<TripStopEventSource>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutWrongDropOffLocationsNestedInput>;
};

export type WrongDropOffLocationUpsertWithWhereUniqueWithoutStudentInput = {
  create: WrongDropOffLocationCreateWithoutStudentInput;
  update: WrongDropOffLocationUpdateWithoutStudentInput;
  where: WrongDropOffLocationWhereUniqueInput;
};

export type WrongDropOffLocationUpsertWithWhereUniqueWithoutTripInput = {
  create: WrongDropOffLocationCreateWithoutTripInput;
  update: WrongDropOffLocationUpdateWithoutTripInput;
  where: WrongDropOffLocationWhereUniqueInput;
};

export type WrongDropOffLocationUpsertWithoutAlertInput = {
  create: WrongDropOffLocationCreateWithoutAlertInput;
  update: WrongDropOffLocationUpdateWithoutAlertInput;
  where?: InputMaybe<WrongDropOffLocationWhereInput>;
};

export type WrongDropOffLocationWhereInput = {
  AND?: InputMaybe<Array<WrongDropOffLocationWhereInput>>;
  NOT?: InputMaybe<Array<WrongDropOffLocationWhereInput>>;
  OR?: InputMaybe<Array<WrongDropOffLocationWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<StringFilter>;
  dropOffLat?: InputMaybe<FloatFilter>;
  dropOffLon?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  plannedLat?: InputMaybe<FloatFilter>;
  plannedLon?: InputMaybe<FloatFilter>;
  source?: InputMaybe<EnumTripStopEventSourceFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<StringFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
};

export type WrongDropOffLocationWhereUniqueInput = {
  AND?: InputMaybe<Array<WrongDropOffLocationWhereInput>>;
  NOT?: InputMaybe<Array<WrongDropOffLocationWhereInput>>;
  OR?: InputMaybe<Array<WrongDropOffLocationWhereInput>>;
  alert?: InputMaybe<AlertRelationFilter>;
  alertId?: InputMaybe<Scalars["String"]["input"]>;
  dropOffLat?: InputMaybe<FloatFilter>;
  dropOffLon?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  plannedLat?: InputMaybe<FloatFilter>;
  plannedLon?: InputMaybe<FloatFilter>;
  source?: InputMaybe<EnumTripStopEventSourceFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<StringFilter>;
  trip?: InputMaybe<TripRelationFilter>;
  tripId?: InputMaybe<StringFilter>;
};

export type Zone = {
  __typename?: "Zone";
  _count: ZoneCount;
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nameAr?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
  serviceZone: ServiceZone;
  serviceZoneId: Scalars["String"]["output"];
  serviceZoneSchool?: Maybe<Array<ServiceZoneSchool>>;
  updatedAt: Scalars["DateTime"]["output"];
  zoneZonesFrom?: Maybe<Array<ZoneZone>>;
  zoneZonesTo?: Maybe<Array<ZoneZone>>;
};

export type ZoneCount = {
  __typename?: "ZoneCount";
  serviceZoneSchool: Scalars["Int"]["output"];
  zoneZonesFrom: Scalars["Int"]["output"];
  zoneZonesTo: Scalars["Int"]["output"];
};

export type ZoneCreateExtendedInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  polygonCoordinates: Array<Array<ZoneGeometryMappingInput>>;
  zoneZonesRelation?: InputMaybe<ZoneZoneCreateManyInput>;
};

export type ZoneCreateExtendedNestedManyWithoutServiceZoneInput = {
  connect?: InputMaybe<Array<ZoneWhereUniqueInput>>;
  create?: InputMaybe<Array<ZoneCreateExtendedInput>>;
};

export type ZoneCreateNestedManyWithoutServiceZoneInput = {
  connect?: InputMaybe<Array<ZoneWhereUniqueInput>>;
};

export type ZoneCreateNestedOneWithoutServiceZoneSchoolInput = {
  connect?: InputMaybe<ZoneWhereUniqueInput>;
};

export type ZoneCreateNestedOneWithoutZoneZonesFromInput = {
  connect?: InputMaybe<ZoneWhereUniqueInput>;
};

export type ZoneCreateNestedOneWithoutZoneZonesToInput = {
  connect?: InputMaybe<ZoneWhereUniqueInput>;
};

export type ZoneCreateNestedOneWithoutZoneZonesToInput2 = {
  connect?: InputMaybe<ZoneWhereUniqueInput2>;
};

export type ZoneGeometryMappingInput = {
  lat: Scalars["Float"]["input"];
  lon: Scalars["Float"]["input"];
};

export type ZoneListRelationFilter = {
  every?: InputMaybe<ZoneWhereInput>;
  none?: InputMaybe<ZoneWhereInput>;
  some?: InputMaybe<ZoneWhereInput>;
};

export type ZoneRelationFilter = {
  is?: InputMaybe<ZoneWhereInput>;
  isNot?: InputMaybe<ZoneWhereInput>;
};

export type ZoneScalarWhereInput = {
  AND?: InputMaybe<Array<ZoneScalarWhereInput>>;
  NOT?: InputMaybe<Array<ZoneScalarWhereInput>>;
  OR?: InputMaybe<Array<ZoneScalarWhereInput>>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  serviceZoneId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ZoneUpdateManyMutationInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ZoneUpdateManyWithWhereWithoutServiceZoneInput = {
  data: ZoneUpdateManyMutationInput;
  where: ZoneScalarWhereInput;
};

export type ZoneUpdateManyWithoutServiceZoneNestedInput = {
  connect?: InputMaybe<Array<ZoneWhereUniqueInput>>;
  delete?: InputMaybe<Array<ZoneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ZoneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ZoneWhereUniqueInput>>;
  set?: InputMaybe<Array<ZoneWhereUniqueInput>>;
  update?: InputMaybe<Array<ZoneUpdateWithWhereUniqueWithoutServiceZoneInput>>;
  updateMany?: InputMaybe<
    Array<ZoneUpdateManyWithWhereWithoutServiceZoneInput>
  >;
};

export type ZoneUpdateOneRequiredWithoutServiceZoneSchoolNestedInput = {
  connect?: InputMaybe<ZoneWhereUniqueInput>;
  update?: InputMaybe<ZoneUpdateToOneWithWhereWithoutServiceZoneSchoolInput>;
};

export type ZoneUpdateOneRequiredWithoutZoneZonesFromNestedInput = {
  connect?: InputMaybe<ZoneWhereUniqueInput>;
  update?: InputMaybe<ZoneUpdateToOneWithWhereWithoutZoneZonesFromInput>;
};

export type ZoneUpdateOneRequiredWithoutZoneZonesToNestedInput = {
  connect?: InputMaybe<ZoneWhereUniqueInput>;
  update?: InputMaybe<ZoneUpdateToOneWithWhereWithoutZoneZonesToInput>;
};

export type ZoneUpdateToOneWithWhereWithoutServiceZoneSchoolInput = {
  data: ZoneUpdateWithoutServiceZoneSchoolInput;
  where?: InputMaybe<ZoneWhereInput>;
};

export type ZoneUpdateToOneWithWhereWithoutZoneZonesFromInput = {
  data: ZoneUpdateWithoutZoneZonesFromInput;
  where?: InputMaybe<ZoneWhereInput>;
};

export type ZoneUpdateToOneWithWhereWithoutZoneZonesToInput = {
  data: ZoneUpdateWithoutZoneZonesToInput;
  where?: InputMaybe<ZoneWhereInput>;
};

export type ZoneUpdateWithWhereUniqueWithoutServiceZoneInput = {
  data: ZoneUpdateWithoutServiceZoneInput;
  where: ZoneWhereUniqueInput;
};

export type ZoneUpdateWithoutServiceZoneInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  serviceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutZoneNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneZonesFrom?: InputMaybe<ZoneZoneUpdateManyWithoutZoneFromNestedInput>;
  zoneZonesTo?: InputMaybe<ZoneZoneUpdateManyWithoutZoneToNestedInput>;
};

export type ZoneUpdateWithoutServiceZoneSchoolInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  serviceZone?: InputMaybe<ServiceZoneUpdateOneRequiredWithoutZonesNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneZonesFrom?: InputMaybe<ZoneZoneUpdateManyWithoutZoneFromNestedInput>;
  zoneZonesTo?: InputMaybe<ZoneZoneUpdateManyWithoutZoneToNestedInput>;
};

export type ZoneUpdateWithoutZoneZonesFromInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  serviceZone?: InputMaybe<ServiceZoneUpdateOneRequiredWithoutZonesNestedInput>;
  serviceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutZoneNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneZonesTo?: InputMaybe<ZoneZoneUpdateManyWithoutZoneToNestedInput>;
};

export type ZoneUpdateWithoutZoneZonesToInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nameAr?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  serviceZone?: InputMaybe<ServiceZoneUpdateOneRequiredWithoutZonesNestedInput>;
  serviceZoneSchool?: InputMaybe<ServiceZoneSchoolUpdateManyWithoutZoneNestedInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneZonesFrom?: InputMaybe<ZoneZoneUpdateManyWithoutZoneFromNestedInput>;
};

export type ZoneWhereInput = {
  AND?: InputMaybe<Array<ZoneWhereInput>>;
  NOT?: InputMaybe<Array<ZoneWhereInput>>;
  OR?: InputMaybe<Array<ZoneWhereInput>>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  serviceZone?: InputMaybe<ServiceZoneRelationFilter>;
  serviceZoneId?: InputMaybe<StringFilter>;
  serviceZoneSchool?: InputMaybe<ServiceZoneSchoolListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zoneZonesFrom?: InputMaybe<ZoneZoneListRelationFilter>;
  zoneZonesTo?: InputMaybe<ZoneZoneListRelationFilter>;
};

export type ZoneWhereUniqueInput = {
  AND?: InputMaybe<Array<ZoneWhereInput>>;
  NOT?: InputMaybe<Array<ZoneWhereInput>>;
  OR?: InputMaybe<Array<ZoneWhereInput>>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<StringFilter>;
  nameAr?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  serviceZone?: InputMaybe<ServiceZoneRelationFilter>;
  serviceZoneId?: InputMaybe<StringFilter>;
  serviceZoneSchool?: InputMaybe<ServiceZoneSchoolListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zoneZonesFrom?: InputMaybe<ZoneZoneListRelationFilter>;
  zoneZonesTo?: InputMaybe<ZoneZoneListRelationFilter>;
};

export type ZoneWhereUniqueInput2 = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ZoneZone = {
  __typename?: "ZoneZone";
  archivedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  currency: CurrencyCode;
  directionality: ZoneZoneDirectionality;
  id: Scalars["String"]["output"];
  oneWayPrice?: Maybe<Scalars["Float"]["output"]>;
  twoWayPrice?: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  zoneFrom: Zone;
  zoneFromId: Scalars["String"]["output"];
  zoneTo: Zone;
  zoneToId: Scalars["String"]["output"];
};

export type ZoneZoneCreateManyInput = {
  create?: InputMaybe<ZoneZoneCreateManyWithoutZoneFromZoneToInputEnvelope>;
};

export type ZoneZoneCreateManyWithoutZoneFromZoneToInputEnvelope = {
  data: Array<ZoneZoneCreateWithoutZoneFromInput2>;
};

export type ZoneZoneCreateManyZoneFromInput = {
  currency: CurrencyCode;
  directionality: ZoneZoneDirectionality;
  id?: InputMaybe<Scalars["String"]["input"]>;
  oneWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  twoWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneToId: Scalars["String"]["input"];
};

export type ZoneZoneCreateManyZoneFromInputEnvelope = {
  data: Array<ZoneZoneCreateManyZoneFromInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ZoneZoneCreateManyZoneToInput = {
  currency: CurrencyCode;
  directionality: ZoneZoneDirectionality;
  id?: InputMaybe<Scalars["String"]["input"]>;
  oneWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  twoWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneFromId: Scalars["String"]["input"];
};

export type ZoneZoneCreateManyZoneToInputEnvelope = {
  data: Array<ZoneZoneCreateManyZoneToInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ZoneZoneCreateOrConnectWithoutZoneFromInput = {
  create: ZoneZoneCreateWithoutZoneFromInput;
  where: ZoneZoneWhereUniqueInput;
};

export type ZoneZoneCreateOrConnectWithoutZoneToInput = {
  create: ZoneZoneCreateWithoutZoneToInput;
  where: ZoneZoneWhereUniqueInput;
};

export type ZoneZoneCreateWithoutZoneFromInput = {
  currency: CurrencyCode;
  directionality: ZoneZoneDirectionality;
  id?: InputMaybe<Scalars["String"]["input"]>;
  oneWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  twoWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneTo: ZoneCreateNestedOneWithoutZoneZonesToInput;
};

export type ZoneZoneCreateWithoutZoneFromInput2 = {
  currency?: InputMaybe<CurrencyCode>;
  directionality: ZoneZoneDirectionality;
  id?: InputMaybe<Scalars["String"]["input"]>;
  oneWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  twoWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneTo?: InputMaybe<ZoneCreateNestedOneWithoutZoneZonesToInput2>;
};

export type ZoneZoneCreateWithoutZoneToInput = {
  currency: CurrencyCode;
  directionality: ZoneZoneDirectionality;
  id?: InputMaybe<Scalars["String"]["input"]>;
  oneWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  twoWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneFrom: ZoneCreateNestedOneWithoutZoneZonesFromInput;
};

export enum ZoneZoneDirectionality {
  ROUND_TRIP = "ROUND_TRIP",
  TO_HOME = "TO_HOME",
  TO_SCHOOL = "TO_SCHOOL",
}

export type ZoneZoneListRelationFilter = {
  every?: InputMaybe<ZoneZoneWhereInput>;
  none?: InputMaybe<ZoneZoneWhereInput>;
  some?: InputMaybe<ZoneZoneWhereInput>;
};

export type ZoneZoneScalarWhereInput = {
  AND?: InputMaybe<Array<ZoneZoneScalarWhereInput>>;
  NOT?: InputMaybe<Array<ZoneZoneScalarWhereInput>>;
  OR?: InputMaybe<Array<ZoneZoneScalarWhereInput>>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  directionality?: InputMaybe<EnumZoneZoneDirectionalityFilter>;
  id?: InputMaybe<StringFilter>;
  oneWayPrice?: InputMaybe<FloatFilter>;
  twoWayPrice?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zoneFromId?: InputMaybe<StringFilter>;
  zoneToId?: InputMaybe<StringFilter>;
};

export type ZoneZoneUpdateManyMutationInput = {
  currency?: InputMaybe<CurrencyCode>;
  directionality?: InputMaybe<ZoneZoneDirectionality>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  oneWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  twoWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ZoneZoneUpdateManyWithWhereWithoutZoneFromInput = {
  data: ZoneZoneUpdateManyMutationInput;
  where: ZoneZoneScalarWhereInput;
};

export type ZoneZoneUpdateManyWithWhereWithoutZoneToInput = {
  data: ZoneZoneUpdateManyMutationInput;
  where: ZoneZoneScalarWhereInput;
};

export type ZoneZoneUpdateManyWithoutZoneFromNestedInput = {
  connect?: InputMaybe<Array<ZoneZoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ZoneZoneCreateOrConnectWithoutZoneFromInput>
  >;
  create?: InputMaybe<Array<ZoneZoneCreateWithoutZoneFromInput>>;
  createMany?: InputMaybe<ZoneZoneCreateManyZoneFromInputEnvelope>;
  delete?: InputMaybe<Array<ZoneZoneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ZoneZoneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ZoneZoneWhereUniqueInput>>;
  set?: InputMaybe<Array<ZoneZoneWhereUniqueInput>>;
  update?: InputMaybe<Array<ZoneZoneUpdateWithWhereUniqueWithoutZoneFromInput>>;
  updateMany?: InputMaybe<
    Array<ZoneZoneUpdateManyWithWhereWithoutZoneFromInput>
  >;
  upsert?: InputMaybe<Array<ZoneZoneUpsertWithWhereUniqueWithoutZoneFromInput>>;
};

export type ZoneZoneUpdateManyWithoutZoneToNestedInput = {
  connect?: InputMaybe<Array<ZoneZoneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ZoneZoneCreateOrConnectWithoutZoneToInput>
  >;
  create?: InputMaybe<Array<ZoneZoneCreateWithoutZoneToInput>>;
  createMany?: InputMaybe<ZoneZoneCreateManyZoneToInputEnvelope>;
  delete?: InputMaybe<Array<ZoneZoneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ZoneZoneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ZoneZoneWhereUniqueInput>>;
  set?: InputMaybe<Array<ZoneZoneWhereUniqueInput>>;
  update?: InputMaybe<Array<ZoneZoneUpdateWithWhereUniqueWithoutZoneToInput>>;
  updateMany?: InputMaybe<Array<ZoneZoneUpdateManyWithWhereWithoutZoneToInput>>;
  upsert?: InputMaybe<Array<ZoneZoneUpsertWithWhereUniqueWithoutZoneToInput>>;
};

export type ZoneZoneUpdateWithWhereUniqueWithoutZoneFromInput = {
  data: ZoneZoneUpdateWithoutZoneFromInput;
  where: ZoneZoneWhereUniqueInput;
};

export type ZoneZoneUpdateWithWhereUniqueWithoutZoneToInput = {
  data: ZoneZoneUpdateWithoutZoneToInput;
  where: ZoneZoneWhereUniqueInput;
};

export type ZoneZoneUpdateWithoutZoneFromInput = {
  currency?: InputMaybe<CurrencyCode>;
  directionality?: InputMaybe<ZoneZoneDirectionality>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  oneWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  twoWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneTo?: InputMaybe<ZoneUpdateOneRequiredWithoutZoneZonesToNestedInput>;
};

export type ZoneZoneUpdateWithoutZoneToInput = {
  currency?: InputMaybe<CurrencyCode>;
  directionality?: InputMaybe<ZoneZoneDirectionality>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  oneWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  twoWayPrice?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  zoneFrom?: InputMaybe<ZoneUpdateOneRequiredWithoutZoneZonesFromNestedInput>;
};

export type ZoneZoneUpsertWithWhereUniqueWithoutZoneFromInput = {
  create: ZoneZoneCreateWithoutZoneFromInput;
  update: ZoneZoneUpdateWithoutZoneFromInput;
  where: ZoneZoneWhereUniqueInput;
};

export type ZoneZoneUpsertWithWhereUniqueWithoutZoneToInput = {
  create: ZoneZoneCreateWithoutZoneToInput;
  update: ZoneZoneUpdateWithoutZoneToInput;
  where: ZoneZoneWhereUniqueInput;
};

export type ZoneZoneWhereInput = {
  AND?: InputMaybe<Array<ZoneZoneWhereInput>>;
  NOT?: InputMaybe<Array<ZoneZoneWhereInput>>;
  OR?: InputMaybe<Array<ZoneZoneWhereInput>>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  directionality?: InputMaybe<EnumZoneZoneDirectionalityFilter>;
  id?: InputMaybe<StringFilter>;
  oneWayPrice?: InputMaybe<FloatFilter>;
  twoWayPrice?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zoneFrom?: InputMaybe<ZoneRelationFilter>;
  zoneFromId?: InputMaybe<StringFilter>;
  zoneTo?: InputMaybe<ZoneRelationFilter>;
  zoneToId?: InputMaybe<StringFilter>;
};

export type ZoneZoneWhereUniqueInput = {
  AND?: InputMaybe<Array<ZoneZoneWhereInput>>;
  NOT?: InputMaybe<Array<ZoneZoneWhereInput>>;
  OR?: InputMaybe<Array<ZoneZoneWhereInput>>;
  currency?: InputMaybe<EnumCurrencyCodeFilter>;
  directionality?: InputMaybe<EnumZoneZoneDirectionalityFilter>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  oneWayPrice?: InputMaybe<FloatFilter>;
  twoWayPrice?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zoneFrom?: InputMaybe<ZoneRelationFilter>;
  zoneFromId?: InputMaybe<StringFilter>;
  zoneTo?: InputMaybe<ZoneRelationFilter>;
  zoneToId?: InputMaybe<StringFilter>;
};
