import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
import { WebStorage } from "redux-persist/lib/types";

import { getEnvironment } from "~/utils/environment";

import rootReducer from "~/state/reducers";

const isDev = getEnvironment().DEV;

const migrations: any = {
  1: (state: RootState) => {
    return state;
  },
  2: (state: RootState) => {
    return {
      ...state,
      user: {
        ...state.user,
        currentUser: {
          ...state.user.currentUser,
          data: {
            ...state.user.currentUser.data,
            accessibleCountryCodes: undefined,
          },
        },
      },
    };
  },
  3: (state: RootState) => {
    let workspacePanels = { ...state.workspacePanels };
    Object.entries(workspacePanels).forEach(
      ([workspaceKey, workspaceValue]) => {
        // Clean up the panels by removing the entries without panelType
        let cleanedUpPanels = {};
        if (workspaceValue) {
          Object.entries(workspaceValue.panels).forEach(([panelKey, panel]) => {
            // Preserve the panels with panelType or GENESIS
            if (panel.panelType || panelKey === "GENESIS") {
              const myPanel = { ...panel };

              // If the panelType is customersPanel, change it to guardiansPanel
              if (myPanel.panelType === "customersPanel") {
                myPanel.panelType = "guardiansPanel";
              }

              // Get rid of unused panels by cross checking the splitWorkspacePanels
              if (
                state.workspaceSplitPanels[
                  workspaceKey
                ]?.GENESIS?.childPanelIds?.includes(panelKey)
              ) {
                cleanedUpPanels = {
                  ...cleanedUpPanels,
                  [panelKey]: myPanel,
                };
              }
            }
          });
        }

        workspacePanels = {
          ...workspacePanels,
          [workspaceKey]: {
            panels: cleanedUpPanels,
          },
        };
      }
    );
    return {
      ...state,
      workspacePanels: {
        ...workspacePanels,
      },
    };
  },
  4: (state: RootState) => {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        // move safetyAlertsNotificationCentre to notificationCentre
        notificationCentre: {
          notifications: {
            ...state.workspace.safetyAlertsNotificationCentre,
          },
          shouldShowNotificationAlerts: true,
        },
        // remove safetyAlertsNotificationCentre
        safetyAlertsNotificationCentre: undefined,
      },
    };
  },
  5: (state: RootState) => {
    // migrate ids from notificationCentre.notifications.READ to be stored in notificationCentre.readNotifications
    const readNotifications: { [key: string]: number } = {};
    state.workspace.notificationCentre.notifications.READ.items.forEach(
      (alert) => {
        readNotifications[alert.id] = Date.now();
      }
    );

    return {
      ...state,
      workspace: {
        ...state.workspace,
        notificationCentre: {
          ...state.workspace.notificationCentre,
          readNotifications,
          // remove notifications
          notifications: undefined,
        },
      },
    };
  },
  6: (state: RootState) => {
    return {
      ...state,
      user: {
        ...state.user,
        currentUser: {
          ...state.user.currentUser,
          permissions: undefined,
          permissionsTabs: undefined,
        },
      },
    };
  },
  7: (state: RootState) => {
    let countryCodes: string[] = [];
    const previousCountry =
      state.settings.panelFilters.schoolRegistrationPanel?.filters?.countryCode;

    if (previousCountry) {
      countryCodes = Array.isArray(previousCountry)
        ? [...previousCountry]
        : [previousCountry];
      // eslint-disable-next-line no-param-reassign
      delete state.settings.panelFilters.schoolRegistrationPanel?.filters
        ?.countryCode;
    }

    return {
      ...state,
      settings: {
        ...state.settings,
        panelFilters: {
          ...state.settings.panelFilters,
          schoolRegistrationPanel: {
            ...state.settings.panelFilters.schoolRegistrationPanel,
            filters: {
              ...state.settings.panelFilters.schoolRegistrationPanel?.filters,
              countryCodes,
            },
          },
        },
      },
    };
  },
};

const createPersistStorage = (): WebStorage => {
  const isServer = typeof window === "undefined";

  // Returns noop (dummy) storage.
  if (isServer) {
    return {
      getItem() {
        return Promise.resolve(null);
      },
      setItem() {
        return Promise.resolve();
      },
      removeItem() {
        return Promise.resolve();
      },
    };
  }

  return createWebStorage("local");
};

const persistConfig = {
  key: "Saar Main Frontend",
  version: 7,
  storage: createPersistStorage(),
  migrate: createMigrate(migrations, { debug: isDev }),
  blacklist: ["devTools"],
};
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === "user/setCurrentUser") {
      return null;
    }
    return action;
  },
  stateTransformer: (state) => {
    // Transform the state to remove sensitive information
    const transformedState = {
      ...state,
      user: {
        currentUser: {
          ...state.user.currentUser,
          accessToken: null,
          data: {
            id: state.user.currentUser.data?.id,
          },
        },
      },
      workspace: {
        ...state.workspace,
        safetyAlertsNotificationCentre: null,
      },
      // old stores some users may have
      basePanel: null,
      splitPanel: null,
      tripPanel: {
        ...state.tripPanel,
        filters: null,
      },
    };
    return transformedState;
  },
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
